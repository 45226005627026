<template>
 <div>  
<div id="PostItem"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'row rowcolumn01 '"   id="rowcolumn01" ref="rowcolumn01"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'row rowrowcolumn015 '"   id="rowrowcolumn015" ref="rowrowcolumn015"  v-bind:style="{}">
 <div  :class="'rectangle rectanglecolumnrowcolumn01111 '"   id="rectanglecolumnrowcolumn01111" ref="rectanglecolumnrowcolumn01111" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',borderRadius : getUnit((byOrga? 0 : 24),'px'),}">
 <v-img  :class="'networkimage networkimagerectanglecolumnrowcolumn01111 '"   id="networkimagerectanglecolumnrowcolumn01111" ref="networkimagerectanglecolumnrowcolumn01111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "byOrga ? orgaImage : userImage" 
>  </v-img>
 <router-link  :class="'routerlink routerlinkrectanglecolumnrowcolumn011113 '"   id="routerlinkrectanglecolumnrowcolumn011113" ref="routerlinkrectanglecolumnrowcolumn011113"  v-bind:style="{}" :to = "byOrga ? orgaLink : userLink" 
>  </router-link>  </div>
 <div  :class="'column columnrowcolumn0112 '"   id="columnrowcolumn0112" ref="columnrowcolumn0112" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'column columncolumnrowcolumn0113 '"   id="columncolumnrowcolumn0113" ref="columncolumnrowcolumn0113"  v-bind:style="{}">
 <router-link  :class="'routerlink routerlinkcolumncolumnrowcolumn01131 '"   id="routerlinkcolumncolumnrowcolumn01131" ref="routerlinkcolumncolumnrowcolumn01131"  v-bind:style="{}" :to = "byOrga ? orgaLink : userLink" 
>
 <div  :class="'text textrouterlinkcolumncolumnrowcolumn011311 '"   id="textrouterlinkcolumncolumnrowcolumn011311" ref="textrouterlinkcolumncolumnrowcolumn011311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ byOrga ? orgaName : userName }}</div>

   </div>  </router-link>
 <div  :class="'text textrouterlinkcolumncolumnrowcolumn0113112 '"   id="textrouterlinkcolumncolumnrowcolumn0113112" ref="textrouterlinkcolumncolumnrowcolumn0113112"  v-bind:style="{color : '#797979',}">
<div class="text-content" :style = "{}">{{ publishedDate }}</div>

   </div>  </div>  </div>  </div>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="toggleMore" v-click-outside="hideMore" :class="'button buttonrowcolumn013 '"   id="buttonrowcolumn013" ref="buttonrowcolumn013"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-more' !== undefined" >{{ 'ion-ios-more' }}
</v-icon></v-btn>  </div>
 <ExtendableTextSection :class="'object extendabletextsectioncolumn03 '"   id="extendabletextsectioncolumn03" ref="extendabletextsectioncolumn03"  v-bind:style="{}" :title = "''" 
 :hasHtmlCode = "true" 
 :maxCharacters = "(mqVal({default:100,md:200}, $mq))" 
 :text = "message" 
 :hasTitle = "false" 
>
</ExtendableTextSection>
 <div  :class="'column columncolumn09 '"   id="columncolumn09" ref="columncolumn09" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',}">
 <v-img  :class="'networkimage networkimagecolumn051 '"   id="networkimagecolumn051" ref="networkimagecolumn051" v-if="hasImage"  v-bind:style="{maxHeight : getUnit(maxHeight,'px'), backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "images[0]" 
>
 <a  :href = "postUrl" 
 :class="'link linknetworkimagecolumn0511 '"   id="linknetworkimagecolumn0511" ref="linknetworkimagecolumn0511"  v-bind:style="{}">  </a>  </v-img>
 <v-carousel  :class="'networkcarousel carouselcolumnmainContent111923 '"   id="carouselcolumnmainContent111923" ref="carouselcolumnmainContent111923" v-if="hasCaroussel"  v-bind:style="{maxHeight : getUnit(maxHeight,'px'),}">
    <v-carousel-item
      v-for='(item,i) in images'
      :key="i"
      :src="item"
    ></v-carousel-item></v-carousel>
<div class="artefactVid"  :class="'networkvideo networkvideocolumn072 '"   id="networkvideocolumn072" ref="networkvideocolumn072" v-if="video"  v-bind:style="{}"> <video width="100%" height="100%"  :controls = "true" 
 :mute = "true" 
 :preload = "'auto'" 
>
<source  v-for='(itemVideoUrl,index) in video' :key="index" :src="itemVideoUrl" >
</video> </div>  </div>
 <div  :class="'column columncolumn07 '"   id="columncolumn07" ref="columncolumn07"  v-bind:style="{}">
 <div  :class="'flow flowcolumncolumn071 '"   id="flowcolumncolumn071" ref="flowcolumncolumn071"  v-bind:style="{justifyContent : 'flex-end',}">
 <div  :class="'text textflowcolumncolumn0711 '"   id="textflowcolumncolumn0711" ref="textflowcolumncolumn0711"  v-bind:style="{color : '#8b8b8b',}">
<div class="text-content" :style = "{}">{{ m_nbLikes }}</div>

   </div>
 <div  :class="'text textflowcolumncolumn07112 '"   id="textflowcolumncolumn07112" ref="textflowcolumncolumn07112"  v-bind:style="{color : '#8b8b8b',}">
<div class="text-content" :style = "{}">{{ ' ∘ ' }}</div>

   </div>
 <div  :class="'text textflowcolumncolumn07113 '"   id="textflowcolumncolumn07113" ref="textflowcolumncolumn07113"  v-bind:style="{color : '#8b8b8b',}">
<div class="text-content" :style = "{}">{{ m_nbComments }}</div>

   </div>  </div>
 <div  :class="'rectangle rectanglecolumncolumn073 '"   id="rectanglecolumncolumn073" ref="rectanglecolumncolumn073"  v-bind:style="{backgroundColor : '#d2d2d2',}">  </div>
 <div  :class="'row rowcolumncolumn075 '"   id="rowcolumncolumn075" ref="rowcolumncolumn075"  v-bind:style="{justifyContent : 'space-between',}">
 <v-btn tag="div" 
 :text = "true" 
 @click="like" :class="'button buttonrowcolumn0311 '"   id="buttonrowcolumn0311" ref="buttonrowcolumn0311" v-if="!alreadyLiked"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-md-thumbs-up' !== undefined"  :left = "true" 
 :color = "'#615f60'" 
>{{ 'ion-md-thumbs-up' }}
</v-icon><span>{{ $t('Feed.likeIt') }}</span>
</v-btn>
 <v-btn tag="div" 
 :text = "true" 
 @click="dislike" :class="'button buttonrowcolumn0311 '"   id="buttonrowcolumn0311" ref="buttonrowcolumn0311" v-if="alreadyLiked"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-md-thumbs-down' !== undefined"  :left = "true" 
 :color = "'red'" 
>{{ 'ion-md-thumbs-down' }}
</v-icon><span>{{ $t('Feed.dislikeIt') }}</span>
</v-btn>
 <v-btn tag="div" 
 :text = "true" 
 @click="shareIt" :class="'button buttonrowcolumn0312 '"   id="buttonrowcolumn0312" ref="buttonrowcolumn0312"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-md-share-alt' !== undefined"  :left = "true" 
 :color = "'#615f60'" 
>{{ 'ion-md-share-alt' }}
</v-icon><span>{{ $t('Feed.share') }}</span>
</v-btn>  </div>  </div>
 <PostMenu :class="'object postmenucolumn09 '"   id="postmenucolumn09" ref="postmenucolumn09" v-if="menuVisible"  v-bind:style="{}" :close = "hideMore" 
 :updateInfo = "updateInfo" 
 :postUrl = "postUrl" 
 :postID = "id" 
 :post = "attributes" 
>
</PostMenu>  </div>
 
      </div>
 </div> 
</template>
<script>
import ExtendableTextSection from '../../../At/Utils/ExtendableTextSection'
import PostMenu from '../../../At/Components/FeedPage/PostMenu'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'PostItem',

    components: {ExtendableTextSection,PostMenu,},
	methods: {
	
		updateInfo: function(val) { 		
	val.attributes.author = this.attributes?.author

	this.m_info = val

}
,
		shareIt: function() { 		
	this.share(this.shareOptions)

}
,
		toggleMore: function() { 		
	if(this.menuVisible){

		this.hideMore()

	} else {

		this.showMore()

	}

}
,
		showMore: function() { 		
	this.menuVisible = true

}
,
		hideMore: function() { 		
	this.menuVisible = false

}
,
		like: function() { 		
	this.getPost(_ => {	

		let list = this.likers

		list.push(this.user.id)

		

		this.registerLikes(list)

	})

}
,
		dislike: function() { 		
	this.getPost(_ => {		

		let list = this.likers

		var index = list.indexOf(this.user.id)

		list.splice(index, 1);

		

		this.registerLikes(list)

	})

}
,
		getPost: function(callback) { 		
	Request.getTable('posts/' + this.id)

	.then((res) => {

		this.m_info = res.data.data

		if(callback) callback()

	})

}
,
		registerLikes: function(list) {		
	let config = {

		likers: list

	}

	

	Request.updateEntry('posts', this.id, config)

	.then(res => {

			this.m_info = res.data.data

	}).catch(err => {

	})

}
,
	},
	mounted(){
			
	this.m_info = this.info

	},
	computed: {
	
		postUrl: function() { 		
	return this.$store.state.webUrl + "/post/" + this.id

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		byOrga: function() { 		
	if(this.organisation){

		return true

	}

	return false

}
,
		shareOptions: function() { 		
	return {

		url: this.postUrl, 

		title: 'Afrotiative | Post', 

		hashtags: ['Post', 'Afrotiative'], 

		description: this.message, 

		to: '',

		subject: 'Post : ' + this.userName

	}

}
,
		maxHeight: function() { 		
	return this.full ? 'none' : 300

}
,
		id: function(){		
	if(this.m_info){

		return this.m_info.id

	}

	return null

}
,
		attributes: function(){		
	if(this.m_info){

		return this.m_info.attributes

	}

	return null

}
,
		images: function() { 		
	if(this.attributes){

		if(this.attributes.images && this.attributes.images.data){

			let items = this.attributes.images.data

				.map(i => {

					return Main.getImgUrl(i.attributes, 'medium');

				});

			return items

		}

		return null

	}

	return null

}
,
		hasImage: function() { 		
	if(this.images == null){ return false}

	

	return this.images.length == 1

}
,
		hasCaroussel: function() { 		
	if(this.images == null){ return false}

	

	return this.images.length > 1

}
,
		video: function() { 		
	if(this.attributes){

		if(this.attributes.video && this.attributes.video.data){

			let videoData = this.attributes.video.data.attributes

			return [Main.getUrl(videoData)]

		}

		return null

	}

	return null

}
,
		author: function() { 		
	return this.attributes?.author?.data?.attributes

}
,
		organisation: function() { 		
	return this.attributes?.organisation?.data?.attributes

}
,
		likers: function() { 		
	return this.attributes?.likers?.data?.map(l => l.id)

}
,
		authorID: function() { 		
	return this.attributes?.author?.data?.id

}
,
		userImage: function() { 		
	if(this.author){

		if(this.author.image){

			let item = this.author.image.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.author.username)

	}

	return null

}
,
		orgaImage: function() { 		
	if(this.organisation){

		if(this.organisation.logo){

			let item = this.organisation.logo.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.organisation.name)

	}

	return null

}
,
		userLink: function() { 		
	if(this.author){

			let slug = this.author.slug;

			

			if(slug){

				return '/profile/' + slug;

			}else{

				return '/profile/id/' + this.authorID;

			}

	}

	return "/"

}
,
		orgaLink: function() { 		
	return "/organisations/" 

			+ this.organisation?.slug

}
,
		userName: function() { 		
	if(this.author){

		return this.author.username;

	}

	return "..."

}
,
		orgaName: function() { 		
	if(this.organisation){

		return this.organisation.name;

	}

	return "..."

}
,
		publishedDate: function() { 		
	if(this.attributes){

			var mydate = new Date(this.attributes.updatedAt);

			return ' ∘ ' + mydate.getMonth() + ',' + mydate.getFullYear()	

		} else {

			return "-.-.-"

		}

	return "-.-.-"

}
,
		message: function() { 		
	return this.attributes?.message

}
,
		nbLikes: function() { 		
	return this.likers?.length ?? 0

}
,
		m_nbLikes: function() { 		
	return this.nbLikes + ' ' + this.$t('Feed.likes')

}
,
		nbComments: function() { 		
	return 0

}
,
		m_nbComments: function() { 		
	return this.nbComments + ' ' + this.$t('Feed.comment')

}
,
		alreadyLiked: function() { 		
	return this.likers?.includes(this.user.id) ?? false

}
,
	},
	watch: {
	
		info: function(val) { 		
	this.m_info = val

}
,
	},
	data(){
	return {
		
		m_info:  null		,
		fromOrg:  false		,
		menuVisible:  false		,
		}
	},
	props: {
	
		info: {					required: false,			default: () =>  null,		},
		full: {			type: Boolean,			required: false,			default: () =>  false,		},
		share: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#PostItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none !important;
	color: #e4580a;
}

.networkvideo{
	video{
		object-fit: cover;	
	}
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:4px ; 
background-color:#fff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#e1e3e7 ; 
padding-top:16px ; 
padding-bottom:16px ; 
padding-left:16px ; 
padding-right:16px ; 

.row.rowcolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
justify-content:space-between ; background-color:transparent ; 

	.row.rowrowcolumn015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:8px ; background-color:transparent ; 

		.rectangle.rectanglecolumnrowcolumn01111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:48px!important ; 
		height:48px!important ; 
		overflow:hidden !important; 
		
		background-color:#ffffff ; 
		border-style:solid ; 
		border-width:1px ; 
		border-color:#dfdfdf ; 

			.networkimage.networkimagerectanglecolumnrowcolumn01111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

			.routerlink.routerlinkrectanglecolumnrowcolumn011113{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			

			
}

		
}

		.column.columnrowcolumn0112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		flex:1 !important; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.column.columncolumnrowcolumn0113{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.routerlink.routerlinkcolumncolumnrowcolumn01131{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				

					.text.textrouterlinkcolumncolumnrowcolumn011311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:auto!important ; 
					
					font-size:15px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

				.text.textrouterlinkcolumncolumnrowcolumn0113112{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#797979 ; 
				font-size:15px ; 
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttonrowcolumn013{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:40px!important ; 
	height:40px!important ; 
	
	font-size:30px ; 
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	font-size:30px ; 
	}
	}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn09{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
overflow:hidden !important; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
border-radius:8px ; 

	.networkimage.networkimagecolumn051{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	max-width: 100%!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		.link.linknetworkimagecolumn0511{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: block;

		
}

	
}

	.networkcarousel.carouselcolumnmainContent111923{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	}

	.networkvideo.networkvideocolumn072{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	background-color: black;

	
}


}

.column.columncolumn07{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:4px ; background-color: transparent ; 

	.flow.flowcolumncolumn071{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: wrap;
	flex: initial;
	gap:6px ; 
	justify-content:flex-end ; background-color:transparent ; 

		.text.textflowcolumncolumn0711{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		color:#8b8b8b ; 
		font-size:12px ; 
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		color:#8b8b8b ; 
		font-size:12px ; 
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07113{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		color:#8b8b8b ; 
		font-size:12px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumncolumn073{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:1px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	background-color:#d2d2d2 ; 

	
}

	.row.rowcolumncolumn075{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:space-between ; background-color:transparent ; 

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:44px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:44px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.button.buttonrowcolumn0312{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:44px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

	
}


}

.object.postmenucolumn09{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: 55px ; 
right: 4px ; 
width:97%!important ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglecolumnrowcolumn01111{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.networkimagerectanglecolumnrowcolumn01111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.routerlink.routerlinkrectanglecolumnrowcolumn011113{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.column.columnrowcolumn0112{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnrowcolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.routerlink.routerlinkcolumncolumnrowcolumn01131{

				
				position:relative !important; 
				
				
				
				
				

					.text.textrouterlinkcolumncolumnrowcolumn011311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textrouterlinkcolumncolumnrowcolumn0113112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttonrowcolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn09{


position:relative !important; 









	.networkimage.networkimagecolumn051{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.link.linknetworkimagecolumn0511{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

		
}

	
}

	.networkcarousel.carouselcolumnmainContent111923{

	
	position:relative !important; 
	
	
	
	
	}

	.networkvideo.networkvideocolumn072{

	
	position:relative !important; 
	
	
	
	
	background-color: black;

	
}


}

.column.columncolumn07{


position:relative !important; 









	.flow.flowcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumn0711{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumncolumn073{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.row.rowcolumncolumn075{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.object.postmenucolumn09{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglecolumnrowcolumn01111{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.networkimagerectanglecolumnrowcolumn01111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.routerlink.routerlinkrectanglecolumnrowcolumn011113{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.column.columnrowcolumn0112{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnrowcolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.routerlink.routerlinkcolumncolumnrowcolumn01131{

				
				position:relative !important; 
				
				
				
				
				

					.text.textrouterlinkcolumncolumnrowcolumn011311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textrouterlinkcolumncolumnrowcolumn0113112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttonrowcolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn09{


position:relative !important; 









	.networkimage.networkimagecolumn051{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.link.linknetworkimagecolumn0511{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

		
}

	
}

	.networkcarousel.carouselcolumnmainContent111923{

	
	position:relative !important; 
	
	
	
	
	}

	.networkvideo.networkvideocolumn072{

	
	position:relative !important; 
	
	
	
	
	background-color: black;

	
}


}

.column.columncolumn07{


position:relative !important; 









	.flow.flowcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumn0711{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumncolumn073{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.row.rowcolumncolumn075{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.object.postmenucolumn09{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglecolumnrowcolumn01111{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.networkimagerectanglecolumnrowcolumn01111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.routerlink.routerlinkrectanglecolumnrowcolumn011113{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.column.columnrowcolumn0112{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnrowcolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.routerlink.routerlinkcolumncolumnrowcolumn01131{

				
				position:relative !important; 
				
				
				
				
				

					.text.textrouterlinkcolumncolumnrowcolumn011311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textrouterlinkcolumncolumnrowcolumn0113112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttonrowcolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn09{


position:relative !important; 









	.networkimage.networkimagecolumn051{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.link.linknetworkimagecolumn0511{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

		
}

	
}

	.networkcarousel.carouselcolumnmainContent111923{

	
	position:relative !important; 
	
	
	
	
	}

	.networkvideo.networkvideocolumn072{

	
	position:relative !important; 
	
	
	
	
	background-color: black;

	
}


}

.column.columncolumn07{


position:relative !important; 









	.flow.flowcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumn0711{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumncolumn073{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.row.rowcolumncolumn075{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.object.postmenucolumn09{


position:absolute !important; 




width:355px!important ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglecolumnrowcolumn01111{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.networkimagerectanglecolumnrowcolumn01111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.routerlink.routerlinkrectanglecolumnrowcolumn011113{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.column.columnrowcolumn0112{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnrowcolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.routerlink.routerlinkcolumncolumnrowcolumn01131{

				
				position:relative !important; 
				
				
				
				
				

					.text.textrouterlinkcolumncolumnrowcolumn011311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textrouterlinkcolumncolumnrowcolumn0113112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttonrowcolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn09{


position:relative !important; 









	.networkimage.networkimagecolumn051{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.link.linknetworkimagecolumn0511{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

		
}

	
}

	.networkcarousel.carouselcolumnmainContent111923{

	
	position:relative !important; 
	
	
	
	
	}

	.networkvideo.networkvideocolumn072{

	
	position:relative !important; 
	
	
	
	
	background-color: black;

	
}


}

.column.columncolumn07{


position:relative !important; 









	.flow.flowcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumn0711{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumncolumn073{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.row.rowcolumncolumn075{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.object.postmenucolumn09{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglecolumnrowcolumn01111{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.networkimagerectanglecolumnrowcolumn01111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.routerlink.routerlinkrectanglecolumnrowcolumn011113{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.column.columnrowcolumn0112{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnrowcolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.routerlink.routerlinkcolumncolumnrowcolumn01131{

				
				position:relative !important; 
				
				
				
				
				

					.text.textrouterlinkcolumncolumnrowcolumn011311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textrouterlinkcolumncolumnrowcolumn0113112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttonrowcolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn09{


position:relative !important; 









	.networkimage.networkimagecolumn051{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.link.linknetworkimagecolumn0511{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

		
}

	
}

	.networkcarousel.carouselcolumnmainContent111923{

	
	position:relative !important; 
	
	
	
	
	}

	.networkvideo.networkvideocolumn072{

	
	position:relative !important; 
	
	
	
	
	background-color: black;

	
}


}

.column.columncolumn07{


position:relative !important; 









	.flow.flowcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumn0711{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumncolumn07113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumncolumn073{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.row.rowcolumncolumn075{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0311{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.object.postmenucolumn09{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
