import { render, staticRenderFns } from "./AdminFormationForm.vue?vue&type=template&id=444a3876&scoped=true&"
import script from "./AdminFormationForm.vue?vue&type=script&lang=js&"
export * from "./AdminFormationForm.vue?vue&type=script&lang=js&"
import style0 from "./AdminFormationForm.vue?vue&type=style&index=0&id=444a3876&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "444a3876",
  null
  
)

export default component.exports