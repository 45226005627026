<template>
 <div>  
<div id="AdminArticleForm"> 
         
 <div  :class="'column columncolumncolumn0712 '"   id="columncolumncolumn0712" ref="columncolumncolumn0712"  v-bind:style="{}">
 <v-row  :class="'grid gridcolumncolumncolumn07129 '"   id="gridcolumncolumncolumn07129" ref="gridcolumncolumncolumn07129"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumncolumn071295 '"   id="colgridcolumncolumncolumn071295" ref="colgridcolumncolumncolumn071295"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.title') + '*'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal0113111 '"   id="textfieldcolumnslottemplatedialogboxmodal0113111" ref="textfieldcolumnslottemplatedialogboxmodal0113111" v-model="title"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712954 '"   id="colgridcolumncolumncolumn0712954" ref="colgridcolumncolumncolumn0712954"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "'slug'" 
 :append-icon = "'ion-md-refresh'" 
 :dense = "true" 
 :type = "'text'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131131 '"   id="textfieldcolumnslottemplatedialogboxmodal01131131" ref="textfieldcolumnslottemplatedialogboxmodal01131131" v-model="slug"  v-bind:style="{color : primaryColor,}" @click:append="generateSlug"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712955 '"   id="colgridcolumncolumncolumn0712955" ref="colgridcolumncolumncolumn0712955"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-textarea  :label = "$t('Admin.description') + '*'" 
 :auto-grow = "true" 
 filled :class="'textarea textarearectanglecolumncolumnslottemplatedialogboxmodal01159141 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal01159141" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal01159141" v-model="description"  v-bind:style="{}"></v-textarea></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712956 '"   id="colgridcolumncolumncolumn0712956" ref="colgridcolumncolumncolumn0712956"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.downloadLink')" 
 :dense = "true" 
 :type = "'url'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="downloadLink"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071291 '"   id="colgridcolumncolumncolumn071291" ref="colgridcolumncolumncolumn071291"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setImage" 
 :title = "'image'" 
 :type = "'image'" 
 :item = "image" 
 :disabled = "localization != 'fr'" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071293 '"   id="colgridcolumncolumncolumn071293" ref="colgridcolumncolumncolumn071293"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setVideo" 
 :type = "'video'" 
 :title = "'video'" 
 :item = "video" 
 :disabled = "localization != 'fr'" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712917 '"   id="colgridcolumncolumncolumn0712917" ref="colgridcolumncolumncolumn0712917"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setFirstImage" 
 :title = "'FirstImage'" 
 :type = "'image'" 
 :item = "firstImage" 
 :disabled = "localization != 'fr'" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712918 '"   id="colgridcolumncolumncolumn0712918" ref="colgridcolumncolumncolumn0712918"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setSecondImage" 
 :title = "'SecondImage'" 
 :type = "'image'" 
 :item = "secondImage" 
 :disabled = "localization != 'fr'" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>  </v-row>
 <v-row  :class="'grid gridcolumncolumncolumn07121596 '"   id="gridcolumncolumncolumn07121596" ref="gridcolumncolumncolumn07121596"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumncolumn07129311 '"   id="colgridcolumncolumncolumn07129311" ref="colgridcolumncolumncolumn07129311"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn07129311 '"   id="admincomboviewcolgridcolumncolumncolumn07129311" ref="admincomboviewcolgridcolumncolumncolumn07129311"  v-bind:style="{}" :updateModel = "setCategories" 
 :tableName = "'categories'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "filtersForCategories" 
 :label = "$t('Admin.categories')" 
 :p_model = "categories" 
 :multiple = "true" 
>
</AdminComboView></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071293113 '"   id="colgridcolumncolumncolumn071293113" ref="colgridcolumncolumncolumn071293113"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn07129311 '"   id="admincomboviewcolgridcolumncolumncolumn07129311" ref="admincomboviewcolgridcolumncolumncolumn07129311"  v-bind:style="{}" :updateModel = "setTags" 
 :tableName = "'tags'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "filtersForTags" 
 :label = "$t('Admin.tags')" 
 :p_model = "tags" 
 :multiple = "true" 
>
</AdminComboView></div></v-col>  </v-row>
 <div  :class="'column columncolumncolumncolumn07129 '"   id="columncolumncolumncolumn07129" ref="columncolumncolumncolumn07129"  v-bind:style="{}">
 <div  :class="'text textcolumncolumncolumncolumn071291 '"   id="textcolumncolumncolumncolumn071291" ref="textcolumncolumncolumncolumn071291"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Admin.introduction') + '*' }}</div>

   </div>
 <VueEditor :class="'importedobject textarearectanglecolumncolumnslottemplatedialogboxmodal01159142 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal01159142" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal01159142" v-model="introduction" :editorToolbar = "customToolbar" 
  v-bind:style="{}">
</VueEditor>  </div>
 <div  :class="'column columncolumncolumncolumn071296 '"   id="columncolumncolumncolumn071296" ref="columncolumncolumncolumn071296"  v-bind:style="{}">
 <div  :class="'text textcolumncolumncolumncolumn071291 '"   id="textcolumncolumncolumncolumn071291" ref="textcolumncolumncolumncolumn071291"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Admin.body') + '*' }}</div>

   </div>
 <VueEditor :class="'importedobject textarearectanglecolumncolumnslottemplatedialogboxmodal01159142 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal01159142" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal01159142" v-model="body" :editorToolbar = "customToolbar" 
  v-bind:style="{}">
</VueEditor>  </div>
 <div  :class="'column columncolumncolumncolumn071297 '"   id="columncolumncolumncolumn071297" ref="columncolumncolumncolumn071297"  v-bind:style="{}">
 <div  :class="'text textcolumncolumncolumncolumn071291 '"   id="textcolumncolumncolumncolumn071291" ref="textcolumncolumncolumncolumn071291"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Admin.conclusion') + '*' }}</div>

   </div>
 <VueEditor :class="'importedobject textarearectanglecolumncolumnslottemplatedialogboxmodal01159142 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal01159142" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal01159142" v-model="conclusion" :editorToolbar = "customToolbar" 
  v-bind:style="{}">
</VueEditor>  </div>
 <div  :class="'text errorText '"   id="errorText" ref="errorText" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import AdminMediaPicker from '../../../../At/Components/AdminPage/AdminMediaPicker'
import AdminComboView from '../../../../At/Components/AdminPage/AdminComboView'

import * as Main from '../../../../Js/main'
import * as Request from '../../../../Js/requests'

import { VueEditor } from "vue2-editor";
export default {
	name: 'AdminArticleForm',

    components: {AdminMediaPicker,AdminComboView,VueEditor,},
	methods: {
	
		loadData: function() { 		
	this.title = this.subEntity?.title

	this.slug = this.subEntity?.slug

	this.description = this.subEntity?.description

	this.downloadLink = this.entity?.downloadLink

	this.introduction = this.subEntity?.introduction

	this.body = this.subEntity?.body

	this.conclusion = this.subEntity?.conclusion

	

	this.image = this.entity?.image.data

	this.video = this.entity?.video.data

	this.firstImage = this.entity?.firstImage.data

	this.secondImage = this.entity?.secondImage.data

	

	this.categories = this.subEntity?.categories.data

	this.tags = this.subEntity?.tags.data

		

	this.loading = false

}
,
		generateSlug: function() { 		
	if(this.title){

		this.slug = this.title.replaceAll(/\s+/g, '-')

	}

}
,
		setImage: function(val) { 		
	this.m_image = val

}
,
		setVideo: function(val) { 		
	this.m_video = val

}
,
		setFirstImage: function(val) { 		
	this.m_firstImage = val

}
,
		setSecondImage: function(val) { 		
	this.m_secondImage = val

}
,
		setCategories: function(val) { 		
	this.categories = val

}
,
		setTags: function(val) { 		
	this.tags = val

}
,
		createEntry: function() { 		
	let data = {}

	

	if(this.title){ data.title = this.title }

	if(this.slug){ data.slug = this.slug }

	if(this.description){ data.description = this.description }

	if(this.downloadLink){ data.downloadLink = this.downloadLink }

	if(this.introduction){ data.introduction = this.introduction }

	if(this.body){ data.body = this.body }

	if(this.conclusion){ data.conclusion = this.conclusion }

	

	if(this.categories){ data.categories = this.categories }

	if(this.tags){ data.tags = this.tags }

	

	data.publishedAt = new Date().toISOString()

	

  const formData = new FormData();

	

	if(this.localization == 'fr'){ 	

		data.redactor = this.$store.state.user.id

			

		if(this.m_image){	

			formData.append(`files.image`, this.m_image, this.m_image.name);

		}

		

		if(this.m_video){

			formData.append(`files.video`, this.m_video, this.m_video.name);

		}

			

		if(this.m_firstImage){	

			formData.append(`files.firstImage`, this.m_firstImage, this.m_firstImage.name);

		}

			

		if(this.m_secondImage){	

			formData.append(`files.secondImage`, this.m_secondImage, this.m_secondImage.name);

		}

	} else {

		data.locale = this.localization

	}

	

	formData.append('data', JSON.stringify(data));

	

	Request.createLocalizedEntry(this.tableName, this.entity?.id ,formData)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryCreated')) 

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		updateEntry: function() { 		
	let data = {}

	

	if(this.title){ data.title = this.title }

	if(this.slug){ data.slug = this.slug }

	if(this.description){ data.description = this.description }

	if(this.downloadLink){ data.downloadLink = this.downloadLink }

	if(this.introduction){ data.introduction = this.introduction }

	if(this.body){ data.body = this.body }

	if(this.conclusion){ data.conclusion = this.conclusion }

	

	if(this.categories){ data.categories = this.categories }

	if(this.tags){ data.tags = this.tags }

	

	if(this.localization == 'fr'){ 	

			

		if(this.m_image){	

			data.image = this.m_image

		}

		

		if(this.m_video){

			data.video = this.m_video;

		}

			

		if(this.m_firstImage){	

			data.firstImage = this.m_firstImage

		}

			

		if(this.m_secondImage){	

			data.secondImage = this.m_secondImage

		}

	}

	

	Request.updateEntry(this.tableName, this.subEntity.id, data)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryUpdated'))

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true	

	this.$awn.alert(message)

}
,
	},
	mounted(){
			
	this.loadData()

	},
	computed: {
	
		customToolbar: function() { 		
	return [	

	  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

	  ['blockquote', 'code-block'],

	

	  [{ 'header': 1 }, { 'header': 2 }],               // custom button values

	  [{ 'list': 'ordered'}, { 'list': 'bullet' }],

	  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript

	  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

	  [{ 'direction': 'rtl' }],                         // text direction

	

	  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

	  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

	

	  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

	  [{ 'font': [] }],

	  [{ 'align': [] }],

	

	  ['clean']  

	]

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		entityID: function() { 		
	return this.entity?.id

}
,
		filtersForCategories: function() { 			
	let filter = {}

	return filter

}
,
		filtersForTags: function() { 			
	let filter = {}

	return filter

}
,
	},
	watch: {
	
		subEntity: function(val) { 		
	this.loading = true

	this.changed = false

	this.loadData()

}
,
		title: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		slug: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		description: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		downloadLink: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		introduction: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		body: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		conclusion: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_image: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_video: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_firstImage: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_seconImage: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		changed: function(val) { 		
	this.changed = val

}
,
		categories: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		tags: function(val) { 		
	if(!this.loading) this.changed = true

}
,
	},
	data(){
	return {
		
		title:  null		,
		slug:  null		,
		description:  null		,
		downloadLink:  null		,
		introduction:  null		,
		body:  null		,
		conclusion:  null		,
		image:  null		,
		video:  null		,
		firstImage:  null		,
		secondImage:  null		,
		categories:  null		,
		tags:  null		,
		m_image:  null		,
		m_video:  null		,
		m_firstImage:  null		,
		m_secondImage:  null		,
		loading:  true		,
		changed:  false		,
		error:  null		,
		errorVisible:  false		,
		tableName:  'articles'		,
		}
	},
	props: {
	
		entity: {					required: false,			default: () =>  null,		},
		subEntity: {					required: false,			default: () =>  null,		},
		localization: {					required: false,			default: () =>  null,		},
		updateSelectedItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminArticleForm{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.columncolumncolumn0712{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; background-color: transparent ; 

.grid.gridcolumncolumncolumn07129{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 5px ; 
		
		margin-top: 5px ; 
		margin-right: 5px ; 
		margin-bottom: 5px ; 
		width: calc(100% - (5px + 5px)) !important;
		height: calc(100% - (5px + 5px)) !important;
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:200px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:200px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712917{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:200px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:200px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.grid.gridcolumncolumncolumn07121596{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn07129{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 5px ; 
	
	margin-top: 5px ; 
	margin-right: 5px ; 
	margin-bottom: 5px ; 
	width: calc(100% - (5px + 5px)) !important;
	height: calc(100% - (5px + 5px)) !important;
	

	
}


}

.column.columncolumncolumncolumn071296{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 5px ; 
	
	margin-top: 5px ; 
	margin-right: 5px ; 
	margin-bottom: 5px ; 
	width: calc(100% - (5px + 5px)) !important;
	height: calc(100% - (5px + 5px)) !important;
	

	
}


}

.column.columncolumncolumncolumn071297{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 5px ; 
	
	margin-top: 5px ; 
	margin-right: 5px ; 
	margin-bottom: 5px ; 
	width: calc(100% - (5px + 5px)) !important;
	height: calc(100% - (5px + 5px)) !important;
	

	
}


}

.text.errorText{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

color:#ff1a05 ; 
line-height:10px ; 
font-size:10px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;text-decoration: underline
}


}


}


@media (max-width: 600px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712917{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.grid.gridcolumncolumncolumn07121596{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn07129{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071296{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071297{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712917{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.grid.gridcolumncolumncolumn07121596{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn07129{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071296{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071297{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712917{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.grid.gridcolumncolumncolumn07121596{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn07129{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071296{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071297{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.text.errorText{


position:relative !important; 





line-height:15px ; 
font-size:15px ; 
.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712917{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.grid.gridcolumncolumncolumn07121596{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn07129{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071296{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071297{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712917{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.grid.gridcolumncolumncolumn07121596{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn07129{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071296{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.column.columncolumncolumncolumn071297{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

	
	position:relative !important; 
	
	
	
	
	

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
