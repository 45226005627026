<template>
 <div>  
<div id="Legal"> 
         
 <MainLayout :class="'object basic0 '"   id="basic0" ref="basic0"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatebasic01 '"   id="slottemplatebasic01" ref="slottemplatebasic01"  v-bind:style="{}">
 <div  :class="'column column012 '"   id="column012" ref="column012"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout0151392 '"   id="contentwrappercolumnslottemplatedefaultlayout0151392" ref="contentwrappercolumnslottemplatedefaultlayout0151392"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column content1 '"   id="content1" ref="content1"  v-bind:style="{}">
 <div  :class="'row rowcontent1 '"   id="rowcontent1" ref="rowcontent1"  v-bind:style="{justifyContent : 'flex-end',}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :outlined = "true" 
 @click="print" :class="'button printBtn '"   id="printBtn" ref="printBtn"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-md-print' !== undefined"  :left = "true" 
>{{ 'ion-md-print' }}
</v-icon><span>{{ $t('Legal.print') }}</span>
</v-btn>  </div>
 <div  :class="'text textContent '"   id="textContent" ref="textContent"  v-bind:style="{color : '#2b2b2b',}">
<div class="text-content" :style = "{}"></div>

   </div>
 <div  :class="'text textcontent5 '"   id="textcontent5" ref="textcontent5"  v-bind:style="{color : '#2b2b2b',}">
<div class="text-content" :style = "{}">{{ lastActualisation }}</div>

   </div>  </div></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
import {marked} from 'marked'
import { Printd } from 'printd'
export default {
	name: 'Legal',

    components: {MainLayout,ContentWrapper,},
	methods: {
	
		print: function() { 		
	const cssText = `

 	 .main {

	    background-color: #141414;

			color: #fff;

	  }

	`

	

	// define options to use

	const options = {

	  parent: document.getElementById('textContent')

	}

	const d = new Printd(options);

	d.print( document.getElementById('textContent'), [ cssText ] );

}
,
		loadArchive: function() { 		
	Request.getArchive(this.page)

	.then(res => {

		this.archive = res.data.data[0].attributes

		document.getElementById('textContent').innerHTML 

			= `<div class="main">${marked.parse(this.archive.content)}</div>`;

	})

	.catch(error => {

		console.log(error)

	})

}
,
	},
	mounted(){
			
	if(this.page == undefined){

		this.$router.push('/')

	}

	this.loadArchive()	

	},
	metaInfo(){
			
	return {

		title: this.companyName + " | Legal",

		meta: [		

	   { property: 'og:title', content: this.companyName + " | Legal"},

	   { property: 'og:type', content: 'website'},

	

	   { name: 'twitter:title', content: this.companyName + " | Legal"},

	   { name: 'twitter:card', content: 'website'},

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		selectedLang: function() { 		
	return this.$store.state.selectedLang

}
,
		lastActualisation: function() { 		
	if(this.archive){	

		let date = new Date(this.archive.updatedAt)

		let lang = this.$store.state.selectedLang

		let inDate = new Intl.DateTimeFormat(lang, { dateStyle: 'full' }).format(date);

		return this.$t('Legal.lastActualisation') + inDate

	}

	return "-- : --"

}
,
		page: function() { 		
	return this.$route.params.page

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
	},
	watch: {
	
		selectedLang: function() { 		
	this.loadArchive()

}
,
		page: function(val) { 		
	location.reload();

}
,
	},
	data(){
	return {
		
		content:  ''		,
		archive:  ""		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Legal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.basic0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column012{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.content1{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
				
				left: calc(50% + 0px) ; 
				
				width:95%!important ; 
				height:auto!important ; 
				max-width: 1024px!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:30px ; background-color: transparent ; 
				padding-top:50px ; 
				padding-bottom:50px ; 

					.row.rowcontent1{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: row;
					justify-content:flex-end ; background-color:transparent ; 
					padding-left:0px ; 

						.button.printBtn{

						height: auto;
						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						
						height:32px!important ; 
						
						border-radius:0px ; 
						text-transform:uppercase ; 
						min-width: auto;
						padding-left: 16px;
						padding-right: 16px;

						i.v-icon{
						}
						}

					
}

					.text.textContent{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#2b2b2b ; 
					font-size:18px ; 
					.text-content{
					width: 100%;
					}

					
}

					.text.textcontent5{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#2b2b2b ; 
					font-size:22px ; 
					.text-content{
					width: 100%;
					font-weight: bold;text-decoration: underline
					}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.content1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.row.rowcontent1{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.button.printBtn{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

					.text.textContent{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcontent5{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.content1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.row.rowcontent1{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.button.printBtn{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

					.text.textContent{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcontent5{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.content1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.row.rowcontent1{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.button.printBtn{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

					.text.textContent{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcontent5{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.content1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.row.rowcontent1{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.button.printBtn{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

					.text.textContent{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcontent5{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.content1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.row.rowcontent1{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.button.printBtn{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

					.text.textContent{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcontent5{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
