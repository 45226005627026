<template>
 <div>  
<div id="AdminComboView"> 
         
 <v-autocomplete :class="'importedobject importedobjectcolgridcolumncolumncolumn07129311 '"   id="importedobjectcolgridcolumncolumncolumn07129311" ref="importedobjectcolgridcolumncolumncolumn07129311" v-model="list" :clearable = "true" 
 :filled = "type == 'filled'" 
 :hideSelected = "true" 
 :multiple = "multiple" 
 :dense = "dense" 
 :smallChips = "true" 
 :label = "label" 
 :filter = "filter" 
 :items = "entries" 
 :itemText = "filterAttribute" 
 :itemValue = "'id'" 
 :solo = "type == 'solo'" 
 :outlined = "type == 'outlined'" 
  v-bind:style="{}">
</v-autocomplete>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'AdminComboView',

    components: {},
	methods: {
	
		filter: function(item, queryText, itemText) {		
	if(this.filterAttribute == null) return true

	

  const textOne = item.filterAttribute.toLowerCase()

  const searchText = queryText.toLowerCase()



  return textOne.indexOf(searchText) > -1

}
,
		getEntries: function() { 			
	let config = {	

		sort: ['updatedAt:desc', 'id:desc']

	}

	

	if(this.filtersForGet){

		config.filters = this.filtersForGet

	}

	

	Request.getTableArgs(this.tableName, config)

	.then((res) => {

		if(res.data.data == undefined){

			this.entries = res.data

		} else {

			this.entries = res.data.data

		}

		

		this.entries = this.entries

			.map(w => {

				if(w.attributes){	

					w.attributes.id = w.id

					return w.attributes

				}

				return w

			})

		this.loading = false

	})

}
,
		getModel: function() { 		
	if(this.p_model.data){

		if(this.multiple){

			return this.p_model.map(m => m.id)

		} else {

			return this.p_model.data.id

		}

	}

	return this.p_model

}
,
	},
	mounted(){
			
	this.getEntries()

	},
	watch: {
	
		list: function(val) { 		
	this.updateModel(val)

}
,
		p_model: function(val) { 		
	if(val){

		this.list = this.getModel()

	}

}
,
	},
	data(){
	return {
		
		entries:  null		,
		list:  null		,
		}
	},
	props: {
	
		label: {					required: false,			default: () =>  null,		},
		tableName: {					required: false,			default: () =>  null,		},
		filterAttribute: {					required: false,			default: () =>  null,		},
		filtersForGet: {					required: false,			default: () =>  null,		},
		p_model: {					required: false,			default: () =>  null,		},
		dense: {			type: Boolean,			required: false,			default: () =>  false,		},
		multiple: {			type: Boolean,			required: false,			default: () =>  false,		},
		updateModel: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		type: {					required: false,			default: () =>  "filled",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminComboView{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.importedobject.importedobjectcolgridcolumncolumncolumn07129311{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
clearable:true !important; 
hideSelected:true !important; 
smallChips:true !important; 
itemValue:id !important; 
width: calc(100% - (0px + 0px)) !important;



}


@media (max-width: 600px) {


.importedobject.importedobjectcolgridcolumncolumncolumn07129311{


position:relative !important; 







}
}

@media (min-width: 601px) {


.importedobject.importedobjectcolgridcolumncolumncolumn07129311{


position:relative !important; 







}
}

@media (min-width: 961px) {


.importedobject.importedobjectcolgridcolumncolumncolumn07129311{


position:relative !important; 







}
}

@media (min-width: 1265px) {


.importedobject.importedobjectcolgridcolumncolumncolumn07129311{


position:relative !important; 







}
}

@media (min-width: 1905px) {


.importedobject.importedobjectcolgridcolumncolumncolumn07129311{


position:relative !important; 







}
}
}
</style>
