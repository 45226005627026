<template>
 <div>  
<div id="RelativeItem"> 
         
 <v-row  :class="'grid grid0 '"   id="grid0" ref="grid0"  v-bind:style="{}">
 <v-col  :class="'col colgrid01 '"   id="colgrid01" ref="colgrid01" :_padding = "'0'" 
  v-bind:style="{padding : '0',}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <div  :class="'column columncolgrid011 '"   id="columncolgrid011" ref="columncolgrid011"  v-bind:style="{}">
 <div  :class="'text textcolumncolgrid0111 '"   id="textcolumncolgrid0111" ref="textcolumncolgrid0111"  v-bind:style="{color : '#8f5849',}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <div  :class="'text textcolumncolgrid01112 '"   id="textcolumncolgrid01112" ref="textcolumncolgrid01112"  v-bind:style="{color : '#000000e6',}">
<div class="text-content" :style = "{}">{{ m_message }}</div>

   </div>  </div></div></v-col>
 <v-col  :class="'col colgrid012 '"   id="colgrid012" ref="colgrid012" :_padding = "'0'" 
  v-bind:style="{padding : '0',}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-img  :class="'networkimage imagecolgrid0121 '"   id="imagecolgrid0121" ref="imagecolgrid0121"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "image" 
>  </v-img></div></v-col>  </v-row>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'RelativeItem',

    components: {},
	computed: {
	
		m_message: function() { 		
	if(this.message.length > this.maxLengthDesc){

		return this.message.slice(0, this.maxLengthDesc) + '...'

	}

	return this.message

}
,
	},
	props: {
	
		title: {					required: false,			default: () =>  "Conversations today could lead to opportunity tomorrow",		},
		message: {					required: false,			default: () =>  "Sending messages to people you know is a great way to strengthen relationships as you take the next step in your career.",		},
		image: {					required: false,			default: () =>  "https://static-exp2.licdn.com/aero-v1/sc/h/2r8kd5zqpi905lkzsshdlvvn5",		},
		maxLengthDesc: {					required: false,			default: () =>  300,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#RelativeItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.grid.grid0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

.col.colgrid01{


position:relative !important; 
margin: unset;
transform-origin: top left;



padding:0 !important; 


::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	margin-left: 0px ; 
	
	top: calc(50% + 0px) ; 
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.text.textcolumncolgrid0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		color:#8f5849 ; 
		font-size:24px ; 
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgrid01112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		color:#000000e6 ; 
		font-size:15px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}

.col.colgrid012{


position:relative !important; 
margin: unset;
transform-origin: top left;



padding:0 !important; 


::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.imagecolgrid0121{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:300px!important ; 
	height:300px!important ; 
	
	display: inherit;

	flex: unset;
	border-radius:150px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}


}


}


}


@media (max-width: 600px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid0111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgrid01112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.imagecolgrid0121{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}


}
}

@media (min-width: 601px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid0111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgrid01112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.imagecolgrid0121{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}


}
}

@media (min-width: 961px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid0111{

		
		position:relative !important; 
		
		
		
		
		
		font-size:40px ; 
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgrid01112{

		
		position:relative !important; 
		
		
		
		
		
		font-size:20px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.imagecolgrid0121{

	
	position:relative !important; 
	
	
	
	
	width:450px!important ; 
	height:450px!important ; 
	
	display: inherit;

	flex: unset;
	border-radius:225px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}


}
}

@media (min-width: 1265px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid0111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgrid01112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.imagecolgrid0121{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}


}
}

@media (min-width: 1905px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid0111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgrid01112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.imagecolgrid0121{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}


}
}
}
</style>
