<template>
 <div>  
<div id="AwareIconLink"> 
         
 <a  :href = "link" 
 :class="'link link0 '"   id="link0" ref="link0" :target = "target" 
  v-bind:style="{}">
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <v-card 
 :class="'card cardcolumn01 ' + `elevation-${elevation}`"   id="cardcolumn01" ref="cardcolumn01" :elevation = "elevation" 
 v-on:mouseover="addElevation"  v-on:mouseout="removeElevation"   v-bind:style="{}">
 <div  :class="'icon iconcardcolumn011 '"   id="iconcardcolumn011" ref="iconcardcolumn011"  v-bind:style="{color : primaryColor,fontSize : getUnit(30,'px'),}"><i  :class="icon"></i></div>  </v-card>
 <div  :class="'text textcolumn03 '"   id="textcolumn03" ref="textcolumn03"  v-bind:style="{color : '#00000099',}">
<div class="text-content" :style = "{}">{{ text }}</div>

   </div>  </div>  </a>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'AwareIconLink',

    components: {},
	methods: {
	
		addElevation: function() { 		
	this.elevation = 3

}
,
		removeElevation: function() { 		
	this.elevation = 0

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		elevation:  0		,
		}
	},
	props: {
	
		text: {					required: false,			default: () =>  "Afrotiative Learning",		},
		icon: {					required: false,			default: () =>  "ion-ios-bookmarks",		},
		link: {					required: false,			default: () =>  "/",		},
		target: {					required: false,			default: () =>  "_self",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AwareIconLink{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
}



.link.link0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: block;

.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:8px ; background-color: transparent ; 

	.card.cardcolumn01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	width:40px!important ; 
	height:40px!important ; 
	background-color:#ffffff ; 
	border-radius:4px ; 
	border-style:solid ; 
	border-width:1px ; 
	border-color:#ebebeb ; max-width: initial;min-width: initial;

		.icon.iconcardcolumn011{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		font-size:30px ; 
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcolumn03{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	color:#00000099 ; 
	font-size:12px ; 
	text-align:center;
	display: flex;
	text-overflow: ellipsis ; 
	.text-content{
	width: 100%;
	white-space: nowrap;
	overflow: hidden ;
	text-overflow: ellipsis ; 
	}

	
}


}


}


@media (max-width: 600px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.card.cardcolumn01{

	
	position:relative !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcolumn011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcolumn03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 601px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.card.cardcolumn01{

	
	position:relative !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcolumn011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcolumn03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 961px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.card.cardcolumn01{

	
	position:relative !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcolumn011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcolumn03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1265px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.card.cardcolumn01{

	
	position:relative !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcolumn011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcolumn03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1905px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.card.cardcolumn01{

	
	position:relative !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcolumn011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcolumn03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}
}
</style>
