<template>
 <div>  
<div id="OrgaMembers"> 
         
 <div  :class="'column mainContent2 '"   id="mainContent2" ref="mainContent2"  v-bind:style="{}">
 <div  :class="'flow rowmainContent12 '"   id="rowmainContent12" ref="rowmainContent12"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'row rowrowmainContent14 '"   id="rowrowmainContent14" ref="rowrowmainContent14"  v-bind:style="{}">
 <div  :class="'text textrowmainContent11 '"   id="textrowmainContent11" ref="textrowmainContent11"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ $t('Members.member') }}</div>

   </div>
 <div  :class="'text textrowmainContent112 '"   id="textrowmainContent112" ref="textrowmainContent112"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ foundNumber }}</div>

   </div>  </div>
 <div  :class="'rectangle searchZone '"   id="searchZone" ref="searchZone"  v-bind:style="{backgroundColor : '#e4580a12',}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 :dark = "true" 
 :class="'button buttonsearchZone1 '"   id="buttonsearchZone1" ref="buttonsearchZone1"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-search' !== undefined"  :color = "'#444444'" 
>{{ 'ion-ios-search' }}
</v-icon></v-btn>
 <div  :class="'item itemsearchZone4 '"   id="itemsearchZone4" ref="itemsearchZone4"  v-bind:style="{}">
 <input  type = "text" :class="'textedit texteditsearchZone3 '"   id="texteditsearchZone3" ref="texteditsearchZone3" v-model="filter" :placeholder = "$t('Blog.search')" 
 :outline = "'none'" 
  v-bind:style="{color : '#000',}" >  </div>  </div>  </div>
 <v-row  :class="'grid gridmainContent3 '"   id="gridmainContent3" ref="gridmainContent3"  v-bind:style="{}">
 <v-col  :class="'col colgridmainContent31 '"   id="colgridmainContent31" ref="colgridmainContent31" v-for="(item,index) in filtredMemb" :key="'colgridmainContent31' + index"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
 :sm = "6" 
><div class="col-container">
 <ProfileItem :class="'object profileitemcolgridmainContent311 '"   id="profileitemcolgridmainContent311" ref="profileitemcolgridmainContent311"  v-bind:style="{}" :user = "item" 
>
</ProfileItem></div></v-col>  </v-row>
 <v-pagination 
 :color = "primaryColor" 
 :length = "numberPages" 
 :class="'pagination paginationmainContent53 '"   id="paginationmainContent53" ref="paginationmainContent53" v-if="numberPages" v-model="curPage"  v-bind:style="{}"></v-pagination>  </div>
 
      </div>
 </div> 
</template>
<script>
import ProfileItem from '../../../At/Components/ProfileItem'

import * as Request from '../../../Js/requests'
export default {
	name: 'OrgaMembers',

    components: {ProfileItem,},
	methods: {
	
		getMembers: function() {		
	let config = {

		pagination: {

			page: this.curPage,

			pageSize: this.memberPerPage,

			withCount: true

		},

		populate: {

			organisation: {

				populate: '*'

			},

			user: {

				populate: '*'

			}

		},

	  filters: {

	    organisation: {

	      id: {

					$eq: this.orgaID,

				}

	    },

	  }	

	}

	

	Request.getTableArgs('orga-members', config)

	.then((res) => {

		if(res.data.meta != undefined){	

			this.members = res.data.data

				.map(m => {

					let user = m.attributes.user.data.attributes

					user.id = m.attributes.user.data.id

					user.image = user.image.data?.attributes

					user.bgImage = user.bgImage.data?.attributes

					return user

				});

			

			let pagination = res.data.meta.pagination;

			this.numberPages = pagination.pageCount

		} else {

			this.members = res.data;

		}

		

		this.loading = false;

	}) 

}
,
	},
	mounted(){
			
	this.getMembers()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		filtredMemb: function(){ 		
	if(this.filter){

		return this.members.filter(m => {

			let username = (m.username ?? "").toLowerCase();

			let title = (m.title ?? "").toLowerCase();

			let email = (m.email ?? "").toLowerCase();

			let filter = this.filter.toLowerCase()

			

			return username.includes(filter)

						|| title.includes(filter)

						|| email.includes(filter)

		})

	} else {

		return this.members

	}

}
,
		foundNumber: function() { 		
		return '(' + this.filtredMemb.length + ')'

}
,
	},
	watch: {
	
		curPage: function(val) { 		
	this.getMembers()

}
,
	},
	data(){
	return {
		
		members:  []		,
		filter:  null		,
		loading:  true		,
		memberPerPage:  24		,
		curPage:  1		,
		numberPages:  null		,
		}
	},
	props: {
	
		orgaID: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#OrgaMembers{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.column.mainContent2{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



height:auto!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:50px ; background-color: transparent ; 

.flow.rowmainContent12{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
gap:20px ; 
justify-content:space-between ; background-color:transparent ; 

	.row.rowrowmainContent14{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	width:100%!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.text.textrowmainContent11{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:100%!important ; 
		height:auto!important ; 
		
		color:#444444 ; 
		font-size:35px ; 
		.text-content{
		width: 100%;
		white-space: normal;
		font-weight: bold;
		}

		
}

		.text.textrowmainContent112{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:100%!important ; 
		height:auto!important ; 
		
		color:#444444 ; 
		font-size:35px ; 
		.text-content{
		width: 100%;
		white-space: normal;
		font-weight: bold;
		}

		
}

	
}

	.rectangle.searchZone{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:100%!important ; 
	height:40px!important ; 
	
	background-color:#e4580a12 ; 
	border-radius:20px ; 

		.button.buttonsearchZone1{

		height: auto;
		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left: 5px ; 
		
		top: calc(50% + 0px) ; 
		width:30px!important ; 
		height:30px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.item.itemsearchZone4{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 40px ; 
		
		top: 5px ; 
		right: 20px ; 
		bottom: 5px ; 
		

			.textedit.texteditsearchZone3{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			top: 0px ; 
			bottom: 0px ; 
			width:100%!important ; 
			outline:none !important; 
			
			color:#000 ; 
			font-size:15px ; 
			}

		
}

	
}


}

.grid.gridmainContent3{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridmainContent31{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.profileitemcolgridmainContent311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:250px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationmainContent53{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;
}


}


@media (max-width: 600px) {


.column.mainContent2{


position:relative !important; 









.flow.rowmainContent12{


position:relative !important; 









	.row.rowrowmainContent14{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowmainContent11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowmainContent112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.searchZone{

	
	position:relative !important; 
	
	
	
	
	
	

		.button.buttonsearchZone1{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.item.itemsearchZone4{

		
		position:absolute !important; 
		
		
		
		
		

			.textedit.texteditsearchZone3{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.grid.gridmainContent3{


position:relative !important; 






	.col.colgridmainContent31{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.profileitemcolgridmainContent311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationmainContent53{


position:relative !important; 




}


}
}

@media (min-width: 601px) {


.column.mainContent2{


position:relative !important; 









.flow.rowmainContent12{


position:relative !important; 









	.row.rowrowmainContent14{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowmainContent11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowmainContent112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.searchZone{

	
	position:relative !important; 
	
	
	
	
	
	

		.button.buttonsearchZone1{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.item.itemsearchZone4{

		
		position:absolute !important; 
		
		
		
		
		

			.textedit.texteditsearchZone3{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.grid.gridmainContent3{


position:relative !important; 






	.col.colgridmainContent31{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.profileitemcolgridmainContent311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationmainContent53{


position:relative !important; 




}


}
}

@media (min-width: 961px) {


.column.mainContent2{


position:relative !important; 









.flow.rowmainContent12{


position:relative !important; 









	.row.rowrowmainContent14{

	
	position:relative !important; 
	
	
	
	
	width:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowmainContent11{

		
		position:relative !important; 
		
		
		
		
		width:auto!important ; 
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowmainContent112{

		
		position:relative !important; 
		
		
		
		
		width:auto!important ; 
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.searchZone{

	
	position:relative !important; 
	
	
	
	
	width:490px!important ; 
	
	

		.button.buttonsearchZone1{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.item.itemsearchZone4{

		
		position:absolute !important; 
		
		
		
		
		

			.textedit.texteditsearchZone3{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.grid.gridmainContent3{


position:relative !important; 






	.col.colgridmainContent31{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.profileitemcolgridmainContent311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationmainContent53{


position:relative !important; 




}


}
}

@media (min-width: 1265px) {


.column.mainContent2{


position:relative !important; 









.flow.rowmainContent12{


position:relative !important; 









	.row.rowrowmainContent14{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowmainContent11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowmainContent112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.searchZone{

	
	position:relative !important; 
	
	
	
	
	
	

		.button.buttonsearchZone1{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.item.itemsearchZone4{

		
		position:absolute !important; 
		
		
		
		
		

			.textedit.texteditsearchZone3{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.grid.gridmainContent3{


position:relative !important; 






	.col.colgridmainContent31{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.profileitemcolgridmainContent311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationmainContent53{


position:relative !important; 




}


}
}

@media (min-width: 1905px) {


.column.mainContent2{


position:relative !important; 









.flow.rowmainContent12{


position:relative !important; 









	.row.rowrowmainContent14{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowmainContent11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowmainContent112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.searchZone{

	
	position:relative !important; 
	
	
	
	
	
	

		.button.buttonsearchZone1{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.item.itemsearchZone4{

		
		position:absolute !important; 
		
		
		
		
		

			.textedit.texteditsearchZone3{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.grid.gridmainContent3{


position:relative !important; 






	.col.colgridmainContent31{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.profileitemcolgridmainContent311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationmainContent53{


position:relative !important; 




}


}
}
}
</style>
