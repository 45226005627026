<template>
 <div>  
<div id="main"> 
         
 <div  id="NaN" :class="'applicationwindow NaN '">
 <Home :class="'object homeNaN1 '"   id="homeNaN1" ref="homeNaN1" v-if="showHome"  v-bind:style="{}">
</Home>
 <Feed :class="'object feed '"   id="feed" ref="feed" v-if="showFeed"  v-bind:style="{}">
</Feed>  </div>
 
      </div>
 </div> 
</template>
<script>
import Home from '../At/Pages/Home'
import Feed from '../At/Pages/Feed'


export default {
	name: 'main',

    components: {Home,Feed,},
	computed: {
	
		userData: function() { 		
	return this.$store.state.user

}
,
		showHome: function() { 		
	return (this.userData == null)

}
,
		showFeed: function() { 		
	return (this.userData != null)

}
,
	},
} 
</script>

<style lang = "scss" scoped>


 div#main{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.applicationwindow.NaN{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.homeNaN1{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.feed{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.applicationwindow.NaN{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.homeNaN1{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.feed{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.applicationwindow.NaN{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.homeNaN1{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.feed{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.applicationwindow.NaN{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.homeNaN1{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.feed{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.applicationwindow.NaN{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.homeNaN1{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.feed{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.applicationwindow.NaN{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.homeNaN1{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.feed{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
