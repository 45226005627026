<template>
 <div>  
<div id="ProfileMainSection"> 
         
 <div  :class="'column mainColumn '"   id="mainColumn" ref="mainColumn" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',backgroundColor : '#fff',}">
 <div  :class="'item topItem '"   id="topItem" ref="topItem"  v-bind:style="{}">
 <v-img  :class="'networkimage bgImage '"   id="bgImage" ref="bgImage"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "c_profileBg" 
>  </v-img>
 <div  :class="'flow flowtopItem4 '"   id="flowtopItem4" ref="flowtopItem4" v-if="!watchMode"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#fff'" 
 :fab = "true" 
 @click="pickProfileBg" :class="'button changeBgImageBtn '"   id="changeBgImageBtn" ref="changeBgImageBtn"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-create' !== undefined"  :color = "primaryColor" 
>{{ 'ion-md-create' }}
</v-icon></v-btn>  </div>  </div>
 <div  :class="'column columncolumncolumncoltab013113 '"   id="columncolumncolumncoltab013113" ref="columncolumncolumncoltab013113"  v-bind:style="{}">
 <div  :class="'row rowcolumncolumncolumncoltab0131131 '"   id="rowcolumncolumncolumncoltab0131131" ref="rowcolumncolumncolumncoltab0131131"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'rectangle rectanglerowcolumncolumncolumncoltab01311311 '"   id="rectanglerowcolumncolumncolumncoltab01311311" ref="rectanglerowcolumncolumncolumncoltab01311311" :_alignSelf = "'flex-end'" 
 :_overflow = "'hidden'" 
  v-bind:style="{alignSelf : 'flex-end',overflow : 'hidden',}">
 <v-img  :class="'networkimage avatarImage '"   id="avatarImage" ref="avatarImage"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "c_profileImg" 
>  </v-img>
 <div  :class="'item itemrectanglerowcolumncolumncolumncoltab013113115 '"   id="itemrectanglerowcolumncolumncolumncoltab013113115" ref="itemrectanglerowcolumncolumncolumncoltab013113115" v-if="!watchMode"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitemrectanglerowcolumncolumncolumncoltab0131131151 '"   id="rectangleitemrectanglerowcolumncolumncolumncoltab0131131151" ref="rectangleitemrectanglerowcolumncolumncolumncoltab0131131151"  v-bind:style="{backgroundColor : '#000',}">  </div>
 <div  :class="'icon camIcon2 '"   id="camIcon2" ref="camIcon2"  v-bind:style="{color : '#fff',fontSize : getUnit(25,'px'),}"><i  :class="'ion-ios-camera'"></i></div>  </div>
 <div  :class="'mousearea mousearearectanglerowcolumncolumncolumncoltab013113113 '"   id="mousearearectanglerowcolumncolumncolumncoltab013113113" ref="mousearearectanglerowcolumncolumncolumncoltab013113113" v-if="!watchMode"  v-bind:style="{}" @click="pickProfileImg"></div>  </div>
 <div  :class="'flow flowrowcolumncolumncolumncoltab01311314 '"   id="flowrowcolumncolumncolumncoltab01311314" ref="flowrowcolumncolumncolumncoltab01311314" v-if="!watchMode"  v-bind:style="{}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="showEditModal" :class="'button editProfileBtn '"   id="editProfileBtn" ref="editProfileBtn"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-create' !== undefined" >{{ 'ion-md-create' }}
</v-icon></v-btn>  </div>  </div>
 <v-row  :class="'grid gridcolumncolumncolumncoltab0131133 '"   id="gridcolumncolumncolumncoltab0131133" ref="gridcolumncolumncolumncoltab0131133"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumncolumncoltab01311331 '"   id="colgridcolumncolumncolumncoltab01311331" ref="colgridcolumncolumncolumncoltab01311331"  v-bind:style="{}" :cols = "12" 
 :md = "8" 
><div class="col-container">
 <div  :class="'column columncolgridcolumncolumncolumncoltab013113311 '"   id="columncolgridcolumncolumncolumncoltab013113311" ref="columncolgridcolumncolumncolumncoltab013113311"  v-bind:style="{}">
 <div  :class="'text textcolumncolgridcolumncolumncolumncoltab0131133111 '"   id="textcolumncolgridcolumncolumncolumncoltab0131133111" ref="textcolumncolgridcolumncolumncolumncoltab0131133111"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ userName }}</div>

   </div>
 <div  :class="'text textcolumncolgridcolumncolumncolumncoltab01311331112 '"   id="textcolumncolgridcolumncolumncolumncoltab01311331112" ref="textcolumncolgridcolumncolumncolumncoltab01311331112" v-if="profileTitle"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ profileTitle }}</div>

   </div>
 <div  :class="'text textcolumncolgridcolumncolumncolumncoltab013113311123 '"   id="textcolumncolgridcolumncolumncolumncoltab013113311123" ref="textcolumncolgridcolumncolumncolumncoltab013113311123" v-if="profileCity && profileCountry"  v-bind:style="{color : '#858787',}">
<div class="text-content" :style = "{}">{{ location }}</div>

   </div>
 <ActionLink :class="'object actionlinkcolumncolgridcolumncolumncolumncoltab0131133117 '"   id="actionlinkcolumncolgridcolumncolumncolumncoltab0131133117" ref="actionlinkcolumncolgridcolumncolumncolumncoltab0131133117"  v-bind:style="{}" :action = "showContactModal" 
 :title = "$t('Profile.contactDetails')" 
 :bold = "false" 
 :size = "'18'" 
 :rightIcon = "'ion-ios-expand'" 
>
</ActionLink>
 <ActionLink :class="'object actionlinkcolumncolgridcolumncolumncolumncoltab01311331175 '"   id="actionlinkcolumncolgridcolumncolumncolumncoltab01311331175" ref="actionlinkcolumncolgridcolumncolumncolumncoltab01311331175" v-if="personalLink"  v-bind:style="{}" :action = "openPersonalPage" 
 :title = "$t('Profile.personalPage')" 
 :bold = "false" 
 :size = "'18'" 
 :rightIcon = "'ion-md-open'" 
 :underline = "true" 
>
</ActionLink>  </div></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumncoltab01311333 '"   id="colgridcolumncolumncolumncoltab01311333" ref="colgridcolumncolumncolumncoltab01311333"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
><div class="col-container">
 <div  :class="'column profilePercentage '"   id="profilePercentage" ref="profilePercentage" v-if="!watchMode"  v-bind:style="{}">
 <div  :class="'text textcolumncolgridcolumncolumncolumncoltab0131133311 '"   id="textcolumncolgridcolumncolumncolumncoltab0131133311" ref="textcolumncolgridcolumncolumncolumncoltab0131133311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Profile.percentageCompletion') }}</div>

   </div>
 <div  :class="'item itemcolumncolgridcolumncolumncolumncoltab0131133315 '"   id="itemcolumncolgridcolumncolumncolumncoltab0131133315" ref="itemcolumncolgridcolumncolumncolumncoltab0131133315"  v-bind:style="{}">  <v-progress-circular
         :class="'circularprogress circularprogresscolumncolgridcolumncolumncolumncoltab01311333131 '"   id="circularprogresscolumncolgridcolumncolumncolumncoltab01311333131" ref="circularprogresscolumncolgridcolumncolumncolumncoltab01311333131"  v-bind:style="{}"
         :value = "completionPercentage" 
 :width = "5" 
 :color = "primaryColor" 
></v-progress-circular>
 <div  :class="'text textitemcolumncolgridcolumncolumncolumncoltab01311333153 '"   id="textitemcolumncolgridcolumncolumncolumncoltab01311333153" ref="textitemcolumncolgridcolumncolumncolumncoltab01311333153"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ completionPercentage + ' / 100' }}</div>

   </div>  </div>  </div></div></v-col>  </v-row>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import ActionLink from '../../../At/Utils/ActionLink'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'ProfileMainSection',

    components: {ActionLink,},
	methods: {
	
		saveFile: function(entry, oldfielId, file) { 		
	if(oldfielId){

		Request.deleteFile(oldfielId)

		.then(res => {

			this.saveFileNext(entry, file)

		})

	} else {

			this.saveFileNext(entry, file)

	}

}
,
		saveFileNext: function(entry, file) { 		
	Request.uploadFile(file)

	.then((res) => {

		Request.updateUserDataField(entry, res.data[0].id)

		.then(_ => {

					

			Request.getTable('users/' + this.user.id)

			.then((res) => {

				this.$store.commit('changeUser', res.data)

				this.$awn.success(this.$t('Profile.profileUpdated'))

			})

			

		})

  })

  .catch((error) => {

 		console.log(error);

	});

}
,
		saveProfileBg: function() { 		
	this.saveFile(

		"bgImage",

		this.user.bgImage?.id,

		this.profileBgBlob

	)

}
,
		saveProfileImg: function() {		
	this.saveFile(

		"image",

		this.user.image?.id,

		this.profileImgBlob

	)

}
,
		openPersonalPage: function() { 		
	window.open(this.personalLink, '_blank').focus();

}
,
		beforeUnloadListener: function(event) { 		
  event.preventDefault();

  return event.returnValue = this.$t('Profile.notSaved');

}
,
		addEventListener: function() { 		
   window.addEventListener("beforeunload", this.beforeUnloadListener, {capture: true});

}
,
		removeEventListener: function() { 		
	window.removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});

}
,
		pickProfileBg: function(){		
	Main.selectFile('image/*', false)

	.then((file) => {			

		this.profileBgBlob = file;

		

		let check = Main.checkFilesSize([file], 1)

		

		if(check[0]){	

	  	const reader = new FileReader();

	  	reader.addEventListener('load', (event) => {

	    	this.m_profileBg = event.target.result;

	  	});

	 		reader.readAsDataURL(file); 

			

			this.saveProfileBg()

		} else {

			this.$awn.alert(

				this.$t('Profile.fileTooLarge')

					.replace('{{name}}', check[1])

					.replace('{{maxSize}}', '1')

			)

		}

	});

}
,
		pickProfileImg: function(){		
	Main.selectFile('image/*', false)

	.then((file) => {			

		this.profileImgBlob = file;

		

		let check = Main.checkFilesSize([file], 1)

		

		if(check[0]){	

	  	const reader = new FileReader();

	  	reader.addEventListener('load', (event) => {

	    	this.m_profileImg = event.target.result;

	  	});

	 		reader.readAsDataURL(file); 

			

			this.saveProfileImg()

		} else {

			this.$awn.alert(

				this.$t('Profile.fileTooLarge')

					.replace('{{name}}', check[1])

					.replace('{{maxSize}}', '1')

			)

		}

	});

}
,
	},
	computed: {
	
		completionPercentage: function() { 		
	if(this.user){

		return this.user.completionPercentage

	}

	return 0

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		userName: function() { 		
	if(this.user){

		return this.user.username

	}

	return null

}
,
		profileDescription: function() { 		
	if(this.user){

		return this.user.description

	}

	return null

}
,
		profileTitle: function() { 		
	if(this.user){

		return this.user.title

	}

	return null

}
,
		personalLink: function() { 		
	if(this.user){

		return this.user.personalLink

	}

	return null

}
,
		profileCity: function() { 		
	if(this.user){

		return this.user.city

	}

	return null

}
,
		profileCountry: function() { 		
	if(this.user){

		return this.user.country

	}

	return null

}
,
		c_profileImg: function() { 		
	if(this.m_profileImg){

		return this.m_profileImg

	}

	return this.profileImage

}
,
		profileBg: function() { 		
	if(this.user){

		if(this.user.bgImage){

			let item = this.user.bgImage;

			return Main.getImgUrl(item, 'medium');

		}

		return Main.getRandomImage(1000)

	}

	return Main.getRandomImage(1000)

}
,
		c_profileBg: function() { 		
	if(this.m_profileBg){

		return this.m_profileBg

	}

	return this.profileBg

}
,
		location: function() { 		
	return this.profileCity + ', ' + this.profileCountry

}
,
	},
	data(){
	return {
		
		m_profileBg:  null		,
		profileBgBlob:  null		,
		m_profileImg:  null		,
		profileImgBlob:  null		,
		}
	},
	props: {
	
		user: {					required: false,			default: () =>  null,		},
		watchMode: {					required: false,			default: () =>  false,		},
		profileImage: {					required: false,			default: () =>  null,		},
		showEditModal: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showContactModal: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ProfileMainSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.mainColumn{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
overflow:hidden !important; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
background-color:#fff ; 
border-radius:5px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#cecdcb ; 
padding-bottom:24px ; 

.item.topItem{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



height:125px!important ; 


	.networkimage.bgImage{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.flow.flowtopItem4{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 20px ; 
	right: 20px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: wrap;
	flex: initial;
	gap:10px ; background-color:transparent ; 

		.button.changeBgImageBtn{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:32px!important ; 
		height:32px!important ; 
		
		font-size:15px ; 
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		font-size:15px ; 
		}
		}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
padding-left:24px ; 
padding-right:24px ; 

	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:52px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:space-between ; background-color:transparent ; 

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-bottom: 0px ; 
		width:128px!important ; 
		height:128px!important ; 
		alignSelf:flex-end !important; 
		overflow:hidden !important; 
		
		background-color:#ffffff ; 
		border-radius:100% ; 

			.networkimage.avatarImage{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

			.item.itemrectanglerowcolumncolumncolumncoltab013113115{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			right: 0px ; 
			bottom: 0px ; 
			height:30px!important ; 
			

				.rectangle.rectangleitemrectanglerowcolumncolumncolumncoltab0131131151{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				opacity: 0.7 ;  filter: alpha(opacity=70) ; 
				
				background-color:#000 ; 

				
}

				.icon.camIcon2{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
				
				left: calc(50% + 0px) ; 
				
				top: calc(50% + 0px) ; 
				width:25px!important ; 
				height:25px!important ; 
				
				color:#fff ; 
				font-size:25px ; 
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mousearearectanglerowcolumncolumncolumncoltab013113113{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

		.flow.flowrowcolumncolumncolumncoltab01311314{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:40px!important ; 
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;
		gap:10px ; background-color:transparent ; 

			.button.editProfileBtn{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:40px!important ; 
			height:40px!important ; 
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.grid.gridcolumncolumncolumncoltab0131133{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	background-color: transparent ; 

		.col.colgridcolumncolumncolumncoltab01311331{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridcolumncolumncolumncoltab013113311{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				font-size:25px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				font-size:15px ; 
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#858787 ; 
				font-size:15px ; 
				.text-content{
				width: 100%;
				}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		
}

		.col.colgridcolumncolumncolumncoltab01311333{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.column.profilePercentage{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:10px ; background-color: transparent ; 

				.text.textcolumncolgridcolumncolumncolumncoltab0131133311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				
				font-size:14px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

				.item.itemcolumncolgridcolumncolumncolumncoltab0131133315{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
				
				left: calc(50% + 0px) ; 
				
				width:100px!important ; 
				height:100px!important ; 
				

					.circularprogress.circularprogresscolumncolgridcolumncolumncolumncoltab01311333131{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					top: 0px ; 
					width:100px!important ; 
					height:100px!important ; 
					}

					.text.textitemcolumncolgridcolumncolumncolumncoltab01311333153{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
					
					left: calc(50% + 0px) ; 
					
					top: calc(50% + 0px) ; 
					width:auto!important ; 
					height:auto!important ; 
					
					font-size:12px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

			
}

		
}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 






	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.flow.flowtopItem4{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.button.changeBgImageBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.avatarImage{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.item.itemrectanglerowcolumncolumncolumncoltab013113115{

			
			position:absolute !important; 
			
			
			
			
			

				.rectangle.rectangleitemrectanglerowcolumncolumncolumncoltab0131131151{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.icon.camIcon2{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mousearearectanglerowcolumncolumncolumncoltab013113113{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.flow.flowrowcolumncolumncolumncoltab01311314{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.editProfileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.grid.gridcolumncolumncolumncoltab0131133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumncolumncoltab01311331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridcolumncolumncolumncoltab013113311{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		
}

		.col.colgridcolumncolumncolumncoltab01311333{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.profilePercentage{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumncolgridcolumncolumncolumncoltab0131133315{

				
				position:relative !important; 
				
				
				
				
				

					.circularprogress.circularprogresscolumncolgridcolumncolumncolumncoltab01311333131{

					
					position:absolute !important; 
					
					
					
					
					}

					.text.textitemcolumncolgridcolumncolumncolumncoltab01311333153{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 




height:99px!important ; 


	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.flow.flowtopItem4{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.button.changeBgImageBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.avatarImage{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.item.itemrectanglerowcolumncolumncolumncoltab013113115{

			
			position:absolute !important; 
			
			
			
			
			

				.rectangle.rectangleitemrectanglerowcolumncolumncolumncoltab0131131151{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.icon.camIcon2{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mousearearectanglerowcolumncolumncolumncoltab013113113{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.flow.flowrowcolumncolumncolumncoltab01311314{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.editProfileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.grid.gridcolumncolumncolumncoltab0131133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumncolumncoltab01311331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridcolumncolumncolumncoltab013113311{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		
}

		.col.colgridcolumncolumncolumncoltab01311333{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.profilePercentage{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumncolgridcolumncolumncolumncoltab0131133315{

				
				position:relative !important; 
				
				
				
				
				

					.circularprogress.circularprogresscolumncolgridcolumncolumncolumncoltab01311333131{

					
					position:absolute !important; 
					
					
					
					
					}

					.text.textitemcolumncolgridcolumncolumncolumncoltab01311333153{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 




height:159px!important ; 


	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.flow.flowtopItem4{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.button.changeBgImageBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		width:160px!important ; 
		height:160px!important ; 
		
		

			.networkimage.avatarImage{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.item.itemrectanglerowcolumncolumncolumncoltab013113115{

			
			position:absolute !important; 
			
			
			
			
			

				.rectangle.rectangleitemrectanglerowcolumncolumncolumncoltab0131131151{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.icon.camIcon2{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mousearearectanglerowcolumncolumncolumncoltab013113113{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.flow.flowrowcolumncolumncolumncoltab01311314{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.editProfileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.grid.gridcolumncolumncolumncoltab0131133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumncolumncoltab01311331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridcolumncolumncolumncoltab013113311{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		
}

		.col.colgridcolumncolumncolumncoltab01311333{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.profilePercentage{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumncolgridcolumncolumncolumncoltab0131133315{

				
				position:relative !important; 
				
				
				
				
				

					.circularprogress.circularprogresscolumncolgridcolumncolumncolumncoltab01311333131{

					
					position:absolute !important; 
					
					
					
					
					}

					.text.textitemcolumncolgridcolumncolumncolumncoltab01311333153{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 




height:195px!important ; 


	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.flow.flowtopItem4{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.button.changeBgImageBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.avatarImage{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.item.itemrectanglerowcolumncolumncolumncoltab013113115{

			
			position:absolute !important; 
			
			
			
			
			

				.rectangle.rectangleitemrectanglerowcolumncolumncolumncoltab0131131151{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.icon.camIcon2{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mousearearectanglerowcolumncolumncolumncoltab013113113{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.flow.flowrowcolumncolumncolumncoltab01311314{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.editProfileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.grid.gridcolumncolumncolumncoltab0131133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumncolumncoltab01311331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridcolumncolumncolumncoltab013113311{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		
}

		.col.colgridcolumncolumncolumncoltab01311333{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.profilePercentage{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumncolgridcolumncolumncolumncoltab0131133315{

				
				position:relative !important; 
				
				
				
				
				

					.circularprogress.circularprogresscolumncolgridcolumncolumncolumncoltab01311333131{

					
					position:absolute !important; 
					
					
					
					
					}

					.text.textitemcolumncolgridcolumncolumncolumncoltab01311333153{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 






	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.flow.flowtopItem4{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.button.changeBgImageBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.avatarImage{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.item.itemrectanglerowcolumncolumncolumncoltab013113115{

			
			position:absolute !important; 
			
			
			
			
			

				.rectangle.rectangleitemrectanglerowcolumncolumncolumncoltab0131131151{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.icon.camIcon2{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mousearearectanglerowcolumncolumncolumncoltab013113113{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.flow.flowrowcolumncolumncolumncoltab01311314{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.editProfileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.grid.gridcolumncolumncolumncoltab0131133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumncolumncoltab01311331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridcolumncolumncolumncoltab013113311{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		
}

		.col.colgridcolumncolumncolumncoltab01311333{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.profilePercentage{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridcolumncolumncolumncoltab0131133311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumncolgridcolumncolumncolumncoltab0131133315{

				
				position:relative !important; 
				
				
				
				
				

					.circularprogress.circularprogresscolumncolgridcolumncolumncolumncoltab01311333131{

					
					position:absolute !important; 
					
					
					
					
					}

					.text.textitemcolumncolgridcolumncolumncolumncoltab01311333153{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		
}

	
}


}


}
}
}
</style>
