<template>
 <div>  
<div id="Auth"> 
         
 <MainLayout :class="'object basic0 '"   id="basic0" ref="basic0"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatebasic01 '"   id="slottemplatebasic01" ref="slottemplatebasic01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout0152 '"   id="columnslottemplatedefaultlayout0152" ref="columnslottemplatedefaultlayout0152"  v-bind:style="{backgroundColor : (mqVal({md:'#f3f2ef'}, $mq)),}">
 <ContentWrapper :class="'object contentWrapper2221 '"   id="contentWrapper2221" ref="contentWrapper2221"  v-bind:style="{}" :paddingTop = "56" 
 :paddingBottom = "56" 
 :paddingLeft = "16" 
 :paddingRight = "16" 
 :bgColor = "'#f0ebe8'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <div  :class="'column col211 '"   id="col211" ref="col211" v-if="emailTaken"  v-bind:style="{backgroundColor : '#000000bf',}">
 <div  :class="'rectangle rectanglecol23 '"   id="rectanglecol23" ref="rectanglecol23"  v-bind:style="{backgroundColor : '#f84730',}">
 <div  :class="'icon iconrectanglecol231 '"   id="iconrectanglecol231" ref="iconrectanglecol231"  v-bind:style="{color : '#000',fontSize : getUnit(100,'px'),}"><i  :class="'ion-ios-close-circle'"></i></div>  </div>
 <div  :class="'text textcol12 '"   id="textcol12" ref="textcol12"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ $t('Auth.emailTaken') }}</div>

   </div>  </div></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
export default {
	name: 'Auth',

    components: {MainLayout,ContentWrapper,},
	mounted(){
			
	Request.signinProvider.bind(this)()

	.then(res => {

		const { jwt, user } = res.data

			

		if(this.$store.state.orgaToEnroll){	

			this.$store.commit('changeToken', jwt)

			

			Main.autoErollment(

				user.id,

				_ => {

					this.$store.commit('changeUser', user)

					

					let toRLink = this.$store.state.toRedirectLink

					

					if(toRLink){

						this.$store.commit('saveToRedirectLink', null)

						window.location.href = toRLink

					} else {

						this.$router.push('/')

					}

				}

			)

		} else {

			this.$store.dispatch('login', {user, jwt})

			

			let toRLink = this.$store.state.toRedirectLink

					

			if(toRLink){

				this.$store.commit('saveToRedirectLink', null)

				window.location.href = toRLink

			} else {

				this.$router.push('/')

			}

		}

		

	}).catch(error => {

		let err = JSON.parse(JSON.stringify(error))

		if(err.status == 400){

			this.emailTaken = true

		}

	})

	},
	computed: {
	
		toRLink: function() { 		
	return this.$store.state.toRedirectLink

}
,
	},
	data(){
	return {
		
		emailTaken:  false		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Auth{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.basic0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout0152{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.col211{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
				
				left: calc(50% + 0px) ; 
				
				width:100%!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:24px ; 
				background-color:#000000bf ; 
				padding-top:10px ; 
				padding-bottom:10px ; 
				padding-left:10px ; 
				padding-right:10px ; 

					.rectangle.rectanglecol23{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					width:200px!important ; 
					height:200px!important ; 
					
					background-color:#f84730 ; 
					border-radius:100% ; 

						.icon.iconrectanglecol231{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left: 10px ; 
						
						top: 10px ; 
						right: 10px ; 
						bottom: 10px ; 
						
						color:#000 ; 
						font-size:100px ; 
						display: flex;
						justify-content: center;
						align-items: center;
						i {
						}
}

					
}

					.text.textcol12{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#fff ; 
					line-height:35px ; 
					font-size:35px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout0152{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.col211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.rectangle.rectanglecol23{

					
					position:relative !important; 
					
					
					
					
					
					

						.icon.iconrectanglecol231{

						
						position:absolute !important; 
						
						
						
						
						
						display: flex;
						justify-content: center;
						align-items: center;
						i {
						}
}

					
}

					.text.textcol12{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout0152{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.col211{

				
				position:relative !important; 
				
				
				
				
				width:500px!important ; 
				
				
				
				

					.rectangle.rectanglecol23{

					
					position:relative !important; 
					
					
					
					
					
					

						.icon.iconrectanglecol231{

						
						position:absolute !important; 
						
						
						
						
						
						display: flex;
						justify-content: center;
						align-items: center;
						i {
						}
}

					
}

					.text.textcol12{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout0152{

	
	position:relative !important; 
	
	
	
	
	
	
	
	
	background-color:#f3f2ef ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.col211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				
				padding-top:70px ; 
				padding-bottom:78px ; 
				padding-left:68px ; 
				padding-right:68px ; 

					.rectangle.rectanglecol23{

					
					position:relative !important; 
					
					
					
					
					
					

						.icon.iconrectanglecol231{

						
						position:absolute !important; 
						
						
						
						
						
						display: flex;
						justify-content: center;
						align-items: center;
						i {
						}
}

					
}

					.text.textcol12{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout0152{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.col211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.rectangle.rectanglecol23{

					
					position:relative !important; 
					
					
					
					
					
					

						.icon.iconrectanglecol231{

						
						position:absolute !important; 
						
						
						
						
						
						display: flex;
						justify-content: center;
						align-items: center;
						i {
						}
}

					
}

					.text.textcol12{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout0152{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.col211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.rectangle.rectanglecol23{

					
					position:relative !important; 
					
					
					
					
					
					

						.icon.iconrectanglecol231{

						
						position:absolute !important; 
						
						
						
						
						
						display: flex;
						justify-content: center;
						align-items: center;
						i {
						}
}

					
}

					.text.textcol12{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
