<template>
 <div>  
<div id="ModalSideBar"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#000000',}">
 <div  :class="'mousearea mousearearectangleitem011 '"   id="mousearearectangleitem011" ref="mousearearectangleitem011"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'rectangle dialog ' + ' ' + 'elevation-1'"   id="dialog" ref="dialog" :_borderRadius = "'8px 0 0 8px'" 
 :data-aos = "'fade-left'" 
 :data-aos-delay = "10" 
  v-bind:style="{borderRadius : '8px 0 0 8px',}">
 <div  :class="'row rowdialog1 '"   id="rowdialog1" ref="rowdialog1"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textrowdialog11 '"   id="textrowdialog11" ref="textrowdialog11"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="close" :class="'button buttonrowdialog13 '"   id="buttonrowdialog13" ref="buttonrowdialog13"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-close' !== undefined" >{{ 'ion-md-close' }}
</v-icon></v-btn>  </div>
 <div  :class="'scrollarea scrollareadialog3 '"   id="scrollareadialog3" ref="scrollareadialog3"  v-bind:style="{}">
 <div  :class="'column column02 '"   id="column02" ref="column02"  v-bind:style="{}">
 <div  :class="'slot slotscrollareadialog311 '"   id="slotscrollareadialog311" ref="slotscrollareadialog311"  v-bind:style="{}"><slot  :name = "'content'" 
></slot></div>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ModalSideBar',

    components: {},
	props: {
	
		title: {					required: false,			default: () =>  "Produits",		},
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ModalSideBar{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.7 ;  filter: alpha(opacity=70) ; 

background-color:#000000 ; 

	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.rectangle.dialog{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: 0px ; 
right: 0px ; 
bottom: 0px ; 
z-index:1 ; 
width:80%!important ; 
borderRadius:8px 0 0 8px !important; 

background-color:#ffffff ; 

	.row.rowdialog1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	height:60px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:space-between ; background-color:transparent ; 
	padding-top:16px ; 
	padding-bottom:16px ; 
	padding-left:24px ; 
	padding-right:12px ; 

		.text.textrowdialog11{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		
		font-size:24px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.button.buttonrowdialog13{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:40px!important ; 
		height:40px!important ; 
		
		font-size:30px ; 
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		font-size:30px ; 
		}
		}

	
}

	.scrollarea.scrollareadialog3{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 60px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	overflow:auto ; 
	overflow-x:hidden ; 
	overflow-y:scroll ; background-color:#ffffff ; 

		.column.column02{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 
		padding-top:8px ; 
		padding-bottom:8px ; 
		padding-left:12px ; 
		padding-right:12px ; 

			.slot.slotscrollareadialog311{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.dialog{


position:absolute !important; 







	.row.rowdialog1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowdialog11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowdialog13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.scrollarea.scrollareadialog3{

	
	position:absolute !important; 
	
	
	
	
	

		.column.column02{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotscrollareadialog311{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.dialog{


position:absolute !important; 




width:380px!important ; 



	.row.rowdialog1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowdialog11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowdialog13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.scrollarea.scrollareadialog3{

	
	position:absolute !important; 
	
	
	
	
	

		.column.column02{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotscrollareadialog311{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.dialog{


position:absolute !important; 







	.row.rowdialog1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowdialog11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowdialog13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.scrollarea.scrollareadialog3{

	
	position:absolute !important; 
	
	
	
	
	

		.column.column02{

		
		position:relative !important; 
		
		
		
		
		
		
		
		
		padding-top:16px ; 
		padding-bottom:32px ; 
		padding-left:24px ; 
		padding-right:24px ; 

			.slot.slotscrollareadialog311{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.dialog{


position:absolute !important; 







	.row.rowdialog1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowdialog11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowdialog13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.scrollarea.scrollareadialog3{

	
	position:absolute !important; 
	
	
	
	
	

		.column.column02{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotscrollareadialog311{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.dialog{


position:absolute !important; 







	.row.rowdialog1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowdialog11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowdialog13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.scrollarea.scrollareadialog3{

	
	position:absolute !important; 
	
	
	
	
	

		.column.column02{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotscrollareadialog311{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}


}


}
}
}
</style>
