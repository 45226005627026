<template>
 <div>  
<div id="LearningTopSection"> 
         
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout01512 '"   id="contentwrappercolumnslottemplatedefaultlayout01512" ref="contentwrappercolumnslottemplatedefaultlayout01512" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',}" :bgColor = "'#fff'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column columncolumnslottemplatedefaultlayout01531 '"   id="columncolumnslottemplatedefaultlayout01531" ref="columncolumnslottemplatedefaultlayout01531"  v-bind:style="{}">
 <div  :class="'item itemcolumncolumnslottemplatedefaultlayout015314 '"   id="itemcolumncolumnslottemplatedefaultlayout015314" ref="itemcolumncolumnslottemplatedefaultlayout015314"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagecolumncolumnslottemplatedefaultlayout015313 '"   id="networkimagecolumncolumnslottemplatedefaultlayout015313" ref="networkimagecolumncolumnslottemplatedefaultlayout015313"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "topImg" 
>  </v-img>  </div>
 <div  :class="'column columncolumncolumnslottemplatedefaultlayout01531 '"   id="columncolumncolumnslottemplatedefaultlayout01531" ref="columncolumncolumnslottemplatedefaultlayout01531" :_width = "'unset !important'" 
  v-bind:style="{width : 'unset !important',}">
 <div  :class="'text textcolumncolumncolumnslottemplatedefaultlayout015311 '"   id="textcolumncolumncolumnslottemplatedefaultlayout015311" ref="textcolumncolumncolumnslottemplatedefaultlayout015311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ motivationText }}</div>

   </div>
 <div  :class="'flow flowcolumncolumncolumnslottemplatedefaultlayout015313 '"   id="flowcolumncolumncolumnslottemplatedefaultlayout015313" ref="flowcolumncolumncolumnslottemplatedefaultlayout015313"  v-bind:style="{}">
 <div  :class="'row rowflowcolumncolumncolumnslottemplatedefaultlayout0153131 '"   id="rowflowcolumncolumncolumnslottemplatedefaultlayout0153131" ref="rowflowcolumncolumncolumnslottemplatedefaultlayout0153131"  v-bind:style="{}">
 <div  :class="'item itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311 '"   id="itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311" ref="itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311" v-for="(item,index) in users" :key="'itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311' + index"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111 '"   id="rectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111" ref="rectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',}">
 <v-img  :class="'networkimage networkimagerectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111 '"   id="networkimagerectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111" ref="networkimagerectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "profileImage(item)" 
>  </v-img>  </div>  </div>  </div>
 <div  :class="'flow flowrowflowcolumncolumncolumnslottemplatedefaultlayout015313142 '"   id="flowrowflowcolumncolumncolumnslottemplatedefaultlayout015313142" ref="flowrowflowcolumncolumncolumnslottemplatedefaultlayout015313142"  v-bind:style="{}">
 <div  :class="'text invitationText '"   id="invitationText" ref="invitationText"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ invitationUser }}</div>

   </div>
 <div  :class="'text invitationText2 '"   id="invitationText2" ref="invitationText2"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ invitationPhrase }}</div>

   </div>  </div>  </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 @click="openTab" :class="'button actionBtn '"   id="actionBtn" ref="actionBtn"  v-bind:style="{}"><span>{{ $t('Feed.startAction') }}</span>
</v-btn>
 <a  :href = "'https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%252B4915736496667%26fbclid%3DIwAR38vgThcs2OQ9T-T0ILlomoi3N4zl9rqK0Lf41SSiJyUTe3LYy3cCV0L78&h=AT2-fvwCSNlRu9rJllV4YcX4REpNlHUBMBP2uEB7r-kSbca-pchFKAh41tFxLZzASix-yBcUMxEiFCbu4sS2BQiSpLUz_kK007LyanLCqBo4YchTXkSYV6Vd7F9ZDsrpxZQWOcG_vVOgcrW6FjrVyOoiMug'" 
 :class="'link routerlinkcolumncolumncolumnslottemplatedefaultlayout015317 ' + ' ' + 'link'"   id="routerlinkcolumncolumncolumnslottemplatedefaultlayout015317" ref="routerlinkcolumncolumncolumnslottemplatedefaultlayout015317" :target = "'_blank'" 
  v-bind:style="{}">
 <div  :class="'text mentorText '"   id="mentorText" ref="mentorText"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('Feed.mentorAtion') }}</div>

   </div>  </a>  </div>  </div></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../../At/Utils/ContentWrapper'

import * as Request from '../../../Js/requests'
import * as Main from '../../../Js/main'
export default {
	name: 'LearningTopSection',

    components: {ContentWrapper,},
	methods: {
	
		profileImage: function(user) { 		
	if(user){

		if(user.image){

			let item = user.image;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(user.username)

	}

	return "https://picsum.photos/300"

}
,
		get3Users: function() { 		
	let config = {

		limit: 3,

		_start: 0,

		sort: "id:desc",

		filters: {

			id: {

				$ne: this.user.id

			}

		}

	}

	

	if(this.user){	

		config.filters = {

			id: {

				$ne: this.user.id

			}

		}

	}

	

	Request.getTableArgs('users', config)

	.then((res) => {

		this.users = res.data

	})

}
,
		openTab: function() { 		
	Main.openInNewTab(this.$store.state.learningUrl)

}
,
	},
	mounted(){
			
	this.get3Users()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		motivationText: function() { 		
	if(this.user){	

		return this.user.username + ', ' + this.$t('Learning.motivationText')

	}

	return this.$t('Learning.motivationText')

}
,
		invitationUser: function() { 		
	if(this.users && this.users[0]){

		return this.users[0].username

	}

	return ''

}
,
		invitationPhrase: function() { 		
	if(this.users && this.users[0]){

		return ' ' + this.$t('Feed.invitationPhrase')

	}

	return this.$t('Feed.invitationPhrase2')

}
,
	},
	data(){
	return {
		
		users:  []		,
		topImg:  "https://static-exp2.licdn.com/sc/h/cch95u9n3wpbme2zzh5n4uwg0"		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#LearningTopSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
overflow:hidden !important; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnslottemplatedefaultlayout01531{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 
	padding-top:48px ; 
	padding-bottom:48px ; 
	padding-left:12px ; 
	padding-right:12px ; 

		.item.itemcolumncolumnslottemplatedefaultlayout015314{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		right: 0px ; 
		display:none !important; 
		width:400px!important ; 
		height:400px!important ; 
		

			.networkimage.networkimagecolumncolumnslottemplatedefaultlayout015313{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			max-width: 600px!important ; 
			max-height: 575px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width:unset !important !important; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:14px ; background-color: transparent ; 

			.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:20px ; 
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015313{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: wrap;
			flex: initial;
			gap:14px ; background-color:transparent ; 

				.row.rowflowcolumncolumncolumnslottemplatedefaultlayout0153131{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:24px!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				gap:0px ; background-color:transparent ; 

					.item.itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:15px!important ; 
					height:40px!important ; 
					

						.rectangle.rectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						
						width:24px!important ; 
						height:24px!important ; 
						overflow:hidden !important; 
						
						background-color:#ffffff ; 
						border-radius:12px ; 

							.networkimage.networkimagerectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

							
							position:absolute !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							left: 0px ; 
							
							top: 0px ; 
							right: 0px ; 
							bottom: 0px ; 
							
							display: inherit;

							flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

							
}

						
}

					
}

				
}

				.flow.flowrowflowcolumncolumncolumnslottemplatedefaultlayout015313142{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: wrap;
				flex: initial;
				gap:5px ; background-color:transparent ; 

					.text.invitationText{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:24px!important ; 
					
					align-items:center;
					display: flex;
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

					.text.invitationText2{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:24px!important ; 
					
					align-items:center;
					display: flex;
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.actionBtn{

			height: auto;
			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:48px!important ; 
			
			border-radius:0px ; 
			font-size:10px ; 
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			font-size:10px ; 
			}
			}

			.link.routerlinkcolumncolumncolumnslottemplatedefaultlayout015317{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			target:_blank !important; 
			
			display: block;

				.text.mentorText{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnslottemplatedefaultlayout01531{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncolumnslottemplatedefaultlayout015314{

		
		position:absolute !important; 
		
		
		
		
		

			.networkimage.networkimagecolumncolumnslottemplatedefaultlayout015313{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015313{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.rowflowcolumncolumncolumnslottemplatedefaultlayout0153131{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.item.itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311{

					
					position:relative !important; 
					
					
					
					
					

						.rectangle.rectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

						
						position:absolute !important; 
						
						
						
						
						
						

							.networkimage.networkimagerectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

							
							position:absolute !important; 
							
							
							
							
							
							display: inherit;

							flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							
}

						
}

					
}

				
}

				.flow.flowrowflowcolumncolumncolumnslottemplatedefaultlayout015313142{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.invitationText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.invitationText2{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.actionBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.link.routerlinkcolumncolumncolumnslottemplatedefaultlayout015317{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.mentorText{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnslottemplatedefaultlayout01531{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncolumnslottemplatedefaultlayout015314{

		
		position:absolute !important; 
		
		
		
		
		

			.networkimage.networkimagecolumncolumnslottemplatedefaultlayout015313{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015313{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.rowflowcolumncolumncolumnslottemplatedefaultlayout0153131{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.item.itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311{

					
					position:relative !important; 
					
					
					
					
					

						.rectangle.rectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

						
						position:absolute !important; 
						
						
						
						
						
						

							.networkimage.networkimagerectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

							
							position:absolute !important; 
							
							
							
							
							
							display: inherit;

							flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							
}

						
}

					
}

				
}

				.flow.flowrowflowcolumncolumncolumnslottemplatedefaultlayout015313142{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.invitationText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.invitationText2{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.actionBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			font-size:20px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:20px ; 
			}
			}

			.link.routerlinkcolumncolumncolumnslottemplatedefaultlayout015317{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.mentorText{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnslottemplatedefaultlayout01531{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncolumnslottemplatedefaultlayout015314{

		
		position:absolute !important; 
		
		
		
		
		 display:block !important; 
		

			.networkimage.networkimagecolumncolumnslottemplatedefaultlayout015313{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		margin-right: 385px ; 
		
		
		
		

			.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

			
			position:relative !important; 
			
			
			
			
			
			font-size:30px ; 
			.text-content{
			width: 100%;
			}

			
}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015313{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.rowflowcolumncolumncolumnslottemplatedefaultlayout0153131{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.item.itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311{

					
					position:relative !important; 
					
					
					
					
					

						.rectangle.rectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

						
						position:absolute !important; 
						
						
						
						
						
						

							.networkimage.networkimagerectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

							
							position:absolute !important; 
							
							
							
							
							
							display: inherit;

							flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							
}

						
}

					
}

				
}

				.flow.flowrowflowcolumncolumncolumnslottemplatedefaultlayout015313142{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.invitationText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.invitationText2{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.actionBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.link.routerlinkcolumncolumncolumnslottemplatedefaultlayout015317{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.mentorText{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnslottemplatedefaultlayout01531{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncolumnslottemplatedefaultlayout015314{

		
		position:absolute !important; 
		
		
		
		
		

			.networkimage.networkimagecolumncolumnslottemplatedefaultlayout015313{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015313{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.rowflowcolumncolumncolumnslottemplatedefaultlayout0153131{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.item.itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311{

					
					position:relative !important; 
					
					
					
					
					

						.rectangle.rectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

						
						position:absolute !important; 
						
						
						
						
						
						

							.networkimage.networkimagerectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

							
							position:absolute !important; 
							
							
							
							
							
							display: inherit;

							flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							
}

						
}

					
}

				
}

				.flow.flowrowflowcolumncolumncolumnslottemplatedefaultlayout015313142{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.invitationText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.invitationText2{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.actionBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.link.routerlinkcolumncolumncolumnslottemplatedefaultlayout015317{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.mentorText{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnslottemplatedefaultlayout01531{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncolumnslottemplatedefaultlayout015314{

		
		position:absolute !important; 
		
		
		
		
		

			.networkimage.networkimagecolumncolumnslottemplatedefaultlayout015313{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015313{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.rowflowcolumncolumncolumnslottemplatedefaultlayout0153131{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.item.itemrowflowcolumncolumncolumnslottemplatedefaultlayout01531311{

					
					position:relative !important; 
					
					
					
					
					

						.rectangle.rectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

						
						position:absolute !important; 
						
						
						
						
						
						

							.networkimage.networkimagerectanglerowflowcolumncolumncolumnslottemplatedefaultlayout015313111{

							
							position:absolute !important; 
							
							
							
							
							
							display: inherit;

							flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							
}

						
}

					
}

				
}

				.flow.flowrowflowcolumncolumncolumnslottemplatedefaultlayout015313142{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.invitationText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.invitationText2{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.actionBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.link.routerlinkcolumncolumncolumnslottemplatedefaultlayout015317{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.mentorText{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
