<template>
 <div>  
<div id="ImageButton"> 
         
 <div  :class="'rectangle rec0 '"   id="rec0" ref="rec0"  v-bind:style="{backgroundColor : bgColor,}">
 <v-img  :class="'image img '"   id="img" ref="img"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + 'Resources' + src + '')" 
>  </v-img>
 <a  :href = "link" 
 :class="'link linkrec03 '"   id="linkrec03" ref="linkrec03" :target = "'_blanck'" 
  v-bind:style="{}">  </a>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ImageButton',

    components: {},
	props: {
	
		bgColor: {					required: false,			default: () =>  "#6d6d6d",		},
		color: {					required: false,			default: () =>  "#b6b6b6",		},
		src: {					required: false,			default: () =>  "Resources/social/facebook.png",		},
		link: {					required: false,			default: () =>  "/",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ImageButton{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.rec0{
	cursor: pointer;
	
	&::hover{
		opacity: 0.7
	}
}



.rectangle.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 
border-radius:100% ; 

.image.img{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 5px ; 

top: 5px ; 
right: 5px ; 
bottom: 5px ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}


}

.link.linkrec03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
target:_blanck !important; 

display: block;


}


}


@media (max-width: 600px) {


.rectangle.rec0{


position:absolute !important; 







.image.img{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.link.linkrec03{


position:absolute !important; 





display: block;


}


}
}

@media (min-width: 601px) {


.rectangle.rec0{


position:absolute !important; 







.image.img{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.link.linkrec03{


position:absolute !important; 





display: block;


}


}
}

@media (min-width: 961px) {


.rectangle.rec0{


position:absolute !important; 







.image.img{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.link.linkrec03{


position:absolute !important; 





display: block;


}


}
}

@media (min-width: 1265px) {


.rectangle.rec0{


position:absolute !important; 







.image.img{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.link.linkrec03{


position:absolute !important; 





display: block;


}


}
}

@media (min-width: 1905px) {


.rectangle.rec0{


position:absolute !important; 







.image.img{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.link.linkrec03{


position:absolute !important; 





display: block;


}


}
}
}
</style>
