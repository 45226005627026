<template>
 <div>  
<div id="RichText"> 
         
 <div  :class="'column container '"   id="container" ref="container" v-html="parsedText"  v-bind:style="{}">  </div>
 
      </div>
 </div> 
</template>
<script>

import {marked} from 'marked'
export default {
	name: 'RichText',

    components: {},
	computed: {
	
		parsedText: function() { 		
	if(this.value){

		return marked.parse(this.value) 

	}

	return "" 

}
,
	},
	props: {
	
		value: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#RichText{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

@import '../../Style/markdown10.css';



.column.container{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 


}


@media (max-width: 600px) {


.column.container{


position:relative !important; 










}
}

@media (min-width: 601px) {


.column.container{


position:relative !important; 










}
}

@media (min-width: 961px) {


.column.container{


position:relative !important; 










}
}

@media (min-width: 1265px) {


.column.container{


position:relative !important; 










}
}

@media (min-width: 1905px) {


.column.container{


position:relative !important; 










}
}
}
</style>
