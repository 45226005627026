<template>
 <div>  
<div id="AccordillonItem"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'column columncolumn01 '"   id="columncolumn01" ref="columncolumn01" v-on:click="toggel"  :cursor = "'pointer'" 
  v-bind:style="{backgroundColor : primaryColor,}">
 <div  :class="'text question '"   id="question" ref="question"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ question }}</div>

   </div>
 <v-btn tag="div" 
 :fab = "true" 
 :onlyIcon = "true" 
 :text = "true" 
 :dark = "true" 
 :class="'button buttoncolumncolumn013 '"   id="buttoncolumncolumn013" ref="buttoncolumncolumn013"  v-bind:style="{}"><span v-if = "(!true) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "icon !== undefined"  :color = "'#fff'" 
>{{ icon }}
</v-icon></v-btn>  </div>
 <div  :class="'transition transitioncolumn04 '"   id="transitioncolumn04" ref="transitioncolumn04"  v-bind:style="{}">
 <transition  :enter-active-class = "'animated slideInRight'" 
 :leave-active-class = "'animated slideOutRight'" 
>
 <div  :class="'column columncolumn012 '"   id="columncolumn012" ref="columncolumn012" v-show="opened"  v-bind:style="{borderColor : primaryColor,}">
 <RichText :class="'object question '"   id="question" ref="question"  v-bind:style="{}" :value = "response" 
>
</RichText>  </div>  </transition></div>  </div>
 
      </div>
 </div> 
</template>
<script>
import RichText from '../../At/Utils/RichText'


export default {
	name: 'AccordillonItem',

    components: {RichText,},
	methods: {
	
		toggel: function() { 		
	this.opened = !this.opened

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		icon: function() { 		
	return (this.opened? 'ion-ios-close' : 'ion-ios-add')

}
,
	},
	data(){
	return {
		
		opened:  false		,
		}
	},
	props: {
	
		question: {					required: false,			default: () =>  "...",		},
		response: {					required: false,			default: () =>   "...",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AccordillonItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:1px ; background-color: transparent ; 

.column.columncolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
cursor:pointer !important; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
padding-top:16px ; 
padding-bottom:16px ; 
padding-left:24px ; 
padding-right:44px ; 

	.text.question{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	color:#fff ; 
	font-size:15px ; 
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	right: 10px ; 
	width:40px!important ; 
	height:40px!important ; 
	
	font-size:55px ; 
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	font-size:55px ; 
	}
	}


}

.transition.transitioncolumn04{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;


	.column.columncolumn012{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 
	padding-top:16px ; 
	padding-bottom:16px ; 
	padding-left:16px ; 
	padding-right:16px ; 

		.object.question{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.question{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.transition.transitioncolumn04{


position:relative !important; 






	.column.columncolumn012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.question{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.question{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.transition.transitioncolumn04{


position:relative !important; 






	.column.columncolumn012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.question{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 








padding-top:20px ; 
padding-bottom:20px ; 
padding-left:31px ; 
padding-right:57px ; 

	.text.question{

	
	position:relative !important; 
	
	
	
	
	
	font-size:18px ; 
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.transition.transitioncolumn04{


position:relative !important; 






	.column.columncolumn012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	
	padding-top:20px ; 
	padding-bottom:20px ; 
	padding-left:20px ; 
	padding-right:20px ; 

		.object.question{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.question{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.transition.transitioncolumn04{


position:relative !important; 






	.column.columncolumn012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.question{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.question{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.transition.transitioncolumn04{


position:relative !important; 






	.column.columncolumn012{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.question{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
