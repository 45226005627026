<template>
 <div>  
<div id="AwareLink"> 
         
 <a  :href = "link" 
 :class="'link link0 '"   id="link0" ref="link0" v-ripple = "true"  v-bind:style="{}">
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'text textcolumn01 '"   id="textcolumn01" ref="textcolumn01"  v-bind:style="{color : '#000000',}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <div  :class="'text textcolumn012 '"   id="textcolumn012" ref="textcolumn012"  v-bind:style="{color : '#000000',}">
<div class="text-content" :style = "{}">{{ subTitle }}</div>

   </div>  </div>  </a>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'AwareLink',

    components: {},
	props: {
	
		title: {					required: false,			default: () =>  "Talent Solutions",		},
		subTitle: {					required: false,			default: () =>  "Trouvez, attirez et recrutez les meilleurs talents",		},
		link: {					required: false,			default: () =>  "/",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AwareLink{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
}



.link.link0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: block;

.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.text.textcolumn01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	color:#000000 ; 
	font-size:15px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.text.textcolumn012{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	color:#000000 ; 
	font-size:13px ; 
	.text-content{
	width: 100%;
	}

	
}


}


}


@media (max-width: 600px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.text.textcolumn01{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcolumn012{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 601px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.text.textcolumn01{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcolumn012{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 961px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.text.textcolumn01{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcolumn012{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1265px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.text.textcolumn01{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcolumn012{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1905px) {


.link.link0{


position:relative !important; 





display: block;

.column.column0{


position:relative !important; 









	.text.textcolumn01{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcolumn012{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}
}
</style>
