<template>
 <div>  
<div id="LDCourseOverviewChapter"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0" :_borderBottom = "'solid 1px #d7d7d7'" 
  v-bind:style="{borderBottom : 'solid 1px #d7d7d7',}">
 <div  :class="'row rowcolumn02 '"   id="rowcolumn02" ref="rowcolumn02"  v-bind:style="{}">
 <div  :class="'row linkrowcolumn024 '"   id="linkrowcolumn024" ref="linkrowcolumn024" :target = "'blank'" 
 :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <v-btn tag="div" 
 :color = "'#ed918a'" 
 :fab = "true" 
 :dark = "true" 
 @click="m_deleteChapter" :class="'button buttonrowcolumn02303 '"   id="buttonrowcolumn02303" ref="buttonrowcolumn02303" v-if="editMode"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-trash' !== undefined" >{{ 'ion-md-trash' }}
</v-icon></v-btn>
 <div  :class="'text text '"   id="text" ref="text" v-if="!editMode"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <input  type = "text" :class="'textedit textEdit '"   id="textEdit" ref="textEdit" v-if="editMode" v-model="m_title" :_flex = "'1'" 
  v-bind:style="{flex : '1',}" >  </div>
 <div  :class="'row rowrowcolumn025 '"   id="rowrowcolumn025" ref="rowrowcolumn025"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :fab = "true" 
 :dark = "true" 
 @click="m_editChapter" :class="'button buttonrowcolumn0230 '"   id="buttonrowcolumn0230" ref="buttonrowcolumn0230" v-if="changed"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-save' !== undefined" >{{ 'ion-ios-save' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :fab = "true" 
 @click="m_addModule" :class="'button buttonrowcolumn0231 '"   id="buttonrowcolumn0231" ref="buttonrowcolumn0231" v-if="editMode"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-add' !== undefined" >{{ 'ion-ios-add' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="togleExtend" :class="'button buttonrowcolumn0232 '"   id="buttonrowcolumn0232" ref="buttonrowcolumn0232"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "extended ? 'ion-ios-arrow-up' : 'ion-ios-arrow-down' !== undefined" >{{ extended ? 'ion-ios-arrow-up' : 'ion-ios-arrow-down' }}
</v-icon></v-btn>  </div>  </div>
 <div  :class="'column columncolumn03 '"   id="columncolumn03" ref="columncolumn03" v-if="extended"  v-bind:style="{}">
 <LDCourseOverviewModule :class="'object ldcourseoverviewmodulecolumncolumn031 '"   id="ldcourseoverviewmodulecolumncolumn031" ref="ldcourseoverviewmodulecolumncolumn031" v-for="(item2, index2) in modules" :key="'ldcourseoverviewmodulecolumncolumn031' + index2"  v-bind:style="{}" :editModule = "editModule" 
 :deleteModule = "deleteModule" 
 :moduleInfo = "item2" 
 :editMode = "editMode" 
 :pIndex = "index" 
 :index = "index2" 
>
</LDCourseOverviewModule>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import LDCourseOverviewModule from '../../../At/Components/Learning/LDCourseOverviewModule'


export default {
	name: 'LDCourseOverviewChapter',

    components: {LDCourseOverviewModule,},
	methods: {
	
		togleExtend: function() { 		
	this.extended = !this.extended

}
,
		m_addModule: function() { 		
	this.addModule(this.index)

}
,
		m_editChapter: function() { 		
	this.editChapter(this.index, this.m_title)

	this.changed = false

}
,
		m_deleteChapter: function() { 		
	this.deleteChapter(this.index)

}
,
	},
	mounted(){
			
	this.m_title = this.title

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		title: function() { 		
	if(this.chapterInfo){

		return this.chapterInfo.name

	}

	return "..." 

}
,
		modules: function() { 		
	if(this.chapterInfo){	

		if(this.chapterInfo.modules){

			return 	this.chapterInfo.modules

		}

	}

	return []

}
,
		url: function() { 		
	if(this.courseURL){

		return this.courseURL + '#section-' 

			+ this.chapterInfo.section

	}

	return null

}
,
	},
	watch: {
	
		m_title: function(val, old) { 		
	if(old){

		this.changed = true

	}

}
,
	},
	data(){
	return {
		
		extended:  true		,
		changed:  false		,
		m_title:  null		,
		}
	},
	props: {
	
		index: {					required: false,			default: () =>  0,		},
		chapterInfo: {					required: false,			default: () =>  null,		},
		courseURL: {					required: false,			default: () =>  null,		},
		editMode: {					required: false,			default: () =>  false,		},
		editChapter: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		deleteChapter: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		editModule: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		addModule: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		deleteModule: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LDCourseOverviewChapter{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none !important;
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
borderBottom:solid 1px #d7d7d7 !important; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:8px ; background-color: transparent ; 
padding-top:24px ; 
padding-bottom:8px ; 
padding-left:24px ; 
padding-right:24px ; 

.row.rowcolumn02{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:8px ; background-color:transparent ; 

	.row.linkrowcolumn024{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:auto!important ; 
	target:blank !important; 
	flex:1 !important; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:5px ; background-color:transparent ; 

		.button.buttonrowcolumn02303{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:30px!important ; 
		height:30px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.text{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		
		font-size:20px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.textedit.textEdit{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		flex:1 !important; 
		
		font-size:20px ; 
		font-weight: bold;
		}

	
}

	.row.rowrowcolumn025{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.button.buttonrowcolumn0230{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:30px!important ; 
		height:30px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0231{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:30px!important ; 
		height:30px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0232{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:30px!important ; 
		height:30px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; background-color: transparent ; 

	.object.ldcourseoverviewmodulecolumncolumn031{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.row.rowcolumn02{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.linkrowcolumn024{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn02303{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.text{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textEdit{

		
		position:relative !important; 
		
		
		
		
		
		}

	
}

	.row.rowrowcolumn025{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0230{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0231{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0232{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.object.ldcourseoverviewmodulecolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.row.rowcolumn02{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.linkrowcolumn024{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn02303{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.text{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textEdit{

		
		position:relative !important; 
		
		
		
		
		
		}

	
}

	.row.rowrowcolumn025{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0230{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0231{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0232{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.object.ldcourseoverviewmodulecolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.row.rowcolumn02{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.linkrowcolumn024{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn02303{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.text{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textEdit{

		
		position:relative !important; 
		
		
		
		
		
		}

	
}

	.row.rowrowcolumn025{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0230{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0231{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0232{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.object.ldcourseoverviewmodulecolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.row.rowcolumn02{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.linkrowcolumn024{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn02303{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.text{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textEdit{

		
		position:relative !important; 
		
		
		
		
		
		}

	
}

	.row.rowrowcolumn025{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0230{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0231{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0232{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.object.ldcourseoverviewmodulecolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.row.rowcolumn02{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.linkrowcolumn024{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn02303{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.text{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textEdit{

		
		position:relative !important; 
		
		
		
		
		
		}

	
}

	.row.rowrowcolumn025{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumn0230{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0231{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcolumn0232{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.object.ldcourseoverviewmodulecolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}
}
</style>
