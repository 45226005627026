<template>
 <div>  
<div id="Mentors"> 
         
 <SearchPage :class="'object searchpage0 '"   id="searchpage0" ref="searchpage0"  v-bind:style="{}" :visitedEntity = "'mentors'" 
 :onlySelectedEntity = "true" 
>
</SearchPage>
 
      </div>
 </div> 
</template>
<script>
import SearchPage from '../../At/Sections/SearchPage'


export default {
	name: 'Mentors',

    components: {SearchPage,},
	metaInfo(){
			
	return {

		title: this.companyName + " | Mentors",

		meta: [		

	   { name: 'description', content: this.$t('Mentors.description')},

	

	   { property: 'og:title', content: this.companyName + " | Mentors"},

	   { property: 'og:description', content: this.$t('Mentors.description')},

	   { property: 'og:type', content: 'website'},

	   { property: 'og:url', content: this.$store.state.webUrl + '/mentors'},

	

	   { name: 'twitter:title', content: this.companyName + " | Mentors"},

	   { name: 'twitter:description', content: this.$t('Search.description')},

	   { name: 'twitter:card', content: 'website'},

	   { name: 'twitter:url', content: this.$store.state.webUrl + '/mentors'}

	  ]

	}

	},
	computed: {
	
		companyName: function() { 		
	return this.$store.state.companyName

}
,
	},
} 
</script>

<style lang = "scss" scoped>


 div#Mentors{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.object.searchpage0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


@media (max-width: 600px) {


.object.searchpage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 601px) {


.object.searchpage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 961px) {


.object.searchpage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1265px) {


.object.searchpage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1905px) {


.object.searchpage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}
}
</style>
