<template>
 <div>  
<div id="OrgaCardMember"> 
         
 <div  :class="'item meberCard '"   id="meberCard" ref="meberCard"  v-bind:style="{}">
 <div  :class="'item meberCard-inner '"   id="meberCard-inner" ref="meberCard-inner"  v-bind:style="{}">
 <v-card 
 :class="'card front ' + `elevation-${2}`"   id="front" ref="front" :elevation = "2" 
 :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',backgroundImage: 'linear-gradient(' + 0 + 'deg, ' +'#ff2c32'+ ',' +'#ffad70' + ')' , }">
 <div  :class="'rectangle rectanglecard095 '"   id="rectanglecard095" ref="rectanglecard095"  v-bind:style="{backgroundColor : '#000',}">  </div>
 <v-img  :class="'networkimage networkimagecard07 '"   id="networkimagecard07" ref="networkimagecard07"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "orgaLogo" 
>  </v-img>
 <div  :class="'rectangle rectanglecard096 '"   id="rectanglecard096" ref="rectanglecard096"  v-bind:style="{backgroundColor : '#000',}">  </div>
 <v-img  :class="'networkimage networkimagecard01 '"   id="networkimagecard01" ref="networkimagecard01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "qrCode" 
>  </v-img>
 <div  :class="'text textcard03 '"   id="textcard03" ref="textcard03"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ companyName }}</div>

   </div>
 <div  :class="'column columncard07 '"   id="columncard07" ref="columncard07"  v-bind:style="{}">
 <div  :class="'text textcard0331 '"   id="textcard0331" ref="textcard0331"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ organisation.name }}</div>

   </div>
 <div  :class="'row rowcolumncard073 '"   id="rowcolumncard073" ref="rowcolumncard073"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'column columnrowcolumncard0731 '"   id="columnrowcolumncard0731" ref="columnrowcolumncard0731"  v-bind:style="{}">
 <div  :class="'text textcolumnrowcolumncard07311 '"   id="textcolumnrowcolumncard07311" ref="textcolumnrowcolumncard07311"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ $t('Profile.cardHolder') }}</div>

   </div>
 <div  :class="'text textcolumnrowcolumncard073112 '"   id="textcolumnrowcolumncard073112" ref="textcolumnrowcolumncard073112"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ user.username }}</div>

   </div>  </div>
 <div  :class="'column columnrowcolumncard07312 '"   id="columnrowcolumncard07312" ref="columnrowcolumncard07312"  v-bind:style="{justifyContent : 'flex-end',}">
 <div  :class="'text textcolumnrowcolumncard07311 '"   id="textcolumnrowcolumncard07311" ref="textcolumnrowcolumncard07311"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ $t('Profile.memberSince') }}</div>

   </div>
 <div  :class="'text textcolumnrowcolumncard073112 '"   id="textcolumnrowcolumncard073112" ref="textcolumnrowcolumncard073112"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ publishedDate }}</div>

   </div>  </div>  </div>  </div>  </v-card>
 <v-card 
 :class="'card back ' + `elevation-${2}`"   id="back" ref="back" :elevation = "2" 
 :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',backgroundImage: 'linear-gradient(' + 0 + 'deg, ' +'#ff2c32'+ ',' +'#ffad70' + ')' , }">
 <div  :class="'rectangle rectanglecard095 '"   id="rectanglecard095" ref="rectanglecard095"  v-bind:style="{backgroundColor : '#000',}">  </div>
 <v-img  :class="'networkimage networkimagecard07 '"   id="networkimagecard07" ref="networkimagecard07"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "orgaLogo" 
>  </v-img>
 <div  :class="'rectangle rectangleback9 '"   id="rectangleback9" ref="rectangleback9"  v-bind:style="{backgroundColor : '#000',}">  </div>
 <div  :class="'text textcard036 '"   id="textcard036" ref="textcard036"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ companyName }}</div>

   </div>
 <div  :class="'item itemback9 '"   id="itemback9" ref="itemback9"  v-bind:style="{}">
 <v-img  :class="'image imageitemback91 '"   id="imageitemback91" ref="imageitemback91"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../../Resources/OrgaCardMember/pattern.jpg')" 
>  </v-img>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :text = "true" 
 @click="download" :class="'button buttonitemback93 '"   id="buttonitemback93" ref="buttonitemback93"  v-bind:style="{}"><span>{{ $t('Profile.download') }}</span>
</v-btn>  </div>  </v-card>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
import QRCode from 'qrcode'
import html2canvas from 'html2canvas';
import { Printd } from 'printd'
export default {
	name: 'OrgaCardMember',

    components: {},
	methods: {
	
		download: function() { 		
	console.log(this.$refs)

	html2canvas(this.$refs.front.$el).then((canvas) => {

		Main.download(

			canvas.toDataURL("image/jpeg", 1.0),

			this.user.username 

			+ '-' + this.organisation.name

		)

	});

}
,
	},
	mounted(){
			
	QRCode.toDataURL(this.link)

  .then(url => {

    this.qrCode = url

  })

  .catch(err => {

    console.error(err)

  })

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
		user: function() { 		
	let user = this.memberShip.user.data.attributes

	user.id = this.memberShip.user.data.id

	return user

}
,
		organisation: function() { 		
	let orga = this.memberShip.organisation.data.attributes

	orga.id = this.memberShip.organisation.data.id

	return orga

}
,
		link: function() { 		
	let url = this.$store.state.webUrl

	let slug = this.user.slug

	

	if(slug){

		url += "/profile/" + slug

	} else {

		url += "/profile/id/" + this.user.id

	}

	return url

}
,
		publishedDate: function() { 		
	if(this.memberShip){

			var mydate = new Date(this.memberShip.adhesionDate);

			return mydate.getMonth() + ' / ' + mydate.getFullYear()	

		} else {

			return "- / -"

		}

	return "- / -"

}
,
		orgaLogo: function() { 		
	if(this.organisation){

		if(this.organisation.logo){

			let item = this.organisation.logo?.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.organisation.name)

	}

	return "https://picsum.photos/300"

}
,
	},
	data(){
	return {
		
		qrCode:  null		,
		}
	},
	props: {
	
		memberShip: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#OrgaCardMember{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.meberCard{
	perspective: 1000px;
	
	&:hover{
		.meberCard-inner{
			transform: rotateY(-180deg);
		}
	}
}

.meberCard-inner{
	transition: transform 1s;
	transform-style: preserve-3d;
	transform-origin: 50% 50% !important;
}

.front, .back{
	transform-origin: 50% 50% !important;
	backface-visibility: hidden;
}

.back{
	transform: rotateY(180deg);
}



.item.meberCard{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.item.meberCard-inner{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


	.card.front{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	overflow:hidden !important; 
	
	border-radius:10px ; 
	background-image: linear-gradient(53deg, #ff2c32, #ffad70) ; max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		opacity: 0.3 ;  filter: alpha(opacity=30) ; 
		
		background-color:#000 ; 

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		opacity: 0.5 ;  filter: alpha(opacity=50) ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

		.rectangle.rectanglecard096{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		opacity: 0.3 ;  filter: alpha(opacity=30) ; 
		
		background-color:#000 ; 

		
}

		.networkimage.networkimagecard01{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 15px ; 
		
		top: 15px ; 
		width:55px!important ; 
		height:55px!important ; 
		
		display: inherit;

		flex: unset;
		border-radius:8px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

		
}

		.text.textcard03{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 15px ; 
		right: 15px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		color:#fff ; 
		font-family:Impact ; 
		font-size:20px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		font-style: italic;
		}

		
}

		.column.columncard07{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 15px ; 
		
		right: 15px ; 
		bottom: 15px ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:10px ; background-color: transparent ; 

			.text.textcard0331{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#fff ; 
			font-family:Copperplate ; 
			font-size:25px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			font-weight: bold;
			}

			
}

			.row.rowcolumncard073{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			justify-content:space-between ; background-color:transparent ; 

				.column.columnrowcolumncard0731{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:4px ; background-color: transparent ; 

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					
					color:#fff ; 
					font-size:9px ; 
					text-transform:uppercase; 
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					max-width: 200px!important ; 
					
					color:#fff ; 
					font-size:15px ; 
					text-overflow: ellipsis ; 
					text-transform:uppercase; 
					.text-content{
					width: 100%;
					white-space: nowrap;
					overflow: hidden ;
					text-overflow: ellipsis ; 
					font-weight: bold;
					}

					
}

				
}

				.column.columnrowcolumncard07312{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:4px ; 
				justify-content:flex-end ; background-color: transparent ; 

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					
					color:#fff ; 
					font-size:9px ; 
					text-align:right;
					display: flex;
					text-transform:uppercase; 
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					
					color:#fff ; 
					font-size:15px ; 
					text-align:right;
					display: flex;
					text-transform:uppercase; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

			
}

		
}

	
}

	.card.back{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	overflow:hidden !important; 
	
	border-radius:10px ; 
	background-image: linear-gradient(53deg, #ff2c32, #ffad70) ; max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		opacity: 0.3 ;  filter: alpha(opacity=30) ; 
		
		background-color:#000 ; 

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		opacity: 0.5 ;  filter: alpha(opacity=50) ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

		.rectangle.rectangleback9{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 20px ; 
		right: 0px ; 
		opacity: 0.7 ;  filter: alpha(opacity=70) ; 
		height:50px!important ; 
		
		background-color:#000 ; 

		
}

		.text.textcard036{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		right: 15px ; 
		bottom: 15px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		color:#fff ; 
		font-family:Impact ; 
		font-size:20px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		font-style: italic;
		}

		
}

		.item.itemback9{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left: 20px ; 
		
		top: calc(50% + 0px) ; 
		right: 20px ; 
		height:35px!important ; 
		

			.image.imageitemback91{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

			.button.buttonitemback93{

			height: auto;
			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			height:30px!important ; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.meberCard{


position:absolute !important; 






.item.meberCard-inner{


position:absolute !important; 






	.card.front{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectanglecard096{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard01{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textcard03{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncard07{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcard0331{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard073{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnrowcolumncard0731{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columnrowcolumncard07312{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}

	.card.back{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectangleback9{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.text.textcard036{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemback9{

		
		position:absolute !important; 
		
		
		
		
		

			.image.imageitemback91{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.buttonitemback93{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.meberCard{


position:absolute !important; 






.item.meberCard-inner{


position:absolute !important; 






	.card.front{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectanglecard096{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard01{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textcard03{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncard07{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcard0331{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard073{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnrowcolumncard0731{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columnrowcolumncard07312{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}

	.card.back{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectangleback9{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.text.textcard036{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemback9{

		
		position:absolute !important; 
		
		
		
		
		

			.image.imageitemback91{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.buttonitemback93{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.meberCard{


position:absolute !important; 






.item.meberCard-inner{


position:absolute !important; 






	.card.front{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectanglecard096{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard01{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textcard03{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncard07{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcard0331{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard073{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnrowcolumncard0731{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columnrowcolumncard07312{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}

	.card.back{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectangleback9{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.text.textcard036{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemback9{

		
		position:absolute !important; 
		
		
		
		
		

			.image.imageitemback91{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.buttonitemback93{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.meberCard{


position:absolute !important; 






.item.meberCard-inner{


position:absolute !important; 






	.card.front{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectanglecard096{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard01{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textcard03{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncard07{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcard0331{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard073{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnrowcolumncard0731{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columnrowcolumncard07312{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}

	.card.back{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectangleback9{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.text.textcard036{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemback9{

		
		position:absolute !important; 
		
		
		
		
		

			.image.imageitemback91{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.buttonitemback93{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.meberCard{


position:absolute !important; 






.item.meberCard-inner{


position:absolute !important; 






	.card.front{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectanglecard096{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard01{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textcard03{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncard07{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcard0331{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard073{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnrowcolumncard0731{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columnrowcolumncard07312{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrowcolumncard07311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrowcolumncard073112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}

	.card.back{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.rectangle.rectanglecard095{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.networkimage.networkimagecard07{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.rectangle.rectangleback9{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.text.textcard036{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemback9{

		
		position:absolute !important; 
		
		
		
		
		

			.image.imageitemback91{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.buttonitemback93{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}
}
</style>
