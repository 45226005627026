<template>
 <div>  
<div id="AdminWebinarForm"> 
         
 <div  :class="'column columncolumncolumn0712 '"   id="columncolumncolumn0712" ref="columncolumncolumn0712"  v-bind:style="{}">
 <v-text-field  :label = "$t('Admin.name') + '*'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal011311 '"   id="textfieldcolumnslottemplatedialogboxmodal011311" ref="textfieldcolumnslottemplatedialogboxmodal011311" v-model="name"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-text-field  :label = "'slug'" 
 :append-icon = "'ion-md-refresh'" 
 :dense = "true" 
 :type = "'text'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal0113113 '"   id="textfieldcolumnslottemplatedialogboxmodal0113113" ref="textfieldcolumnslottemplatedialogboxmodal0113113" v-model="slug"  v-bind:style="{color : primaryColor,}" @click:append="generateSlug"></v-text-field>
 <v-text-field  :label = "'duration'" 
 :disabled = "localization != 'fr'" 
 :dense = "true" 
 :type = "'number'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal0113115 '"   id="textfieldcolumnslottemplatedialogboxmodal0113115" ref="textfieldcolumnslottemplatedialogboxmodal0113115" v-model="duration"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-textarea  :label = "$t('Admin.description') + '*'" 
 :auto-grow = "true" 
 filled :class="'textarea textarearectanglecolumncolumnslottemplatedialogboxmodal0115914 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal0115914" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal0115914" v-model="description"  v-bind:style="{}"></v-textarea>
 <v-row  :class="'grid gridcolumncolumncolumn07129 '"   id="gridcolumncolumncolumn07129" ref="gridcolumncolumncolumn07129"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumncolumn071291 '"   id="colgridcolumncolumncolumn071291" ref="colgridcolumncolumncolumn071291"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setImage" 
 :title = "'image'" 
 :type = "'image'" 
 :item = "image" 
 :disabled = "localization != 'fr'" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071293 '"   id="colgridcolumncolumncolumn071293" ref="colgridcolumncolumncolumn071293"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setVideo" 
 :type = "'video'" 
 :title = "'video'" 
 :item = "video" 
 :disabled = "localization != 'fr'" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>  </v-row>
 <div  :class="'text errorText '"   id="errorText" ref="errorText" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>
 <div  :class="'column colAdminSessionView '"   id="colAdminSessionView" ref="colAdminSessionView"  v-bind:style="{}">
 <div  :class="'scrollarea scrollareacolumncolumncolumncolumn0712131 '"   id="scrollareacolumncolumncolumncolumn0712131" ref="scrollareacolumncolumncolumncolumn0712131"  v-bind:style="{backgroundColor : '#efefef',backgroundColor : '#efefef',}">
 <div  :class="'column colAdminSessionViewcol '"   id="colAdminSessionViewcol" ref="colAdminSessionViewcol"  v-bind:style="{}">
 <div  :class="'text sessionWarning '"   id="sessionWarning" ref="sessionWarning" :_padding = "'10px'" 
  v-bind:style="{padding : '10px',color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('Admin.createBeforePossibleSession') }}</div>

   </div>
 <AdminSessionView :class="'object adminformationviewscrollareacolumncolumncolumncolumn07121311 '"   id="adminformationviewscrollareacolumncolumncolumncolumn07121311" ref="adminformationviewscrollareacolumncolumncolumncolumn07121311" v-if="subEntity?.id"  v-bind:style="{}" :parentID = "subEntity?.id" 
>
</AdminSessionView>  </div>  </div>  </div>
 <v-row  :class="'grid gridcolumncolumncolumn071215 '"   id="gridcolumncolumncolumn071215" ref="gridcolumncolumncolumn071215"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumncolumn07129311 '"   id="colgridcolumncolumncolumn07129311" ref="colgridcolumncolumncolumn07129311"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn07129311 '"   id="admincomboviewcolgridcolumncolumncolumn07129311" ref="admincomboviewcolgridcolumncolumncolumn07129311"  v-bind:style="{}" :updateModel = "setCategories" 
 :tableName = "'categories'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "filtersForCategories" 
 :label = "$t('Admin.categories')" 
 :p_model = "categories" 
 :multiple = "true" 
>
</AdminComboView></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071293112 '"   id="colgridcolumncolumncolumn071293112" ref="colgridcolumncolumncolumn071293112"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn07129311 '"   id="admincomboviewcolgridcolumncolumncolumn07129311" ref="admincomboviewcolgridcolumncolumncolumn07129311"  v-bind:style="{}" :updateModel = "setSubCategories" 
 :tableName = "'subCategories'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "filtersForSubCategories" 
 :label = "$t('Admin.subCategories')" 
 :p_model = "subcategories" 
 :multiple = "true" 
>
</AdminComboView></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071293113 '"   id="colgridcolumncolumncolumn071293113" ref="colgridcolumncolumncolumn071293113"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn07129311 '"   id="admincomboviewcolgridcolumncolumncolumn07129311" ref="admincomboviewcolgridcolumncolumncolumn07129311"  v-bind:style="{}" :updateModel = "setTags" 
 :tableName = "'tags'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "filtersForTags" 
 :label = "$t('Admin.tags')" 
 :p_model = "tags" 
 :multiple = "true" 
>
</AdminComboView></div></v-col>  </v-row>  </div>
 
      </div>
 </div> 
</template>
<script>
import AdminMediaPicker from '../../../../At/Components/AdminPage/AdminMediaPicker'
import AdminSessionView from '../../../../At/Components/AdminPage/AdminSessionView'
import AdminComboView from '../../../../At/Components/AdminPage/AdminComboView'

import * as Main from '../../../../Js/main'
import * as Request from '../../../../Js/requests'
export default {
	name: 'AdminWebinarForm',

    components: {AdminMediaPicker,AdminSessionView,AdminComboView,},
	methods: {
	
		loadData: function() { 		
	this.name = this.subEntity?.name

	this.slug = this.subEntity?.slug

	this.description = this.subEntity?.description

	this.duration = this.entity?.duration

	this.image = this.entity?.image.data

	this.video = this.entity?.video.data

	

	this.categories = this.subEntity?.categories.data

	this.subcategories = this.subEntity?.subcategories.data

	this.tags = this.subEntity?.tags.data

	

	this.loading = false

}
,
		generateSlug: function() { 		
	if(this.name){

		this.slug = this.name.replaceAll(/\s+/g, '-')

	}

}
,
		setImage: function(val) { 		
	this.m_image = val

}
,
		setVideo: function(val) { 		
	this.m_video = val

}
,
		setCategories: function(val) { 		
	this.categories = val

}
,
		setSubCategories: function(val) { 		
	this.subcategories = val

}
,
		setTags: function(val) { 		
	this.tags = val

}
,
		createEntry: function() { 		
	let data = {}

	

	if(this.name){ data.name = this.name }

	if(this.slug){ data.slug = this.slug }

	if(this.description){ data.description = this.description }

	

	if(this.categories){ data.categories = this.categories }

	if(this.subcategories){ data.subcategories = this.subcategories }

	if(this.tags){ data.tags = this.tags }

	

	data.publishedAt = new Date().toISOString()

	

  const formData = new FormData();

	

	if(this.localization == 'fr'){ 	

		if(this.duration){ data.duration = this.duration }

		data.instructor = this.$store.state.user.id

			

		if(this.m_image){	

			formData.append(`files.image`, this.m_image, this.m_image.name);

		}

		

		if(this.m_video){

			formData.append(`files.video`, this.m_video, this.m_video.name);

		}

	} else {

		data.locale = this.localization

	}

	

	formData.append('data', JSON.stringify(data));

	

	Request.createLocalizedEntry(this.tableName, this.entity?.id ,formData)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryCreated')) 

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		updateEntry: function() { 		
	let data = {}

	

	if(this.name){ data.name = this.name }

	if(this.slug){ data.slug = this.slug }

	if(this.description){ data.description = this.description }

	

	if(this.categories){ data.categories = this.categories }

	if(this.subcategories){ data.subcategories = this.subcategories }

	if(this.tags){ data.tags = this.tags }

	

	if(this.localization == 'fr'){ 	

		if(this.duration){ data.duration = this.duration }

			

		if(this.m_image){	

			data.image = this.m_image

		}

		

		if(this.m_video){

			data.video = this.m_video;

		}

	}

	

	Request.updateEntry(this.tableName, this.subEntity.id, data)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryUpdated'))

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true	

	this.$awn.alert(message)

}
,
	},
	mounted(){
			
	this.loadData()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		entityID: function() { 		
	return this.entity?.id

}
,
		filtersForCategories: function() { 			
	let filter = {}

	return filter

}
,
		filtersForSubCategories: function() { 			
	let filter = {}

	return filter

}
,
		filtersForTags: function() { 			
	let filter = {}

	return filter

}
,
	},
	watch: {
	
		subEntity: function(val) { 		
	this.loading = true

	this.changed = false

	this.loadData()

}
,
		name: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		slug: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		description: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		duration: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_image: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_video: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		categories: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		subcategories: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		tags: function(val) { 		
	if(!this.loading) this.changed = true

}
,
	},
	data(){
	return {
		
		name:  null		,
		slug:  null		,
		description:  null		,
		duration:  null		,
		image:  null		,
		video:  null		,
		categories:  null		,
		subcategories:  null		,
		tags:  null		,
		m_image:  null		,
		m_video:  null		,
		loading:  true		,
		changed:  false		,
		error:  null		,
		errorVisible:  false		,
		tableName:  'webinars'		,
		}
	},
	props: {
	
		entity: {					required: false,			default: () =>  null,		},
		subEntity: {					required: false,			default: () =>  null,		},
		localization: {					required: false,			default: () =>  null,		},
		updateSelectedItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminWebinarForm{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.columncolumncolumn0712{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

font-size:12px ; 
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

font-size:12px ; 
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

font-size:12px ; 
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 5px ; 

margin-top: 5px ; 
margin-right: 5px ; 
margin-bottom: 5px ; 
width: calc(100% - (5px + 5px)) !important;
height: calc(100% - (5px + 5px)) !important;


::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

color:#ff1a05 ; 
line-height:10px ; 
font-size:10px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;text-decoration: underline
}


}

.column.colAdminSessionView{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
padding-top:20px ; 
padding-bottom:20px ; 
padding-left:20px ; 
padding-right:20px ; 

	.scrollarea.scrollareacolumncolumncolumncolumn0712131{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	max-height: 400px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	overflow:hidden ; 
	overflow-x:hidden ; 
	overflow-y:scroll ; 
	background-color:#efefef ; 
	border-radius:8px ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #0ae;    

      // =======================================Style4
    border: 2px solid #555555;
      //-----------------------------------------------
        
      }

		.column.colAdminSessionViewcol{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.text.sessionWarning{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			padding:10px !important; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:14px ; 
			.text-content{
			width: 100%;
			}

			
}

			.object.adminformationviewscrollareacolumncolumncolumncolumn07121311{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn071215{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}


}


@media (max-width: 600px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}

.column.colAdminSessionView{


position:relative !important; 









	.scrollarea.scrollareacolumncolumncolumncolumn0712131{

	
	position:relative !important; 
	
	
	
	
	

		.column.colAdminSessionViewcol{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.sessionWarning{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.object.adminformationviewscrollareacolumncolumncolumncolumn07121311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn071215{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}


}
}

@media (min-width: 601px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}

.column.colAdminSessionView{


position:relative !important; 









	.scrollarea.scrollareacolumncolumncolumncolumn0712131{

	
	position:relative !important; 
	
	
	
	
	

		.column.colAdminSessionViewcol{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.sessionWarning{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.object.adminformationviewscrollareacolumncolumncolumncolumn07121311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn071215{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}


}
}

@media (min-width: 961px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





line-height:15px ; 
font-size:15px ; 
.text-content{
width: 100%;
}


}

.column.colAdminSessionView{


position:relative !important; 









	.scrollarea.scrollareacolumncolumncolumncolumn0712131{

	
	position:relative !important; 
	
	
	
	
	

		.column.colAdminSessionViewcol{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.sessionWarning{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.object.adminformationviewscrollareacolumncolumncolumncolumn07121311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn071215{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}

.column.colAdminSessionView{


position:relative !important; 









	.scrollarea.scrollareacolumncolumncolumncolumn0712131{

	
	position:relative !important; 
	
	
	
	
	

		.column.colAdminSessionViewcol{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.sessionWarning{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.object.adminformationviewscrollareacolumncolumncolumncolumn07121311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn071215{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}

.column.colAdminSessionView{


position:relative !important; 









	.scrollarea.scrollareacolumncolumncolumncolumn0712131{

	
	position:relative !important; 
	
	
	
	
	

		.column.colAdminSessionViewcol{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.sessionWarning{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.object.adminformationviewscrollareacolumncolumncolumncolumn07121311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn071215{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}


}
}
}
</style>
