<template>
 <div>  
<div id="MainHeader"> 
         
 <ContentWrapper :class="'object contentwrapper0 '"   id="contentwrapper0" ref="contentwrapper0" :_backgroundColor = "'#fff'" 
  v-bind:style="{backgroundColor : '#fff',}" :paddingTop = "0" 
 :paddingBottom = "0" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrapper01 '"   id="slottemplatecontentwrapper01" ref="slottemplatecontentwrapper01"  v-bind:style="{}">
 <div  :class="'row rowslottemplatecontentwrapper011 '"   id="rowslottemplatecontentwrapper011" ref="rowslottemplatecontentwrapper011"  v-bind:style="{}">
 <Logo :class="'object logorowslottemplatecontentwrapper0115 '"   id="logorowslottemplatecontentwrapper0115" ref="logorowslottemplatecontentwrapper0115"  v-bind:style="{}" :mini = "true" 
 :size = "45" 
>
</Logo>
 <div  :class="'row searchCol '"   id="searchCol" ref="searchCol" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <MainSearchBar :class="'object searchbarrowslottemplatecontentwrapper01151 '"   id="searchbarrowslottemplatecontentwrapper01151" ref="searchbarrowslottemplatecontentwrapper01151"  v-bind:style="{}">
</MainSearchBar>  </div>
 <div  :class="'row rightSectionBar '"   id="rightSectionBar" ref="rightSectionBar"  v-bind:style="{}">
 <div  :class="'row rowtop131 '"   id="rowtop131" ref="rowtop131"  v-bind:style="{}">
 <TabButton :class="'object tabbuttonrowtop11 '"   id="tabbuttonrowtop11" ref="tabbuttonrowtop11" v-for="(item,index) in tabs" :key="'tabbuttonrowtop11' + index"  v-bind:style="{}" :active = "(currentTab == index)" 
 :value = "item.name" 
 :color = "primaryColor" 
 :index = "index" 
 :link = "item.link" 
 :icon = "item.icon" 
>
</TabButton>
 <v-select
          :items = "language" :class="'select langSelect '"   id="langSelect" ref="langSelect" v-model="selectedLang"  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>
 <TabActionButton :class="'object tabbuttonrowtop1152 '"   id="tabbuttonrowtop1152" ref="tabbuttonrowtop1152"  v-bind:style="{}" :action = "toggleProfileModal" 
 :value = "$t('Header.you')" 
 :image = "profileImage" 
>
</TabActionButton>  </div>
 <div  :class="'rectangle devider2 '"   id="devider2" ref="devider2"  v-bind:style="{backgroundColor : '#00000014',}">  </div>
 <TabActionButton :class="'object tabbuttonrowtop1153 '"   id="tabbuttonrowtop1153" ref="tabbuttonrowtop1153"  v-bind:style="{}" :action = "toggleServiceModal" 
 :value = "$t('Header.sections')" 
 :icon = "'ion-md-apps'" 
>
</TabActionButton>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="toggleMenu" :class="'button buttonrowslottemplatecontentwrapper011114 '"   id="buttonrowslottemplatecontentwrapper011114" ref="buttonrowslottemplatecontentwrapper011114"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-menu' !== undefined" >{{ 'ion-md-menu' }}
</v-icon></v-btn>  </div>  </div></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../At/Utils/ContentWrapper'
import Logo from '../../At/Utils/Logo'
import MainSearchBar from '../../At/Components/MainSearchBar'
import TabButton from '../../At/Utils/TabButton'
import TabActionButton from '../../At/Utils/TabActionButton'

import * as Main from '../../Js/main'
import * as Request from '../../Js/requests'
export default {
	name: 'MainHeader',

    components: {ContentWrapper,Logo,MainSearchBar,TabButton,TabActionButton,},
	methods: {
	
		setLang: function() { 		
	Main.checkLang()

	this.selectedLang = this.$store.state.selectedLang

	this.$i18n.locale = this.selectedLang

}
,
		getUser: function() { 		
	Request.getTable('users/' + this.user.id)

	.then((res) => {

		this.$store.commit('changeUser', res.data)

	})

}
,
	},
	mounted(){
			
	this.setLang()

	this.getUser()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		language: function() { 		
	return this.$store.state.language

}
,
		paramLang: function() { 		
	return this.$router.history.current.query.lang

}
,
		tabs: function() { 		
	return [

		{

			name: this.$t('Header.home'),

			icon: "ion-ios-home",

			link: "/"

		},

		{

			name: this.$t('Header.learning'),

			icon: "ion-md-book",

			link: "/learning"

		},

		{

			name: this.$t('Header.webinars'),

			icon: "ion-ios-globe",

			link: "/webinars"

		},

		{

			name: this.$t('Header.blog'),

			icon: "ion-md-paper",

			link: "/blog"

		},

	]

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		profileImage: function() { 		
	if(this.user){

		if(this.user.image){

			let item = this.user.image;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.user.username)

	}

	return null

}
,
	},
	watch: {
	
		selectedLang: function(val) { 		
	this.$i18n.locale = val

	this.$store.commit('changeSelectedLang', val)

}
,
		paramLang: function(val) { 		
	this.setLang()

}
,
	},
	data(){
	return {
		
		selectedLang:  'fr'		,
		}
	},
	props: {
	
		toggleProfileModal: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		toggleServiceModal: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		toggleMenu: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		currentTab: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#MainHeader{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.selectright53{
	&:before{
		border: none;
	}
}



.object.contentwrapper0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
margin-bottom: 0px ; 
backgroundColor:#fff !important; 
width: calc(100% - (0px + 0px)) !important;
height: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
height:auto!important ; 

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:50px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:auto!important ; 
		height:48px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.searchCol{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:48px!important ; 
		flex:1 !important; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;background-color:transparent ; 
		padding-top:24px ; 

			.object.searchbarrowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-top: 0px ; 
			margin-right: 0px ; 
			margin-bottom: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			height: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rightSectionBar{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:auto!important ; 
		height: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:10px ; background-color:transparent ; 

			.row.rowtop131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			margin-bottom: 0px ; 
			display:none !important; 
			width:auto!important ; 
			height: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;background-color:transparent ; 

				.object.tabbuttonrowtop11{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				margin-bottom: 0px ; 
				width:auto!important ; 
				height: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.select.langSelect{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				
				top: calc(50% + 0px) ; 
				width:70px!important ; 
				height:50px!important ; 
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.object.tabbuttonrowtop1152{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				margin-bottom: 0px ; 
				width:auto!important ; 
				height: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.rectangle.devider2{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			margin-bottom: 0px ; 
			display:none !important; 
			width:1px!important ; 
			height: calc(100% - (0px + 0px)) !important;
			
			background-color:#00000014 ; 

			
}

			.object.tabbuttonrowtop1153{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			margin-bottom: 0px ; 
			display:none !important; 
			width:auto!important ; 
			height: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.button.buttonrowslottemplatecontentwrapper011114{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			 display:flex !important; 
			width:50px!important ; 
			height:50px!important ; 
			
			font-size:25px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:25px ; 
			}
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.searchCol{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.searchbarrowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rightSectionBar{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.row.rowtop131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.tabbuttonrowtop11{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.select.langSelect{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.object.tabbuttonrowtop1152{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.rectangle.devider2{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.object.tabbuttonrowtop1153{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.button.buttonrowslottemplatecontentwrapper011114{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.searchCol{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.searchbarrowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rightSectionBar{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.row.rowtop131{

			
			position:relative !important; 
			
			
			
			
			 display:flex !important; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.tabbuttonrowtop11{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.select.langSelect{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.object.tabbuttonrowtop1152{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.rectangle.devider2{

			
			position:relative !important; 
			
			
			
			
			 display:block !important; 
			
			

			
}

			.object.tabbuttonrowtop1153{

			
			position:relative !important; 
			
			
			
			
			 display:block !important; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.button.buttonrowslottemplatecontentwrapper011114{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			display:none !important; 
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.searchCol{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.searchbarrowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rightSectionBar{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.row.rowtop131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.tabbuttonrowtop11{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.select.langSelect{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.object.tabbuttonrowtop1152{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.rectangle.devider2{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.object.tabbuttonrowtop1153{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.button.buttonrowslottemplatecontentwrapper011114{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.searchCol{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.searchbarrowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rightSectionBar{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.row.rowtop131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.tabbuttonrowtop11{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.select.langSelect{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.object.tabbuttonrowtop1152{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.rectangle.devider2{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.object.tabbuttonrowtop1153{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.button.buttonrowslottemplatecontentwrapper011114{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.searchCol{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.searchbarrowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rightSectionBar{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.row.rowtop131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.tabbuttonrowtop11{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.select.langSelect{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.object.tabbuttonrowtop1152{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.rectangle.devider2{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.object.tabbuttonrowtop1153{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.button.buttonrowslottemplatecontentwrapper011114{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}
}
</style>
