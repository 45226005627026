<template>
 <div>  
<div id="LearningMoodleDetails"> 
         
 <DetailsPage :class="'object learningdetails0 '"   id="learningdetails0" ref="learningdetails0"  v-bind:style="{}" :fromMoodle = "true" 
 :currentTab = "1" 
 :moodleInfo = "info" 
>
</DetailsPage>
 
      </div>
 </div> 
</template>
<script>
import DetailsPage from '../../At/Sections/DetailsPage'

import * as Request from '../../Js/requests'
export default {
	name: 'LearningMoodleDetails',

    components: {DetailsPage,},
	methods: {
	
		getMoodleCoursByField: function(){ 		
	Request.getMoodleCoursByField('id', this.id)

	.then((res) => {

		let list = res.data.courses

		if(list !== undefined && list.length > 0){

			this.tmpInfo = list[0]

			this.getMoodleCoursContent()

		}

	})

}
,
		getMoodleCoursContent: function(){ 		
	Request.getMoodleCoursContent(this.id)

	.then((res) => {

		this.tmpInfo.sectionsTree = res.data

		this.info = this.tmpInfo

	})

}
,
	},
	mounted(){
			
	this.getMoodleCoursByField()

	},
	computed: {
	
		id: function() { 		
	return parseInt(this.$route.params.id)

}
,
	},
	watch: {
	
		id: function(val) { 		
	location.reload();

}
,
	},
	data(){
	return {
		
		info:  null		,
		tmpInfo:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#LearningMoodleDetails{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.learningdetails0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


@media (max-width: 600px) {


.object.learningdetails0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 601px) {


.object.learningdetails0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 961px) {


.object.learningdetails0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1265px) {


.object.learningdetails0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1905px) {


.object.learningdetails0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}
}
</style>
