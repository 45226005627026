<template>
 <div>  
<div id="ActionLink"> 
         
 <div  :class="'row rowlinkrow01232 '"   id="rowlinkrow01232" ref="rowlinkrow01232" v-on:click="action"  v-ripple = "true"  v-bind:style="{}">
 <div  :class="'icon iconrowlinkrow01233 '"   id="iconrowlinkrow01233" ref="iconrowlinkrow01233" v-if="leftIcon"  v-bind:style="{width : getUnit(size,'px'),fontSize : getUnit(size,'px'),}"><i  :class="leftIcon"></i></div>
 <v-img  :class="'image imagerowlinkrow01237 '"   id="imagerowlinkrow01237" ref="imagerowlinkrow01237" v-if="leftImage"  v-bind:style="{width : getUnit(size,'px'),height : getUnit(size,'px'), backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + 'Resources' + leftImage + '')" 
>  </v-img>
 <div  :class="'text textlinkrow0111 ' + ' ' + 'linkText'"   id="textlinkrow0111" ref="textlinkrow0111" :_fontWeight = "fontWeight" 
  v-bind:style="{fontWeight : fontWeight,color : '#000000',fontSize : getUnit(size,'px'),}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <div  :class="'icon iconrowlinkrow012333 '"   id="iconrowlinkrow012333" ref="iconrowlinkrow012333" v-if="rightIcon"  v-bind:style="{width : getUnit(size,'px'),fontSize : getUnit(size,'px'),}"><i  :class="rightIcon"></i></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ActionLink',

    components: {},
	computed: {
	
		fontWeight: function() { 		
	return (this.bold? 'bold' : 'normal')

}
,
	},
	props: {
	
		title: {					required: false,			default: () =>  "Home",		},
		leftIcon: {					required: false,			default: () =>  null,		},
		rightIcon: {					required: false,			default: () =>  null,		},
		leftImage: {					required: false,			default: () =>  null,		},
		size: {					required: false,			default: () =>  20,		},
		action: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		underline: {			type: Boolean,			required: false,			default: () =>  false,		},
		bold: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ActionLink{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.rowlinkrow01232{
	cursor: pointer;
}

.text-content{
	&:hover{
		opacity: 0.7;
	}
}




.row.rowlinkrow01232{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:auto!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:8px ; background-color:transparent ; 

.icon.iconrowlinkrow01233{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
height: calc(100% - (0px + 0px)) !important;

display: flex;
justify-content: center;
align-items: center;
i {
}
}

.image.imagerowlinkrow01237{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.text.textlinkrow0111{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
width:auto!important ; 

color:#000000 ; 
.text-content{
width: 100%;
}


}

.icon.iconrowlinkrow012333{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
height: calc(100% - (0px + 0px)) !important;

display: flex;
justify-content: center;
align-items: center;
i {
}
}


}


@media (max-width: 600px) {


.row.rowlinkrow01232{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.icon.iconrowlinkrow01233{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.image.imagerowlinkrow01237{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.textlinkrow0111{


position:relative !important; 





.text-content{
width: 100%;
}


}

.icon.iconrowlinkrow012333{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}


}
}

@media (min-width: 601px) {


.row.rowlinkrow01232{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.icon.iconrowlinkrow01233{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.image.imagerowlinkrow01237{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.textlinkrow0111{


position:relative !important; 





.text-content{
width: 100%;
}


}

.icon.iconrowlinkrow012333{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}


}
}

@media (min-width: 961px) {


.row.rowlinkrow01232{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.icon.iconrowlinkrow01233{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.image.imagerowlinkrow01237{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.textlinkrow0111{


position:relative !important; 





.text-content{
width: 100%;
}


}

.icon.iconrowlinkrow012333{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}


}
}

@media (min-width: 1265px) {


.row.rowlinkrow01232{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.icon.iconrowlinkrow01233{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.image.imagerowlinkrow01237{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.textlinkrow0111{


position:relative !important; 





.text-content{
width: 100%;
}


}

.icon.iconrowlinkrow012333{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}


}
}

@media (min-width: 1905px) {


.row.rowlinkrow01232{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.icon.iconrowlinkrow01233{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.image.imagerowlinkrow01237{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.textlinkrow0111{


position:relative !important; 





.text-content{
width: 100%;
}


}

.icon.iconrowlinkrow012333{


position:relative !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}


}
}
}
</style>
