<template>
 <div>  
<div id="BlogDetail"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <MainLayout :class="'object basic01 '"   id="basic01" ref="basic01"  v-bind:style="{}" :currentTab = "3" 
 :bgColor = "'#fff'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatebasic '"   id="slottemplatebasic" ref="slottemplatebasic"  v-bind:style="{}">
 <div  :class="'column columnslottemplatebasic3 '"   id="columnslottemplatebasic3" ref="columnslottemplatebasic3"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout015139222 '"   id="contentwrappercolumnslottemplatedefaultlayout015139222" ref="contentwrappercolumnslottemplatedefaultlayout015139222"  v-bind:style="{}" :paddingTop = "0" 
 :paddingBottom = "40" 
 :paddingLeft = "(mqVal({default:0,md:20}, $mq))" 
 :paddingRight = "(mqVal({default:0,md:20}, $mq))" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column mainContent1 '"   id="mainContent1" ref="mainContent1"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagecolumnmainContent111312 '"   id="networkimagecolumnmainContent111312" ref="networkimagecolumnmainContent111312"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "image" 
>
 <div  :class="'rectangle rectanglenetworkimagecolumnmainContent111311 '"   id="rectanglenetworkimagecolumnmainContent111311" ref="rectanglenetworkimagecolumnmainContent111311"  v-bind:style="{backgroundColor : '#000',}">  </div>
 <div  :class="'text textcolumnmainContent11112 '"   id="textcolumnmainContent11112" ref="textcolumnmainContent11112"  v-bind:style="{color : '#fff',}">
<h1 class="text-content" :style = "{}">{{ title }}</h1>

   </div>  </v-img>
 <v-row  :class="'grid gridmainContent1 '"   id="gridmainContent1" ref="gridmainContent1"  v-bind:style="{}" :no-gutters = "true" 
>
 <v-col  :class="'col colgridmainContent13 '"   id="colgridmainContent13" ref="colgridmainContent13" :_order = "(mqVal({default:1,lg:2}, $mq))" 
  v-bind:style="{order : (mqVal({default:1,lg:2}, $mq)),}" :cols = "12" 
 :lg = "9" 
 :md = "8" 
><div class="col-container">
 <div  :class="'flow topLeft '"   id="topLeft" ref="topLeft" :_alignItems = "'center'" 
 :_borderBottom = "'solid 1px #80808042'" 
  v-bind:style="{alignItems : 'center',borderBottom : 'solid 1px #80808042',justifyContent : 'space-between',}">
 <div  :class="'flow flowcolumnmainContent11111 '"   id="flowcolumnmainContent11111" ref="flowcolumnmainContent11111" :_flex = "'1'" 
  v-bind:style="{flex : '1',justifyContent : 'space-between',}">
 <div  :class="'row rowcolumnflow03541 '"   id="rowcolumnflow03541" ref="rowcolumnflow03541"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerowcolumnflow0351 '"   id="networkimagerowcolumnflow0351" ref="networkimagerowcolumnflow0351"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "redactorImage" 
>  </v-img>
 <div  :class="'column columnrowcolumnflow0353 '"   id="columnrowcolumnflow0353" ref="columnrowcolumnflow0353" :_flex = "'1'" 
  v-bind:style="{flex : '1',justifyContent : 'space-between',}">
 <div  :class="'text textcolumnrowcolumnflow03531 '"   id="textcolumnrowcolumnflow03531" ref="textcolumnrowcolumnflow03531"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ redactorName }}</div>

   </div>
 <div  :class="'text textcolumnrowcolumnflow035312 '"   id="textcolumnrowcolumnflow035312" ref="textcolumnrowcolumnflow035312"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ date }}</div>

   </div>  </div>  </div>
 <div  :class="'flow flowcolumnmainContent111152 '"   id="flowcolumnmainContent111152" ref="flowcolumnmainContent111152" :_alignItems = "'center'" 
  v-bind:style="{alignItems : 'center',}">
 <div  :class="'row tagRow611 '"   id="tagRow611" ref="tagRow611" v-for="(item,index) in tags" :key="'tagRow611' + index"  v-bind:style="{backgroundColor : primaryColor,}">
 <div  :class="'text tagText '"   id="tagText" ref="tagText"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ item.name }}</div>

   </div>  </div>  </div>  </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 @click="download" :class="'button buttoncolumnmainContent1113 '"   id="buttoncolumnmainContent1113" ref="buttoncolumnmainContent1113" v-show="downloadLink"  v-bind:style="{}"><span>{{ $t('Blog.downloadNow') }}</span>
</v-btn>  </div></div></v-col>
 <v-col  :class="'col colgridmainContent15 '"   id="colgridmainContent15" ref="colgridmainContent15" :_order = "2" 
  v-bind:style="{order : 2,}" :cols = "12" 
 :lg = "3" 
 :md = "4" 
><div class="col-container">
 <div  :class="'column columnmainContent111 '"   id="columnmainContent111" ref="columnmainContent111" :_justifyContent = "'center'" 
 :_borderBottom = "'solid 1px #80808042'" 
  v-bind:style="{justifyContent : 'center',borderBottom : 'solid 1px #80808042',}">
 <v-btn tag="div" 
 :color = "'#fff'" 
 @click="showShare" :class="'button buttoncolumn011 '"   id="buttoncolumn011" ref="buttoncolumn011" :_border = "'solid 1px #d7d7d7'" 
  v-bind:style="{border : 'solid 1px #d7d7d7',}"><v-icon  :style = "{}"  v-if = "'ion-ios-share-alt' !== undefined"  :left = "true" 
>{{ 'ion-ios-share-alt' }}
</v-icon><span>{{ $t('Blog.shareArticle') }}</span>
</v-btn>  </div></div></v-col>  </v-row>
 <v-row  :class="'grid gridmainContent13 '"   id="gridmainContent13" ref="gridmainContent13"  v-bind:style="{}" :no-gutters = "true" 
>
 <v-col  :class="'col colgridmainContent13 '"   id="colgridmainContent13" ref="colgridmainContent13" :_order = "(mqVal({default:1,lg:2}, $mq))" 
  v-bind:style="{order : (mqVal({default:1,lg:2}, $mq)),}" :cols = "12" 
 :lg = "8" 
 :md = "8" 
><div class="col-container">
 <div  :class="'column columnmainContent111 '"   id="columnmainContent111" ref="columnmainContent111"  v-bind:style="{}">
 <div  :class="'column columncolumnmainContent1119 '"   id="columncolumnmainContent1119" ref="columncolumnmainContent1119"  v-bind:style="{}">
 <div  :class="'column introductionContent ' + 'markdown-body'"   id="introductionContent" ref="introductionContent" v-html="introduction"  v-bind:style="{}">  </div>
 <v-img  :class="'networkimage firstImage '"   id="firstImage" ref="firstImage" v-if="firstImage"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "firstImage" 
>  </v-img>
 <div  :class="'column bodyContent ' + 'markdown-body'"   id="bodyContent" ref="bodyContent" v-html="body"  v-bind:style="{}">  </div>
 <v-img  :class="'networkimage firstImage5 '"   id="firstImage5" ref="firstImage5" v-if="secondImage"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "secondImage" 
>  </v-img>
 <div  :class="'column conclusionContent ' + 'markdown-body'"   id="conclusionContent" ref="conclusionContent" v-html="conclusion"  v-bind:style="{}">  </div>  </div>  </div></div></v-col>
 <v-col  :class="'col colgridmainContent15 '"   id="colgridmainContent15" ref="colgridmainContent15" :_order = "2" 
  v-bind:style="{order : 2,}" :cols = "12" 
 :lg = "4" 
 :md = "4" 
><div class="col-container">
 <div  :class="'column columnmainContent111 '"   id="columnmainContent111" ref="columnmainContent111"  v-bind:style="{}">
 <div  :class="'text textcolumnmainContent1113 '"   id="textcolumnmainContent1113" ref="textcolumnmainContent1113"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Blog.anotherArticle') }}</div>

   </div>
 <v-row  :class="'grid gridcolumnmainContent1115 '"   id="gridcolumnmainContent1115" ref="gridcolumnmainContent1115"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumnmainContent11151 '"   id="colgridcolumnmainContent11151" ref="colgridcolumnmainContent11151" v-for="(item,index) in articles" :key="'colgridcolumnmainContent11151' + index"  v-bind:style="{}" :cols = "12" 
 :md = "12" 
 :sm = "6" 
><div class="col-container">
 <ArticleBoxMini :class="'object articleboxmainContent1111 '"   id="articleboxmainContent1111" ref="articleboxmainContent1111"  v-bind:style="{}" :article = "item" 
>
</ArticleBoxMini></div></v-col>  </v-row>  </div></div></v-col>  </v-row>  </div></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 <ShareModal :class="'object sharemodalitem052222 '"   id="sharemodalitem052222" ref="sharemodalitem052222" v-if="shareModalVi"  v-bind:style="{}" :close = "hideShare" 
 :url = "shareOptions.url" 
 :title = "shareOptions.title" 
 :hashtags = "shareOptions.hashtags" 
 :subject = "shareOptions.subject" 
 :description = "shareOptions.description" 
>
</ShareModal>  </div>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import ArticleBoxMini from '../../At/Components/BlogPage/ArticleBoxMini'
import ShareModal from '../../At/Sections/Modal/ShareModal'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
import {marked} from 'marked'
import {Printd} from 'printd'
import 'github-markdown-css/github-markdown-light.css'
export default {
	name: 'BlogDetail',

    components: {MainLayout,ContentWrapper,ArticleBoxMini,ShareModal,},
	methods: {
	
		download: function() { 		
	if(this.$store.state.user){

		Main.download(

			this.downloadLink,

			this.shareOptions.title,

			'_blank'

		)

	} else {

		this.$router.push('/login')

	}

}
,
		showShare: function() { 		
	this.shareModalVi = true;

}
,
		hideShare: function() { 		
	this.shareModalVi = false;

}
,
		getArticle: function() { 		
	let config = {

		locale: "all",

		filters: {	

			slug: {

				$eq: this.slug

			}

		}

	}

	

	Request.getTableArgs('articles', config)

	.then((res) => {

		this.article = res.data.data[0].attributes

		this.articleMeta = {'id': res.data.data[0].id}

		

		//this.defineMeta()

	

		this.getInstructor()

		this.getArticles()

	})

}
,
		getArticles: function() {			
	let config = {

		pagination: {

			limit: 5

		},

		sort: ['id:desc'],

		filters: {

			id: {

				$ne: 	this.articleMeta.id

			}

		}

	}

	

	Request.getLocalTableArgs('articles', config)

	.then((res) => {

		this.articles = res.data.data

	}) 

}
,
		getInstructor: function() { 		
	if(this.article.redactor){	

		let config = {	

		  filters: {

		    id: {

		      $eq: this.article.redactor.data.id,

		    },

		  },

		}	

			

		Request.getTableArgs('users', config)

		.then((res) => {

			this.redactor = res.data[0]

		})

	}

}
,
		increaseVues: function() { 		
	let data = {

		vues: this.article.vues + 1

	}

	

	Request.updateEntry('articles', 	this.articleMeta.id, data)

	.then(res => {

		this.article.vues = res.data.data.attributes.vues

	})

}
,
	},
	mounted(){
			
	this.getArticle()

	setTimeout(() => {this.increaseVues()}, 2.0 * 1000);

	},
	metaInfo(){
			
	return {

		title: this.shareOptions.title,

		meta: [		

	   { name: 'description', content: this.shareOptions.description},

	

	   { property: 'og:title', content: this.shareOptions.title},

	   { property: 'og:description', content: this.shareOptions.description},

	   { property: 'og:type', content: 'blog'},

	   { property: 'og:url', content: this.shareOptions.url},

	   { property: 'og:image', content: this.image },

	

	   { name: 'twitter:title', content: this.shareOptions.title},

	   { name: 'twitter:description', content: this.shareOptions.description},

	   { name: 'twitter:card', content: 'blog'},

	   { name: 'twitter:url', content: this.shareOptions.url},

	   { name: 'twitter:image', content: this.image }    

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		selectedLang: function() { 		
	return this.$store.state.selectedLang

}
,
		title: function() { 		
	return this.article.title

}
,
		downloadLink: function() { 		
	return this.article.downloadLink

}
,
		description: function() { 		
	return this.article.description

}
,
		redactorName: function() { 		
	return this.article.redactor.username

}
,
		redactorImage: function() { 		
	let item 

	if(this.redactor){	

		item = this.redactor.image;

	}

	return Main.getImgUrl(item, 'small');

}
,
		date: function() { 		
	if(this.article){	

		let date = new Date(this.article.publishedAt)

		let lang = this.$store.state.selectedLang

		return new Intl.DateTimeFormat(lang, { dateStyle: 'full' }).format(date);

	}

	return "- : -"

}
,
		image: function() { 		
	let item 

	if(this.article.image?.data){	

		item = this.article.image.data.attributes;

	}

	return Main.getImgUrl(item, 'medium');

}
,
		firstImage: function() { 		
	if(this.article.firstImage?.data){	

		let item = this.article.firstImage.data.attributes;

		return Main.getImgUrl(item, 'medium');

	}

	return null

}
,
		secondImage: function() { 		
	if(this.article.secondImage?.data){	

		let item = this.article.secondImage.data.attributes;

		return Main.getImgUrl(item, 'medium');

	}

	return null

}
,
		tags: function() { 		
	return (this.article.tags?

		this.article.tags.data.map(t => t.attributes)

		: []

	)

}
,
		link: function() { 		
	return '/blog/' + this.slug

}
,
		redactorName: function() { 		
	if(this.redactor){

		return this.redactor.username

	}

	return ""

}
,
		redactorImage: function() { 		
	let item 

	if(this.redactor){	

		item = this.redactor.image;

	}

	return Main.getImgUrl(item, 'small');

}
,
		introduction: function() { 		
	//return marked.parse(this.article?.introduction ?? "")

	return this.article?.introduction ?? ""

}
,
		body: function() { 		
	return marked.parse(this.article?.body ?? "")

}
,
		conclusion: function() { 		
	return marked.parse(this.article?.conclusion ?? "")

}
,
		slugWord: function() { 		
	if(this.slug){

		return Main.quebabToWords(this.slug)

	}

	return null

}
,
		shareOptions: function() { 		
	if(this.title){	

		return {

			url: this.$store.state.webUrl + '/blog/' + this.slug, 

			title: this.title + '| Afrotiative', 

			hashtags: ['Blog', 'Article', this.m_name, this.slug, 'Afrotiative'], 

			description: this.introduction, 

			to: '',

			subject: this.title

		}

	} else if(this.slugWord){	

		return {		

			url: window.location.href, 

			title: this.slugWord + '| Afrotiative', 

			hashtags: ['Blog', 'Article', 'Afrotiative'], 

			description: this.slugWord, 

			to: '',

			subject: this.slugWord

		}

	}

	

	return null

}
,
		slug: function() { 		
	return this.$route.params.slug

}
,
	},
	watch: {
	
		selectedLang: function() { 		
	this.getArticles()

}
,
		slug: function(val) { 		
	location.reload();

}
,
	},
	data(){
	return {
		
		article:  ({})		,
		articleMeta:  ({})		,
		articles:  []		,
		shareModalVi:  false		,
		redactor:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#BlogDetail{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.object.basic01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic3{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.contentwrappercolumnslottemplatedefaultlayout015139222{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.mainContent1{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					width:98%!important ; 
					height:auto!important ; 
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:0px ; background-color: transparent ; 
					padding-top:0px ; 

						.networkimage.networkimagecolumnmainContent111312{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:400px!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

							.rectangle.rectanglenetworkimagecolumnmainContent111311{

							
							position:absolute !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							left: 0px ; 
							
							top: 0px ; 
							right: 0px ; 
							bottom: 0px ; 
							opacity: 0.3 ;  filter: alpha(opacity=30) ; 
							
							background-color:#000 ; 

							
}

							.text.textcolumnmainContent11112{

							
							position:absolute !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
							
							left: 10px ; 
							
							top: calc(50% + 0px) ; 
							right: 10px ; 
							height:auto!important ; 
							
							color:#fff ; 
							line-height:40px ; 
							font-size:20px ; 
							text-align:center;
							align-items:center;
							display: flex;
							.text-content{
							width: 100%;
							font-weight: bold;
							}

							
}

						
}

						.grid.gridmainContent1{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						background-color: transparent ; 

							.col.colgridmainContent13{

							
							position:relative !important; 
							margin: unset;
							transform-origin: top left;
							
							
							
							order:1 !important; 
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.flow.topLeft{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
								
								left: calc(50% + 0px) ; 
								
								alignItems:center !important; 
								borderBottom:solid 1px #80808042 !important; 
								
								display: flex;
								flex-wrap: wrap;
								flex: initial;
								justify-content:space-between ; background-color:transparent ; 
								padding-top:10px ; 
								padding-bottom:10px ; 
								padding-left:10px ; 
								padding-right:10px ; 

									.flow.flowcolumnmainContent11111{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									left:0px ; 
									
									width:auto!important ; 
									height:auto!important ; 
									flex:1 !important; 
									
									display: flex;
									flex-wrap: wrap;
									flex: initial;
									justify-content:space-between ; background-color:transparent ; 

										.row.rowcolumnflow03541{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										left:0px ; 
										
										width:auto!important ; 
										height:auto!important ; 
										
										display: flex;
										flex-wrap: nowrap;
										flex: initial;
										flex-direction: row;
										gap:10px ; background-color:transparent ; 

											.networkimage.networkimagerowcolumnflow0351{

											
											position:relative !important; 
											margin: unset;
											padding: unset;
											transform-origin: top left;
											
											
											
											width:40px!important ; 
											height:40px!important ; 
											
											display: inherit;

											flex: unset;
											border-radius:29px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

											
}

											.column.columnrowcolumnflow0353{

											
											position:relative !important; 
											margin: unset;
											padding: unset;
											transform-origin: top left;
											
											
											
											margin-top: 0px ; 
											margin-bottom: 0px ; 
											width:auto!important ; 
											flex:1 !important; 
											height: calc(100% - (0px + 0px)) !important;
											
											display: flex;
											flex-wrap: nowrap;
											flex: initial;
											flex-direction: column;
											justify-content:space-between ; background-color: transparent ; 

												.text.textcolumnrowcolumnflow03531{

												
												position:relative !important; 
												margin: unset;
												padding: unset;
												transform-origin: top left;
												
												
												margin-left: 0px ; 
												
												margin-right: 0px ; 
												height:25px!important ; 
												width: calc(100% - (0px + 0px)) !important;
												
												font-size:20px ; 
												.text-content{
												width: 100%;
												font-weight: bold;
												}

												
}

												.text.textcolumnrowcolumnflow035312{

												
												position:relative !important; 
												margin: unset;
												padding: unset;
												transform-origin: top left;
												
												
												margin-left: 0px ; 
												
												margin-right: 0px ; 
												height:25px!important ; 
												width: calc(100% - (0px + 0px)) !important;
												
												font-size:15px ; 
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

										.flow.flowcolumnmainContent111152{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										left:0px ; 
										
										width:auto!important ; 
										height:auto!important ; 
										alignItems:center !important; 
										
										display: flex;
										flex-wrap: wrap;
										flex: initial;
										gap:8px ; background-color:transparent ; 

											.row.tagRow611{

											
											position:relative !important; 
											margin: unset;
											padding: unset;
											transform-origin: top left;
											
											
											
											width:auto!important ; 
											height:auto!important ; 
											
											display: flex;
											flex-wrap: nowrap;
											flex: initial;
											flex-direction: row;background-color:transparent ; 
											border-radius:8px ; 
											padding-top:3px ; 
											padding-bottom:3px ; 
											padding-left:3px ; 
											padding-right:3px ; 

												.text.tagText{

												align-self: flex-start;
												position:relative !important; 
												margin: unset;
												padding: unset;
												transform-origin: top left;
												
												
												
												width:auto!important ; 
												height:auto!important ; 
												
												color:#fff ; 
												font-size:10px ; 
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

									
}

									.button.buttoncolumnmainContent1113{

									height: auto;
									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									
									width:auto!important ; 
									height:38px!important ; 
									
									text-transform:unset ; 
									min-width: auto;
									padding-left: 16px;
									padding-right: 16px;

									i.v-icon{
									}
									}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							margin: unset;
							transform-origin: top left;
							
							
							
							order:2 !important; 
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-top: 0px ; 
								margin-right: 0px ; 
								margin-bottom: 0px ; 
								justifyContent:center !important; 
								borderBottom:solid 1px #80808042 !important; 
								width: calc(100% - (0px + 0px)) !important;
								height: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:10px ; background-color: transparent ; 
								padding-top:10px ; 
								padding-bottom:10px ; 
								padding-left:10px ; 
								padding-right:10px ; 

									.button.buttoncolumn011{

									height: auto;
									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:40px!important ; 
									border:solid 1px #d7d7d7 !important; 
									width: calc(100% - (0px + 0px)) !important;
									
									border-radius:0px ; 
									text-transform:unset ; 
									min-width: auto;
									padding-left: 16px;
									padding-right: 16px;

									i.v-icon{
									}
									}

								
}

							
}

							
}

						
}

						.grid.gridmainContent13{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						background-color: transparent ; 

							.col.colgridmainContent13{

							
							position:relative !important; 
							margin: unset;
							transform-origin: top left;
							
							
							
							order:1 !important; 
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
								
								left: calc(50% + 0px) ; 
								
								margin-top: 0px ; 
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:10px ; background-color: transparent ; 
								border-radius:10px ; 
								padding-top:15px ; 
								padding-bottom:15px ; 
								padding-left:15px ; 
								padding-right:15px ; 

									.column.columncolumnmainContent1119{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
									display: flex;
									flex-wrap: nowrap;
									flex: initial;
									flex-direction: column;background-color: transparent ; 
									border-radius:10px ; 

										.column.introductionContent{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										display: flex;
										flex-wrap: nowrap;
										flex: initial;
										flex-direction: column;background-color: transparent ; 

										
}

										.networkimage.firstImage{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

										
}

										.column.bodyContent{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										display: flex;
										flex-wrap: nowrap;
										flex: initial;
										flex-direction: column;background-color: transparent ; 

										
}

										.networkimage.firstImage5{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

										
}

										.column.conclusionContent{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										display: flex;
										flex-wrap: nowrap;
										flex: initial;
										flex-direction: column;background-color: transparent ; 

										
}

									
}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							margin: unset;
							transform-origin: top left;
							
							
							
							order:2 !important; 
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:10px ; background-color: transparent ; 
								border-radius:10px ; 
								padding-top:15px ; 
								padding-bottom:15px ; 
								padding-left:15px ; 
								padding-right:15px ; 

									.text.textcolumnmainContent1113{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									width: calc(100% - (0px + 0px)) !important;
									
									font-size:20px ; 
									.text-content{
									width: 100%;
									font-weight: bold;
									}

									
}

									.grid.gridcolumnmainContent1115{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									background-color: transparent ; 

										.col.colgridcolumnmainContent11151{

										
										position:relative !important; 
										margin: unset;
										transform-origin: top left;
										
										
										
										

										::v-deep .col-container{position: relative;

										height: 100%;

										width: 100%;

											.object.articleboxmainContent1111{

											
											position:relative !important; 
											margin: unset;
											padding: unset;
											transform-origin: top left;
											
											
											margin-left: 0px ; 
											
											margin-right: 0px ; 
											width: calc(100% - (0px + 0px)) !important;
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

										
}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic3{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015139222{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.mainContent1{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.networkimage.networkimagecolumnmainContent111312{

						
						position:relative !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							.rectangle.rectanglenetworkimagecolumnmainContent111311{

							
							position:absolute !important; 
							
							
							
							
							
							

							
}

							.text.textcolumnmainContent11112{

							
							position:absolute !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.grid.gridmainContent1{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.flow.topLeft{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.flow.flowcolumnmainContent11111{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.row.rowcolumnflow03541{

										
										position:relative !important; 
										
										
										
										
										
										display: flex;
										flex-wrap: nowrap;
										flex: initial;

											.networkimage.networkimagerowcolumnflow0351{

											
											position:relative !important; 
											
											
											
											
											
											display: inherit;

											flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

											
}

											.column.columnrowcolumnflow0353{

											
											position:relative !important; 
											
											
											
											
											
											
											
											

												.text.textcolumnrowcolumnflow03531{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

												.text.textcolumnrowcolumnflow035312{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

										.flow.flowcolumnmainContent111152{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

											.row.tagRow611{

											
											position:relative !important; 
											
											
											
											
											
											display: flex;
											flex-wrap: nowrap;
											flex: initial;

												.text.tagText{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

									
}

									.button.buttoncolumnmainContent1113{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.button.buttoncolumn011{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

						
}

						.grid.gridmainContent13{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.column.columncolumnmainContent1119{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.column.introductionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.bodyContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage5{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.conclusionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

									
}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.text.textcolumnmainContent1113{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

									.grid.gridcolumnmainContent1115{

									
									position:relative !important; 
									
									
									
									
									

										.col.colgridcolumnmainContent11151{

										
										position:relative !important; 
										
										
										
										

										::v-deep .col-container{position: relative;

										height: 100%;

										width: 100%;

											.object.articleboxmainContent1111{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

										
}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic3{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015139222{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.mainContent1{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.networkimage.networkimagecolumnmainContent111312{

						
						position:relative !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							.rectangle.rectanglenetworkimagecolumnmainContent111311{

							
							position:absolute !important; 
							
							
							
							
							
							

							
}

							.text.textcolumnmainContent11112{

							
							position:absolute !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.grid.gridmainContent1{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.flow.topLeft{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.flow.flowcolumnmainContent11111{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.row.rowcolumnflow03541{

										
										position:relative !important; 
										
										
										
										
										
										display: flex;
										flex-wrap: nowrap;
										flex: initial;

											.networkimage.networkimagerowcolumnflow0351{

											
											position:relative !important; 
											
											
											
											
											
											display: inherit;

											flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

											
}

											.column.columnrowcolumnflow0353{

											
											position:relative !important; 
											
											
											
											
											
											
											
											

												.text.textcolumnrowcolumnflow03531{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

												.text.textcolumnrowcolumnflow035312{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

										.flow.flowcolumnmainContent111152{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

											.row.tagRow611{

											
											position:relative !important; 
											
											
											
											
											
											display: flex;
											flex-wrap: nowrap;
											flex: initial;

												.text.tagText{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

									
}

									.button.buttoncolumnmainContent1113{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.button.buttoncolumn011{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

						
}

						.grid.gridmainContent13{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.column.columncolumnmainContent1119{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.column.introductionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.bodyContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage5{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.conclusionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

									
}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.text.textcolumnmainContent1113{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

									.grid.gridcolumnmainContent1115{

									
									position:relative !important; 
									
									
									
									
									

										.col.colgridcolumnmainContent11151{

										
										position:relative !important; 
										
										
										
										

										::v-deep .col-container{position: relative;

										height: 100%;

										width: 100%;

											.object.articleboxmainContent1111{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

										
}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic3{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015139222{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.mainContent1{

					
					position:relative !important; 
					
					
					
					
					width:90%!important ; 
					
					
					
					

						.networkimage.networkimagecolumnmainContent111312{

						
						position:relative !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							.rectangle.rectanglenetworkimagecolumnmainContent111311{

							
							position:absolute !important; 
							
							
							
							
							
							

							
}

							.text.textcolumnmainContent11112{

							
							position:absolute !important; 
							
							
							
							
							
							line-height:65px ; 
							font-size:35px ; 
							.text-content{
							width: 100%;
							}

							
}

						
}

						.grid.gridmainContent1{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.flow.topLeft{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.flow.flowcolumnmainContent11111{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.row.rowcolumnflow03541{

										
										position:relative !important; 
										
										
										
										
										
										display: flex;
										flex-wrap: nowrap;
										flex: initial;

											.networkimage.networkimagerowcolumnflow0351{

											
											position:relative !important; 
											
											
											
											
											
											display: inherit;

											flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

											
}

											.column.columnrowcolumnflow0353{

											
											position:relative !important; 
											
											
											
											
											
											
											
											

												.text.textcolumnrowcolumnflow03531{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

												.text.textcolumnrowcolumnflow035312{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

										.flow.flowcolumnmainContent111152{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

											.row.tagRow611{

											
											position:relative !important; 
											
											
											
											
											
											display: flex;
											flex-wrap: nowrap;
											flex: initial;

												.text.tagText{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

									
}

									.button.buttoncolumnmainContent1113{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.button.buttoncolumn011{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

						
}

						.grid.gridmainContent13{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.column.columncolumnmainContent1119{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.column.introductionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.bodyContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage5{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.conclusionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

									
}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.text.textcolumnmainContent1113{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

									.grid.gridcolumnmainContent1115{

									
									position:relative !important; 
									
									
									
									
									

										.col.colgridcolumnmainContent11151{

										
										position:relative !important; 
										
										
										
										

										::v-deep .col-container{position: relative;

										height: 100%;

										width: 100%;

											.object.articleboxmainContent1111{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

										
}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic3{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015139222{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.mainContent1{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.networkimage.networkimagecolumnmainContent111312{

						
						position:relative !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							.rectangle.rectanglenetworkimagecolumnmainContent111311{

							
							position:absolute !important; 
							
							
							
							
							
							

							
}

							.text.textcolumnmainContent11112{

							
							position:absolute !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.grid.gridmainContent1{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							order:2 !important; 
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.flow.topLeft{

								
								position:relative !important; 
								
								
								
								
								
								
								
								
								padding-top:30px ; 
								padding-bottom:30px ; 
								padding-left:30px ; 
								padding-right:30px ; 

									.flow.flowcolumnmainContent11111{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.row.rowcolumnflow03541{

										
										position:relative !important; 
										
										
										
										
										
										display: flex;
										flex-wrap: nowrap;
										flex: initial;

											.networkimage.networkimagerowcolumnflow0351{

											
											position:relative !important; 
											
											
											
											
											
											display: inherit;

											flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

											
}

											.column.columnrowcolumnflow0353{

											
											position:relative !important; 
											
											
											
											
											
											
											
											

												.text.textcolumnrowcolumnflow03531{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

												.text.textcolumnrowcolumnflow035312{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

										.flow.flowcolumnmainContent111152{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

											.row.tagRow611{

											
											position:relative !important; 
											
											
											
											
											
											display: flex;
											flex-wrap: nowrap;
											flex: initial;

												.text.tagText{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

									
}

									.button.buttoncolumnmainContent1113{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.button.buttoncolumn011{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

						
}

						.grid.gridmainContent13{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							order:2 !important; 
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								
								padding-top:30px ; 
								padding-bottom:30px ; 
								padding-left:30px ; 
								padding-right:30px ; 

									.column.columncolumnmainContent1119{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.column.introductionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.bodyContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage5{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.conclusionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

									
}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.text.textcolumnmainContent1113{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

									.grid.gridcolumnmainContent1115{

									
									position:relative !important; 
									
									
									
									
									

										.col.colgridcolumnmainContent11151{

										
										position:relative !important; 
										
										
										
										

										::v-deep .col-container{position: relative;

										height: 100%;

										width: 100%;

											.object.articleboxmainContent1111{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

										
}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic3{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015139222{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.mainContent1{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.networkimage.networkimagecolumnmainContent111312{

						
						position:relative !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

							.rectangle.rectanglenetworkimagecolumnmainContent111311{

							
							position:absolute !important; 
							
							
							
							
							
							

							
}

							.text.textcolumnmainContent11112{

							
							position:absolute !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.grid.gridmainContent1{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.flow.topLeft{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.flow.flowcolumnmainContent11111{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.row.rowcolumnflow03541{

										
										position:relative !important; 
										
										
										
										
										
										display: flex;
										flex-wrap: nowrap;
										flex: initial;

											.networkimage.networkimagerowcolumnflow0351{

											
											position:relative !important; 
											
											
											
											
											
											display: inherit;

											flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

											
}

											.column.columnrowcolumnflow0353{

											
											position:relative !important; 
											
											
											
											
											
											
											
											

												.text.textcolumnrowcolumnflow03531{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

												.text.textcolumnrowcolumnflow035312{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

										.flow.flowcolumnmainContent111152{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

											.row.tagRow611{

											
											position:relative !important; 
											
											
											
											
											
											display: flex;
											flex-wrap: nowrap;
											flex: initial;

												.text.tagText{

												
												position:relative !important; 
												
												
												
												
												
												.text-content{
												width: 100%;
												}

												
}

											
}

										
}

									
}

									.button.buttoncolumnmainContent1113{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.button.buttoncolumn011{

									height: auto;
									
									position:relative !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

								
}

							
}

							
}

						
}

						.grid.gridmainContent13{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridmainContent13{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.column.columncolumnmainContent1119{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.column.introductionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.bodyContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

										.networkimage.firstImage5{

										
										position:relative !important; 
										
										
										
										
										
										display: inherit;

										flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

										
}

										.column.conclusionContent{

										
										position:relative !important; 
										
										
										
										
										
										
										
										

										
}

									
}

								
}

							
}

							
}

							.col.colgridmainContent15{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columnmainContent111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.text.textcolumnmainContent1113{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

									.grid.gridcolumnmainContent1115{

									
									position:relative !important; 
									
									
									
									
									

										.col.colgridcolumnmainContent11151{

										
										position:relative !important; 
										
										
										
										

										::v-deep .col-container{position: relative;

										height: 100%;

										width: 100%;

											.object.articleboxmainContent1111{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

										
}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
