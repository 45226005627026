<template>
 <div>  
<div id="ContactOrgaModal"> 
         
 <DialogBoxModal :class="'object dialogboxmodal0 '"   id="dialogboxmodal0" ref="dialogboxmodal0"  v-bind:style="{}" :close = "close" 
 :title = "$t('Profile.contactDetails')" 
 :fullHeight = "false" 
 :maxWidth = "552" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedialogboxmodal01 '"   id="slottemplatedialogboxmodal01" ref="slottemplatedialogboxmodal01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedialogboxmodal011 '"   id="columnslottemplatedialogboxmodal011" ref="columnslottemplatedialogboxmodal011"  v-bind:style="{}">
 <div  :class="'row rowcolumnslottemplatedialogboxmodal0111 '"   id="rowcolumnslottemplatedialogboxmodal0111" ref="rowcolumnslottemplatedialogboxmodal0111"  v-bind:style="{}">
 <div  :class="'item itemrowcolumnslottemplatedialogboxmodal01111 '"   id="itemrowcolumnslottemplatedialogboxmodal01111" ref="itemrowcolumnslottemplatedialogboxmodal01111"  v-bind:style="{}">
 <Logo :class="'object logoitemrowcolumnslottemplatedialogboxmodal011111 '"   id="logoitemrowcolumnslottemplatedialogboxmodal011111" ref="logoitemrowcolumnslottemplatedialogboxmodal011111"  v-bind:style="{}" :mini = "true" 
 :size = "'26'" 
>
</Logo>  </div>
 <div  :class="'column columnrowcolumnslottemplatedialogboxmodal01113 '"   id="columnrowcolumnslottemplatedialogboxmodal01113" ref="columnrowcolumnslottemplatedialogboxmodal01113" :_flex = "'1'" 
 :_maxWidth = "'-webkit-fill-available'" 
  v-bind:style="{flex : '1',maxWidth : '-webkit-fill-available',}">
 <div  :class="'text textcolumnrowcolumnslottemplatedialogboxmodal011131 '"   id="textcolumnrowcolumnslottemplatedialogboxmodal011131" ref="textcolumnrowcolumnslottemplatedialogboxmodal011131"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Profile.yourProfile') }}</div>

   </div>
 <a  :href = "link" 
 :class="'link linkcolumnrowcolumnslottemplatedialogboxmodal011133 '"   id="linkcolumnrowcolumnslottemplatedialogboxmodal011133" ref="linkcolumnrowcolumnslottemplatedialogboxmodal011133" :target = "'_blank'" 
  v-bind:style="{}">
 <div  :class="'text textcolumnrowcolumnslottemplatedialogboxmodal0111311 '"   id="textcolumnrowcolumnslottemplatedialogboxmodal0111311" ref="textcolumnrowcolumnslottemplatedialogboxmodal0111311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ link }}</div>

   </div>  </a>  </div>  </div>
 <div  :class="'row rowcolumnslottemplatedialogboxmodal01112 '"   id="rowcolumnslottemplatedialogboxmodal01112" ref="rowcolumnslottemplatedialogboxmodal01112" v-if="websiteLink"  v-bind:style="{}">
 <div  :class="'item itemrowcolumnslottemplatedialogboxmodal01111 '"   id="itemrowcolumnslottemplatedialogboxmodal01111" ref="itemrowcolumnslottemplatedialogboxmodal01111"  v-bind:style="{}">
 <div  :class="'icon iconitemrowcolumnslottemplatedialogboxmodal011111 '"   id="iconitemrowcolumnslottemplatedialogboxmodal011111" ref="iconitemrowcolumnslottemplatedialogboxmodal011111"  v-bind:style="{fontSize : getUnit(25,'px'),}"><i  :class="'ion-ios-link'"></i></div>  </div>
 <div  :class="'column columnrowcolumnslottemplatedialogboxmodal01113 '"   id="columnrowcolumnslottemplatedialogboxmodal01113" ref="columnrowcolumnslottemplatedialogboxmodal01113" :_flex = "'1'" 
 :_maxWidth = "'-webkit-fill-available'" 
  v-bind:style="{flex : '1',maxWidth : '-webkit-fill-available',}">
 <div  :class="'text textcolumnrowcolumnslottemplatedialogboxmodal011131 '"   id="textcolumnrowcolumnslottemplatedialogboxmodal011131" ref="textcolumnrowcolumnslottemplatedialogboxmodal011131"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Profile.personalPage') }}</div>

   </div>
 <a  :href = "websiteLink" 
 :class="'link linkcolumnrowcolumnslottemplatedialogboxmodal011133 '"   id="linkcolumnrowcolumnslottemplatedialogboxmodal011133" ref="linkcolumnrowcolumnslottemplatedialogboxmodal011133" :target = "'_blank'" 
  v-bind:style="{}">
 <div  :class="'text textcolumnrowcolumnslottemplatedialogboxmodal0111311 '"   id="textcolumnrowcolumnslottemplatedialogboxmodal0111311" ref="textcolumnrowcolumnslottemplatedialogboxmodal0111311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ websiteLink }}</div>

   </div>  </a>  </div>  </div>
 <div  :class="'row rowcolumnslottemplatedialogboxmodal011123 '"   id="rowcolumnslottemplatedialogboxmodal011123" ref="rowcolumnslottemplatedialogboxmodal011123"  v-bind:style="{}">
 <div  :class="'item itemrowcolumnslottemplatedialogboxmodal01111 '"   id="itemrowcolumnslottemplatedialogboxmodal01111" ref="itemrowcolumnslottemplatedialogboxmodal01111"  v-bind:style="{}">
 <div  :class="'icon iconitemrowcolumnslottemplatedialogboxmodal011111 '"   id="iconitemrowcolumnslottemplatedialogboxmodal011111" ref="iconitemrowcolumnslottemplatedialogboxmodal011111"  v-bind:style="{fontSize : getUnit(25,'px'),}"><i  :class="'ion-ios-mail'"></i></div>  </div>
 <div  :class="'column columnrowcolumnslottemplatedialogboxmodal01113 '"   id="columnrowcolumnslottemplatedialogboxmodal01113" ref="columnrowcolumnslottemplatedialogboxmodal01113" :_flex = "'1'" 
 :_maxWidth = "'-webkit-fill-available'" 
  v-bind:style="{flex : '1',maxWidth : '-webkit-fill-available',}">
 <div  :class="'text textcolumnrowcolumnslottemplatedialogboxmodal011131 '"   id="textcolumnrowcolumnslottemplatedialogboxmodal011131" ref="textcolumnrowcolumnslottemplatedialogboxmodal011131"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Profile.personalPage') }}</div>

   </div>
 <a  :href = "'mailto:' + email" 
 :class="'link linkcolumnrowcolumnslottemplatedialogboxmodal011133 '"   id="linkcolumnrowcolumnslottemplatedialogboxmodal011133" ref="linkcolumnrowcolumnslottemplatedialogboxmodal011133"  v-bind:style="{}">
 <div  :class="'text textcolumnrowcolumnslottemplatedialogboxmodal0111311 '"   id="textcolumnrowcolumnslottemplatedialogboxmodal0111311" ref="textcolumnrowcolumnslottemplatedialogboxmodal0111311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ email }}</div>

   </div>  </a>  </div>  </div>  </div></div></template>
</DialogBoxModal>
 
      </div>
 </div> 
</template>
<script>
import DialogBoxModal from '../../../At/Sections/Modal/DialogBoxModal'
import Logo from '../../../At/Utils/Logo'


export default {
	name: 'ContactOrgaModal',

    components: {DialogBoxModal,Logo,},
	computed: {
	
		email: function() { 		
	if(this.orga){

		return this.orga.email

	}

	return null

}
,
		websiteLink: function() { 		
	if(this.orga){

		return this.orga.websiteLink

	}

	return null

}
,
		link: function() { 		
	if(this.orga){

		let slug = this.orga.slug;

		

		if(slug){

			return this.$store.state.webUrl + '/organisations/' + slug;

		}

	}

	return "/"

}
,
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		orga: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ContactOrgaModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.dialogboxmodal0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.row.rowcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:10px ; background-color:transparent ; 

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:26px!important ; 
			height:26px!important ; 
			

				.object.logoitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			flex:1 !important; 
			maxWidth:-webkit-fill-available !important; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				font-size:17px ; 
				text-overflow: ellipsis ; 
				.text-content{
				width: 100%;
				white-space: nowrap;
				overflow: hidden ;
				text-overflow: ellipsis ; 
				font-weight: bold;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				target:_blank !important; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					width: calc(100% - (0px + 0px)) !important;
					
					font-size:15px ; 
					text-overflow: ellipsis ; 
					.text-content{
					width: 100%;
					white-space: nowrap;
					overflow: hidden ;
					text-overflow: ellipsis ; 
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:10px ; background-color:transparent ; 

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:26px!important ; 
			height:26px!important ; 
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				font-size:25px ; 
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			flex:1 !important; 
			maxWidth:-webkit-fill-available !important; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				font-size:17px ; 
				text-overflow: ellipsis ; 
				.text-content{
				width: 100%;
				white-space: nowrap;
				overflow: hidden ;
				text-overflow: ellipsis ; 
				font-weight: bold;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				target:_blank !important; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					width: calc(100% - (0px + 0px)) !important;
					
					font-size:15px ; 
					text-overflow: ellipsis ; 
					.text-content{
					width: 100%;
					white-space: nowrap;
					overflow: hidden ;
					text-overflow: ellipsis ; 
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal011123{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:10px ; background-color:transparent ; 

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:26px!important ; 
			height:26px!important ; 
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				font-size:25px ; 
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			flex:1 !important; 
			maxWidth:-webkit-fill-available !important; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				font-size:17px ; 
				text-overflow: ellipsis ; 
				.text-content{
				width: 100%;
				white-space: nowrap;
				overflow: hidden ;
				text-overflow: ellipsis ; 
				font-weight: bold;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					width: calc(100% - (0px + 0px)) !important;
					
					font-size:15px ; 
					text-overflow: ellipsis ; 
					.text-content{
					width: 100%;
					white-space: nowrap;
					overflow: hidden ;
					text-overflow: ellipsis ; 
					}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.object.logoitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal011123{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.object.logoitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal011123{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.object.logoitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal011123{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.object.logoitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal011123{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.object.logoitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.row.rowcolumnslottemplatedialogboxmodal011123{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumnslottemplatedialogboxmodal01111{

			
			position:relative !important; 
			
			
			
			
			

				.icon.iconitemrowcolumnslottemplatedialogboxmodal011111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.column.columnrowcolumnslottemplatedialogboxmodal01113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrowcolumnslottemplatedialogboxmodal011131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.link.linkcolumnrowcolumnslottemplatedialogboxmodal011133{

				
				position:relative !important; 
				
				
				
				
				
				display: block;

					.text.textcolumnrowcolumnslottemplatedialogboxmodal0111311{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
