<template>
 <div>  
<div id="OrgaItem"> 
         
 <v-card 
 :class="'card card0 ' + `elevation-${1}`"   id="card0" ref="card0" :elevation = "1" 
 :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',}">
 <div  :class="'column columncard01 '"   id="columncard01" ref="columncard01"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'column columncolumncard017 '"   id="columncolumncard017" ref="columncolumncard017"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagecolumncard0111 '"   id="networkimagecolumncard0111" ref="networkimagecolumncard0111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "orgaBanner" 
>  </v-img>
 <div  :class="'item itemcolumncard0132 '"   id="itemcolumncard0132" ref="itemcolumncard0132"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitemcolumncard01322 '"   id="rectangleitemcolumncard01322" ref="rectangleitemcolumncard01322"  v-bind:style="{backgroundColor : '#fff',}">
 <v-img  :class="'networkimage networkimageitemcolumncard0131 '"   id="networkimageitemcolumncard0131" ref="networkimageitemcolumncard0131"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "orgaLogo" 
>  </v-img>  </div>  </div>  </div>
 <div  :class="'column columncolumncard015 '"   id="columncolumncard015" ref="columncolumncard015" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'text textcolumncolumncard0151 '"   id="textcolumncolumncard0151" ref="textcolumncolumncard0151"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ orgaName }}</div>

   </div>
 <div  :class="'text textcolumncolumncard01512 '"   id="textcolumncolumncard01512" ref="textcolumncolumncard01512"  v-bind:style="{color : '#808080',}">
<div class="text-content" :style = "{}">{{ m_orgaNumberMembers }}</div>

   </div>  </div>  </div>
 <router-link  :class="'routerlink routerlinkcard03 '"   id="routerlinkcard03" ref="routerlinkcard03"  v-bind:style="{}" :to = "orgaLink" 
>  </router-link>  </v-card>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'OrgaItem',

    components: {},
	computed: {
	
		attributes: function() { 		
	return this.organisation.attributes

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		orgaName: function() { 		
	if(this.attributes){

		return this.attributes.name

	}

	return null

}
,
		orgaNumberMembers: function() { 		
	if(this.attributes){	

		if(this.attributes.members){

			return this.attributes.members.data.length

		}

	}

	return 0

}
,
		m_orgaNumberMembers: function() { 		
	return this.orgaNumberMembers + " " + this.$t('Members.members')

}
,
		orgaLogo: function() { 		
	if(this.attributes){

		if(this.attributes.logo){

			let item = this.attributes.logo?.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.attributes.name)

	}

	return "https://picsum.photos/300"

}
,
		orgaBanner: function() { 		
	if(this.attributes){

		if(this.attributes.bannerImage){

			let item = this.attributes.bannerImage?.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomImage(1000)

	}

	return Main.getRandomImage(1000)

}
,
		orgaLink: function() { 		
	if(this.attributes){

		let slug = this.attributes.slug;

		

		if(slug){

			return '/organisations/' + slug;

		}

	}

	return "/"

}
,
	},
	props: {
	
		organisation: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#OrgaItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
overflow:hidden !important; 
background-color:#ffffff ; 
border-radius:8px ; max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
margin-bottom: 0px ; 
width: calc(100% - (0px + 0px)) !important;
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
justify-content:space-between ; background-color: transparent ; 

	.column.columncolumncard017{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:62px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:62px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		

			.rectangle.rectangleitemcolumncard01322{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			bottom: 0px ; 
			width:104px!important ; 
			height:104px!important ; 
			
			background-color:#fff ; 

				.networkimage.networkimageitemcolumncard0131{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

				
}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	flex:1 !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:4px ; background-color: transparent ; 
	padding-top:12px ; 
	padding-bottom:12px ; 
	padding-left:12px ; 
	padding-right:12px ; 

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:24px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#808080 ; 
		font-size:15px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 



}


}


@media (max-width: 600px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumncard01322{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.networkimageitemcolumncard0131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}

@media (min-width: 601px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumncard01322{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.networkimageitemcolumncard0131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}

@media (min-width: 961px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumncard01322{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.networkimageitemcolumncard0131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}

@media (min-width: 1265px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumncard01322{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.networkimageitemcolumncard0131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}

@media (min-width: 1905px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumncard01322{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.networkimageitemcolumncard0131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}
}
</style>
