import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

import vuetifyFR from 'vuetify/lib/locale/fr'
import vuetifyEN from 'vuetify/lib/locale/en'
import vuetifyDE from 'vuetify/lib/locale/de'

import store from "../store";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { vuetifyFR, vuetifyEN, vuetifyDE },
    current: 'fr',
  },
  theme: {
    themes: {
      light: {
        primary: store.state.primaryColor,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
});
