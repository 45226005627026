<template>
 <div>  
<div id="AdminOrgaMemberForm"> 
         
 <div  :class="'column columncolumncolumn0712 '"   id="columncolumncolumn0712" ref="columncolumncolumn0712"  v-bind:style="{}">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn071293115 '"   id="admincomboviewcolgridcolumncolumncolumn071293115" ref="admincomboviewcolgridcolumncolumncolumn071293115"  v-bind:style="{}" :updateModel = "setUser" 
 :tableName = "'users'" 
 :filterAttribute = "'email'" 
 :filtersForGet = "filtersForGetPages" 
 :label = "$t('Admin.user')" 
 :p_model = "user" 
>
</AdminComboView>
 <v-text-field  :label = "$t('Admin.adhesionDate') + '*'" 
 :dense = "true" 
 :type = "'datetime-local'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal011311 '"   id="textfieldcolumnslottemplatedialogboxmodal011311" ref="textfieldcolumnslottemplatedialogboxmodal011311" v-model="adhesionDate"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-text-field  :label = "$t('Admin.position')" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal0113115 '"   id="textfieldcolumnslottemplatedialogboxmodal0113115" ref="textfieldcolumnslottemplatedialogboxmodal0113115" v-model="position"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <div  :class="'text errorText '"   id="errorText" ref="errorText" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import AdminComboView from '../../../../At/Components/AdminPage/AdminComboView'

import * as Main from '../../../../Js/main'
import * as Request from '../../../../Js/requests'
export default {
	name: 'AdminOrgaMemberForm',

    components: {AdminComboView,},
	methods: {
	
		loadData: function() { 		
	this.position = this.entity?.position

	this.adhesionDate = this.entity?.adhesionDate

	this.user = this.entity?.user

	

	this.loading = false

}
,
		createEntry: function() { 		
	let data = {}

	

	if(this.parentID){ data.organisation = this.parentID }

	if(this.position){ data.position = this.position }

	if(this.adhesionDate){ data.adhesionDate = this.adhesionDate }

	if(this.user){ data.user = this.user }

	data.publishedAt = new Date().toISOString()

	

  const formData = new FormData();

	

	formData.append('data', JSON.stringify(data));

	

	Request.createEntry(this.tableName,formData)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryCreated')) 

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		updateEntry: function() { 		
	let data = {}

	

	if(this.position){ data.position = this.position }

	if(this.adhesionDate){ data.adhesionDate = this.adhesionDate }

	if(this.user){ data.user = this.user }

	

	Request.updateEntry(this.tableName, this.entity.id, data)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryUpdated'))

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		setUser: function(val) { 		
	this.user = val

}
,
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true	

	this.$awn.alert(message)

}
,
	},
	mounted(){
			
	this.loadData()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		entityID: function() { 		
	return this.entity?.id

}
,
		filtersForGetPages: function() { 			
	let filter = {}

	

	return filter

}
,
	},
	watch: {
	
		position: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		adhesionDate: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		user: function(val) { 		
	if(!this.loading) this.changed = true

}
,
	},
	data(){
	return {
		
		position:  null		,
		adhesionDate:  null		,
		user:  null		,
		loading:  true		,
		changed:  false		,
		error:  null		,
		errorVisible:  false		,
		tableName:  'orga-members'		,
		}
	},
	props: {
	
		parentID: {					required: false,			default: () =>  null,		},
		entity: {					required: false,			default: () =>  null,		},
		updateSelectedItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminOrgaMemberForm{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.columncolumncolumn0712{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

.object.admincomboviewcolgridcolumncolumncolumn071293115{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

font-size:12px ; 
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

font-size:12px ; 
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.text.errorText{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

color:#ff1a05 ; 
line-height:10px ; 
font-size:10px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;text-decoration: underline
}


}


}


@media (max-width: 600px) {


.column.columncolumncolumn0712{


position:relative !important; 









.object.admincomboviewcolgridcolumncolumncolumn071293115{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.column.columncolumncolumn0712{


position:relative !important; 









.object.admincomboviewcolgridcolumncolumncolumn071293115{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.column.columncolumncolumn0712{


position:relative !important; 









.object.admincomboviewcolgridcolumncolumncolumn071293115{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.text.errorText{


position:relative !important; 





line-height:15px ; 
font-size:15px ; 
.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.column.columncolumncolumn0712{


position:relative !important; 









.object.admincomboviewcolgridcolumncolumncolumn071293115{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.column.columncolumncolumn0712{


position:relative !important; 









.object.admincomboviewcolgridcolumncolumncolumn071293115{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
