<template>
 <div>  
<div id="LDMainSection"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'column columncolumn01 '"   id="columncolumn01" ref="columncolumn01"  v-bind:style="{}">
 <div  :class="'text textcolumncolumn011 '"   id="textcolumncolumn011" ref="textcolumncolumn011"  v-bind:style="{color : '#000',}">
<div class="text-content" :style = "{}">{{ $t('Learning.whatIncluded') }}</div>

   </div>
 <div  :class="'flow rowcolumncolumn01334 '"   id="rowcolumncolumn01334" ref="rowcolumncolumn01334" v-if="isFree"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerowcolumncolumn01335 '"   id="networkimagerowcolumncolumn01335" ref="networkimagerowcolumncolumn01335"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "'https://api.iconify.design/twemoji:money-with-wings.svg?color=%23e07f10'" 
>  </v-img>
 <div  :class="'text textrowcolumncolumn0133 '"   id="textrowcolumncolumn0133" ref="textrowcolumncolumn0133"  v-bind:style="{color : '#7d7d7d',}">
<div class="text-content" :style = "{}">{{ $t('Learning.free') }}</div>

   </div>  </div>
 <div  :class="'flow rowcolumncolumn0133 '"   id="rowcolumncolumn0133" ref="rowcolumncolumn0133" v-if="hasWorkshop"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerowcolumncolumn01335 '"   id="networkimagerowcolumncolumn01335" ref="networkimagerowcolumncolumn01335"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "'https://api.iconify.design/twemoji:puzzle-piece.svg?color=%23e07f10'" 
>  </v-img>
 <div  :class="'text textrowcolumncolumn0133 '"   id="textrowcolumncolumn0133" ref="textrowcolumncolumn0133"  v-bind:style="{color : '#7d7d7d',}">
<div class="text-content" :style = "{}">{{ $t('Learning.hasWorkshop') }}</div>

   </div>  </div>  </div>
 <ExtendableTextSection :class="'object extendabletextsectioncolumn03 '"   id="extendabletextsectioncolumn03" ref="extendabletextsectioncolumn03" v-if="description"  v-bind:style="{}" :title = "$t('Learning.description')" 
 :text = "description" 
 :maxCharacters = "500" 
 :hasHtmlCode = "true" 
>
</ExtendableTextSection>
 <ExtendableTextSection :class="'object extendabletextsectioncolumn033 '"   id="extendabletextsectioncolumn033" ref="extendabletextsectioncolumn033" v-if="content"  v-bind:style="{}" :title = "contentTitle" 
 :text = "content" 
 :maxCharacters = "500" 
 :hasHtmlCode = "true" 
>
</ExtendableTextSection>
 <ExtendableTagSection :class="'object extendabletagsectioncolumn07 '"   id="extendabletagsectioncolumn07" ref="extendabletagsectioncolumn07" v-if="tags.length > 0"  v-bind:style="{}" :title = "$t('LearningDetails.competencesCovered')" 
 :list = "tags" 
>
</ExtendableTagSection>
 <LDCertifSection :class="'object ldcertifsectioncolumn09 '"   id="ldcertifsectioncolumn09" ref="ldcertifsectioncolumn09" v-if="!isWebinar"  v-bind:style="{}">
</LDCertifSection>
 <LDFormator :class="'object ldformatorcolumn011 '"   id="ldformatorcolumn011" ref="ldformatorcolumn011"  v-bind:style="{}" :instructorID = "instructorID" 
 :fromMoodle = "fromMoodle" 
 :instructorEmail = "moodleInstructorEmail" 
>
</LDFormator>
 <div  :class="'column columncolumn013 '"   id="columncolumn013" ref="columncolumn013"  v-bind:style="{}">
 <div  :class="'text textrowcolumncolumn013311 '"   id="textrowcolumncolumn013311" ref="textrowcolumncolumn013311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Autres cours qui pourraient vous intéresser' }}</div>

   </div>
 <v-row  :class="'grid gridcolumncolumn0133 '"   id="gridcolumncolumn0133" ref="gridcolumncolumn0133"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumn01331 '"   id="colgridcolumncolumn01331" ref="colgridcolumncolumn01331" v-for="(item,index) in moodleCours" :key="'colgridcolumncolumn01331' + index"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
 :sm = "6" 
><div class="col-container">
 <SwiperCard :class="'object swipercardcolgridcolumncolumn013311 '"   id="swipercardcolgridcolumncolumn013311" ref="swipercardcolgridcolumncolumn013311"  v-bind:style="{}" :fromMoodle = "true" 
 :moodleInfo = "item" 
 :baseUrl = "'learning'" 
>
</SwiperCard></div></v-col>  </v-row>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import ExtendableTextSection from '../../../At/Utils/ExtendableTextSection'
import ExtendableTagSection from '../../../At/Utils/ExtendableTagSection'
import LDCertifSection from '../../../At/Components/Learning/LDCertifSection'
import LDFormator from '../../../At/Components/Learning/LDFormator'
import SwiperCard from '../../../At/Utils/SwiperCard'

import * as Request from '../../../Js/requests'
export default {
	name: 'LDMainSection',

    components: {ExtendableTextSection,ExtendableTagSection,LDCertifSection,LDFormator,SwiperCard,},
	methods: {
	
		getMoodleCoursByField: function(){ 		
	Request.getMoodleCoursByField()

	.then((res) => {

		this.moodleCours = res.data.courses

			.filter(c => c.id != 1).reverse().slice(0, 6)

	})

}
,
	},
	mounted(){
			
	this.getMoodleCoursByField()

	},
	computed: {
	
		contentTitle: function() { 		
	if(this.isWebinar){

		return this.$t('LearningDetails.webinarContent')

	}

	return this.$t('LearningDetails.courseContent')

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		moodleInstructorEmail: function() { 		
	if(this.moodleInstructor){

		return this.moodleInstructor.email

	}

}
,
	},
	data(){
	return {
		
		moodleCours:  []		,
		}
	},
	props: {
	
		description: {					required: false,			default: () =>  null,		},
		content: {					required: false,			default: () =>  null,		},
		tags: {					required: false,			default: () =>  [],		},
		instructorID: {					required: false,			default: () =>  null,		},
		fromMoodle: {					required: false,			default: () =>  false,		},
		moodleInstructor: {					required: false,			default: () =>  null,		},
		isWebinar: {			type: Boolean,			required: false,			default: () =>  false,		},
		isFree: {			type: Boolean,			required: false,			default: () =>  false,		},
		hasWorkshop: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LDMainSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

.column.columncolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
margin-bottom: 24px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:6px ; background-color: transparent ; 

	.text.textcolumncolumn011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	color:#000 ; 
	font-size:16px ; 
	text-transform:uppercase; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.flow.rowcolumncolumn01334{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: wrap;
	flex: initial;
	gap:4px ; background-color:transparent ; 

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:24px!important ; 
		height:24px!important ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:auto!important ; 
		
		color:#7d7d7d ; 
		font-size:14px ; 
		align-items:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

	
}

	.flow.rowcolumncolumn0133{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: wrap;
	flex: initial;
	gap:4px ; background-color:transparent ; 

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:24px!important ; 
		height:24px!important ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:auto!important ; 
		
		color:#7d7d7d ; 
		font-size:14px ; 
		align-items:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

	
}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
margin-bottom: 30px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletextsectioncolumn033{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
margin-bottom: 30px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletagsectioncolumn07{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
margin-bottom: 30px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldcertifsectioncolumn09{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
margin-bottom: 30px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldformatorcolumn011{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
margin-bottom: 30px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn013{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

	.text.textrowcolumncolumn013311{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:auto!important ; 
	
	font-size:22px ; 
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.grid.gridcolumncolumn0133{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	background-color: transparent ; 

		.col.colgridcolumncolumn01331{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.object.swipercardcolgridcolumncolumn013311{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:260px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcolumncolumn01334{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.rowcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletextsectioncolumn033{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletagsectioncolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldcertifsectioncolumn09{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldformatorcolumn011{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn013{


position:relative !important; 









	.text.textrowcolumncolumn013311{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn01331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.swipercardcolgridcolumncolumn013311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcolumncolumn01334{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.rowcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletextsectioncolumn033{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletagsectioncolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldcertifsectioncolumn09{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldformatorcolumn011{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn013{


position:relative !important; 









	.text.textrowcolumncolumn013311{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn01331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.swipercardcolgridcolumncolumn013311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcolumncolumn01334{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.rowcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletextsectioncolumn033{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletagsectioncolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldcertifsectioncolumn09{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldformatorcolumn011{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn013{


position:relative !important; 









	.text.textrowcolumncolumn013311{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn01331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.swipercardcolgridcolumncolumn013311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcolumncolumn01334{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.rowcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletextsectioncolumn033{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletagsectioncolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldcertifsectioncolumn09{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldformatorcolumn011{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn013{


position:relative !important; 









	.text.textrowcolumncolumn013311{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn01331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.swipercardcolgridcolumncolumn013311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcolumncolumn01334{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.rowcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagerowcolumncolumn01335{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.text.textrowcolumncolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.extendabletextsectioncolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletextsectioncolumn033{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.extendabletagsectioncolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldcertifsectioncolumn09{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldformatorcolumn011{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.column.columncolumn013{


position:relative !important; 









	.text.textrowcolumncolumn013311{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn0133{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn01331{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.swipercardcolgridcolumncolumn013311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}
}
</style>
