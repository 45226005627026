<template>
 <div>  
<div id="ProfileItem"> 
         
 <v-card 
 :class="'card card0 ' + `elevation-${1}`"   id="card0" ref="card0" :elevation = "1" 
 :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',}">
 <div  :class="'column columncard01 '"   id="columncard01" ref="columncard01"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'column columncolumncard017 '"   id="columncolumncard017" ref="columncolumncard017"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagecolumncard0111 '"   id="networkimagecolumncard0111" ref="networkimagecolumncard0111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "profileBg" 
>  </v-img>
 <div  :class="'item itemcolumncard0132 '"   id="itemcolumncard0132" ref="itemcolumncard0132"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageitemcolumncard0131 '"   id="networkimageitemcolumncard0131" ref="networkimageitemcolumncard0131"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "profileImage" 
>  </v-img>  </div>  </div>
 <div  :class="'column columncolumncard015 '"   id="columncolumncard015" ref="columncolumncard015" :_flex = "'1'" 
  v-bind:style="{flex : '1',justifyContent : 'space-around',}">
 <div  :class="'text textcolumncolumncard0151 '"   id="textcolumncolumncard0151" ref="textcolumncolumncard0151"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ userName }}</div>

   </div>
 <div  :class="'text textcolumncolumncard01512 '"   id="textcolumncolumncard01512" ref="textcolumncolumncard01512" v-if="showTitle"  v-bind:style="{color : '#808080',}">
<div class="text-content" :style = "{}">{{ m_profileTitle }}</div>

   </div>
 <v-img  :class="'networkimage imagecolumncolumncard0155 '"   id="imagecolumncolumncard0155" ref="imagecolumncolumncard0155" v-if="univ != null"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "univLogo" 
>  </v-img>  </div>  </div>
 <router-link  :class="'routerlink routerlinkcard03 '"   id="routerlinkcard03" ref="routerlinkcard03"  v-bind:style="{}" :to = "userLink" 
>  </router-link>  </v-card>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../Js/main'
import * as Request from '../../Js/requests'
export default {
	name: 'ProfileItem',

    components: {},
	computed: {
	
		univ: function() { 		
	return this.mentor?.attributes?.university?.data?.attributes

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		id: function() { 		
	return this.user.id

}
,
		slug: function() { 		
	return this.user.slug

}
,
		userName: function() { 		
	if(this.user){

		return this.user.username

	}

	return null

}
,
		profileTitle: function() { 		
	if(this.user){

		return this.user.title

	}

	return null

}
,
		m_profileTitle: function() { 		
	if(this.profileTitle){	

		let max = 60

		

		if(this.profileTitle.length > max){

			return this.profileTitle.slice(0, max) + '...'

		}

		return this.profileTitle

	}

	return null

}
,
		profileImage: function() { 		
	if(this.user){

		if(this.user.image){

			let item = this.user.image;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.user.username)

	}

	return "https://picsum.photos/300"

}
,
		profileBg: function() { 		
	if(this.user){

		if(this.user.bgImage){

			let item = this.user.bgImage;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomImage(1000)

	}

	return Main.getRandomImage(1000)

}
,
		univLogo: function() { 		
	if(this.univ){

		if(this.univ.logo){

			let item = this.univ.logo.data?.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomImage(1000)

	}

	return Main.getRandomImage(1000)

}
,
		userLink: function() { 		
	if(this.user){

			let slug = this.user.slug;

			

			if(slug){

				return '/profile/' + slug;

			}else{

				return '/profile/id/' + this.user.id;

			}

	}

	return "/"

}
,
		showTitle: function() { 		
	return (this.univ == null) && (this.profileTitle != null)

}
,
	},
	props: {
	
		user: {					required: false,			default: () =>  null,		},
		mentor: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ProfileItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
overflow:hidden !important; 
background-color:#ffffff ; 
border-radius:8px ; max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
margin-bottom: 0px ; 
width: calc(100% - (0px + 0px)) !important;
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
justify-content:space-between ; background-color: transparent ; 

	.column.columncolumncard017{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:62px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:62px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		

			.networkimage.networkimageitemcolumncard0131{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			bottom: 0px ; 
			width:104px!important ; 
			height:104px!important ; 
			
			display: inherit;

			flex: unset;
			border-radius:52px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	flex:1 !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:4px ; 
	justify-content:space-around ; background-color: transparent ; 
	padding-top:12px ; 
	padding-bottom:12px ; 
	padding-left:12px ; 
	padding-right:12px ; 

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		line-height:20px ; 
		font-size:20px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#808080 ; 
		font-size:12px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.networkimage.imagecolumncolumncard0155{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:40px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 



}


}


@media (max-width: 600px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.networkimage.networkimageitemcolumncard0131{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.networkimage.imagecolumncolumncard0155{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}

@media (min-width: 601px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.networkimage.networkimageitemcolumncard0131{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.networkimage.imagecolumncolumncard0155{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}

@media (min-width: 961px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.networkimage.networkimageitemcolumncard0131{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.networkimage.imagecolumncolumncard0155{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}

@media (min-width: 1265px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.networkimage.networkimageitemcolumncard0131{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.networkimage.imagecolumncolumncard0155{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}

@media (min-width: 1905px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.column.columncolumncard017{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkimage.networkimagecolumncard0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemcolumncard0132{

		
		position:relative !important; 
		
		
		
		
		

			.networkimage.networkimageitemcolumncard0131{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolumncard015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard01512{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.networkimage.imagecolumncolumncard0155{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}

.routerlink.routerlinkcard03{


position:absolute !important; 







}


}
}
}
</style>
