<template>
 <div>  
<div id="MembersSearch"> 
         
 <ResultGroup :class="'object resultgroupmainColumn12 '"   id="resultgroupmainColumn12" ref="resultgroupmainColumn12"  v-bind:style="{}" :action = "m_selectEntity" 
 :title = "memberFoundNumber" 
 :actionText = "moreText" 
 :actived = "selected" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateresultgroupmainColumn11 '"   id="slottemplateresultgroupmainColumn11" ref="slottemplateresultgroupmainColumn11"  v-bind:style="{}">
 <div  :class="'column columnslottemplateresultgroupmainColumn111 '"   id="columnslottemplateresultgroupmainColumn111" ref="columnslottemplateresultgroupmainColumn111"  v-bind:style="{}">
 <v-row  :class="'grid gridmainContent311 '"   id="gridmainContent311" ref="gridmainContent311"  v-bind:style="{}">
 <v-col  :class="'col colgridmainContent31 '"   id="colgridmainContent31" ref="colgridmainContent31" v-for="(item,index) in sliced_filtredMemb" :key="'colgridmainContent31' + index"  v-bind:style="{}" :cols = "12" 
 :lg = "3" 
 :md = "4" 
 :sm = "6" 
><div class="col-container">
 <ProfileItem :class="'object profileitemcolgridmainContent311 '"   id="profileitemcolgridmainContent311" ref="profileitemcolgridmainContent311"  v-bind:style="{}" :user = "item" 
>
</ProfileItem></div></v-col>  </v-row>
 <v-pagination 
 :color = "primaryColor" 
 :length = "nbMemberPages" 
 :class="'pagination memberPagination '"   id="memberPagination" ref="memberPagination" v-if="nbMemberPages" v-model="curMemberPage"  v-bind:style="{}"></v-pagination>  </div></div></template>
</ResultGroup>
 
      </div>
 </div> 
</template>
<script>
import ResultGroup from '../../../At/Components/SearchPage/ResultGroup'
import ProfileItem from '../../../At/Components/ProfileItem'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'MembersSearch',

    components: {ResultGroup,ProfileItem,},
	methods: {
	
		getMembers: function() {		
	let config = {

		pagination: {

			page: this.curMemberPage,

			pageSize: this.memberPerPage,

			withCount: true

		},

		sort: ['id:desc']

	}

	

	Request.getTableArgs(this.entityName, config)

	.then((res) => {

		if(res.data.meta != undefined){	

			this.members = res.data.data;

			

			let pagination = res.data.meta.pagination;

			this.nbMemberPages = pagination.pageCount

		} else {

			this.members = res.data;

		}

		

		this.loading = false;

	}) 

}
,
		m_selectEntity: function() { 		
	this.selectEntity(this.entityName)

}
,
	},
	mounted(){
			
	this.getMembers()

	},
	computed: {
	
		memberPerPage: function() { 		
	return (this.selected ? 16 : 8)

}
,
		selected: function() { 		
	return this.selectedEntity == this.entityName

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		nbMemberPages: function() { 		
	return Math.ceil(this.members.length / this.memberPerPage)

}
,
		filtredMemb: function(){ 		
	if(this.filter){

		return this.members.filter(m => {

			let username = (m.username ?? "").toLowerCase();

			let title = (m.title ?? "").toLowerCase();

			let email = (m.email ?? "").toLowerCase();

			let description = (m.description ?? "").toLowerCase();

			let firstName = (m.firstName ?? "").toLowerCase();

			let lastName = (m.lastName ?? "").toLowerCase();

			let city = (m.city ?? "").toLowerCase();

			

			let filter = this.filter.toLowerCase()

			

			return username.includes(filter)

						|| title.includes(filter)

						|| email.includes(filter)

						|| description.includes(filter)

						|| firstName.includes(filter)

						|| lastName.includes(filter)

						|| city.includes(filter)

		})

	} else {

		return this.members

	}

}
,
		sliced_filtredMemb: function() { 		
	let start = (this.curMemberPage - 1) * this.memberPerPage

	let end = (this.curMemberPage) * this.memberPerPage

	

	return this.filtredMemb.slice(start,end)

}
,
		memberFoundNumber: function() { 		
		return this.$t('Members.members') 

			+ ' (' + this.filtredMemb.length + ')'

}
,
		moreText: function() { 		
	return this.$t('Search.seeAll')

		.replace('{{ entity }}', this.$t('Search.members'))

}
,
	},
	watch: {
	
		memberPerPage: function(val) { 		
	this.getMembers()

}
,
		filtredMemb: function(val) { 		
	this.setNbFinded(this.filtredMemb.length)

}
,
		curMemberPage: function(val) { 		
	this.getMembers()

}
,
	},
	data(){
	return {
		
		members:  []		,
		curMemberPage:  1		,
		loading:  true		,
		entityName:  "users"		,
		}
	},
	props: {
	
		filter: {					required: false,			default: () =>  null,		},
		selectedEntity: {					required: false,			default: () =>  null,		},
		setNbFinded: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		selectEntity: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#MembersSearch{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.resultgroupmainColumn12{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.grid.gridmainContent311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color: transparent ; 

			.col.colgridmainContent31{

			
			position:relative !important; 
			margin: unset;
			transform-origin: top left;
			
			
			
			height:auto!important ; 
			

			::v-deep .col-container{position: relative;

			height: auto;

			width: 100%;

				.object.profileitemcolgridmainContent311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:250px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.memberPagination{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		}

	
}


}


}


@media (max-width: 600px) {


.object.resultgroupmainColumn12{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent311{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.memberPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.resultgroupmainColumn12{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent311{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.memberPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.resultgroupmainColumn12{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent311{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.memberPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.resultgroupmainColumn12{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent311{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.memberPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.resultgroupmainColumn12{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent311{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.memberPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}
}
</style>
