<template>
 <div>  
<div id="PostMenu"> 
         
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0"  v-bind:style="{}">
 <div  :class="'column columncard01 '"   id="columncard01" ref="columncard01"  v-bind:style="{}">
 <v-btn tag="div" 
 :text = "true" 
 @click="item.action" :class="'button buttoncolumncard011 '"   id="buttoncolumncard011" ref="buttoncolumncard011" v-for="(item,index) in actions" :key="'buttoncolumncard011' + index" :_justifyContent = "'flex-start'" 
  v-bind:style="{justifyContent : 'flex-start',}"><v-icon  :style = "{}"  v-if = "item.icon !== undefined"  :left = "true" 
>{{ item.icon }}
</v-icon><span>{{ item.text }}</span>
</v-btn>  </div>  </v-card>
 
      </div>
 </div> 
</template>
<script>

import copy from 'copy-to-clipboard';
import * as Request from '../../../Js/requests'
export default {
	name: 'PostMenu',

    components: {},
	methods: {
	
		copyToClip: function() { 		
	copy(this.postUrl)

}
,
		deletePost: function() { 		
	Request.deleteEntry('posts', this.postID)

	.then(res => {

		location.reload()

	}).catch(err => {

		console.log(err)

		this.close()

	})

}
,
		reportPost: function() { 		
	let numberReports = (this.post.reports ?? 0)

	

	if(numberReports >= 5){

		this.deletePost()

	} else {	

		let config = {

			reports: numberReports + 1

		}

		

		Request.updateEntry('posts', this.postID, config)

		.then(res => {

				this.updateInfo(res.data.data)

				this.close()

				this.$awn.success(this.$t('Feed.succSignaled'))

		}).catch(err => {

		})

	}

}
,
	},
	computed: {
	
		author: function() { 		
	return this.post?.author?.data?.attributes

}
,
		authorID: function() { 		
	return this.post?.author?.data?.id

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		userID: function() { 		
	if(this.user){

		return this.user.id

	}

	return null

}
,
		actions: function() { 		
	let list = [

		{

			text: this.$t('PostItem.copyLink'),

			icon: "ion-ios-link",

			action: this.copyToClip

		}

	]

	

	if(this.userID == this.authorID){

		list = list.concat([	

			{

				text: this.$t('PostItem.deletePost'),

				icon: "ion-md-trash",

				action: this.deletePost

			},

		])

	} else {

		list = list.concat([	

			{

				text: this.$t('PostItem.reportPost'),

				icon: "ion-ios-flag",

				action: this.reportPost

			},

		])

	}

	

	return list

}
,
	},
	props: {
	
		post: {					required: false,			default: () =>  null,		},
		postUrl: {					required: false,			default: () =>  null,		},
		postID: {					required: false,			default: () =>  null,		},
		updateInfo: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#PostMenu{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.card.card0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color:#ffffff ; max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
padding-top:5px ; 
padding-bottom:5px ; 
padding-left:0px ; 
padding-right:0px ; 

	.button.buttoncolumncard011{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:56px!important ; 
	justifyContent:flex-start !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	border-radius:0px ; 
	font-size:14px ; 
	text-transform:capitalize ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	font-size:14px ; 
	}
	}


}


}


@media (max-width: 600px) {


.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.button.buttoncolumncard011{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 601px) {


.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.button.buttoncolumncard011{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 961px) {


.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.button.buttoncolumncard011{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1265px) {


.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.button.buttoncolumncard011{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1905px) {


.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

.column.columncard01{


position:relative !important; 









	.button.buttoncolumncard011{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}
}
</style>
