<template>
 <div>  
<div id="TabActionButton"> 
         
 <div  :class="'column rectangle012 '"   id="rectangle012" ref="rectangle012" v-on:click="action"  :_cursor = "'pointer'" 
  v-bind:style="{cursor : 'pointer',justifyContent : 'space-around',}">
 <div  :class="'item itemrectangle013 '"   id="itemrectangle013" ref="itemrectangle013" v-if="(icon != null)"  v-bind:style="{}">
 <div  :class="'icon iconitemrectangle0135 '"   id="iconitemrectangle0135" ref="iconitemrectangle0135"  v-bind:style="{color : '#5a5a5a',fontSize : getUnit(24,'px'),}"><i  :class="icon"></i></div>  </div>
 <div  :class="'item itemrectangle0133 '"   id="itemrectangle0133" ref="itemrectangle0133" v-if="(image != null)"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageitemrectangle01331 '"   id="networkimageitemrectangle01331" ref="networkimageitemrectangle01331"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "image" 
>  </v-img>  </div>
 <div  :class="'row rowrectangle017 '"   id="rowrectangle017" ref="rowrectangle017"  v-bind:style="{justifyContent : 'center',}">
 <div  :class="'text textrectangle0151 '"   id="textrectangle0151" ref="textrectangle0151"  v-bind:style="{color : '#5a5a5a',}">
<div class="text-content" :style = "{}">{{ value }}</div>

   </div>
 <div  :class="'icon iconrowrectangle0173 '"   id="iconrowrectangle0173" ref="iconrowrectangle0173"  v-bind:style="{color : '#5a5a5a',fontSize : getUnit(18,'px'),}"><i  :class="'ion-md-arrow-dropdown'"></i></div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'TabActionButton',

    components: {},
	props: {
	
		value: {					required: false,			default: () =>  "Offres d'emploi",		},
		image: {					required: false,			default: () =>  null,		},
		icon: {					required: false,			default: () =>  null,		},
		action: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#TabActionButton{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.rectangle012{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
cursor:pointer !important; 
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; 
justify-content:space-around ; background-color: transparent ; 
padding-top:3px ; 
padding-bottom:3px ; 

.item.itemrectangle013{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:24px!important ; 
height:24px!important ; 


	.icon.iconitemrectangle0135{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	color:#5a5a5a ; 
	font-size:24px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.item.itemrectangle0133{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:24px!important ; 
height:24px!important ; 


	.networkimage.networkimageitemrectangle01331{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	border-radius:12px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}


}

.row.rowrectangle017{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:18px!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:5px ; 
justify-content:center ; background-color:transparent ; 
padding-left:5px ; 
padding-right:5px ; 

	.text.textrectangle0151{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	width:auto!important ; 
	
	color:#5a5a5a ; 
	font-size:10px ; 
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowrectangle0173{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:10px!important ; 
	height:10px!important ; 
	
	color:#5a5a5a ; 
	font-size:18px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}


@media (max-width: 600px) {


.column.rectangle012{


position:relative !important; 









.item.itemrectangle013{


position:relative !important; 






	.icon.iconitemrectangle0135{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.item.itemrectangle0133{


position:relative !important; 






	.networkimage.networkimageitemrectangle01331{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.row.rowrectangle017{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrectangle0151{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowrectangle0173{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}

@media (min-width: 601px) {


.column.rectangle012{


position:relative !important; 









.item.itemrectangle013{


position:relative !important; 






	.icon.iconitemrectangle0135{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.item.itemrectangle0133{


position:relative !important; 






	.networkimage.networkimageitemrectangle01331{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.row.rowrectangle017{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrectangle0151{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowrectangle0173{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}

@media (min-width: 961px) {


.column.rectangle012{


position:relative !important; 









.item.itemrectangle013{


position:relative !important; 






	.icon.iconitemrectangle0135{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.item.itemrectangle0133{


position:relative !important; 






	.networkimage.networkimageitemrectangle01331{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.row.rowrectangle017{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrectangle0151{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowrectangle0173{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}

@media (min-width: 1265px) {


.column.rectangle012{


position:relative !important; 









.item.itemrectangle013{


position:relative !important; 






	.icon.iconitemrectangle0135{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.item.itemrectangle0133{


position:relative !important; 






	.networkimage.networkimageitemrectangle01331{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.row.rowrectangle017{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrectangle0151{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowrectangle0173{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}

@media (min-width: 1905px) {


.column.rectangle012{


position:relative !important; 









.item.itemrectangle013{


position:relative !important; 






	.icon.iconitemrectangle0135{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.item.itemrectangle0133{


position:relative !important; 






	.networkimage.networkimageitemrectangle01331{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.row.rowrectangle017{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrectangle0151{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowrectangle0173{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}
}
</style>
