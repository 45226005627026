<template>
 <div>  
<div id="BigCallToAction"> 
         
 <ContentWrapper :class="'object contentWrapper '"   id="contentWrapper" ref="contentWrapper"  v-bind:style="{}" :paddingTop = "60" 
 :paddingBottom = "60" 
 :paddingLeft = "16" 
 :paddingRight = "16" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <div  :class="'column columnslottemplatecontentWrapper2211 '"   id="columnslottemplatecontentWrapper2211" ref="columnslottemplatecontentWrapper2211"  v-bind:style="{}">
 <div  :class="'text textcolumnslottemplatecontentWrapper22111 '"   id="textcolumnslottemplatecontentWrapper22111" ref="textcolumnslottemplatecontentWrapper22111"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Home.joinNow') }}</div>

   </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :rounded = "true" 
 :dark = "true" 
 :class="'button buttoncolumncolgridslottemplatecontentWrapper1111172 '"   id="buttoncolumncolgridslottemplatecontentWrapper1111172" ref="buttoncolumncolgridslottemplatecontentWrapper1111172" :to = "'/login'" 
  v-bind:style="{}"><span>{{ $t('Home.getStarted') }}</span>
</v-btn>  </div></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../../At/Utils/ContentWrapper'


export default {
	name: 'BigCallToAction',

    components: {ContentWrapper,},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
	},
} 
</script>

<style lang = "scss" scoped>


 div#BigCallToAction{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.contentWrapper{
	@media (min-width: 961px){
		&:before{
	    content: '';
	    width: 100vw;
	    height: 100%;
	    top: 0;
	    right: 0;
	    position: absolute;
	    z-index: 0;
	    background-repeat: repeat-x;
	    background-position: bottom;
	    background-image: url(https://static-exp1.licdn.com/sc/h/4ezbw852t2wrgf27zl1o1qtu7);
		}
	}
}



.object.contentWrapper{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
z-index:1 ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	z-index:1 ; 
	height:auto!important ; 
	min-height: 0px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:24px ; background-color: transparent ; 

		.text.textcolumnslottemplatecontentWrapper22111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:25px ; 
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncolumncolgridslottemplatecontentWrapper1111172{

		height: auto;
		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		width:auto!important ; 
		height:56px!important ; 
		to:/login !important; 
		
		font-size:25px ; 
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		font-size:25px ; 
		}
		}

	
}


}


}


@media (max-width: 600px) {


.object.contentWrapper{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncolumncolgridslottemplatecontentWrapper1111172{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentWrapper{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncolumncolgridslottemplatecontentWrapper1111172{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentWrapper{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	min-height: 840px!important ; 
	
	
	
	
	gap:40px ; 

		.text.textcolumnslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		
		
		font-size:56px ; 
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncolumncolgridslottemplatecontentWrapper1111172{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentWrapper{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncolumncolgridslottemplatecontentWrapper1111172{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentWrapper{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncolumncolgridslottemplatecontentWrapper1111172{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}
}
</style>
