<template>
 <div>  
<div id="ProductModal"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'column columncolumn03 '"   id="columncolumn03" ref="columncolumn03"  v-bind:style="{}">
 <GroupItem :class="'object groupitemcolumncolumn031 '"   id="groupitemcolumncolumn031" ref="groupitemcolumncolumn031" v-for="(item,index) in sections" :key="'groupitemcolumncolumn031' + index"  v-bind:style="{}" :title = "item.name" 
 :padding = "12" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplategroupitemcolumncolumn0311 '"   id="slottemplategroupitemcolumncolumn0311" ref="slottemplategroupitemcolumncolumn0311"  v-bind:style="{}">
 <div  :class="'flow flowslottemplategroupitemcolumncolumn03111 '"   id="flowslottemplategroupitemcolumncolumn03111" ref="flowslottemplategroupitemcolumncolumn03111"  v-bind:style="{justifyContent : 'space-between',}">
 <AwareIconLink :class="'object awareiconlinkflowslottemplategroupitemcolumncolumn031111 '"   id="awareiconlinkflowslottemplategroupitemcolumncolumn031111" ref="awareiconlinkflowslottemplategroupitemcolumncolumn031111" v-for="(item2, index2) in item.list" :key="'awareiconlinkflowslottemplategroupitemcolumncolumn031111' + index2"  v-bind:style="{}" :text = "item2.name" 
 :icon = "item2.icon" 
 :link = "item2.link" 
 :target = "item2.target" 
>
</AwareIconLink>  </div></div></template>
</GroupItem>
 <GroupItem :class="'object groupitemcolumncolumn033 '"   id="groupitemcolumncolumn033" ref="groupitemcolumncolumn033" v-if="false"  v-bind:style="{}" :title = "'Gérer'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplategroupitemcolumncolumn03111 '"   id="slottemplategroupitemcolumncolumn03111" ref="slottemplategroupitemcolumncolumn03111"  v-bind:style="{}">
 <div  :class="'column columnslottemplategroupitemcolumncolumn03111 '"   id="columnslottemplategroupitemcolumncolumn03111" ref="columnslottemplategroupitemcolumncolumn03111"  v-bind:style="{}">
 <AwareLink :class="'object awarelinkcolumnslottemplategroupitemcolumncolumn031111 '"   id="awarelinkcolumnslottemplategroupitemcolumncolumn031111" ref="awarelinkcolumnslottemplategroupitemcolumncolumn031111"  v-bind:style="{}" :title = "'Post et activités'" 
>
</AwareLink>
 <AwareLink :class="'object awarelinkcolumnslottemplategroupitemcolumncolumn0311113 '"   id="awarelinkcolumnslottemplategroupitemcolumncolumn0311113" ref="awarelinkcolumnslottemplategroupitemcolumncolumn0311113" v-for="(item,index) in 3" :key="'awarelinkcolumnslottemplategroupitemcolumncolumn0311113' + index"  v-bind:style="{}" :title = "'Entreprise'" 
>
</AwareLink>  </div></div></template>
</GroupItem>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import GroupItem from '../../../At/Utils/GroupItem'
import AwareIconLink from '../../../At/Utils/AwareIconLink'
import AwareLink from '../../../At/Utils/AwareLink'


export default {
	name: 'ProductModal',

    components: {GroupItem,AwareIconLink,AwareLink,},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		sections: function() { 		
	return [

		{

			name: this.$t('Modal.otherSections'),

			list: [

				{

					name: this.$t('Members.members'),

					link: "/members",

					target: "_self",

					icon: "ion-ios-people"

				},

				{

					name: this.$t('Organisations.organisations'),

					link: "/organisations",

					target: "_self",

					icon: "ion-md-hand"

				},

				{

					name: this.$t('Universities.universities'),

					link: "/universities",

					target: "_self",

					icon: "ion-md-business"

				},

				{

					name: this.$t('Mentors.mentors'),

					link: "/mentors",

					target: "_self",

					icon: "ion-ios-contacts"

				},

				{

					name: this.$t('Sites.sites'),

					link: "/sites",

					target: "_self",

					icon: "ion-ios-laptop"

				}

			]

		},

		{

			name: this.$t('Modal.otherProducts'),

			list: [

				{

					name: "Learning",

					link: "https://learning.afrotiative.com/",

					target: "_blank",

					icon: "ion-ios-school"

				}

			]

		}

	]

}
,
	},
} 
</script>

<style lang = "scss" scoped>


 div#ProductModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:15px ; background-color: transparent ; 

.column.columncolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: wrap;
			flex: initial;
			gap:5px ; 
			justify-content:space-between ; background-color:transparent ; 

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:60px!important ; 
				height:auto!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn033{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn03111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:4px ; background-color: transparent ; 

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn0311113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn0311113{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn0311113{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn0311113{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn0311113{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn0311113{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
