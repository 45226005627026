<template>
 <div>  
<div id="AdminFormationForm"> 
         
 <div  :class="'column columncolumncolumn0712 '"   id="columncolumncolumn0712" ref="columncolumncolumn0712"  v-bind:style="{}">
 <v-text-field  :label = "$t('Admin.name') + '*'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal011311 '"   id="textfieldcolumnslottemplatedialogboxmodal011311" ref="textfieldcolumnslottemplatedialogboxmodal011311" v-model="name"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-text-field  :label = "'slug'" 
 :append-icon = "'ion-md-refresh'" 
 :dense = "true" 
 :type = "'text'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal0113113 '"   id="textfieldcolumnslottemplatedialogboxmodal0113113" ref="textfieldcolumnslottemplatedialogboxmodal0113113" v-model="slug"  v-bind:style="{color : primaryColor,}" @click:append="generateSlug"></v-text-field>
 <v-text-field  :label = "'duration'" 
 :disabled = "localization != 'fr'" 
 :dense = "true" 
 :type = "'number'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal0113115 '"   id="textfieldcolumnslottemplatedialogboxmodal0113115" ref="textfieldcolumnslottemplatedialogboxmodal0113115" v-model="duration"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-textarea  :label = "$t('Admin.description') + '*'" 
 :auto-grow = "true" 
 filled :class="'textarea textarearectanglecolumncolumnslottemplatedialogboxmodal0115914 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal0115914" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal0115914" v-model="description"  v-bind:style="{}"></v-textarea>
 <v-textarea  :label = "$t('Admin.content')" 
 :auto-grow = "true" 
 filled :class="'textarea textarearectanglecolumncolumnslottemplatedialogboxmodal0115914 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal0115914" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal0115914" v-model="content"  v-bind:style="{}"></v-textarea>
 <v-row  :class="'grid gridcolumncolumncolumn07129 '"   id="gridcolumncolumncolumn07129" ref="gridcolumncolumncolumn07129"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumncolumn071291 '"   id="colgridcolumncolumncolumn071291" ref="colgridcolumncolumncolumn071291"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setImage" 
 :title = "'image'" 
 :type = "'image'" 
 :item = "image" 
 :disabled = "localization != 'fr'" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071293 '"   id="colgridcolumncolumncolumn071293" ref="colgridcolumncolumncolumn071293"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setVideo" 
 :type = "'video'" 
 :title = "'video'" 
 :item = "video" 
 :disabled = "localization != 'fr'" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>  </v-row>
 <div  :class="'column columncolumncolumncolumn071219 '"   id="columncolumncolumncolumn071219" ref="columncolumncolumncolumn071219"  v-bind:style="{}">
 <div  :class="'text textcolumncolumncolumncolumn0712195 '"   id="textcolumncolumncolumncolumn0712195" ref="textcolumncolumncolumncolumn0712195"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Admin.sectionsTree') }}</div>

   </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 @click="addChapter" :class="'button buttoncolumncolumncolumncolumn0712195 '"   id="buttoncolumncolumncolumncolumn0712195" ref="buttoncolumncolumncolumncolumn0712195"  v-bind:style="{}"><span>{{ $t('Admin.addChapter') }}</span>
</v-btn>
 <div  :class="'scrollarea scrollareacolumncolumncolumncolumn0712195 '"   id="scrollareacolumncolumncolumncolumn0712195" ref="scrollareacolumncolumncolumncolumn0712195"  v-bind:style="{backgroundColor : '#efefef',backgroundColor : '#efefef',}">
 <div  :class="'column columnscrollareacolumn03111 '"   id="columnscrollareacolumn03111" ref="columnscrollareacolumn03111"  v-bind:style="{}">
 <LDCourseOverviewChapter :class="'object ldcourseoverviewchaptercolumnscrollareacolumn0311 '"   id="ldcourseoverviewchaptercolumnscrollareacolumn0311" ref="ldcourseoverviewchaptercolumnscrollareacolumn0311" v-for="(item,index) in sectionsTree" :key="'ldcourseoverviewchaptercolumnscrollareacolumn0311' + index"  v-bind:style="{}" :editChapter = "editChapter" 
 :editModule = "editModule" 
 :addModule = "addModule" 
 :deleteChapter = "deleteChapter" 
 :deleteModule = "deleteModule" 
 :chapterInfo = "item" 
 :index = "index" 
 :editMode = "true" 
>
</LDCourseOverviewChapter>  </div>  </div>  </div>
 <v-row  :class="'grid gridcolumncolumncolumn0712159 '"   id="gridcolumncolumncolumn0712159" ref="gridcolumncolumncolumn0712159"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumncolumn07129311 '"   id="colgridcolumncolumncolumn07129311" ref="colgridcolumncolumncolumn07129311"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn07129311 '"   id="admincomboviewcolgridcolumncolumncolumn07129311" ref="admincomboviewcolgridcolumncolumncolumn07129311"  v-bind:style="{}" :updateModel = "setCategories" 
 :tableName = "'categories'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "filtersForCategories" 
 :label = "$t('Admin.categories')" 
 :p_model = "categories" 
 :multiple = "true" 
>
</AdminComboView></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071293112 '"   id="colgridcolumncolumncolumn071293112" ref="colgridcolumncolumncolumn071293112"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn07129311 '"   id="admincomboviewcolgridcolumncolumncolumn07129311" ref="admincomboviewcolgridcolumncolumncolumn07129311"  v-bind:style="{}" :updateModel = "setSubCategories" 
 :tableName = "'subCategories'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "filtersForSubCategories" 
 :label = "$t('Admin.subCategories')" 
 :p_model = "subcategories" 
 :multiple = "true" 
>
</AdminComboView></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071293113 '"   id="colgridcolumncolumncolumn071293113" ref="colgridcolumncolumncolumn071293113"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn07129311 '"   id="admincomboviewcolgridcolumncolumncolumn07129311" ref="admincomboviewcolgridcolumncolumncolumn07129311"  v-bind:style="{}" :updateModel = "setTags" 
 :tableName = "'tags'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "filtersForTags" 
 :label = "$t('Admin.tags')" 
 :p_model = "tags" 
 :multiple = "true" 
>
</AdminComboView></div></v-col>  </v-row>
 <div  :class="'text errorText '"   id="errorText" ref="errorText" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import AdminMediaPicker from '../../../../At/Components/AdminPage/AdminMediaPicker'
import LDCourseOverviewChapter from '../../../../At/Components/Learning/LDCourseOverviewChapter'
import AdminComboView from '../../../../At/Components/AdminPage/AdminComboView'

import * as Main from '../../../../Js/main'
import * as Request from '../../../../Js/requests'
export default {
	name: 'AdminFormationForm',

    components: {AdminMediaPicker,LDCourseOverviewChapter,AdminComboView,},
	methods: {
	
		loadData: function() { 		
	this.name = this.subEntity?.name

	this.slug = this.subEntity?.slug

	this.description = this.subEntity?.description

	this.content = this.subEntity?.content

	this.sectionsTree = this.subEntity?.sectionsTree ?? []

	this.duration = this.entity?.duration

	this.image = this.entity?.image.data

	this.video = this.entity?.video.data

	

	this.categories = this.subEntity?.categories.data

	this.subcategories = this.subEntity?.subcategories.data

	this.tags = this.subEntity?.tags.data

	

	this.loading = false

}
,
		generateSlug: function() { 		
	if(this.name){

		this.slug = this.name.replaceAll(/\s+/g, '-')

	}

}
,
		setImage: function(val) { 		
	this.m_image = val

}
,
		setVideo: function(val) { 		
	this.m_video = val

}
,
		setCategories: function(val) { 		
	this.categories = val

}
,
		setSubCategories: function(val) { 		
	this.subcategories = val

}
,
		setTags: function(val) { 		
	this.tags = val

}
,
		addModule: function(index) { 		
	this.sectionsTree[index].modules = this.sectionsTree[index].modules ?? []

	this.sectionsTree[index].modules.push({

		name: 'Module ' + (this.sectionsTree[index].modules.length + 1), 

		duration: 0

	})

}
,
		addChapter: function() { 		
	this.sectionsTree.push({

		name: "Chapter " + (this.sectionsTree.length + 1),

		modules: []

	})

}
,
		deleteModule: function(pIndex, index) { 		
	this.sectionsTree[pIndex].modules.splice(index, 1)

}
,
		deleteChapter: function(index) { 		
	this.sectionsTree.splice(index, 1)

}
,
		editModule: function(pIndex, index, name, duration) { 		
	this.sectionsTree[pIndex].modules[index].name = name

	this.sectionsTree[pIndex].modules[index].duration = parseFloat(duration)

}
,
		editChapter: function(index, title) { 		
	this.sectionsTree[index].name = title

}
,
		createEntry: function() { 		
	let data = {}

	

	if(this.name){ data.name = this.name }

	if(this.slug){ data.slug = this.slug }

	if(this.description){ data.description = this.description }

	if(this.content){ data.content = this.content }

	if(this.sectionsTree){ data.sectionsTree = this.sectionsTree }

	

	if(this.categories){ data.categories = this.categories }

	if(this.subcategories){ data.subcategories = this.subcategories }

	if(this.tags){ data.tags = this.tags }

	

	data.publishedAt = new Date().toISOString()

	

  const formData = new FormData();

	

	if(this.localization == 'fr'){ 	

		if(this.duration){ data.duration = this.duration }

		data.instructor = this.$store.state.user.id

			

		if(this.m_image){	

			formData.append(`files.image`, this.m_image, this.m_image.name);

		}

		

		if(this.m_video){

			formData.append(`files.video`, this.m_video, this.m_video.name);

		}

	} else {

		data.locale = this.localization

	}

	

	formData.append('data', JSON.stringify(data));

	

	Request.createLocalizedEntry(this.tableName, this.entity?.id ,formData)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.webinarCreated')) 

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		updateEntry: function() { 		
	let data = {}

	

	if(this.name){ data.name = this.name }

	if(this.slug){ data.slug = this.slug }

	if(this.description){ data.description = this.description }

	if(this.content){ data.content = this.content }

	if(this.sectionsTree){ data.sectionsTree = this.sectionsTree }

	

	if(this.categories){ data.categories = this.categories }

	if(this.subcategories){ data.subcategories = this.subcategories }

	if(this.tags){ data.tags = this.tags }

	

	if(this.localization == 'fr'){ 	

		if(this.duration){ data.duration = this.duration }

			

		if(this.m_image){	

			data.image = this.m_image

		}

		

		if(this.m_video){

			data.video = this.m_video;

		}

	}

	

	Request.updateEntry(this.tableName, this.subEntity.id, data)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.webinarUpdated'))

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		console.log(error)

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true	

	this.$awn.alert(message)

}
,
	},
	mounted(){
			
	this.loadData()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		entityID: function() { 		
	return this.entity?.id

}
,
		filtersForCategories: function() { 			
	let filter = {}

	return filter

}
,
		filtersForSubCategories: function() { 			
	let filter = {}

	return filter

}
,
		filtersForTags: function() { 			
	let filter = {}

	return filter

}
,
	},
	watch: {
	
		subEntity: function(val) { 		
	this.loading = true

	this.changed = false

	this.loadData()

}
,
		name: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		slug: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		description: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		content: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		sectionsTree: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		duration: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_image: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_video: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		categories: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		subcategories: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		tags: function(val) { 		
	if(!this.loading) this.changed = true

}
,
	},
	data(){
	return {
		
		name:  null		,
		slug:  null		,
		description:  null		,
		duration:  null		,
		image:  null		,
		video:  null		,
		content:  null		,
		sectionsTree:  null		,
		categories:  null		,
		subcategories:  null		,
		tags:  null		,
		m_image:  null		,
		m_video:  null		,
		loading:  true		,
		changed:  false		,
		error:  null		,
		errorVisible:  false		,
		tableName:  'formations'		,
		}
	},
	props: {
	
		entity: {					required: false,			default: () =>  null,		},
		subEntity: {					required: false,			default: () =>  null,		},
		localization: {					required: false,			default: () =>  null,		},
		updateSelectedItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminFormationForm{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.column.columncolumncolumn0712{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

font-size:12px ; 
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

font-size:12px ; 
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

font-size:12px ; 
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 5px ; 

margin-top: 5px ; 
margin-right: 5px ; 
margin-bottom: 5px ; 
width: calc(100% - (5px + 5px)) !important;
height: calc(100% - (5px + 5px)) !important;


::v-deep &.v-input{
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 5px ; 

margin-top: 5px ; 
margin-right: 5px ; 
margin-bottom: 5px ; 
width: calc(100% - (5px + 5px)) !important;
height: calc(100% - (5px + 5px)) !important;


::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:200px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:200px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn071219{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

	.text.textcolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	font-size:15px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.button.buttoncolumncolumncolumncolumn0712195{

	height: auto;
	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:40px!important ; 
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}

	.scrollarea.scrollareacolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	min-height: 200px!important ; 
	max-height: 400px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	overflow:hidden ; 
	overflow-x:hidden ; 
	overflow-y:scroll ; 
	background-color:#efefef ; 
	border-radius:8px ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #0ae;    

      // =======================================Style4
    border: 2px solid #555555;
      //-----------------------------------------------
        
      }

		.column.columnscrollareacolumn03111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 
		padding-top:10px ; 
		padding-bottom:10px ; 
		padding-left:10px ; 
		padding-right:10px ; 

			.object.ldcourseoverviewchaptercolumnscrollareacolumn0311{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn0712159{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

color:#ff1a05 ; 
line-height:10px ; 
font-size:10px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;text-decoration: underline
}


}


}


@media (max-width: 600px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn071219{


position:relative !important; 









	.text.textcolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumncolumncolumn0712195{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.scrollarea.scrollareacolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.ldcourseoverviewchaptercolumnscrollareacolumn0311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn0712159{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn071219{


position:relative !important; 









	.text.textcolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumncolumncolumn0712195{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.scrollarea.scrollareacolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.ldcourseoverviewchaptercolumnscrollareacolumn0311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn0712159{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn071219{


position:relative !important; 









	.text.textcolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumncolumncolumn0712195{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.scrollarea.scrollareacolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.ldcourseoverviewchaptercolumnscrollareacolumn0311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn0712159{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





line-height:15px ; 
font-size:15px ; 
.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn071219{


position:relative !important; 









	.text.textcolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumncolumncolumn0712195{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.scrollarea.scrollareacolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.ldcourseoverviewchaptercolumnscrollareacolumn0311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn0712159{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.column.columncolumncolumn0712{


position:relative !important; 









.textfield.textfieldcolumnslottemplatedialogboxmodal011311{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113113{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.textfieldcolumnslottemplatedialogboxmodal0113115{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal0115914{


position:relative !important; 






::v-deep &.v-input{
input{

}
}
}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.column.columncolumncolumncolumn071219{


position:relative !important; 









	.text.textcolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttoncolumncolumncolumncolumn0712195{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.scrollarea.scrollareacolumncolumncolumncolumn0712195{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacolumn03111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.ldcourseoverviewchaptercolumnscrollareacolumn0311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.grid.gridcolumncolumncolumn0712159{


position:relative !important; 






	.col.colgridcolumncolumncolumn07129311{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293112{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071293113{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
