<template>
 <div>  
<div id="ProfileSection"> 
         
 <div  :class="'column columncolumncolumncoltab0131132 '"   id="columncolumncolumncoltab0131132" ref="columncolumncolumncoltab0131132"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'row rowcolumncolumncolumncoltab0131131 '"   id="rowcolumncolumncolumncoltab0131131" ref="rowcolumncolumncolumncoltab0131131"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textcolumncolgridcolumncolumncolumncoltab01311331112 '"   id="textcolumncolgridcolumncolumncolumncoltab01311331112" ref="textcolumncolgridcolumncolumncolumncoltab01311331112"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <div  :class="'row rowrowcolumncolumncolumncoltab01311315 '"   id="rowrowcolumncolumncolumncoltab01311315" ref="rowrowcolumncolumncolumncoltab01311315"  v-bind:style="{}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="editAction" :class="'button buttonrowcolumncolumncolumncoltab013113132 '"   id="buttonrowcolumncolumncolumncoltab013113132" ref="buttonrowcolumncolumncolumncoltab013113132" v-if="!watchMode"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-create' !== undefined" >{{ 'ion-md-create' }}
</v-icon></v-btn>  </div>  </div>
 <div  :class="'column columncolumncolumncolumncoltab01311323 '"   id="columncolumncolumncolumncoltab01311323" ref="columncolumncolumncolumncoltab01311323"  v-bind:style="{}">
 <div  :class="'slot slotcolumncolumncolumncolumncoltab013113231 '"   id="slotcolumncolumncolumncolumncoltab013113231" ref="slotcolumncolumncolumncolumncoltab013113231"  v-bind:style="{}"><slot  :name = "'content'" 
></slot></div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ProfileSection',

    components: {},
	props: {
	
		title: {					required: false,			default: () =>  "Expérience",		},
		hasAddAction: {			type: Boolean,			required: false,			default: () =>  false,		},
		hasEditAction: {			type: Boolean,			required: false,			default: () =>  false,		},
		addAction: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		editAction: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		watchMode: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ProfileSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.columncolumncolumncoltab0131132{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
background-color:#fff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#e0dfdc ; 
padding-top:24px ; 
padding-bottom:24px ; 
padding-left:24px ; 
padding-right:24px ; 

.row.rowcolumncolumncolumncoltab0131131{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:52px!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
justify-content:space-between ; background-color:transparent ; 

	.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:auto!important ; 
	
	font-size:25px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.row.rowrowcolumncolumncolumncoltab01311315{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:40px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:5px ; background-color:transparent ; 

		.button.buttonrowcolumncolumncolumncoltab013113132{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:40px!important ; 
		height:40px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncolumncoltab01311323{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.slot.slotcolumncolumncolumncolumncoltab013113231{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	}


}


}


@media (max-width: 600px) {


.column.columncolumncolumncoltab0131132{


position:relative !important; 









.row.rowcolumncolumncolumncoltab0131131{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowcolumncolumncolumncoltab01311315{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumncolumncolumncoltab013113132{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncolumncoltab01311323{


position:relative !important; 









	.slot.slotcolumncolumncolumncolumncoltab013113231{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 601px) {


.column.columncolumncolumncoltab0131132{


position:relative !important; 









.row.rowcolumncolumncolumncoltab0131131{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowcolumncolumncolumncoltab01311315{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumncolumncolumncoltab013113132{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncolumncoltab01311323{


position:relative !important; 









	.slot.slotcolumncolumncolumncolumncoltab013113231{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 961px) {


.column.columncolumncolumncoltab0131132{


position:relative !important; 









.row.rowcolumncolumncolumncoltab0131131{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowcolumncolumncolumncoltab01311315{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumncolumncolumncoltab013113132{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncolumncoltab01311323{


position:relative !important; 









	.slot.slotcolumncolumncolumncolumncoltab013113231{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 1265px) {


.column.columncolumncolumncoltab0131132{


position:relative !important; 









.row.rowcolumncolumncolumncoltab0131131{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowcolumncolumncolumncoltab01311315{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumncolumncolumncoltab013113132{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncolumncoltab01311323{


position:relative !important; 









	.slot.slotcolumncolumncolumncolumncoltab013113231{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 1905px) {


.column.columncolumncolumncoltab0131132{


position:relative !important; 









.row.rowcolumncolumncolumncoltab0131131{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowcolumncolumncolumncoltab01311315{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowcolumncolumncolumncoltab013113132{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.column.columncolumncolumncolumncoltab01311323{


position:relative !important; 









	.slot.slotcolumncolumncolumncolumncoltab013113231{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}
}
</style>
