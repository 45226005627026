<template>
 <div>  
<div id="ShareBoxFeed"> 
         
 <div  :class="'column column0 ' + ' ' + 'elevation-1'"   id="column0" ref="column0"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'row rowcolumn01 '"   id="rowcolumn01" ref="rowcolumn01"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerowcolumn011 '"   id="rectanglerowcolumn011" ref="rectanglerowcolumn011" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',}">
 <v-img  :class="'networkimage networkimagerectanglerowcolumn0111 '"   id="networkimagerectanglerowcolumn0111" ref="networkimagerectanglerowcolumn0111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "profileImage" 
>  </v-img>
 <router-link  :class="'routerlink routerlinkrectanglerowcolumn0113 '"   id="routerlinkrectanglerowcolumn0113" ref="routerlinkrectanglerowcolumn0113"  v-bind:style="{}" :to = "'/profile'" 
>  </router-link>  </div>
 <v-btn tag="div" 
 :outlined = "true" 
 :rounded = "true" 
 @click="showCreateDialog" :class="'button buttonrectanglerowcolumn01132 '"   id="buttonrectanglerowcolumn01132" ref="buttonrectanglerowcolumn01132" :_flex = "'1'" 
  v-bind:style="{flex : '1',}"><span>{{ $t('Feed.startPost') }}</span>
</v-btn>  </div>
 <div  :class="'flow rowcolumn03 '"   id="rowcolumn03" ref="rowcolumn03"  v-bind:style="{justifyContent : 'space-between',}">
 <v-btn tag="div" 
 :text = "true" 
 @click="showCreateDialog" :class="'button buttonrowcolumn031 '"   id="buttonrowcolumn031" ref="buttonrowcolumn031" :_flex = "'1'" 
 :_minWidth = "'unset'" 
  v-bind:style="{flex : '1',minWidth : 'unset',}"><v-icon  :style = "{}"  v-if = "'ion-ios-image' !== undefined"  :left = "true" 
 :color = "'#378fe9'" 
>{{ 'ion-ios-image' }}
</v-icon><span>{{ $t('Feed.pictureVideo') }}</span>
</v-btn>
 <v-btn tag="div" 
 :text = "true" 
 :class="'button buttonrowcolumn0314 '"   id="buttonrowcolumn0314" ref="buttonrowcolumn0314" :_flex = "'1'" 
 :_minWidth = "'unset'" 
  v-bind:style="{flex : '1',minWidth : 'unset',}"><v-icon  :style = "{}"  v-if = "'ion-ios-create' !== undefined"  :left = "true" 
 :color = "'#e16745'" 
>{{ 'ion-ios-create' }}
</v-icon><span>{{ $t('Feed.writeArticle') }}</span>
</v-btn>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'ShareBoxFeed',

    components: {},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		profileImage: function() { 		
	if(this.user){

		if(this.user.image){

			let item = this.user.image;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.user.username)

	}

	return null

}
,
	},
	props: {
	
		showCreateDialog: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ShareBoxFeed{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.v-btn{	
	::v-deep .v-btn__content{
		max-width: 100%;	
		
		span{
			max-width: 100%;
			white-space: nowrap;
		  text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; 
background-color:#fff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#e1e3e7 ; 
padding-top:8px ; 
padding-bottom:8px ; 
padding-left:8px ; 
padding-right:8px ; 

.row.rowcolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:8px ; background-color:transparent ; 

	.rectangle.rectanglerowcolumn011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:48px!important ; 
	height:48px!important ; 
	overflow:hidden !important; 
	
	background-color:#ffffff ; 
	border-radius:24px ; 

		.networkimage.networkimagerectanglerowcolumn0111{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

		.routerlink.routerlinkrectanglerowcolumn0113{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		

		
}

	
}

	.button.buttonrectanglerowcolumn01132{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	height:48px!important ; 
	flex:1 !important; 
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}


}

.flow.rowcolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
display:none !important; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
gap:6px ; 
justify-content:space-between ; background-color:transparent ; 

	.button.buttonrowcolumn031{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	height:48px!important ; 
	flex:1 !important; 
	minWidth:unset !important; 
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}

	.button.buttonrowcolumn0314{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	height:48px!important ; 
	flex:1 !important; 
	minWidth:unset !important; 
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.rectangle.rectanglerowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglerowcolumn0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.routerlink.routerlinkrectanglerowcolumn0113{

		
		position:absolute !important; 
		
		
		
		
		

		
}

	
}

	.button.buttonrectanglerowcolumn01132{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.flow.rowcolumn03{


position:relative !important; 









	.button.buttonrowcolumn031{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0314{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.rectangle.rectanglerowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglerowcolumn0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.routerlink.routerlinkrectanglerowcolumn0113{

		
		position:absolute !important; 
		
		
		
		
		

		
}

	
}

	.button.buttonrectanglerowcolumn01132{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.flow.rowcolumn03{


position:relative !important; 









	.button.buttonrowcolumn031{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0314{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.rectangle.rectanglerowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglerowcolumn0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.routerlink.routerlinkrectanglerowcolumn0113{

		
		position:absolute !important; 
		
		
		
		
		

		
}

	
}

	.button.buttonrectanglerowcolumn01132{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.flow.rowcolumn03{


position:relative !important; 









	.button.buttonrowcolumn031{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0314{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.rectangle.rectanglerowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglerowcolumn0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.routerlink.routerlinkrectanglerowcolumn0113{

		
		position:absolute !important; 
		
		
		
		
		

		
}

	
}

	.button.buttonrectanglerowcolumn01132{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.flow.rowcolumn03{


position:relative !important; 









	.button.buttonrowcolumn031{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0314{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.rectangle.rectanglerowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglerowcolumn0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.routerlink.routerlinkrectanglerowcolumn0113{

		
		position:absolute !important; 
		
		
		
		
		

		
}

	
}

	.button.buttonrectanglerowcolumn01132{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.flow.rowcolumn03{


position:relative !important; 









	.button.buttonrowcolumn031{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0314{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}
}
</style>
