<template>
 <div>  
<div id="EditProfileModal"> 
         
 <DialogBoxModal :class="'object dialogboxmodal0 '"   id="dialogboxmodal0" ref="dialogboxmodal0"  v-bind:style="{}" :close = "close" 
 :action = "updateProfile" 
 :title = "$t('Profile.modifyProfile')" 
 :hasAction = "(changed ? true : false)" 
 :fullHeight = "true" 
 :actionName = "$t('Profile.update')" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedialogboxmodal01 '"   id="slottemplatedialogboxmodal01" ref="slottemplatedialogboxmodal01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedialogboxmodal011 '"   id="columnslottemplatedialogboxmodal011" ref="columnslottemplatedialogboxmodal011"  v-bind:style="{}">
 <div  :class="'text textcolumnslottemplatedialogboxmodal0111 '"   id="textcolumnslottemplatedialogboxmodal0111" ref="textcolumnslottemplatedialogboxmodal0111"  v-bind:style="{color : '#6f6f6f',}">
<div class="text-content" :style = "{}">{{ $t('Profile.indicateReqFields') }}</div>

   </div>
 <div  :class="'column columncolumnslottemplatedialogboxmodal0115 '"   id="columncolumnslottemplatedialogboxmodal0115" ref="columncolumnslottemplatedialogboxmodal0115"  v-bind:style="{}">
 <v-text-field  :label = "$t('Profile.username')" 
 :prepend-inner-icon = "'ion-ios-finger-print'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131 '"   id="textfieldcolumnslottemplatedialogboxmodal01131" ref="textfieldcolumnslottemplatedialogboxmodal01131" v-model="username"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-text-field  :label = "$t('Profile.firstName')" 
 :prepend-inner-icon = "'ion-ios-finger-print'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal011312 '"   id="textfieldcolumnslottemplatedialogboxmodal011312" ref="textfieldcolumnslottemplatedialogboxmodal011312" v-model="firstName"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-text-field  :label = "$t('Profile.lastName')" 
 :prepend-inner-icon = "'ion-ios-finger-print'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal011313 '"   id="textfieldcolumnslottemplatedialogboxmodal011313" ref="textfieldcolumnslottemplatedialogboxmodal011313" v-model="lastName"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-text-field  :label = "$t('Profile.title')" 
 :prepend-inner-icon = "'ion-ios-filing'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal011314 '"   id="textfieldcolumnslottemplatedialogboxmodal011314" ref="textfieldcolumnslottemplatedialogboxmodal011314" v-model="title"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-text-field  :label = "$t('Profile.personalPage')" 
 :prepend-inner-icon = "'ion-ios-planet'" 
 :dense = "true" 
 :type = "'url'" 
 outlined :class="'textfield inputPersonalLink '"   id="inputPersonalLink" ref="inputPersonalLink" v-model="personalLink"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-text-field  :label = "$t('Profile.phoneNumber')" 
 :prepend-inner-icon = "'ion-md-call'" 
 :dense = "true" 
 :type = "'tel'" 
 outlined :class="'textfield inputPhoneNumber '"   id="inputPhoneNumber" ref="inputPhoneNumber" v-model="phoneNumber"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-select
           :label = "$t('Profile.country')" 
 :prepend-inner-icon = "'ion-md-globe'" 
:items = "countriesList" outlined :class="'select selectcolumncolumncolumnslottemplatecontentWrapper22113159 '"   id="selectcolumncolumncolumnslottemplatecontentWrapper22113159" ref="selectcolumncolumncolumnslottemplatecontentWrapper22113159" v-model="country" :dense = "true" 
 :item-text = "'label'" 
 :item-value = "'value'" 
  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>
 <v-text-field  :label = "$t('Profile.city')" 
 :prepend-inner-icon = "'ion-md-business'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal0113146 '"   id="textfieldcolumnslottemplatedialogboxmodal0113146" ref="textfieldcolumnslottemplatedialogboxmodal0113146" v-model="city"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <div  :class="'column rectanglecolumncolumnslottemplatedialogboxmodal01159 '"   id="rectanglecolumncolumnslottemplatedialogboxmodal01159" ref="rectanglecolumncolumnslottemplatedialogboxmodal01159"  v-bind:style="{}">
 <v-textarea  :label = "$t('Profile.description')" 
 :prepend-inner-icon = "'ion-md-create'" 
 :auto-grow = "true" 
 :class="'textarea textarearectanglecolumncolumnslottemplatedialogboxmodal011591 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal011591" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal011591" v-model="description"  v-bind:style="{}"></v-textarea>  </div>  </div>  </div></div></template>
</DialogBoxModal>
 
      </div>
 </div> 
</template>
<script>
import DialogBoxModal from '../../../At/Sections/Modal/DialogBoxModal'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
import * as Countries from 'i18n-iso-countries'
export default {
	name: 'EditProfileModal',

    components: {DialogBoxModal,},
	methods: {
	
		chargeData: function() { 		
	this.username = this.user.username

	this.firstName = this.user.firstName

	this.lastName = this.user.lastName

	this.description = this.user.description

	this.title = this.user.title

	this.city = this.user.city

	this.country = this.user.country

	this.personalLink = this.user.personalLink

	this.phoneNumber = this.user.phoneNumber

	

	this.changed = false

}
,
		getUser: function(id) { 		
	Request.getTable('users/' + id)

	.then((res) => {

		this.$store.commit('changeUser', res.data)

	})

}
,
		updateProfile: function() { 		
	let config = {}

	

	if(this.username){ config.username = this.username }

	if(this.firstName){ config.firstName = this.firstName }

	if(this.lastName){ config.lastName = this.lastName }

	if(this.description){ config.description = this.description }

	if(this.title){ config.title = this.title }

	if(this.city){ config.city = this.city }

	if(this.country){ config.country = this.country }

	if(this.personalLink){ config.personalLink = this.personalLink }

	if(this.phoneNumber){ config.phoneNumber = this.phoneNumber }

	

	Request.updateUserData(config)

	.then((res) => {

		this.getUser(res.data.id)

		this.$awn.success(this.$t('Profile.profileUpdated'))

		this.close()

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		showError: function(message) { 		
	this.$awn.alert(message)

}
,
	},
	mounted(){
			
	this.countries = Countries

	this.countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

	this.countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

	this.countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));



	this.chargeData()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		selectedLang: function(){ 		
	return this.$store.state.selectedLang

}
,
		countriesList: function() { 			
	if(this.countries == null) return []

	

	const list = this.countries.getNames(this.selectedLang, {select: 'official'})

	return Object.keys(list).map((key) => ({

	    value: key, // FR

	    label: list[key] // ex: France

	}))

}
,
	},
	watch: {
	
		user: function(val) { 		
	this.chargeData()

}
,
		username: function(val, oldVal) { 		
	this.changed = true

}
,
		firstName: function(val) { 		
	this.changed = true

}
,
		lastName: function(val) { 		
	this.changed = true

}
,
		description: function(val) { 		
	this.changed = true

}
,
		title: function(val) { 		
	this.changed = true

}
,
		city: function(val) { 		
	this.changed = true

}
,
		country: function(val) { 		
	this.changed = true

}
,
		personalLink: function(val) { 		
	this.changed = true

}
,
		phoneNumber: function(val) { 		
	this.changed = true

}
,
	},
	data(){
	return {
		
		username:  null		,
		firstName:  null		,
		lastName:  null		,
		description:  null		,
		title:  null		,
		city:  null		,
		country:  null		,
		personalLink:  null		,
		phoneNumber:  null		,
		countries:  null		,
		changed:  false		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		user: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#EditProfileModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.dialogboxmodal0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.text.textcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		color:#6f6f6f ; 
		font-size:12px ; 
		.text-content{
		width: 100%;
		}

		
}

		.column.columncolumnslottemplatedialogboxmodal0115{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:12px ; background-color: transparent ; 

			.textfield.textfieldcolumnslottemplatedialogboxmodal01131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:12px ; 
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011312{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:12px ; 
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011313{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:12px ; 
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011314{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:12px ; 
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPersonalLink{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:12px ; 
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPhoneNumber{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:12px ; 
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.select.selectcolumncolumncolumnslottemplatecontentWrapper22113159{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			dense:true !important; 
			item-text:label !important; 
			item-value:value !important; 
			width: calc(100% - (0px + 0px)) !important;
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal0113146{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:12px ; 
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.column.rectanglecolumncolumnslottemplatedialogboxmodal01159{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 
			border-radius:8px ; 
			border-style:solid ; 
			border-width:1px ; 
			border-color:#989898 ; 

				.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal011591{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 5px ; 
				
				margin-top: 5px ; 
				margin-right: 5px ; 
				margin-bottom: 5px ; 
				width: calc(100% - (5px + 5px)) !important;
				height: calc(100% - (5px + 5px)) !important;
				
				
::v-deep &.v-input{
input{

}
				}
				}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncolumnslottemplatedialogboxmodal0115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.textfield.textfieldcolumnslottemplatedialogboxmodal01131{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011312{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011313{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011314{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPersonalLink{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPhoneNumber{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.select.selectcolumncolumncolumnslottemplatecontentWrapper22113159{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal0113146{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.column.rectanglecolumncolumnslottemplatedialogboxmodal01159{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal011591{

				
				position:relative !important; 
				
				
				
				
				
				
::v-deep &.v-input{
input{

}
				}
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncolumnslottemplatedialogboxmodal0115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.textfield.textfieldcolumnslottemplatedialogboxmodal01131{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011312{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011313{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011314{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPersonalLink{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPhoneNumber{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.select.selectcolumncolumncolumnslottemplatecontentWrapper22113159{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal0113146{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.column.rectanglecolumncolumnslottemplatedialogboxmodal01159{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal011591{

				
				position:relative !important; 
				
				
				
				
				
				
::v-deep &.v-input{
input{

}
				}
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncolumnslottemplatedialogboxmodal0115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.textfield.textfieldcolumnslottemplatedialogboxmodal01131{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011312{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011313{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011314{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPersonalLink{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPhoneNumber{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.select.selectcolumncolumncolumnslottemplatecontentWrapper22113159{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal0113146{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.column.rectanglecolumncolumnslottemplatedialogboxmodal01159{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal011591{

				
				position:relative !important; 
				
				
				
				
				
				
::v-deep &.v-input{
input{

}
				}
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncolumnslottemplatedialogboxmodal0115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.textfield.textfieldcolumnslottemplatedialogboxmodal01131{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011312{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011313{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011314{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPersonalLink{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPhoneNumber{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.select.selectcolumncolumncolumnslottemplatecontentWrapper22113159{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal0113146{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.column.rectanglecolumncolumnslottemplatedialogboxmodal01159{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal011591{

				
				position:relative !important; 
				
				
				
				
				
				
::v-deep &.v-input{
input{

}
				}
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.column.columncolumnslottemplatedialogboxmodal0115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.textfield.textfieldcolumnslottemplatedialogboxmodal01131{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011312{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011313{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal011314{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPersonalLink{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.textfield.inputPhoneNumber{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.select.selectcolumncolumncolumnslottemplatecontentWrapper22113159{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.textfield.textfieldcolumnslottemplatedialogboxmodal0113146{

			
			position:relative !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.column.rectanglecolumncolumnslottemplatedialogboxmodal01159{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal011591{

				
				position:relative !important; 
				
				
				
				
				
				
::v-deep &.v-input{
input{

}
				}
				}

			
}

		
}

	
}


}


}
}
}
</style>
