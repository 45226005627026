<template>
 <div>  
<div id="GroupItem"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'column columncolumn01 '"   id="columncolumn01" ref="columncolumn01"  v-bind:style="{paddingLeft : (mqVal({default:12+'px',md:getUnit(padding,'px')}, $mq)),paddingRight : (mqVal({default:12+'px',md:getUnit(padding,'px')}, $mq)),}">
 <div  :class="'text textcolumncolumn011 '"   id="textcolumncolumn011" ref="textcolumncolumn011"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>  </div>
 <div  :class="'rectangle rectanglecolumn03 '"   id="rectanglecolumn03" ref="rectanglecolumn03"  v-bind:style="{backgroundColor : '#00000014',}">  </div>
 <div  :class="'column columncolumn013 '"   id="columncolumn013" ref="columncolumn013"  v-bind:style="{paddingLeft : (mqVal({default:12+'px',md:getUnit(padding,'px')}, $mq)),paddingRight : (mqVal({default:12+'px',md:getUnit(padding,'px')}, $mq)),}">
 <div  :class="'slot slotcolumncolumn0131 '"   id="slotcolumncolumn0131" ref="slotcolumncolumn0131"  v-bind:style="{}"><slot  :name = "'content'" 
></slot></div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'GroupItem',

    components: {},
	props: {
	
		title: {					required: false,			default: () =>  "Autres produits Afrotiative",		},
		padding: {					required: false,			default: () =>  24,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#GroupItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
background-color:#fff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#00000014 ; 

.column.columncolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
padding-top:8px ; 
padding-bottom:8px ; 

	.text.textcolumncolumn011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	font-size:18px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:1px!important ; 
width: calc(100% - (0px + 0px)) !important;

background-color:#00000014 ; 


}

.column.columncolumn013{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
padding-top:8px ; 
padding-bottom:8px ; 

	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}

.column.columncolumn013{


position:relative !important; 









	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}

.column.columncolumn013{


position:relative !important; 









	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 








padding-top:16px ; 
padding-bottom:16px ; 

	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}

.column.columncolumn013{


position:relative !important; 








padding-top:16px ; 
padding-bottom:16px ; 

	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}

.column.columncolumn013{


position:relative !important; 









	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}

.column.columncolumn013{


position:relative !important; 









	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}
}
</style>
