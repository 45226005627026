<template>
 <div>  
<div id="Sites"> 
         
 <MainLayout :class="'object basic0 '"   id="basic0" ref="basic0"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatebasic '"   id="slottemplatebasic" ref="slottemplatebasic"  v-bind:style="{}">
 <div  :class="'column mainContent '"   id="mainContent" ref="mainContent"  v-bind:style="{}">
 <div  :class="'flow rowmainContent12 '"   id="rowmainContent12" ref="rowmainContent12"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'row rowrowmainContent14 '"   id="rowrowmainContent14" ref="rowrowmainContent14"  v-bind:style="{}">
 <div  :class="'text textrowmainContent11 '"   id="textrowmainContent11" ref="textrowmainContent11"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ $t('Sites.sites') }}</div>

   </div>
 <div  :class="'text textrowmainContent112 '"   id="textrowmainContent112" ref="textrowmainContent112"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ foundNumber }}</div>

   </div>  </div>
 <div  :class="'rectangle searchZone '"   id="searchZone" ref="searchZone"  v-bind:style="{backgroundColor : '#e4580a12',}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 :dark = "true" 
 :class="'button buttonsearchZone1 '"   id="buttonsearchZone1" ref="buttonsearchZone1"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-search' !== undefined"  :color = "'#444444'" 
>{{ 'ion-ios-search' }}
</v-icon></v-btn>
 <div  :class="'item itemsearchZone4 '"   id="itemsearchZone4" ref="itemsearchZone4"  v-bind:style="{}">
 <input  type = "text" :class="'textedit texteditsearchZone3 '"   id="texteditsearchZone3" ref="texteditsearchZone3" v-model="filter" :placeholder = "$t('Blog.search')" 
 :outline = "'none'" 
  v-bind:style="{color : '#000',}" >  </div>  </div>  </div>
 <v-row  :class="'grid gridmainContent3 '"   id="gridmainContent3" ref="gridmainContent3"  v-bind:style="{}">
 <v-col  :class="'col colgridmainContent31 '"   id="colgridmainContent31" ref="colgridmainContent31" v-for="(item,index) in sites" :key="'colgridmainContent31' + index"  v-bind:style="{}" :cols = "12" 
><div class="col-container">
 <SiteItem :class="'object siteItem '"   id="siteItem" ref="siteItem"  v-bind:style="{}" :site = "item" 
>
</SiteItem></div></v-col>  </v-row>
 <v-pagination 
 :color = "primaryColor" 
 :length = "numberPages" 
 :class="'pagination paginationmainContent53 '"   id="paginationmainContent53" ref="paginationmainContent53" v-if="numberPages" v-model="curPage"  v-bind:style="{}"></v-pagination>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import SiteItem from '../../At/Components/SitesPage/SiteItem'

import * as Request from '../../Js/requests'
export default {
	name: 'Sites',

    components: {MainLayout,SiteItem,},
	methods: {
	
		getSites: function() {		
	let config = {

		pagination: {

			page: this.curPage,

			pageSize: this.sitesPerPage,

			withCount: true

		},

		filters: {

			$or: [	

				{ name: { $contains: this.filter } },

				{ description: { $contains: this.filter } },

			]

		},

		sort: ['id:desc'],

	}

	

	Request.getTableArgs('pages', config)

	.then((res) => {

		if(res.data.meta != undefined){	

			this.sites = res.data.data;

			

			let pagination = res.data.meta.pagination;

			this.numberPages = pagination.pageCount

		} else {

			this.sites = res.data;

		}

		

		this.loading = false;

	}) 

}
,
	},
	mounted(){
			
	this.getSites()

	},
	metaInfo(){
			
	return {

		title: this.companyName + " | Sites",

		meta: [		

	   { name: 'description', content: this.$t('Members.description')},

	

	   { property: 'og:title', content: this.companyName + " | Members"},

	   { property: 'og:description', content: this.$t('Members.description')},

	   { property: 'og:type', content: 'website'},

	   { property: 'og:url', content: this.$store.state.webUrl + '/members'},

	

	   { name: 'twitter:title', content: this.companyName + " | Members"},

	   { name: 'twitter:description', content: this.$t('Members.description')},

	   { name: 'twitter:card', content: 'website'},

	   { name: 'twitter:url', content: this.$store.state.webUrl + '/members'}

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		foundNumber: function() { 		
		return '(' + this.sites.length + ')'

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
	},
	watch: {
	
		curPage: function(val) { 		
	this.getSites()

}
,
		filter: function(val) { 		
	this.getSites()

}
,
	},
	data(){
	return {
		
		sites:  []		,
		filter:  null		,
		loading:  true		,
		sitesPerPage:  24		,
		curPage:  1		,
		numberPages:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Sites{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.basic0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainContent{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	width:90%!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:50px ; background-color: transparent ; 
	padding-top:70px ; 

		.flow.rowmainContent12{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;
		gap:20px ; 
		justify-content:space-between ; background-color:transparent ; 

			.row.rowrowmainContent14{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			width:100%!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:10px ; background-color:transparent ; 

				.text.textrowmainContent11{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:100%!important ; 
				height:auto!important ; 
				
				color:#444444 ; 
				font-size:35px ; 
				.text-content{
				width: 100%;
				white-space: normal;
				font-weight: bold;
				}

				
}

				.text.textrowmainContent112{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:100%!important ; 
				height:auto!important ; 
				
				color:#444444 ; 
				font-size:35px ; 
				.text-content{
				width: 100%;
				white-space: normal;
				font-weight: bold;
				}

				
}

			
}

			.rectangle.searchZone{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:100%!important ; 
			height:40px!important ; 
			
			background-color:#e4580a12 ; 
			border-radius:20px ; 

				.button.buttonsearchZone1{

				height: auto;
				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				left: 5px ; 
				
				top: calc(50% + 0px) ; 
				width:30px!important ; 
				height:30px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.item.itemsearchZone4{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 40px ; 
				
				top: 5px ; 
				right: 20px ; 
				bottom: 5px ; 
				

					.textedit.texteditsearchZone3{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					top: 0px ; 
					bottom: 0px ; 
					width:100%!important ; 
					outline:none !important; 
					
					color:#000 ; 
					font-size:15px ; 
					}

				
}

			
}

		
}

		.grid.gridmainContent3{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color: transparent ; 

			.col.colgridmainContent31{

			
			position:relative !important; 
			margin: unset;
			transform-origin: top left;
			
			
			
			height:auto!important ; 
			

			::v-deep .col-container{position: relative;

			height: auto;

			width: 100%;

				.object.siteItem{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationmainContent53{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		}

	
}


}


}


@media (max-width: 600px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainContent{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.rowmainContent12{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowrowmainContent14{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowmainContent11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textrowmainContent112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.rectangle.searchZone{

			
			position:relative !important; 
			
			
			
			
			
			

				.button.buttonsearchZone1{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.item.itemsearchZone4{

				
				position:absolute !important; 
				
				
				
				
				

					.textedit.texteditsearchZone3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

			
}

		
}

		.grid.gridmainContent3{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.siteItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationmainContent53{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainContent{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.rowmainContent12{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowrowmainContent14{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowmainContent11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textrowmainContent112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.rectangle.searchZone{

			
			position:relative !important; 
			
			
			
			
			
			

				.button.buttonsearchZone1{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.item.itemsearchZone4{

				
				position:absolute !important; 
				
				
				
				
				

					.textedit.texteditsearchZone3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

			
}

		
}

		.grid.gridmainContent3{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.siteItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationmainContent53{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainContent{

	
	position:relative !important; 
	
	
	
	
	width:900px!important ; 
	
	
	
	

		.flow.rowmainContent12{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowrowmainContent14{

			
			position:relative !important; 
			
			
			
			
			width:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowmainContent11{

				
				position:relative !important; 
				
				
				
				
				width:auto!important ; 
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textrowmainContent112{

				
				position:relative !important; 
				
				
				
				
				width:auto!important ; 
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.rectangle.searchZone{

			
			position:relative !important; 
			
			
			
			
			width:490px!important ; 
			
			

				.button.buttonsearchZone1{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.item.itemsearchZone4{

				
				position:absolute !important; 
				
				
				
				
				

					.textedit.texteditsearchZone3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

			
}

		
}

		.grid.gridmainContent3{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.siteItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationmainContent53{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainContent{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.rowmainContent12{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowrowmainContent14{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowmainContent11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textrowmainContent112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.rectangle.searchZone{

			
			position:relative !important; 
			
			
			
			
			
			

				.button.buttonsearchZone1{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.item.itemsearchZone4{

				
				position:absolute !important; 
				
				
				
				
				

					.textedit.texteditsearchZone3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

			
}

		
}

		.grid.gridmainContent3{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.siteItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationmainContent53{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainContent{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.rowmainContent12{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowrowmainContent14{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowmainContent11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textrowmainContent112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.rectangle.searchZone{

			
			position:relative !important; 
			
			
			
			
			
			

				.button.buttonsearchZone1{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.item.itemsearchZone4{

				
				position:absolute !important; 
				
				
				
				
				

					.textedit.texteditsearchZone3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

			
}

		
}

		.grid.gridmainContent3{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.siteItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationmainContent53{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}
}
</style>
