import { render, staticRenderFns } from "./AdminWebinarView.vue?vue&type=template&id=9596d090&scoped=true&"
import script from "./AdminWebinarView.vue?vue&type=script&lang=js&"
export * from "./AdminWebinarView.vue?vue&type=script&lang=js&"
import style0 from "./AdminWebinarView.vue?vue&type=style&index=0&id=9596d090&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9596d090",
  null
  
)

export default component.exports