<template>
 <div>  
<div id="MainSearchBar"> 
         
 <div  :class="'row column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <v-text-field  :placeholder = "'Search'" 
 :dense = "true" 
 :background-color = "lightPrimaryColor" 
 solo :class="'textfield searchInput '"   id="searchInput" ref="searchInput" v-model="query" :flat = "true" 
 :appendIcon = "'ion-ios-search'" 
  v-bind:style="{color : '#777777',}" @click:append="gotoSearch"></v-text-field>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'MainSearchBar',

    components: {},
	methods: {
	
		gotoSearch: function() { 		
	if(this.query){

		this.$router.push('/search?q=' + this.query)

	} else {

		this.$router.push('/search')

	}

}
,
	},
	computed: {
	
		lightPrimaryColor: function() { 		
	return this.$store.state.lightPrimaryColor

}
,
	},
	data(){
	return {
		
		query:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#MainSearchBar{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.row.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
margin-bottom: 0px ; 
width: calc(100% - (0px + 0px)) !important;
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;background-color:transparent ; 

.textfield.searchInput{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);

margin-left: 0px ; 

top: calc(50% + 0px) ; 
margin-right: 0px ; 
height:auto!important ; 
max-width: 280px!important ; 
flat:true !important; 
appendIcon:ion-ios-search !important; 
width: calc(100% - (0px + 0px)) !important;

color:#777777 ; 
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}


}


@media (max-width: 600px) {


.row.column0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.textfield.searchInput{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}


}
}

@media (min-width: 601px) {


.row.column0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.textfield.searchInput{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}


}
}

@media (min-width: 961px) {


.row.column0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.textfield.searchInput{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}


}
}

@media (min-width: 1265px) {


.row.column0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.textfield.searchInput{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}


}
}

@media (min-width: 1905px) {


.row.column0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.textfield.searchInput{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}


}
}
}
</style>
