<template>
 <div>  
<div id="Feed"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <MainLayout :class="'object defaultlayout01 '"   id="defaultlayout01" ref="defaultlayout01"  v-bind:style="{}" :currentTab = "0" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout015133 '"   id="contentwrappercolumnslottemplatedefaultlayout015133" ref="contentwrappercolumnslottemplatedefaultlayout015133"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
 :paddingLeft = "0" 
 :paddingRight = "0" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <v-row  :class="'grid gridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113 '"   id="gridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113" ref="gridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113"  v-bind:style="{}">
 <v-col  :class="'col leftZone '"   id="leftZone" ref="leftZone"  v-bind:style="{}" :cols = "12" 
 :md = "3" 
 :order = "1" 
 :order-md = "0" 
><div class="col-container">
 <div  :class="'column columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout01511311 '"   id="columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout01511311" ref="columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout01511311"  v-bind:style="{}">
 <FeedLeftSection :class="'object feedleftsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111 '"   id="feedleftsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111" ref="feedleftsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111"  v-bind:style="{}">
</FeedLeftSection>  </div></div></v-col>
 <v-col  :class="'col centerZone '"   id="centerZone" ref="centerZone"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
 :order = "0" 
 :order-md = "0" 
><div class="col-container">
 <div  :class="'column mainCol '"   id="mainCol" ref="mainCol"  v-bind:style="{}">
 <div  :class="'column topCol '"   id="topCol" ref="topCol"  v-bind:style="{}"><v-card class="overflow-hidden"
        
       :class="'tabnavigation toTabNav '"   id="toTabNav" ref="toTabNav" v-model="tab"  v-bind:style="{}"
        ><v-tabs
            v-model="tab"
style="z-index: 10;">
        <v-tabs-slider   :color = "primaryColor" 
></v-tabs-slider>
  
        <v-tab
          v-for='(i,n) in tabsName'
          :key="n"
          :href="`#tab-${n}`"
        >
          {{ i }}
          <v-icon v-if='iconName = []' >{{ iconName[n] }}</v-icon></v-tab>
  
        </v-tabs>
        <v-tabs-items  v-model="tab" style="height: -webkit-fill-available;" ><v-tab-item
          :key="0"
          :value="'tab-0'"
        >
          
 <SwiperRow :class="'object swiperrowcolumncoltab013110 '"   id="swiperrowcolumncoltab013110" ref="swiperrowcolumncoltab013110"  v-bind:style="{}" :list = "moodleCours" 
 :title = "$t('Feed.courses')" 
 :mini = "true" 
 :baseUrl = "'learning'" 
 :fromMoodle = "true" 
>
</SwiperRow></v-tab-item>
      <v-tab-item
          :key="1"
          :value="'tab-1'"
        >
          
 <SwiperRow :class="'object swiperrowcolumncoltab013111 '"   id="swiperrowcolumncoltab013111" ref="swiperrowcolumncoltab013111"  v-bind:style="{}" :list = "formations" 
 :title = "$t('Feed.formations')" 
 :mini = "true" 
 :baseUrl = "'learning'" 
>
</SwiperRow></v-tab-item>
      <v-tab-item
          :key="2"
          :value="'tab-2'"
        >
          
 <SwiperRow :class="'object swiperrowcolumncoltab0131122 '"   id="swiperrowcolumncoltab0131122" ref="swiperrowcolumncoltab0131122"  v-bind:style="{}" :list = "webinars" 
 :title = "$t('Feed.webinars')" 
 :mini = "true" 
 :baseUrl = "'webinars'" 
>
</SwiperRow></v-tab-item>
      <v-tab-item
          :key="3"
          :value="'tab-3'"
        >
          
 <SwiperRow :class="'object swiperrowcolumncoltab0131133 '"   id="swiperrowcolumncoltab0131133" ref="swiperrowcolumncoltab0131133"  v-bind:style="{}" :list = "articles" 
 :title = "$t('Feed.articles')" 
 :mini = "true" 
 :isArticles = "true" 
>
</SwiperRow></v-tab-item>
      </v-tabs-items></v-card>
 <ShareBoxFeed :class="'object shareBoxFeed '"   id="shareBoxFeed" ref="shareBoxFeed"  v-bind:style="{}" :showCreateDialog = "showCreateDialog" 
>
</ShareBoxFeed>  </div>
 <div  :class="'rectangle devider '"   id="devider" ref="devider"  v-bind:style="{backgroundColor : '#a1a1a166',}">  </div>
 <div  :class="'column feedColumn '"   id="feedColumn" ref="feedColumn"  v-bind:style="{}">
 <PostItem :class="'object postitemcolumncoltab013112151 ' + 'noFit'"   id="postitemcolumncoltab013112151" ref="postitemcolumncoltab013112151" v-for="(item,index) in posts" :key="'postitemcolumncoltab013112151' + index"  v-bind:style="{}" :share = "showShare" 
 :info = "item" 
>
</PostItem>  </div>  </div></div></v-col>
 <v-col  :class="'col rightZone '"   id="rightZone" ref="rightZone"  v-bind:style="{}" :cols = "12" 
 :md = "3" 
 :order = "2" 
><div class="col-container">
 <div  :class="'column columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111 '"   id="columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111" ref="columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111"  v-bind:style="{}">
 <SocialCallToAction :class="'object socialcalltoactionrightZone32 '"   id="socialcalltoactionrightZone32" ref="socialcalltoactionrightZone32"  v-bind:style="{}" :hasCloseBtn = "false" 
 :index = "2" 
>
</SocialCallToAction>
 <OrgaRightSection :class="'object orgarightsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout0151131111 '"   id="orgarightsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout0151131111" ref="orgarightsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout0151131111"  v-bind:style="{}" :standalone = "true" 
 :maxItem = "3" 
>
</OrgaRightSection>  </div></div></v-col>  </v-row></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 <CreatePostDialog :class="'object createpostdialogitem03 '"   id="createpostdialogitem03" ref="createpostdialogitem03" v-if="createPostDialogVisible"  v-bind:style="{}" :close = "closeCreateDialog" 
 :getPosts = "getPosts" 
>
</CreatePostDialog>
 <ShareModal :class="'object sharemodalitem052223 '"   id="sharemodalitem052223" ref="sharemodalitem052223" v-if="shareModalVi"  v-bind:style="{}" :close = "hideShare" 
 :url = "shareOptions.url" 
 :title = "shareOptions.title" 
 :hashtags = "shareOptions.hashtags" 
 :subject = "shareOptions.subject" 
 :description = "shareOptions.description" 
>
</ShareModal>  </div>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import FeedLeftSection from '../../At/Components/FeedPage/FeedLeftSection'
import SwiperRow from '../../At/Utils/SwiperRow'
import ShareBoxFeed from '../../At/Components/FeedPage/ShareBoxFeed'
import PostItem from '../../At/Components/FeedPage/PostItem'
import SocialCallToAction from '../../At/Utils/SocialCallToAction'
import OrgaRightSection from '../../At/Components/OrganisationsPage/OrgaRightSection'
import CreatePostDialog from '../../At/Components/FeedPage/CreatePostDialog'
import ShareModal from '../../At/Sections/Modal/ShareModal'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'

import introJs from 'intro.js/minified/intro.min.js';
import 'intro.js/minified/introjs.min.css';
export default {
	name: 'Feed',

    components: {MainLayout,ContentWrapper,FeedLeftSection,SwiperRow,ShareBoxFeed,PostItem,SocialCallToAction,OrgaRightSection,CreatePostDialog,ShareModal,},
	methods: {
	
		getFormations: function() { 		
	let config = {	

		populate: {	

			image: "*",

	    instructor: {

	      populate: ['image'],

	    }

	  }

	}

	

	Request.getLocalTableArgs('formations', config)

	.then((res) => {

		this.formations = res.data.data

	})

}
,
		getWebinars: function() { 		
	let config = {	

		populate: {	

			image: "*",

	    instructor: {

	      populate: ['image'],

	    }

	  }

	}

	

	Request.getLocalTableArgs('webinars', config)

	.then((res) => {

		this.webinars = res.data.data

	})

}
,
		getArticle: function() { 		
	let config = {	

		populate: {	

			image: "*",

	    redactor: {

	      populate: ['image'],

	    }

	  }

	}

	

	Request.getLocalTableArgs('articles', config)

	.then((res) => {

		this.articles = res.data.data

	})

}
,
		getPosts: function() { 		
	let config = {	

		populate: {	

			images: "*",

			video: "*",

			likers: "*",

	    author: {

	      populate: ['image'],

	    },

	    organisation: {

	      populate: ['logo'],

	    }

	  },

		sort: ['updatedAt:desc', 'id:desc']

	}

	

	Request.getTableArgs('posts', config)

	.then((res) => {

		this.posts = res.data.data

	})

}
,
		getMoodleCoursByField: function(){ 		
	Request.getMoodleCoursByField()

	.then((res) => {

		this.moodleCours = res.data.courses

			.reverse().slice(0, 6)

	})

}
,
		closeCreateDialog: function() { 		
	this.createPostDialogVisible = false

}
,
		showCreateDialog: function() { 		
	this.createPostDialogVisible = true

}
,
		showShare: function(shareOptions) { 		
	this.shareOptions = shareOptions

	this.shareModalVi = true;

}
,
		hideShare: function() { 		
	this.shareModalVi = false;

}
,
		setupTour: function() { 		
	if(!this.$store.state.feedIntroWached){

		introJs().setOptions({	

		  steps: [	

			  {

			    element: document.querySelector('.searchInput'),

			    intro: this.$t('Introductions.searchBar')

			  },

			  {

			    element: document.querySelector('.rightSectionBar'),

			    intro: this.$t('Introductions.rightSection')

			  },

			  {

			    element: document.querySelector('.langSelect'),

			    intro: this.$t('Introductions.selectLang')

			  },

			  {

			    element: document.querySelector('.toTabNav'),

			    intro: this.$t('Introductions.statusTab')

			  },

			  {

			    element: document.querySelector('.shareBoxFeed'),

			    intro: this.$t('Introductions.doPost')

			  },

			  {

			    element: document.querySelector('.feedColumn'),

			    intro: this.$t('Introductions.feedSection'),

						position: 'top'

			  },

			]

		}).start();

		

		this.$store.commit('watchfeedIntro')

	}

	

}
,
	},
	mounted(){
			
	this.getMoodleCoursByField()

	this.getFormations()

	this.getWebinars()

	this.getArticle()

	this.getPosts()

	

	this.setupTour()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		tabsName: function() { 		
	return [

		this.$t('Feed.courses'),

		this.$t('Feed.formations'),

		this.$t('Feed.webinars'),

		this.$t('Feed.articles')

	]

}
,
		user: function() { 		
	return this.$store.state.user

}
,
	},
	watch: {
	
		user: function(val) { 			
	if(this.user.country != undefined){

		Main.notify(this.$t('Profile.selectCountry'))

	}

}
,
	},
	data(){
	return {
		
		moodleCours:  []		,
		formations:  []		,
		webinars:  []		,
		articles:  []		,
		events:  []		,
		posts:  []		,
		tab:  0		,
		createPostDialogVisible:  false		,
		shareModalVi:  false		,
		shareOptions:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Feed{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.link {
	text-decoration: none;
	cursor: pointer;
	
	&:hover {
		text-decoration: underline;
		text-decoration-color: #e4580a;
	}
}

.object{
	::v-deep > div  {
		min-width: unset !important;
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.object.defaultlayout01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.contentwrappercolumnslottemplatedefaultlayout015133{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.gridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					background-color: transparent ; 

						.col.leftZone{

						
						position:relative !important; 
						margin: unset;
						transform-origin: top left;
						
						
						
						height:auto!important ; 
						

						::v-deep .col-container{position: relative;

						height: auto;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout01511311{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;background-color: transparent ; 

								.object.feedleftsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

						.col.centerZone{

						
						position:relative !important; 
						margin: unset;
						transform-origin: top left;
						
						
						
						height:auto!important ; 
						

						::v-deep .col-container{position: relative;

						height: auto;

						width: 100%;

							.column.mainCol{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;
							gap:25px ; background-color: transparent ; 

								.column.topCol{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:10px ; background-color: transparent ; 

									.tabnavigation.toTabNav{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:300px!important ; 
									width: calc(100% - (0px + 0px)) !important;
									

									.object.swiperrowcolumncoltab013110{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 10px ; 
									
									margin-top: 10px ; 
									margin-right: 10px ; 
									margin-bottom: 10px ; 
									width: calc(100% - (10px + 10px)) !important;
									height: calc(100% - (10px + 10px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab013111{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 10px ; 
									
									margin-top: 10px ; 
									margin-right: 10px ; 
									margin-bottom: 10px ; 
									width: calc(100% - (10px + 10px)) !important;
									height: calc(100% - (10px + 10px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131122{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 10px ; 
									
									margin-top: 10px ; 
									margin-right: 10px ; 
									margin-bottom: 10px ; 
									width: calc(100% - (10px + 10px)) !important;
									height: calc(100% - (10px + 10px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131133{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 10px ; 
									
									margin-top: 10px ; 
									margin-right: 10px ; 
									margin-bottom: 10px ; 
									width: calc(100% - (10px + 10px)) !important;
									height: calc(100% - (10px + 10px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}}

									.object.shareBoxFeed{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

								.rectangle.devider{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:1px!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								background-color:#a1a1a166 ; 

								
}

								.column.feedColumn{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:5px ; background-color: transparent ; 

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.rightZone{

						
						position:relative !important; 
						margin: unset;
						transform-origin: top left;
						
						
						
						height:auto!important ; 
						

						::v-deep .col-container{position: relative;

						height: auto;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;
							gap:30px ; background-color: transparent ; 

								.object.socialcalltoactionrightZone32{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.orgarightsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout0151131111{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.createpostdialogitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.sharemodalitem052223{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015133{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.gridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113{

					
					position:relative !important; 
					
					
					
					
					

						.col.leftZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout01511311{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.feedleftsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

						.col.centerZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.mainCol{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.column.topCol{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.tabnavigation.toTabNav{

									
									position:relative !important; 
									
									
									
									
									

									.object.swiperrowcolumncoltab013110{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab013111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131122{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131133{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}}

									.object.shareBoxFeed{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

								.rectangle.devider{

								
								position:relative !important; 
								
								
								
								
								
								

								
}

								.column.feedColumn{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.rightZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.socialcalltoactionrightZone32{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.orgarightsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout0151131111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.createpostdialogitem03{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.sharemodalitem052223{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015133{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.gridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113{

					
					position:relative !important; 
					
					
					
					
					

						.col.leftZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout01511311{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.feedleftsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

						.col.centerZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.mainCol{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.column.topCol{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.tabnavigation.toTabNav{

									
									position:relative !important; 
									
									
									
									
									

									.object.swiperrowcolumncoltab013110{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab013111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131122{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131133{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}}

									.object.shareBoxFeed{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

								.rectangle.devider{

								
								position:relative !important; 
								
								
								
								
								
								

								
}

								.column.feedColumn{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.rightZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.socialcalltoactionrightZone32{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.orgarightsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout0151131111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.createpostdialogitem03{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.sharemodalitem052223{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015133{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.gridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113{

					
					position:relative !important; 
					
					
					
					
					

						.col.leftZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout01511311{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.feedleftsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

						.col.centerZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.mainCol{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.column.topCol{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.tabnavigation.toTabNav{

									
									position:relative !important; 
									
									
									
									
									

									.object.swiperrowcolumncoltab013110{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab013111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131122{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131133{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}}

									.object.shareBoxFeed{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

								.rectangle.devider{

								
								position:relative !important; 
								
								
								
								
								
								

								
}

								.column.feedColumn{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.rightZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.socialcalltoactionrightZone32{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.orgarightsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout0151131111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.createpostdialogitem03{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.sharemodalitem052223{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015133{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.gridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113{

					
					position:relative !important; 
					
					
					
					
					

						.col.leftZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout01511311{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.feedleftsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

						.col.centerZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.mainCol{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.column.topCol{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.tabnavigation.toTabNav{

									
									position:relative !important; 
									
									
									
									
									

									.object.swiperrowcolumncoltab013110{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab013111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131122{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131133{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}}

									.object.shareBoxFeed{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

								.rectangle.devider{

								
								position:relative !important; 
								
								
								
								
								
								

								
}

								.column.feedColumn{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.rightZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.socialcalltoactionrightZone32{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.orgarightsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout0151131111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.createpostdialogitem03{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.sharemodalitem052223{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout015133{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.gridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113{

					
					position:relative !important; 
					
					
					
					
					

						.col.leftZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout01511311{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.feedleftsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

						.col.centerZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.mainCol{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.column.topCol{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.tabnavigation.toTabNav{

									
									position:relative !important; 
									
									
									
									
									

									.object.swiperrowcolumncoltab013110{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab013111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131122{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

									.object.swiperrowcolumncoltab0131133{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}}

									.object.shareBoxFeed{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

								.rectangle.devider{

								
								position:relative !important; 
								
								
								
								
								
								

								
}

								.column.feedColumn{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.rightZone{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout015113111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.socialcalltoactionrightZone32{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.orgarightsectioncolumncolgridslottemplatecontentwrappercolumnslottemplatedefaultlayout0151131111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.createpostdialogitem03{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.sharemodalitem052223{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
