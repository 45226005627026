<template>
 <div>  
<div id="ShareModal"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangle0 '"   id="rectangle0" ref="rectangle0"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectangle01 '"   id="mousearearectangle01" ref="mousearearectangle01"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'column box2 '"   id="box2" ref="box2"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'row rowbox1 '"   id="rowbox1" ref="rowbox1"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textrowbox11 '"   id="textrowbox11" ref="textrowbox11"  v-bind:style="{color : '#000',}">
<div class="text-content" :style = "{}">{{ $t('shareModal.share') }}</div>

   </div>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 :dark = "true" 
 @click="close" :class="'button buttonrowbox13 '"   id="buttonrowbox13" ref="buttonrowbox13"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-close' !== undefined"  :color = "'#000'" 
>{{ 'ion-ios-close' }}
</v-icon></v-btn>  </div>
 <div  :class="'item itemrowbox152 '"   id="itemrowbox152" ref="itemrowbox152"  v-bind:style="{}">
 <Swiper :class="'importedobject importedobjectitemrowbox1525 '"   id="importedobjectitemrowbox1525" ref="importedobjectitemrowbox1525" :options = "swiperOptions" 
  v-bind:style="{}">
 <SwiperSlide :class="'importedobject importedobjectimportedobjectitemrowbox15251 '"   id="importedobjectimportedobjectitemrowbox15251" ref="importedobjectimportedobjectitemrowbox15251" v-for="(item,index) in socialPlatform" :key="'importedobjectimportedobjectitemrowbox15251' + index"  v-bind:style="{}">
 <div  :class="'column columnimportedobjectimportedobjectitemrowbox152511 '"   id="columnimportedobjectimportedobjectitemrowbox152511" ref="columnimportedobjectimportedobjectitemrowbox152511" v-on:click="share(item.tag)"  :_cursor = "'pointer'" 
  v-bind:style="{cursor : 'pointer',justifyContent : 'space-around',}">
 <div  :class="'rectangle rectanglecolumnimportedobjectimportedobjectitemrowbox1525111 '"   id="rectanglecolumnimportedobjectimportedobjectitemrowbox1525111" ref="rectanglecolumnimportedobjectimportedobjectitemrowbox1525111"  v-bind:style="{backgroundColor : item.color,}">
 <v-img  :class="'image imagerectanglecolumnimportedobjectimportedobjectitemrowbox15251111 '"   id="imagerectanglecolumnimportedobjectimportedobjectitemrowbox15251111" ref="imagerectanglecolumnimportedobjectimportedobjectitemrowbox15251111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../../' + 'Resources' + item.image + '')" 
>  </v-img>  </div>
 <div  :class="'text textcolumnimportedobjectimportedobjectitemrowbox1525113 '"   id="textcolumnimportedobjectimportedobjectitemrowbox1525113" ref="textcolumnimportedobjectimportedobjectitemrowbox1525113"  v-bind:style="{color : '#000',}">
<div class="text-content" :style = "{}">{{ item.name }}</div>

   </div>  </div>
</SwiperSlide>
</Swiper>
 <v-card 
 :class="'card carditemrowbox1521 ' + ' ' + 'swiper-button-prev' + `elevation-${5}`"   id="carditemrowbox1521" ref="carditemrowbox1521" :elevation = "5" 
 :_color = "primaryColor" 
  v-bind:style="{color : primaryColor,}">  </v-card>
 <v-card 
 :class="'card carditemrowbox15212 ' + ' ' + 'swiper-button-next' + `elevation-${5}`"   id="carditemrowbox15212" ref="carditemrowbox15212" :elevation = "5" 
 :_color = "primaryColor" 
  v-bind:style="{color : primaryColor,}">  </v-card>  </div>
 <div  :class="'row rectanglebox25 '"   id="rectanglebox25" ref="rectanglebox25" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',justifyContent : 'space-between',backgroundColor : 'transparent',}">
 <div  :class="'text textrectanglebox253 '"   id="textrectanglebox253" ref="textrectanglebox253" :_flex = "'1'" 
  v-bind:style="{flex : '1',color : primaryColor,}">
<div class="text-content" :style = "{}">{{ url }}</div>

   </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 @click="copyToClip" :class="'button buttonrectanglebox251 '"   id="buttonrectanglebox251" ref="buttonrectanglebox251" :_textTransform = "'uppercase'" 
  v-bind:style="{textTransform : 'uppercase',}"><span>{{ $t('shareModal.copy') }}</span>
</v-btn>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import copy from 'copy-to-clipboard';
export default {
	name: 'ShareModal',

    components: {Swiper,SwiperSlide,},
	methods: {
	
		share: function(tag) { 		
	Main.share(tag, this.shareOptions)

}
,
		copyToClip: function() { 		
	copy(this.url)

}
,
	},
	computed: {
	
		shareOptions: function() { 		
	return {

		url: this.url, 

		title: this.title + '\n', 

		hashtags: this.basicTags.concat(this.hashtags), 

		description: this.description, 

		to: '',

		tags: this.basicTags.concat(this.hashtags),

		subject: this.subject

	}

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		socialPlatform: function(){ 		
	return [

		{

			name: 'WhatsApp',

			color: '#23d366',

			tag: 'whatsapp',

			image: '/social/whatsapp.png'

		},

		{

			name: 'Linkedin',

			color: '#0177b5',

			tag: 'linkedin',

			image: '/social/linkedin.png'

		},

		{

			name: 'Telegram',

			color: '#077bc1',

			tag: 'telegram',

			image: '/social/telegram.png'

		},

		{

			name: 'Facebook',

			color: '#3c5997',

			tag: 'facebook',

			image: '/social/facebook2.png'

		},

		{

			name: 'Twitter',

			color: '#1da1f1',

			tag: 'twitter',

			image: '/social/twitter2.png'

		},

		{

			name: 'Mail',

			color: '#888888',

			tag: 'mail',

			image: '/social/mail.png'

		},

		{

			name: 'Reddit',

			color: '#fe4500',

			tag: 'reddit',

			image: '/social/reddit.png'

		},

		{

			name: 'Vk',

			color: '#4680c2',

			tag: 'vk',

			image: '/social/vk.png'

		},

		{

			name: 'Pinterest',

			color: '#bd091c',

			tag: 'pinterest',

			image: '/social/pinterest.png'

		},

		{

			name: 'Tumblr',

			color: '#34465c',

			tag: 'tumblr',

			image: '/social/tumblr.png'

		},

		{

			name: 'Viber',

			color: '#7360f2',

			tag: 'viber',

			image: '/social/viber.png'

		},

	]

}
,
	},
	data(){
	return {
		
		basicTags:  ['Afrotiative', 'okike', 'African']		,
		swiperOptions:  {		
  spaceBetween: 5,

	navigation: {

    nextEl: '.swiper-button-next',

    prevEl: '.swiper-button-prev'

  }

}
,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		url: {					required: false,			default: () =>  "https://afrotiative.com",		},
		title: {					required: false,			default: () =>  "Afrotiative",		},
		hashtags: {					required: false,			default: () =>  [],		},
		subject: {					required: false,			default: () =>  "...",		},
		description: {					required: false,			default: () =>  "...",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ShareModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.swiper-button-prev, .swiper-button-next{
	color: #fff;
	top: unset;
	left: unset;
	right: unset;
  justify-content: center;
  display: flex;
	
	&::after{
		font-size: 20px;
    justify-content: center;
    display: flex;
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangle0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.7 ;  filter: alpha(opacity=70) ; 

background-color:#000 ; 

	.mousearea.mousearearectangle01{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.column.box2{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:90%!important ; 
height:250px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; 
background-color:#fff ; 
border-radius:8px ; 
padding-top:20px ; 
padding-bottom:20px ; 
padding-left:20px ; 
padding-right:20px ; 

	.row.rowbox1{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:38px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:space-between ; background-color:transparent ; 

		.text.textrowbox11{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		
		color:#000 ; 
		font-size:20px ; 
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowbox13{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:38px!important ; 
		height:38px!important ; 
		
		font-size:38px ; 
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		font-size:38px ; 
		}
		}

	
}

	.item.itemrowbox152{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:100px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	

		.importedobject.importedobjectitemrowbox1525{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		

			.importedobject.importedobjectimportedobjectitemrowbox15251{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			margin-bottom: 0px ; 
			width:70px!important ; 
			height: calc(100% - (0px + 0px)) !important;
			

				.column.columnimportedobjectimportedobjectitemrowbox152511{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				cursor:pointer !important; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				justify-content:space-around ; background-color: transparent ; 

					.rectangle.rectanglecolumnimportedobjectimportedobjectitemrowbox1525111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					width:60px!important ; 
					height:60px!important ; 
					
					background-color:#ffffff ; 
					border-radius:30px ; 

						.image.imagerectanglecolumnimportedobjectimportedobjectitemrowbox15251111{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left: 10px ; 
						
						top: 10px ; 
						right: 10px ; 
						bottom: 10px ; 
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

						
}

					
}

					.text.textcolumnimportedobjectimportedobjectitemrowbox1525113{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#000 ; 
					text-align:center;
					align-items:center;
					display: flex;
					text-overflow: ellipsis ; 
					.text-content{
					width: 100%;
					white-space: nowrap;
					overflow: hidden ;
					text-overflow: ellipsis ; 
					}

					
}

				
}

			
}

		
}

		.card.carditemrowbox1521{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left: -20px ; 
		
		top: calc(50% + 0px) ; 
		width:40px!important ; 
		height:40px!important ; 
		background-color:#ffffff ; 
		border-radius:100% ; max-width: initial;min-width: initial;

		
}

		.card.carditemrowbox15212{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		right: -20px ; 
		width:40px!important ; 
		height:40px!important ; 
		background-color:#ffffff ; 
		border-radius:100% ; max-width: initial;min-width: initial;

		
}

	
}

	.row.rectanglebox25{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:40px!important ; 
	overflow:hidden !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:space-between ; 
	background-color:transparent ; 
	border-radius:2px ; 
	border-style:solid ; 
	border-width:1px ; 
	border-color:#000 ; 
	padding-top:5px ; 
	padding-bottom:5px ; 
	padding-left:5px ; 
	padding-right:5px ; 

		.text.textrectanglebox253{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		opacity: 0.7 ;  filter: alpha(opacity=70) ; 
		flex:1 !important; 
		height: calc(100% - (0px + 0px)) !important;
		
		align-items:center;
		display: flex;
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

		.button.buttonrectanglebox251{

		height: auto;
		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 4px ; 
		right: 4px ; 
		bottom: 4px ; 
		width:auto!important ; 
		textTransform:uppercase !important; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangle0{


position:absolute !important; 







	.mousearea.mousearearectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.box2{


position:absolute !important; 









	.row.rowbox1{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowbox11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowbox13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.item.itemrowbox152{

	
	position:relative !important; 
	
	
	
	
	

		.importedobject.importedobjectitemrowbox1525{

		
		position:absolute !important; 
		
		
		
		
		

			.importedobject.importedobjectimportedobjectitemrowbox15251{

			
			position:relative !important; 
			
			
			
			
			

				.column.columnimportedobjectimportedobjectitemrowbox152511{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.rectangle.rectanglecolumnimportedobjectimportedobjectitemrowbox1525111{

					
					position:relative !important; 
					
					
					
					
					
					

						.image.imagerectanglecolumnimportedobjectimportedobjectitemrowbox15251111{

						
						position:absolute !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

						
}

					
}

					.text.textcolumnimportedobjectimportedobjectitemrowbox1525113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.card.carditemrowbox1521{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

		.card.carditemrowbox15212{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

	
}

	.row.rectanglebox25{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectanglebox253{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrectanglebox251{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangle0{


position:absolute !important; 







	.mousearea.mousearearectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.box2{


position:absolute !important; 









	.row.rowbox1{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowbox11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowbox13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.item.itemrowbox152{

	
	position:relative !important; 
	
	
	
	
	

		.importedobject.importedobjectitemrowbox1525{

		
		position:absolute !important; 
		
		
		
		
		

			.importedobject.importedobjectimportedobjectitemrowbox15251{

			
			position:relative !important; 
			
			
			
			
			

				.column.columnimportedobjectimportedobjectitemrowbox152511{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.rectangle.rectanglecolumnimportedobjectimportedobjectitemrowbox1525111{

					
					position:relative !important; 
					
					
					
					
					
					

						.image.imagerectanglecolumnimportedobjectimportedobjectitemrowbox15251111{

						
						position:absolute !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

						
}

					
}

					.text.textcolumnimportedobjectimportedobjectitemrowbox1525113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.card.carditemrowbox1521{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

		.card.carditemrowbox15212{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

	
}

	.row.rectanglebox25{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectanglebox253{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrectanglebox251{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangle0{


position:absolute !important; 







	.mousearea.mousearearectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.box2{


position:absolute !important; 




width:518px!important ; 





	.row.rowbox1{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowbox11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowbox13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.item.itemrowbox152{

	
	position:relative !important; 
	
	
	
	
	

		.importedobject.importedobjectitemrowbox1525{

		
		position:absolute !important; 
		
		
		
		
		

			.importedobject.importedobjectimportedobjectitemrowbox15251{

			
			position:relative !important; 
			
			
			
			
			

				.column.columnimportedobjectimportedobjectitemrowbox152511{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.rectangle.rectanglecolumnimportedobjectimportedobjectitemrowbox1525111{

					
					position:relative !important; 
					
					
					
					
					
					

						.image.imagerectanglecolumnimportedobjectimportedobjectitemrowbox15251111{

						
						position:absolute !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

						
}

					
}

					.text.textcolumnimportedobjectimportedobjectitemrowbox1525113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.card.carditemrowbox1521{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

		.card.carditemrowbox15212{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

	
}

	.row.rectanglebox25{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectanglebox253{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrectanglebox251{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangle0{


position:absolute !important; 







	.mousearea.mousearearectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.box2{


position:absolute !important; 









	.row.rowbox1{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowbox11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowbox13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.item.itemrowbox152{

	
	position:relative !important; 
	
	
	
	
	

		.importedobject.importedobjectitemrowbox1525{

		
		position:absolute !important; 
		
		
		
		
		

			.importedobject.importedobjectimportedobjectitemrowbox15251{

			
			position:relative !important; 
			
			
			
			
			

				.column.columnimportedobjectimportedobjectitemrowbox152511{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.rectangle.rectanglecolumnimportedobjectimportedobjectitemrowbox1525111{

					
					position:relative !important; 
					
					
					
					
					
					

						.image.imagerectanglecolumnimportedobjectimportedobjectitemrowbox15251111{

						
						position:absolute !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

						
}

					
}

					.text.textcolumnimportedobjectimportedobjectitemrowbox1525113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.card.carditemrowbox1521{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

		.card.carditemrowbox15212{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

	
}

	.row.rectanglebox25{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectanglebox253{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrectanglebox251{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangle0{


position:absolute !important; 







	.mousearea.mousearearectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.box2{


position:absolute !important; 









	.row.rowbox1{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowbox11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrowbox13{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.item.itemrowbox152{

	
	position:relative !important; 
	
	
	
	
	

		.importedobject.importedobjectitemrowbox1525{

		
		position:absolute !important; 
		
		
		
		
		

			.importedobject.importedobjectimportedobjectitemrowbox15251{

			
			position:relative !important; 
			
			
			
			
			

				.column.columnimportedobjectimportedobjectitemrowbox152511{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.rectangle.rectanglecolumnimportedobjectimportedobjectitemrowbox1525111{

					
					position:relative !important; 
					
					
					
					
					
					

						.image.imagerectanglecolumnimportedobjectimportedobjectitemrowbox15251111{

						
						position:absolute !important; 
						
						
						
						
						
						display: inherit;

						flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

						
}

					
}

					.text.textcolumnimportedobjectimportedobjectitemrowbox1525113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.card.carditemrowbox1521{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

		.card.carditemrowbox15212{

		
		position:absolute !important; 
		
		
		
		
		max-width: initial;min-width: initial;

		
}

	
}

	.row.rectanglebox25{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectanglebox253{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttonrectanglebox251{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}
}
</style>
