<template>
 <div>  
<div id="Admin"> 
         
 <div  :class="'row row0 '"   id="row0" ref="row0"  v-bind:style="{backgroundColor : '#f6f6f9',}">
 <div  :class="'item leftSection '"   id="leftSection" ref="leftSection"  v-bind:style="{width : getUnit(leftExpended? 223 : 64,'px'),}">
 <AdminLeftSection :class="'object adminleftsectionleftSection1 '"   id="adminleftsectionleftSection1" ref="adminleftsectionleftSection1"  v-bind:style="{}" :hideLeftZone = "hideLeftZone" 
 :showLeftZone = "showLeftZone" 
 :selectTab = "selectTab" 
 :expended = "leftExpended" 
 :selectedTab = "selectedTab" 
 :hasEducatorAccess = "hasEducatorAccess" 
 :hasOrganisationAccess = "hasOrganisationAccess" 
>
</AdminLeftSection>  </div>
 <div  :class="'scrollarea scrollarearow03 '"   id="scrollarearow03" ref="scrollarearow03" :_flex = "'1'" 
  v-bind:style="{flex : '1',backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <AdminWebinarView :class="'object adminwebinarviewscrollarearow0313 '"   id="adminwebinarviewscrollarearow0313" ref="adminwebinarviewscrollarearow0313" v-if="hasEducatorAccess && selectedTab == 'webinars'"  v-bind:style="{}">
</AdminWebinarView>
 <AdminFormationView :class="'object adminformationviewscrollarearow033 '"   id="adminformationviewscrollarearow033" ref="adminformationviewscrollarearow033" v-if="hasEducatorAccess && selectedTab == 'formations'"  v-bind:style="{}">
</AdminFormationView>
 <AdminArticleView :class="'object adminarticleviewscrollarearow031 '"   id="adminarticleviewscrollarearow031" ref="adminarticleviewscrollarearow031" v-if="hasEducatorAccess && selectedTab == 'articles'"  v-bind:style="{}">
</AdminArticleView>
 <AdminOrganisationView :class="'object adminorganisationviewscrollarearow037 '"   id="adminorganisationviewscrollarearow037" ref="adminorganisationviewscrollarearow037" v-if="hasOrganisationAccess && selectedTab == 'organisations'"  v-bind:style="{}">
</AdminOrganisationView>
 <AdminPageView :class="'object adminpageviewscrollarearow039 '"   id="adminpageviewscrollarearow039" ref="adminpageviewscrollarearow039" v-if="hasOrganisationAccess && selectedTab == 'pages'"  v-bind:style="{}">
</AdminPageView>
 <AdminProjectView :class="'object adminprojectviewscrollarearow0311 '"   id="adminprojectviewscrollarearow0311" ref="adminprojectviewscrollarearow0311" v-if="hasOrganisationAccess && selectedTab == 'projects'"  v-bind:style="{}">
</AdminProjectView>
 <NoAccessView :class="'object noAccessView '"   id="noAccessView" ref="noAccessView" v-if="noAccess"  v-bind:style="{}">
</NoAccessView>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import AdminLeftSection from '../../At/Components/AdminPage/AdminLeftSection'
import AdminWebinarView from '../../At/Components/AdminPage/AdminWebinarView'
import AdminFormationView from '../../At/Components/AdminPage/AdminFormationView'
import AdminArticleView from '../../At/Components/AdminPage/AdminArticleView'
import AdminOrganisationView from '../../At/Components/AdminPage/AdminOrganisationView'
import AdminPageView from '../../At/Components/AdminPage/AdminPageView'
import AdminProjectView from '../../At/Components/AdminPage/AdminProjectView'
import NoAccessView from '../../At/Components/AdminPage/NoAccessView'

import * as Request from '../../Js/requests'
export default {
	name: 'Admin',

    components: {AdminLeftSection,AdminWebinarView,AdminFormationView,AdminArticleView,AdminOrganisationView,AdminPageView,AdminProjectView,NoAccessView,},
	methods: {
	
		hideLeftZone: function() { 		
	this.leftExpended = false

}
,
		showLeftZone: function() { 		
	this.leftExpended = true

}
,
		selectTab: function(tab) { 		
	this.selectedTab = tab

}
,
		getUser: function() { 		
	Request.getTable('users/' + this.user.id)

	.then((res) => {

		this.$store.commit('changeUser', res.data)

	})

}
,
	},
	mounted(){
			
	if(this.user == null){

		this.$router.push('/login')

	}

	this.getUser()

	document.title = 'Admin | Afrotiative'

	

	if(this.tab){

		this.selectedTab = this.tab

	} else {

		if(this.hasEducatorAccess){

			this.selectedTab = "webinars"

		} else {

			if(this.hasOrganisationAccess){

				this.selectedTab = "organisations"

			} else {

				this.selectedTab = null

			}

		}

	}

	},
	computed: {
	
		user: function() { 		
	return this.$store.state.user

}
,
		hasEducatorAccess: function() { 		
	return this.user.educatorAccess ?? false

}
,
		hasOrganisationAccess: function() { 		
	return this.user.organisationAccess ?? false

}
,
		noAccess: function() { 		
	return !this.hasEducatorAccess 

		&& !this.hasOrganisationAccess

}
,
		tab: function() { 		
	return this.$route.params.tab

}
,
	},
	watch: {
	
		selectedTab: function(val) { 		
	console.log("----->", val)

	this.$router.push("/admin/" + val)

}
,
		tab: function(val) { 		
	//location.reload();

}
,
	},
	data(){
	return {
		
		leftExpended:  true		,
		selectedTab:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Admin{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.row.row0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
background-color:#f6f6f9 ; 

.item.leftSection{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
z-index:10 ; 
height: calc(100% - (0px + 0px)) !important;


	.object.adminleftsectionleftSection1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.scrollarea.scrollarearow03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-right: 0px ; 
margin-bottom: 0px ; 
flex:1 !important; 
height: calc(100% - (0px + 0px)) !important;

overflow:hidden ; 
overflow-x:hidden ; 
overflow-y:auto ; 
background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #b7b7b7;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #b7b7b7;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #737373;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

	.object.adminwebinarviewscrollarearow0313{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminformationviewscrollarearow033{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminarticleviewscrollarearow031{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminorganisationviewscrollarearow037{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminpageviewscrollarearow039{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminprojectviewscrollarearow0311{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.noAccessView{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}


@media (max-width: 600px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.item.leftSection{


position:relative !important; 






	.object.adminleftsectionleftSection1{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.scrollarea.scrollarearow03{


position:relative !important; 






	.object.adminwebinarviewscrollarearow0313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminformationviewscrollarearow033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminarticleviewscrollarearow031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminorganisationviewscrollarearow037{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminpageviewscrollarearow039{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminprojectviewscrollarearow0311{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.noAccessView{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 601px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.item.leftSection{


position:relative !important; 






	.object.adminleftsectionleftSection1{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.scrollarea.scrollarearow03{


position:relative !important; 






	.object.adminwebinarviewscrollarearow0313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminformationviewscrollarearow033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminarticleviewscrollarearow031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminorganisationviewscrollarearow037{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminpageviewscrollarearow039{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminprojectviewscrollarearow0311{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.noAccessView{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 961px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.item.leftSection{


position:relative !important; 






	.object.adminleftsectionleftSection1{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.scrollarea.scrollarearow03{


position:relative !important; 






	.object.adminwebinarviewscrollarearow0313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminformationviewscrollarearow033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminarticleviewscrollarearow031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminorganisationviewscrollarearow037{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminpageviewscrollarearow039{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminprojectviewscrollarearow0311{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.noAccessView{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1265px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.item.leftSection{


position:relative !important; 






	.object.adminleftsectionleftSection1{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.scrollarea.scrollarearow03{


position:relative !important; 






	.object.adminwebinarviewscrollarearow0313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminformationviewscrollarearow033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminarticleviewscrollarearow031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminorganisationviewscrollarearow037{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminpageviewscrollarearow039{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminprojectviewscrollarearow0311{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.noAccessView{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1905px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.item.leftSection{


position:relative !important; 






	.object.adminleftsectionleftSection1{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.scrollarea.scrollarearow03{


position:relative !important; 






	.object.adminwebinarviewscrollarearow0313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminformationviewscrollarearow033{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminarticleviewscrollarearow031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminorganisationviewscrollarearow037{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminpageviewscrollarearow039{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.adminprojectviewscrollarearow0311{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.noAccessView{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}
}
</style>
