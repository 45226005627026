<template>
 <div>  
<div id="AdminTableView"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'flow flowcolumn03 '"   id="flowcolumn03" ref="flowcolumn03"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'column columnflowcolumn032 '"   id="columnflowcolumn032" ref="columnflowcolumn032"  v-bind:style="{}">
 <div  :class="'text textflowcolumn031 '"   id="textflowcolumn031" ref="textflowcolumn031"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <div  :class="'text textflowcolumn0312 '"   id="textflowcolumn0312" ref="textflowcolumn0312"  v-bind:style="{color : '#9d9997',}">
<div class="text-content" :style = "{}">{{ nbFound }}</div>

   </div>  </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 @click="createItem" :class="'button buttonflowcolumn033 '"   id="buttonflowcolumn033" ref="buttonflowcolumn033"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-ios-add' !== undefined"  :left = "true" 
>{{ 'ion-ios-add' }}
</v-icon><span>{{ $t('Admin.newEntry') }}</span>
</v-btn>  </div>
 <div  :class="'column columncolumn07 '"   id="columncolumn07" ref="columncolumn07"  v-bind:style="{}">
 <v-text-field  :placeholder = "$t('Admin.search')" 
 :prepend-inner-icon = "'ion-ios-search'" 
 solo :class="'textfield textfieldcolumn051 '"   id="textfieldcolumn051" ref="textfieldcolumn051" v-model="search"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-data-table :class="'importedobject importedobjectcolumn012 ' + ' ' + 'elevation-1'"   id="importedobjectcolumn012" ref="importedobjectcolumn012" :items = "m_items" 
 :headers = "headers" 
 :footerProps = "footerProps" 
 :loading = "loading" 
 :loadingText = "'Loading... Please wait'" 
 :multiSort = "true" 
 :search = "search" 
  v-bind:style="{}">
 <template  v-slot:item.actions = "{ item }">
 <div  :class="'slottemplate slottemplateimportedobjectcolumn0121 '"   id="slottemplateimportedobjectcolumn0121" ref="slottemplateimportedobjectcolumn0121"  v-bind:style="{}">
 <div  :class="'row rowslottemplateimportedobjectcolumn01211 '"   id="rowslottemplateimportedobjectcolumn01211" ref="rowslottemplateimportedobjectcolumn01211"  v-bind:style="{}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="editItem(item)" :class="'button buttonrowslottemplateimportedobjectcolumn012111 '"   id="buttonrowslottemplateimportedobjectcolumn012111" ref="buttonrowslottemplateimportedobjectcolumn012111"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-create' !== undefined" >{{ 'ion-md-create' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="showConfDiag(item)" :class="'button buttonrowslottemplateimportedobjectcolumn0121112 '"   id="buttonrowslottemplateimportedobjectcolumn0121112" ref="buttonrowslottemplateimportedobjectcolumn0121112"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-trash' !== undefined" >{{ 'ion-md-trash' }}
</v-icon></v-btn>  </div></div></template>
</v-data-table>  </div>
 <ConfirmDialog :class="'object confirmdialogslottemplatecontentWrapper2213123 '"   id="confirmdialogslottemplatecontentWrapper2213123" ref="confirmdialogslottemplatecontentWrapper2213123" v-if="confirmDVisible"  v-bind:style="{}" :close = "hideConfDiag" 
 :action = "m_deleteItem" 
 :message = "$t('Admin.confirmDelete')" 
>
</ConfirmDialog>  </div>
 
      </div>
 </div> 
</template>
<script>
import ConfirmDialog from '../../../At/Utils/ConfirmDialog'


export default {
	name: 'AdminTableView',

    components: {ConfirmDialog,},
	methods: {
	
		showConfDiag: function(item) { 		
	this.confirmDVisible = true

	this.selectedItem = item

}
,
		hideConfDiag: function() { 		
	this.confirmDVisible = false

}
,
		m_deleteItem: function() { 		
	this.deleteItem(this.selectedItem)

	this.hideConfDiag()

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		m_items: function() { 		
	if(this.mapItems){

		return this.items.map(this.mappingFunction)

	}

	return this.items

}
,
		footerProps: function() { 		
	return {	

	  showFirstLastPage: true,

		showCurrentPage: true,

	  firstIcon: 'mdi-arrow-collapse-left',

	  lastIcon: 'mdi-arrow-collapse-right',

	  prevIcon: 'mdi-minus',

	  nextIcon: 'mdi-plus',

	  'items-per-page-text': this.$t('Admin.elePerPage'),

		'page-text': "..."

	}

}
,
		nbFound: function() { 		
	let nb = this.items?.length ?? 0

	return nb + ' ' + this.$t('Admin.entriesFound')

}
,
	},
	data(){
	return {
		
		search:  null		,
		confirmDVisible:  false		,
		selectedItem:  null		,
		}
	},
	props: {
	
		title: {					required: false,			default: () =>  "Event",		},
		items: {					required: false,			default: () =>  [],		},
		headers: {					required: false,			default: () =>  [],		},
		loading: {			type: Boolean,			required: false,			default: () =>  true,		},
		createItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		editItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		deleteItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		mapItems: {			type: Boolean,			required: false,			default: () =>  false,		},
		mappingFunction: {			type: Function,			required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminTableView{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:40px ; background-color: transparent ; 
padding-top:10px ; 
padding-bottom:10px ; 
padding-left:10px ; 
padding-right:10px ; 

.flow.flowcolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
justify-content:space-between ; background-color:transparent ; 

	.column.columnflowcolumn032{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:4px ; background-color: transparent ; 

		.text.textflowcolumn031{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		font-size:30px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.textflowcolumn0312{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		color:#9d9997 ; 
		font-size:18px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}

	.button.buttonflowcolumn033{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	height:40px!important ; 
	
	font-size:15px ; 
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	font-size:15px ; 
	}
	}


}

.column.columncolumn07{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:0px ; background-color: transparent ; 

	.textfield.textfieldcolumn051{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.importedobject.importedobjectcolumn012{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	

		.slottemplate.slottemplateimportedobjectcolumn0121{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.row.rowslottemplateimportedobjectcolumn01211{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:4px ; background-color:transparent ; 
			padding-top:5px ; 
			padding-bottom:5px ; 
			padding-left:5px ; 
			padding-right:5px ; 

				.button.buttonrowslottemplateimportedobjectcolumn012111{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:35px!important ; 
				
				font-size:20px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:20px ; 
				}
				}

				.button.buttonrowslottemplateimportedobjectcolumn0121112{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:35px!important ; 
				
				font-size:20px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:20px ; 
				}
				}

			
}

		
}

	
}


}

.object.confirmdialogslottemplatecontentWrapper2213123{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumn0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.button.buttonflowcolumn033{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn07{


position:relative !important; 









	.textfield.textfieldcolumn051{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.importedobject.importedobjectcolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.slottemplate.slottemplateimportedobjectcolumn0121{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.row.rowslottemplateimportedobjectcolumn01211{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowslottemplateimportedobjectcolumn012111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowslottemplateimportedobjectcolumn0121112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}

.object.confirmdialogslottemplatecontentWrapper2213123{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumn0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.button.buttonflowcolumn033{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn07{


position:relative !important; 









	.textfield.textfieldcolumn051{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.importedobject.importedobjectcolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.slottemplate.slottemplateimportedobjectcolumn0121{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.row.rowslottemplateimportedobjectcolumn01211{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowslottemplateimportedobjectcolumn012111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowslottemplateimportedobjectcolumn0121112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}

.object.confirmdialogslottemplatecontentWrapper2213123{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 








padding-top:24px ; 
padding-bottom:24px ; 
padding-left:56px ; 
padding-right:56px ; 

.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumn0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.button.buttonflowcolumn033{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn07{


position:relative !important; 









	.textfield.textfieldcolumn051{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.importedobject.importedobjectcolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.slottemplate.slottemplateimportedobjectcolumn0121{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.row.rowslottemplateimportedobjectcolumn01211{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowslottemplateimportedobjectcolumn012111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowslottemplateimportedobjectcolumn0121112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}

.object.confirmdialogslottemplatecontentWrapper2213123{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumn0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.button.buttonflowcolumn033{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn07{


position:relative !important; 









	.textfield.textfieldcolumn051{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.importedobject.importedobjectcolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.slottemplate.slottemplateimportedobjectcolumn0121{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.row.rowslottemplateimportedobjectcolumn01211{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowslottemplateimportedobjectcolumn012111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowslottemplateimportedobjectcolumn0121112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}

.object.confirmdialogslottemplatecontentWrapper2213123{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textflowcolumn0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.button.buttonflowcolumn033{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn07{


position:relative !important; 









	.textfield.textfieldcolumn051{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.importedobject.importedobjectcolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.slottemplate.slottemplateimportedobjectcolumn0121{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.row.rowslottemplateimportedobjectcolumn01211{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowslottemplateimportedobjectcolumn012111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowslottemplateimportedobjectcolumn0121112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}

.object.confirmdialogslottemplatecontentWrapper2213123{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
