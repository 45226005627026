<template>
 <div>  
<div id="EttaBtn"> 
         
 <a  :href = "link" 
 :class="'link link0 '"   id="link0" ref="link0"  v-bind:style="{}">
 <div  :class="'row rowlink01 '"   id="rowlink01" ref="rowlink01" :_alignItems = "'center'" 
  v-bind:style="{alignItems : 'center',justifyContent : 'space-between',backgroundColor : bgColor,}">
 <div  :class="'text textrowlink011 '"   id="textrowlink011" ref="textrowlink011"  v-bind:style="{color : '#000000e6',}">
<div class="text-content" :style = "{}">{{ text }}</div>

   </div>
 <div  :class="'icon iconrowlink013 '"   id="iconrowlink013" ref="iconrowlink013"  v-bind:style="{color : '#000000e6',fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-arrow-forward'"></i></div>  </div>  </a>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'EttaBtn',

    components: {},
	props: {
	
		link: {					required: false,			default: () =>  "/",		},
		text: {					required: false,			default: () =>  "Find a coworker or classmate",		},
		bgColor: {					required: false,			default: () =>  "#eae6df",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#EttaBtn{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
}



.link.link0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

margin-top: 0px ; 
width:auto!important ; 
height:auto!important ; 

display: block;

.row.rowlink01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

margin-top: 0px ; 
alignItems:center !important; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:5px ; 
justify-content:space-between ; background-color:transparent ; 
padding-top:16px ; 
padding-bottom:16px ; 
padding-left:16px ; 
padding-right:16px ; 

	.text.textrowlink011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	width:auto!important ; 
	
	color:#000000e6 ; 
	font-size:20px ; 
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowlink013{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	color:#000000e6 ; 
	font-size:20px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}


@media (max-width: 600px) {


.link.link0{


position:relative !important; 





display: block;

.row.rowlink01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowlink011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowlink013{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}

@media (min-width: 601px) {


.link.link0{


position:relative !important; 





display: block;

.row.rowlink01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowlink011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowlink013{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}

@media (min-width: 961px) {


.link.link0{


position:relative !important; 





display: block;

.row.rowlink01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowlink011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowlink013{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}

@media (min-width: 1265px) {


.link.link0{


position:relative !important; 





display: block;

.row.rowlink01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowlink011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowlink013{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}

@media (min-width: 1905px) {


.link.link0{


position:relative !important; 





display: block;

.row.rowlink01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowlink011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.icon.iconrowlink013{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}


}
}
}
</style>
