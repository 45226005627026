<template>
 <div>  
<div id="RegisterSessionPage"> 
         
 <MainLayout :class="'object defaultlayout0 '"   id="defaultlayout0" ref="defaultlayout0"  v-bind:style="{}" :currentTab = "currentTab" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{backgroundColor : (mqVal({md:'#f3f2ef'}, $mq)),}">
 <ContentWrapper :class="'object contentWrapper2221 '"   id="contentWrapper2221" ref="contentWrapper2221"  v-bind:style="{}" :paddingTop = "56" 
 :paddingBottom = "56" 
 :paddingLeft = "16" 
 :paddingRight = "16" 
 :bgColor = "'#f0ebe8'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <div  :class="'column columnslottemplatecontentWrapper2211 '"   id="columnslottemplatecontentWrapper2211" ref="columnslottemplatecontentWrapper2211"  v-bind:style="{}">
 <GroupItem :class="'object groupitemcolumnslottemplatecontentWrapper22111 '"   id="groupitemcolumnslottemplatecontentWrapper22111" ref="groupitemcolumnslottemplatecontentWrapper22111" v-if="hasSessions"  v-bind:style="{}" :title = "$t('RegisterSession.chooseSession')" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplategroupitemcolumnslottemplatecontentWrapper221111 '"   id="slottemplategroupitemcolumnslottemplatecontentWrapper221111" ref="slottemplategroupitemcolumnslottemplatecontentWrapper221111"  v-bind:style="{}">
 <div  :class="'column columnslottemplategroupitemcolumnslottemplatecontentWrapper2211111 '"   id="columnslottemplategroupitemcolumnslottemplatecontentWrapper2211111" ref="columnslottemplategroupitemcolumnslottemplatecontentWrapper2211111"  v-bind:style="{}">
 <SessionItem :class="'object sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111 '"   id="sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111" ref="sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111" v-for="(item,index) in sessions" :key="'sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111' + index"  v-bind:style="{}" :choose = "chooseSession" 
 :leave = "leaveSession" 
 :info = "item" 
 :isLast = "index == (sessions.length - 1)" 
>
</SessionItem>  </div></div></template>
</GroupItem>
 <div  :class="'column columnslottemplatecontentWrapper22132 '"   id="columnslottemplatecontentWrapper22132" ref="columnslottemplatecontentWrapper22132"  v-bind:style="{}">
 <div  :class="'column columncolumnslottemplatecontentWrapper221132 ' + ' ' + 'elevation-1'"   id="columncolumnslottemplatecontentWrapper221132" ref="columncolumnslottemplatecontentWrapper221132" v-if="!hasSessions"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'text textcolumncolumnslottemplatecontentWrapper221131 '"   id="textcolumncolumnslottemplatecontentWrapper221131" ref="textcolumncolumnslottemplatecontentWrapper221131"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Webinar.noSessions') }}</div>

   </div>  </div>
 <SocialCallToAction :class="'object socialcalltoactionrightZone321 '"   id="socialcalltoactionrightZone321" ref="socialcalltoactionrightZone321"  v-bind:style="{}" :hasCloseBtn = "false" 
 :index = "2" 
>
</SocialCallToAction>  </div>  </div></div></template>
</ContentWrapper>  </div>
 <ConfirmDialog :class="'object confirmdialogslottemplatecontentWrapper221312 '"   id="confirmdialogslottemplatecontentWrapper221312" ref="confirmdialogslottemplatecontentWrapper221312" v-if="confirmDVisible"  v-bind:style="{}" :close = "hideConfDiag" 
 :action = "registerChoice" 
 :message = "$t('RegisterSession.confirmMessage')" 
>
</ConfirmDialog>
 <ConfirmDialog :class="'object confirmdialogslottemplatecontentWrapper221323 '"   id="confirmdialogslottemplatecontentWrapper221323" ref="confirmdialogslottemplatecontentWrapper221323" v-if="confirmLDVisible"  v-bind:style="{}" :close = "hideLeaveConfDiag" 
 :action = "registerChoice" 
 :message = "$t('RegisterSession.confirmLeaveMessage')" 
>
</ConfirmDialog></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import GroupItem from '../../At/Utils/GroupItem'
import SessionItem from '../../At/Components/RegisterSession/SessionItem'
import SocialCallToAction from '../../At/Utils/SocialCallToAction'
import ConfirmDialog from '../../At/Utils/ConfirmDialog'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
export default {
	name: 'RegisterSessionPage',

    components: {MainLayout,ContentWrapper,GroupItem,SessionItem,SocialCallToAction,ConfirmDialog,},
	methods: {
	
		checkIfConnected: function() { 		
	if(this.$store.state.user == null){

		this.$store.commit('saveToRedirectLink', window.location.href)

		this.$router.push('/login')

	}

}
,
		showConfDiag: function() { 		
	this.confirmDVisible = true

}
,
		hideConfDiag: function() { 		
	this.confirmDVisible = false

}
,
		showLeaveConfDiag: function() { 		
	this.confirmLDVisible = true

}
,
		hideLeaveConfDiag: function() { 		
	this.confirmLDVisible = false

}
,
		chooseSession: function(id, list) { 		
	this.selectedSession = id

	this.selectedSessionParticipants = list

	

	this.showConfDiag()

}
,
		leaveSession: function(id, list) { 		
	this.selectedSession = id

	this.selectedSessionParticipants = list

	

	this.showLeaveConfDiag()

}
,
		registerChoice: function() {		
	let config = {

    participants: this.selectedSessionParticipants

  }

	

	Request.updateSession(this.selectedSession, config)

	.then((res) => {

		//this.info = res.data.data[0]

		this.getInfo()

		this.hideConfDiag()

		this.hideLeaveConfDiag()

	})

}
,
	},
	mounted(){
			
	this.checkIfConnected()

	},
	computed: {
	
		attributes: function() { 		
	if(this.info){

		return this.info.attributes

	}

	return null

}
,
		id: function() { 		
	if(this.info){

		return this.info.id

	}

	return null

}
,
		sessions: function() { 		
	if(this.attributes){

		return this.attributes?.sessions?.data

	}

	return []

}
,
		hasSessions: function() { 		
	return this.sessions.length > 0

}
,
	},
	data(){
	return {
		
		confirmDVisible:  false		,
		confirmLDVisible:  false		,
		selectedSession:  null		,
		selectedSessionParticipants:  []		,
		m_info:  null		,
		}
	},
	props: {
	
		info: {					required: false,			default: () =>  null,		},
		isWebinar: {			type: Boolean,			required: false,			default: () =>  false,		},
		getInfo: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		currentTab: {					required: false,			default: () =>  0,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#RegisterSessionPage{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.object.defaultlayout0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:15px ; background-color: transparent ; 

					.object.groupitemcolumnslottemplatecontentWrapper22111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					width:100%!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

						.slottemplate.slottemplategroupitemcolumnslottemplatecontentWrapper221111{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						left: 0;
						top:0;
						right: 0;
						bottom: 0;
						width: 100% !important;
						height: 100% !important;

							.column.columnslottemplategroupitemcolumnslottemplatecontentWrapper2211111{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;background-color: transparent ; 

								.object.sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

					.column.columnslottemplatecontentWrapper22132{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					width:100%!important ; 
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:20px ; background-color: transparent ; 

						.column.columncolumnslottemplatecontentWrapper221132{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						display: flex;
						flex-wrap: nowrap;
						flex: initial;
						flex-direction: column;
						background-color:#fff ; 
						border-radius:8px ; 
						padding-top:10px ; 
						padding-bottom:10px ; 
						padding-left:10px ; 
						padding-right:10px ; 

							.text.textcolumncolumnslottemplatecontentWrapper221131{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.object.socialcalltoactionrightZone321{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}

	.object.confirmdialogslottemplatecontentWrapper221312{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.confirmdialogslottemplatecontentWrapper221323{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}


@media (max-width: 600px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.groupitemcolumnslottemplatecontentWrapper22111{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

						.slottemplate.slottemplategroupitemcolumnslottemplatecontentWrapper221111{

						
						position:relative !important; 
						
						
						
						
						
						left: 0;
						top:0;
						right: 0;
						bottom: 0;
						width: 100% !important;
						height: 100% !important;

							.column.columnslottemplategroupitemcolumnslottemplatecontentWrapper2211111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

					.column.columnslottemplatecontentWrapper22132{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.column.columncolumnslottemplatecontentWrapper221132{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.text.textcolumncolumnslottemplatecontentWrapper221131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.object.socialcalltoactionrightZone321{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}

	.object.confirmdialogslottemplatecontentWrapper221312{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.confirmdialogslottemplatecontentWrapper221323{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.groupitemcolumnslottemplatecontentWrapper22111{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

						.slottemplate.slottemplategroupitemcolumnslottemplatecontentWrapper221111{

						
						position:relative !important; 
						
						
						
						
						
						left: 0;
						top:0;
						right: 0;
						bottom: 0;
						width: 100% !important;
						height: 100% !important;

							.column.columnslottemplategroupitemcolumnslottemplatecontentWrapper2211111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

					.column.columnslottemplatecontentWrapper22132{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.column.columncolumnslottemplatecontentWrapper221132{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.text.textcolumncolumnslottemplatecontentWrapper221131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.object.socialcalltoactionrightZone321{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}

	.object.confirmdialogslottemplatecontentWrapper221312{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.confirmdialogslottemplatecontentWrapper221323{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	
	background-color:#f3f2ef ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.groupitemcolumnslottemplatecontentWrapper22111{

					
					position:relative !important; 
					
					
					
					
					width:700px!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

						.slottemplate.slottemplategroupitemcolumnslottemplatecontentWrapper221111{

						
						position:relative !important; 
						
						
						
						
						
						left: 0;
						top:0;
						right: 0;
						bottom: 0;
						width: 100% !important;
						height: 100% !important;

							.column.columnslottemplategroupitemcolumnslottemplatecontentWrapper2211111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

					.column.columnslottemplatecontentWrapper22132{

					
					position:relative !important; 
					
					
					
					
					width:700px!important ; 
					
					
					
					

						.column.columncolumnslottemplatecontentWrapper221132{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.text.textcolumncolumnslottemplatecontentWrapper221131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.object.socialcalltoactionrightZone321{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}

	.object.confirmdialogslottemplatecontentWrapper221312{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.confirmdialogslottemplatecontentWrapper221323{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.groupitemcolumnslottemplatecontentWrapper22111{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

						.slottemplate.slottemplategroupitemcolumnslottemplatecontentWrapper221111{

						
						position:relative !important; 
						
						
						
						
						
						left: 0;
						top:0;
						right: 0;
						bottom: 0;
						width: 100% !important;
						height: 100% !important;

							.column.columnslottemplategroupitemcolumnslottemplatecontentWrapper2211111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

					.column.columnslottemplatecontentWrapper22132{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.column.columncolumnslottemplatecontentWrapper221132{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.text.textcolumncolumnslottemplatecontentWrapper221131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.object.socialcalltoactionrightZone321{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}

	.object.confirmdialogslottemplatecontentWrapper221312{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.confirmdialogslottemplatecontentWrapper221323{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.groupitemcolumnslottemplatecontentWrapper22111{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

						.slottemplate.slottemplategroupitemcolumnslottemplatecontentWrapper221111{

						
						position:relative !important; 
						
						
						
						
						
						left: 0;
						top:0;
						right: 0;
						bottom: 0;
						width: 100% !important;
						height: 100% !important;

							.column.columnslottemplategroupitemcolumnslottemplatecontentWrapper2211111{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.sessionitemcolumnslottemplategroupitemcolumnslottemplatecontentWrapper22111111{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

					.column.columnslottemplatecontentWrapper22132{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.column.columncolumnslottemplatecontentWrapper221132{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.text.textcolumncolumnslottemplatecontentWrapper221131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.object.socialcalltoactionrightZone321{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}

	.object.confirmdialogslottemplatecontentWrapper221312{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.confirmdialogslottemplatecontentWrapper221323{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}
}
</style>
