<template>
 <div>  
<div id="NoAccessView"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'column columncolumn03 ' + ' ' + 'elevation-3'"   id="columncolumn03" ref="columncolumn03"  v-bind:style="{justifyContent : 'flex-start',backgroundColor : '#fff',}">
 <RichText :class="'object richtextcolumn011 '"   id="richtextcolumn011" ref="richtextcolumn011"  v-bind:style="{}" :value = "$t('Admin.noAccess')" 
>
</RichText>
 <a  :href = "contact.telegram_link" 
 :class="'link linkcolumncolumn033 '"   id="linkcolumncolumn033" ref="linkcolumncolumn033" :target = "'_blank'" 
  v-bind:style="{}">
 <div  :class="'text textlinkcolumncolumn0331 '"   id="textlinkcolumncolumn0331" ref="textlinkcolumncolumn0331"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ contact.telegram_link }}</div>

   </div>  </a>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import RichText from '../../../At/Utils/RichText'

import * as Request from "../../../Js/requests"
export default {
	name: 'NoAccessView',

    components: {RichText,},
	mounted(){
			
	Request.getTable('contact')

	.then((res) => {

		this.contact = res.data.data.attributes

	})

	},
	data(){
	return {
		
		contact:  ({})		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#NoAccessView{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 
padding-top:0px ; 

.column.columncolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:90%!important ; 
height:auto!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
justify-content:flex-start ; 
background-color:#fff ; 
border-radius:8px ; 
padding-top:20px ; 
padding-bottom:20px ; 
padding-left:20px ; 
padding-right:20px ; 

	.object.richtextcolumn011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.link.linkcolumncolumn033{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	target:_blank !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: block;

		.text.textlinkcolumncolumn0331{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:absolute !important; 









.column.columncolumn03{


position:relative !important; 









	.object.richtextcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.link.linkcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:absolute !important; 









.column.columncolumn03{


position:relative !important; 









	.object.richtextcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.link.linkcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:absolute !important; 








padding-top:200px ; 

.column.columncolumn03{


position:relative !important; 




width:500px!important ; 





	.object.richtextcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.link.linkcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:absolute !important; 









.column.columncolumn03{


position:relative !important; 









	.object.richtextcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.link.linkcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:absolute !important; 









.column.columncolumn03{


position:relative !important; 









	.object.richtextcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.link.linkcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}
}
</style>
