<template>
 <div>  
<div id="Learning"> 
         
 <MainLayout :class="'object defaultlayout0 '"   id="defaultlayout0" ref="defaultlayout0"  v-bind:style="{}" :currentTab = "1" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{}">
 <LearningTopSection :class="'object learningtopsectioncolumnslottemplatedefaultlayout0155 '"   id="learningtopsectioncolumnslottemplatedefaultlayout0155" ref="learningtopsectioncolumnslottemplatedefaultlayout0155"  v-bind:style="{}">
</LearningTopSection>
 <div  :class="'column columnslottemplatedefaultlayout0152 '"   id="columnslottemplatedefaultlayout0152" ref="columnslottemplatedefaultlayout0152"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout0151331011 '"   id="contentwrappercolumnslottemplatedefaultlayout0151331011" ref="contentwrappercolumnslottemplatedefaultlayout0151331011" v-for="(cat, catIndex) in moodleCat" :key="'contentwrappercolumnslottemplatedefaultlayout0151331011' + catIndex" v-if="returnCatVisible(cat.id)"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
 :bgColor = "'#f1ece5'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column columncoltab01311 '"   id="columncoltab01311" ref="columncoltab01311"  v-bind:style="{}">
 <SwiperRow :class="'object swiperrowcolumncoltab01311 '"   id="swiperrowcolumncoltab01311" ref="swiperrowcolumncoltab01311" v-for="(subcat, subcatIndex) in returnSubCats(cat.id)" :key="'swiperrowcolumncoltab01311' + subcatIndex"  v-bind:style="{}" :list = "returnCours(subcat.id)" 
 :title = "subcat.name" 
 :fromMoodle = "true" 
>
</SwiperRow>  </div></div></template>
</ContentWrapper>
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout01513310112 '"   id="contentwrappercolumnslottemplatedefaultlayout01513310112" ref="contentwrappercolumnslottemplatedefaultlayout01513310112" v-for="(cat, catIndex) in categories" :key="'contentwrappercolumnslottemplatedefaultlayout01513310112' + catIndex" v-if="returnCatVisible2(cat)"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
 :bgColor = "getSectionBg(catIndex)" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column columncoltab01311 '"   id="columncoltab01311" ref="columncoltab01311"  v-bind:style="{}">
 <SwiperRow :class="'object swiperrowcolumncoltab01311 '"   id="swiperrowcolumncoltab01311" ref="swiperrowcolumncoltab01311" v-for="(subcat, subcatIndex) in cat.attributes.subcategories.data" :key="'swiperrowcolumncoltab01311' + subcatIndex" v-if="subcat.attributes.formations.data.length > 0"  v-bind:style="{}" :list = "subcat.attributes.formations.data" 
 :title = "subcat.attributes.name" 
 :baseUrl = "'learning'" 
>
</SwiperRow>  </div></div></template>
</ContentWrapper>  </div>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import LearningTopSection from '../../At/Components/FeedPage/LearningTopSection'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import SwiperRow from '../../At/Utils/SwiperRow'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
export default {
	name: 'Learning',

    components: {MainLayout,LearningTopSection,ContentWrapper,SwiperRow,},
	methods: {
	
		getCategories: function() { 		
	let config = {	

		populate: {	

			formations: {

				fields: ['id']

			},

	    subcategories: {

	      populate: {

					formations: {

						populate: "*"

					}

				}

	    }

	  }

	}

	

	Request.getLocalTableArgs('categories', config)

	.then((res) => {

		this.categories = res.data.data

	})

}
,
		getImage: function(item) { 		
	if(item.image){

			return Main.getImgUrl(item.image, 'small');

	}

	return "https://picsum.photos/200"

}
,
		getMoodleCat: function(criteria){ 		
	Request.getMoodleCat(criteria)

	.then((res) => {

		this.tmp_moodleCat = res.data

			.filter(c => c.parent == 1)

		

		for(let i=0; i < this.tmp_moodleCat.length; i++){

			this.numberCoursByCat[this.tmp_moodleCat[i].id] = 0

			

			this.getMoodleSubCat(

				this.tmp_moodleCat[i].id,

				i == this.tmp_moodleCat.length - 1

			)

		}

	})

}
,
		getMoodleSubCat: function(catid, lastCat){ 		
	Request.getMoodleCat()

	.then((res) => {

		this.moodleSubCat[catid] = res.data

			.filter(c => c.parent == catid)

		

		if(this.moodleSubCat[catid].length > 0){	

			for(let i=0; i < this.moodleSubCat[catid].length; i++){

				this.getMoodleCoursByField(

					catid,

					this.moodleSubCat[catid][i].id, 

					lastCat,

					i == this.moodleSubCat[catid].length - 1

				)

			}

		} else {	

			if(lastCat){

				this.moodleCat = this.tmp_moodleCat

			}

		}

		

	})

}
,
		getMoodleCoursByField: function(catid, subcatid, lastCat, lastSubcat){ 		
	Request.getMoodleCoursByField()

	.then((res) => {

		this.moodleCours[subcatid] 

			= res.data.courses

					.filter(c => c.categoryid == subcatid)

					

			this.numberCoursByCat[catid] += this.moodleCours[subcatid].length

		

		if(lastCat && lastSubcat){

			this.filterSubCat()

			this.moodleCat = this.tmp_moodleCat

		}

	})

}
,
		returnSubCats: function(catid) { 		
	if(this.moodleSubCat[catid]){

		return this.moodleSubCat[catid]

	}

	return []

}
,
		returnCours: function(subcatid) {  		
	if(this.moodleCours[subcatid]){

		return this.moodleCours[subcatid]

	}

	return []

}
,
		returnCatVisible: function(id) { 		
	return this.numberCoursByCat[id] > 0

}
,
		returnCatVisible2: function(cat) { 		
	return cat.attributes.formations.data.length > 0

}
,
		filterSubCat: function() { 		
	for(let catid in this.moodleSubCat){	

		this.moodleSubCat[catid] = this.moodleSubCat[catid].filter(subCat => {

			let list = this.returnCours(subCat.id)

				

			if(list){

				return (list.length > 0)

			}

			return false

		})

	}

}
,
		getSectionBg: function(index) { 		
	return ((index % 2) == 0 ? '#fff' : '#f1ece5')

}
,
	},
	mounted(){
			
	this.getCategories()

	

	this.getMoodleCat()

	},
	metaInfo(){
			
	return {

		title: this.companyName + " | Learning",

		meta: [		

	   { name: 'description', content: this.$t('Learning.motivationText')},

	

	   { property: 'og:title', content: this.companyName + " | Learning"},

	   { property: 'og:description', content: this.$t('Learning.motivationText')},

	   { property: 'og:type', content: 'website'},

	   { property: 'og:url', content: this.$store.state.webUrl + '/learning'},

	

	   { name: 'twitter:title', content: this.companyName + " | Learning"},

	   { name: 'twitter:description', content: this.$t('Learning.motivationText')},

	   { name: 'twitter:card', content: 'website'},

	   { name: 'twitter:url', content: this.$store.state.webUrl + '/learning'}

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		selectedLang: function() { 		
	return this.$store.state.selectedLang

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
	},
	watch: {
	
		selectedLang: function() { 		
	this.getCategories()

}
,
	},
	data(){
	return {
		
		categories:  []		,
		moodleCat:  []		,
		moodleSubCat:  ({})		,
		moodleCours:  ({})		,
		numberCoursByCat:  ({})		,
		tmp_moodleCat:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Learning{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.defaultlayout0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:50px ; background-color: transparent ; 

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:50px ; background-color: transparent ; 

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
