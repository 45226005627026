<template>
 <div>  
<div id="Footer"> 
         
 <v-row  :class="'grid footer2 '"   id="footer2" ref="footer2" :padding = "(mqVal({default:'0 20px',lg:'0 100px'}, $mq))" 
  v-bind:style="{backgroundColor : '#fff',}">
 <v-col  :class="'col colfooter21 '"   id="colfooter21" ref="colfooter21" v-for="(item,index) in footerZone" :key="'colfooter21' + index"  v-bind:style="{}" :cols = "12" 
 :lg = "3" 
 :md = "4" 
 :sm = "6" 
><div class="col-container">
 <div  :class="'column columncolfooter211 '"   id="columncolfooter211" ref="columncolfooter211"  v-bind:style="{}">
 <div  :class="'text textcolumncolfooter2111 '"   id="textcolumncolfooter2111" ref="textcolumncolfooter2111"  v-bind:style="{color : '#000',}">
<div class="text-content" :style = "{}">{{ item.title }}</div>

   </div>
 <div  :class="'rectangle rectanglecolumncolfooter2113 '"   id="rectanglecolumncolfooter2113" ref="rectanglecolumncolfooter2113"  v-bind:style="{backgroundColor : '#00000026',}">  </div>
 <div  :class="'flow flowcolumncolfooter2115 '"   id="flowcolumncolfooter2115" ref="flowcolumncolfooter2115"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textflowcolumncolfooter21151 '"   id="textflowcolumncolfooter21151" ref="textflowcolumncolfooter21151" v-for="(item2, index2) in item.sub" :key="'textflowcolumncolfooter21151' + index2" :padding = "'0 0 10px 0'" 
  v-bind:style="{color : '#6b6b6b',}">
<div class="text-content" :style = "{}">{{ item2.title }}</div>

 
 <router-link  :class="'routerlink routerlinktextflowcolumncolfooter211511 '"   id="routerlinktextflowcolumncolfooter211511" ref="routerlinktextflowcolumncolfooter211511" :target = "target(item2.target)" 
  v-bind:style="{}" :to = "item2.link" 
>  </router-link>  </div>  </div>  </div></div></v-col>
 <v-col  :class="'col colfooter23 '"   id="colfooter23" ref="colfooter23"  v-bind:style="{}" :cols = "12" 
 :lg = "3" 
><div class="col-container">
 <div  :class="'column columncolfooter231 '"   id="columncolfooter231" ref="columncolfooter231"  v-bind:style="{justifyContent : 'flex-start',}">
 <div  :class="'flow flowcolumncolfooter2311 '"   id="flowcolumncolfooter2311" ref="flowcolumncolfooter2311"  v-bind:style="{justifyContent : 'flex-end',}">
 <ImageButton :class="'object imagebtn '"   id="imagebtn" ref="imagebtn" v-for="(item,index) in socials" :key="'imagebtn' + index"  v-bind:style="{}" :bgColor = "'#16181d'" 
 :src = "item.icon" 
 :link = "item.link" 
>
</ImageButton>  </div>
 <div  :class="'row rowcolumncolfooter23111 '"   id="rowcolumncolfooter23111" ref="rowcolumncolfooter23111"  v-bind:style="{justifyContent : 'flex-end',}">
 <Logo :class="'object logorowslottemplatecontentwrapper01151 '"   id="logorowslottemplatecontentwrapper01151" ref="logorowslottemplatecontentwrapper01151"  v-bind:style="{}">
</Logo>  </div>
 <div  :class="'text appTitle3 '"   id="appTitle3" ref="appTitle3"  v-bind:style="{color : '#bebebe',}">
<div class="text-content" :style = "{}">{{ $t('Signup.slogan') }}</div>

   </div>
 <div  :class="'flow flowcolumncolfooter21155 '"   id="flowcolumncolfooter21155" ref="flowcolumncolfooter21155"  v-bind:style="{justifyContent : 'flex-end',}">
 <div  :class="'text textflowcolumncolfooter21151 '"   id="textflowcolumncolfooter21151" ref="textflowcolumncolfooter21151" v-for="(item,index) in languages" :key="'textflowcolumncolfooter21151' + index" :padding = "'0 0 10px 0'" 
  v-bind:style="{color : '#aaaaaa',}">
<div class="text-content" :style = "{}">{{ item }}</div>

 
 <router-link  :class="'routerlink routerlinktextflowcolumncolfooter211511 '"   id="routerlinktextflowcolumncolfooter211511" ref="routerlinktextflowcolumncolfooter211511"  v-bind:style="{}" :to = "'/?lang=' + item" 
>  </router-link>  </div>  </div>
 <div  :class="'text appTitle34 '"   id="appTitle34" ref="appTitle34"  v-bind:style="{color : '#bebebe',}">
<div class="text-content" :style = "{}">{{ copywrite }}</div>

   </div>  </div></div></v-col>  </v-row>
 
      </div>
 </div> 
</template>
<script>
import ImageButton from '../../At/Utils/ImageButton'
import Logo from '../../At/Utils/Logo'

import * as Request from "../../Js/requests"
export default {
	name: 'Footer',

    components: {ImageButton,Logo,},
	methods: {
	
		target: function(type) { 		
	if(type){

		return type

	}

	return 'self'

}
,
	},
	mounted(){
			
	Request.getTable('contact')

	.then((res) => {

		this.contact = res.data.data.attributes

	})

	},
	computed: {
	
		companyName: function() { 		
	return this.$store.state.companyName

}
,
		languages: function() { 		
	return this.$store.state.language

}
,
		socials: function(){ 		
	return [

		{icon: "/social/facebook.png", link: this.contact.facebook_link},

		{icon: "/social/twitter2.png", link: this.contact.twitter_link},

		{icon: "/social/instagram2.png", link: this.contact.instagram_link},

		{icon: "/social/telegram.png", link: this.contact.telegram_link},

		{icon: "/social/youtube.png", link: this.contact.youtube_link},

		{icon: "/social/linkedin.png", link: this.contact.linkedin_link},

		{icon: "/social/snapchat.png", link: this.contact.snapchat_link},

		{icon: "/social/tiktok.png", link: this.contact.tiktok_link},

	]

}
,
		copywrite: function() { 		
	return "© " + this.companyName + " " + new Date().getFullYear()

}
,
		footerZone: function(){ 		
	return [

		{

			title: this.$t('Footer.sections'),

			sub: [

				{title: this.$t('Footer.home'), link: "/"},

				{title: this.$t('Footer.learning'), link: "/learning"},

				{title: this.$t('Footer.webinars'), link: "/webinars"},

				{title: this.$t('Footer.profile'), link: "/profile"},

				{title: this.$t('Members.members'), link: "/members"},

				{title: this.$t('Organisations.organisations'), link: "/organisations"},

				{title: this.$t('Footer.blog'), link: "/blog"},

			]

		},

		{

			title: this.$t('Footer.services'),

			sub: [

				{title: this.$t('Footer.help'), link: "/help"},

				{title: this.$t('Sites.sites'), link: "/sites"},

			]

		},

		{

			title: this.$t('Footer.infos'),

			sub: [

				{title: this.$t('Footer.cookies'), link: "/legal/cookies", target: '_blanck'},

				{title: this.$t('Footer.protection'), link: "/legal/copywrite", target: '_blanck'},

				{title: this.$t('Footer.condition'), link: "/legal/termsofuse", target: '_blanck'},

				{title: this.$t('Footer.faq'), link: "/faq", target: '_blanck'},

			]

		},

	]

}
,
	},
	data(){
	return {
		
		categories:  []		,
		contact:  ({})		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Footer{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.columncolfooter231{
	align-items: flex-end;
}



.grid.footer2{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
height:auto!important ; 
padding:0 20px !important; 

background-color:#fff ; 

.col.colfooter21{


position:relative !important; 
margin: unset;
transform-origin: top left;



height:auto!important ; 


::v-deep .col-container{position: relative;

height: auto;

width: 100%;

	.column.columncolfooter211{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.text.textcolumncolfooter2111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#000 ; 
		font-size:20px ; 
		align-items:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.rectangle.rectanglecolumncolfooter2113{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:1px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		background-color:#00000026 ; 

		
}

		.flow.flowcolumncolfooter2115{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;
		justify-content:space-between ; background-color:transparent ; 

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:47.5%!important ; 
			height:auto!important ; 
			padding:0 0 10px 0 !important; 
			
			color:#6b6b6b ; 
			font-size:12px ; 
			.text-content{
			width: 100%;
			white-space: normal;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				

				
}

			
}

		
}

	
}


}


}

.col.colfooter23{


position:relative !important; 
margin: unset;
transform-origin: top left;





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter231{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; 
	justify-content:flex-start ; background-color: transparent ; 

		.flow.flowcolumncolfooter2311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;
		gap:15px ; 
		justify-content:flex-end ; background-color:transparent ; 

			.object.imagebtn{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:30px!important ; 
			height:30px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rowcolumncolfooter23111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		justify-content:flex-end ; background-color:transparent ; 

			.object.logorowslottemplatecontentwrapper01151{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.text.appTitle3{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#bebebe ; 
		font-size:15px ; 
		text-align:right;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowcolumncolfooter21155{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;
		gap:10px ; 
		justify-content:flex-end ; background-color:transparent ; 

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			padding:0 0 10px 0 !important; 
			
			color:#aaaaaa ; 
			font-size:12px ; 
			.text-content{
			width: 100%;
			white-space: normal;text-decoration: underline
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				

				
}

			
}

		
}

		.text.appTitle34{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:25px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#bebebe ; 
		font-size:15px ; 
		text-align:right;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}


}


@media (max-width: 600px) {


.grid.footer2{


position:absolute !important; 






.col.colfooter21{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolfooter2111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumncolfooter2113{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.flow.flowcolumncolfooter2115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

	
}


}


}

.col.colfooter23{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter231{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.flowcolumncolfooter2311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.imagebtn{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rowcolumncolfooter23111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.text.appTitle3{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowcolumncolfooter21155{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

		.text.appTitle34{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}


}
}

@media (min-width: 601px) {


.grid.footer2{


position:absolute !important; 






.col.colfooter21{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolfooter2111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumncolfooter2113{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.flow.flowcolumncolfooter2115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

	
}


}


}

.col.colfooter23{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter231{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.flowcolumncolfooter2311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.imagebtn{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rowcolumncolfooter23111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.text.appTitle3{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowcolumncolfooter21155{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

		.text.appTitle34{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}


}
}

@media (min-width: 961px) {


.grid.footer2{


position:absolute !important; 






.col.colfooter21{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolfooter2111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumncolfooter2113{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.flow.flowcolumncolfooter2115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

	
}


}


}

.col.colfooter23{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter231{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.flowcolumncolfooter2311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.imagebtn{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rowcolumncolfooter23111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.text.appTitle3{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowcolumncolfooter21155{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

		.text.appTitle34{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}


}
}

@media (min-width: 1265px) {


.grid.footer2{


position:absolute !important; 




padding:0 100px !important; 


.col.colfooter21{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolfooter2111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumncolfooter2113{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.flow.flowcolumncolfooter2115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

	
}


}


}

.col.colfooter23{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter231{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.flowcolumncolfooter2311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.imagebtn{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rowcolumncolfooter23111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.text.appTitle3{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowcolumncolfooter21155{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

		.text.appTitle34{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}


}
}

@media (min-width: 1905px) {


.grid.footer2{


position:absolute !important; 






.col.colfooter21{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolfooter2111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumncolfooter2113{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.flow.flowcolumncolfooter2115{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

	
}


}


}

.col.colfooter23{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolfooter231{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.flowcolumncolfooter2311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.imagebtn{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.row.rowcolumncolfooter23111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowslottemplatecontentwrapper01151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		.text.appTitle3{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowcolumncolfooter21155{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textflowcolumncolfooter21151{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.routerlink.routerlinktextflowcolumncolfooter211511{

				
				position:absolute !important; 
				
				
				
				
				

				
}

			
}

		
}

		.text.appTitle34{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}


}
}
}
</style>
