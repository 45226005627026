<template>
 <div>  
<div id="Login"> 
         
 <MainLayout :class="'object defaultlayout0 '"   id="defaultlayout0" ref="defaultlayout0"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{backgroundColor : (mqVal({md:'#f3f2ef'}, $mq)),}">
 <ContentWrapper :class="'object contentWrapper2221 '"   id="contentWrapper2221" ref="contentWrapper2221"  v-bind:style="{}" :paddingTop = "56" 
 :paddingBottom = "56" 
 :paddingLeft = "16" 
 :paddingRight = "16" 
 :bgColor = "'#f0ebe8'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <div  :class="'column columnslottemplatecontentWrapper2211 '"   id="columnslottemplatecontentWrapper2211" ref="columnslottemplatecontentWrapper2211"  v-bind:style="{}">
 <div  :class="'column columncolumnslottemplatecontentWrapper22113 '"   id="columncolumnslottemplatecontentWrapper22113" ref="columncolumnslottemplatecontentWrapper22113"  v-bind:style="{backgroundColor : '#fff',}">
 <v-text-field  :label = "$t('Signup.email')" 
 :dense = "true" 
 :type = "'email'" 
 outlined :class="'textfield textfieldcolumncolumnslottemplatecontentWrapper2211312 '"   id="textfieldcolumncolumnslottemplatecontentWrapper2211312" ref="textfieldcolumncolumnslottemplatecontentWrapper2211312" v-model="email"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-text-field  :label = "$t('Signup.pass')" 
 :append-icon = "passIcon" 
 :dense = "true" 
 :type = "(showPass? 'text' : 'password')" 
 outlined :class="'textfield textfieldcolumncolumnslottemplatecontentWrapper22113123 '"   id="textfieldcolumncolumnslottemplatecontentWrapper22113123" ref="textfieldcolumncolumnslottemplatecontentWrapper22113123" v-model="pass"  v-bind:style="{color : primaryColor,}" @click:append="toggelPass"></v-text-field>
 <div  :class="'text textcolumncolgridslottemplatecontentWrapper11111546 '"   id="textcolumncolgridslottemplatecontentWrapper11111546" ref="textcolumncolgridslottemplatecontentWrapper11111546"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('LogIn.instruction') }}</div>

   </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :rounded = "true" 
 :dark = "true" 
 @click="login" :class="'button buttoncolumncolgridslottemplatecontentWrapper1111173 '"   id="buttoncolumncolgridslottemplatecontentWrapper1111173" ref="buttoncolumncolgridslottemplatecontentWrapper1111173"  v-bind:style="{}"><span>{{ $t('LogIn.login') }}</span>
</v-btn>
 <div  :class="'text errorMessage65 '"   id="errorMessage65" ref="errorMessage65" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>
 <router-link  :class="'routerlink routerlinkcolumncolumnslottemplatecontentWrapper2211313 '"   id="routerlinkcolumncolumnslottemplatecontentWrapper2211313" ref="routerlinkcolumncolumnslottemplatecontentWrapper2211313"  v-bind:style="{}" :to = "'/forgot-password'" 
>
 <div  :class="'text textrouterlinkcolumncolumnslottemplatecontentWrapper22113131 '"   id="textrouterlinkcolumncolumnslottemplatecontentWrapper22113131" ref="textrouterlinkcolumncolumnslottemplatecontentWrapper22113131"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('LogIn.forgotPass') }}</div>

   </div>  </router-link>
 <div  :class="'column columncolumncolumnslottemplatecontentWrapper221139 '"   id="columncolumncolumnslottemplatecontentWrapper221139" ref="columncolumncolumnslottemplatecontentWrapper221139"  v-bind:style="{}">
 <div  :class="'column columncolumncolumncolumnslottemplatecontentWrapper2211391 '"   id="columncolumncolumncolumnslottemplatecontentWrapper2211391" ref="columncolumncolumncolumnslottemplatecontentWrapper2211391"  v-bind:style="{}">
 <div  :class="'rectangle divider '"   id="divider" ref="divider"  v-bind:style="{backgroundColor : '#4e4e4e',}">
 <div  :class="'row rowdivider1 '"   id="rowdivider1" ref="rowdivider1"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'text textrowdivider11 '"   id="textrowdivider11" ref="textrowdivider11"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'or' }}</div>

   </div>  </div>  </div>  </div>
 <div  :class="'item googleBtn '"   id="googleBtn" ref="googleBtn"  v-bind:style="{}">
 <div  :class="'row rowcolumncolumncolumnslottemplatecontentWrapper2211393 '"   id="rowcolumncolumncolumnslottemplatecontentWrapper2211393" ref="rowcolumncolumncolumnslottemplatecontentWrapper2211393"  v-bind:style="{justifyContent : 'center',}">
 <v-img  :class="'image imagerowcolumncolumncolumnslottemplatecontentWrapper22113931 '"   id="imagerowcolumncolumncolumnslottemplatecontentWrapper22113931" ref="imagerowcolumncolumncolumnslottemplatecontentWrapper22113931"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/Logo/googleLogo.png')" 
>  </v-img>
 <div  :class="'text textrowcolumncolumncolumnslottemplatecontentWrapper22113933 '"   id="textrowcolumncolumncolumnslottemplatecontentWrapper22113933" ref="textrowcolumncolumncolumnslottemplatecontentWrapper22113933"  v-bind:style="{color : '#404040',}">
<div class="text-content" :style = "{}">{{ $t('LogIn.continueGoogle') }}</div>

   </div>  </div>
 <div  :class="'mousearea mouseareagoogleBtn3 '"   id="mouseareagoogleBtn3" ref="mouseareagoogleBtn3"  v-bind:style="{}" @click="loginGoogle"></div>  </div>
 <div  :class="'item facebookBtn '"   id="facebookBtn" ref="facebookBtn"  v-bind:style="{}">
 <div  :class="'row rowcolumncolumncolumnslottemplatecontentWrapper2211393 '"   id="rowcolumncolumncolumnslottemplatecontentWrapper2211393" ref="rowcolumncolumncolumnslottemplatecontentWrapper2211393"  v-bind:style="{justifyContent : 'center',}">
 <v-img  :class="'image imagerowcolumncolumncolumnslottemplatecontentWrapper22113931 '"   id="imagerowcolumncolumncolumnslottemplatecontentWrapper22113931" ref="imagerowcolumncolumncolumnslottemplatecontentWrapper22113931"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/Logo/facebookLogo.png')" 
>  </v-img>
 <div  :class="'text textrowcolumncolumncolumnslottemplatecontentWrapper22113933 '"   id="textrowcolumncolumncolumnslottemplatecontentWrapper22113933" ref="textrowcolumncolumncolumnslottemplatecontentWrapper22113933"  v-bind:style="{color : '#404040',}">
<div class="text-content" :style = "{}">{{ $t('LogIn.continueFacebook') }}</div>

   </div>  </div>
 <div  :class="'mousearea mouseareagoogleBtn32 '"   id="mouseareagoogleBtn32" ref="mouseareagoogleBtn32"  v-bind:style="{}" @click="loginFacebook"></div>  </div>
 <div  :class="'item linkedinBtn '"   id="linkedinBtn" ref="linkedinBtn"  v-bind:style="{}">
 <div  :class="'row rowcolumncolumncolumnslottemplatecontentWrapper2211393 '"   id="rowcolumncolumncolumnslottemplatecontentWrapper2211393" ref="rowcolumncolumncolumnslottemplatecontentWrapper2211393"  v-bind:style="{justifyContent : 'center',}">
 <v-img  :class="'image imagerowcolumncolumncolumnslottemplatecontentWrapper22113931 '"   id="imagerowcolumncolumncolumnslottemplatecontentWrapper22113931" ref="imagerowcolumncolumncolumnslottemplatecontentWrapper22113931"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/social/linkedin2.png')" 
>  </v-img>
 <div  :class="'text textrowcolumncolumncolumnslottemplatecontentWrapper22113933 '"   id="textrowcolumncolumncolumnslottemplatecontentWrapper22113933" ref="textrowcolumncolumncolumnslottemplatecontentWrapper22113933"  v-bind:style="{color : '#404040',}">
<div class="text-content" :style = "{}">{{ $t('LogIn.continueLinkedin') }}</div>

   </div>  </div>
 <div  :class="'mousearea mouseareagoogleBtn32 '"   id="mouseareagoogleBtn32" ref="mouseareagoogleBtn32"  v-bind:style="{}" @click="loginLinkedin"></div>  </div>  </div>  </div>
 <div  :class="'flow flowcolumncolumncolumnslottemplatecontentWrapper22113973 '"   id="flowcolumncolumncolumnslottemplatecontentWrapper22113973" ref="flowcolumncolumncolumnslottemplatecontentWrapper22113973"  v-bind:style="{justifyContent : 'center',}">
 <div  :class="'text textflowcolumncolumncolumnslottemplatecontentWrapper22113971 '"   id="textflowcolumncolumncolumnslottemplatecontentWrapper22113971" ref="textflowcolumncolumncolumnslottemplatecontentWrapper22113971"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('LogIn.newToAfro') }}</div>

   </div>
 <router-link  :class="'routerlink linkflowcolumncolumncolumnslottemplatecontentWrapper22113973 '"   id="linkflowcolumncolumncolumnslottemplatecontentWrapper22113973" ref="linkflowcolumncolumncolumnslottemplatecontentWrapper22113973"  v-bind:style="{}" :to = "'signup'" 
>
 <div  :class="'text textflowcolumncolumncolumnslottemplatecontentWrapper221139711 '"   id="textflowcolumncolumncolumnslottemplatecontentWrapper221139711" ref="textflowcolumncolumncolumnslottemplatecontentWrapper221139711"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('LogIn.joinNow') }}</div>

   </div>  </router-link>  </div>  </div></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
export default {
	name: 'Login',

    components: {MainLayout,ContentWrapper,},
	methods: {
	
		toggelPass: function() { 		
	this.showPass = !this.showPass

}
,
		loginGoogle: function() {		
	Request.signinGoogle()

}
,
		loginFacebook: function() {		
	Request.signinFacebook()

}
,
		loginLinkedin: function() {		
	Request.signinLinkedin()

}
,
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true	

	this.$awn.alert(message)

}
,
		login: function() { 		
	// check email

	if(!Main.validateEmail(this.email)){

		this.showError(this.$t('Landing.provideGoodMail'))	

		return

	}

	// check pass

	if(this.pass == null){

		this.showError(this.$t('Signup.enterPass'))		

		return

	}	

	

	Request.login(this.email, this.pass)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {		

			let {user, jwt} = res.data

			

			this.$store.commit('changeToken', jwt)

				

			let token = this.$route.params.token

			let toRLink = this.$store.state.toRedirectLink

			

			if(token == "successful"){

				Main.autoErollment(

					user.id,

					_ => {

						this.$store.commit('changeUser', user)

						

						if(toRLink){

							this.$store.commit('saveToRedirectLink', null)

							window.location.href = toRLink

						} else {

							this.$router.push('/')

						}

					}

				)

			} else {

				this.$store.commit('changeUser', user)

				

				if(toRLink){

					this.$store.commit('saveToRedirectLink', null)

					window.location.href = toRLink

				} else {

					this.$router.push('/')

				}

			}

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

		)

	})

}
,
	},
	mounted(){
			
	if(this.$store.state.user){

		this.$router.push('/')

	}

	},
	metaInfo(){
			
	return {

		title: this.companyName + " | Login",

		meta: [		

	   { name: 'description', content: this.$t('LogIn.description')},

	

	   { property: 'og:title', content: this.companyName + " | Login"},

	   { property: 'og:description', content: this.$t('LogIn.description')},

	   { property: 'og:type', content: 'website'},

	   { property: 'og:url', content: this.$store.state.webUrl + '/login'},

	

	   { name: 'twitter:title', content: this.companyName + " | Login"},

	   { name: 'twitter:description', content: this.$t('LogIn.description')},

	   { name: 'twitter:card', content: 'website'},

	   { name: 'twitter:url', content: this.$store.state.webUrl + '/login'}

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		passIcon: function(){		
	if(this.showPass){

		return 'ion-ios-eye'

	} else {

		return 'ion-ios-eye-off'

	}

}
,
		secondColor: function() { 		
	return this.$store.state.secondColor

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
	},
	data(){
	return {
		
		email:  null		,
		pass:  null		,
		errorVisible:  false		,
		error:  "..."		,
		showPass:  false		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Login{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#teaserImg{
	position: absolute !important;
	height: 100%;
}

#overlay{
	position: absolute !important;
	height: 100%;		
}



.object.defaultlayout0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:15px ; background-color: transparent ; 

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					width:100%!important ; 
					height:auto!important ; 
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:10px ; 
					background-color:#fff ; 
					border-radius:10px ; 
					padding-top:24px ; 
					padding-bottom:24px ; 
					padding-left:24px ; 
					padding-right:24px ; 

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						width: calc(100% - (0px + 0px)) !important;
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						width: calc(100% - (0px + 0px)) !important;
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						font-size:13px ; 
						text-align:center;
						display: flex;
						.text-content{
						width: 100%;
						}

						
}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:48px!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						text-transform:unset ; 
						min-width: auto;
						padding-left: 16px;
						padding-right: 16px;

						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						color:#ff1a05 ; 
						line-height:10px ; 
						font-size:10px ; 
						text-align:center;
						display: flex;
						.text-content{
						width: 100%;
						font-weight: bold;text-decoration: underline
						}

						
}

						.routerlink.routerlinkcolumncolumnslottemplatecontentWrapper2211313{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						

							.text.textrouterlinkcolumncolumnslottemplatecontentWrapper22113131{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							width: calc(100% - (0px + 0px)) !important;
							
							text-align:center;
							display: flex;
							.text-content{
							width: 100%;
							}

							
}

						
}

						.column.columncolumncolumnslottemplatecontentWrapper221139{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						display: flex;
						flex-wrap: nowrap;
						flex: initial;
						flex-direction: column;
						gap:25px ; background-color: transparent ; 

							.column.columncolumncolumncolumnslottemplatecontentWrapper2211391{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;background-color: transparent ; 
							padding-top:10px ; 
							padding-bottom:10px ; 

								.rectangle.divider{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:1px!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								background-color:#4e4e4e ; 

									.row.rowdivider1{

									
									position:absolute !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
									
									left: calc(50% + 0px) ; 
									
									top: calc(50% + 0px) ; 
									width:auto!important ; 
									height:auto!important ; 
									
									display: flex;
									flex-wrap: nowrap;
									flex: initial;
									flex-direction: row;
									background-color:#fff ; 
									padding-top:5px ; 
									padding-bottom:5px ; 
									padding-left:5px ; 
									padding-right:5px ; 

										.text.textrowdivider11{

										align-self: flex-start;
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										
										width:auto!important ; 
										height:auto!important ; 
										
										font-size:16px ; 
										.text-content{
										width: 100%;
										}

										
}

									
}

								
}

							
}

							.item.googleBtn{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:44px!important ; 
							width: calc(100% - (0px + 0px)) !important;
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								left: 0px ; 
								
								top: 0px ; 
								right: 0px ; 
								bottom: 0px ; 
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: row;
								gap:5px ; 
								justify-content:center ; background-color:transparent ; 
								border-radius:22px ; 
								border-style:solid ; 
								border-width:1px ; 
								border-color:#888888 ; 

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
									
									
									top: calc(50% + 0px) ; 
									width:20px!important ; 
									height:20px!important ; 
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									
									margin-top: 0px ; 
									margin-bottom: 0px ; 
									width:auto!important ; 
									height: calc(100% - (0px + 0px)) !important;
									
									color:#404040 ; 
									align-items:center;
									display: flex;
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn3{

								
								position:absolute !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								left: 0px ; 
								
								top: 0px ; 
								right: 0px ; 
								bottom: 0px ; 
								
								cursor: pointer;
								}

							
}

							.item.facebookBtn{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:44px!important ; 
							width: calc(100% - (0px + 0px)) !important;
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								left: 0px ; 
								
								top: 0px ; 
								right: 0px ; 
								bottom: 0px ; 
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: row;
								gap:5px ; 
								justify-content:center ; background-color:transparent ; 
								border-radius:22px ; 
								border-style:solid ; 
								border-width:1px ; 
								border-color:#888888 ; 

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
									
									
									top: calc(50% + 0px) ; 
									width:20px!important ; 
									height:20px!important ; 
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									
									margin-top: 0px ; 
									margin-bottom: 0px ; 
									width:auto!important ; 
									height: calc(100% - (0px + 0px)) !important;
									
									color:#404040 ; 
									align-items:center;
									display: flex;
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								left: 0px ; 
								
								top: 0px ; 
								right: 0px ; 
								bottom: 0px ; 
								
								cursor: pointer;
								}

							
}

							.item.linkedinBtn{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:44px!important ; 
							width: calc(100% - (0px + 0px)) !important;
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								left: 0px ; 
								
								top: 0px ; 
								right: 0px ; 
								bottom: 0px ; 
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: row;
								gap:5px ; 
								justify-content:center ; background-color:transparent ; 
								border-radius:22px ; 
								border-style:solid ; 
								border-width:1px ; 
								border-color:#888888 ; 

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
									
									
									top: calc(50% + 0px) ; 
									width:20px!important ; 
									height:20px!important ; 
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									
									margin-top: 0px ; 
									margin-bottom: 0px ; 
									width:auto!important ; 
									height: calc(100% - (0px + 0px)) !important;
									
									color:#404040 ; 
									align-items:center;
									display: flex;
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								left: 0px ; 
								
								top: 0px ; 
								right: 0px ; 
								bottom: 0px ; 
								
								cursor: pointer;
								}

							
}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: wrap;
					flex: initial;
					gap:8px ; 
					justify-content:center ; background-color:transparent ; 

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						
						width:auto!important ; 
						height:auto!important ; 
						
						font-size:14px ; 
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						
						width:auto!important ; 
						height:auto!important ; 
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							
							width:auto!important ; 
							height:auto!important ; 
							
							font-size:14px ; 
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.routerlinkcolumncolumnslottemplatecontentWrapper2211313{

						
						position:relative !important; 
						
						
						
						
						

							.text.textrouterlinkcolumncolumnslottemplatecontentWrapper22113131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.column.columncolumncolumnslottemplatecontentWrapper221139{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.column.columncolumncolumncolumnslottemplatecontentWrapper2211391{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.rectangle.divider{

								
								position:relative !important; 
								
								
								
								
								
								

									.row.rowdivider1{

									
									position:absolute !important; 
									
									
									
									
									
									display: flex;
									flex-wrap: nowrap;
									flex: initial;

										.text.textrowdivider11{

										
										position:relative !important; 
										
										
										
										
										
										.text-content{
										width: 100%;
										}

										
}

									
}

								
}

							
}

							.item.googleBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn3{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.facebookBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.linkedinBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.routerlinkcolumncolumnslottemplatecontentWrapper2211313{

						
						position:relative !important; 
						
						
						
						
						

							.text.textrouterlinkcolumncolumnslottemplatecontentWrapper22113131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.column.columncolumncolumnslottemplatecontentWrapper221139{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.column.columncolumncolumncolumnslottemplatecontentWrapper2211391{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.rectangle.divider{

								
								position:relative !important; 
								
								
								
								
								
								

									.row.rowdivider1{

									
									position:absolute !important; 
									
									
									
									
									
									display: flex;
									flex-wrap: nowrap;
									flex: initial;

										.text.textrowdivider11{

										
										position:relative !important; 
										
										
										
										
										
										.text-content{
										width: 100%;
										}

										
}

									
}

								
}

							
}

							.item.googleBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn3{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.facebookBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.linkedinBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	
	background-color:#f3f2ef ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					width:400px!important ; 
					
					
					
					

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						line-height:15px ; 
						font-size:15px ; 
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.routerlinkcolumncolumnslottemplatecontentWrapper2211313{

						
						position:relative !important; 
						
						
						
						
						

							.text.textrouterlinkcolumncolumnslottemplatecontentWrapper22113131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.column.columncolumncolumnslottemplatecontentWrapper221139{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.column.columncolumncolumncolumnslottemplatecontentWrapper2211391{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.rectangle.divider{

								
								position:relative !important; 
								
								
								
								
								
								

									.row.rowdivider1{

									
									position:absolute !important; 
									
									
									
									
									
									display: flex;
									flex-wrap: nowrap;
									flex: initial;

										.text.textrowdivider11{

										
										position:relative !important; 
										
										
										
										
										
										.text-content{
										width: 100%;
										}

										
}

									
}

								
}

							
}

							.item.googleBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn3{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.facebookBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.linkedinBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.routerlinkcolumncolumnslottemplatecontentWrapper2211313{

						
						position:relative !important; 
						
						
						
						
						

							.text.textrouterlinkcolumncolumnslottemplatecontentWrapper22113131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.column.columncolumncolumnslottemplatecontentWrapper221139{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.column.columncolumncolumncolumnslottemplatecontentWrapper2211391{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.rectangle.divider{

								
								position:relative !important; 
								
								
								
								
								
								

									.row.rowdivider1{

									
									position:absolute !important; 
									
									
									
									
									
									display: flex;
									flex-wrap: nowrap;
									flex: initial;

										.text.textrowdivider11{

										
										position:relative !important; 
										
										
										
										
										
										.text-content{
										width: 100%;
										}

										
}

									
}

								
}

							
}

							.item.googleBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn3{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.facebookBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.linkedinBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.routerlinkcolumncolumnslottemplatecontentWrapper2211313{

						
						position:relative !important; 
						
						
						
						
						

							.text.textrouterlinkcolumncolumnslottemplatecontentWrapper22113131{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

						.column.columncolumncolumnslottemplatecontentWrapper221139{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.column.columncolumncolumncolumnslottemplatecontentWrapper2211391{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.rectangle.divider{

								
								position:relative !important; 
								
								
								
								
								
								

									.row.rowdivider1{

									
									position:absolute !important; 
									
									
									
									
									
									display: flex;
									flex-wrap: nowrap;
									flex: initial;

										.text.textrowdivider11{

										
										position:relative !important; 
										
										
										
										
										
										.text-content{
										width: 100%;
										}

										
}

									
}

								
}

							
}

							.item.googleBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn3{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.facebookBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

							.item.linkedinBtn{

							
							position:relative !important; 
							
							
							
							
							

								.row.rowcolumncolumncolumnslottemplatecontentWrapper2211393{

								
								position:absolute !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.image.imagerowcolumncolumncolumnslottemplatecontentWrapper22113931{

									
									position:relative !important; 
									
									
									
									
									
									display: inherit;

									flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

									
}

									.text.textrowcolumncolumncolumnslottemplatecontentWrapper22113933{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.mousearea.mouseareagoogleBtn32{

								
								position:absolute !important; 
								
								
								
								
								
								}

							
}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
