<template>
 <div>  
<div id="PySection"> 
         
 <ContentWrapper :class="'object contentWrapper222 '"   id="contentWrapper222" ref="contentWrapper222" :_backgroundColor = "bgColor" 
  v-bind:style="{backgroundColor : bgColor,}" :paddingTop = "56" 
 :paddingBottom = "56" 
 :paddingLeft = "16" 
 :paddingRight = "16" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <v-row  :class="'grid gridslottemplatecontentWrapper1111 '"   id="gridslottemplatecontentWrapper1111" ref="gridslottemplatecontentWrapper1111"  v-bind:style="{}">
 <v-col  :class="'col colgridslottemplatecontentWrapper1111 '"   id="colgridslottemplatecontentWrapper1111" ref="colgridslottemplatecontentWrapper1111"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <div  :class="'column columncolgridslottemplatecontentWrapper11111 '"   id="columncolgridslottemplatecontentWrapper11111" ref="columncolgridslottemplatecontentWrapper11111"  v-bind:style="{}">
 <div  :class="'text textcolumncolgridslottemplatecontentWrapper111111 '"   id="textcolumncolgridslottemplatecontentWrapper111111" ref="textcolumncolgridslottemplatecontentWrapper111111" :_fontWeight = "'200px'" 
  v-bind:style="{fontWeight : '200px',color : titleColor,}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>  </div></div></v-col>
 <v-col  :class="'col colgridslottemplatecontentWrapper11112 '"   id="colgridslottemplatecontentWrapper11112" ref="colgridslottemplatecontentWrapper11112"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <div  :class="'column columncolgridslottemplatecontentWrapper111121 '"   id="columncolgridslottemplatecontentWrapper111121" ref="columncolgridslottemplatecontentWrapper111121"  v-bind:style="{}">
 <div  :class="'text textcolumncolgridslottemplatecontentWrapper1111211 '"   id="textcolumncolgridslottemplatecontentWrapper1111211" ref="textcolumncolgridslottemplatecontentWrapper1111211"  v-bind:style="{color : '#00000099',}">
<div class="text-content" :style = "{}">{{ $t('Home.contentTopic') }}</div>

   </div>
 <div  :class="'flow flowcolumncolgridslottemplatecontentWrapper1111213 '"   id="flowcolumncolgridslottemplatecontentWrapper1111213" ref="flowcolumncolgridslottemplatecontentWrapper1111213"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "(index==0? primaryColor : '#000000bf')" 
 :outlined = "true" 
 :rounded = "true" 
 :disabled = "true" 
 :class="'button buttonflowcolumncolgridslottemplatecontentWrapper11112131 ' + ' ' + 'topicBtn'"   id="buttonflowcolumncolgridslottemplatecontentWrapper11112131" ref="buttonflowcolumncolgridslottemplatecontentWrapper11112131" v-for="(item,index) in m_list" :key="'buttonflowcolumncolgridslottemplatecontentWrapper11112131' + index" :_fontSize = "'20px'" 
 :_fontWeight = "'600'" 
 :to = "baseUrl + '/' + item.slug" 
  v-bind:style="{fontSize : '20px',fontWeight : '600',}"><span>{{ item.name }}</span>
</v-btn>  </div>
 <v-btn tag="div" 
 :text = "true" 
 @click="togleExtend" :class="'button buttoncolumncolgridslottemplatecontentWrapper1111215 '"   id="buttoncolumncolgridslottemplatecontentWrapper1111215" ref="buttoncolumncolgridslottemplatecontentWrapper1111215" v-if="longList"  v-bind:style="{}"><span>{{ $t('Home.showMore') }}</span>
<v-icon  :style = "{}"  v-if = "extended ? 'ion-ios-arrow-up' : 'ion-ios-arrow-down' !== undefined" >{{ extended ? 'ion-ios-arrow-up' : 'ion-ios-arrow-down' }}
</v-icon></v-btn>  </div></div></v-col>  </v-row></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../At/Utils/ContentWrapper'


export default {
	name: 'PySection',

    components: {ContentWrapper,},
	methods: {
	
		togleExtend: function() { 		
	this.extended = !this.extended

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		longList: function() { 		
	return this.list.length > this.maxItems

}
,
		m_list: function() { 			
	if(this.list){

		if(this.longList){	

			if(this.extended){

				return this.list

			}

			return this.list.slice(0, this.maxItems)

		} else {

			return this.list

		}

	}

	return []

}
,
	},
	data(){
	return {
		
		maxItems:  5		,
		extended:  false		,
		}
	},
	props: {
	
		bgColor: {					required: false,			default: () =>  "#f3f2f0",		},
		titleColor: {					required: false,			default: () =>  "#8f5849",		},
		title: {					required: false,			default: () =>  "...",		},
		baseUrl: {					required: false,			default: () =>  "",		},
		list: {					required: false,			default: () =>  ({}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#PySection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.topicBtn{
	::v-deep .v-btn__content{
		max-width: 100%;	
		
		span{
			max-width: 100%;
			white-space: nowrap;
		  text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}



.object.contentWrapper222{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper1111{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	background-color: transparent ; 

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:10px ; background-color: transparent ; 

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				fontWeight:200px !important; 
				width: calc(100% - (0px + 0px)) !important;
				
				font-size:25px ; 
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:10px ; background-color: transparent ; 

				.text.textcolumncolgridslottemplatecontentWrapper1111211{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				
				color:#00000099 ; 
				font-size:16px ; 
				text-transform:uppercase; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

				.flow.flowcolumncolgridslottemplatecontentWrapper1111213{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: wrap;
				flex: initial;
				gap:8px ; background-color:transparent ; 

					.button.buttonflowcolumncolgridslottemplatecontentWrapper11112131{

					height: auto;
					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					height:56px!important ; 
					max-width: 100%!important ; 
					fontSize:20px !important; 
					fontWeight:600 !important; 
					
					text-transform:capitalize ; 
					min-width: auto;
					padding-left: 16px;
					padding-right: 16px;

					i.v-icon{
					}
					}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper1111215{

				height: auto;
				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:48px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper1111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper1111211{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.flowcolumncolgridslottemplatecontentWrapper1111213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumncolgridslottemplatecontentWrapper11112131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper1111215{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper1111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper1111211{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.flowcolumncolgridslottemplatecontentWrapper1111213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumncolgridslottemplatecontentWrapper11112131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper1111215{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper1111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				font-size:50px ; 
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper1111211{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.flowcolumncolgridslottemplatecontentWrapper1111213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumncolgridslottemplatecontentWrapper11112131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper1111215{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper1111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper1111211{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.flowcolumncolgridslottemplatecontentWrapper1111213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumncolgridslottemplatecontentWrapper11112131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper1111215{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper1111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper1111211{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.flowcolumncolgridslottemplatecontentWrapper1111213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumncolgridslottemplatecontentWrapper11112131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper1111215{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}
}
</style>
