<template>
 <div>  
<div id="DetailsPage"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <MainLayout :class="'object defaultlayout0 '"   id="defaultlayout0" ref="defaultlayout0"  v-bind:style="{}" :currentTab = "currentTab" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{}">
 <LDTopSection :class="'object learningtopsectioncolumnslottemplatedefaultlayout0155 '"   id="learningtopsectioncolumnslottemplatedefaultlayout0155" ref="learningtopsectioncolumnslottemplatedefaultlayout0155"  v-bind:style="{}" :showVideoModal = "showVideoModal" 
 :image = "m_image" 
 :name = "m_name" 
 :duration = "duration" 
 :isWebinar = "isWebinar" 
 :url = "m_mainLink" 
 :videoLink = "teaser" 
>
</LDTopSection>
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout01513 '"   id="contentwrappercolumnslottemplatedefaultlayout01513" ref="contentwrappercolumnslottemplatedefaultlayout01513"  v-bind:style="{}" :paddingTop = "24" 
 :paddingBottom = "24" 
 :bgColor = "'#fff'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column columncoltab01311 '"   id="columncoltab01311" ref="columncoltab01311"  v-bind:style="{}">
 <v-row  :class="'grid gridcolumncoltab013111 '"   id="gridcolumncoltab013111" ref="gridcolumncoltab013111"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncoltab0131111 '"   id="colgridcolumncoltab0131111" ref="colgridcolumncoltab0131111"  v-bind:style="{}" :cols = "12" 
 :sm = "8" 
><div class="col-container">
 <div  :class="'column columncolgridcolumncoltab01311111 '"   id="columncolgridcolumncoltab01311111" ref="columncolgridcolumncoltab01311111"  v-bind:style="{}">
 <LDMainSection :class="'object ldmainsectioncolumncolgridcolumncoltab013111111 '"   id="ldmainsectioncolumncolgridcolumncoltab013111111" ref="ldmainsectioncolumncolgridcolumncoltab013111111"  v-bind:style="{}" :description = "m_description" 
 :content = "content" 
 :tags = "tags" 
 :instructorID = "instructorID" 
 :fromMoodle = "fromMoodle" 
 :moodleInstructor = "moodleInstructor" 
 :isWebinar = "isWebinar" 
 :isFree = "isFree" 
 :hasWorkshop = "hasWorkshop" 
>
</LDMainSection>  </div></div></v-col>
 <v-col  :class="'col colgridcolumncoltab0131113 '"   id="colgridcolumncoltab0131113" ref="colgridcolumncoltab0131113"  v-bind:style="{}" :cols = "12" 
 :sm = "4" 
><div class="col-container">
 <div  :class="'column columncolgridcolumncoltab013111111 '"   id="columncolgridcolumncoltab013111111" ref="columncolgridcolumncoltab013111111"  v-bind:style="{}">
 <LDLeftSection :class="'object ldleftsectioncolumncolgridcolumncoltab0131111113 '"   id="ldleftsectioncolumncolgridcolumncoltab0131111113" ref="ldleftsectioncolumncolgridcolumncoltab0131111113"  v-bind:style="{}" :showShare = "showShare" 
 :sectionsTree = "m_sectionsTree" 
 :isWebinar = "isWebinar" 
 :courseURL = "m_mainLink" 
>
</LDLeftSection>  </div></div></v-col>  </v-row>  </div></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 <ShareModal :class="'object sharemodalitem05222 '"   id="sharemodalitem05222" ref="sharemodalitem05222" v-if="shareModalVi"  v-bind:style="{}" :close = "hideShare" 
 :url = "shareOptions.url" 
 :title = "shareOptions.title" 
 :hashtags = "shareOptions.hashtags" 
 :subject = "shareOptions.subject" 
 :description = "shareOptions.description" 
>
</ShareModal>
 <LDVideoModal :class="'object ldvideomodalitem05 '"   id="ldvideomodalitem05" ref="ldvideomodalitem05" v-if="videoModalVisible"  v-bind:style="{}" :close = "closeVideoModal" 
 :image = "m_image" 
 :isWebinar = "isWebinar" 
 :videoLink = "teaser" 
 :title = "name" 
 :url = "m_mainLink" 
 :isFree = "isFree" 
 :hasWorkshop = "hasWorkshop" 
>
</LDVideoModal>  </div>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import LDTopSection from '../../At/Components/Learning/LDTopSection'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import LDMainSection from '../../At/Components/Learning/LDMainSection'
import LDLeftSection from '../../At/Components/Learning/LDLeftSection'
import ShareModal from '../../At/Sections/Modal/ShareModal'
import LDVideoModal from '../../At/Components/Learning/LDVideoModal'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
export default {
	name: 'DetailsPage',

    components: {MainLayout,LDTopSection,ContentWrapper,LDMainSection,LDLeftSection,ShareModal,LDVideoModal,},
	methods: {
	
		showShare: function() { 		
	this.shareModalVi = true;

}
,
		hideShare: function() { 		
	this.shareModalVi = false;

}
,
		getMoodleUser: function(id) { 		
	let config = [

		{

			key: 'id',

			value: id

		}

	]

	

	Request.getMoodleUsersByField(config)

	.then((res) => {

		this.moodleInstructor = res.data.users[0]

	})

}
,
		showVideoModal: function() { 		
	this.videoModalVisible = true

}
,
		closeVideoModal: function() { 		
	this.videoModalVisible = false

}
,
	},
	mounted(){
			
	},
	metaInfo(){
			
	if(this.shareOptions == null) return {}

	

	return {

		title: this.shareOptions.title,

		meta: [		

	   { name: 'description', content: this.shareOptions.description},

	

	   { property: 'og:title', content: this.shareOptions.title},

	   { property: 'og:description', content: this.shareOptions.description},

	   { property: 'og:type', content: this.isWebinar ? 'Webinar' : 'Formation'},

	   { property: 'og:url', content: this.shareOptions.url},

	   { property: 'og:image', content: this.m_image },

	

	   { name: 'twitter:title', content: this.shareOptions.title},

	   { name: 'twitter:description', content: this.shareOptions.description},

	   { name: 'twitter:card', content: this.isWebinar ? 'Webinar' : 'Formation'},

	   { name: 'twitter:url', content: this.shareOptions.url},

	   { name: 'twitter:image', content: this.m_image }    

	  ]

	}

	},
	computed: {
	
		url: function() { 		
	let url = this.$store.state.webUrl

	

	if(this.isWebinar){

		url += "/webinar/" + this.slug

	} else {

		if(this.fromMoodle){

			url += "/learning/m/" + this.moodleInfo?.id

		} else {

			url += "/learning/" + this.slug

		}

	}

	return url

}
,
		slugWord: function() { 		
	if(this.slug){

		return Main.quebabToWords(this.slug)

	}

	return null

}
,
		shareOptions: function() { 		
	if(this.m_name){	

		return {

			url: this.url, 

			title: 'Afrotiative | ' + this.m_name, 

			hashtags: ['Afrotiative', 'Educaton', 'formation', 'Webinars', this.m_name, this.slug], 

			description: this.m_name + '' + this.$t('LearningDetails.onAfro'), 

			to: '',

			subject: this.m_name + '' + this.$t('LearningDetails.onAfro')

		}

	} else if(this.slugWord){	

		return {

			url: window.location.href, 

			title: this.slugWord + '| Afrotiative', 

			hashtags: ['Afrotiative', 'Educaton', 'Formation', 'Webinars'], 

			description: this.slugWord, 

			to: '',

			subject: this.slugWord

		}

	}

	

	return null

	

}
,
		id: function(){		
	if(this.info){

		return this.info.id

	}

	return null

}
,
		attributes: function(){		
	if(this.info){

		return this.info.attributes

	}

	return null

}
,
		image: function() { 		
	if(this.attributes){

		if(this.attributes.image){

			let item = this.attributes.image.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return null

	}

	return null

}
,
		moodle_image: function() { 		
	if(this.moodleInfo){

		if(this.moodleInfo.overviewfiles.length > 0){

			return Main.getMoodleAssets(this.moodleInfo.overviewfiles[0].fileurl)

		}

		return "https://picsum.photos/200"

	}

	return "https://picsum.photos/200"

}
,
		m_image: function() { 		
	if(this.fromMoodle){

		return this.moodle_image

	}

	return this.image

}
,
		teaser: function() { 		
	if(this.attributes){

		if(this.attributes.video.data){

			let videoData = this.attributes.video.data.attributes

			return Main.getUrl(videoData)

		}

		return null

	}

	return null

}
,
		sectionsTree: function() { 		
	if(this.attributes){

		return this.attributes.sectionsTree

	}

	return null

}
,
		moodle_sectionsTree: function() { 		
	if(this.moodleInfo){

		return this.moodleInfo.sectionsTree

	}

	return null

}
,
		m_sectionsTree: function() { 		
	if(this.fromMoodle){

		return this.moodle_sectionsTree

	}

	return this.sectionsTree

}
,
		name: function() { 		
	if(this.attributes){

		return this.attributes.name

	}

	return null

}
,
		moodle_name: function() { 		
	if(this.moodleInfo){

		return this.moodleInfo.displayname

	}

	return ""

}
,
		m_name: function() { 		
	if(this.fromMoodle){

		return this.moodle_name

	}

	return this.name

}
,
		mainLink: function() { 		
	if(this.attributes){	

		if(this.isWebinar){

			return "/webinars/" 

				+ this.attributes.slug

				+ "/register"

		}

		

		return this.attributes.link

	}

	return null

}
,
		moodle_mainLink: function() { 		
	if(this.moodleInfo){

		return this.$store.state.learningUrl 

			+ '/course/view.php?id=' 

			+ this.moodleInfo.id

	}

	return this.$store.state.learningUrl

}
,
		m_mainLink: function() { 		
	if(this.fromMoodle){

		return this.moodle_mainLink

	}

	return this.mainLink

}
,
		m_instructor: function() { 		
	if(this.attributes){

		if(this.attributes.instructor){

			return this.attributes.instructor.data

		}

		return null

	}

	return null

}
,
		author: function() { 		
	if(this.m_instructor){

		return this.m_instructor.attributes.username

	}

	return null

}
,
		instructorID: function() { 		
	if(this.m_instructor){

		return this.m_instructor.id

	}

	return null

}
,
		duration: function() { 		
	if(this.attributes){

		if(this.attributes.duration){

			return Main.convertHMS(this.attributes.duration)

		}

		return null

	}

	return null

}
,
		publishedDate: function() { 		
	if(this.attributes){

		let lang = this.$store.state.selectedLang

		let date = new Date(this.attributes.publishedAt);

		return new Intl.DateTimeFormat(lang, { dateStyle: 'full' }).format(date);

	}

	return null

}
,
		moodle_publishedDate: function() { 		
	if(this.moodleInfo){

		let lang = this.$store.state.selectedLang

		let date = new Date(this.moodleInfo.startdate)

		return new Intl.DateTimeFormat(lang, { dateStyle: 'full' }).format(date);

	}

	return null

}
,
		m_publishedDate: function() { 		
	if(this.fromMoodle){

		return this.moodle_publishedDate

	}

	return this.publishedDate

}
,
		description: function() { 			
	if(this.attributes){

		return this.attributes.description

	}

	return null

}
,
		moodle_description: function() { 		
	if(this.moodleInfo){

		return this.moodleInfo.summary

	}

	return null

}
,
		m_description: function() { 		
	if(this.fromMoodle){

		return this.moodle_description

	}

	return this.description

}
,
		content: function() { 			
	if(this.attributes){

		return this.attributes.content

	}

	return null

}
,
		tags: function() { 			
	if(this.attributes){

		let tags = this.attributes.tags.data

			.map(s => s.attributes)

		return tags

	}

	return []

}
,
		isFree: function() { 		
	if(this.attributes){

		return this.attributes.free

	}

	return false

}
,
		hasWorkshop: function() { 		
	if(this.attributes){

		return this.attributes.hasWorkshop

	}

	return false

}
,
	},
	watch: {
	
		moodleInfo: function(val) { 		
	if(this.fromMoodle){

		if(this.moodleInfo){	

			if(this.moodleInfo.contacts.length > 0){

				this.getMoodleUser(parseInt(this.moodleInfo.contacts[0].id))

			}

		}

	}

}
,
	},
	data(){
	return {
		
		shareModalVi:  false		,
		moodleInstructor:  null		,
		videoModalVisible:  false		,
		}
	},
	props: {
	
		fromMoodle: {			type: Boolean,			required: false,			default: () =>  false,		},
		info: {					required: false,			default: () =>  null,		},
		moodleInfo: {					required: false,			default: () =>  null,		},
		isWebinar: {			type: Boolean,			required: false,			default: () =>  false,		},
		currentTab: {					required: false,			default: () =>  0,		},
		slug: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#DetailsPage{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.object.defaultlayout0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:50px ; background-color: transparent ; 

						.grid.gridcolumncoltab013111{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						background-color: transparent ; 

							.col.colgridcolumncoltab0131111{

							
							position:relative !important; 
							margin: unset;
							transform-origin: top left;
							
							
							
							height:auto!important ; 
							

							::v-deep .col-container{position: relative;

							height: auto;

							width: 100%;

								.column.columncolgridcolumncoltab01311111{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;background-color: transparent ; 

									.object.ldmainsectioncolumncolgridcolumncoltab013111111{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

							.col.colgridcolumncoltab0131113{

							
							position:relative !important; 
							margin: unset;
							transform-origin: top left;
							
							
							
							height:auto!important ; 
							

							::v-deep .col-container{position: relative;

							height: auto;

							width: 100%;

								.column.columncolgridcolumncoltab013111111{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;background-color: transparent ; 

									.object.ldleftsectioncolumncolgridcolumncoltab0131111113{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem05222{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldvideomodalitem05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.grid.gridcolumncoltab013111{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridcolumncoltab0131111{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab01311111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldmainsectioncolumncolgridcolumncoltab013111111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

							.col.colgridcolumncoltab0131113{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab013111111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldleftsectioncolumncolgridcolumncoltab0131111113{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem05222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldvideomodalitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.grid.gridcolumncoltab013111{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridcolumncoltab0131111{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab01311111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldmainsectioncolumncolgridcolumncoltab013111111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

							.col.colgridcolumncoltab0131113{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab013111111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldleftsectioncolumncolgridcolumncoltab0131111113{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem05222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldvideomodalitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.grid.gridcolumncoltab013111{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridcolumncoltab0131111{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab01311111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldmainsectioncolumncolgridcolumncoltab013111111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

							.col.colgridcolumncoltab0131113{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab013111111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldleftsectioncolumncolgridcolumncoltab0131111113{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem05222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldvideomodalitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.grid.gridcolumncoltab013111{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridcolumncoltab0131111{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab01311111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldmainsectioncolumncolgridcolumncoltab013111111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

							.col.colgridcolumncoltab0131113{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab013111111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldleftsectioncolumncolgridcolumncoltab0131111113{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem05222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldvideomodalitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.contentwrappercolumnslottemplatedefaultlayout01513{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.grid.gridcolumncoltab013111{

						
						position:relative !important; 
						
						
						
						
						

							.col.colgridcolumncoltab0131111{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab01311111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldmainsectioncolumncolgridcolumncoltab013111111{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

							.col.colgridcolumncoltab0131113{

							
							position:relative !important; 
							
							
							
							

							::v-deep .col-container{position: relative;

							height: 100%;

							width: 100%;

								.column.columncolgridcolumncoltab013111111{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.ldleftsectioncolumncolgridcolumncoltab0131111113{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem05222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.ldvideomodalitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
