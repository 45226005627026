<template>
 <div>  
<div id="Header"> 
         
 <ContentWrapper :class="'object contentwrapper0 '"   id="contentwrapper0" ref="contentwrapper0" :_backgroundColor = "'#fff'" 
  v-bind:style="{backgroundColor : '#fff',}" :paddingTop = "0" 
 :paddingBottom = "0" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrapper01 '"   id="slottemplatecontentwrapper01" ref="slottemplatecontentwrapper01"  v-bind:style="{}">
 <div  :class="'row rowslottemplatecontentwrapper011 '"   id="rowslottemplatecontentwrapper011" ref="rowslottemplatecontentwrapper011"  v-bind:style="{justifyContent : 'space-between',}">
 <Logo :class="'object logorowslottemplatecontentwrapper0115 '"   id="logorowslottemplatecontentwrapper0115" ref="logorowslottemplatecontentwrapper0115"  v-bind:style="{}">
</Logo>
 <SearchBar :class="'object searchbarrowslottemplatecontentwrapper0115 '"   id="searchbarrowslottemplatecontentwrapper0115" ref="searchbarrowslottemplatecontentwrapper0115" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
</SearchBar>
 <div  :class="'row rowrowslottemplatecontentwrapper0113 '"   id="rowrowslottemplatecontentwrapper0113" ref="rowrowslottemplatecontentwrapper0113" :_flex = "''" 
  v-bind:style="{flex : '',}">
 <v-select
          :items = "language" :class="'select selectright5 '"   id="selectright5" ref="selectright5" v-model="selectedLang"  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>
 <v-btn tag="div" 
 :rounded = "true" 
 :text = "true" 
 :class="'button buttonrowrowslottemplatecontentwrapper01131 '"   id="buttonrowrowslottemplatecontentwrapper01131" ref="buttonrowrowslottemplatecontentwrapper01131" :to = "'/signup'" 
  v-bind:style="{}"><span>{{ $t('Header.joinNow') }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :outlined = "true" 
 :rounded = "true" 
 :class="'button buttonrowrowslottemplatecontentwrapper011312 '"   id="buttonrowrowslottemplatecontentwrapper011312" ref="buttonrowrowslottemplatecontentwrapper011312" :to = "'/login'" 
  v-bind:style="{}"><span>{{ $t('Header.signIn') }}</span>
</v-btn>  </div>
 <div  :class="'row rowrowslottemplatecontentwrapper01111 '"   id="rowrowslottemplatecontentwrapper01111" ref="rowrowslottemplatecontentwrapper01111"  v-bind:style="{}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="gotoSearch" :class="'button buttonrowslottemplatecontentwrapper0111141 '"   id="buttonrowslottemplatecontentwrapper0111141" ref="buttonrowslottemplatecontentwrapper0111141"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-search' !== undefined" >{{ 'ion-md-search' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="toggleMenu" :class="'button buttonrowslottemplatecontentwrapper01111452 '"   id="buttonrowslottemplatecontentwrapper01111452" ref="buttonrowslottemplatecontentwrapper01111452"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-menu' !== undefined" >{{ 'ion-md-menu' }}
</v-icon></v-btn>  </div>  </div></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../At/Utils/ContentWrapper'
import Logo from '../../At/Utils/Logo'
import SearchBar from '../../At/Components/SearchBar'

import * as Main from '../../Js/main'
export default {
	name: 'Header',

    components: {ContentWrapper,Logo,SearchBar,},
	methods: {
	
		setLang: function() { 		
	Main.checkLang()

	this.selectedLang = this.$store.state.selectedLang

	this.$i18n.locale = this.selectedLang

}
,
		gotoSearch: function() { 		
	let base = "/search"

	

	this.$router.push(base)

}
,
	},
	mounted(){
			
	this.setLang()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		language: function() { 		
	return this.$store.state.language

}
,
		paramLang: function() { 		
	return this.$router.history.current.query.lang

}
,
	},
	watch: {
	
		selectedLang: function(val) { 		
	this.$i18n.locale = val

	this.$store.commit('changeSelectedLang', val)

}
,
		paramLang: function(val) { 		
	this.setLang()

}
,
	},
	data(){
	return {
		
		selectedLang:  'fr'		,
		}
	},
	props: {
	
		toggleMenu: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Header{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.selectright5{
	&:before{
		border: none;
	}
}



.object.contentwrapper0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
backgroundColor:#fff !important; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
height:auto!important ; 

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:20px ; 
	justify-content:space-between ; background-color:transparent ; 
	padding-top:12px ; 
	padding-bottom:12px ; 

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:50px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.searchbarrowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		display:none !important; 
		flex:1 !important; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.rowrowslottemplatecontentwrapper0113{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		display:none !important; 
		width:auto!important ; 
		height:50px!important ; 
		flex: !important; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:5px ; background-color:transparent ; 

			.select.selectright5{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:70px!important ; 
			height:50px!important ; 
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.button.buttonrowrowslottemplatecontentwrapper01131{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			height:40px!important ; 
			to:/signup !important; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

			.button.buttonrowrowslottemplatecontentwrapper011312{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			height:40px!important ; 
			to:/login !important; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

		
}

		.row.rowrowslottemplatecontentwrapper01111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:50px!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:8px ; background-color:transparent ; 

			.button.buttonrowslottemplatecontentwrapper0111141{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			 display:flex !important; 
			width:50px!important ; 
			height:50px!important ; 
			
			font-size:25px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:25px ; 
			}
			}

			.button.buttonrowslottemplatecontentwrapper01111452{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			 display:flex !important; 
			width:50px!important ; 
			height:50px!important ; 
			
			font-size:25px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:25px ; 
			}
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.searchbarrowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.rowrowslottemplatecontentwrapper0113{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.select.selectright5{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.button.buttonrowrowslottemplatecontentwrapper01131{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowrowslottemplatecontentwrapper011312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.row.rowrowslottemplatecontentwrapper01111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowslottemplatecontentwrapper0111141{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowslottemplatecontentwrapper01111452{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.searchbarrowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.rowrowslottemplatecontentwrapper0113{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.select.selectright5{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.button.buttonrowrowslottemplatecontentwrapper01131{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowrowslottemplatecontentwrapper011312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.row.rowrowslottemplatecontentwrapper01111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowslottemplatecontentwrapper0111141{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowslottemplatecontentwrapper01111452{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.searchbarrowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		 display:block !important; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.rowrowslottemplatecontentwrapper0113{

		
		position:relative !important; 
		
		
		
		
		 display:flex !important; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.select.selectright5{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.button.buttonrowrowslottemplatecontentwrapper01131{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowrowslottemplatecontentwrapper011312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.row.rowrowslottemplatecontentwrapper01111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowslottemplatecontentwrapper0111141{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			display:none !important; 
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowslottemplatecontentwrapper01111452{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			display:none !important; 
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.searchbarrowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.rowrowslottemplatecontentwrapper0113{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.select.selectright5{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.button.buttonrowrowslottemplatecontentwrapper01131{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowrowslottemplatecontentwrapper011312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.row.rowrowslottemplatecontentwrapper01111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowslottemplatecontentwrapper0111141{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowslottemplatecontentwrapper01111452{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentwrapper0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrapper01{


position:unset !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.row.rowslottemplatecontentwrapper011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.logorowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.searchbarrowslottemplatecontentwrapper0115{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.row.rowrowslottemplatecontentwrapper0113{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.select.selectright5{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

			.button.buttonrowrowslottemplatecontentwrapper01131{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowrowslottemplatecontentwrapper011312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.row.rowrowslottemplatecontentwrapper01111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowslottemplatecontentwrapper0111141{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowslottemplatecontentwrapper01111452{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}
}
</style>
