<template>
 <div>  
<div id="MenuModal"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'column columncolumn03 '"   id="columncolumn03" ref="columncolumn03"  v-bind:style="{}">
 <GroupItem :class="'object groupitemcolumncolumn031 '"   id="groupitemcolumncolumn031" ref="groupitemcolumncolumn031" v-if="isLogedIn"  v-bind:style="{}" :title = "$t('Modal.menu')" 
 :padding = "12" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplategroupitemcolumncolumn0311 '"   id="slottemplategroupitemcolumncolumn0311" ref="slottemplategroupitemcolumncolumn0311"  v-bind:style="{}">
 <div  :class="'flow flowslottemplategroupitemcolumncolumn03111 '"   id="flowslottemplategroupitemcolumncolumn03111" ref="flowslottemplategroupitemcolumncolumn03111"  v-bind:style="{justifyContent : 'space-between',}">
 <AwareIconLink :class="'object awareiconlinkflowslottemplategroupitemcolumncolumn031111 '"   id="awareiconlinkflowslottemplategroupitemcolumncolumn031111" ref="awareiconlinkflowslottemplategroupitemcolumncolumn031111" v-for="(item,index) in sections" :key="'awareiconlinkflowslottemplategroupitemcolumncolumn031111' + index"  v-bind:style="{}" :text = "item.name" 
 :icon = "item.icon" 
 :link = "item.link" 
 :target = "item.target" 
>
</AwareIconLink>
 <TabActionButton :class="'object tabbuttonrowtop11522 '"   id="tabbuttonrowtop11522" ref="tabbuttonrowtop11522"  v-bind:style="{}" :action = "toggleProfileModal" 
 :value = "$t('Header.you')" 
 :image = "profileImage" 
>
</TabActionButton>
 <TabActionButton :class="'object tabbuttonrowtop1153 '"   id="tabbuttonrowtop1153" ref="tabbuttonrowtop1153"  v-bind:style="{}" :action = "toggleServiceModal" 
 :value = "$t('Header.sections')" 
 :icon = "'ion-md-apps'" 
>
</TabActionButton>  </div></div></template>
</GroupItem>
 <GroupItem :class="'object groupitemcolumncolumn0312 '"   id="groupitemcolumncolumn0312" ref="groupitemcolumncolumn0312"  v-bind:style="{}" :title = "$t('Modal.actions')" 
 :padding = "12" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplategroupitemcolumncolumn0311 '"   id="slottemplategroupitemcolumncolumn0311" ref="slottemplategroupitemcolumncolumn0311"  v-bind:style="{}">
 <div  :class="'column rowrowslottemplatecontentwrapper01131 '"   id="rowrowslottemplatecontentwrapper01131" ref="rowrowslottemplatecontentwrapper01131" :_flex = "''" 
  v-bind:style="{flex : '',}">
 <v-select
          :items = "language" :class="'select selectright5 '"   id="selectright5" ref="selectright5" v-model="selectedLang"  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>
 <v-btn tag="div" 
 :rounded = "true" 
 :text = "true" 
 :class="'button buttonrowrowslottemplatecontentwrapper01131 '"   id="buttonrowrowslottemplatecontentwrapper01131" ref="buttonrowrowslottemplatecontentwrapper01131" v-if="!isLogedIn" :to = "'/signup'" 
  v-bind:style="{}"><span>{{ $t('Header.joinNow') }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :outlined = "true" 
 :rounded = "true" 
 :class="'button buttonrowrowslottemplatecontentwrapper011312 '"   id="buttonrowrowslottemplatecontentwrapper011312" ref="buttonrowrowslottemplatecontentwrapper011312" v-if="!isLogedIn" :to = "'/login'" 
  v-bind:style="{}"><span>{{ $t('Header.signIn') }}</span>
</v-btn>  </div></div></template>
</GroupItem>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import GroupItem from '../../../At/Utils/GroupItem'
import AwareIconLink from '../../../At/Utils/AwareIconLink'
import TabActionButton from '../../../At/Utils/TabActionButton'

import * as Main from '../../../Js/main'
export default {
	name: 'MenuModal',

    components: {GroupItem,AwareIconLink,TabActionButton,},
	computed: {
	
		language: function() { 		
	return this.$store.state.language

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		profileImage: function() { 		
	if(this.user){

		if(this.user.image){

			let item = this.user.image;

			return Main.getImgUrl(item, 'small');

		}

		return "https://picsum.photos/500"

	}

	return "https://picsum.photos/500"

}
,
		isLogedIn: function() { 		
	return this.user != null

}
,
		sections: function() { 		
	return [

		{

			name: this.$t('Header.home'),

			icon: "ion-ios-home",

			target: "_self",

			link: "/"

		},

		{

			name: this.$t('Header.learning'),

			icon: "ion-md-book",

			target: "_self",

			link: "/learning"

		},

		{

			name: this.$t('Header.webinars'),

			icon: "ion-ios-globe",

			target: "_self",

			link: "/webinars"

		},

		{

			name: this.$t('Header.blog'),

			icon: "ion-md-paper",

			target: "_self",

			link: "/blog"

		},

	]

}
,
	},
	watch: {
	
		selectedLang: function(val) { 		
	this.$i18n.locale = val

	this.$store.commit('changeSelectedLang', val)

}
,
	},
	data(){
	return {
		
		selectedLang:  'fr'		,
		}
	},
	props: {
	
		toggleProfileModal: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		toggleServiceModal: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#MenuModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:15px ; background-color: transparent ; 

.column.columncolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: wrap;
			flex: initial;
			gap:5px ; 
			justify-content:space-between ; background-color:transparent ; 

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:60px!important ; 
				height:auto!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop11522{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:60px!important ; 
				height:auto!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop1153{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				margin-bottom: 0px ; 
				width:60px!important ; 
				height: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn0312{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.rowrowslottemplatecontentwrapper01131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			flex: !important; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:8px ; background-color: transparent ; 

				.select.selectright5{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:50px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.button.buttonrowrowslottemplatecontentwrapper01131{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:40px!important ; 
				to:/signup !important; 
				width: calc(100% - (0px + 0px)) !important;
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

				.button.buttonrowrowslottemplatecontentwrapper011312{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:40px!important ; 
				to:/login !important; 
				width: calc(100% - (0px + 0px)) !important;
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop11522{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop1153{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn0312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.rowrowslottemplatecontentwrapper01131{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.select.selectright5{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.button.buttonrowrowslottemplatecontentwrapper01131{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowrowslottemplatecontentwrapper011312{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop11522{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop1153{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn0312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.rowrowslottemplatecontentwrapper01131{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.select.selectright5{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.button.buttonrowrowslottemplatecontentwrapper01131{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowrowslottemplatecontentwrapper011312{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop11522{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop1153{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn0312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.rowrowslottemplatecontentwrapper01131{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.select.selectright5{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.button.buttonrowrowslottemplatecontentwrapper01131{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowrowslottemplatecontentwrapper011312{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop11522{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop1153{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn0312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.rowrowslottemplatecontentwrapper01131{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.select.selectright5{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.button.buttonrowrowslottemplatecontentwrapper01131{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowrowslottemplatecontentwrapper011312{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.flow.flowslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awareiconlinkflowslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop11522{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.tabbuttonrowtop1153{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}

	.object.groupitemcolumncolumn0312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.rowrowslottemplatecontentwrapper01131{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.select.selectright5{

				
				position:relative !important; 
				
				
				
				
				
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
				}

				.button.buttonrowrowslottemplatecontentwrapper01131{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrowrowslottemplatecontentwrapper011312{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

	
}


}


}
}
}
</style>
