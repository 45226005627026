<template>
 <div>  
<div id="SitesDetails"> 
         
 <iframe :class="'importedobject importedobjectcolumn012 '"   id="importedobjectcolumn012" ref="importedobjectcolumn012" v-if="loaded" :srcdoc = "htmlCode" 
 :_border = "'none'" 
  v-bind:style="{border : 'none',}">
</iframe>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../Js/main'
import * as Request from '../../Js/requests'
export default {
	name: 'SitesDetails',

    components: {},
	methods: {
	
		updateFavIcon: function() { 		
	var link = document.querySelector("link[rel~='icon']");	

	if(!link) {

	    link = document.createElement('link');

	    link.rel = 'icon';

	    document.getElementsByTagName('head')[0].appendChild(link);

	}

	

	link.href = this.icon

}
,
		updatePageDetails: function() { 		
	document.title = this.page.name + ' | Afrotiative'

	this.updateFavIcon()

}
,
		getSite: function() { 		
	let config = {	

	  filters: {

	    slug: {

	      $eq: this.slug,

	    },

	  }

	}

		

	Request.getTableArgs('pages', config)

	.then((res) => {

		this.page = res.data.data[0].attributes

		this.loaded = true

		this.updatePageDetails()

	})

}
,
	},
	mounted(){
			
	this.getSite()

	},
	computed: {
	
		slug: function() { 		
	return this.$route.params.slug

}
,
		htmlCode: function() { 		
	return this.page.htmlCode

}
,
		icon: function() { 		
	if(this.page){

		let item = this.page.icon?.data?.attributes;

		if(item){

			return Main.getImgUrl(item, 'small');

		}

	}

	return "https://res.cloudinary.com/afrotiative/image/upload/v1668805319/logo-white_oc6zqj.png"

}
,
	},
	data(){
	return {
		
		page:  null		,
		loaded:  false		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#SitesDetails{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.importedobject.importedobjectcolumn012{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:100vh!important ; 
border:none !important; 
width: calc(100% - (0px + 0px)) !important;



}


@media (max-width: 600px) {


.importedobject.importedobjectcolumn012{


position:relative !important; 







}
}

@media (min-width: 601px) {


.importedobject.importedobjectcolumn012{


position:relative !important; 







}
}

@media (min-width: 961px) {


.importedobject.importedobjectcolumn012{


position:relative !important; 







}
}

@media (min-width: 1265px) {


.importedobject.importedobjectcolumn012{


position:relative !important; 







}
}

@media (min-width: 1905px) {


.importedobject.importedobjectcolumn012{


position:relative !important; 







}
}
}
</style>
