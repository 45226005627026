<template>
 <div>  
<div id="WebinarRegisterSession"> 
         
 <RegisterSessionPage :class="'object detailspage0 '"   id="detailspage0" ref="detailspage0"  v-bind:style="{}" :getInfo = "getInfo" 
 :info = "info" 
 :isWebinar = "true" 
 :currentTab = "2" 
>
</RegisterSessionPage>
 
      </div>
 </div> 
</template>
<script>
import RegisterSessionPage from '../../At/Sections/RegisterSessionPage'

import * as Request from '../../Js/requests'
export default {
	name: 'WebinarRegisterSession',

    components: {RegisterSessionPage,},
	methods: {
	
		getInfo: function() { 		
	let slug = this.$route.params.slug

	

	let config = {	

	  filters: {

	    slug: {

	      $eq: slug,

	    },

	  },

		populate: {	

	    sessions: {

	      populate: ['participants'],

					sort: 'date:desc',

	    }

	  }

	}

	

	Request.getTableArgs('webinars', config)

	.then((res) => {

		this.info = res.data.data[0]

	})

}
,
	},
	mounted(){
			
	this.getInfo()

	},
	data(){
	return {
		
		info:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#WebinarRegisterSession{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.detailspage0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


@media (max-width: 600px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 601px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 961px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1265px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1905px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}
}
</style>
