<template>
 <div>  
<div id="ContentWrapper"> 
         
 <div  :class="'column contentWrapperColumn '"   id="contentWrapperColumn" ref="contentWrapperColumn"  v-bind:style="{backgroundColor : bgColor,paddingTop : getUnit(paddingTop,'px'),paddingBottom : getUnit(paddingBottom,'px'),paddingLeft : getUnit(paddingLeft,'px'),paddingRight : getUnit(paddingRight,'px'),}">
 <div  :class="'item itemcontentWrapperColumn5 '"   id="itemcontentWrapperColumn5" ref="itemcontentWrapperColumn5" v-if="bgImage"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagecontentWrapperColumn31 '"   id="networkimagecontentWrapperColumn31" ref="networkimagecontentWrapperColumn31"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "bgImage" 
>  </v-img>
 <div  :class="'item itemitemcontentWrapperColumn53 '"   id="itemitemcontentWrapperColumn53" ref="itemitemcontentWrapperColumn53" :_background = "(mqVal({default:'linear-gradient(to left,rgba(255,255,255,.2),#fff 90%)',sm:'linear-gradient(to left,rgba(255,255,255,.3),#fff 67%)',md:'linear-gradient(to left,rgba(255,255,255,.3),#fff 75%)'}, $mq))" 
  v-bind:style="{background : (mqVal({default:'linear-gradient(to left,rgba(255,255,255,.2),#fff 90%)',sm:'linear-gradient(to left,rgba(255,255,255,.3),#fff 67%)',md:'linear-gradient(to left,rgba(255,255,255,.3),#fff 75%)'}, $mq)),}">  </div>  </div>
 <div  :class="'slot slotcolumn01 '"   id="slotcolumn01" ref="slotcolumn01"  v-bind:style="{maxWidth : getUnit(maxWidth,'px'),}"><slot  :name = "'content'" 
></slot></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ContentWrapper',

    components: {},
	computed: {
	
		maxWidth: function() { 		
	if(this.fullWidth){

		return "none"

	} else {

		return 1128

	}

}
,
	},
	props: {
	
		paddingTop: {					required: false,			default: () =>  20,		},
		paddingBottom: {					required: false,			default: () =>  20,		},
		paddingLeft: {					required: false,			default: () =>  20,		},
		paddingRight: {					required: false,			default: () =>  20,		},
		bgColor: {					required: false,			default: () =>  "transparent",		},
		bgImage: {					required: false,			default: () =>  null,		},
		fullWidth: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ContentWrapper{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.contentWrapperColumn{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

.item.itemcontentWrapperColumn5{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


	.networkimage.networkimagecontentWrapperColumn31{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.item.itemitemcontentWrapperColumn53{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	background:linear-gradient(to left,rgba(255,255,255,.2),#fff 90%) !important; 
	

	
}


}

.slot.slotcolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:100%!important ; 
height:auto!important ; 
}


}


@media (max-width: 600px) {


.column.contentWrapperColumn{


position:relative !important; 









.item.itemcontentWrapperColumn5{


position:absolute !important; 






	.networkimage.networkimagecontentWrapperColumn31{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemitemcontentWrapperColumn53{

	
	position:absolute !important; 
	
	
	
	
	

	
}


}

.slot.slotcolumn01{


position:relative !important; 




}


}
}

@media (min-width: 601px) {


.column.contentWrapperColumn{


position:relative !important; 









.item.itemcontentWrapperColumn5{


position:absolute !important; 






	.networkimage.networkimagecontentWrapperColumn31{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemitemcontentWrapperColumn53{

	
	position:absolute !important; 
	
	
	
	
	background:linear-gradient(to left,rgba(255,255,255,.3),#fff 67%) !important; 
	

	
}


}

.slot.slotcolumn01{


position:relative !important; 




}


}
}

@media (min-width: 961px) {


.column.contentWrapperColumn{


position:relative !important; 









.item.itemcontentWrapperColumn5{


position:absolute !important; 






	.networkimage.networkimagecontentWrapperColumn31{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemitemcontentWrapperColumn53{

	
	position:absolute !important; 
	
	
	
	
	background:linear-gradient(to left,rgba(255,255,255,.3),#fff 75%) !important; 
	

	
}


}

.slot.slotcolumn01{


position:relative !important; 




}


}
}

@media (min-width: 1265px) {


.column.contentWrapperColumn{


position:relative !important; 









.item.itemcontentWrapperColumn5{


position:absolute !important; 






	.networkimage.networkimagecontentWrapperColumn31{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemitemcontentWrapperColumn53{

	
	position:absolute !important; 
	
	
	
	
	

	
}


}

.slot.slotcolumn01{


position:relative !important; 




}


}
}

@media (min-width: 1905px) {


.column.contentWrapperColumn{


position:relative !important; 









.item.itemcontentWrapperColumn5{


position:absolute !important; 






	.networkimage.networkimagecontentWrapperColumn31{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemitemcontentWrapperColumn53{

	
	position:absolute !important; 
	
	
	
	
	

	
}


}

.slot.slotcolumn01{


position:relative !important; 




}


}
}
}
</style>
