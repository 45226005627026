<template>
 <div>  
<div id="SearchBar"> 
         
 <div  :class="'row row0 '"   id="row0" ref="row0"  v-bind:style="{backgroundColor : '#ebeff1',}">
 <v-select
          :items = "entities" :class="'select selectrow03 '"   id="selectrow03" ref="selectrow03" v-model="topic" :color = "primaryColor" 
 :item-text = "'name'" 
 :item-value = "'entityName'" 
  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>
 <div  :class="'rectangle divider '"   id="divider" ref="divider"  v-bind:style="{backgroundColor : '#c8cbcd',}">  </div>
 <div  :class="'row rowrow03 '"   id="rowrow03" ref="rowrow03" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <v-text-field  :placeholder = "$t('Header.serachList')" 
 :class="'textfield searchField '"   id="searchField" ref="searchField" v-model="query" :_flex = "'1'" 
 :color = "primaryColor" 
  v-bind:style="{flex : '1',}"></v-text-field>
 <div  :class="'rectangle divider1 '"   id="divider1" ref="divider1"  v-bind:style="{backgroundColor : '#c8cbcd',}">  </div>
 <v-btn tag="div" 
 :fab = "true" 
 :rounded = "true" 
 :onlyIcon = "true" 
 :text = "true" 
 @click="gotoSearch" :class="'button buttonrowrow035 '"   id="buttonrowrow035" ref="buttonrowrow035"  v-bind:style="{}"><span v-if = "(!true) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-search' !== undefined" >{{ 'ion-ios-search' }}
</v-icon></v-btn>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'SearchBar',

    components: {},
	methods: {
	
		gotoSearch: function() { 		
	let base = "/search"

	

	if(this.topic){

		base += "/" + this.topic

	}

	

	if(this.query){

		this.$router.push(base + '?q=' + this.query)

	} else {

		this.$router.push(base)

	}

}
,
	},
	mounted(){
			
	let field = this.$refs.searchField.$el

	let input = field.querySelector('input')

	input.addEventListener('keypress', e => {

    if (e.key === 'Enter') {

      this.gotoSearch()

    }

	});

	},
	computed: {
	
		entities: function() { 		
	return [

		{

			name: this.$t('Search.organisations'),

			entityName: "organisations"

		},

		{

			name: this.$t('Search.members'),

			entityName: "users"

		},

		{

			name: this.$t('Search.universities'),

			entityName: "universities"

		},

		{

			name: this.$t('Search.mentors'),

			entityName: "mentors"

		},

		{

			name: this.$t('Search.webinars'),

			entityName: "webinars"

		},

		{

			name: this.$t('Search.articles'),

			entityName: "articles"

		},

		{

			name: this.$t('Search.formations'),

			entityName: "formations"

		},

		{

			name: this.$t('Search.pages'),

			entityName: "pages"

		},

		{

			name: this.$t('Search.projects'),

			entityName: "projects"

		},

	]

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		topic:  'users'		,
		query:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#SearchBar{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.row.row0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
background-color:#ebeff1 ; 
border-radius:8px ; 

.select.selectrow03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
width:auto!important ; 
max-width: 150px!important ; 
item-text:name !important; 
item-value:entityName !important; 
height: calc(100% - (0px + 0px)) !important;

::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
}

.rectangle.divider{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
width:1px!important ; 
height: calc(100% - (0px + 0px)) !important;

background-color:#c8cbcd ; 


}

.row.rowrow03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
flex:1 !important; 
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:4px ; background-color:transparent ; 

	.textfield.searchField{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	flex:1 !important; 
	height: calc(100% - (0px + 0px)) !important;
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.rectangle.divider1{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:1px!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	background-color:#c8cbcd ; 

	
}

	.button.buttonrowrow035{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:40px!important ; 
	height:40px!important ; 
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}


@media (max-width: 600px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.select.selectrow03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
}

.rectangle.divider{


position:relative !important; 








}

.row.rowrow03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.textfield.searchField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.rectangle.divider1{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.button.buttonrowrow035{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 601px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.select.selectrow03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
}

.rectangle.divider{


position:relative !important; 








}

.row.rowrow03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.textfield.searchField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.rectangle.divider1{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.button.buttonrowrow035{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 961px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.select.selectrow03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
}

.rectangle.divider{


position:relative !important; 








}

.row.rowrow03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.textfield.searchField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.rectangle.divider1{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.button.buttonrowrow035{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1265px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.select.selectrow03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
}

.rectangle.divider{


position:relative !important; 








}

.row.rowrow03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.textfield.searchField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.rectangle.divider1{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.button.buttonrowrow035{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1905px) {


.row.row0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.select.selectrow03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
}

.rectangle.divider{


position:relative !important; 








}

.row.rowrow03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.textfield.searchField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.rectangle.divider1{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.button.buttonrowrow035{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}
}
</style>
