import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
/**
 * Please don' t remove this.
 * Artefact will use it to inject code
 */
//<= inject_import =>
import * as Main from '../artefact/Js/main'
//<= end_import =>

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  /**
   * Please don' t remove this.
   * Artefact will use it to inject code
   */
  //<= inject_store =>
state: {
	
  
		companyName:  "Afrotiative"		,
		primaryColor:  "#e4580a"		,
		lightPrimaryColor:  "#e4580a12"		,
		webUrl:  "https://afrotiative.com"		,
		apiServer:  "https://api.afrotiative.com"		,
		learningUrl:  "https://learning.afrotiative.com"		,
		selectedLang:  "fr"		,
		user:  null		,
		token:  null		,
		moodleToken:  "efa0de763ec89862c01aae252b0d6592"		,
		language:  ['fr', 'en', 'de']		,
		selectedLang:  'fr'		,
		orgaToEnroll:  null		,
		feedIntroWached:  false		,
		profilIntroWached:  false		,
		toRedirectLink:  null		,      },

        mutations: {

   
		changeSelectedLang: function(state, val) { 		
	state.selectedLang = val

}
,
		changeUser: function(state, val) { 		
	if(val == null || val == undefined || val.length == 0){

		state.user = null

	} else {

		val.completionPercentage = Math.round(Main.computeProlileCompletion(val) * 100)

		state.user = val

	}

}
,
		changeToken: function(state, val) { 		
	state.token = val

}
,
		setOrgaToEnroll: function(state, val) { 		
	state.orgaToEnroll = val

}
,
		watchfeedIntro: function(state) { 		
	state.feedIntroWached = true

}
,
		watchProfileIntro: function(state) { 		
	state.profilIntroWached = true

}
,
		saveToRedirectLink: function(state, val) { 		
	state.toRedirectLink = val

}
,     },

        actions: {

   
		login: function({ commit }, {user, jwt}) { 		
	commit('changeUser', user)

	commit('changeToken', jwt)

}
,
		logout: function({ commit }) { 		
	commit('changeUser', null)

	commit('changeToken', null)

}
,     },

        
//<= end_store =>
})
