<template>
 <div>  
<div id="Faq"> 
         
 <MainLayout :class="'object basic0 '"   id="basic0" ref="basic0"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatebasic01 '"   id="slottemplatebasic01" ref="slottemplatebasic01"  v-bind:style="{}">
 <div  :class="'column column01 '"   id="column01" ref="column01"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout0151392 '"   id="contentwrappercolumnslottemplatedefaultlayout0151392" ref="contentwrappercolumnslottemplatedefaultlayout0151392"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column flow011 '"   id="flow011" ref="flow011"  v-bind:style="{}">
 <div  :class="'text textflow011 '"   id="textflow011" ref="textflow011"  v-bind:style="{color : '#4b4b4b',}">
<div class="text-content" :style = "{}">{{ $t('Faq.faq') }}</div>

   </div>
 <div  :class="'column columnflow013 '"   id="columnflow013" ref="columnflow013"  v-bind:style="{}">  </div>
 <div  :class="'column columnflow015 '"   id="columnflow015" ref="columnflow015"  v-bind:style="{}">
 <AccordillonItem :class="'object accordillonitemcolumnflow0151 '"   id="accordillonitemcolumnflow0151" ref="accordillonitemcolumnflow0151" v-for="(item,index) in faqs" :key="'accordillonitemcolumnflow0151' + index"  v-bind:style="{}" :question = "item.attributes.question" 
 :response = "item.attributes.response" 
>
</AccordillonItem>  </div>  </div></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import AccordillonItem from '../../At/Utils/AccordillonItem'

import * as Request from '../../Js/requests'
export default {
	name: 'Faq',

    components: {MainLayout,ContentWrapper,AccordillonItem,},
	methods: {
	
		getFaqs: function() { 		
	Request.getLocalTableArgs('faqs', '')

	.then((res) => {

		this.faqs = res.data.data

	})

}
,
	},
	mounted(){
			
	this.getFaqs()

	},
	metaInfo(){
			
	return {

		title: this.companyName + " | FAQ",

		meta: [		

	   { name: 'description', content: this.$t('Blog.description')},

	

	   { property: 'og:title', content: this.companyName + " | FAQ"},

	   { property: 'og:description', content: this.$t('Faq.description')},

	   { property: 'og:type', content: 'faq'},

	   { property: 'og:url', content: this.$store.state.webUrl + '/faq'},

	

	   { name: 'twitter:title', content: this.companyName + " | FAQ"},

	   { name: 'twitter:description', content: this.$t('Faq.description')},

	   { name: 'twitter:card', content: 'faq'},

	   { name: 'twitter:url', content: this.$store.state.webUrl + '/faq'}

	  ]

	}

	},
	computed: {
	
		selectedLang: function() { 		
	return this.$store.state.selectedLang

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
	},
	watch: {
	
		selectedLang: function() { 		
	this.getFaqs()

}
,
	},
	data(){
	return {
		
		faqs:  ({})		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Faq{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.basic0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.flow011{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:10px ; background-color: transparent ; 
				padding-top:50px ; 
				padding-bottom:50px ; 
				padding-left:5% ; 
				padding-right:5% ; 

					.text.textflow011{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#4b4b4b ; 
					font-size:25px ; 
					text-align:center;
					display: flex;
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

					.column.columnflow013{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;background-color: transparent ; 

					
}

					.column.columnflow015{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					width:100%!important ; 
					height:auto!important ; 
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:10px ; background-color: transparent ; 

						.object.accordillonitemcolumnflow0151{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column01{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.flow011{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textflow011{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.column.columnflow013{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

					
}

					.column.columnflow015{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.accordillonitemcolumnflow0151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column01{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.flow011{

				
				position:relative !important; 
				
				
				
				
				
				
				
				
				padding-top:70px ; 
				padding-bottom:70px ; 
				padding-left:45px ; 
				padding-right:45px ; 

					.text.textflow011{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.column.columnflow013{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

					
}

					.column.columnflow015{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.accordillonitemcolumnflow0151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column01{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.flow011{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textflow011{

					
					position:relative !important; 
					
					
					
					
					
					font-size:55px ; 
					.text-content{
					width: 100%;
					}

					
}

					.column.columnflow013{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

					
}

					.column.columnflow015{

					
					position:relative !important; 
					
					
					
					
					width:75%!important ; 
					
					
					
					

						.object.accordillonitemcolumnflow0151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column01{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.flow011{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textflow011{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.column.columnflow013{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

					
}

					.column.columnflow015{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.accordillonitemcolumnflow0151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.basic0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatebasic01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column01{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentwrappercolumnslottemplatedefaultlayout0151392{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.flow011{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textflow011{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.column.columnflow013{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

					
}

					.column.columnflow015{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.accordillonitemcolumnflow0151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
