<template>
 <div>  
<div id="CallToActionSection"> 
         
 <ContentWrapper :class="'object contentWrapper222 '"   id="contentWrapper222" ref="contentWrapper222"  v-bind:style="{}" :paddingTop = "120" 
 :paddingBottom = "120" 
 :paddingLeft = "16" 
 :paddingRight = "16" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <v-row  :class="'grid grid01 '"   id="grid01" ref="grid01"  v-bind:style="{}">
 <v-col  :class="'col colgrid01 '"   id="colgrid01" ref="colgrid01"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <MiniCallToAction :class="'object minicalltoactioncolgrid011 '"   id="minicalltoactioncolgrid011" ref="minicalltoactioncolgrid011"  v-bind:style="{}" :image = "services[0].image" 
 :title = "services[0].title" 
>
 <template  v-slot:actionItem>
 <div  :class="'slottemplate slottemplateminicalltoactioncolgrid0111 '"   id="slottemplateminicalltoactioncolgrid0111" ref="slottemplateminicalltoactioncolgrid0111"  v-bind:style="{}">
 <v-btn tag="div" 
 :outlined = "true" 
 :rounded = "true" 
 :text = "false" 
 :class="'button buttonslottemplateminicalltoactioncolgrid01111 ' + ' ' + 'topicBtn'"   id="buttonslottemplateminicalltoactioncolgrid01111" ref="buttonslottemplateminicalltoactioncolgrid01111" :_fontWeight = "'600'" 
  v-bind:style="{fontWeight : '600',}"><span>{{ 'Find people you know' }}</span>
</v-btn></div></template>
</MiniCallToAction></div></v-col>
 <v-col  :class="'col colgrid012 '"   id="colgrid012" ref="colgrid012"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <MiniCallToAction :class="'object minicalltoactioncolgrid0111 '"   id="minicalltoactioncolgrid0111" ref="minicalltoactioncolgrid0111"  v-bind:style="{}" :image = "services[1].image" 
 :title = "services[1].title" 
>
</MiniCallToAction></div></v-col>  </v-row></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../../At/Utils/ContentWrapper'
import MiniCallToAction from '../../../At/Components/HomePage/MiniCallToAction'

import * as Main from '../../../Js/main'
export default {
	name: 'CallToActionSection',

    components: {ContentWrapper,MiniCallToAction,},
	computed: {
	
		services: function() { 		
	if(this.info == null){

		return [	

			{

				title: this.$t('Home.connectWP'),

				image: "https://static-exp1.licdn.com/aero-v1/sc/h/b1fxwht7hdbeusleja7ciftsj"

			},

			{

				title: this.$t('Home.learnSkills'),

				image: "https://static-exp1.licdn.com/aero-v1/sc/h/b1fxwht7hdbeusleja7ciftsj",

			}

		]

	}

	

	return [

		{

			title: this.$t('Home.connectWP'),

			image: Main.getImgUrl(this.info.serviceImage2?.data.attributes)

		},

		{

			title: this.$t('Home.learnSkills'),

			image: Main.getImgUrl(this.info.serviceImage6?.data.attributes),

		},

	]

}
,
	},
	props: {
	
		info: {					required: false,			default: () =>  ({}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#CallToActionSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.topicBtn{
	max-width: 100%;	
		
	::v-deep .v-btn__content{
		max-width: 100%;	
		
		span{
			max-width: 100%;
			white-space: nowrap;
		  text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}



.object.contentWrapper222{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.grid01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	background-color: transparent ; 

		.col.colgrid01{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid011{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateminicalltoactioncolgrid0111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.button.buttonslottemplateminicalltoactioncolgrid01111{

					height: auto;
					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					height:56px!important ; 
					fontWeight:600 !important; 
					
					font-size:20px ; 
					text-transform:unset ; 
					min-width: auto;
					padding-left: 16px;
					padding-right: 16px;

					i.v-icon{
					font-size:20px ; 
					}
					}

				
}

			
}

		
}

		
}

		.col.colgrid012{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid0111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.grid01{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgrid01{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateminicalltoactioncolgrid0111{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.button.buttonslottemplateminicalltoactioncolgrid01111{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

		
}

		.col.colgrid012{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid0111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.grid01{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgrid01{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateminicalltoactioncolgrid0111{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.button.buttonslottemplateminicalltoactioncolgrid01111{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

		
}

		.col.colgrid012{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid0111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.grid01{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgrid01{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateminicalltoactioncolgrid0111{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.button.buttonslottemplateminicalltoactioncolgrid01111{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

		
}

		.col.colgrid012{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid0111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.grid01{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgrid01{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateminicalltoactioncolgrid0111{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.button.buttonslottemplateminicalltoactioncolgrid01111{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

		
}

		.col.colgrid012{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid0111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.grid01{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgrid01{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid011{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateminicalltoactioncolgrid0111{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.button.buttonslottemplateminicalltoactioncolgrid01111{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

		
}

		.col.colgrid012{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.minicalltoactioncolgrid0111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}
}
</style>
