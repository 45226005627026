<template>
 <div>  
<div id="AdminProjectForm"> 
         
 <div  :class="'column columncolumncolumn0712 '"   id="columncolumncolumn0712" ref="columncolumncolumn0712"  v-bind:style="{}">
 <v-row  :class="'grid gridcolumncolumncolumn07129 '"   id="gridcolumncolumncolumn07129" ref="gridcolumncolumncolumn07129"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumncolumn0712957 '"   id="colgridcolumncolumncolumn0712957" ref="colgridcolumncolumncolumn0712957"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.name') + '*'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal0113111 '"   id="textfieldcolumnslottemplatedialogboxmodal0113111" ref="textfieldcolumnslottemplatedialogboxmodal0113111" v-model="name"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712954 '"   id="colgridcolumncolumncolumn0712954" ref="colgridcolumncolumncolumn0712954"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "'slug*'" 
 :append-icon = "'ion-md-refresh'" 
 :dense = "true" 
 :type = "'text'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131131 '"   id="textfieldcolumnslottemplatedialogboxmodal01131131" ref="textfieldcolumnslottemplatedialogboxmodal01131131" v-model="slug"  v-bind:style="{color : primaryColor,}" @click:append="generateSlug"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071295 '"   id="colgridcolumncolumncolumn071295" ref="colgridcolumncolumncolumn071295"  v-bind:style="{}" :cols = "12" 
><div class="col-container">
 <div  :class="'column columncolumncolumncolumn07129 '"   id="columncolumncolumncolumn07129" ref="columncolumncolumncolumn07129"  v-bind:style="{}">
 <div  :class="'text textcolumncolumncolumncolumn071291 '"   id="textcolumncolumncolumncolumn071291" ref="textcolumncolumncolumncolumn071291"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Admin.description') + '*' }}</div>

   </div>
 <VueEditor :class="'importedobject textarearectanglecolumncolumnslottemplatedialogboxmodal01159142 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal01159142" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal01159142" v-model="description" :editorToolbar = "customToolbar" 
  v-bind:style="{}">
</VueEditor>  </div></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071291 '"   id="colgridcolumncolumncolumn071291" ref="colgridcolumncolumncolumn071291"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setLogo" 
 :title = "'logo*'" 
 :type = "'image'" 
 :item = "logo" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712918 '"   id="colgridcolumncolumncolumn0712918" ref="colgridcolumncolumncolumn0712918"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setImageDescription" 
 :title = "$t('Admin.imageDescription')" 
 :type = "'image'" 
 :item = "imageDescription" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071295611 '"   id="colgridcolumncolumncolumn071295611" ref="colgridcolumncolumncolumn071295611"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.location') + '*'" 
 :dense = "true" 
 :type = "'text'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="location"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712955 '"   id="colgridcolumncolumncolumn0712955" ref="colgridcolumncolumncolumn0712955"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-textarea  :label = "$t('Admin.gpsLocation')" 
 :auto-grow = "true" 
 filled :class="'textarea textarearectanglecolumncolumnslottemplatedialogboxmodal01159141 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal01159141" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal01159141" v-model="gpsLocation"  v-bind:style="{}"></v-textarea></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071295613 '"   id="colgridcolumncolumncolumn071295613" ref="colgridcolumncolumncolumn071295613"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.email') + '*'" 
 :dense = "true" 
 :type = "'email'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="email"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn07129568 '"   id="colgridcolumncolumncolumn07129568" ref="colgridcolumncolumncolumn07129568"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.facebookLink')" 
 :dense = "true" 
 :type = "'url'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="facebookLink"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn07129569 '"   id="colgridcolumncolumncolumn07129569" ref="colgridcolumncolumncolumn07129569"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.instagramLink')" 
 :dense = "true" 
 :type = "'url'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="instagramLink"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712956913 '"   id="colgridcolumncolumncolumn0712956913" ref="colgridcolumncolumncolumn0712956913"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.twitterLink')" 
 :dense = "true" 
 :type = "'url'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="twitterLink"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712956914 '"   id="colgridcolumncolumncolumn0712956914" ref="colgridcolumncolumncolumn0712956914"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.linkedinLink')" 
 :dense = "true" 
 :type = "'url'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="linkedinLink"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712956915 '"   id="colgridcolumncolumncolumn0712956915" ref="colgridcolumncolumncolumn0712956915"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.snapchatLink')" 
 :dense = "true" 
 :type = "'url'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="snapchatLink"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712956916 '"   id="colgridcolumncolumncolumn0712956916" ref="colgridcolumncolumncolumn0712956916"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.whatsappLink')" 
 :dense = "true" 
 :type = "'url'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="whatsappLink"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071295610 '"   id="colgridcolumncolumncolumn071295610" ref="colgridcolumncolumncolumn071295610"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.websiteLink')" 
 :dense = "true" 
 :type = "'url'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131151 '"   id="textfieldcolumnslottemplatedialogboxmodal01131151" ref="textfieldcolumnslottemplatedialogboxmodal01131151" v-model="websiteLink"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712931 '"   id="colgridcolumncolumncolumn0712931" ref="colgridcolumncolumncolumn0712931"  v-bind:style="{}" :cols = "12" 
><div class="col-container">
 <AdminComboView :class="'object admincomboviewcolgridcolumncolumncolumn07129311 '"   id="admincomboviewcolgridcolumncolumncolumn07129311" ref="admincomboviewcolgridcolumncolumncolumn07129311"  v-bind:style="{}" :updateModel = "setPage" 
 :tableName = "'pages'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "filtersForGetPages" 
 :label = "$t('Admin.webSite')" 
 :p_model = "page" 
>
</AdminComboView></div></v-col>  </v-row>
 <div  :class="'text errorText '"   id="errorText" ref="errorText" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import AdminMediaPicker from '../../../../At/Components/AdminPage/AdminMediaPicker'
import AdminComboView from '../../../../At/Components/AdminPage/AdminComboView'

import * as Main from '../../../../Js/main'
import * as Request from '../../../../Js/requests'

import { VueEditor } from "vue2-editor";
export default {
	name: 'AdminProjectForm',

    components: {AdminMediaPicker,AdminComboView,VueEditor,},
	methods: {
	
		loadData: function() { 				
	this.name = this.subEntity?.name

	this.slug = this.subEntity?.slug

	this.description = this.subEntity?.description

	this.location = this.entity?.location

	this.gpsLocation = this.entity?.gpsLocation

	this.email = this.entity?.email

	this.facebookLink = this.entity?.facebookLink

	this.instagramLink = this.entity?.instagramLink

	this.twitterLink = this.entity?.twitterLink

	this.linkedinLink = this.entity?.linkedinLink

	this.snapchatLink = this.entity?.snapchatLink

	this.whatsappLink = this.entity?.whatsappLink

	this.websiteLink = this.entity?.websiteLink

	this.page = this.entity?.page

		

	this.logo = this.entity?.logo.data

	this.imageDescription = this.entity?.imageDescription.data

	

	this.loading = false

}
,
		generateSlug: function() { 		
	if(this.name){

		this.slug = this.name.replaceAll(/\s+/g, '-')

	}

}
,
		setLogo: function(val) { 		
	this.m_logo = val

}
,
		setImageDescription: function(val) { 		
	this.m_imageDescription= val

}
,
		setPage: function(val) { 		
	this.page = val

}
,
		chargeDate: function() { 		
	let data = {}

	

	if(this.name){ data.name = this.name }

	if(this.slug){ data.slug = this.slug }

	if(this.description){ data.description = this.description }

	if(this.location){ data.location = this.location }

	if(this.gpsLocation){ data.gpsLocation = this.gpsLocation }

	if(this.email){ data.email = this.email }

	if(this.facebookLink){ data.facebookLink = this.facebookLink }

	if(this.instagramLink){ data.instagramLink = this.instagramLink }

	if(this.twitterLink){ data.twitterLink = this.twitterLink }

	if(this.linkedinLink){ data.linkedinLink = this.linkedinLink }

	if(this.snapchatLink){ data.snapchatLink = this.snapchatLink }

	if(this.whatsappLink){ data.whatsappLink = this.whatsappLink }

	if(this.websiteLink){ data.websiteLink = this.websiteLink }

	if(this.page){ data.page = this.page }

	

	return data

}
,
		createEntry: function() { 		
	let data = this.chargeDate()

	data.owner = this.$store.state.user.id

	data.publishedAt = new Date().toISOString()

	

  const formData = new FormData();

	

	if(this.localization == 'fr'){ 	

		if(this.m_logo){	

			formData.append(`files.logo`, this.m_logo, this.m_logo.name);

		}

		

		if(this.m_imageDescription){

			formData.append(`files.imageDescription`, this.m_imageDescription, this.m_imageDescription.name);

		}

	} else {

		data.locale = this.localization

	}

	

	formData.append('data', JSON.stringify(data));

	

	Request.createLocalizedEntry(this.tableName, this.entity?.id ,formData)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryCreated')) 

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		updateEntry: function() { 		
	let data = this.chargeDate()

	

	if(this.localization == 'fr'){ 	

			

		if(this.m_logo){	

			data.logo = this.m_logo

		}

		

		if(this.m_imageDescription){

			data.imageDescription = this.m_imageDescription;

		}

	}

	

	Request.updateEntry(this.tableName, this.subEntity.id, data)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryUpdated'))

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		console.log(error)

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true	

	this.$awn.alert(message)

}
,
	},
	mounted(){
			
	this.loadData()

	},
	computed: {
	
		customToolbar: function() { 		
	return [	

	  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

	  ['blockquote', 'code-block'],

	

	  [{ 'header': 1 }, { 'header': 2 }],               // custom button values

	  [{ 'list': 'ordered'}, { 'list': 'bullet' }],

	  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript

	  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

	  [{ 'direction': 'rtl' }],                         // text direction

	

	  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

	  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

	

	  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

	  [{ 'font': [] }],

	  [{ 'align': [] }],

	

	  ['clean']  

	]

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		entityID: function() { 		
	return this.entity?.id

} 
,
		filtersForGetPages: function() { 			
	let filter = {}

	

	filter.owner = {

		id: {

			$eq: this.$store.state.user.id

		}

	}

	

	return filter

}
,
	},
	watch: {
	
		subEntity: function(val) { 		
	this.loading = true

	this.changed = false

	this.loadData()

}
,
		name: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		slug: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		description: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		location: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		gpsLocation: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		email: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		facebookLink: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		instagramLink: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		twitterLink: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		linkedinLink: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		snapchatLink: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		whatsappLink: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		websiteLink: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		page: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_logo: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_imageDescription: function(val) { 		
	if(!this.loading) this.changed = true

}
,
	},
	data(){
	return {
		
		name:  null		,
		slug:  null		,
		description:  null		,
		location:  null		,
		gpsLocation:  null		,
		email:  null		,
		facebookLink:  null		,
		instagramLink:  null		,
		twitterLink:  null		,
		linkedinLink:  null		,
		snapchatLink:  null		,
		whatsappLink:  null		,
		websiteLink:  null		,
		page:  null		,
		logo:  null		,
		imageDescription:  null		,
		m_logo:  null		,
		m_imageDescription:  null		,
		loading:  true		,
		changed:  false		,
		error:  null		,
		errorVisible:  false		,
		tableName:  'projects'		,
		}
	},
	props: {
	
		entity: {					required: false,			default: () =>  null,		},
		subEntity: {					required: false,			default: () =>  null,		},
		localization: {					required: false,			default: () =>  null,		},
		updateSelectedItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminProjectForm{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.columncolumncolumn0712{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; background-color: transparent ; 

.grid.gridcolumncolumncolumn07129{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolumncolumncolumn07129{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.text.textcolumncolumncolumncolumn071291{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

			.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 5px ; 
			
			margin-top: 5px ; 
			margin-right: 5px ; 
			margin-bottom: 5px ; 
			width: calc(100% - (5px + 5px)) !important;
			height: calc(100% - (5px + 5px)) !important;
			

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:200px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:200px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071295611{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 5px ; 
		
		margin-top: 5px ; 
		margin-right: 5px ; 
		margin-bottom: 5px ; 
		width: calc(100% - (5px + 5px)) !important;
		height: calc(100% - (5px + 5px)) !important;
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295613{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129568{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129569{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956913{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956914{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956915{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956916{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295610{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712931{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

color:#ff1a05 ; 
line-height:10px ; 
font-size:10px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;text-decoration: underline
}


}


}


@media (max-width: 600px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolumncolumncolumn07129{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncolumncolumncolumn071291{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

			
			position:relative !important; 
			
			
			
			
			

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071295611{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295613{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129568{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129569{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956913{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956914{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956915{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956916{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295610{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712931{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolumncolumncolumn07129{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncolumncolumncolumn071291{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

			
			position:relative !important; 
			
			
			
			
			

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071295611{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295613{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129568{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129569{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956913{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956914{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956915{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956916{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295610{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712931{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolumncolumncolumn07129{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncolumncolumncolumn071291{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

			
			position:relative !important; 
			
			
			
			
			

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071295611{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295613{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129568{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129569{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956913{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956914{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956915{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956916{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295610{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712931{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





line-height:15px ; 
font-size:15px ; 
.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolumncolumncolumn07129{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncolumncolumncolumn071291{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

			
			position:relative !important; 
			
			
			
			
			

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071295611{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295613{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129568{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129569{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956913{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956914{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956915{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956916{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295610{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712931{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.column.columncolumncolumn0712{


position:relative !important; 









.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolumncolumncolumn07129{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncolumncolumncolumn071291{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal01159142{

			
			position:relative !important; 
			
			
			
			
			

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712918{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn071295611{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textarea.textarearectanglecolumncolumnslottemplatedialogboxmodal01159141{

		
		position:relative !important; 
		
		
		
		
		
		
::v-deep &.v-input{
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295613{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129568{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn07129569{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956913{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956914{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956915{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712956916{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071295610{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131151{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712931{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.admincomboviewcolgridcolumncolumncolumn07129311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
