<template>
 <div>  
<div id="ForgotPassword"> 
         
 <MainLayout :class="'object defaultlayout0 '"   id="defaultlayout0" ref="defaultlayout0"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{backgroundColor : (mqVal({md:'#f3f2ef'}, $mq)),}">
 <ContentWrapper :class="'object contentWrapper2221 '"   id="contentWrapper2221" ref="contentWrapper2221"  v-bind:style="{}" :paddingTop = "56" 
 :paddingBottom = "56" 
 :paddingLeft = "16" 
 :paddingRight = "16" 
 :bgColor = "'#f0ebe8'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <div  :class="'column columnslottemplatecontentWrapper2211 '"   id="columnslottemplatecontentWrapper2211" ref="columnslottemplatecontentWrapper2211"  v-bind:style="{}">
 <div  :class="'column columncolumnslottemplatecontentWrapper22113 '"   id="columncolumnslottemplatecontentWrapper22113" ref="columncolumnslottemplatecontentWrapper22113"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'text textcolumncolgridslottemplatecontentWrapper11111546 '"   id="textcolumncolgridslottemplatecontentWrapper11111546" ref="textcolumncolgridslottemplatecontentWrapper11111546"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('LogIn.enterMail') }}</div>

   </div>
 <v-text-field  :label = "$t('Signup.email')" 
 :dense = "true" 
 :type = "'email'" 
 outlined :class="'textfield textfieldcolumncolumnslottemplatecontentWrapper2211312 '"   id="textfieldcolumncolumnslottemplatecontentWrapper2211312" ref="textfieldcolumncolumnslottemplatecontentWrapper2211312" v-model="email"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :rounded = "true" 
 :dark = "true" 
 @click="reset" :class="'button buttoncolumncolgridslottemplatecontentWrapper1111173 '"   id="buttoncolumncolgridslottemplatecontentWrapper1111173" ref="buttoncolumncolgridslottemplatecontentWrapper1111173"  v-bind:style="{}"><span>{{ $t('LogIn.reset') }}</span>
</v-btn>
 <div  :class="'text errorMessage65 '"   id="errorMessage65" ref="errorMessage65" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>  </div>
 <div  :class="'flow flowcolumncolumncolumnslottemplatecontentWrapper22113973 '"   id="flowcolumncolumncolumnslottemplatecontentWrapper22113973" ref="flowcolumncolumncolumnslottemplatecontentWrapper22113973"  v-bind:style="{justifyContent : 'center',}">
 <div  :class="'text textflowcolumncolumncolumnslottemplatecontentWrapper22113971 '"   id="textflowcolumncolumncolumnslottemplatecontentWrapper22113971" ref="textflowcolumncolumncolumnslottemplatecontentWrapper22113971"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('LogIn.newToAfro') }}</div>

   </div>
 <router-link  :class="'routerlink linkflowcolumncolumncolumnslottemplatecontentWrapper22113973 '"   id="linkflowcolumncolumncolumnslottemplatecontentWrapper22113973" ref="linkflowcolumncolumncolumnslottemplatecontentWrapper22113973"  v-bind:style="{}" :to = "'signup'" 
>
 <div  :class="'text textflowcolumncolumncolumnslottemplatecontentWrapper221139711 '"   id="textflowcolumncolumncolumnslottemplatecontentWrapper221139711" ref="textflowcolumncolumncolumnslottemplatecontentWrapper221139711"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('LogIn.joinNow') }}</div>

   </div>  </router-link>  </div>  </div></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
export default {
	name: 'ForgotPassword',

    components: {MainLayout,ContentWrapper,},
	methods: {
	
		reset: function() { 		
	// check email

	if(!Main.validateEmail(this.email)){

		this.showError(this.$t('Landing.provideGoodMail'))	

		return

	}

	

	Request.forgotPass(this.email)

	.then(res => {

		this.$awn.success(this.$t('LogIn.verifyMailbox'))

		setTimeout(() => { this.$router.push('/login') }, 5000);

	})

	.catch(error => {

		if ( !error.response.data.message ) {

       this.showError(["Something went wrong"])

   } else {	

	   const messages = error.response.data.message[0].messages;

	

	   const list = [];

	   messages.map((message,i) => {

	       let item = "";

	       if (i === 0) item += `<ul>`;

	

	       item += `<li>${message.id}</li>`;

	

	       if (i === messages.length - 1) item += `</ul>`

	       list.push(item);

	   });

	

	   this.showError(list);

   }

	})

}
,
		showError: function(messages) { 		
	this.error = ""

	

	for(let mes in messages){

		this.error += mes

	}

	this.errorVisible = true	

	this.$awn.alert(this.error)

}
,
	},
	mounted(){
			
	if(this.$store.state.user){

		this.$router.push('/')

	}

	},
	metaInfo(){
			
	return {

		title: this.companyName + " | Forgot password",

		meta: [		

	   { name: 'description', content: this.$t('LogIn.description')},

	

	   { property: 'og:title', content: this.companyName + " | Forgot password"},

	   { property: 'og:description', content: this.$t('LogIn.description')},

	   { property: 'og:type', content: 'website'},

	   { property: 'og:url', content: this.$store.state.webUrl + '/forgot-password'},

	

	   { name: 'twitter:title', content: this.companyName + " | Forgot password"},

	   { name: 'twitter:description', content: this.$t('LogIn.description')},

	   { name: 'twitter:card', content: 'website'},

	   { name: 'twitter:url', content: this.$store.state.webUrl + '/forgot-passwor'}

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		secondColor: function() { 		
	return this.$store.state.secondColor

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
	},
	data(){
	return {
		
		email:  null		,
		errorVisible:  false		,
		error:  "..."		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#ForgotPassword{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#teaserImg{
	position: absolute !important;
	height: 100%;
}

#overlay{
	position: absolute !important;
	height: 100%;		
}



.object.defaultlayout0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:15px ; background-color: transparent ; 

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					width:100%!important ; 
					height:auto!important ; 
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:10px ; 
					background-color:#fff ; 
					border-radius:10px ; 
					padding-top:24px ; 
					padding-bottom:24px ; 
					padding-left:24px ; 
					padding-right:24px ; 

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						font-size:13px ; 
						text-align:center;
						display: flex;
						.text-content{
						width: 100%;
						}

						
}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						width: calc(100% - (0px + 0px)) !important;
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:48px!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						text-transform:unset ; 
						min-width: auto;
						padding-left: 16px;
						padding-right: 16px;

						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						color:#ff1a05 ; 
						line-height:10px ; 
						font-size:10px ; 
						text-align:center;
						display: flex;
						.text-content{
						width: 100%;
						font-weight: bold;text-decoration: underline
						}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: wrap;
					flex: initial;
					gap:8px ; 
					justify-content:center ; background-color:transparent ; 

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						
						width:auto!important ; 
						height:auto!important ; 
						
						font-size:14px ; 
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						
						width:auto!important ; 
						height:auto!important ; 
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							
							width:auto!important ; 
							height:auto!important ; 
							
							font-size:14px ; 
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	
	background-color:#f3f2ef ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					width:400px!important ; 
					
					
					
					

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						line-height:15px ; 
						font-size:15px ; 
						.text-content{
						width: 100%;
						}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2211{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.columncolumnslottemplatecontentWrapper22113{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textcolumncolgridslottemplatecontentWrapper11111546{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.textfield.textfieldcolumncolumnslottemplatecontentWrapper2211312{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

						.text.errorMessage65{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

					.flow.flowcolumncolumncolumnslottemplatecontentWrapper22113973{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textflowcolumncolumncolumnslottemplatecontentWrapper22113971{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.routerlink.linkflowcolumncolumncolumnslottemplatecontentWrapper22113973{

						
						position:relative !important; 
						
						
						
						
						

							.text.textflowcolumncolumncolumnslottemplatecontentWrapper221139711{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
