<template>
 <div>  
<div id="Post"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <MainLayout :class="'object basic01 '"   id="basic01" ref="basic01"  v-bind:style="{}" :currentTab = "3" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatebasic '"   id="slottemplatebasic" ref="slottemplatebasic"  v-bind:style="{}">
 <div  :class="'column mainContent '"   id="mainContent" ref="mainContent"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout01513922 '"   id="contentwrappercolumnslottemplatedefaultlayout01513922" ref="contentwrappercolumnslottemplatedefaultlayout01513922"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column flow011 '"   id="flow011" ref="flow011"  v-bind:style="{}">
 <PostItem :class="'object postitemcolumncoltab01311215111 ' + 'noFit'"   id="postitemcolumncoltab01311215111" ref="postitemcolumncoltab01311215111" v-if="post"  v-bind:style="{}" :share = "showShare" 
 :info = "post" 
 :full = "true" 
>
</PostItem>  </div></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 <ShareModal :class="'object sharemodalitem0522232 '"   id="sharemodalitem0522232" ref="sharemodalitem0522232" v-if="shareModalVi"  v-bind:style="{}" :close = "hideShare" 
 :url = "shareOptions.url" 
 :title = "shareOptions.title" 
 :hashtags = "shareOptions.hashtags" 
 :subject = "shareOptions.subject" 
 :description = "shareOptions.description" 
>
</ShareModal>  </div>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import PostItem from '../../At/Components/FeedPage/PostItem'
import ShareModal from '../../At/Sections/Modal/ShareModal'

import * as Request from '../../Js/requests'
export default {
	name: 'Post',

    components: {MainLayout,ContentWrapper,PostItem,ShareModal,},
	methods: {
	
		getPost: function() { 		
	Request.getTable('posts/' + this.id)

	.then((res) => {

		this.post = res.data.data

		this.post.attributes.id = res.data.data.id

		

		this.shareOptions = {	

			url: this.$store.state.webUrl + "/post/" + this.post.attributes.id, 

			title: 'Afrotiative | Post', 

			hashtags: ['Post', 'Afrotiative'], 

			description: this.post.attributes.message, 

		}

	})

}
,
		showShare: function(shareOptions) { 		
	this.shareOptions = shareOptions

	this.shareModalVi = true;

}
,
		hideShare: function() { 		
	this.shareModalVi = false;

}
,
	},
	mounted(){
			
	this.getPost()

	},
	metaInfo(){
			
	if(this.shareOptions == null) return {}

	

	return {

		title: this.shareOptions.title,

		meta: [		

	   { name: 'description', content: this.shareOptions.description},

	

	   { property: 'og:title', content: this.shareOptions.title},

	   { property: 'og:description', content: this.shareOptions.description},

	   { property: 'og:type', content: 'post'},

	   { property: 'og:url', content: this.shareOptions.url},

	

	   { name: 'twitter:title', content: this.shareOptions.title},

	   { name: 'twitter:description', content: this.shareOptions.description},

	   { name: 'twitter:card', content: 'post'},

	   { name: 'twitter:url', content: this.shareOptions.url},

	  ]

	}

	},
	computed: {
	
		id: function() { 		
	return this.$route.params.id

}
,
	},
	watch: {
	
		id: function(val) { 		
	location.reload();

}
,
	},
	data(){
	return {
		
		post:  null		,
		shareModalVi:  false		,
		shareOptions:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Post{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.link {
	text-decoration: none;
	cursor: pointer;
	
	&:hover {
		text-decoration: underline;
		text-decoration-color: #e4580a;
	}
}

.object{
	::v-deep > div  {
		min-width: unset !important;
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.object.basic01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.mainContent{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		width:98%!important ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 
		padding-top:70px ; 
		padding-bottom:70px ; 

			.object.contentwrappercolumnslottemplatedefaultlayout01513922{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.flow011{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:10px ; background-color: transparent ; 
					padding-top:50px ; 
					padding-bottom:50px ; 
					padding-left:5% ; 
					padding-right:5% ; 

						.object.postitemcolumncoltab01311215111{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem0522232{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.mainContent{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513922{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.flow011{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.postitemcolumncoltab01311215111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem0522232{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.mainContent{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513922{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.flow011{

					
					position:relative !important; 
					
					
					
					
					
					
					
					
					padding-top:70px ; 
					padding-bottom:70px ; 
					padding-left:45px ; 
					padding-right:45px ; 

						.object.postitemcolumncoltab01311215111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem0522232{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.mainContent{

		
		position:relative !important; 
		
		
		
		
		width:90%!important ; 
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513922{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.flow011{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.postitemcolumncoltab01311215111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem0522232{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.mainContent{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513922{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.flow011{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.postitemcolumncoltab01311215111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem0522232{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.mainContent{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513922{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.flow011{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.postitemcolumncoltab01311215111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem0522232{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
