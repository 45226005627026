<template>
 <div>  
<div id="RelativeSection"> 
         
 <ContentWrapper :class="'object contentWrapper222 '"   id="contentWrapper222" ref="contentWrapper222" :_backgroundColor = "bgColor" 
  v-bind:style="{backgroundColor : bgColor,}" :paddingTop = "120" 
 :paddingBottom = "120" 
 :paddingLeft = "16" 
 :paddingRight = "16" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <div  :class="'column columnslottemplatecontentWrapper2211 '"   id="columnslottemplatecontentWrapper2211" ref="columnslottemplatecontentWrapper2211"  v-bind:style="{}">
 <Swiper :class="'importedobject swiperContainer1 '"   id="swiperContainer1" ref="swiperContainer1" :overflow = "'visible'" 
 :options = "swiperOptions" 
  v-bind:style="{}">
 <SwiperSlide :class="'importedobject swiperSlide1 '"   id="swiperSlide1" ref="swiperSlide1" v-for="(item,index) in services" :key="'swiperSlide1' + index"  v-bind:style="{}">
 <RelativeItem :class="'object relativeItem '"   id="relativeItem" ref="relativeItem"  v-bind:style="{}" :title = "item.title" 
 :message = "item.description" 
 :image = "item.image" 
 :maxLengthDesc = "maxLengthDesc" 
>
</RelativeItem>
</SwiperSlide>
 <div  :class="'item prev ' + ' ' + 'swiper-button-prev'"   id="prev" ref="prev" :slot = "'button-prev'" 
 :_left = "(mqVal({default:'0px',md:'-60px'}, $mq))" 
  v-bind:style="{left : (mqVal({default:'0px',md:'-60px'}, $mq)),}">  </div>
 <div  :class="'item next ' + ' ' + 'swiper-button-next'"   id="next" ref="next" :slot = "'button-next'" 
 :_right = "(mqVal({default:'0px',md:'-60px'}, $mq))" 
  v-bind:style="{right : (mqVal({default:'0px',md:'-60px'}, $mq)),}">  </div>
</Swiper>  </div></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../At/Utils/ContentWrapper'
import RelativeItem from '../../At/Components/Relative/RelativeItem'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import * as Main from '../../Js/main'
export default {
	name: 'RelativeSection',

    components: {ContentWrapper,RelativeItem,Swiper,SwiperSlide,},
	computed: {
	
		services: function() { 		
	if(this.info == null){

		return []

	}

	return [

		{

			title: this.info.serviceName1,

			image: Main.getImgUrl(this.info.serviceImage1?.data.attributes),

			description: this.info.serviceDescription1

		},

		{

			title: this.info.serviceName2,

			image: Main.getImgUrl(this.info.serviceImage2?.data.attributes),

			description: this.info.serviceDescription2

		},

		{

			title: this.info.serviceName3,

			image: Main.getImgUrl(this.info.serviceImage3?.data.attributes),

			description: this.info.serviceDescription3

		},

		{

			title: this.info.serviceName4,

			image: Main.getImgUrl(this.info.serviceImage4?.data.attributes),

			description: this.info.serviceDescription4

		},

		{

			title: this.info.serviceName5,

			image: Main.getImgUrl(this.info.serviceImage5?.data.attributes),

			description: this.info.serviceDescription5

		},

		{

			title: this.info.serviceName6,

			image: Main.getImgUrl(this.info.serviceImage6?.data.attributes),

			description: this.info.serviceDescription6

		},

	]

}
,
	},
	data(){
	return {
		
		maxLengthDesc:  300		,
		swiperOptions:  ({		
  spaceBetween: 15,

	navigation: {

    nextEl: '.swiper-button-next',

    prevEl: '.swiper-button-prev'

  }

})
,
		}
	},
	props: {
	
		bgColor: {					required: false,			default: () =>  "#f3f2f0",		},
		info: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#RelativeSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.swiper-button-prev, .swiper-button-next{
	color: #FCBE00;
}



.object.contentWrapper222{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.importedobject.swiperContainer1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		overflow:visible !important; 
		width: calc(100% - (0px + 0px)) !important;
		

			.importedobject.swiperSlide1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:100%!important ; 
			height:auto!important ; 
			

				.object.relativeItem{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.item.prev{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:40px!important ; 
			height:40px!important ; 
			slot:button-prev !important; 
			left:0px !important; 
			

			
}

			.item.next{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:40px!important ; 
			height:40px!important ; 
			slot:button-next !important; 
			right:0px !important; 
			

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.importedobject.swiperContainer1{

		
		position:relative !important; 
		
		
		
		
		

			.importedobject.swiperSlide1{

			
			position:relative !important; 
			
			
			
			
			

				.object.relativeItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.item.prev{

			
			position:absolute !important; 
			
			
			
			
			

			
}

			.item.next{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.importedobject.swiperContainer1{

		
		position:relative !important; 
		
		
		
		
		

			.importedobject.swiperSlide1{

			
			position:relative !important; 
			
			
			
			
			width:50%!important ; 
			

				.object.relativeItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.item.prev{

			
			position:absolute !important; 
			
			
			
			
			

			
}

			.item.next{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.importedobject.swiperContainer1{

		
		position:relative !important; 
		
		
		
		
		

			.importedobject.swiperSlide1{

			
			position:relative !important; 
			
			
			
			
			width:100%!important ; 
			

				.object.relativeItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.item.prev{

			
			position:absolute !important; 
			
			
			
			
			left:-60px !important; 
			

			
}

			.item.next{

			
			position:absolute !important; 
			
			
			
			
			right:-60px !important; 
			

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.importedobject.swiperContainer1{

		
		position:relative !important; 
		
		
		
		
		

			.importedobject.swiperSlide1{

			
			position:relative !important; 
			
			
			
			
			

				.object.relativeItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.item.prev{

			
			position:absolute !important; 
			
			
			
			
			

			
}

			.item.next{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.importedobject.swiperContainer1{

		
		position:relative !important; 
		
		
		
		
		

			.importedobject.swiperSlide1{

			
			position:relative !important; 
			
			
			
			
			

				.object.relativeItem{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.item.prev{

			
			position:absolute !important; 
			
			
			
			
			

			
}

			.item.next{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

	
}


}


}
}
}
</style>
