<template>
 <div>  
<div id="LDTopSection"> 
         
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout01512 '"   id="contentwrappercolumnslottemplatedefaultlayout01512" ref="contentwrappercolumnslottemplatedefaultlayout01512" :_overflow = "'hidden'" 
 :_borderBottom = "'solid 1px #d9d9d9'" 
  v-bind:style="{overflow : 'hidden',borderBottom : 'solid 1px #d9d9d9',}" :bgColor = "'#fff'" 
 :bgImage = "image" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'flow columncolumnslottemplatedefaultlayout0153111 '"   id="columncolumnslottemplatedefaultlayout0153111" ref="columncolumnslottemplatedefaultlayout0153111"  v-bind:style="{}">
 <div  :class="'column columncolumncolumnslottemplatedefaultlayout01531 '"   id="columncolumncolumnslottemplatedefaultlayout01531" ref="columncolumncolumnslottemplatedefaultlayout01531" :_width = "'unset !important'" 
  v-bind:style="{width : 'unset !important',}">
 <a  :href = "'/'" 
 :class="'link linkcolumncolumncolumnslottemplatedefaultlayout015318 '"   id="linkcolumncolumncolumnslottemplatedefaultlayout015318" ref="linkcolumncolumncolumnslottemplatedefaultlayout015318"  v-bind:style="{}">
 <div  :class="'text textcolumncolumncolumnslottemplatedefaultlayout015311 '"   id="textcolumncolumncolumnslottemplatedefaultlayout015311" ref="textcolumncolumncolumnslottemplatedefaultlayout015311"  v-bind:style="{}">
<h1 class="text-content" :style = "{}">{{ name }}</h1>

   </div>  </a>
 <div  :class="'column columncolumncolumncolumnslottemplatedefaultlayout015316 '"   id="columncolumncolumncolumnslottemplatedefaultlayout015316" ref="columncolumncolumncolumnslottemplatedefaultlayout015316"  v-bind:style="{}">
 <div  :class="'flow flowcolumncolumncolumnslottemplatedefaultlayout015315 '"   id="flowcolumncolumncolumnslottemplatedefaultlayout015315" ref="flowcolumncolumncolumnslottemplatedefaultlayout015315"  v-bind:style="{}">
 <div  :class="'text textDate1 '"   id="textDate1" ref="textDate1"  v-bind:style="{color : '#515151',}">
<div class="text-content" :style = "{}">{{ duration }}</div>

   </div>  </div>  </div>
 <v-btn tag="div" 
 :color = "'#323232ba'" 
 :rounded = "false" 
 :text = "true" 
 :dark = "true" 
 @click="showVideoModal" :class="'button previewBtn2 '"   id="previewBtn2" ref="previewBtn2"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-ios-play' !== undefined"  :left = "true" 
>{{ 'ion-ios-play' }}
</v-icon><span>{{ $t('LearningDetails.overview') }}</span>
</v-btn>
 <div  :class="'flow flowcolumncolumncolumnslottemplatedefaultlayout015316 '"   id="flowcolumncolumncolumnslottemplatedefaultlayout015316" ref="flowcolumncolumncolumnslottemplatedefaultlayout015316"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :outlined = "!item.important" 
 :dark = "true" 
 :class="'button actionBtn2 '"   id="actionBtn2" ref="actionBtn2" v-for="(item,index) in actions" :key="'actionBtn2' + index" :_padding = "'10px'" 
 :to = "item.to" 
 :href = "item.href" 
 :target = "item.target" 
  v-bind:style="{padding : '10px',}"><span>{{ item.text }}</span>
</v-btn>  </div>  </div>
 <div  :class="'column columncolumncolumnslottemplatedefaultlayout01531113 '"   id="columncolumncolumnslottemplatedefaultlayout01531113" ref="columncolumncolumnslottemplatedefaultlayout01531113"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#323232ba'" 
 :rounded = "true" 
 :dark = "true" 
 @click="showVideoModal" :class="'button previewBtn1 '"   id="previewBtn1" ref="previewBtn1" :_border = "'solid 2px #fff'" 
  v-bind:style="{border : 'solid 2px #fff',}"><v-icon  :style = "{}"  v-if = "'ion-ios-play' !== undefined"  :left = "true" 
>{{ 'ion-ios-play' }}
</v-icon><span>{{ $t('LearningDetails.overview') }}</span>
</v-btn>  </div>  </div></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../../At/Utils/ContentWrapper'


export default {
	name: 'LDTopSection',

    components: {ContentWrapper,},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		actions: function() { 		
	if(this.isWebinar){

		return [	

			{

				text: this.$t('LearningDetails.registerToWebinar'),

				href: this.url,

				important: true,

				target: "_self"

			}

		]	

	}

	

	return [	

		{

			text: this.$t('LearningDetails.seeCours'),

			href: this.url,

			important: true,

				target: "_blank"

		}

	]	

}
,
	},
	props: {
	
		image: {					required: false,			default: () =>  "",		},
		name: {					required: false,			default: () =>  "",		},
		duration: {					required: false,			default: () =>  "",		},
		isWebinar: {			type: Boolean,			required: false,			default: () =>  false,		},
		url: {					required: false,			default: () =>  "",		},
		videoLink: {					required: false,			default: () =>  null,		},
		showVideoModal: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LDTopSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
  color: inherit;
}



.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
overflow:hidden !important; 
borderBottom:solid 1px #d9d9d9 !important; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.columncolumnslottemplatedefaultlayout0153111{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	min-height: 420px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: wrap;
	flex: initial;background-color:transparent ; 
	padding-top:48px ; 
	padding-bottom:48px ; 
	padding-left:12px ; 
	padding-right:12px ; 

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width:unset !important !important; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:16px ; background-color: transparent ; 
		padding-top:64px ; 
		padding-bottom:64px ; 

			.link.linkcolumncolumncolumnslottemplatedefaultlayout015318{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: block;

				.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width:auto!important ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				font-size:20px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

			
}

			.column.columncolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:8px ; background-color: transparent ; 

				.flow.flowcolumncolumncolumnslottemplatedefaultlayout015315{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: wrap;
				flex: initial;
				gap:5px ; background-color:transparent ; 

					.text.textDate1{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:auto!important ; 
					
					color:#515151 ; 
					font-size:20px ; 
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.previewBtn2{

			height: auto;
			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			 display:flex !important; 
			width:auto!important ; 
			height:46px!important ; 
			
			border-radius:0px ; 
			font-size:25px ; 
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			font-size:25px ; 
			}
			}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: wrap;
			flex: initial;
			gap:20px ; background-color:transparent ; 

				.button.actionBtn2{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:100%!important ; 
				height:48px!important ; 
				padding:10px !important; 
				
				border-radius:0px ; 
				font-size:15px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:15px ; 
				}
				}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531113{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		right: 0px ; 
		display:none !important; 
		width:0px!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.button.previewBtn1{

			height: auto;
			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			width:auto!important ; 
			height:46px!important ; 
			border:solid 2px #fff !important; 
			
			font-size:25px ; 
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			font-size:25px ; 
			}
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.columncolumnslottemplatedefaultlayout0153111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.link.linkcolumncolumncolumnslottemplatedefaultlayout015318{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.column.columncolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowcolumncolumncolumnslottemplatedefaultlayout015315{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textDate1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.previewBtn2{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn2{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.button.previewBtn1{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.columncolumnslottemplatedefaultlayout0153111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.link.linkcolumncolumncolumnslottemplatedefaultlayout015318{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.column.columncolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowcolumncolumncolumnslottemplatedefaultlayout015315{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textDate1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.previewBtn2{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn2{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.button.previewBtn1{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.columncolumnslottemplatedefaultlayout0153111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		margin-right: 385px ; 
		
		
		
		
		padding-top:0px ; 
		padding-bottom:0px ; 

			.link.linkcolumncolumncolumnslottemplatedefaultlayout015318{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

				
				position:relative !important; 
				
				
				
				
				
				font-size:35px ; 
				.text-content{
				width: 100%;
				}

				
}

			
}

			.column.columncolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowcolumncolumncolumnslottemplatedefaultlayout015315{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textDate1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.previewBtn2{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			display:none !important; 
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn2{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				width:auto!important ; 
				
				font-size:20px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:20px ; 
				}
				}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531113{

		
		position:absolute !important; 
		
		
		
		
		 display:flex !important; 
		width:385px!important ; 
		
		
		
		

			.button.previewBtn1{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.columncolumnslottemplatedefaultlayout0153111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.link.linkcolumncolumncolumnslottemplatedefaultlayout015318{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.column.columncolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowcolumncolumncolumnslottemplatedefaultlayout015315{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textDate1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.previewBtn2{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn2{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.button.previewBtn1{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentwrappercolumnslottemplatedefaultlayout01512{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.columncolumnslottemplatedefaultlayout0153111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumnslottemplatedefaultlayout01531{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.link.linkcolumncolumncolumnslottemplatedefaultlayout015318{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.text.textcolumncolumncolumnslottemplatedefaultlayout015311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.column.columncolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowcolumncolumncolumnslottemplatedefaultlayout015315{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textDate1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.button.previewBtn2{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.flow.flowcolumncolumncolumnslottemplatedefaultlayout015316{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn2{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columncolumncolumnslottemplatedefaultlayout01531113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.button.previewBtn1{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}
}
</style>
