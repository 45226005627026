<template>
 <div>  
<div id="MiniCallToAction"> 
         
 <v-row  :class="'grid grid0 '"   id="grid0" ref="grid0"  v-bind:style="{}">
 <v-col  :class="'col colgrid01 '"   id="colgrid01" ref="colgrid01"  v-bind:style="{}" :cols = "12" 
 :md = "12" 
 :sm = "6" 
><div class="col-container">
 <v-img  :class="'networkimage networkimagecolgrid011 '"   id="networkimagecolgrid011" ref="networkimagecolgrid011"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "image" 
>  </v-img></div></v-col>
 <v-col  :class="'col colgrid012 '"   id="colgrid012" ref="colgrid012"  v-bind:style="{}" :cols = "12" 
 :md = "12" 
 :sm = "6" 
><div class="col-container">
 <div  :class="'column columncolgrid0121 '"   id="columncolgrid0121" ref="columncolgrid0121"  v-bind:style="{}">
 <div  :class="'text textcolumncolgrid01211 '"   id="textcolumncolgrid01211" ref="textcolumncolgrid01211"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <div  :class="'slot slotcolumncolgrid01213 '"   id="slotcolumncolgrid01213" ref="slotcolumncolgrid01213"  v-bind:style="{}"><slot  :name = "'actionItem'" 
></slot></div>  </div></div></v-col>  </v-row>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'MiniCallToAction',

    components: {},
	props: {
	
		image: {					required: false,			default: () =>  "https://static-exp1.licdn.com/aero-v1/sc/h/b1fxwht7hdbeusleja7ciftsj",		},
		title: {					required: false,			default: () =>  "Connect with people who can help",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#MiniCallToAction{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.topicBtn{
	max-width: 100%;	
		
	::v-deep .v-btn__content{
		max-width: 100%;	
		
		span{
			max-width: 100%;
			white-space: nowrap;
		  text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}



.grid.grid0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

.col.colgrid01{


position:relative !important; 
margin: unset;
transform-origin: top left;



height:auto!important ; 


::v-deep .col-container{position: relative;

height: auto;

width: 100%;

	.networkimage.networkimagecolgrid011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	height:auto!important ; 
	max-width: 240px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}


}


}

.col.colgrid012{


position:relative !important; 
margin: unset;
transform-origin: top left;



height:auto!important ; 


::v-deep .col-container{position: relative;

height: auto;

width: 100%;

	.column.columncolgrid0121{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:8px ; background-color: transparent ; 

		.text.textcolumncolgrid01211{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		line-height:20px ; 
		font-size:20px ; 
		.text-content{
		width: 100%;
		}

		
}

		.slot.slotcolumncolgrid01213{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		}

	
}


}


}


}


@media (max-width: 600px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.networkimagecolgrid011{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid0121{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid01211{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.slot.slotcolumncolgrid01213{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}


}
}

@media (min-width: 601px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.networkimagecolgrid011{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid0121{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid01211{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.slot.slotcolumncolgrid01213{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}


}
}

@media (min-width: 961px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.networkimagecolgrid011{

	
	position:relative !important; 
	
	
	
	
	max-width: 300px!important ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid0121{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid01211{

		
		position:relative !important; 
		
		
		
		
		
		line-height:45px ; 
		font-size:45px ; 
		.text-content{
		width: 100%;
		}

		
}

		.slot.slotcolumncolgrid01213{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}


}
}

@media (min-width: 1265px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.networkimagecolgrid011{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid0121{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid01211{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.slot.slotcolumncolgrid01213{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}


}
}

@media (min-width: 1905px) {


.grid.grid0{


position:relative !important; 






.col.colgrid01{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.networkimage.networkimagecolgrid011{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}


}

.col.colgrid012{


position:relative !important; 





::v-deep .col-container{position: relative;

height: 100%;

width: 100%;

	.column.columncolgrid0121{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgrid01211{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.slot.slotcolumncolgrid01213{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}


}
}
}
</style>
