import Vue from 'vue'
import VueI18n from 'vue-i18n'

import enLocale from 'element-ui/lib/locale/lang/en'
import frLocale from 'element-ui/lib/locale/lang/fr'
import deLocale from 'element-ui/lib/locale/lang/de'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  messages.fr = {...messages.fr, ...frLocale}
  messages.en = {...messages.en, ...enLocale}
  messages.de = {...messages.de, ...deLocale}
  return messages
}

const i18n = new VueI18n({
  locale: "fr",//<= inject_i18n_locale =>
  fallbackLocale: "en",//<= inject_i18n_fallback =>
  messages: loadLocaleMessages()
})

export default i18n;
