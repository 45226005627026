<template>
 <div>  
<div id="Logo"> 
         
 <router-link  :class="'routerlink routerlink0 '"   id="routerlink0" ref="routerlink0"  v-bind:style="{}" :to = "'/'" 
>
 <div  :class="'row rowrowslottemplatecontentwrapper011221 '"   id="rowrowslottemplatecontentwrapper011221" ref="rowrowslottemplatecontentwrapper011221"  v-bind:style="{height : getUnit(size,'px'),}">
 <v-img  :class="'image imagerowslottemplatecontentwrapper0111 '"   id="imagerowslottemplatecontentwrapper0111" ref="imagerowslottemplatecontentwrapper0111"  v-bind:style="{width : getUnit(size,'px'),height : getUnit(size,'px'), backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/afrotiative.png')" 
>  </v-img>
 <div  :class="'text textrowrowslottemplatecontentwrapper01123 '"   id="textrowrowslottemplatecontentwrapper01123" ref="textrowrowslottemplatecontentwrapper01123" v-if="showName"  v-bind:style="{color : primaryColor,fontSize : getUnit(size/2,'px'),}">
<div class="text-content" :style = "{}">{{ companyName }}</div>

   </div>  </div>  </router-link>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'Logo',

    components: {},
	computed: {
	
		companyName: function() { 		
	return this.$store.state.companyName

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		showName: function() { 		
	return !this.mini

}
,
	},
	props: {
	
		mini: {			type: Boolean,			required: false,			default: () =>  false,		},
		size: {					required: false,			default: () =>  50,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Logo{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
}



.routerlink.routerlink0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:auto!important ; 


.row.rowrowslottemplatecontentwrapper011221{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:5px ; background-color:transparent ; 

	.image.imagerowslottemplatecontentwrapper0111{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}

	.text.textrowrowslottemplatecontentwrapper01123{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;
	}

	
}


}


}


@media (max-width: 600px) {


.routerlink.routerlink0{


position:relative !important; 






.row.rowrowslottemplatecontentwrapper011221{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.image.imagerowslottemplatecontentwrapper0111{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrowrowslottemplatecontentwrapper01123{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 601px) {


.routerlink.routerlink0{


position:relative !important; 






.row.rowrowslottemplatecontentwrapper011221{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.image.imagerowslottemplatecontentwrapper0111{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrowrowslottemplatecontentwrapper01123{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 961px) {


.routerlink.routerlink0{


position:relative !important; 






.row.rowrowslottemplatecontentwrapper011221{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.image.imagerowslottemplatecontentwrapper0111{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrowrowslottemplatecontentwrapper01123{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1265px) {


.routerlink.routerlink0{


position:relative !important; 






.row.rowrowslottemplatecontentwrapper011221{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.image.imagerowslottemplatecontentwrapper0111{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrowrowslottemplatecontentwrapper01123{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1905px) {


.routerlink.routerlink0{


position:relative !important; 






.row.rowrowslottemplatecontentwrapper011221{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.image.imagerowslottemplatecontentwrapper0111{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrowrowslottemplatecontentwrapper01123{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}
}
</style>
