<template>
 <div>  
<div id="MiniPreview"> 
         
 <router-link  :class="'routerlink routerlink0 '"   id="routerlink0" ref="routerlink0"  v-bind:style="{}" :to = "m_link" 
>
 <v-card 
 :class="'card card01 '"   id="card01" ref="card01" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',}">
 <v-img  :class="'networkimage networkimagecard01 '"   id="networkimagecard01" ref="networkimagecard01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "m_image" 
>  </v-img>
 <div  :class="'rectangle rectanglecard07 '"   id="rectanglecard07" ref="rectanglecard07"  v-bind:style="{backgroundColor : '#000',}">  </div>
 <div  :class="'rectangle rectanglecard03 '"   id="rectanglecard03" ref="rectanglecard03" v-if="instructor"  v-bind:style="{backgroundColor : 'transparent',borderColor : primaryColor,}">
 <v-img  :class="'networkimage networkimagerectanglecard031 '"   id="networkimagerectanglecard031" ref="networkimagerectanglecard031"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "instructorImage" 
>  </v-img>  </div>
 <div  :class="'text textcard05 '"   id="textcard05" ref="textcard05"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ m_name }}</div>

   </div>  </v-card>  </router-link>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
export default {
	name: 'MiniPreview',

    components: {},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		id: function(){		
	if(this.info){

		return this.info.id

	}

	return null

}
,
		attributes: function(){		
	if(this.info){

		return this.info.attributes

	}

	return null

}
,
		image: function() { 		
	if(this.attributes){

		if(this.attributes.image.data){

			let item = this.attributes.image.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return "https://picsum.photos/200"

	}

	return "https://picsum.photos/200"

}
,
		moodle_image: function() { 		
	if(this.moodleInfo != undefined && this.moodleInfo.overviewfiles != undefined){

		if(this.moodleInfo.overviewfiles.length > 0){

			return Main.getMoodleAssets(this.moodleInfo.overviewfiles[0].fileurl)

		}

		return "https://picsum.photos/200"

	}

	return "https://picsum.photos/200"

}
,
		m_image: function() { 		
	if(this.fromMoodle){

		return this.moodle_image

	}

	return this.image

}
,
		name: function() { 		
	if(this.attributes){

		return this.attributes.name

	}

	return null

}
,
		moodle_name: function() { 		
	if(this.moodleInfo){

		return this.moodleInfo.displayname

	}

	return ""

}
,
		m_name: function() { 		
	if(this.fromMoodle){

		return this.moodle_name

	}

	return this.name

}
,
		instructor: function() { 		
	if(this.attributes){

		if(this.attributes.instructor){

			return this.attributes.instructor.data?.attributes

		}

		return null

	}

	return null

}
,
		instructorImage: function() { 		
	if(this.instructor){

		if(this.instructor.image){

			let item = this.instructor.image.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return "https://picsum.photos/500"

	}

	return "https://picsum.photos/500"

}
,
		baseLink: function() { 		
	return "/" + this.baseUrl + "/"

}
,
		link: function() { 		
	if(this.attributes){

		return this.baseLink + this.attributes.slug

	}

	return this.baseLink

}
,
		moodle_link: function() { 		
	if(this.moodleInfo){

		return this.baseLink + "m/" + this.moodleInfo.id

	}

	return this.baseLink

}
,
		m_link: function() { 		
	if(this.fromMoodle){

		return this.moodle_link

	}

	return this.link

}
,
	},
	props: {
	
		info: {					required: false,			default: () =>  null,		},
		moodleInfo: {					required: false,			default: () =>  null,		},
		fromMoodle: {			type: Boolean,			required: false,			default: () =>  false,		},
		baseUrl: {					required: false,			default: () =>  "",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#MiniPreview{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.routerlink.routerlink0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.card.card01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
overflow:hidden !important; 
background-color:#ffffff ; max-width: initial;min-width: initial;

	.networkimage.networkimagecard01{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	border-radius:4px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	opacity: 0.3 ;  filter: alpha(opacity=30) ; 
	
	background-color:#000 ; 

	
}

	.rectangle.rectanglecard03{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 10px ; 
	
	top: 10px ; 
	width:46px!important ; 
	height:46px!important ; 
	
	background-color:transparent ; 
	border-radius:46px ; 
	border-style:solid ; 
	border-width:3px ; 
	border-color:undefined ; 

		.networkimage.networkimagerectanglecard031{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
		
		left: calc(50% + 0px) ; 
		
		top: calc(50% + 0px) ; 
		width:40px!important ; 
		height:40px!important ; 
		
		display: inherit;

		flex: unset;
		border-radius:20px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 10px ; 
	
	right: 10px ; 
	bottom: 10px ; 
	height:auto!important ; 
	
	color:#fff ; 
	font-size:13px ; 
	.text-content{
	width: 100%;
	}

	
}


}


}


@media (max-width: 600px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectanglecard03{

	
	position:absolute !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglecard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 601px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectanglecard03{

	
	position:absolute !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglecard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 961px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectanglecard03{

	
	position:absolute !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglecard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1265px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectanglecard03{

	
	position:absolute !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglecard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1905px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectanglecard03{

	
	position:absolute !important; 
	
	
	
	
	
	

		.networkimage.networkimagerectanglecard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}
}
</style>
