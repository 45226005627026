<template>
 <div>  
<div id="SessionItem"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'row rowcolumn01 '"   id="rowcolumn01" ref="rowcolumn01" :_alignItems = "'center'" 
  v-bind:style="{alignItems : 'center',justifyContent : 'space-between',}">
 <div  :class="'column columnrowcolumn011 '"   id="columnrowcolumn011" ref="columnrowcolumn011"  v-bind:style="{}">
 <div  :class="'row rowcolumnrowcolumn0111 '"   id="rowcolumnrowcolumn0111" ref="rowcolumnrowcolumn0111"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerowcolumnrowcolumn01111 '"   id="networkimagerowcolumnrowcolumn01111" ref="networkimagerowcolumnrowcolumn01111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "'https://api.iconify.design/material-symbols:calendar-month-sharp.svg?color=%23424242'" 
>  </v-img>
 <div  :class="'text textrowcolumnrowcolumn01113 '"   id="textrowcolumnrowcolumn01113" ref="textrowcolumnrowcolumn01113"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ dateDay }}</div>

   </div>  </div>
 <div  :class="'row rowcolumnrowcolumn01112 '"   id="rowcolumnrowcolumn01112" ref="rowcolumnrowcolumn01112"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerowcolumnrowcolumn01111 '"   id="networkimagerowcolumnrowcolumn01111" ref="networkimagerowcolumnrowcolumn01111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "'https://api.iconify.design/material-symbols:av-timer.svg?color=%23424242'" 
>  </v-img>
 <div  :class="'text textrowcolumnrowcolumn01113 '"   id="textrowcolumnrowcolumn01113" ref="textrowcolumnrowcolumn01113"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ hours }}</div>

   </div>  </div>
 <div  :class="'row rowrowcolumn0133 '"   id="rowrowcolumn0133" ref="rowrowcolumn0133"  v-bind:style="{}">
 <div  :class="'icon iconrowrowcolumn0131 '"   id="iconrowrowcolumn0131" ref="iconrowrowcolumn0131"  v-bind:style="{fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-globe'"></i></div>
 <div  :class="'text textrowrowcolumn0133 '"   id="textrowrowcolumn0133" ref="textrowrowcolumn0133"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('RegisterSession.onlineAppointment') }}</div>

   </div>  </div>  </div>
 <div  :class="'row rowrowcolumn013 '"   id="rowrowcolumn013" ref="rowrowcolumn013"  v-bind:style="{}">
 <div  :class="'icon iconrowrowcolumn0131 '"   id="iconrowrowcolumn0131" ref="iconrowrowcolumn0131"  v-bind:style="{fontSize : getUnit(25,'px'),}"><i  :class="'ion-ios-globe'"></i></div>
 <div  :class="'text textrowrowcolumn0133 '"   id="textrowrowcolumn0133" ref="textrowrowcolumn0133"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('RegisterSession.onlineAppointment') }}</div>

   </div>  </div>
 <div  :class="'column columnrowcolumn015 '"   id="columnrowcolumn015" ref="columnrowcolumn015"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :outlined = "true" 
 :disabled = "done" 
 @click="select" :class="'button buttoncolumnrowcolumn0151 '"   id="buttoncolumnrowcolumn0151" ref="buttoncolumnrowcolumn0151" v-if="!alreadyRegistred"  v-bind:style="{}"><span>{{ $t('RegisterSession.choose') }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#d0463e'" 
 :disabled = "done" 
 :dark = "true" 
 @click="remove" :class="'button buttoncolumnrowcolumn01513 '"   id="buttoncolumnrowcolumn01513" ref="buttoncolumnrowcolumn01513" v-if="alreadyRegistred"  v-bind:style="{}"><span>{{ $t('RegisterSession.remove') }}</span>
</v-btn>
 <div  :class="'text textcolumnrowcolumn0153 '"   id="textcolumnrowcolumn0153" ref="textcolumnrowcolumn0153"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ freeSpace }}</div>

   </div>  </div>  </div>
 <div  :class="'rectangle rectanglecolumn03 '"   id="rectanglecolumn03" ref="rectanglecolumn03" v-if="!isLast"  v-bind:style="{backgroundColor : '#5f5f5f',}">  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as dayjs from "dayjs"
import * as Request from '../../../Js/requests'
export default {
	name: 'SessionItem',

    components: {},
	methods: {
	
		select: function() { 		
	this.getSession(_ => {	

		let list = this.listParticipants

		list.push(this.user.id)

		

		this.choose(this.id, list)

	})

}
,
		remove: function() { 		
	this.getSession(_ => {		

		let list = this.listParticipants

		var index = list.indexOf(this.user.id)

		list.splice(index, 1);

		

		this.leave(this.id, list)

	})

}
,
		getSession: function(callback) { 		
	Request.getTable('sessions/' + this.id)

	.then((res) => {

		this.m_info = res.data.data

		if(callback) callback()

	})

}
,
	},
	mounted(){
			
	this.m_info = this.info

	},
	computed: {
	
		localFormat: function() { 		
	switch(this.$store.state.selectedLang){

		case "fr":

			return "fr-FR";

		case "de":

			return "de-DE";

		default:

			return "en-GB"

	}

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		id: function() { 		
	if(this.m_info){

		return this.m_info.id

	}

	return null

}	
,
		date: function() { 		
	if(this.m_info){

		return this.m_info.attributes.date

	}

	return ""

}	
,
		duration: function() { 		
	if(this.m_info){

		return this.m_info.attributes.duration

	}

	return 0

}	
,
		maxPlaces: function() { 		
	if(this.m_info){

		return this.m_info.attributes.maxPlaces

	}

	return 0

}	
,
		done: function() { 		
	if(this.m_info){

		return this.m_info.attributes.done

	}

	return false

}	
,
		participants: function() { 		
	if(this.m_info){

		return this.m_info.attributes.participants.data

	}

	return []

}	
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		dateDay: function() { 		
	var mydate = new Date(this.date);

	return mydate.toDateString(this.localFormat) 

}
,
		hours: function() { 		
	var mydate = new Date(this.date);

	return mydate.toLocaleTimeString(this.localFormat)

}
,
		freeSpace: function() { 		
	let free = this.maxPlaces - this.participants.length

	return free + ' ' + this.$t('RegisterSession.freeSeats')

}
,
		listParticipants: function() { 		
	return this.participants.map(p => p.id)

}
,
		alreadyRegistred: function() { 		
	let list = this.listParticipants

	return list.includes(this.user.id)

}
,
	},
	data(){
	return {
		
		m_info:  null		,
		}
	},
	props: {
	
		info: {					required: false,			default: () =>  null,		},
		sessionID: {					required: false,			default: () =>  null,		},
		isLast: {			type: Boolean,			required: false,			default: () =>  false,		},
		choose: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		leave: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SessionItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

.row.rowcolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
alignItems:center !important; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
justify-content:space-between ; background-color:transparent ; 
padding-top:8px ; 
padding-bottom:8px ; 
padding-left:8px ; 
padding-right:8px ; 

	.column.columnrowcolumn011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:4px ; background-color: transparent ; 

		.row.rowcolumnrowcolumn0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;background-color:transparent ; 

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:20px!important ; 
			height:20px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:20px!important ; 
			
			font-size:10px ; 
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

		
}

		.row.rowcolumnrowcolumn01112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;background-color:transparent ; 

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:20px!important ; 
			height:20px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:20px!important ; 
			
			font-size:10px ; 
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowrowcolumn0133{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		 display:flex !important; 
		width:auto!important ; 
		height:20px!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;background-color:transparent ; 

			.icon.iconrowrowcolumn0131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:20px!important ; 
			height:20px!important ; 
			
			font-size:20px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textrowrowcolumn0133{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:20px!important ; 
			
			font-size:12px ; 
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcolumn013{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	display:none !important; 
	width:auto!important ; 
	height:25px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;background-color:transparent ; 

		.icon.iconrowrowcolumn0131{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:25px!important ; 
		height:25px!important ; 
		
		font-size:25px ; 
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrowrowcolumn0133{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:25px!important ; 
		
		font-size:12px ; 
		align-items:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columnrowcolumn015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:4px ; background-color: transparent ; 

		.button.buttoncolumnrowcolumn0151{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:40px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.button.buttoncolumnrowcolumn01513{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:40px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.text.textcolumnrowcolumn0153{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		font-size:10px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
opacity: 0.1 ;  filter: alpha(opacity=10) ; 
height:1px!important ; 
width: calc(100% - (0px + 0px)) !important;

background-color:#5f5f5f ; 


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.column.columnrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnrowcolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowcolumnrowcolumn01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.icon.iconrowrowcolumn0131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textrowrowcolumn0133{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.icon.iconrowrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columnrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttoncolumnrowcolumn0151{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttoncolumnrowcolumn01513{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textcolumnrowcolumn0153{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.column.columnrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnrowcolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowcolumnrowcolumn01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.icon.iconrowrowcolumn0131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textrowrowcolumn0133{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.icon.iconrowrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columnrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttoncolumnrowcolumn0151{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttoncolumnrowcolumn01513{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textcolumnrowcolumn0153{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.column.columnrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnrowcolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			font-size:12px ; 
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowcolumnrowcolumn01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			font-size:12px ; 
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		display:none !important; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.icon.iconrowrowcolumn0131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textrowrowcolumn0133{

			
			position:relative !important; 
			
			
			
			
			
			font-size:10px ; 
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	 display:flex !important; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.icon.iconrowrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columnrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttoncolumnrowcolumn0151{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttoncolumnrowcolumn01513{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textcolumnrowcolumn0153{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.column.columnrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnrowcolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowcolumnrowcolumn01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.icon.iconrowrowcolumn0131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textrowrowcolumn0133{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.icon.iconrowrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columnrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttoncolumnrowcolumn0151{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttoncolumnrowcolumn01513{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textcolumnrowcolumn0153{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.column.columnrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumnrowcolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowcolumnrowcolumn01112{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimagerowcolumnrowcolumn01111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textrowcolumnrowcolumn01113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.icon.iconrowrowcolumn0131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textrowrowcolumn0133{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.icon.iconrowrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrowrowcolumn0133{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columnrowcolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttoncolumnrowcolumn0151{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttoncolumnrowcolumn01513{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textcolumnrowcolumn0153{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectanglecolumn03{


position:relative !important; 








}


}
}
}
</style>
