<template>
 <div>  
<div id="FeedLeftSection"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'column columncolumn05 '"   id="columncolumn05" ref="columncolumn05"  v-bind:style="{}">
 <div  :class="'text textcolumncolumn051 '"   id="textcolumncolumn051" ref="textcolumncolumn051"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Feed.users') }}</div>

   </div>
 <v-row  :class="'grid grid0 '"   id="grid0" ref="grid0"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumn0551 '"   id="colgridcolumncolumn0551" ref="colgridcolumncolumn0551" v-for="(item,index) in users" :key="'colgridcolumncolumn0551' + index"  v-bind:style="{}" :cols = "12" 
 :md = "12" 
 :sm = "6" 
><div class="col-container">
 <ProfileItem :class="'object profileitemcolgridmainContent311211 '"   id="profileitemcolgridmainContent311211" ref="profileitemcolgridmainContent311211"  v-bind:style="{}" :user = "item" 
>
</ProfileItem></div></v-col>  </v-row>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import ProfileItem from '../../../At/Components/ProfileItem'

import * as Request from '../../../Js/requests'
export default {
	name: 'FeedLeftSection',

    components: {ProfileItem,},
	methods: {
	
		getUsers: function() {		
	let config = {

		filters: {	

	    id: {

				$ne: this.user.id,

			}	

		},

		sort: ['id:desc']

	}

	

	Request.getTableArgs('users', config)

	.then((res) => {

		this.users = res.data.slice(0, 3)

	}) 

}
,
	},
	mounted(){
			
	this.getUsers()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		user: function() { 		
	return this.$store.state.user

}
,
	},
	data(){
	return {
		
		users:  []		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#FeedLeftSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:18px ; background-color: transparent ; 

.column.columncolumn05{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

	.text.textcolumncolumn051{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	font-size:20px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.grid.grid0{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	background-color: transparent ; 

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.object.profileitemcolgridmainContent311211{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:230px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.grid0{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.profileitemcolgridmainContent311211{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.grid0{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.profileitemcolgridmainContent311211{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.grid0{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.profileitemcolgridmainContent311211{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.grid0{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.profileitemcolgridmainContent311211{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.grid0{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.profileitemcolgridmainContent311211{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}
}
</style>
