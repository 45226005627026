<template>
 <div>  
<div id="SocialCallToAction"> 
         
 <div  :class="'transition transition0 '"   id="transition0" ref="transition0"  v-bind:style="{}">
 <transition  :mode = "'out-in'" 
 :appear = "true" 
 :appear-active-class = "'animated fadeInUp'" 
 :duration = "7000" 
>
 <div  :class="'flow column01 ' + ' ' + 'elevation-3'"   id="column01" ref="column01"  v-bind:style="{backgroundColor : '#fff',paddingRight : getUnit(chooseOne ? 10 : 30,'px'),}">
 <div  :class="'row rowcolumn017 '"   id="rowcolumn017" ref="rowcolumn017"  v-bind:style="{width : chooseOne ? '100%' : '40px',justifyContent : 'center',}">
 <v-img  :class="'image imagecolumn0111 '"   id="imagecolumn0111" ref="imagecolumn0111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + 'Resources' + m_message.icon + '')" 
>  </v-img>  </div>
 <div  :class="'column columncolumn015 '"   id="columncolumn015" ref="columncolumn015" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'text textcolumn0131 '"   id="textcolumn0131" ref="textcolumn0131"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ m_message.text }}</div>

   </div>
 <a  :href = "m_message.link" 
 :class="'link linkcolumncolumn0155 '"   id="linkcolumncolumn0155" ref="linkcolumncolumn0155" :target = "'_blank'" 
  v-bind:style="{}">
 <div  :class="'text textcolumn01321 '"   id="textcolumn01321" ref="textcolumn01321"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ m_message.link }}</div>

   </div>  </a>  </div>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="close" :class="'button buttoncolumn015 '"   id="buttoncolumn015" ref="buttoncolumn015" v-if="hasCloseBtn"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-close' !== undefined" >{{ 'ion-ios-close' }}
</v-icon></v-btn>  </div>  </transition></div>
 
      </div>
 </div> 
</template>
<script>

import * as Request from "../../Js/requests"
export default {
	name: 'SocialCallToAction',

    components: {},
	mounted(){
			
	Request.getTable('contact')

	.then((res) => {

		this.contact = res.data.data.attributes

	})

	},
	computed: {
	
		chooseOne: function() { 		
	return this.index != null

}
,
		randomMessage: function() { 		
	let i = Math.floor(Math.random() * this.messages.length)

	return this.messages[i]

}
,
		choosedMessage: function() { 		
	if(this.index) return this.messages[this.index]

	return null

}
,
		m_message: function() { 		
	return this.choosedMessage ?? this.randomMessage

}
,
		messages: function() { 		
	return [

		{

			icon: "/Logo/facebookLogo.png",

			text: this.$t('SocialCallToAction.facebookMessage'),

			link: this.contact.facebook_link

		},

		{

			icon: "/social/linkedin2.png",

			text: this.$t('SocialCallToAction.linkedinMessage'),

			link: this.contact.linkedin_link

		},

		{

			icon: "/Logo/whatsappLogo.png",

			text: this.$t('SocialCallToAction.whatsappMessage'),

			link: this.contact.whatsapp_link

		},

		{

			icon: "/Logo/InstagramLogo.webp",

			text: this.$t('SocialCallToAction.instagramMessage'),

			link: this.contact.instagram_link

		},

		{

			icon: "/Logo/telegramLogo.png",

			text: this.$t('SocialCallToAction.telegramMessage'),

			link: this.contact.telegram_link

		},

	]

}
,
	},
	data(){
	return {
		
		contact:  ({})		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		index: {					required: false,			default: () =>  null,		},
		hasCloseBtn: {			type: Boolean,			required: false,			default: () =>  true,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SocialCallToAction{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.transition.transition0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;


.flow.column01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
gap:10px ; 
background-color:#fff ; 
border-radius:8px ; 
padding-top:10px ; 
padding-bottom:10px ; 
padding-left:10px ; 

	.row.rowcolumn017{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	height:40px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:center ; background-color:transparent ; 

		.image.imagecolumn0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:40px!important ; 
		height:40px!important ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

		
}

	
}

	.column.columncolumn015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	max-width: 100%!important ; 
	flex:1 !important; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.text.textcolumn0131{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:13px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

		.link.linkcolumncolumn0155{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		target:_blank !important; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: block;

			.text.textcolumn01321{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:13px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			}

			
}

		
}

	
}

	.button.buttoncolumn015{

	height: auto;
	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 5px ; 
	right: 5px ; 
	width:25px!important ; 
	height:25px!important ; 
	
	font-size:28px ; 
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	font-size:28px ; 
	}
	}


}


}


@media (max-width: 600px) {


.transition.transition0{


position:relative !important; 






.flow.column01{


position:relative !important; 









	.row.rowcolumn017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.image.imagecolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.column.columncolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumncolumn0155{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textcolumn01321{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.button.buttoncolumn015{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 601px) {


.transition.transition0{


position:relative !important; 






.flow.column01{


position:relative !important; 









	.row.rowcolumn017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.image.imagecolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.column.columncolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumncolumn0155{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textcolumn01321{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.button.buttoncolumn015{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 961px) {


.transition.transition0{


position:relative !important; 






.flow.column01{


position:relative !important; 









	.row.rowcolumn017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.image.imagecolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.column.columncolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumncolumn0155{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textcolumn01321{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.button.buttoncolumn015{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1265px) {


.transition.transition0{


position:relative !important; 






.flow.column01{


position:relative !important; 









	.row.rowcolumn017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.image.imagecolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.column.columncolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumncolumn0155{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textcolumn01321{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.button.buttoncolumn015{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1905px) {


.transition.transition0{


position:relative !important; 






.flow.column01{


position:relative !important; 









	.row.rowcolumn017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.image.imagecolumn0111{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.column.columncolumn015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumncolumn0155{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textcolumn01321{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.button.buttoncolumn015{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}
}
</style>
