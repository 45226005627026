<template>
 <div>  
<div id="UnivSearch"> 
         
 <ResultGroup :class="'object resultgroupmainColumn122 '"   id="resultgroupmainColumn122" ref="resultgroupmainColumn122"  v-bind:style="{}" :action = "m_selectEntity" 
 :title = "univFoundNumber" 
 :actionText = "moreText" 
 :actived = "selected" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateresultgroupmainColumn11 '"   id="slottemplateresultgroupmainColumn11" ref="slottemplateresultgroupmainColumn11"  v-bind:style="{}">
 <div  :class="'column columnslottemplateresultgroupmainColumn111 '"   id="columnslottemplateresultgroupmainColumn111" ref="columnslottemplateresultgroupmainColumn111"  v-bind:style="{}">
 <v-row  :class="'grid gridmainContent311 '"   id="gridmainContent311" ref="gridmainContent311"  v-bind:style="{}">
 <v-col  :class="'col colgridmainContent31 '"   id="colgridmainContent31" ref="colgridmainContent31" v-for="(item,index) in univs" :key="'colgridmainContent31' + index"  v-bind:style="{}" :cols = "12" 
 :lg = "3" 
 :md = "4" 
 :sm = "6" 
><div class="col-container">
 <UnivItem :class="'object profileitemcolgridmainContent3112 '"   id="profileitemcolgridmainContent3112" ref="profileitemcolgridmainContent3112"  v-bind:style="{}" :univ = "item" 
>
</UnivItem></div></v-col>  </v-row>
 <v-pagination 
 :color = "primaryColor" 
 :length = "nbUnivPages" 
 :class="'pagination orgaPagination '"   id="orgaPagination" ref="orgaPagination" v-if="nbUnivPages" v-model="univCurPage"  v-bind:style="{}"></v-pagination>  </div></div></template>
</ResultGroup>
 
      </div>
 </div> 
</template>
<script>
import ResultGroup from '../../../At/Components/SearchPage/ResultGroup'
import UnivItem from '../../../At/Components/UniversityPage/UnivItem'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'UnivSearch',

    components: {ResultGroup,UnivItem,},
	methods: {
	
		m_selectEntity: function() { 		
	this.selectEntity(this.entityName)

}
,
		getUnivs: function() {		
	let config = {

		pagination: {

			page: this.univCurPage,

			pageSize: this.univPerPage,

			withCount: true

		},

		filters: this.searchFilters,

		sort: ['id:desc']

	}

	

	Request.getTableArgs(this.entityName, config)

	.then((res) => {

		if(res.data.meta != undefined){	

			this.univs = res.data.data;

			

			let pagination = res.data.meta.pagination;

			this.nbUnivPages = pagination.pageCount

		} else {

			this.univs = res.data;

		}

		

		this.loading = false;

	}) 

}
,
	},
	mounted(){
			
	this.getUnivs()

	},
	computed: {
	
		univPerPage: function() { 		
	return (this.selected ? 8 : 4)

}
,
		selected: function() { 		
	return this.selectedEntity == this.entityName

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		univFoundNumber: function() { 		
		return this.$t('University.universities') 

			+ ' (' + this.univs.length + ')'

}
,
		searchFilters: function() { 		
	let filters = ({})

	

	if(this.filter){

		filters['$and'] = filters['$and'] ?? []

		

		let filter1 = {

			$or: [	

				{ name: { $contains: this.filter } },

				{ country: { $contains: this.filter } },

				{ description: { $contains: this.filter	 } },

			]

		}

		

		filters['$and'].push(filter1)

	}

	

	return filters

}
,
		moreText: function() { 		
	return this.$t('Search.seeAll')

		.replace('{{ entity }}', this.$t('Search.universities'))

}
,
	},
	watch: {
	
		univPerPage: function(val) { 		
	this.getUnivs()

}
,
		filter: function(val) { 		
	this.getUnivs()

}
,
		univs: function(val) { 		
	this.setNbFinded(this.univs.length)

}
,
		univCurPage: function(val) { 		
	this.getUnivs()

}
,
	},
	data(){
	return {
		
		univs:  []		,
		univCurPage:  1		,
		nbUnivPages:  null		,
		loading:  true		,
		entityName:  "universities"		,
		}
	},
	props: {
	
		filter: {					required: false,			default: () =>  null,		},
		selectedEntity: {					required: false,			default: () =>  null,		},
		setNbFinded: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		selectEntity: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#UnivSearch{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.resultgroupmainColumn122{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.grid.gridmainContent311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color: transparent ; 

			.col.colgridmainContent31{

			
			position:relative !important; 
			margin: unset;
			transform-origin: top left;
			
			
			
			height:auto!important ; 
			

			::v-deep .col-container{position: relative;

			height: auto;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:250px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		}

	
}


}


}


@media (max-width: 600px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}
}
</style>
