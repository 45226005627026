<template>
 <div>  
<div id="LDFormator"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0" v-if="instructor"  v-bind:style="{}">
 <div  :class="'text textrowcolumncolumn013311 '"   id="textrowcolumncolumn013311" ref="textrowcolumncolumn013311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Formateur' }}</div>

   </div>
 <div  :class="'row rowcolumn03 '"   id="rowcolumn03" ref="rowcolumn03"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerowcolumn031 '"   id="networkimagerowcolumn031" ref="networkimagerowcolumn031"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "image" 
>  </v-img>
 <div  :class="'column columnrowcolumn033 '"   id="columnrowcolumn033" ref="columnrowcolumn033" :_flex = "'1'" 
  v-bind:style="{flex : '1',justifyContent : 'space-between',}">
 <div  :class="'text textrowcolumncolumn0133111 '"   id="textrowcolumncolumn0133111" ref="textrowcolumncolumn0133111"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ name }}</div>

   </div>
 <div  :class="'text textrowcolumncolumn01331112 '"   id="textrowcolumncolumn01331112" ref="textrowcolumncolumn01331112"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ p_description }}</div>

   </div>
 <div  :class="'flow flowrowcolumn0353 '"   id="flowrowcolumn0353" ref="flowrowcolumn0353"  v-bind:style="{}">
 <router-link  :class="'routerlink routerlinkflowrowcolumn03531 '"   id="routerlinkflowrowcolumn03531" ref="routerlinkflowrowcolumn03531"  v-bind:style="{}" :to = "instructorLink" 
>
 <div  :class="'text textrouterlinkflowrowcolumn035311 '"   id="textrouterlinkflowrowcolumn035311" ref="textrouterlinkflowrowcolumn035311"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ moreCourse }}</div>

   </div>  </router-link>
 <div  :class="'icon ico33 '"   id="ico33" ref="ico33"  v-bind:style="{fontSize : getUnit(8,'px'),}"><i  :class="'ion-ios-keypad'"></i></div>
 <router-link  :class="'routerlink routerlinkflowrowcolumn035312 '"   id="routerlinkflowrowcolumn035312" ref="routerlinkflowrowcolumn035312"  v-bind:style="{}" :to = "instructorProfileLink" 
>
 <div  :class="'text textrouterlinkflowrowcolumn035311 '"   id="textrouterlinkflowrowcolumn035311" ref="textrouterlinkflowrowcolumn035311"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('LearningDetails.seeInAfro') }}</div>

   </div>  </router-link>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'LDFormator',

    components: {},
	methods: {
	
		getInstructor: function(config) { 		
	Request.getTableArgs('users', config)

	.then((res) => {

		this.instructor = res.data[0]

	})

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		name: function() { 		
	if(this.instructor){

		return this.instructor.username

	}

	return null

}
,
		slug: function() { 		
	if(this.instructor){

		return this.instructor.slug

	}

	return null

}
,
		profile: function() { 		
	if(this.instructor){

		return this.instructor.profile

	}

	return null

}
,
		p_description: function() { 		
	if(this.profile){

		return this.profile.description

	}

	return null

}
,
		image: function() { 		
	if(this.instructor){

		if(this.instructor.image){

			let item = this.instructor.image;

			return Main.getImgUrl(item, 'small');

		}

		return "https://picsum.photos/500"

	}

	return "https://picsum.photos/500"

}
,
		moreCourse: function() { 		
	if(this.name){

		return this.$t('LearningDetails.moreCourses') + " " +  this.name

	}

	return null

}
,
		instructorLink: function() { 		
	if(this.slug){

		return "/learning/instructors/" + this.slug

	} else {

		return "/learning/instructors/id/" + this.user.id

	}

	return "/"

}
,
		instructorProfileLink: function() { 		
	if(this.slug){

		return "/profile/" + this.slug

	} else {

		return "/profile/id/" + this.user.id

	}

	return "/"

}
,
	},
	watch: {
	
		instructorID: function(val) { 		
	if(this.instructorID){	

		let config = {	

		  filters: {

		    id: {

		      $eq: this.instructorID,

		    },

		  },

		}	

			

		if(!this.fromMoodle){

			this.getInstructor(config)

		}

	}

}
,
		instructorEmail: function(val) { 		
	if(this.instructorEmail){	

		let config = {	

		  filters: {

		    email: {

		      $eq: this.instructorEmail,

		    },

		  },

		}		

			

		if(this.fromMoodle){

			this.getInstructor(config)

		}

	}

}
,
	},
	data(){
	return {
		
		instructor:  null		,
		}
	},
	props: {
	
		instructorID: {					required: false,			default: () =>  null,		},
		instructorEmail: {					required: false,			default: () =>  null,		},
		fromMoodle: {			type: Boolean,			required: false,			default: () =>  true,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LDFormator{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

.text.textrowcolumncolumn013311{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:auto!important ; 

font-size:22px ; 
align-items:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;
}


}

.row.rowcolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:5px ; background-color:transparent ; 

	.networkimage.networkimagerowcolumn031{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:72px!important ; 
	height:72px!important ; 
	
	display: inherit;

	flex: unset;
	border-radius:36px ; 
	border-style: solid ; 
	border-width:0.5px ; 
	border-color:#6d6d6d ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.column.columnrowcolumn033{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	flex:1 !important; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	justify-content:space-between ; background-color: transparent ; 

		.text.textrowcolumncolumn0133111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:22px ; 
		align-items:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.textrowcolumncolumn01331112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:27px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:18px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

		.flow.flowrowcolumn0353{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;background-color:transparent ; 

			.routerlink.routerlinkflowrowcolumn03531{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:27px!important ; 
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				margin-bottom: 0px ; 
				width:auto!important ; 
				height: calc(100% - (0px + 0px)) !important;
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.icon.ico33{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:27px!important ; 
			height:27px!important ; 
			
			font-size:8px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.routerlink.routerlinkflowrowcolumn035312{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:27px!important ; 
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				margin-bottom: 0px ; 
				width:auto!important ; 
				height: calc(100% - (0px + 0px)) !important;
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.text.textrowcolumncolumn013311{


position:relative !important; 





.text-content{
width: 100%;
}


}

.row.rowcolumn03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerowcolumn031{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrowcolumn033{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumncolumn0133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumncolumn01331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowrowcolumn0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.routerlink.routerlinkflowrowcolumn03531{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.icon.ico33{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.routerlink.routerlinkflowrowcolumn035312{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.text.textrowcolumncolumn013311{


position:relative !important; 





.text-content{
width: 100%;
}


}

.row.rowcolumn03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerowcolumn031{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrowcolumn033{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumncolumn0133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumncolumn01331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowrowcolumn0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.routerlink.routerlinkflowrowcolumn03531{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.icon.ico33{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.routerlink.routerlinkflowrowcolumn035312{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.text.textrowcolumncolumn013311{


position:relative !important; 





.text-content{
width: 100%;
}


}

.row.rowcolumn03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerowcolumn031{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrowcolumn033{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumncolumn0133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumncolumn01331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowrowcolumn0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.routerlink.routerlinkflowrowcolumn03531{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.icon.ico33{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.routerlink.routerlinkflowrowcolumn035312{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.text.textrowcolumncolumn013311{


position:relative !important; 





.text-content{
width: 100%;
}


}

.row.rowcolumn03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerowcolumn031{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrowcolumn033{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumncolumn0133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumncolumn01331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowrowcolumn0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.routerlink.routerlinkflowrowcolumn03531{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.icon.ico33{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.routerlink.routerlinkflowrowcolumn035312{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.text.textrowcolumncolumn013311{


position:relative !important; 





.text-content{
width: 100%;
}


}

.row.rowcolumn03{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerowcolumn031{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrowcolumn033{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumncolumn0133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumncolumn01331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.flowrowcolumn0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.routerlink.routerlinkflowrowcolumn03531{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.icon.ico33{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.routerlink.routerlinkflowrowcolumn035312{

			
			position:relative !important; 
			
			
			
			
			

				.text.textrouterlinkflowrowcolumn035311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
