<template>
 <div>  
<div id="WebinarDetail"> 
         
 <DetailsPage :class="'object detailspage0 '"   id="detailspage0" ref="detailspage0"  v-bind:style="{}" :info = "info" 
 :isWebinar = "true" 
 :currentTab = "2" 
 :slug = "slug" 
>
</DetailsPage>
 
      </div>
 </div> 
</template>
<script>
import DetailsPage from '../../At/Sections/DetailsPage'

import * as Request from '../../Js/requests'
export default {
	name: 'WebinarDetail',

    components: {DetailsPage,},
	mounted(){
			
	let config = {	

		locale: "all",

	  filters: {

	    slug: {

	      $eq: this.slug,

	    },

	  },

	}

	

	Request.getTableArgs('webinars', config)

	.then((res) => {

		this.info = res.data.data[0]

	})

	},
	computed: {
	
		slug: function() { 		
	return this.$route.params.slug

}
,
	},
	watch: {
	
		slug: function(val) { 		
	location.reload();

}
,
	},
	data(){
	return {
		
		info:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#WebinarDetail{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.object.detailspage0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


@media (max-width: 600px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 601px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 961px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1265px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1905px) {


.object.detailspage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}
}
</style>
