<template>
 <div>  
<div id="LDCertifSection"> 
         
 <div  :class="'column columncolumn0322 '"   id="columncolumn0322" ref="columncolumn0322"  v-bind:style="{}">
 <div  :class="'text textrowcolumncolumn01331 '"   id="textrowcolumncolumn01331" ref="textrowcolumncolumn01331"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Certificat à partager' }}</div>

   </div>
 <v-row  :class="'grid gridcolumncolumn03223 '"   id="gridcolumncolumn03223" ref="gridcolumncolumn03223"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumn032231 '"   id="colgridcolumncolumn032231" ref="colgridcolumncolumn032231"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
><div class="col-container">
 <div  :class="'column columncolgridcolumncolumn0322311 '"   id="columncolgridcolumncolumn0322311" ref="columncolgridcolumncolumn0322311"  v-bind:style="{}">
 <v-img  :class="'image certifImg '"   id="certifImg" ref="certifImg"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../../Resources/Learning/afro-certificate.png')" 
>  </v-img>
 <div  :class="'flow rowcolumncolumn01332 '"   id="rowcolumncolumn01332" ref="rowcolumncolumn01332"  v-bind:style="{}">
 <div  :class="'text textrowcolumncolumn0133 '"   id="textrowcolumncolumn0133" ref="textrowcolumncolumn0133"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Exemple de certificat' }}</div>

   </div>  </div>  </div></div></v-col>
 <v-col  :class="'col colgridcolumncolumn0322312 '"   id="colgridcolumncolumn0322312" ref="colgridcolumncolumn0322312"  v-bind:style="{}" :cols = "12" 
 :md = "8" 
><div class="col-container">
 <div  :class="'column columncolgridcolumncolumn03223111 '"   id="columncolgridcolumncolumn03223111" ref="columncolgridcolumncolumn03223111"  v-bind:style="{}">
 <div  :class="'flow flowcolumncolgridcolumncolumn032231111 '"   id="flowcolumncolgridcolumncolumn032231111" ref="flowcolumncolgridcolumncolumn032231111"  v-bind:style="{}">
 <Logo :class="'object logoflowcolumncolgridcolumncolumn0322311111 '"   id="logoflowcolumncolgridcolumncolumn0322311111" ref="logoflowcolumncolgridcolumncolumn0322311111"  v-bind:style="{}" :size = "30" 
 :mini = "true" 
>
</Logo>
 <div  :class="'text textflowcolumncolgridcolumncolumn0322311113 '"   id="textflowcolumncolgridcolumncolumn0322311113" ref="textflowcolumncolgridcolumncolumn0322311113"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ companyName }}</div>

   </div>
 <div  :class="'text textflowcolumncolgridcolumncolumn03223111133 '"   id="textflowcolumncolgridcolumncolumn03223111133" ref="textflowcolumncolgridcolumncolumn03223111133"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Learning' }}</div>

   </div>  </div>
 <div  :class="'text textrowcolumncolumn013312 '"   id="textrowcolumncolumn013312" ref="textrowcolumncolumn013312"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Certificat d’accomplissement' }}</div>

   </div>
 <div  :class="'row rowcolumncolgridcolumncolumn032231115 '"   id="rowcolumncolgridcolumncolumn032231115" ref="rowcolumncolgridcolumncolumn032231115" v-for="(item,index) in certifCheck" :key="'rowcolumncolgridcolumncolumn032231115' + index"  v-bind:style="{}">
 <div  :class="'icon iconrowcolumncolgridcolumncolumn0322311151 '"   id="iconrowcolumncolgridcolumncolumn0322311151" ref="iconrowcolumncolgridcolumncolumn0322311151"  v-bind:style="{fontSize : getUnit(16,'px'),}"><i  :class="'ion-md-checkmark'"></i></div>
 <div  :class="'text textrowcolumncolumn01332 '"   id="textrowcolumncolumn01332" ref="textrowcolumncolumn01332" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
<div class="text-content" :style = "{}">{{ item }}</div>

   </div>  </div>  </div></div></v-col>  </v-row>  </div>
 
      </div>
 </div> 
</template>
<script>
import Logo from '../../../At/Utils/Logo'


export default {
	name: 'LDCertifSection',

    components: {Logo,},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
		certifCheck: function() { 		
	return [

		this.$t('LearningDetails.certifCheck1'),

		this.$t('LearningDetails.certifCheck2'),

		this.$t('LearningDetails.certifCheck3')

	]

}
,
	},
} 
</script>

<style lang = "scss" scoped>


 div#LDCertifSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.certifImg{
	box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}



.column.columncolumn0322{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

.text.textrowcolumncolumn01331{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:24px!important ; 

font-size:22px ; 
align-items:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;
}


}

.grid.gridcolumncolumn03223{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumn032231{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.column.columncolgridcolumncolumn0322311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:23px ; background-color: transparent ; 

			.image.certifImg{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			max-width: 200px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

			.flow.rowcolumncolumn01332{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: wrap;
			flex: initial;
			gap:4px ; background-color:transparent ; 

				.text.textrowcolumncolumn0133{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:24px!important ; 
				
				font-size:15px ; 
				align-items:center;
				display: flex;
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumn0322312{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.column.columncolgridcolumncolumn03223111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:8px ; background-color: transparent ; 

			.flow.flowcolumncolgridcolumncolumn032231111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: wrap;
			flex: initial;
			gap:8px ; background-color:transparent ; 

				.object.logoflowcolumncolgridcolumncolumn0322311111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textflowcolumncolgridcolumncolumn0322311113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				height:30px!important ; 
				
				font-family:Arial ; 
				font-size:14px ; 
				align-items:center;
				display: flex;
				text-transform:uppercase; 
				.text-content{
				width: 100%;
				}

				
}

				.text.textflowcolumncolgridcolumncolumn03223111133{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				height:30px!important ; 
				
				font-family:Arial ; 
				font-size:14px ; 
				align-items:center;
				display: flex;
				text-transform:uppercase; 
				.text-content{
				width: 100%;
				}

				
}

			
}

			.text.textrowcolumncolumn013312{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:24px!important ; 
			
			font-size:16px ; 
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

			.row.rowcolumncolgridcolumncolumn032231115{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;background-color:transparent ; 

				.icon.iconrowcolumncolgridcolumncolumn0322311151{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:24px!important ; 
				height:24px!important ; 
				
				font-size:16px ; 
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

				.text.textrowcolumncolumn01332{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				flex:1 !important; 
				
				font-size:15px ; 
				align-items:center;
				display: flex;
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}


}


}


@media (max-width: 600px) {


.column.columncolumn0322{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.grid.gridcolumncolumn03223{


position:relative !important; 






	.col.colgridcolumncolumn032231{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn0322311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.image.certifImg{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.rowcolumncolumn01332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textrowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumn0322312{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn03223111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumncolgridcolumncolumn032231111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.logoflowcolumncolgridcolumncolumn0322311111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textflowcolumncolgridcolumncolumn0322311113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textflowcolumncolgridcolumncolumn03223111133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.text.textrowcolumncolumn013312{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncolgridcolumncolumn032231115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.icon.iconrowcolumncolgridcolumncolumn0322311151{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

				.text.textrowcolumncolumn01332{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}


}


}
}

@media (min-width: 601px) {


.column.columncolumn0322{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.grid.gridcolumncolumn03223{


position:relative !important; 






	.col.colgridcolumncolumn032231{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn0322311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.image.certifImg{

			
			position:relative !important; 
			
			
			
			
			max-width: auto!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.rowcolumncolumn01332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textrowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumn0322312{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn03223111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumncolgridcolumncolumn032231111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.logoflowcolumncolgridcolumncolumn0322311111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textflowcolumncolgridcolumncolumn0322311113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textflowcolumncolgridcolumncolumn03223111133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.text.textrowcolumncolumn013312{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncolgridcolumncolumn032231115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.icon.iconrowcolumncolgridcolumncolumn0322311151{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

				.text.textrowcolumncolumn01332{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}


}


}
}

@media (min-width: 961px) {


.column.columncolumn0322{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.grid.gridcolumncolumn03223{


position:relative !important; 






	.col.colgridcolumncolumn032231{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn0322311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.image.certifImg{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.rowcolumncolumn01332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textrowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumn0322312{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn03223111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumncolgridcolumncolumn032231111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.logoflowcolumncolgridcolumncolumn0322311111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textflowcolumncolgridcolumncolumn0322311113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textflowcolumncolgridcolumncolumn03223111133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.text.textrowcolumncolumn013312{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncolgridcolumncolumn032231115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.icon.iconrowcolumncolgridcolumncolumn0322311151{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

				.text.textrowcolumncolumn01332{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.columncolumn0322{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.grid.gridcolumncolumn03223{


position:relative !important; 






	.col.colgridcolumncolumn032231{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn0322311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.image.certifImg{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.rowcolumncolumn01332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textrowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumn0322312{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn03223111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumncolgridcolumncolumn032231111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.logoflowcolumncolgridcolumncolumn0322311111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textflowcolumncolgridcolumncolumn0322311113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textflowcolumncolgridcolumncolumn03223111133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.text.textrowcolumncolumn013312{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncolgridcolumncolumn032231115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.icon.iconrowcolumncolgridcolumncolumn0322311151{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

				.text.textrowcolumncolumn01332{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.columncolumn0322{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.grid.gridcolumncolumn03223{


position:relative !important; 






	.col.colgridcolumncolumn032231{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn0322311{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.image.certifImg{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.rowcolumncolumn01332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textrowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}

	.col.colgridcolumncolumn0322312{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumn03223111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumncolgridcolumncolumn032231111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.logoflowcolumncolgridcolumncolumn0322311111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textflowcolumncolgridcolumncolumn0322311113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textflowcolumncolgridcolumncolumn03223111133{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.text.textrowcolumncolumn013312{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncolgridcolumncolumn032231115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.icon.iconrowcolumncolgridcolumncolumn0322311151{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

				.text.textrowcolumncolumn01332{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	
}


}


}
}
}
</style>
