<template>
 <div>  
<div id="SiteItem"> 
         
 <div  :class="'column column0 ' + ' ' + 'elevation-1'"   id="column0" ref="column0"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'row row01 '"   id="row01" ref="row01"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerow01 '"   id="networkimagerow01" ref="networkimagerow01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "icon" 
>  </v-img>
 <div  :class="'column columnrow03 '"   id="columnrow03" ref="columnrow03" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'text textcolumnrow033 '"   id="textcolumnrow033" ref="textcolumnrow033"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ name }}</div>

   </div>
 <a  :href = "link" 
 :class="'link linkcolumnrow031 '"   id="linkcolumnrow031" ref="linkcolumnrow031" :_textDecoration = "'none'" 
  v-bind:style="{textDecoration : 'none',}">
 <div  :class="'text textlinkcolumnrow0311 '"   id="textlinkcolumnrow0311" ref="textlinkcolumnrow0311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ link }}</div>

   </div>  </a>  </div>  </div>
 <div  :class="'column columncolumn03 '"   id="columncolumn03" ref="columncolumn03"  v-bind:style="{}">
 <a  :href = "link" 
 :class="'link linkcolumnrow0311 '"   id="linkcolumnrow0311" ref="linkcolumnrow0311" :_textDecoration = "'none'" 
  v-bind:style="{textDecoration : 'none',}">
 <div  :class="'text textlinkcolumnrow0311 '"   id="textlinkcolumnrow0311" ref="textlinkcolumnrow0311"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ name }}</div>

   </div>  </a>
 <div  :class="'text textcolumncolumn033 '"   id="textcolumncolumn033" ref="textcolumncolumn033" v-if="description"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ description }}</div>

   </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'SiteItem',

    components: {},
	computed: {
	
		attributes: function() { 		
	return this.site.attributes

}
,
		name: function() { 		
	return this.attributes.name

}
,
		icon: function() { 		
	let item = this.attributes.icon.data?.attributes;

	

	if(item){

		return Main.getImgUrl(item, 'large');

	}

	return "https://res.cloudinary.com/afrotiative/image/upload/v1660944347/fourth_Vector_d9cb11405d.svg"

}
,
		description: function() { 		
	if(this.attributes.description == null)

		return null

		

	let maxChar = 200

	

	if(this.attributes.description.length > maxChar){

		return this.attributes.description.slice(0, maxChar) + '...'

	}

	return this.attributes.description

}
,
		slug: function() { 		
	return this.attributes.slug

}
,
		link: function() { 		
	if(this.slug == null) return null

	return this.$store.state.webUrl + '/sites/' + this.slug

}
,
	},
	props: {
	
		site: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SiteItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:4px ; 
background-color:#fff ; 
border-radius:8px ; 
padding-top:10px ; 
padding-bottom:10px ; 
padding-left:10px ; 
padding-right:10px ; 

.row.row01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:7px ; background-color:transparent ; 

	.networkimage.networkimagerow01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:38px!important ; 
	height:38px!important ; 
	
	display: inherit;

	flex: unset;
	border-radius:19px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}

	.column.columnrow03{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	flex:1 !important; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:2px ; background-color: transparent ; 

		.text.textcolumnrow033{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumnrow031{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		textDecoration:none !important; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: block;

			.text.textlinkcolumnrow0311{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:12px ; 
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.column.columncolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.link.linkcolumnrow0311{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	textDecoration:none !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: block;

		.text.textlinkcolumnrow0311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:18px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

	
}

	.text.textcolumncolumn033{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	.text-content{
	width: 100%;
	}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.row.row01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerow01{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrow03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnrow033{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumnrow031{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textlinkcolumnrow0311{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.link.linkcolumnrow0311{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumnrow0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.text.textcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.row.row01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerow01{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrow03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnrow033{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumnrow031{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textlinkcolumnrow0311{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.link.linkcolumnrow0311{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumnrow0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.text.textcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.row.row01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerow01{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrow03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnrow033{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumnrow031{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textlinkcolumnrow0311{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.link.linkcolumnrow0311{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumnrow0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.text.textcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.row.row01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerow01{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrow03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnrow033{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumnrow031{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textlinkcolumnrow0311{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.link.linkcolumnrow0311{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumnrow0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.text.textcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.row.row01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.networkimagerow01{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.column.columnrow03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnrow033{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.link.linkcolumnrow031{

		
		position:relative !important; 
		
		
		
		
		
		display: block;

			.text.textlinkcolumnrow0311{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.column.columncolumn03{


position:relative !important; 









	.link.linkcolumnrow0311{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumnrow0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.text.textcolumncolumn033{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}
}
</style>
