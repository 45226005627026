<template>
 <div>  
<div id="SearchPage"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <MainLayout :class="'object basic01 '"   id="basic01" ref="basic01"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatebasic01 '"   id="slottemplatebasic01" ref="slottemplatebasic01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatebasic011 '"   id="columnslottemplatebasic011" ref="columnslottemplatebasic011"  v-bind:style="{}">
 <div  :class="'flow flowcolumnslottemplatebasic0113 ' + ' ' + 'elevation-1'"   id="flowcolumnslottemplatebasic0113" ref="flowcolumnslottemplatebasic0113" v-if="!onlySelectedEntity" :_borderTop = "'solid 1px #8080801c'" 
  v-bind:style="{borderTop : 'solid 1px #8080801c',justifyContent : 'center',backgroundColor : '#fff',}">
 <div  :class="'flow flowflowcolumnslottemplatebasic01131 '"   id="flowflowcolumnslottemplatebasic01131" ref="flowflowcolumnslottemplatebasic01131"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "selectedEntity == item.entityName ? primaryColor : '#6c6c6c'" 
 :outlined = "selectedEntity == item.entityName ? false : true" 
 :rounded = "true" 
 :dark = "selectedEntity == item.entityName ? true : false" 
 @click="selectEntity(item.entityName)" :class="'button buttonflowcolumnslottemplatebasic011351 '"   id="buttonflowcolumnslottemplatebasic011351" ref="buttonflowcolumnslottemplatebasic011351" v-for="(item,index) in entities" :key="'buttonflowcolumnslottemplatebasic011351' + index"  v-bind:style="{}"><span>{{ item.name }}</span>
</v-btn>  </div>
 <v-btn tag="div" 
 :color = "selectedEntity == null ? primaryColor : '#6c6c6c'" 
 :outlined = "selectedEntity == null ? false : true" 
 :rounded = "true" 
 :dark = "selectedEntity == null ? true : false" 
 @click="selectEntity(null)" :class="'button buttonflowcolumnslottemplatebasic011354 '"   id="buttonflowcolumnslottemplatebasic011354" ref="buttonflowcolumnslottemplatebasic011354"  v-bind:style="{}"><span>{{ $t('Search.allCat') }}</span>
</v-btn>
 <div  :class="'rectangle rectangleflowcolumnslottemplatebasic01133 '"   id="rectangleflowcolumnslottemplatebasic01133" ref="rectangleflowcolumnslottemplatebasic01133"  v-bind:style="{backgroundColor : '#80801c',}">  </div>
 <v-btn tag="div" 
 :color = "filteMenuVisible ? primaryColor : '#6c6c6c'" 
 :outlined = "!filteMenuVisible" 
 :rounded = "true" 
 :dark = "filteMenuVisible" 
 @click="toggleFilterMenu" :class="'button buttonflowcolumnslottemplatebasic01135 '"   id="buttonflowcolumnslottemplatebasic01135" ref="buttonflowcolumnslottemplatebasic01135"  v-bind:style="{}"><span>{{ $t('Search.allFilter') }}</span>
</v-btn>  </div>
 <div  :class="'row rowcolumnslottemplatebasic0115 '"   id="rowcolumnslottemplatebasic0115" ref="rowcolumnslottemplatebasic0115" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'scrollarea scrollarearowcolumnslottemplatebasic01155 '"   id="scrollarearowcolumnslottemplatebasic01155" ref="scrollarearowcolumnslottemplatebasic01155" :_flex = "'1'" 
  v-bind:style="{flex : '1',backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'column columncolumnslottemplatebasic011311 '"   id="columncolumnslottemplatebasic011311" ref="columncolumnslottemplatebasic011311"  v-bind:style="{maxWidth : (mqVal({default:'100%',md:filteMenuVisible? '100%' : '80%'}, $mq)),}">
 <div  :class="'column mainContent1 '"   id="mainContent1" ref="mainContent1"  v-bind:style="{}">
 <div  :class="'flow rowmainContent12 '"   id="rowmainContent12" ref="rowmainContent12"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'row rowrowmainContent14 '"   id="rowrowmainContent14" ref="rowrowmainContent14"  v-bind:style="{}">
 <div  :class="'text textrowmainContent11 '"   id="textrowmainContent11" ref="textrowmainContent11"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ $t('Search.results') }}</div>

   </div>
 <div  :class="'text textrowmainContent112 '"   id="textrowmainContent112" ref="textrowmainContent112"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ foundNumber }}</div>

   </div>  </div>
 <div  :class="'rectangle searchZone '"   id="searchZone" ref="searchZone" :_flex = "'1'" 
  v-bind:style="{flex : '1',backgroundColor : '#e4580a12',}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 :dark = "true" 
 :class="'button buttonsearchZone1 '"   id="buttonsearchZone1" ref="buttonsearchZone1"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-search' !== undefined"  :color = "'#444444'" 
>{{ 'ion-ios-search' }}
</v-icon></v-btn>
 <div  :class="'item itemsearchZone4 '"   id="itemsearchZone4" ref="itemsearchZone4"  v-bind:style="{}">
 <input  type = "text" :class="'textedit texteditsearchZone3 '"   id="texteditsearchZone3" ref="texteditsearchZone3" v-model="filter" :placeholder = "$t('Blog.search')" 
 :outline = "'none'" 
  v-bind:style="{color : '#000',}" >  </div>  </div>  </div>
 <div  :class="'column mainColumn '"   id="mainColumn" ref="mainColumn"  v-bind:style="{}">
 <OrgaSearch :class="'object orgasearch '"   id="orgasearch" ref="orgasearch" v-if="checkIfVisible(selectedEntity, 'organisations')"  v-bind:style="{}" :setNbFinded = "setNbOrga" 
 :selectEntity = "selectEntity" 
 :filter = "filter" 
 :selectedEntity = "selectedEntity" 
>
</OrgaSearch>
 <MembersSearch :class="'object members '"   id="members" ref="members" v-if="checkIfVisible(selectedEntity, 'users')"  v-bind:style="{}" :setNbFinded = "setNbMembers" 
 :selectEntity = "selectEntity" 
 :filter = "filter" 
 :selectedEntity = "selectedEntity" 
>
</MembersSearch>
 <UnivSearch :class="'object univSearch '"   id="univSearch" ref="univSearch" v-if="checkIfVisible(selectedEntity, 'universities')"  v-bind:style="{}" :setNbFinded = "setNbUnivs" 
 :selectEntity = "selectEntity" 
 :filter = "filter" 
 :selectedEntity = "selectedEntity" 
>
</UnivSearch>
 <MentorSearch :class="'object mentorSearch '"   id="mentorSearch" ref="mentorSearch" v-if="checkIfVisible(selectedEntity, 'mentors')"  v-bind:style="{}" :setNbFinded = "setNbMentors" 
 :selectEntity = "selectEntity" 
 :filter = "filter" 
 :selectedUnivs = "selectedUnivs" 
 :selectedFields = "selectedStudyFields" 
 :selectedLevels = "selectedStudyLevels" 
 :selectedEntity = "selectedEntity" 
>
</MentorSearch>
 <WebinarSearch :class="'object webinarsearchmainColumn13 '"   id="webinarsearchmainColumn13" ref="webinarsearchmainColumn13" v-if="checkIfVisible(selectedEntity, 'webinars')"  v-bind:style="{}" :setNbFinded = "setNbWebinars" 
 :selectEntity = "selectEntity" 
 :filter = "filter" 
 :selectedCats = "selectedCats" 
 :selectedSubCats = "selectedSubCats" 
 :selectedTags = "selectedTags" 
 :selectedEntity = "selectedEntity" 
>
</WebinarSearch>
 <BlogSearch :class="'object blogSearch '"   id="blogSearch" ref="blogSearch" v-if="checkIfVisible(selectedEntity, 'articles')"  v-bind:style="{}" :setNbFinded = "setNbArticles" 
 :selectEntity = "selectEntity" 
 :filter = "filter" 
 :selectedCats = "selectedCats" 
 :selectedTags = "selectedTags" 
 :selectedEntity = "selectedEntity" 
>
</BlogSearch>
 <FormationSearch :class="'object formationSearch '"   id="formationSearch" ref="formationSearch" v-if="checkIfVisible(selectedEntity, 'formations')"  v-bind:style="{}" :setNbFinded = "setNbFormation" 
 :selectEntity = "selectEntity" 
 :filter = "filter" 
 :selectedTags = "selectedTags" 
 :selectedCats = "selectedCats" 
 :selectedSubCats = "selectedSubCats" 
 :selectedEntity = "selectedEntity" 
>
</FormationSearch>  </div>  </div>  </div>  </div>
 <div  :class="'scrollarea scrollarearowcolumnslottemplatebasic01153 ' + ' ' + 'elevation-1'"   id="scrollarearowcolumnslottemplatebasic01153" ref="scrollarearowcolumnslottemplatebasic01153" v-if="filteMenuVisible"  v-bind:style="{backgroundColor : '#fff',backgroundColor : '#fff',}">
 <div  :class="'column columnscrollarearowcolumnslottemplatebasic011531 '"   id="columnscrollarearowcolumnslottemplatebasic011531" ref="columnscrollarearowcolumnslottemplatebasic011531"  v-bind:style="{}">
 <AdminComboView :class="'object tagsCombo '"   id="tagsCombo" ref="tagsCombo" v-if="checkIfFV('tags', selectedEntity)"  v-bind:style="{}" :updateModel = "setSelectedTags" 
 :tableName = "'tags'" 
 :filterAttribute = "'name'" 
 :label = "$t('Search.tags')" 
 :p_model = "selectedTags" 
 :type = "'outlined'" 
 :multiple = "true" 
 :dense = "true" 
>
</AdminComboView>
 <AdminComboView :class="'object categoriesCombo '"   id="categoriesCombo" ref="categoriesCombo" v-if="checkIfFV('cats', selectedEntity)"  v-bind:style="{}" :updateModel = "setSelectedCats" 
 :tableName = "'categories'" 
 :filterAttribute = "'name'" 
 :label = "$t('Search.categories')" 
 :p_model = "selectedCats" 
 :type = "'outlined'" 
 :multiple = "true" 
 :dense = "true" 
>
</AdminComboView>
 <AdminComboView :class="'object subCatCombo '"   id="subCatCombo" ref="subCatCombo" v-if="checkIfFV('subCats', selectedEntity)"  v-bind:style="{}" :updateModel = "setSelectedSubCat" 
 :tableName = "'subcategories'" 
 :filterAttribute = "'name'" 
 :label = "$t('Search.subcategories')" 
 :p_model = "selectedSubCats" 
 :type = "'outlined'" 
 :multiple = "true" 
 :dense = "true" 
>
</AdminComboView>
 <v-autocomplete :class="'importedobject importedobjectcolgridcolumncolumncolumn07129311 '"   id="importedobjectcolgridcolumncolumncolumn07129311" ref="importedobjectcolgridcolumncolumncolumn07129311" v-if="checkIfFV('countries', selectedEntity)" v-model="country" :clearable = "true" 
 :filled = "true" 
 :hideSelected = "true" 
 :multiple = "true" 
 :smallChips = "true" 
 :label = "$t('Profile.country')" 
 :filter = "filter" 
 :items = "countriesList" 
 :outlined = "true" 
 :flat = "true" 
 :dense = "true" 
 :item-text = "'label'" 
 :item-value = "'value'" 
 :prependIcon = "'ion-md-globe'" 
  v-bind:style="{}">
</v-autocomplete>
 <AdminComboView :class="'object univCombo '"   id="univCombo" ref="univCombo" v-if="checkIfFV('univs', selectedEntity)"  v-bind:style="{}" :updateModel = "setSelectedUniv" 
 :tableName = "'universities'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "(mqVal({}, $mq))" 
 :label = "$t('Search.universities')" 
 :p_model = "selectedUnivs" 
 :type = "'outlined'" 
 :multiple = "true" 
 :dense = "true" 
>
</AdminComboView>
 <AdminComboView :class="'object studyLevelCombo '"   id="studyLevelCombo" ref="studyLevelCombo" v-if="checkIfFV('studLevel', selectedEntity)"  v-bind:style="{}" :updateModel = "setSelectedStudyLevel" 
 :tableName = "'study-levels'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "(mqVal({}, $mq))" 
 :label = "$t('Search.studyLevel')" 
 :p_model = "selectedStudyLevels" 
 :type = "'outlined'" 
 :multiple = "true" 
 :dense = "true" 
>
</AdminComboView>
 <AdminComboView :class="'object fieldsCombo '"   id="fieldsCombo" ref="fieldsCombo" v-if="checkIfFV('studFields', selectedEntity)"  v-bind:style="{}" :updateModel = "setSelectedStudyField" 
 :tableName = "'field-of-studies'" 
 :filterAttribute = "'name'" 
 :filtersForGet = "(mqVal({}, $mq))" 
 :label = "$t('Search.studyField')" 
 :p_model = "selectedStudyFields" 
 :type = "'outlined'" 
 :multiple = "true" 
 :dense = "true" 
>
</AdminComboView>  </div>  </div>  </div>  </div></div></template>
</MainLayout>  </div>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import OrgaSearch from '../../At/Components/SearchPage/OrgaSearch'
import MembersSearch from '../../At/Components/SearchPage/MembersSearch'
import UnivSearch from '../../At/Components/SearchPage/UnivSearch'
import MentorSearch from '../../At/Components/SearchPage/MentorSearch'
import WebinarSearch from '../../At/Components/SearchPage/WebinarSearch'
import BlogSearch from '../../At/Components/SearchPage/BlogSearch'
import FormationSearch from '../../At/Components/SearchPage/FormationSearch'
import AdminComboView from '../../At/Components/AdminPage/AdminComboView'

import * as Main from '../../Js/main'
import * as Request from '../../Js/requests'
import * as Countries from 'i18n-iso-countries'
export default {
	name: 'SearchPage',

    components: {MainLayout,OrgaSearch,MembersSearch,UnivSearch,MentorSearch,WebinarSearch,BlogSearch,FormationSearch,AdminComboView,},
	methods: {
	
		checkIfFV: function(key, se) { 		
	let list = this.filterToEntity[key]

	if(list){

		if(se) return list.includes(se)

		return true

	}

	return false

}
,
		setNbMembers: function(val) { 		
	this.nbMembers = val

}
,
		setNbOrga: function(val) { 		
	this.nbOrga = val

}
,
		setNbUnivs: function(val) { 		
	this.nbUnivs = val

}
,
		setNbMentors: function(val) { 		
	this.nbMentors = val

}
,
		setNbArticles: function(val) { 		
	this.nbArticles = val

}
,
		setNbFormation: function(val) { 		
	this.nbFormations = val

}
,
		setNbWebinars: function(val) { 		
	this.nbWebinars = val

}
,
		selectEntity: function(entity) { 		
	this.selectedEntity = entity

}
,
		setSelectedTags: function(val) { 		
	this.selectedTags = val

}
,
		setSelectedCats: function(val) { 		
	this.selectedCats = val

}
,
		setSelectedSubCat: function(val) { 		
	this.selectedSubCats = val

}
,
		setSelectedUniv: function(val) { 		
	this.selectedUnivs = val

}
,
		setSelectedStudyField: function(val) { 		
	this.selectedStudyFields = val

}
,
		setSelectedStudyLevel: function(val) { 		
	this.selectedStudyLevels = val

}
,
		checkIfVisible: function(e1, e2) { 		
	return (e1 == null || e1 == e2)

}
,
		toggleFilterMenu: function() { 		
	this.filteMenuVisible = !this.filteMenuVisible

}
,
	},
	mounted(){
			
	this.selectedEntity = this.visitedEntity

	

	if(this.query){

		this.filter = this.query

	}

	

	this.countries = Countries

	this.countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

	this.countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

	this.countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

	},
	computed: {
	
		query: function() { 		
	return this.$route.query.q

}
,
		filterToEntity: function() { 		
	return {

		tags: ["articles", "formations", "webinars"],

		cats: ["articles", "formations", "webinars"],

		subCats: ["formations", "webinars"],

		univs: ["mentors"],

		studFields: ["mentors"],

		studLevel: ["mentors"],

	}

}
,
		foundNumber: function() { 		
	return '(' 

		+ (

			(this.selectedEntity == null || this.selectedEntity == "users"  ? this.nbMembers : 0)

		+ (this.selectedEntity == null || this.selectedEntity == "organisations" ? this.nbOrga : 0)

		+ (this.selectedEntity == null || this.selectedEntity == "universities" ? this.nbUnivs : 0)

		+ (this.selectedEntity == null || this.selectedEntity == "mentors" ? this.nbMentors : 0)

		+ (this.selectedEntity == null || this.selectedEntity == "articles" ? this.nbArticles : 0)

		+ (this.selectedEntity == null || this.selectedEntity == "formations" ? this.nbFormations : 0)

		+ (this.selectedEntity == null || this.selectedEntity == "webinars" ? this.nbWebinars : 0)

		)

		+ ')'

}
,
		selectedLang: function(){ 		
	return this.$store.state.selectedLang

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		vp: function() { 		
	return this.$mq

}
,
		countriesList: function() { 			
	if(this.countries == null) return []

	

	const list = this.countries.getNames(	

		this.selectedLang, 

		{select: 'official'}

	)

	return Object.keys(list).map((key) => ({	

		    value: key, // FR

		    label: list[key] // ex: France

		})

	)

}
,
		entities: function() { 		
	return [

		{

			name: this.$t('Search.organisations'),

			entityName: "organisations"

		},

		{

			name: this.$t('Search.members'),

			entityName: "users"

		},

		{

			name: this.$t('Search.universities'),

			entityName: "universities"

		},

		{

			name: this.$t('Search.mentors'),

			entityName: "mentors"

		},

		{

			name: this.$t('Search.webinars'),

			entityName: "webinars"

		},

		{

			name: this.$t('Search.articles'),

			entityName: "articles"

		},

		{

			name: this.$t('Search.formations'),

			entityName: "formations"

		},

		{

			name: this.$t('Search.pages'),

			entityName: "pages"

		},

		{

			name: this.$t('Search.projects'),

			entityName: "projects"

		},

	]

}
,
	},
	watch: {
	
		visitedEntity: function(val) { 		
	this.selectedEntity = val

}
,
		vp: function(val) { 		
	if(val == "xs"){

		this.filteMenuVisible = false

	} else if(val == "sm") {

		this.filteMenuVisible = false

	} else {

		this.filteMenuVisible = true

	}

}
,
	},
	data(){
	return {
		
		selectedEntity:  null		,
		filteMenuVisible:  true		,
		selectedTags:  null		,
		selectedCats:  null		,
		selectedSubCats:  null		,
		selectedUnivs:  null		,
		selectedStudyFields:  null		,
		selectedStudyLevels:  null		,
		countries:  null		,
		country:  null		,
		filter:  null		,
		loading:  true		,
		nbMembers:  0		,
		nbOrga:  0		,
		nbUnivs:  0		,
		nbMentors:  0		,
		nbArticles:  0		,
		nbFormations:  0		,
		nbWebinars:  0		,
		}
	},
	props: {
	
		visitedEntity: {					required: false,			default: () =>  null,		},
		onlySelectedEntity: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SearchPage{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.object.basic01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic011{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:80vh!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.flow.flowcolumnslottemplatebasic0113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			z-index:1 ; 
			borderTop:solid 1px #8080801c !important; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: wrap;
			flex: initial;
			gap:8px ; 
			justify-content:center ; 
			background-color:#fff ; 
			padding-top:12px ; 
			padding-bottom:12px ; 
			padding-left:8px ; 
			padding-right:8px ; 

				.flow.flowflowcolumnslottemplatebasic01131{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: wrap;
				flex: initial;
				gap:8px ; background-color:transparent ; 

					.button.buttonflowcolumnslottemplatebasic011351{

					height: auto;
					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					height:32px!important ; 
					
					font-size:10px ; 
					text-transform:unset ; 
					min-width: auto;
					padding-left: 16px;
					padding-right: 16px;

					i.v-icon{
					font-size:10px ; 
					}
					}

				
}

				.button.buttonflowcolumnslottemplatebasic011354{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:32px!important ; 
				
				font-size:10px ; 
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				font-size:10px ; 
				}
				}

				.rectangle.rectangleflowcolumnslottemplatebasic01133{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				display:none !important; 
				width:1px!important ; 
				height:32px!important ; 
				
				background-color:#80801c ; 

				
}

				.button.buttonflowcolumnslottemplatebasic01135{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:32px!important ; 
				
				font-size:10px ; 
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				font-size:10px ; 
				}
				}

			
}

			.row.rowcolumnslottemplatebasic0115{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			flex:1 !important; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;background-color:transparent ; 

				.scrollarea.scrollarearowcolumnslottemplatebasic01155{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				flex:1 !important; 
				
				overflow:hidden ; 
				overflow-x:hidden ; 
				overflow-y:scroll ; 
				background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #0ae;    

      // =======================================Style4
    border: 2px solid #555555;
      //-----------------------------------------------
        
      }

					.column.columncolumnslottemplatebasic011311{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					right: 0px ; 
					height:auto!important ; 
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;background-color: transparent ; 
					padding-top:30px ; 
					padding-bottom:30px ; 
					padding-left:30px ; 
					padding-right:30px ; 

						.column.mainContent1{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						display: flex;
						flex-wrap: nowrap;
						flex: initial;
						flex-direction: column;
						gap:50px ; background-color: transparent ; 

							.flow.rowmainContent12{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: wrap;
							flex: initial;
							gap:20px ; 
							justify-content:space-between ; background-color:transparent ; 

								.row.rowrowmainContent14{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								left:0px ; 
								
								width:100%!important ; 
								height:auto!important ; 
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: row;
								gap:10px ; background-color:transparent ; 

									.text.textrowmainContent11{

									align-self: flex-start;
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									
									width:100%!important ; 
									height:auto!important ; 
									
									color:#444444 ; 
									font-size:35px ; 
									.text-content{
									width: 100%;
									white-space: normal;
									font-weight: bold;
									}

									
}

									.text.textrowmainContent112{

									align-self: flex-start;
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									
									width:100%!important ; 
									height:auto!important ; 
									
									color:#444444 ; 
									font-size:35px ; 
									.text-content{
									width: 100%;
									white-space: normal;
									font-weight: bold;
									}

									
}

								
}

								.rectangle.searchZone{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								
								width:100%!important ; 
								height:40px!important ; 
								min-width: 300px!important ; 
								flex:1 !important; 
								
								background-color:#e4580a12 ; 
								border-radius:20px ; 

									.button.buttonsearchZone1{

									height: auto;
									
									position:absolute !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
									
									left: 5px ; 
									
									top: calc(50% + 0px) ; 
									width:30px!important ; 
									height:30px!important ; 
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

									.item.itemsearchZone4{

									
									position:absolute !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									left: 40px ; 
									
									top: 5px ; 
									right: 20px ; 
									bottom: 5px ; 
									

										.textedit.texteditsearchZone3{

										
										position:absolute !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										
										top: 0px ; 
										bottom: 0px ; 
										width:100%!important ; 
										outline:none !important; 
										
										color:#000 ; 
										font-size:15px ; 
										}

									
}

								
}

							
}

							.column.mainColumn{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;
							gap:30px ; background-color: transparent ; 

								.object.orgasearch{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.members{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.univSearch{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.mentorSearch{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.webinarsearchmainColumn13{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.blogSearch{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.formationSearch{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

				
}

				.scrollarea.scrollarearowcolumnslottemplatebasic01153{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:300px!important ; 
				
				background-color:#fff ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #0ae;    

      // =======================================Style4
    border: 2px solid #555555;
      //-----------------------------------------------
        
      }

					.column.columnscrollarearowcolumnslottemplatebasic011531{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:5px ; background-color: transparent ; 
					padding-top:10px ; 
					padding-bottom:10px ; 
					padding-left:10px ; 
					padding-right:10px ; 

						.object.tagsCombo{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.categoriesCombo{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.subCatCombo{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.importedobject.importedobjectcolgridcolumncolumncolumn07129311{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						clearable:true !important; 
						filled:true !important; 
						hideSelected:true !important; 
						multiple:true !important; 
						smallChips:true !important; 
						outlined:true !important; 
						flat:true !important; 
						dense:true !important; 
						item-text:label !important; 
						item-value:value !important; 
						prependIcon:ion-md-globe !important; 
						width: calc(100% - (0px + 0px)) !important;
						

						
}

						.object.univCombo{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.studyLevelCombo{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.fieldsCombo{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnslottemplatebasic0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowflowcolumnslottemplatebasic01131{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumnslottemplatebasic011351{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.button.buttonflowcolumnslottemplatebasic011354{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.rectangle.rectangleflowcolumnslottemplatebasic01133{

				
				position:relative !important; 
				
				
				
				
				
				

				
}

				.button.buttonflowcolumnslottemplatebasic01135{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.row.rowcolumnslottemplatebasic0115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.scrollarea.scrollarearowcolumnslottemplatebasic01155{

				
				position:relative !important; 
				
				
				
				
				

					.column.columncolumnslottemplatebasic011311{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.column.mainContent1{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.flow.rowmainContent12{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.row.rowrowmainContent14{

								
								position:relative !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.text.textrowmainContent11{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

									.text.textrowmainContent112{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.rectangle.searchZone{

								
								position:relative !important; 
								
								
								
								
								
								

									.button.buttonsearchZone1{

									height: auto;
									
									position:absolute !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

									.item.itemsearchZone4{

									
									position:absolute !important; 
									
									
									
									
									

										.textedit.texteditsearchZone3{

										
										position:absolute !important; 
										
										
										
										
										
										}

									
}

								
}

							
}

							.column.mainColumn{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgasearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.members{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.univSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.mentorSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.webinarsearchmainColumn13{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.blogSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.formationSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

				
}

				.scrollarea.scrollarearowcolumnslottemplatebasic01153{

				
				position:relative !important; 
				
				
				
				
				

					.column.columnscrollarearowcolumnslottemplatebasic011531{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.tagsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.categoriesCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.subCatCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.importedobject.importedobjectcolgridcolumncolumncolumn07129311{

						
						position:relative !important; 
						
						
						
						
						

						
}

						.object.univCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.studyLevelCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.fieldsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnslottemplatebasic0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowflowcolumnslottemplatebasic01131{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumnslottemplatebasic011351{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.button.buttonflowcolumnslottemplatebasic011354{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.rectangle.rectangleflowcolumnslottemplatebasic01133{

				
				position:relative !important; 
				
				
				
				
				
				

				
}

				.button.buttonflowcolumnslottemplatebasic01135{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.row.rowcolumnslottemplatebasic0115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.scrollarea.scrollarearowcolumnslottemplatebasic01155{

				
				position:relative !important; 
				
				
				
				
				

					.column.columncolumnslottemplatebasic011311{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.column.mainContent1{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.flow.rowmainContent12{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.row.rowrowmainContent14{

								
								position:relative !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.text.textrowmainContent11{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

									.text.textrowmainContent112{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.rectangle.searchZone{

								
								position:relative !important; 
								
								
								
								
								
								

									.button.buttonsearchZone1{

									height: auto;
									
									position:absolute !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

									.item.itemsearchZone4{

									
									position:absolute !important; 
									
									
									
									
									

										.textedit.texteditsearchZone3{

										
										position:absolute !important; 
										
										
										
										
										
										}

									
}

								
}

							
}

							.column.mainColumn{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgasearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.members{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.univSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.mentorSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.webinarsearchmainColumn13{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.blogSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.formationSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

				
}

				.scrollarea.scrollarearowcolumnslottemplatebasic01153{

				
				position:relative !important; 
				
				
				
				
				

					.column.columnscrollarearowcolumnslottemplatebasic011531{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.tagsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.categoriesCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.subCatCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.importedobject.importedobjectcolgridcolumncolumncolumn07129311{

						
						position:relative !important; 
						
						
						
						
						

						
}

						.object.univCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.studyLevelCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.fieldsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnslottemplatebasic0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowflowcolumnslottemplatebasic01131{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumnslottemplatebasic011351{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					font-size:15px ; 
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					font-size:15px ; 
					}
					}

				
}

				.button.buttonflowcolumnslottemplatebasic011354{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				font-size:15px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:15px ; 
				}
				}

				.rectangle.rectangleflowcolumnslottemplatebasic01133{

				
				position:relative !important; 
				
				
				
				
				 display:block !important; 
				
				

				
}

				.button.buttonflowcolumnslottemplatebasic01135{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				font-size:15px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:15px ; 
				}
				}

			
}

			.row.rowcolumnslottemplatebasic0115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.scrollarea.scrollarearowcolumnslottemplatebasic01155{

				
				position:relative !important; 
				
				
				
				
				

					.column.columncolumnslottemplatebasic011311{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.column.mainContent1{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.flow.rowmainContent12{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.row.rowrowmainContent14{

								
								position:relative !important; 
								
								
								
								
								width:auto!important ; 
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.text.textrowmainContent11{

									
									position:relative !important; 
									
									
									
									
									width:auto!important ; 
									
									.text-content{
									width: 100%;
									}

									
}

									.text.textrowmainContent112{

									
									position:relative !important; 
									
									
									
									
									width:auto!important ; 
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.rectangle.searchZone{

								
								position:relative !important; 
								
								
								
								
								width:auto!important ; 
								
								

									.button.buttonsearchZone1{

									height: auto;
									
									position:absolute !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

									.item.itemsearchZone4{

									
									position:absolute !important; 
									
									
									
									
									

										.textedit.texteditsearchZone3{

										
										position:absolute !important; 
										
										
										
										
										
										}

									
}

								
}

							
}

							.column.mainColumn{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgasearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.members{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.univSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.mentorSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.webinarsearchmainColumn13{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.blogSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.formationSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

				
}

				.scrollarea.scrollarearowcolumnslottemplatebasic01153{

				
				position:relative !important; 
				
				
				
				
				

					.column.columnscrollarearowcolumnslottemplatebasic011531{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.tagsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.categoriesCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.subCatCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.importedobject.importedobjectcolgridcolumncolumncolumn07129311{

						
						position:relative !important; 
						
						
						
						
						

						
}

						.object.univCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.studyLevelCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.fieldsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnslottemplatebasic0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowflowcolumnslottemplatebasic01131{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumnslottemplatebasic011351{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.button.buttonflowcolumnslottemplatebasic011354{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.rectangle.rectangleflowcolumnslottemplatebasic01133{

				
				position:relative !important; 
				
				
				
				
				
				

				
}

				.button.buttonflowcolumnslottemplatebasic01135{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.row.rowcolumnslottemplatebasic0115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.scrollarea.scrollarearowcolumnslottemplatebasic01155{

				
				position:relative !important; 
				
				
				
				
				

					.column.columncolumnslottemplatebasic011311{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.column.mainContent1{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.flow.rowmainContent12{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.row.rowrowmainContent14{

								
								position:relative !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.text.textrowmainContent11{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

									.text.textrowmainContent112{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.rectangle.searchZone{

								
								position:relative !important; 
								
								
								
								
								
								

									.button.buttonsearchZone1{

									height: auto;
									
									position:absolute !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

									.item.itemsearchZone4{

									
									position:absolute !important; 
									
									
									
									
									

										.textedit.texteditsearchZone3{

										
										position:absolute !important; 
										
										
										
										
										
										}

									
}

								
}

							
}

							.column.mainColumn{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgasearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.members{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.univSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.mentorSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.webinarsearchmainColumn13{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.blogSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.formationSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

				
}

				.scrollarea.scrollarearowcolumnslottemplatebasic01153{

				
				position:relative !important; 
				
				
				
				
				

					.column.columnscrollarearowcolumnslottemplatebasic011531{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.tagsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.categoriesCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.subCatCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.importedobject.importedobjectcolgridcolumncolumncolumn07129311{

						
						position:relative !important; 
						
						
						
						
						

						
}

						.object.univCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.studyLevelCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.fieldsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.object.basic01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatebasic01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatebasic011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnslottemplatebasic0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.flow.flowflowcolumnslottemplatebasic01131{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonflowcolumnslottemplatebasic011351{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.button.buttonflowcolumnslottemplatebasic011354{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.rectangle.rectangleflowcolumnslottemplatebasic01133{

				
				position:relative !important; 
				
				
				
				
				
				

				
}

				.button.buttonflowcolumnslottemplatebasic01135{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.row.rowcolumnslottemplatebasic0115{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.scrollarea.scrollarearowcolumnslottemplatebasic01155{

				
				position:relative !important; 
				
				
				
				
				

					.column.columncolumnslottemplatebasic011311{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.column.mainContent1{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.flow.rowmainContent12{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.row.rowrowmainContent14{

								
								position:relative !important; 
								
								
								
								
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;

									.text.textrowmainContent11{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

									.text.textrowmainContent112{

									
									position:relative !important; 
									
									
									
									
									
									.text-content{
									width: 100%;
									}

									
}

								
}

								.rectangle.searchZone{

								
								position:relative !important; 
								
								
								
								
								
								

									.button.buttonsearchZone1{

									height: auto;
									
									position:absolute !important; 
									
									
									
									
									
									text-transform:unset ; 
									min-width: auto;
									i.v-icon{
									}
									}

									.item.itemsearchZone4{

									
									position:absolute !important; 
									
									
									
									
									

										.textedit.texteditsearchZone3{

										
										position:absolute !important; 
										
										
										
										
										
										}

									
}

								
}

							
}

							.column.mainColumn{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgasearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.members{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.univSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.mentorSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.webinarsearchmainColumn13{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.blogSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.formationSearch{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

					
}

				
}

				.scrollarea.scrollarearowcolumnslottemplatebasic01153{

				
				position:relative !important; 
				
				
				
				
				

					.column.columnscrollarearowcolumnslottemplatebasic011531{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.tagsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.categoriesCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.subCatCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.importedobject.importedobjectcolgridcolumncolumncolumn07129311{

						
						position:relative !important; 
						
						
						
						
						

						
}

						.object.univCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.studyLevelCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

						.object.fieldsCombo{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
