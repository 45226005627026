<template>
 <div>  
<div id="AdminOrganisationView"> 
         
 <AdminEntriesView :class="'object adminentriesview0 '"   id="adminentriesview0" ref="adminentriesview0"  v-bind:style="{}" :tableName = "'organisations'" 
 :formName = "'AdminOrganisationForm'" 
 :headers = "headers" 
 :editViewHeader = "$t('Admin.organisations')" 
 :filtersForGet = "filtersForGet" 
 :hasLocalization = "false" 
>
</AdminEntriesView>
 
      </div>
 </div> 
</template>
<script>
import AdminEntriesView from '../../../At/Components/AdminPage/AdminEntriesView'


export default {
	name: 'AdminOrganisationView',

    components: {AdminEntriesView,},
	computed: {
	
		filtersForGet: function() { 			
	return {	

		$or: [	

			{		

				president: {

					id: {

						$eq: this.$store.state.user.id

					}

				}

			},

			{		

				vicePresident: {

					id: {

						$eq: this.$store.state.user.id

					}

				}

			},

			{		

				generalSecretary: {

					id: {

						$eq: this.$store.state.user.id

					}

				}

			},

		]		

	}

}
,
		headers: function() { 		
	return [	

	  {

	    text: 'ID',

	    align: 'start',

	    sortable: true,

	    value: 'id'

	  },

	  {

	    text: this.$t('Admin.name'),

	    align: 'start',

	    sortable: true,

	    value: 'name'

	  },

	  {

	    text: this.$t('Admin.createdAt'),

	    align: 'start',

	    sortable: true,

	    value: 'createdAt'

	  },

	  {

	    text: this.$t('Admin.updatedAt'),

	    align: 'start',

	    sortable: true,

	    value: 'updatedAt'

	  },

	  {

	    text: this.$t('Admin.actions'),

	    align: 'start',

	    sortable: false,

	    value: 'actions'

	  },

	]

}
,
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminOrganisationView{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.object.adminentriesview0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


@media (max-width: 600px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 601px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 961px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1265px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1905px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}
}
</style>
