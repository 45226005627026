<template>
 <div>  
<div id="Skeleton"> 
         
 <v-card 
 :class="'card card0 ' + ' ' + 'skeleton'"   id="card0" ref="card0"  v-bind:style="{backgroundColor : '#00000029',}">  </v-card>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'Skeleton',

    components: {},
} 
</script>

<style lang = "scss" scoped>


 div#Skeleton{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.skeleton {
	overflow: hidden;
	
	&::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .2), transparent);
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {  
  100% {
    transform: translateX(100%);
  }
}



.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#00000029 ; 
border-radius:2px ; max-width: initial;min-width: initial;


}


@media (max-width: 600px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;


}
}

@media (min-width: 601px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;


}
}

@media (min-width: 961px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;


}
}

@media (min-width: 1265px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;


}
}

@media (min-width: 1905px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;


}
}
}
</style>
