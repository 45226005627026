<template>
 <div>  
<div id="AdminEditView"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :text = "true" 
 @click="back" :class="'button buttoncolumn05 '"   id="buttoncolumn05" ref="buttoncolumn05"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-md-arrow-back' !== undefined"  :left = "true" 
>{{ 'ion-md-arrow-back' }}
</v-icon><span>{{ $t('Admin.back') }}</span>
</v-btn>
 <div  :class="'flow flowcolumn03 '"   id="flowcolumn03" ref="flowcolumn03"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'column columnflowcolumn032 '"   id="columnflowcolumn032" ref="columnflowcolumn032"  v-bind:style="{}">
 <div  :class="'text textflowcolumn031 '"   id="textflowcolumn031" ref="textflowcolumn031"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>  </div>
 <div  :class="'flow flowflowcolumn035 '"   id="flowflowcolumn035" ref="flowflowcolumn035"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :disabled = "changed" 
 :dark = "true" 
 @click="updateEntry" :class="'button buttonflowcolumn0331 '"   id="buttonflowcolumn0331" ref="buttonflowcolumn0331" v-if="!isNewItem"  v-bind:style="{}"><span>{{ $t('Admin.save') }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :disabled = "changed" 
 :dark = "true" 
 @click="createEntry" :class="'button buttonflowcolumn03312 '"   id="buttonflowcolumn03312" ref="buttonflowcolumn03312" v-if="isNewItem"  v-bind:style="{}"><span>{{ $t('Admin.create') }}</span>
</v-btn>  </div>  </div>
 <div  :class="'flow flowcolumn07 '"   id="flowcolumn07" ref="flowcolumn07" v-if="hasLocalization"  v-bind:style="{justifyContent : 'center',backgroundColor : lightPrimaryColor,borderColor : primaryColor,}">
 <v-btn tag="div" 
 :color = "currentLang == index ? primaryColor : '#eaeaef'" 
 :disabled = "entityID == null && index != 0" 
 :dark = "currentLang == index" 
 @click="changeLanguage(index)" :class="'button buttonflowcolumn03311 ' + `elevation-${0}`"   id="buttonflowcolumn03311" ref="buttonflowcolumn03311" v-for="(item,index) in languageList" :key="'buttonflowcolumn03311' + index" :elevation = "0" 
 :_border = "'solid 1px #dcdce4'" 
  v-bind:style="{border : 'solid 1px #dcdce4',}"><span>{{ item }}</span>
</v-btn>
 <div  :class="'text textflowcolumn073 '"   id="textflowcolumn073" ref="textflowcolumn073"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('Admin.lossInfoBySwitch') }}</div>

   </div>  </div>
 <div  :class="'column columncolumn07 ' + ' ' + 'elevation-1'"   id="columncolumn07" ref="columncolumn07"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'slot slotcolumncolumn071 '"   id="slotcolumncolumn071" ref="slotcolumncolumn071"  v-bind:style="{}"><slot  :name = "'formContent'" 
></slot></div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'AdminEditView',

    components: {},
	methods: {
	
		changeLanguage: function(index) { 		
	this.currentLang = index

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		lightPrimaryColor: function() { 		
	return this.$store.state.lightPrimaryColor

}
,
		title: function() { 		
	if(this.isNewItem) return this.$t('Admin.newEntry')

	return this.entityName

}
,
		languageList: function() { 		
	return[

		"Francais",

		"English",

		"Deutsh"

	]

}
,
		localeLang: function() { 		
	if(this.currentLang == 0) return 'fr'

	if(this.currentLang == 1) return 'en'

	if(this.currentLang == 2) return 'de'

	return 'en'

}
,
		isNewItem: function() { 		
	return this.entityName == null

}
,
	},
	watch: {
	
		localeLang: function(val) { 		
	this.switchLocalization(val)

}
,
	},
	data(){
	return {
		
		currentLang:  0		,
		}
	},
	props: {
	
		entityID: {					required: false,			default: () =>  null,		},
		entityName: {					required: false,			default: () =>  null,		},
		changed: {					required: false,			default: () =>  false,		},
		hasLocalization: {			type: Boolean,			required: false,			default: () =>  true,		},
		back: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		createEntry: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		updateEntry: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		switchLocalization: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminEditView{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 
padding-top:10px ; 
padding-bottom:10px ; 
padding-left:10px ; 
padding-right:10px ; 

.button.buttoncolumn05{

height: auto;
align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:40px!important ; 

text-transform:unset ; 
min-width: auto;
padding-left: 16px;
padding-right: 16px;

i.v-icon{
}
}

.flow.flowcolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
justify-content:space-between ; background-color:transparent ; 

	.column.columnflowcolumn032{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:4px ; background-color: transparent ; 

		.text.textflowcolumn031{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		font-size:30px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

	
}

	.flow.flowflowcolumn035{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: wrap;
	flex: initial;
	gap:7px ; background-color:transparent ; 

		.button.buttonflowcolumn0331{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:34px!important ; 
		
		font-size:14px ; 
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		font-size:14px ; 
		}
		}

		.button.buttonflowcolumn03312{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:34px!important ; 
		
		font-size:14px ; 
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		font-size:14px ; 
		}
		}

	
}


}

.flow.flowcolumn07{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
gap:5px ; 
justify-content:center ; background-color:transparent ; 
border-radius:8px ; 
border-style:solid ; 
border-width:1px ; 
border-color:undefined ; 
padding-top:10px ; 
padding-bottom:10px ; 
padding-left:10px ; 
padding-right:10px ; 

	.button.buttonflowcolumn03311{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	height:34px!important ; 
	min-width: unsetpx!important ; 
	border:solid 1px #dcdce4 !important; 
	
	font-size:14px ; 
	text-transform:unset ; 
	
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	font-size:14px ; 
	}
	}

	.text.textflowcolumn073{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	font-size:14px ; 
	text-align:center;
	display: flex;
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn07{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:0px ; 
background-color:#fff ; 
border-radius:8px ; 
padding-top:24px ; 
padding-bottom:24px ; 
padding-left:24px ; 
padding-right:24px ; 

	.slot.slotcolumncolumn071{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn05{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.flowflowcolumn035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonflowcolumn0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonflowcolumn03312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.flow.flowcolumn07{


position:relative !important; 









	.button.buttonflowcolumn03311{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textflowcolumn073{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn07{


position:relative !important; 









	.slot.slotcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn05{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.flowflowcolumn035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonflowcolumn0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonflowcolumn03312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.flow.flowcolumn07{


position:relative !important; 









	.button.buttonflowcolumn03311{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textflowcolumn073{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn07{


position:relative !important; 









	.slot.slotcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 








padding-top:24px ; 
padding-bottom:24px ; 
padding-left:56px ; 
padding-right:56px ; 

.button.buttoncolumn05{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.flowflowcolumn035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonflowcolumn0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonflowcolumn03312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.flow.flowcolumn07{


position:relative !important; 









	.button.buttonflowcolumn03311{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	min-width: 200px!important ; 
	
	text-transform:unset ; 
	
	i.v-icon{
	}
	}

	.text.textflowcolumn073{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn07{


position:relative !important; 









	.slot.slotcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn05{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.flowflowcolumn035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonflowcolumn0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonflowcolumn03312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.flow.flowcolumn07{


position:relative !important; 









	.button.buttonflowcolumn03311{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textflowcolumn073{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn07{


position:relative !important; 









	.slot.slotcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn05{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.flow.flowcolumn03{


position:relative !important; 









	.column.columnflowcolumn032{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumn031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.flow.flowflowcolumn035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonflowcolumn0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonflowcolumn03312{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.flow.flowcolumn07{


position:relative !important; 









	.button.buttonflowcolumn03311{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textflowcolumn073{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn07{


position:relative !important; 









	.slot.slotcolumncolumn071{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}
}
</style>
