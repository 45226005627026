<template>
 <div>  
<div id="HomeBanner"> 
         
 <ContentWrapper :class="'object contentWrapper112 '"   id="contentWrapper112" ref="contentWrapper112"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper11 '"   id="slottemplatecontentWrapper11" ref="slottemplatecontentWrapper11"  v-bind:style="{}">
 <v-row  :class="'grid gridslottemplatecontentWrapper111 '"   id="gridslottemplatecontentWrapper111" ref="gridslottemplatecontentWrapper111"  v-bind:style="{}">
 <v-col  :class="'col colgridslottemplatecontentWrapper1111 '"   id="colgridslottemplatecontentWrapper1111" ref="colgridslottemplatecontentWrapper1111"  v-bind:style="{}" :cols = "12" 
 :sm = "6" 
><div class="col-container">
 <div  :class="'column columncolgridslottemplatecontentWrapper11111 '"   id="columncolgridslottemplatecontentWrapper11111" ref="columncolgridslottemplatecontentWrapper11111"  v-bind:style="{}">
 <div  :class="'text textcolumncolgridslottemplatecontentWrapper111111 '"   id="textcolumncolgridslottemplatecontentWrapper111111" ref="textcolumncolgridslottemplatecontentWrapper111111" :_fontWeight = "'200px'" 
  v-bind:style="{fontWeight : '200px',color : '#8f5849',}">
<h1 class="text-content" :style = "{}">{{ $t('Home.welcome') }}</h1>

   </div>
 <div  :class="'column columncolumncolgridslottemplatecontentWrapper111113 '"   id="columncolumncolgridslottemplatecontentWrapper111113" ref="columncolumncolgridslottemplatecontentWrapper111113"  v-bind:style="{}">
 <v-text-field  :placeholder = "$t('Home.email')" 
 :type = "'email'" 
 outlined :class="'textfield textfieldcolumncolumncolgridslottemplatecontentWrapper1111131 '"   id="textfieldcolumncolumncolgridslottemplatecontentWrapper1111131" ref="textfieldcolumncolumncolgridslottemplatecontentWrapper1111131" v-model="email" :autocomplete = "'email'" 
  v-bind:style="{}"></v-text-field>
 <v-text-field  :placeholder = "$t('Home.passWordInput')" 
 :append-icon = "passIcon" 
 :type = "(showPass? 'text' : 'password')" 
 outlined :class="'textfield textfieldcolumncolumncolgridslottemplatecontentWrapper11111312 '"   id="textfieldcolumncolumncolgridslottemplatecontentWrapper11111312" ref="textfieldcolumncolumncolgridslottemplatecontentWrapper11111312" v-model="pass" :autocomplete = "'new-password'" 
  v-bind:style="{}" @click:append="toggelPass"></v-text-field>
 <div  :class="'text errorMessage63 '"   id="errorMessage63" ref="errorMessage63" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>  </div>
 <div  :class="'text textcolumncolgridslottemplatecontentWrapper111115 '"   id="textcolumncolgridslottemplatecontentWrapper111115" ref="textcolumncolgridslottemplatecontentWrapper111115"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Home.joinText') }}</div>

   </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :rounded = "true" 
 :dark = "true" 
 @click="signIn" :class="'button buttoncolumncolgridslottemplatecontentWrapper111117 '"   id="buttoncolumncolgridslottemplatecontentWrapper111117" ref="buttoncolumncolgridslottemplatecontentWrapper111117"  v-bind:style="{}"><span>{{ $t('Home.agreeJoin') }}</span>
</v-btn>  </div></div></v-col>
 <v-col  :class="'col colgridslottemplatecontentWrapper11112 '"   id="colgridslottemplatecontentWrapper11112" ref="colgridslottemplatecontentWrapper11112"  v-bind:style="{}" :cols = "12" 
 :sm = "6" 
><div class="col-container">
 <v-img  :class="'image imagecolgridslottemplatecontentWrapper111121 '"   id="imagecolgridslottemplatecontentWrapper111121" ref="imagecolgridslottemplatecontentWrapper111121"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../../Resources/Home/firstVector.svg')" 
>  </v-img></div></v-col>  </v-row></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../../At/Utils/ContentWrapper'

import * as Request from '../../../Js/requests'
import * as Main from '../../../Js/main'
export default {
	name: 'HomeBanner',

    components: {ContentWrapper,},
	methods: {
	
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true	

}
,
		toggelPass: function() { 		
	this.showPass = !this.showPass

}
,
		signIn: function(){ 		
	// check email

	if(!Main.validateEmail(this.email)){

		this.showError(this.$t('Signup.provideGoodMail'))	

		return

	}

	// check pass

	if(this.pass == null){

		this.showError(this.$t('Signup.enterPass'))		

		return

	}	

	

	this.$router.push(`/signup?email=${this.email}&pass=${this.pass}`)

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		passIcon: function(){		
	if(this.showPass){

		return 'ion-ios-eye'

	} else {

		return 'ion-ios-eye-off'

	}

}
,
	},
	data(){
	return {
		
		email:  null		,
		pass:  null		,
		showPass:  false		,
		errorVisible:  false		,
		error:  "..."		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#HomeBanner{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.contentWrapper112{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper11{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper111{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	background-color: transparent ; 

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:10px ; background-color: transparent ; 

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				fontWeight:200px !important; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#8f5849 ; 
				line-height:30px ; 
				font-size:15px ; 
				.text-content{
				width: 100%;
				}

				
}

				.column.columncolumncolgridslottemplatecontentWrapper111113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:0px ; background-color: transparent ; 

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper1111131{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					autocomplete:email !important; 
					width: calc(100% - (0px + 0px)) !important;
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper11111312{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					autocomplete:new-password !important; 
					width: calc(100% - (0px + 0px)) !important;
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.text.errorMessage63{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#ff1a05 ; 
					line-height:10px ; 
					font-size:10px ; 
					text-align:left;
					display: flex;
					.text-content{
					width: 100%;
					font-weight: bold;text-decoration: underline
					}

					
}

				
}

				.text.textcolumncolgridslottemplatecontentWrapper111115{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				font-size:13px ; 
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper111117{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:56px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.image.imagecolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			height:auto!important ; 
			min-width: 374px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			
}

		
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.contentWrapper112{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.column.columncolumncolgridslottemplatecontentWrapper111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper1111131{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper11111312{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.text.errorMessage63{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textcolumncolgridslottemplatecontentWrapper111115{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper111117{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.image.imagecolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentWrapper112{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.column.columncolumncolgridslottemplatecontentWrapper111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper1111131{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper11111312{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.text.errorMessage63{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textcolumncolgridslottemplatecontentWrapper111115{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper111117{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.image.imagecolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			min-width: 700px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentWrapper112{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				line-height:56px ; 
				font-size:28px ; 
				.text-content{
				width: 100%;
				}

				
}

				.column.columncolumncolgridslottemplatecontentWrapper111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper1111131{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper11111312{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.text.errorMessage63{

					
					position:relative !important; 
					
					
					
					
					
					line-height:15px ; 
					font-size:15px ; 
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textcolumncolgridslottemplatecontentWrapper111115{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper111117{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.image.imagecolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentWrapper112{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.column.columncolumncolgridslottemplatecontentWrapper111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper1111131{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper11111312{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.text.errorMessage63{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textcolumncolgridslottemplatecontentWrapper111115{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper111117{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.image.imagecolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentWrapper112{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper111{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper1111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper11111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolgridslottemplatecontentWrapper111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.column.columncolumncolgridslottemplatecontentWrapper111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper1111131{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.textfield.textfieldcolumncolumncolgridslottemplatecontentWrapper11111312{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

					.text.errorMessage63{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.textcolumncolgridslottemplatecontentWrapper111115{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumncolgridslottemplatecontentWrapper111117{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper11112{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.image.imagecolgridslottemplatecontentWrapper111121{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}
}
</style>
