<template>
 <div>  
<div id="AdminPageForm"> 
         
 <div  :class="'column columncolumncolumn0712 '"   id="columncolumncolumn0712" ref="columncolumncolumn0712"  v-bind:style="{}">
 <div  :class="'column columncolumncolumncolumn07125 '"   id="columncolumncolumncolumn07125" ref="columncolumncolumncolumn07125" v-if="entityID"  v-bind:style="{}">
 <div  :class="'text textcolumncolumncolumncolumn071251 '"   id="textcolumncolumncolumncolumn071251" ref="textcolumncolumncolumncolumn071251"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Admin.pageLink') }}</div>

   </div>
 <a  :href = "pageLink" 
 :class="'link linkcolumncolumncolumncolumn071253 '"   id="linkcolumncolumncolumncolumn071253" ref="linkcolumncolumncolumncolumn071253" :target = "'_blank'" 
  v-bind:style="{}">
 <div  :class="'text textlinkcolumncolumncolumncolumn0712531 '"   id="textlinkcolumncolumncolumncolumn0712531" ref="textlinkcolumncolumncolumncolumn0712531"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ pageLink }}</div>

   </div>  </a>  </div>
 <v-row  :class="'grid gridcolumncolumncolumn07129 '"   id="gridcolumncolumncolumn07129" ref="gridcolumncolumncolumn07129"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumncolumn0712957 '"   id="colgridcolumncolumncolumn0712957" ref="colgridcolumncolumncolumn0712957"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "$t('Admin.name') + '*'" 
 :dense = "true" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal0113111 '"   id="textfieldcolumnslottemplatedialogboxmodal0113111" ref="textfieldcolumnslottemplatedialogboxmodal0113111" v-model="name"  v-bind:style="{color : primaryColor,}"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712954 '"   id="colgridcolumncolumncolumn0712954" ref="colgridcolumncolumncolumn0712954"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-text-field  :label = "'slug'" 
 :append-icon = "'ion-md-refresh'" 
 :dense = "true" 
 :type = "'text'" 
 outlined :class="'textfield textfieldcolumnslottemplatedialogboxmodal01131131 '"   id="textfieldcolumnslottemplatedialogboxmodal01131131" ref="textfieldcolumnslottemplatedialogboxmodal01131131" v-model="slug"  v-bind:style="{color : primaryColor,}" @click:append="generateSlug"></v-text-field></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn071291 '"   id="colgridcolumncolumncolumn071291" ref="colgridcolumncolumncolumn071291"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <AdminMediaPicker :class="'object adminmediapickercolumncolumncolumn07191 '"   id="adminmediapickercolumncolumncolumn07191" ref="adminmediapickercolumncolumncolumn07191"  v-bind:style="{}" :updateItem = "setIcon" 
 :title = "'icon'" 
 :type = "'image'" 
 :item = "icon" 
 :entryID = "entityID" 
>
</AdminMediaPicker></div></v-col>
 <v-col  :class="'col colgridcolumncolumncolumn0712955 '"   id="colgridcolumncolumncolumn0712955" ref="colgridcolumncolumncolumn0712955"  v-bind:style="{}" :cols = "12" 
><div class="col-container">
 <div  :class="'column columncolgridcolumncolumncolumn07129551 '"   id="columncolgridcolumncolumncolumn07129551" ref="columncolgridcolumncolumncolumn07129551"  v-bind:style="{backgroundColor : lightPrimaryColor,borderColor : primaryColor,}">
 <div  :class="'icon iconcolumncolgridcolumncolumncolumn071295511 '"   id="iconcolumncolgridcolumncolumncolumn071295511" ref="iconcolumncolgridcolumncolumncolumn071295511"  v-bind:style="{color : primaryColor,fontSize : getUnit(30,'px'),}"><i  :class="'ion-ios-brush'"></i></div>
 <div  :class="'text textcolumncolgridcolumncolumncolumn071295513 '"   id="textcolumncolgridcolumncolumncolumn071295513" ref="textcolumncolgridcolumncolumncolumn071295513"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('Admin.openDesigner') }}</div>

   </div>
 <div  :class="'mousearea mouseareacolumncolgridcolumncolumncolumn071295515 '"   id="mouseareacolumncolgridcolumncolumncolumn071295515" ref="mouseareacolumncolgridcolumncolumncolumn071295515"  v-bind:style="{}" @click="m_openDesigner"></div>  </div></div></v-col>  </v-row>
 <div  :class="'text errorText '"   id="errorText" ref="errorText" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import AdminMediaPicker from '../../../../At/Components/AdminPage/AdminMediaPicker'

import * as Main from '../../../../Js/main'
import * as Request from '../../../../Js/requests'
export default {
	name: 'AdminPageForm',

    components: {AdminMediaPicker,},
	methods: {
	
		generateSlug: function() { 		
	if(this.name){

		this.slug = this.name.replaceAll(/\s+/g, '-')

	}

}
,
		setIcon: function(val) { 		
	this.m_icon = val

}
,
		loadData: function() { 		
	this.name = this.entity?.name

	this.slug = this.entity?.slug

	this.code = this.entity?.code

	this.htmlCode = this.entity?.htmlCode

	

	this.icon = this.entity?.icon.data

	

	this.loading = false

}
,
		chargeDate: function() { 		
	let data = {}

	

	if(this.parentID){ data.project = this.parentID }

	if(this.name){ data.name = this.name }

	if(this.slug){ data.slug = this.slug }

	if(this.code){ data.code = this.code }

	if(this.htmlCode){ data.htmlCode = this.htmlCode }

	

	return data

}
,
		getDesignerData: function(code, htmlCode) { 		
	this.code = code

	this.htmlCode = htmlCode

	

	if(this.entityID){

		this.updateEntry()

	}

}
,
		m_openDesigner: function() { 		
	this.openDesigner(this.code, this.pageLink)

}
,
		createEntry: function() { 		
	let data = this.chargeDate()

	data.owner = this.$store.state.user.id

	

	data.publishedAt = new Date().toISOString()

	

  const formData = new FormData();

	

	if(this.m_icon){	

		formData.append(`files.icon`, this.m_icon, this.m_icon.name);

	}

	

	formData.append('data', JSON.stringify(data));

	

	Request.createEntry(this.tableName,formData)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryCreated')) 

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		updateEntry: function() { 		
	let data = this.chargeDate()

	

	if(this.m_icon){	

		data.icon = this.m_icon

	}

		

	Request.updateEntry(this.tableName, this.entity.id, data)

	.then(res => {

		if(res.data.error){

			this.showError(

				res.data.error.details.errors[0].path.toString()

				+ ' ' + res.data.error.details.errors[0].message

			)

		} else {	

			this.$awn.success(this.$t('Admin.entryUpdated'))

			this.updateSelectedItem(res.data.data.id)

		}

	})

	.catch(error => {

		this.showError(

			error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true	

	this.$awn.alert(message)

}
,
	},
	mounted(){
			
	this.loadData()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		lightPrimaryColor: function() { 		
	return this.$store.state.lightPrimaryColor

}
,
		entityID: function() { 		
	return this.entity?.id

}
,
		pageLink: function() { 		
	if(this.slug == null) return null

	return this.$store.state.webUrl + '/sites/' + this.slug

}
,
	},
	watch: {
	
		name: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		slug: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		code: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		htmlCode: function(val) { 		
	if(!this.loading) this.changed = true

}
,
		m_icon: function(val) { 		
	if(!this.loading) this.changed = true

}
,
	},
	data(){
	return {
		
		name:  null		,
		slug:  null		,
		code:  null		,
		htmlCode:  null		,
		icon:  null		,
		m_icon:  null		,
		loading:  true		,
		changed:  false		,
		error:  null		,
		errorVisible:  false		,
		tableName:  'pages'		,
		}
	},
	props: {
	
		parentID: {					required: false,			default: () =>  null,		},
		entity: {					required: false,			default: () =>  null,		},
		updateSelectedItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		openDesigner: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminPageForm{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.columncolumncolumn0712{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:8px ; background-color: transparent ; 

.column.columncolumncolumncolumn07125{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.text.textcolumncolumncolumncolumn071251{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	.text-content{
	width: 100%;
	}

	
}

	.link.linkcolumncolumncolumncolumn071253{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	target:_blank !important; 
	
	display: block;

		.text.textlinkcolumncolumncolumncolumn0712531{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:12px ; 
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:auto!important ; 
	

	::v-deep .col-container{position: relative;

	height: auto;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:200px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumncolumn07129551{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:8px ; background-color: transparent ; 
		border-radius:8px ; 
		border-style:solid ; 
		border-width:1px ; 
		border-color:undefined ; 
		padding-top:10px ; 
		padding-bottom:10px ; 
		padding-left:10px ; 
		padding-right:10px ; 

			.icon.iconcolumncolgridcolumncolumncolumn071295511{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			
			font-size:30px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolgridcolumncolumncolumn071295513{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			text-align:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

			.mousearea.mouseareacolumncolgridcolumncolumncolumn071295515{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

color:#ff1a05 ; 
line-height:10px ; 
font-size:10px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;text-decoration: underline
}


}


}


@media (max-width: 600px) {


.column.columncolumncolumn0712{


position:relative !important; 









.column.columncolumncolumncolumn07125{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071251{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.link.linkcolumncolumncolumncolumn071253{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumncolumncolumn0712531{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumncolumn07129551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolgridcolumncolumncolumn071295511{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolgridcolumncolumncolumn071295513{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.mousearea.mouseareacolumncolgridcolumncolumncolumn071295515{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.column.columncolumncolumn0712{


position:relative !important; 









.column.columncolumncolumncolumn07125{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071251{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.link.linkcolumncolumncolumncolumn071253{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumncolumncolumn0712531{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumncolumn07129551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolgridcolumncolumncolumn071295511{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolgridcolumncolumncolumn071295513{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.mousearea.mouseareacolumncolgridcolumncolumncolumn071295515{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.column.columncolumncolumn0712{


position:relative !important; 









.column.columncolumncolumncolumn07125{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071251{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.link.linkcolumncolumncolumncolumn071253{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumncolumncolumn0712531{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumncolumn07129551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolgridcolumncolumncolumn071295511{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolgridcolumncolumncolumn071295513{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.mousearea.mouseareacolumncolgridcolumncolumncolumn071295515{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





line-height:15px ; 
font-size:15px ; 
.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.column.columncolumncolumn0712{


position:relative !important; 









.column.columncolumncolumncolumn07125{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071251{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.link.linkcolumncolumncolumncolumn071253{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumncolumncolumn0712531{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumncolumn07129551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolgridcolumncolumncolumn071295511{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolgridcolumncolumncolumn071295513{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.mousearea.mouseareacolumncolgridcolumncolumncolumn071295515{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.column.columncolumncolumn0712{


position:relative !important; 









.column.columncolumncolumncolumn07125{


position:relative !important; 









	.text.textcolumncolumncolumncolumn071251{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.link.linkcolumncolumncolumncolumn071253{

	
	position:relative !important; 
	
	
	
	
	
	display: block;

		.text.textlinkcolumncolumncolumncolumn0712531{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.grid.gridcolumncolumncolumn07129{


position:relative !important; 






	.col.colgridcolumncolumncolumn0712957{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal0113111{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn0712954{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.textfield.textfieldcolumnslottemplatedialogboxmodal01131131{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	
}

	.col.colgridcolumncolumncolumn071291{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.adminmediapickercolumncolumncolumn07191{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumncolumncolumn0712955{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.column.columncolgridcolumncolumncolumn07129551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolgridcolumncolumncolumn071295511{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolgridcolumncolumncolumn071295513{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.mousearea.mouseareacolumncolgridcolumncolumncolumn071295515{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}

	
}


}

.text.errorText{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
