<template>
 <div>  
<div id="DialogBoxModal"> 
         
 <div  :class="'scrollarea item0 '"   id="item0" ref="item0"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectangleitem011 '"   id="mousearearectangleitem011" ref="mousearearectangleitem011" v-if="closable"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'column carditem03 '"   id="carditem03" ref="carditem03"  v-bind:style="{width : (mqVal({default:'90%',md:getUnit(maxWidth,'px')}, $mq)),height : getUnit(fullHeight? 'calc(100vh - 160px)' : 'auto','px'),backgroundColor : '#fff',}">
 <div  :class="'row rowcarditem037 '"   id="rowcarditem037" ref="rowcarditem037"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textrowcarditem0373 '"   id="textrowcarditem0373" ref="textrowcarditem0373" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="close" :class="'button buttoncarditem0331 '"   id="buttoncarditem0331" ref="buttoncarditem0331" v-if="closable"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-close' !== undefined" >{{ 'ion-md-close' }}
</v-icon></v-btn>  </div>
 <div  :class="'rectangle divider '"   id="divider" ref="divider"  v-bind:style="{backgroundColor : '#000',}">  </div>
 <div  :class="'scrollarea scrollareacolumncarditem03334 '"   id="scrollareacolumncarditem03334" ref="scrollareacolumncarditem03334" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'column columncarditem0331 '"   id="columncarditem0331" ref="columncarditem0331"  v-bind:style="{}">
 <div  :class="'slot slotcolumncarditem0331 '"   id="slotcolumncarditem0331" ref="slotcolumncarditem0331"  v-bind:style="{}"><slot  :name = "'content'" 
></slot></div>  </div>  </div>
 <div  :class="'column columncarditem039 '"   id="columncarditem039" ref="columncarditem039" v-if="hasAction"  v-bind:style="{}">
 <div  :class="'rectangle divider41 '"   id="divider41" ref="divider41"  v-bind:style="{backgroundColor : '#000',}">  </div>
 <div  :class="'row rowcolumncarditem0393 '"   id="rowcolumncarditem0393" ref="rowcolumncarditem0393"  v-bind:style="{justifyContent : 'flex-end',}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 @click="action" :class="'button buttonrowcolumncarditem03931 '"   id="buttonrowcolumncarditem03931" ref="buttonrowcolumncarditem03931"  v-bind:style="{}"><span>{{ actionName }}</span>
</v-btn>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'DialogBoxModal',

    components: {},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
	},
	props: {
	
		title: {					required: false,			default: () =>  "Les fondements de l'architecture logicielle",		},
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		fullHeight: {			type: Boolean,			required: false,			default: () =>  false,		},
		closable: {			type: Boolean,			required: false,			default: () =>  true,		},
		actionName: {					required: false,			default: () =>  "Action",		},
		hasAction: {			type: Boolean,			required: false,			default: () =>  false,		},
		action: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		maxWidth: {					required: false,			default: () =>  950,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#DialogBoxModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.scrollarea.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

overflow-y:scroll ; 
background-color:transparent ; 

.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.4 ;  filter: alpha(opacity=40) ; 

background-color:#000 ; 

	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.column.carditem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
max-height: calc(100vh - 160px)!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; 
background-color:#fff ; 
border-radius:8px ; 

	.row.rowcarditem037{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:61px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:space-between ; background-color:transparent ; 
	padding-top:16px ; 
	padding-bottom:16px ; 
	padding-left:24px ; 
	padding-right:24px ; 

		.text.textrowcarditem0373{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:auto!important ; 
		max-width: 90%!important ; 
		flex:1 !important; 
		height: calc(100% - (0px + 0px)) !important;
		
		font-size:20px ; 
		align-items:center;
		display: flex;
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		font-weight: bold;
		}

		
}

		.button.buttoncarditem0331{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:30px!important ; 
		height:30px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.rectangle.divider{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	opacity: 0.3 ;  filter: alpha(opacity=30) ; 
	height:1px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	background-color:#000 ; 

	
}

	.scrollarea.scrollareacolumncarditem03334{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	margin-bottom: 0px ; 
	flex:1 !important; 
	width: calc(100% - (0px + 0px)) !important;
	height: calc(100% - (0px + 0px)) !important;
	
	overflow:hidden ; 
	overflow-x:hidden ; 
	overflow-y:scroll ; background-color:#ffffff ; 

		.column.columncarditem0331{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 
		padding-top:16px ; 
		padding-bottom:16px ; 
		padding-left:24px ; 
		padding-right:24px ; 

			.slot.slotcolumncarditem0331{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			}

		
}

	
}

	.column.columncarditem039{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:16px ; background-color: transparent ; 
	padding-bottom:16px ; 
	padding-left:24px ; 
	padding-right:24px ; 

		.rectangle.divider41{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		opacity: 0.3 ;  filter: alpha(opacity=30) ; 
		height:1px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		background-color:#000 ; 

		
}

		.row.rowcolumncarditem0393{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		justify-content:flex-end ; background-color:transparent ; 

			.button.buttonrowcolumncarditem03931{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:40px!important ; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 









	.row.rowcarditem037{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcarditem0373{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncarditem0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.rectangle.divider{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.scrollarea.scrollareacolumncarditem03334{

	
	position:relative !important; 
	
	
	
	
	

		.column.columncarditem0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotcolumncarditem0331{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}

	.column.columncarditem039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.divider41{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.row.rowcolumncarditem0393{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumncarditem03931{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 









	.row.rowcarditem037{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcarditem0373{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncarditem0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.rectangle.divider{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.scrollarea.scrollareacolumncarditem03334{

	
	position:relative !important; 
	
	
	
	
	

		.column.columncarditem0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotcolumncarditem0331{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}

	.column.columncarditem039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.divider41{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.row.rowcolumncarditem0393{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumncarditem03931{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 









	.row.rowcarditem037{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcarditem0373{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncarditem0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.rectangle.divider{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.scrollarea.scrollareacolumncarditem03334{

	
	position:relative !important; 
	
	
	
	
	

		.column.columncarditem0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotcolumncarditem0331{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}

	.column.columncarditem039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.divider41{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.row.rowcolumncarditem0393{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumncarditem03931{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 









	.row.rowcarditem037{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcarditem0373{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncarditem0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.rectangle.divider{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.scrollarea.scrollareacolumncarditem03334{

	
	position:relative !important; 
	
	
	
	
	

		.column.columncarditem0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotcolumncarditem0331{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}

	.column.columncarditem039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.divider41{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.row.rowcolumncarditem0393{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumncarditem03931{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 









	.row.rowcarditem037{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcarditem0373{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.buttoncarditem0331{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.rectangle.divider{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.scrollarea.scrollareacolumncarditem03334{

	
	position:relative !important; 
	
	
	
	
	

		.column.columncarditem0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotcolumncarditem0331{

			
			position:relative !important; 
			
			
			
			
			}

		
}

	
}

	.column.columncarditem039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.divider41{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.row.rowcolumncarditem0393{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumncarditem03931{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}
}
</style>
