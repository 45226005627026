<template>
 <div>  
<div id="PreviewModal"> 
         
 <router-link  :class="'routerlink routerlink0 '"   id="routerlink0" ref="routerlink0"  v-bind:style="{}" :to = "m_link" 
>
 <div  :class="'column card01 ' + `elevation-${elevation}`"   id="card01" ref="card01" :elevation = "elevation" 
 :overflow = "'hidden'" 
  v-bind:style="{backgroundColor : bgColor,borderWidth : getUnit(border,'px'), borderStyle:'solid' ,  }">
 <v-card 
 :class="'card cardContainer ' + `elevation-${videoElevation}`"   id="cardContainer" ref="cardContainer" :elevation = "videoElevation" 
 :overflow = "'hidden'" 
  v-bind:style="{borderRadius : hovered? 0 : 2,}">
<div class="artefactVid"  :class="'networkvideo networkvideo '"   id="networkvideo" ref="networkvideo"  v-bind:style="{}"> <video width="100%" height="100%"  :controls = "false" 
 :autoplay = "hovered" 
 :loop = "true" 
 :mute = "true" 
 :preload = "'auto'" 
 :poster = "m_image" 
>
<source  v-for='(itemVideoUrl,index) in preview' :key="index" :src="itemVideoUrl" >
</video> </div>
 <div  :class="'row rownetworkvideo12 '"   id="rownetworkvideo12" ref="rownetworkvideo12"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'text textrownetworkvideo11 '"   id="textrownetworkvideo11" ref="textrownetworkvideo11"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ 'popular' }}</div>

   </div>  </div>
 <div  :class="'row rownetworkvideo123 '"   id="rownetworkvideo123" ref="rownetworkvideo123"  v-bind:style="{backgroundColor : '#343434',}">
 <div  :class="'text textrownetworkvideo11 '"   id="textrownetworkvideo11" ref="textrownetworkvideo11"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ duration }}</div>

   </div>  </div>  </v-card>
 <div  :class="'column columncard03 '"   id="columncard03" ref="columncard03" :_flex = "'1'" 
  v-bind:style="{flex : '1',paddingLeft : getUnit(padding,'px'),paddingRight : getUnit(padding,'px'),}">
 <div  :class="'text textType '"   id="textType" ref="textType"  v-bind:style="{color : '#515151',}">
<div class="text-content" :style = "{}">{{ baseUrl }}</div>

   </div>
 <div  :class="'text textName '"   id="textName" ref="textName"  v-bind:style="{color : '#515151',}">
<div class="text-content" :style = "{}">{{ m_name }}</div>

   </div>
 <div  :class="'text textDate '"   id="textDate" ref="textDate"  v-bind:style="{color : '#515151',}">
<div class="text-content" :style = "{}">{{ 'By: ' + m_author + publishedDate }}</div>

   </div>
 <div  :class="'text textDescription1 '"   id="textDescription1" ref="textDescription1" v-if="hovered && !fromMoodle" :_flex = "'1'" 
 :_overflow = "'hidden'" 
  v-bind:style="{flex : '1',overflow : 'hidden',color : '#515151',}">
<div class="text-content" :style = "{}">{{ m_description }}</div>

   </div>
 <div  :class="'text textDescription2 '"   id="textDescription2" ref="textDescription2" v-if="hovered && fromMoodle" v-html="moodle_description" :_flex = "'1'" 
 :_overflow = "'hidden'" 
  v-bind:style="{flex : '1',overflow : 'hidden',color : '#515151',}">
<div class="text-content" :style = "{}"></div>

   </div>
 <div  :class="'row rowcard055 '"   id="rowcard055" ref="rowcard055" v-if="hovered"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textrowcard051 '"   id="textrowcard051" ref="textrowcard051"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ '56 mn 38s' }}</div>

   </div>
 <v-btn tag="div" 
 :text = "true" 
 :class="'button buttonrowcard0553 '"   id="buttonrowcard0553" ref="buttonrowcard0553"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-md-bookmark' !== undefined"  :left = "true" 
 :color = "'#424142'" 
>{{ 'ion-md-bookmark' }}
</v-icon><span>{{ 'save' }}</span>
</v-btn>  </div>  </div>  </div>  </router-link>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../Js/main'
export default {
	name: 'PreviewModal',

    components: {},
	methods: {
	
		playVideo: function() { 		
	this.videoItem.play()

}
,
		stopVideo: function() { 		
	this.videoItem.pause()

}
,
	},
	computed: {
	
		id: function(){		
	if(this.info){

		return this.info.id

	}

	return null

}
,
		attributes: function(){		
	if(this.info){

		return this.info.attributes

	}

	return null

}
,
		image: function() { 		
	if(this.attributes){

		if(this.attributes.image.data){

			let item = this.attributes.image.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return "https://picsum.photos/200"

	}

	return "https://picsum.photos/200"

}
,
		moodle_image: function() { 		
	if(this.moodleInfo != undefined && this.moodleInfo.overviewfiles != undefined){

		if(this.moodleInfo.overviewfiles.length > 0){

			return Main.getMoodleAssets(this.moodleInfo.overviewfiles[0].fileurl)

		}

		return "https://picsum.photos/200"

	}

	return "https://picsum.photos/200"

}
,
		m_image: function() { 		
	if(this.fromMoodle){

		return this.moodle_image

	}

	return this.image

}
,
		teaser: function() { 		
	if(this.attributes){

		if(this.attributes.video.data){

			let videoData = this.attributes.video.data.attributes

			return Main.getUrl(videoData)

		}

		return null

	}

	return null

}
,
		preview: function() { 		
	if(this.teaser){

		return [this.teaser]

	}

	return []

}
,
		name: function() { 		
	if(this.attributes){

		return this.attributes.name

	}

	return null

}
,
		moodle_name: function() { 		
	if(this.moodleInfo){

		return this.moodleInfo.displayname

	}

	return ""

}
,
		m_name: function() { 		
	if(this.fromMoodle){

		return this.moodle_name

	}

	return this.name

}
,
		author: function() { 		
	if(this.attributes){

		if(this.attributes.instructor){

			return this.attributes.instructor.data?.attributes.username

		}

		return this.$store.state.companyName

	}

	return this.$store.state.companyName

}
,
		moodle_author: function() { 		
	if(this.moodleInfo != undefined && this.moodleInfo.contacts != undefined){

		if(this.moodleInfo.contacts.length > 0){

			return this.moodleInfo.contacts[0].fullname

		}

		return this.$store.state.companyName

	}

	return this.$store.state.companyName

}
,
		m_author: function() { 		
	if(this.fromMoodle){

		return this.moodle_author

	}

	return this.author

}
,
		duration: function() { 		
	if(this.attributes){

		if(this.attributes.duration){

			return Main.convertHMS(this.attributes.duration)

		}

		return null

	}

	return null

}
,
		publishedDate: function() { 		
	if(this.attributes){

		if(this.hovered){

			var mydate = new Date(this.attributes.publishedAt);

			return ' ∘ ' + mydate.getMonth() + ',' + mydate.getFullYear()	

		} else {

			return ""

		}

	}

	return ""

}
,
		m_description: function() { 		
	var description = ""

	

	if(this.attributes){

		description = this.attributes.description

	}

	return description.slice(0, 100) + '...'

}
,
		moodle_description: function() { 		
	if(this.moodleInfo != undefined && this.moodleInfo.summary != undefined){

		if(this.moodleInfo.summary.length > 150) return this.moodleInfo.summary.slice(0, 150) + '...'

		return this.moodleInfo.summary

	}

	return "..."

}
,
		bgColor: function() { 		
	return this.hovered? "#fff" : "transparent"

}
,
		border: function() { 		
	return this.hovered? 1 : 0

}
,
		elevation: function() { 		
	return this.hovered? 2 : 0

}
,
		videoElevation: function() { 		
	return this.hovered? 0 : 2

}
,
		videoItem: function() { 		
	return this.$refs

		.networkvideo

		.querySelector("video");

}
,
		padding: function() { 		
	return this.hovered? 15 : 0

}
,
		baseLink: function() { 		
	return "/" + this.baseUrl + "/"

}
,
		link: function() { 		
	if(this.attributes){

		return this.baseLink + this.attributes.slug

	}

	return this.baseLink

}
,
		moodle_link: function() { 		
	if(this.moodleInfo){

		return this.baseLink + "m/" + this.moodleInfo.id

	}

	return this.baseLink

}
,
		m_link: function() { 		
	if(this.fromMoodle){

		return this.moodle_link

	}

	return this.link

}
,
	},
	watch: {
	
		hovered: function(val) { 		
	if(val){

		this.playVideo()

	} else {

		this.stopVideo()

	}

}
,
	},
	props: {
	
		info: {					required: false,			default: () =>  null,		},
		moodleInfo: {					required: false,			default: () =>  null,		},
		fromMoodle: {			type: Boolean,			required: false,			default: () =>  false,		},
		hovered: {					required: false,			default: () =>  false,		},
		baseUrl: {					required: false,			default: () =>  "",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#PreviewModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none !important;
	color: #e4580a;
}

.networkvideo{
	video{
		object-fit: cover;	
	}
}



.routerlink.routerlink0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;


.column.card01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
margin-bottom: 0px ; 
overflow:hidden !important; 
width: calc(100% - (0px + 0px)) !important;
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
border-radius:3px ; 

	.card.cardContainer{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:100%!important ; 
	overflow:hidden !important; 
	width: calc(100% - (0px + 0px)) !important;
	background-color:#ffffff ; max-width: initial;min-width: initial;

		.networkvideo.networkvideo{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		background-color: black;

		
}

		.row.rownetworkvideo12{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 5px ; 
		
		top: 5px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		background-color:#fff ; 
		border-radius:4px ; 
		padding-top:2px ; 
		padding-bottom:2px ; 
		padding-left:8px ; 
		padding-right:8px ; 

			.text.textrownetworkvideo11{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			color:#444444 ; 
			font-size:12px ; 
			text-transform:uppercase; 
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rownetworkvideo123{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		right: 0px ; 
		bottom: 0px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		background-color:#343434 ; 
		padding-top:2px ; 
		padding-bottom:2px ; 
		padding-left:8px ; 
		padding-right:8px ; 

			.text.textrownetworkvideo11{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			color:#fff ; 
			font-size:12px ; 
			text-transform:capitalize; 
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.column.columncard03{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	flex:1 !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:4px ; background-color: transparent ; 
	padding-top:10px ; 
	padding-bottom:10px ; 

		.text.textType{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#515151 ; 
		font-size:13px ; 
		text-transform:uppercase; 
		.text-content{
		width: 100%;
		}

		
}

		.text.textName{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#515151 ; 
		font-size:16px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.textDate{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#515151 ; 
		font-size:14px ; 
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		flex:1 !important; 
		overflow:hidden !important; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#515151 ; 
		font-size:15px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

		.text.textDescription2{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		flex:1 !important; 
		overflow:hidden !important; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#515151 ; 
		font-size:15px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

		.row.rowcard055{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:30px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		justify-content:space-between ; background-color:transparent ; 

			.text.textrowcard051{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			margin-bottom: 0px ; 
			width:auto!important ; 
			height: calc(100% - (0px + 0px)) !important;
			
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcard0553{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			display:none !important; 
			width:auto!important ; 
			height:30px!important ; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.routerlink.routerlink0{


position:relative !important; 






.column.card01{


position:relative !important; 









	.card.cardContainer{

	
	position:relative !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkvideo.networkvideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.row.rownetworkvideo12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rownetworkvideo123{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.column.columncard03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textType{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textName{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDate{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcard055{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrowcard051{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcard0553{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.routerlink.routerlink0{


position:relative !important; 






.column.card01{


position:relative !important; 









	.card.cardContainer{

	
	position:relative !important; 
	
	
	
	
	height:50%!important ; 
	max-width: initial;min-width: initial;

		.networkvideo.networkvideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.row.rownetworkvideo12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rownetworkvideo123{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.column.columncard03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textType{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textName{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDate{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcard055{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrowcard051{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcard0553{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.routerlink.routerlink0{


position:relative !important; 






.column.card01{


position:relative !important; 









	.card.cardContainer{

	
	position:relative !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkvideo.networkvideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.row.rownetworkvideo12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rownetworkvideo123{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.column.columncard03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textType{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textName{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDate{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcard055{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrowcard051{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcard0553{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.routerlink.routerlink0{


position:relative !important; 






.column.card01{


position:relative !important; 









	.card.cardContainer{

	
	position:relative !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkvideo.networkvideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.row.rownetworkvideo12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rownetworkvideo123{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.column.columncard03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textType{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textName{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDate{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcard055{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrowcard051{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcard0553{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.routerlink.routerlink0{


position:relative !important; 






.column.card01{


position:relative !important; 









	.card.cardContainer{

	
	position:relative !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkvideo.networkvideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.row.rownetworkvideo12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.row.rownetworkvideo123{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrownetworkvideo11{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.column.columncard03{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textType{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textName{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDate{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textDescription2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcard055{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.text.textrowcard051{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcard0553{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}
}
</style>
