<template>
 <div>  
<div id="ConfirmDialog"> 
         
 <div  :class="'scrollarea item0 '"   id="item0" ref="item0"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectangleitem011 '"   id="mousearearectangleitem011" ref="mousearearectangleitem011"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'column carditem03 '"   id="carditem03" ref="carditem03"  v-bind:style="{backgroundColor : '#fff',}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="close" :class="'button buttoncarditem033 '"   id="buttoncarditem033" ref="buttoncarditem033"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-close' !== undefined" >{{ 'ion-md-close' }}
</v-icon></v-btn>
 <div  :class="'text textcarditem031 '"   id="textcarditem031" ref="textcarditem031"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ message }}</div>

   </div>
 <div  :class="'flow rowcarditem035 '"   id="rowcarditem035" ref="rowcarditem035"  v-bind:style="{justifyContent : 'space-around',}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 @click="action" :class="'button buttonrowcarditem0351 '"   id="buttonrowcarditem0351" ref="buttonrowcarditem0351"  v-bind:style="{}"><span>{{ $t('ConfirmDialog.yes') }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#f0362f'" 
 :dark = "true" 
 @click="close" :class="'button buttonrowcarditem03512 '"   id="buttonrowcarditem03512" ref="buttonrowcarditem03512"  v-bind:style="{}"><span>{{ $t('ConfirmDialog.no') }}</span>
</v-btn>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ConfirmDialog',

    components: {},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
	},
	props: {
	
		message: {					required: false,			default: () =>  "...",		},
		action: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ConfirmDialog{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.scrollarea.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:transparent ; 

.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.4 ;  filter: alpha(opacity=40) ; 

background-color:#000 ; 

	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.column.carditem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:90%!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; 
background-color:#fff ; 
border-radius:8px ; 
padding-top:30px ; 
padding-bottom:15px ; 
padding-left:15px ; 
padding-right:15px ; 

	.button.buttoncarditem033{

	height: auto;
	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 10px ; 
	right: 10px ; 
	width:30px!important ; 
	height:30px!important ; 
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textcarditem031{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	font-size:20px ; 
	text-align:center;
	display: flex;
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcarditem035{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: wrap;
	flex: initial;
	justify-content:space-around ; background-color:transparent ; 

		.button.buttonrowcarditem0351{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:40px!important ; 
		min-width: 100px!important ; 
		
		text-transform:unset ; 
		
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.button.buttonrowcarditem03512{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		height:40px!important ; 
		min-width: 100px!important ; 
		
		text-transform:unset ; 
		
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

	
}


}


}


@media (max-width: 600px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 









	.button.buttoncarditem033{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textcarditem031{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcarditem035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonrowcarditem0351{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcarditem03512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 601px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 









	.button.buttoncarditem033{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textcarditem031{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcarditem035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonrowcarditem0351{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcarditem03512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 961px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 




width:500px!important ; 





	.button.buttoncarditem033{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textcarditem031{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcarditem035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonrowcarditem0351{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcarditem03512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1265px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 









	.button.buttoncarditem033{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textcarditem031{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcarditem035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonrowcarditem0351{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcarditem03512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1905px) {


.scrollarea.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.column.carditem03{


position:absolute !important; 









	.button.buttoncarditem033{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.text.textcarditem031{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.flow.rowcarditem035{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.button.buttonrowcarditem0351{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowcarditem03512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}
}
</style>
