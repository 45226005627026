<template>
 <div>  
<div id="MentorSearch"> 
         
 <ResultGroup :class="'object resultgroupmainColumn122 '"   id="resultgroupmainColumn122" ref="resultgroupmainColumn122"  v-bind:style="{}" :action = "m_selectEntity" 
 :title = "mentorFoundNumber" 
 :actionText = "moreText" 
 :actived = "selected" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateresultgroupmainColumn11 '"   id="slottemplateresultgroupmainColumn11" ref="slottemplateresultgroupmainColumn11"  v-bind:style="{}">
 <div  :class="'column columnslottemplateresultgroupmainColumn111 '"   id="columnslottemplateresultgroupmainColumn111" ref="columnslottemplateresultgroupmainColumn111"  v-bind:style="{}">
 <v-row  :class="'grid gridmainContent311 '"   id="gridmainContent311" ref="gridmainContent311"  v-bind:style="{}">
 <v-col  :class="'col colgridmainContent31 '"   id="colgridmainContent31" ref="colgridmainContent31" v-for="(item,index) in mentors" :key="'colgridmainContent31' + index"  v-bind:style="{}" :cols = "12" 
 :lg = "3" 
 :md = "4" 
 :sm = "6" 
><div class="col-container">
 <ProfileItem :class="'object profileitemcolgridmainContent3112 '"   id="profileitemcolgridmainContent3112" ref="profileitemcolgridmainContent3112"  v-bind:style="{}" :user = "item.attributes?.user?.data?.attributes" 
 :mentor = "item" 
>
</ProfileItem></div></v-col>  </v-row>
 <v-pagination 
 :color = "primaryColor" 
 :length = "nbMentorPages" 
 :class="'pagination orgaPagination '"   id="orgaPagination" ref="orgaPagination" v-if="nbMentorPages" v-model="mentorCurPage"  v-bind:style="{}"></v-pagination>  </div></div></template>
</ResultGroup>
 
      </div>
 </div> 
</template>
<script>
import ResultGroup from '../../../At/Components/SearchPage/ResultGroup'
import ProfileItem from '../../../At/Components/ProfileItem'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'MentorSearch',

    components: {ResultGroup,ProfileItem,},
	methods: {
	
		getMentors: function() {		
	let config = {

		pagination: {

			page: this.mentorCurPage,

			pageSize: this.mentorPerPage,

			withCount: true

		},

		filters: this.searchFilters,

		populate: {

			user: {

				populate: '*'

			},

			university: {

				populate: '*'

			},

			studyLevel: {

				populate: '*'

			},

			field_of_study: {

				populate: '*'

			}

		},

		sort: ['id:desc']

	}

	

	Request.getTableArgs(this.entityName, config)

	.then((res) => {

		if(res.data.meta != undefined){	

			this.mentors = res.data.data

			

			let pagination = res.data.meta.pagination;

			this.nbMentorPages = pagination.pageCount;

		} else {

			this.mentors = res.data;

		}

		

		this.mentors = this.mentors

			.map(m => {

				let u = m.attributes.user.data

				m.attributes.user.data.attributes.id = u.id

				m.attributes.user.data.attributes.image = u.attributes?.image?.data?.attributes

				m.attributes.user.data.attributes.bgImage = u.attributes?.bgImage?.data?.attributes

				return m

			});

			

		this.loading = false;

	}) 

}
,
		m_selectEntity: function() { 		
	this.selectEntity(this.entityName)

}
,
	},
	mounted(){
			
	this.getMentors()

	},
	computed: {
	
		mentorPerPage: function() { 		
	return (this.selected ? 16 : 8)

}
,
		selected: function() { 		
	return this.selectedEntity == this.entityName

}
,
		moreText: function() { 		
	return this.$t('Search.seeAll')

		.replace('{{ entity }}', this.$t('Search.mentors'))

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		mentorFoundNumber: function() { 		
		return this.$t('Mentor.mentors') 

			+ ' (' + this.mentors.length + ')'

}
,
		searchFilters: function() { 		
	let filters = ({})

	

	if(this.filter){

		filters['$and'] = filters['$and'] ?? []

		

		let tmpFilter = {

			$or: [	

				{ user: { username: { $contains: this.filter } } },

				{ user: { lastName: { $contains: this.filter } } },

				{ user: { firstName: { $contains: this.filter } } },

			]

		}

		

		filters['$and'].push(tmpFilter)

	}

	

	if(this.selectedUnivs){

		filters['$and'] = filters['$and'] ?? []

		let tmpFilter = { university: this.selectedUnivs }

		filters['$and'].push(tmpFilter)

	}

	

	if(this.selectedFields){

		filters['$and'] = filters['$and'] ?? []

		let tmpFilter = { field_of_study: this.selectedFields }

		filters['$and'].push(tmpFilter)

	}

	

	if(this.selectedLevels){

		filters['$and'] = filters['$and'] ?? []

		let tmpFilter = { studyLevel: this.selectedLevels }

		filters['$and'].push(tmpFilter)

	}

	

	return filters

}
,
	},
	watch: {
	
		mentorPerPage: function(val) { 		
	this.getMentors()

}
,
		filter: function(val) { 		
	this.getMentors()

}
,
		mentors: function(val) { 		
	this.setNbFinded(this.mentors.length)

}
,
		mentorCurPage: function(val) { 		
	this.getMentors()

}
,
		searchFilters: function(val) { 		
	this.getMentors()

}
,
	},
	data(){
	return {
		
		mentors:  []		,
		mentorCurPage:  1		,
		nbMentorPages:  null		,
		loading:  true		,
		entityName:  "mentors"		,
		}
	},
	props: {
	
		filter: {					required: false,			default: () =>  null,		},
		selectedEntity: {					required: false,			default: () =>  null,		},
		selectedUnivs: {					required: false,			default: () =>  null,		},
		selectedFields: {					required: false,			default: () =>  null,		},
		selectedLevels: {					required: false,			default: () =>  null,		},
		setNbFinded: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		selectEntity: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#MentorSearch{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.object.resultgroupmainColumn122{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.grid.gridmainContent311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color: transparent ; 

			.col.colgridmainContent31{

			
			position:relative !important; 
			margin: unset;
			transform-origin: top left;
			
			
			
			height:auto!important ; 
			

			::v-deep .col-container{position: relative;

			height: auto;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:250px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		}

	
}


}


}


@media (max-width: 600px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.resultgroupmainColumn122{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateresultgroupmainColumn11{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplateresultgroupmainColumn111{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.grid.gridmainContent311{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridmainContent31{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.profileitemcolgridmainContent3112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.orgaPagination{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}
}
</style>
