<template>
 <div>  
<div id="MainLayout"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'scrollarea scrollarea0 '"   id="scrollarea0" ref="scrollarea0"  v-bind:style="{}">
 <div  :class="'column columnscrollarea05 '"   id="columnscrollarea05" ref="columnscrollarea05"  v-bind:style="{backgroundColor : bgColor,}">
 <MainHeader :class="'object mainHeader '"   id="mainHeader" ref="mainHeader" v-if="userConnected"  v-bind:style="{}" :toggleProfileModal = "toggleProfileModal" 
 :toggleServiceModal = "toggleServiceModal" 
 :toggleMenu = "toggleMenu" 
 :currentTab = "currentTab" 
>
</MainHeader>
 <Header :class="'object header '"   id="header" ref="header" v-if="!userConnected"  v-bind:style="{}" :toggleMenu = "toggleMenu" 
>
</Header>
 <div  :class="'column mainLayoutColumn '"   id="mainLayoutColumn" ref="mainLayoutColumn"  v-bind:style="{paddingTop : getUnit(userConnected? '50px' : '74px','px'),}">
 <div  :class="'slot slotContent1 '"   id="slotContent1" ref="slotContent1"  v-bind:style="{}"><slot  :name = "'content'" 
></slot></div>  </div>
 <Footer :class="'object footer '"   id="footer" ref="footer"  v-bind:style="{}">
</Footer>  </div>  </div>
 <ModalSideBar :class="'object profileModalObject '"   id="profileModalObject" ref="profileModalObject" v-if="profileModalOpened"  v-bind:style="{top : getUnit(topPos,'px'),}" :close = "toggleProfileModal" 
 :title = "'Profile'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofileModal1 '"   id="slottemplateprofileModal1" ref="slottemplateprofileModal1"  v-bind:style="{}">
 <ProfileModal :class="'object profilemodalslottemplateprofileModal11 '"   id="profilemodalslottemplateprofileModal11" ref="profilemodalslottemplateprofileModal11"  v-bind:style="{}">
</ProfileModal></div></template>
</ModalSideBar>
 <ModalSideBar :class="'object serviceModalObject '"   id="serviceModalObject" ref="serviceModalObject" v-if="serviceModalOpened"  v-bind:style="{top : getUnit(topPos,'px'),}" :close = "toggleServiceModal" 
 :title = "$t('Footer.services')" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofileModal1 '"   id="slottemplateprofileModal1" ref="slottemplateprofileModal1"  v-bind:style="{}">
 <ProductModal :class="'object profilemodalslottemplateprofileModal11 '"   id="profilemodalslottemplateprofileModal11" ref="profilemodalslottemplateprofileModal11"  v-bind:style="{}">
</ProductModal></div></template>
</ModalSideBar>
 <ModalSideBar :class="'object menuModalObject '"   id="menuModalObject" ref="menuModalObject" v-if="menuModalOpened"  v-bind:style="{top : getUnit(topPos,'px'),}" :close = "toggleMenu" 
 :title = "$t('Modal.menu')" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofileModal1 '"   id="slottemplateprofileModal1" ref="slottemplateprofileModal1"  v-bind:style="{}">
 <MenuModal :class="'object menuModal '"   id="menuModal" ref="menuModal"  v-bind:style="{}" :toggleProfileModal = "toggleProfileModal" 
 :toggleServiceModal = "toggleServiceModal" 
>
</MenuModal></div></template>
</ModalSideBar>
 <SelectCountryModal :class="'object selectcountrymodalitem011 '"   id="selectcountrymodalitem011" ref="selectcountrymodalitem011" v-if="countryModalVisible"  v-bind:style="{}">
</SelectCountryModal>
 <SocialCallToAction :class="'object socialcalltoactionitem011 '"   id="socialcalltoactionitem011" ref="socialcalltoactionitem011" v-if="socialCTAVisible"  v-bind:style="{}" :close = "closeSocialCTA" 
>
</SocialCallToAction>  </div>
 
      </div>
 </div> 
</template>
<script>
import MainHeader from '../../At/Sections/MainHeader'
import Header from '../../At/Sections/Header'
import Footer from '../../At/Sections/Footer'
import ModalSideBar from '../../At/Components/ModalSideBar'
import ProfileModal from '../../At/Sections/Modal/ProfileModal'
import ProductModal from '../../At/Sections/Modal/ProductModal'
import MenuModal from '../../At/Sections/Modal/MenuModal'
import SelectCountryModal from '../../At/Sections/Modal/SelectCountryModal'
import SocialCallToAction from '../../At/Utils/SocialCallToAction'


export default {
	name: 'MainLayout',

    components: {MainHeader,Header,Footer,ModalSideBar,ProfileModal,ProductModal,MenuModal,SelectCountryModal,SocialCallToAction,},
	methods: {
	
		toggleProfileModal: function() { 		
	this.profileModalOpened = !this.profileModalOpened

	this.serviceModalOpened = false

	this.menuModalOpened = false

}
,
		toggleServiceModal: function() { 		
	this.profileModalOpened = false

	this.serviceModalOpened = !this.serviceModalOpened

	this.menuModalOpened = false

}
,
		toggleMenu: function() { 		
	if(this.profileModalOpened || this.serviceModalOpened){	

		this.profileModalOpened = false

		this.serviceModalOpened = false

	} else {

		this.menuModalOpened = !this.menuModalOpened

	}

}
,
		closeSocialCTA: function() { 		
	this.socialCTAVisible = false

}
,
	},
	created(){
			
  const html = document.documentElement // returns the html tag

  html.setAttribute('lang', this.$store.state.selectedLang)

	},
	computed: {
	
		user: function() { 		
	return this.$store.state.user

}
,
		userConnected: function() { 		
	return this.user != null

}
,
		topPos: function() { 		
	return this.userConnected ? 50 : 74

}
,
	},
	watch: {
	
		user: function(val) { 		
	if(!this.user?.confirmed){

		this.$router.push("/confirm-mail")

	}

	

	this.countryModalVisible = (this.user?.country == undefined)

}
,
	},
	data(){
	return {
		
		profileModalOpened:  false		,
		serviceModalOpened:  false		,
		menuModalOpened:  false		,
		countryModalVisible:  false		,
		socialCTAVisible:  true		,
		}
	},
	props: {
	
		currentTab: {					required: false,			default: () =>  null,		},
		bgColor: {					required: false,			default: () =>  "#f3f2ef",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#MainLayout{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');

:not(i):not(.icon){
	font-family: 'Raleway', Arial,sans-serif !important;
	
	.text.text-content{
		font-family: 'Raleway', Arial,sans-serif !important;	
	}
	
	i, .icon{
		font-family: inherit !important;	
	}
}

.overlay {	
	background-color: rgba(255,255,255,.55);
	backdrop-filter: blur(10px);
}

a{
	text-decoration: none;
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.scrollarea.scrollarea0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

overflow:auto ; 
overflow-x:hidden ; 
overflow-y:scroll ; background-color:#ffffff ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

      // =======================================Style1
        border-radius: 10px;
      //-----------------------------------------------
        
          }

    &::-webkit-scrollbar
    {
        width: 5px;
        height: 5px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #7b7b7b;    

      // =======================================Style1
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      //-----------------------------------------------
        
      }

	.column.columnscrollarea05{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.mainHeader{

		
		position:fixed !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		z-index:1000 ; 
		height:50px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.header{

		
		position:fixed !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		z-index:1000 ; 
		height:74px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.mainLayoutColumn{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.slot.slotContent1{

			
			position:unset !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			right: 0px ; 
			}

		
}

		.object.footer{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.profileModalObject{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.serviceModalObject{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.menuModalObject{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.menuModal{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.selectcountrymodalitem011{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.socialcalltoactionitem011{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

bottom: 80px ; 
width:90%!important ; 
height:auto!important ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea05{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.mainHeader{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.header{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.mainLayoutColumn{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotContent1{

			
			position:unset !important; 
			
			
			
			
			}

		
}

		.object.footer{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.profileModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.serviceModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.menuModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.menuModal{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.selectcountrymodalitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.socialcalltoactionitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea05{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.mainHeader{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.header{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.mainLayoutColumn{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotContent1{

			
			position:unset !important; 
			
			
			
			
			}

		
}

		.object.footer{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.profileModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.serviceModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.menuModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.menuModal{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.selectcountrymodalitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.socialcalltoactionitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea05{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.mainHeader{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.header{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.mainLayoutColumn{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotContent1{

			
			position:unset !important; 
			
			
			
			
			}

		
}

		.object.footer{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.profileModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.serviceModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.menuModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.menuModal{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.selectcountrymodalitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.socialcalltoactionitem011{


position:absolute !important; 




bottom: 20px ; 
width:500px!important ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea05{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.mainHeader{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.header{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.mainLayoutColumn{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotContent1{

			
			position:unset !important; 
			
			
			
			
			}

		
}

		.object.footer{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.profileModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.serviceModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.menuModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.menuModal{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.selectcountrymodalitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.socialcalltoactionitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea05{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.mainHeader{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.header{

		
		position:fixed !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.mainLayoutColumn{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.slot.slotContent1{

			
			position:unset !important; 
			
			
			
			
			}

		
}

		.object.footer{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.profileModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.serviceModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.profilemodalslottemplateprofileModal11{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.menuModalObject{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplateprofileModal1{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.object.menuModal{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.object.selectcountrymodalitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.socialcalltoactionitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
