<template>
 <div>  
<div id="CreatePostDialog"> 
         
 <DialogBoxModal :class="'object dialogboxmodal0 '"   id="dialogboxmodal0" ref="dialogboxmodal0"  v-bind:style="{}" :close = "close" 
 :action = "post" 
 :title = "$t('Feed.startPost')" 
 :hasAction = "true" 
 :actionName = "$t('Feed.post')" 
 :maxWidth = "500" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedialogboxmodal01 '"   id="slottemplatedialogboxmodal01" ref="slottemplatedialogboxmodal01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedialogboxmodal011 '"   id="columnslottemplatedialogboxmodal011" ref="columnslottemplatedialogboxmodal011"  v-bind:style="{}">
 <div  :class="'text textcolumnslottemplatedialogboxmodal0119 '"   id="textcolumnslottemplatedialogboxmodal0119" ref="textcolumnslottemplatedialogboxmodal0119" v-if="error"  v-bind:style="{color : '#ff5942',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>
 <div  :class="'flow rowcolumn034 '"   id="rowcolumn034" ref="rowcolumn034"  v-bind:style="{justifyContent : 'space-between',}">
 <v-btn tag="div" 
 :color = "'#378fe9'" 
 :text = "false" 
 :dark = "true" 
 @click="pickImages" :class="'button buttonrowcolumn031 '"   id="buttonrowcolumn031" ref="buttonrowcolumn031" :_flex = "'1'" 
 :_minWidth = "'unset'" 
  v-bind:style="{flex : '1',minWidth : 'unset',}"><v-icon  :style = "{}"  v-if = "'ion-ios-image' !== undefined"  :left = "true" 
 :color = "'#fff'" 
>{{ 'ion-ios-image' }}
</v-icon><span>{{ $t('Feed.picture') }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#e16745'" 
 :text = "false" 
 :dark = "true" 
 @click="pickVideo" :class="'button buttonrowcolumn0314 '"   id="buttonrowcolumn0314" ref="buttonrowcolumn0314" :_flex = "'1'" 
 :_minWidth = "'unset'" 
  v-bind:style="{flex : '1',minWidth : 'unset',}"><v-icon  :style = "{}"  v-if = "'ion-ios-videocam' !== undefined"  :left = "true" 
 :color = "'#fff'" 
>{{ 'ion-ios-videocam' }}
</v-icon><span>{{ $t('Feed.video') }}</span>
</v-btn>  </div>
 <VueEditor :class="'importedobject textarearectanglecolumncolumnslottemplatedialogboxmodal011591426 '"   id="textarearectanglecolumncolumnslottemplatedialogboxmodal011591426" ref="textarearectanglecolumncolumnslottemplatedialogboxmodal011591426" v-model="message" :placeholder = "postQuestion" 
 :editorToolbar = "customToolbar" 
  v-bind:style="{}">
</VueEditor>
 <v-carousel  :class="'networkcarousel carouselcolumnmainContent11192 '"   id="carouselcolumnmainContent11192" ref="carouselcolumnmainContent11192" v-if="images"  v-bind:style="{}">
    <v-carousel-item
      v-for='(item,i) in images'
      :key="i"
      :src="item"
    ></v-carousel-item></v-carousel>
<div class="artefactVid"  :class="'networkvideo postVideo '"   id="postVideo" ref="postVideo" v-show="video"  v-bind:style="{}"> <video width="100%" height="100%"  :controls = "true" 
 :autoplay = "true" 
 :preload = "'auto'" 
>
<source  v-for='(itemVideoUrl,index) in video' :key="index" :src="itemVideoUrl" >
</video> </div>  </div></div></template>
</DialogBoxModal>
 
      </div>
 </div> 
</template>
<script>
import DialogBoxModal from '../../../At/Sections/Modal/DialogBoxModal'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'

import { VueEditor } from "vue2-editor";
export default {
	name: 'CreatePostDialog',

    components: {DialogBoxModal,VueEditor,},
	methods: {
	
		showError: function(error) { 		
	this.error = error

	this.$awn.alert(message)

}
,
		hideError: function() { 		
	this.error = null

}
,
		post: function() { 		
	if(this.message == null){

		this.showError(this.$t('Feed.errorGiveMessage'))

		return

	}



	let config = {

		message: this.message,

		author: this.user.id,

		images: this.imagesBlob,

		video: this.videoBlob,

		organisation: this.orgaID

	}

	

	Request.createPost(config)

	.then(res => {

			this.$awn.success(this.$t('feed.postSuccessfull'))

			this.getPosts()

			this.close()

	}).catch(error => {

		console.log("-------", error)

		this.showError(error.response.data.error.message

			+ ' : ' + error.response.data.error.details.errors[0].path.toString()

		)

	})

}
,
		pickImages: function(){		
	this.images = []

		

	Main.selectFile('image/*', true)

	.then((files) => {		

		this.video = null;

		this.videoBlob = null;

	

		this.imagesBlob = files;

		

		let check = Main.checkFilesSize(files, 1)

		

		if(check[0]){	

	  	

			for(let i=0; i<files.length; i++){	

		  	const reader = new FileReader();

		  	reader.addEventListener('load', (event) => {

		    	this.images.push(event.target.result);

		  	});

	  		reader.readAsDataURL(files[i]); 

			}

		

		} else {

			this.$awn.alert(

				this.$t('Profile.fileTooLarge')

					.replace('{{name}}', check[1])

					.replace('{{maxSize}}', '1')

			)

		}

		

	});

}
,
		pickVideo: function(){		
	Main.selectFile('video/*', false)

	.then((file) => {		

		this.images = null

		this.imagesBlob = null

	

		this.videoBlob = file;

		

		let check = Main.checkFilesSize([file], 3)

		

		if(check[0]){		

			this.video = [URL.createObjectURL(file)];

	  	this.$refs.postVideo.querySelector("video").load();

		} else {

			this.$awn.alert(

				this.$t('Profile.fileTooLarge')

					.replace('{{name}}', check[1])

					.replace('{{maxSize}}', '3')

			)

		}

	});

}
,
	},
	computed: {
	
		customToolbar: function() { 		
	return [	

	  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

	  ['blockquote', 'code-block'],

	

	  [{ 'header': 1 }, { 'header': 2 }],               // custom button values

	  [{ 'list': 'ordered'}, { 'list': 'bullet' }],

	  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript

	  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

	  [{ 'direction': 'rtl' }],                         // text direction

	

	  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

	  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

	

	  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

	  [{ 'font': [] }],

	  [{ 'align': [] }],

	

	  ['clean']  

	]

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		postQuestion: function() { 		
	let question = this.$t('Feed.postQuestion')

	return question.replace("{{name}}", this.user.username)

}
,
	},
	data(){
	return {
		
		message:  null		,
		images:  null		,
		imagesBlob:  null		,
		video:  null		,
		videoBlob:  null		,
		error:  null		,
		}
	},
	props: {
	
		orgaID: {					required: false,			default: () =>  null,		},
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		getPosts: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#CreatePostDialog{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.dialogboxmodal0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.text.textcolumnslottemplatedialogboxmodal0119{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#ff5942 ; 
		font-size:12px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

		.flow.rowcolumn034{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;
		gap:6px ; 
		justify-content:space-between ; background-color:transparent ; 

			.button.buttonrowcolumn031{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			height:48px!important ; 
			flex:1 !important; 
			minWidth:unset !important; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

			.button.buttonrowcolumn0314{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			height:48px!important ; 
			flex:1 !important; 
			minWidth:unset !important; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

		
}

		.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal011591426{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 5px ; 
		
		margin-top: 5px ; 
		margin-right: 5px ; 
		margin-bottom: 5px ; 
		width: calc(100% - (5px + 5px)) !important;
		height: calc(100% - (5px + 5px)) !important;
		

		
}

		.networkcarousel.carouselcolumnmainContent11192{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		}

		.networkvideo.postVideo{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color: black;

		
}

	
}


}


}


@media (max-width: 600px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0119{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.rowcolumn034{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.buttonrowcolumn031{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumn0314{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal011591426{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.networkcarousel.carouselcolumnmainContent11192{

		
		position:relative !important; 
		
		
		
		
		}

		.networkvideo.postVideo{

		
		position:relative !important; 
		
		
		
		
		background-color: black;

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0119{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.rowcolumn034{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.buttonrowcolumn031{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumn0314{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal011591426{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.networkcarousel.carouselcolumnmainContent11192{

		
		position:relative !important; 
		
		
		
		
		}

		.networkvideo.postVideo{

		
		position:relative !important; 
		
		
		
		
		background-color: black;

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0119{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.rowcolumn034{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.buttonrowcolumn031{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumn0314{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal011591426{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.networkcarousel.carouselcolumnmainContent11192{

		
		position:relative !important; 
		
		
		
		
		}

		.networkvideo.postVideo{

		
		position:relative !important; 
		
		
		
		
		background-color: black;

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0119{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.rowcolumn034{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.buttonrowcolumn031{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumn0314{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal011591426{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.networkcarousel.carouselcolumnmainContent11192{

		
		position:relative !important; 
		
		
		
		
		}

		.networkvideo.postVideo{

		
		position:relative !important; 
		
		
		
		
		background-color: black;

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal0119{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.flow.rowcolumn034{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.button.buttonrowcolumn031{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumn0314{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.importedobject.textarearectanglecolumncolumnslottemplatedialogboxmodal011591426{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.networkcarousel.carouselcolumnmainContent11192{

		
		position:relative !important; 
		
		
		
		
		}

		.networkvideo.postVideo{

		
		position:relative !important; 
		
		
		
		
		background-color: black;

		
}

	
}


}


}
}
}
</style>
