<template>
 <div>  
<div id="Home"> 
         
 <MainLayout :class="'object defaultlayout0 '"   id="defaultlayout0" ref="defaultlayout0"  v-bind:style="{}" :bgColor = "'#fff'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{}">
 <HomeBanner :class="'object homebannercolumnslottemplatedefaultlayout0159 '"   id="homebannercolumnslottemplatedefaultlayout0159" ref="homebannercolumnslottemplatedefaultlayout0159"  v-bind:style="{}">
</HomeBanner>
 <PySection :class="'object pysectioncolumnslottemplatedefaultlayout0153 '"   id="pysectioncolumnslottemplatedefaultlayout0153" ref="pysectioncolumnslottemplatedefaultlayout0153"  v-bind:style="{}" :titleColor = "'#000000e6'" 
 :title = "$t('Home.exploreTopic')" 
 :list = "tags" 
 :baseUrl = "'tags'" 
>
</PySection>
 <PySection :class="'object pysectioncolumnslottemplatedefaultlayout01533 '"   id="pysectioncolumnslottemplatedefaultlayout01533" ref="pysectioncolumnslottemplatedefaultlayout01533"  v-bind:style="{}" :bgColor = "'#fff'" 
 :titleColor = "'#000000e6'" 
 :title = "$t('Home.exploreCat')" 
 :list = "categories" 
 :baseUrl = "'categories'" 
>
</PySection>
 <PySection :class="'object pysectioncolumnslottemplatedefaultlayout01534 '"   id="pysectioncolumnslottemplatedefaultlayout01534" ref="pysectioncolumnslottemplatedefaultlayout01534"  v-bind:style="{}" :bgColor = "'#f1ece5'" 
 :titleColor = "'#b24020'" 
 :title = "$t('Home.exploreSubCat')" 
 :list = "subCategories" 
 :baseUrl = "'subcategories'" 
>
</PySection>
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout0151331012 '"   id="contentwrappercolumnslottemplatedefaultlayout0151331012" ref="contentwrappercolumnslottemplatedefaultlayout0151331012"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column columncoltab01311 '"   id="columncoltab01311" ref="columncoltab01311"  v-bind:style="{}">
 <SwiperRow :class="'object swiperrowcolumncoltab01311 '"   id="swiperrowcolumncoltab01311" ref="swiperrowcolumncoltab01311"  v-bind:style="{}" :list = "organisations" 
 :title = "$t('Organisations.organisations')" 
 :baseUrl = "'organisations'" 
 :moreLink = "'/organisations'" 
 :isOrganisations = "true" 
>
</SwiperRow>  </div></div></template>
</ContentWrapper>
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout015139 '"   id="contentwrappercolumnslottemplatedefaultlayout015139" ref="contentwrappercolumnslottemplatedefaultlayout015139"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column columncoltab01311 '"   id="columncoltab01311" ref="columncoltab01311"  v-bind:style="{}">
 <SwiperRow :class="'object swiperrowcolumncoltab01311 '"   id="swiperrowcolumncoltab01311" ref="swiperrowcolumncoltab01311"  v-bind:style="{}" :list = "formations" 
 :title = "$t('Feed.formations')" 
 :moreLink = "'/learning'" 
>
</SwiperRow>
 <SwiperRow :class="'object swiperrowcolumncoltab013112 '"   id="swiperrowcolumncoltab013112" ref="swiperrowcolumncoltab013112"  v-bind:style="{}" :list = "webinars" 
 :title = "$t('Feed.webinars')" 
 :baseUrl = "'webinars'" 
 :moreLink = "'/webinars'" 
>
</SwiperRow>  </div></div></template>
</ContentWrapper>
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout01513310 '"   id="contentwrappercolumnslottemplatedefaultlayout01513310" ref="contentwrappercolumnslottemplatedefaultlayout01513310"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
 :bgColor = "'#f3f2f0'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column columncoltab01311 '"   id="columncoltab01311" ref="columncoltab01311"  v-bind:style="{}">
 <SwiperRow :class="'object swiperrowcolumncoltab01311 '"   id="swiperrowcolumncoltab01311" ref="swiperrowcolumncoltab01311"  v-bind:style="{}" :list = "articles" 
 :title = "$t('Home.articles')" 
 :isArticles = "true" 
 :baseUrl = "'blog'" 
 :moreLink = "'/blog'" 
>
</SwiperRow>  </div></div></template>
</ContentWrapper>
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout0151331011 '"   id="contentwrappercolumnslottemplatedefaultlayout0151331011" ref="contentwrappercolumnslottemplatedefaultlayout0151331011" v-for="(cat, catIndex) in moodleCat" :key="'contentwrappercolumnslottemplatedefaultlayout0151331011' + catIndex" v-if="returnCatVisible(cat.id)"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
 :bgColor = "'#f1ece5'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column columncoltab01311 '"   id="columncoltab01311" ref="columncoltab01311"  v-bind:style="{}">
 <SwiperRow :class="'object swiperrowcolumncoltab01311 '"   id="swiperrowcolumncoltab01311" ref="swiperrowcolumncoltab01311" v-for="(subcat, subcatIndex) in returnSubCats(cat.id)" :key="'swiperrowcolumncoltab01311' + subcatIndex"  v-bind:style="{}" :list = "returnCours(subcat.id)" 
 :title = "subcat.name" 
 :fromMoodle = "true" 
 :moreLink = "'/learning#moodlePart'" 
>
</SwiperRow>  </div></div></template>
</ContentWrapper>
 <RelativeSection :class="'object relativesectioncolumnslottemplatedefaultlayout0159 '"   id="relativesectioncolumnslottemplatedefaultlayout0159" ref="relativesectioncolumnslottemplatedefaultlayout0159"  v-bind:style="{}" :info = "pageDatas" 
>
</RelativeSection>
 <CallToActionSection :class="'object calltoactionsectioncolumnslottemplatedefaultlayout01511 '"   id="calltoactionsectioncolumnslottemplatedefaultlayout01511" ref="calltoactionsectioncolumnslottemplatedefaultlayout01511"  v-bind:style="{}" :info = "pageDatas" 
>
</CallToActionSection>
 <WhoIsForSection :class="'object whoisforsectioncolumnslottemplatedefaultlayout01513 '"   id="whoisforsectioncolumnslottemplatedefaultlayout01513" ref="whoisforsectioncolumnslottemplatedefaultlayout01513"  v-bind:style="{}" :image = "whoIsImage" 
>
</WhoIsForSection>
 <BigCallToAction :class="'object bigcalltoactioncolumnslottemplatedefaultlayout01515 '"   id="bigcalltoactioncolumnslottemplatedefaultlayout01515" ref="bigcalltoactioncolumnslottemplatedefaultlayout01515"  v-bind:style="{}">
</BigCallToAction>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import HomeBanner from '../../At/Components/HomePage/HomeBanner'
import PySection from '../../At/Components/PySection'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import SwiperRow from '../../At/Utils/SwiperRow'
import RelativeSection from '../../At/Components/RelativeSection'
import CallToActionSection from '../../At/Components/HomePage/CallToActionSection'
import WhoIsForSection from '../../At/Components/HomePage/WhoIsForSection'
import BigCallToAction from '../../At/Components/HomePage/BigCallToAction'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
export default {
	name: 'Home',

    components: {MainLayout,HomeBanner,PySection,ContentWrapper,SwiperRow,RelativeSection,CallToActionSection,WhoIsForSection,BigCallToAction,},
	methods: {
	
		getTags: function() { 		
	Request.getLocalTable('tags')

	.then((res) => {

		this.tags = res.data.data.map(t => t.attributes)

	})	

}
,
		getCats: function() { 		
	Request.getLocalTable('categories')

	.then((res) => {

		this.categories = res.data.data.map(t => t.attributes)

	})	

}
,
		getSubCats: function() { 		
	Request.getLocalTable('subcategories')

	.then((res) => {

		this.subCategories = res.data.data.map(t => t.attributes)

	})	

}
,
		getArticles: function() {		
	let config = {

		sort: ['id:desc']

	}

	

	Request.getLocalTableArgs('articles', config)

	.then((res) => {

		this.articles = res.data.data;

	}) 

}
,
		getFormations: function() { 		
	Request.getLocalTable('formations')

	.then((res) => {

		this.formations = res.data.data

	})

}
,
		getImage: function(item) { 		
	if(item.image){

			return Main.getImgUrl(item.image, 'small');

	}

	return "https://picsum.photos/200"

}
,
		getWebinars: function() { 		
	Request.getLocalTable('webinars')

	.then((res) => {

		this.webinars = res.data.data

	})

}
,
		getMoodleCat: function(criteria){ 		
	Request.getMoodleCat(criteria)

	.then((res) => {

		this.tmp_moodleCat = res.data

			.filter(c => c.parent == 1)

		

		for(let i=0; i < this.tmp_moodleCat.length; i++){

			this.numberCoursByCat[this.tmp_moodleCat[i].id] = 0

			

			this.getMoodleSubCat(

				this.tmp_moodleCat[i].id,

				i == this.tmp_moodleCat.length - 1

			)

		}

	})

}
,
		getMoodleSubCat: function(catid, lastCat){ 		
	Request.getMoodleCat()

	.then((res) => {

		this.moodleSubCat[catid] = res.data

			.filter(c => c.parent == catid)

		

		if(this.moodleSubCat[catid].length > 0){	

			for(let i=0; i < this.moodleSubCat[catid].length; i++){

				this.getMoodleCoursByField(

					catid,

					this.moodleSubCat[catid][i].id, 

					lastCat,

					i == this.moodleSubCat[catid].length - 1

				)

			}

		} else {	

			if(lastCat){

				this.moodleCat = this.tmp_moodleCat

			}

		}

		

	})

}
,
		getMoodleCoursByField: function(catid, subcatid, lastCat, lastSubcat){ 		
	Request.getMoodleCoursByField()

	.then((res) => {

		this.moodleCours[subcatid] 

			= res.data.courses

					.filter(c => c.categoryid == subcatid)

					

			this.numberCoursByCat[catid] += this.moodleCours[subcatid].length

		

		if(lastCat && lastSubcat){

			this.filterSubCat()

			this.moodleCat = this.tmp_moodleCat

		}

	})

}
,
		getOrganisations: function() {		
	let config = ({

		sort: ['id:desc']

	})

	

	Request.getTableArgs('organisations', config)

	.then((res) => {

		if(res.data.meta != undefined){	

			this.organisations = res.data.data;

		} else {

			this.organisations = res.data;

		}

	}) 

}
,
		returnSubCats: function(catid) { 		
	if(this.moodleSubCat[catid]){

		return this.moodleSubCat[catid]

	}

	return []

}
,
		returnCours: function(subcatid) {  		
	if(this.moodleCours[subcatid]){

		return this.moodleCours[subcatid]

	}

	return []

}
,
		returnCatVisible: function(id) { 		
	return this.numberCoursByCat[id] > 0

}
,
		filterSubCat: function() { 		
	for(let catid in this.moodleSubCat){	

		this.moodleSubCat[catid] = this.moodleSubCat[catid].filter(subCat => {

			let list = this.returnCours(subCat.id)

				

			if(list){

				return (list.length > 0)

			}

			return false

		})

	}

}
,
		getHomeDatas: function() { 			
	Request.getLocalTable('home')

	.then((res) => {

		this.pageDatas = res.data.data.attributes

	})

}
,
		loadContens: function() { 		
	this.getHomeDatas()

	

	this.getTags()

	this.getCats()

	this.getSubCats()

	

	//from learning

	this.getFormations()

	this.getWebinars()

	

	this.getArticles()

	

	this.getMoodleCat()

	

	this.getOrganisations()

}
,
	},
	mounted(){
			
	this.loadContens()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		selectedLang: function() { 		
	return this.$store.state.selectedLang

}
,
		whoIsImage: function() { 		
	if(this.pageDatas.whoIsImage){

		return Main.getImgUrl(this.pageDatas.whoIsImage.data.attributes)

	}

	return "https://static-exp1.licdn.com/aero-v1/sc/h/7da1ofavh4l95hg2y6ynu314w"

}
,
	},
	watch: {
	
		selectedLang: function() { 		
	this.loadContens()

}
,
	},
	data(){
	return {
		
		tags:  []		,
		categories:  []		,
		subCategories:  []		,
		formations:  []		,
		webinars:  []		,
		articles:  []		,
		events:  []		,
		organisations:  []		,
		moodleCat:  []		,
		moodleSubCat:  ({})		,
		moodleCours:  ({})		,
		numberCoursByCat:  ({})		,
		pageDatas:  ({})		,
		tmp_moodleCat:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Home{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.defaultlayout0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.homebannercolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout0153{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01533{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01534{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331012{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:50px ; background-color: transparent ; 

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout015139{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:50px ; background-color: transparent ; 

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.swiperrowcolumncoltab013112{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout01513310{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:50px ; background-color: transparent ; 

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:50px ; background-color: transparent ; 

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.relativesectioncolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.calltoactionsectioncolumnslottemplatedefaultlayout01511{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.whoisforsectioncolumnslottemplatedefaultlayout01513{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.bigcalltoactioncolumnslottemplatedefaultlayout01515{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebannercolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout0153{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01533{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01534{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331012{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout015139{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.swiperrowcolumncoltab013112{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout01513310{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.relativesectioncolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.calltoactionsectioncolumnslottemplatedefaultlayout01511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.whoisforsectioncolumnslottemplatedefaultlayout01513{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.bigcalltoactioncolumnslottemplatedefaultlayout01515{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebannercolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout0153{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01533{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01534{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331012{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout015139{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.swiperrowcolumncoltab013112{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout01513310{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.relativesectioncolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.calltoactionsectioncolumnslottemplatedefaultlayout01511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.whoisforsectioncolumnslottemplatedefaultlayout01513{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.bigcalltoactioncolumnslottemplatedefaultlayout01515{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebannercolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout0153{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01533{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01534{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331012{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout015139{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.swiperrowcolumncoltab013112{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout01513310{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.relativesectioncolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.calltoactionsectioncolumnslottemplatedefaultlayout01511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.whoisforsectioncolumnslottemplatedefaultlayout01513{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.bigcalltoactioncolumnslottemplatedefaultlayout01515{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebannercolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout0153{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01533{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01534{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331012{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout015139{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.swiperrowcolumncoltab013112{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout01513310{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.relativesectioncolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.calltoactionsectioncolumnslottemplatedefaultlayout01511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.whoisforsectioncolumnslottemplatedefaultlayout01513{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.bigcalltoactioncolumnslottemplatedefaultlayout01515{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebannercolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout0153{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01533{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.pysectioncolumnslottemplatedefaultlayout01534{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331012{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout015139{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.swiperrowcolumncoltab013112{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout01513310{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.contentwrappercolumnslottemplatedefaultlayout0151331011{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columncoltab01311{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.swiperrowcolumncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.relativesectioncolumnslottemplatedefaultlayout0159{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.calltoactionsectioncolumnslottemplatedefaultlayout01511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.whoisforsectioncolumnslottemplatedefaultlayout01513{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.bigcalltoactioncolumnslottemplatedefaultlayout01515{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
