<template>
 <div>  
<div id="AdminLeftSection"> 
         
 <v-card 
 :class="'card cardrow01 ' + `elevation-${0.5}`"   id="cardrow01" ref="cardrow01" :elevation = "0.5" 
  v-bind:style="{}">
 <div  :class="'column columncardrow015 '"   id="columncardrow015" ref="columncardrow015"  v-bind:style="{}">
 <div  :class="'column columncardrow0132 '"   id="columncardrow0132" ref="columncardrow0132" :_borderBottom = "'solid 1px #80808040'" 
  v-bind:style="{borderBottom : 'solid 1px #80808040',}">
 <div  :class="'row rowcolumncardrow0131 '"   id="rowcolumncardrow0131" ref="rowcolumncardrow0131"  v-bind:style="{}">
 <Logo :class="'object logorowcolumncardrow01313 '"   id="logorowcolumncardrow01313" ref="logorowcolumncardrow01313"  v-bind:style="{}" :mini = "true" 
 :size = "32" 
>
</Logo>
 <v-select
          :items = "language" outlined :class="'select selectright53 '"   id="selectright53" ref="selectright53" v-if="expended" v-model="selectedLang" :dense = "true" 
 :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>  </div>  </div>
 <div  :class="'scrollarea scrollareacardrow0111 '"   id="scrollareacardrow0111" ref="scrollareacardrow0111" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'column columnscrollareacardrow01111 '"   id="columnscrollareacardrow01111" ref="columnscrollareacardrow01111"  v-bind:style="{}">
 <div  :class="'column columncolumnscrollareacardrow011111 '"   id="columncolumnscrollareacardrow011111" ref="columncolumnscrollareacardrow011111" v-for="(item,index) in actionSections" :key="'columncolumnscrollareacardrow011111' + index" v-if="item.accessible"  v-bind:style="{}">
 <div  :class="'column columncolumncolumnscrollareacardrow0111115 '"   id="columncolumncolumnscrollareacardrow0111115" ref="columncolumncolumnscrollareacardrow0111115" v-if="expended"  v-bind:style="{}">
 <div  :class="'text textcolumncolumnscrollareacardrow01111111 '"   id="textcolumncolumnscrollareacardrow01111111" ref="textcolumncolumnscrollareacardrow01111111"  v-bind:style="{color : ligthPrimaryColor,}">
<div class="text-content" :style = "{}">{{ item.name }}</div>

   </div>  </div>
 <div  :class="'column columncolumncolumnscrollareacardrow0111113 '"   id="columncolumncolumnscrollareacardrow0111113" ref="columncolumncolumnscrollareacardrow0111113"  v-bind:style="{}">
 <v-btn tag="div" 
 :depressed = "true" 
 :color = "action.tab == selectedTab ? selBtnColor : btnColor" 
 :onlyIcon = "!expended" 
 :text = "action.tab != selectedTab" 
 @click="selectTab(action.tab)" :class="'button buttoncolumncolumncolumnscrollareacardrow01111131 '"   id="buttoncolumncolumncolumnscrollareacardrow01111131" ref="buttoncolumncolumncolumnscrollareacardrow01111131" v-for="(action, actionIndex) in item.actions" :key="'buttoncolumncolumncolumnscrollareacardrow01111131' + actionIndex" :_justifyContent = "expended ? 'flex-start' : 'center'" 
  v-bind:style="{justifyContent : expended ? 'flex-start' : 'center',}"><v-icon  :style = "{}"  v-if = "action.icon !== undefined"  :left = "true" 
 :color = "action.tab == selectedTab ? primaryColor : btnColor" 
>{{ action.icon }}
</v-icon><span v-if = "(!false) && (!!expended)">{{ action.name }}</span>
</v-btn>  </div>
 <div  :class="'rectangle divider '"   id="divider" ref="divider" v-if="!expended"  v-bind:style="{backgroundColor : '#80808040',}">  </div>  </div>  </div>  </div>
 <div  :class="'column columncardrow01323 '"   id="columncardrow01323" ref="columncardrow01323" :_borderTop = "'solid 1px #80808040'" 
  v-bind:style="{borderTop : 'solid 1px #80808040',}">
 <div  :class="'row rowcolumncardrow01323 '"   id="rowcolumncardrow01323" ref="rowcolumncardrow01323"  v-bind:style="{}">
 <div  :class="'item itemrowcolumncardrow013231 '"   id="itemrowcolumncardrow013231" ref="itemrowcolumncardrow013231"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageitemrowcolumncardrow0132311 '"   id="networkimageitemrowcolumncardrow0132311" ref="networkimageitemrowcolumncardrow0132311"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "profileImage" 
>  </v-img>  </div>
 <div  :class="'text textitemrowcolumncardrow01323132 '"   id="textitemrowcolumncardrow01323132" ref="textitemrowcolumncardrow01323132" v-if="expended" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
<div class="text-content" :style = "{}">{{ username }}</div>

   </div>
 <v-btn tag="div" 
 :outlined = "true" 
 :text = "true" 
 @click="hideLeftZone" :class="'button buttonrowcolumncardrow013235 '"   id="buttonrowcolumncardrow013235" ref="buttonrowcolumncardrow013235" v-if="expended"  v-bind:style="{}"><span></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-arrow-back' !== undefined" >{{ 'ion-ios-arrow-back' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :outlined = "true" 
 :text = "true" 
 @click="showLeftZone" :class="'button buttonrowcolumncardrow0132354 '"   id="buttonrowcolumncardrow0132354" ref="buttonrowcolumncardrow0132354" v-if="!expended"  v-bind:style="{}"><span></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-arrow-forward' !== undefined" >{{ 'ion-ios-arrow-forward' }}
</v-icon></v-btn>  </div>  </div>  </div>  </v-card>
 
      </div>
 </div> 
</template>
<script>
import Logo from '../../../At/Utils/Logo'

import * as Main from '../../../Js/main'
export default {
	name: 'AdminLeftSection',

    components: {Logo,},
	computed: {
	
		language: function() { 		
	return this.$store.state.language

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		ligthPrimaryColor: function() { 		
	return '#6e594e'

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		profileImage: function() { 		
	if(this.user){

		if(this.user.image){

			let item = this.user.image;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.user.username)

	}

	return null

}
,
		username: function() { 		
	return this.user?.username

}
,
		actionSections: function() { 		
	return [

		{

			name: this.$t('Admin.education'),

			accessible: this.hasEducatorAccess,

			actions: [

				{

					name: this.$t('Admin.webinars'),

					icon: "ion-ios-microphone",

					action: _ => {},

					tab: "webinars"

				},

				{

					name: this.$t('Admin.formations'),

					icon: "ion-ios-school",

					action: _ => {},

					tab: "formations"

				},

				{

					name: this.$t('Admin.articles'),

					icon: "ion-ios-paper",

					action: _ => {},

					tab: "articles"

				}

			]

		},

		

		{

			name: this.$t('Organisations.organisation'),

			accessible: this.hasOrganisationAccess,

			actions: [

				{

					name: this.$t('Admin.organisations'),

					icon: "ion-md-hand",

					action: _ => {},

					tab: "organisations"

				},

				{

					name: this.$t('Admin.landingPages'),

					icon: "ion-ios-desktop",

					action: _ => {},

					tab: "pages"

				},

				{

					name: this.$t('Admin.projects'),

					icon: "ion-md-folder-open",

					action: _ => {},

					tab: "projects"

				}

			]

		},

	]

}
,
	},
	watch: {
	
		selectedLang: function(val) { 		
	this.$i18n.locale = val

	this.$store.commit('changeSelectedLang', val)

}
,
	},
	data(){
	return {
		
		selectedLang:  'fr'		,
		btnColor:  "#717171b3"		,
		selBtnColor:  "#e4580a2b"		,
		}
	},
	props: {
	
		hideLeftZone: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		showLeftZone: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		expended: {			type: Boolean,			required: false,			default: () =>  true,		},
		selectedTab: {					required: false,			default: () =>  "tab-0",		},
		selectTab: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		hasEducatorAccess: {			type: Boolean,			required: false,			default: () =>  false,		},
		hasOrganisationAccess: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminLeftSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.card.cardrow01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
background-color:#ffffff ; 
border-radius:0px ; max-width: initial;min-width: initial;

.column.columncardrow015{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.column.columncardrow0132{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	borderBottom:solid 1px #80808040 !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.row.rowcolumncardrow0131{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:5px ; background-color:transparent ; 
		padding-top:10px ; 
		padding-bottom:10px ; 
		padding-left:10px ; 
		padding-right:10px ; 

			.object.logorowcolumncardrow01313{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:32px!important ; 
			height:32px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.select.selectright53{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			height:40px!important ; 
			dense:true !important; 
			flex:1 !important; 
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

		
}

	
}

	.scrollarea.scrollareacardrow0111{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	flex:1 !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	overflow:hidden ; 
	overflow-x:hidden ; 
	overflow-y:scroll ; background-color:#ffffff ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: transparent;

      // =======================================Style1
        border-radius: 10px;
      //-----------------------------------------------
        
          }

    &::-webkit-scrollbar
    {
        width: 5px;
        height: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #e9e9e9;    

      // =======================================Style1
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      //-----------------------------------------------
        
      }

		.column.columnscrollareacardrow01111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:20px ; background-color: transparent ; 
		padding-top:12px ; 
		padding-bottom:12px ; 
		padding-left:12px ; 
		padding-right:12px ; 

			.column.columncolumnscrollareacardrow011111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:4px ; background-color: transparent ; 

				.column.columncolumncolumnscrollareacardrow0111115{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 
				padding-top:4px ; 
				padding-bottom:4px ; 
				padding-left:16px ; 
				padding-right:16px ; 

					.text.textcolumncolumnscrollareacardrow01111111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					width: calc(100% - (0px + 0px)) !important;
					
					font-size:12px ; 
					text-transform:uppercase; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

				.column.columncolumncolumnscrollareacardrow0111113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:7px ; background-color: transparent ; 

					.button.buttoncolumncolumncolumnscrollareacardrow01111131{

					height: auto;
					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:36px!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					text-transform:unset ; 
					min-width: auto;
					padding-left: 16px;
					padding-right: 16px;

					i.v-icon{
					}
					}

				
}

				.rectangle.divider{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 2px ; 
				
				margin-right: 2px ; 
				height:1px!important ; 
				width: calc(100% - (2px + 2px)) !important;
				
				background-color:#80808040 ; 

				
}

			
}

		
}

	
}

	.column.columncardrow01323{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	borderTop:solid 1px #80808040 !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.row.rowcolumncardrow01323{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:50px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:4px ; background-color:transparent ; 
		padding-left:15px ; 
		padding-right:15px ; 

			.item.itemrowcolumncardrow013231{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:26px!important ; 
			height:26px!important ; 
			

				.networkimage.networkimageitemrowcolumncardrow0132311{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

				
}

			
}

			.text.textitemrowcolumncardrow01323132{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			margin-bottom: 0px ; 
			flex:1 !important; 
			height: calc(100% - (0px + 0px)) !important;
			
			align-items:center;
			display: flex;
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			}

			
}

			.button.buttonrowcolumncardrow013235{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:18px!important ; 
			height:25px!important ; 
			
			font-size:15px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:15px ; 
			}
			}

			.button.buttonrowcolumncardrow0132354{

			height: auto;
			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			right: -8px ; 
			width:18px!important ; 
			height:25px!important ; 
			
			font-size:15px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:15px ; 
			}
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.card.cardrow01{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncardrow015{


position:absolute !important; 









	.column.columncardrow0132{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowcolumncardrow01313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.select.selectright53{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

		
}

	
}

	.scrollarea.scrollareacardrow0111{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacardrow01111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnscrollareacardrow011111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columncolumncolumnscrollareacardrow0111115{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumncolumnscrollareacardrow01111111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columncolumncolumnscrollareacardrow0111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttoncolumncolumncolumnscrollareacardrow01111131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.rectangle.divider{

				
				position:relative !important; 
				
				
				
				
				
				

				
}

			
}

		
}

	
}

	.column.columncardrow01323{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow01323{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumncardrow013231{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemrowcolumncardrow0132311{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.text.textitemrowcolumncardrow01323132{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumncardrow013235{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumncardrow0132354{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.card.cardrow01{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncardrow015{


position:absolute !important; 









	.column.columncardrow0132{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowcolumncardrow01313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.select.selectright53{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

		
}

	
}

	.scrollarea.scrollareacardrow0111{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacardrow01111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnscrollareacardrow011111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columncolumncolumnscrollareacardrow0111115{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumncolumnscrollareacardrow01111111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columncolumncolumnscrollareacardrow0111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttoncolumncolumncolumnscrollareacardrow01111131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.rectangle.divider{

				
				position:relative !important; 
				
				
				
				
				
				

				
}

			
}

		
}

	
}

	.column.columncardrow01323{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow01323{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumncardrow013231{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemrowcolumncardrow0132311{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.text.textitemrowcolumncardrow01323132{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumncardrow013235{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumncardrow0132354{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.card.cardrow01{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncardrow015{


position:absolute !important; 









	.column.columncardrow0132{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowcolumncardrow01313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.select.selectright53{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

		
}

	
}

	.scrollarea.scrollareacardrow0111{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacardrow01111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnscrollareacardrow011111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columncolumncolumnscrollareacardrow0111115{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumncolumnscrollareacardrow01111111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columncolumncolumnscrollareacardrow0111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttoncolumncolumncolumnscrollareacardrow01111131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.rectangle.divider{

				
				position:relative !important; 
				
				
				
				
				
				

				
}

			
}

		
}

	
}

	.column.columncardrow01323{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow01323{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumncardrow013231{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemrowcolumncardrow0132311{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.text.textitemrowcolumncardrow01323132{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumncardrow013235{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumncardrow0132354{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.card.cardrow01{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncardrow015{


position:absolute !important; 









	.column.columncardrow0132{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowcolumncardrow01313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.select.selectright53{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

		
}

	
}

	.scrollarea.scrollareacardrow0111{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacardrow01111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnscrollareacardrow011111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columncolumncolumnscrollareacardrow0111115{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumncolumnscrollareacardrow01111111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columncolumncolumnscrollareacardrow0111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttoncolumncolumncolumnscrollareacardrow01111131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.rectangle.divider{

				
				position:relative !important; 
				
				
				
				
				
				

				
}

			
}

		
}

	
}

	.column.columncardrow01323{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow01323{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumncardrow013231{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemrowcolumncardrow0132311{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.text.textitemrowcolumncardrow01323132{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumncardrow013235{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumncardrow0132354{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.card.cardrow01{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncardrow015{


position:absolute !important; 









	.column.columncardrow0132{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow0131{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorowcolumncardrow01313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.select.selectright53{

			
			position:relative !important; 
			
			
			
			
			
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
			}

		
}

	
}

	.scrollarea.scrollareacardrow0111{

	
	position:relative !important; 
	
	
	
	
	

		.column.columnscrollareacardrow01111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnscrollareacardrow011111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columncolumncolumnscrollareacardrow0111115{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumncolumnscrollareacardrow01111111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.column.columncolumncolumnscrollareacardrow0111113{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttoncolumncolumncolumnscrollareacardrow01111131{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

				.rectangle.divider{

				
				position:relative !important; 
				
				
				
				
				
				

				
}

			
}

		
}

	
}

	.column.columncardrow01323{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.row.rowcolumncardrow01323{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.item.itemrowcolumncardrow013231{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemrowcolumncardrow0132311{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.text.textitemrowcolumncardrow01323132{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumncardrow013235{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcolumncardrow0132354{

			height: auto;
			
			position:absolute !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}
}
</style>
