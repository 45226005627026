<template>
 <div>  
<div id="AdminEntriesView"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumn05 '"   id="contentwrappercolumn05" ref="contentwrappercolumn05"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumn051 '"   id="slottemplatecontentwrappercolumn051" ref="slottemplatecontentwrappercolumn051"  v-bind:style="{}">
 <div  :class="'column col0111 '"   id="col0111" ref="col0111" v-show="!designMode"  v-bind:style="{}">
 <AdminTableView :class="'object admintableview '"   id="admintableview" ref="admintableview" v-if="!editMode"  v-bind:style="{}" :editItem = "editItem" 
 :deleteItem = "deleteItem" 
 :createItem = "createNewEntry" 
 :mappingFunction = "mappingFunction" 
 :items = "entries" 
 :headers = "headers" 
 :loading = "loading" 
 :title = "editViewHeader" 
 :mapItems = "mapItems" 
>
</AdminTableView>
 <AdminEditView :class="'object adminEditView '"   id="adminEditView" ref="adminEditView" v-if="editMode"  v-bind:style="{}" :back = "goToTableView" 
 :switchLocalization = "switchLocalization" 
 :createEntry = "createEntry" 
 :updateEntry = "updateEntry" 
 :entityName = "selectedItemName" 
 :changed = "changed" 
 :entityID = "selectedItem?.id" 
 :hasLocalization = "hasLocalization" 
>
 <template  v-slot:formContent>
 <div  :class="'slottemplate slottemplateadminEditView1 '"   id="slottemplateadminEditView1" ref="slottemplateadminEditView1"  v-bind:style="{}">
 <AdminWebinarForm :class="'object adminForm '"   id="adminForm" ref="adminForm" v-if="formName == 'AdminWebinarForm'"  v-bind:style="{}" :updateSelectedItem = "updateSelectedItem" 
 :entity = "selectedItem" 
 :subEntity = "subSelectedItem" 
 :localization = "localization" 
>
</AdminWebinarForm>
 <AdminFormationForm :class="'object adminForm '"   id="adminForm" ref="adminForm" v-if="formName == 'AdminFormationForm'"  v-bind:style="{}" :updateSelectedItem = "updateSelectedItem" 
 :entity = "selectedItem" 
 :subEntity = "subSelectedItem" 
 :localization = "localization" 
>
</AdminFormationForm>
 <AdminArticleForm :class="'object adminForm '"   id="adminForm" ref="adminForm" v-if="formName == 'AdminArticleForm'"  v-bind:style="{}" :updateSelectedItem = "updateSelectedItem" 
 :entity = "selectedItem" 
 :subEntity = "subSelectedItem" 
 :localization = "localization" 
>
</AdminArticleForm>
 <AdminOrganisationForm :class="'object adminForm '"   id="adminForm" ref="adminForm" v-if="formName == 'AdminOrganisationForm'"  v-bind:style="{}" :updateSelectedItem = "updateSelectedItem" 
 :entity = "selectedItem" 
 :subEntity = "subSelectedItem" 
 :localization = "localization" 
>
</AdminOrganisationForm>
 <AdminProjectForm :class="'object adminForm '"   id="adminForm" ref="adminForm" v-if="formName == 'AdminProjectForm'"  v-bind:style="{}" :updateSelectedItem = "updateSelectedItem" 
 :entity = "selectedItem" 
 :subEntity = "subSelectedItem" 
 :localization = "localization" 
>
</AdminProjectForm>
 <AdminPageForm :class="'object adminForm '"   id="adminForm" ref="adminForm" v-if="formName == 'AdminPageForm'"  v-bind:style="{}" :updateSelectedItem = "updateSelectedItem" 
 :openDesigner = "goToDesignMode" 
 :entity = "selectedItem" 
 :subEntity = "subSelectedItem" 
 :localization = "localization" 
 :parentID = "parentID" 
>
</AdminPageForm>
 <AdminSessionForm :class="'object adminForm '"   id="adminForm" ref="adminForm" v-if="formName == 'AdminSessionForm'"  v-bind:style="{}" :updateSelectedItem = "updateSelectedItem" 
 :entity = "selectedItem" 
 :subEntity = "subSelectedItem" 
 :localization = "localization" 
 :parentID = "parentID" 
>
</AdminSessionForm>
 <AdminOrgaMemberForm :class="'object adminForm '"   id="adminForm" ref="adminForm" v-if="formName == 'AdminOrgaMemberForm'"  v-bind:style="{}" :updateSelectedItem = "updateSelectedItem" 
 :entity = "selectedItem" 
 :subEntity = "subSelectedItem" 
 :localization = "localization" 
 :parentID = "parentID" 
>
</AdminOrgaMemberForm></div></template>
</AdminEditView>  </div></div></template>
</ContentWrapper>
 <SiteBuilder :class="'object siteBuilder '"   id="siteBuilder" ref="siteBuilder" v-show="designMode"  v-bind:style="{}" :goBack = "backFromDesignMode" 
 :returnData = "getDesignerData" 
>
</SiteBuilder>  </div>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../../At/Utils/ContentWrapper'
import AdminTableView from '../../../At/Components/AdminPage/AdminTableView'
import AdminEditView from '../../../At/Components/AdminPage/AdminEditView'
import AdminWebinarForm from '../../../At/Components/AdminPage/AdminFormular/AdminWebinarForm'
import AdminFormationForm from '../../../At/Components/AdminPage/AdminFormular/AdminFormationForm'
import AdminArticleForm from '../../../At/Components/AdminPage/AdminFormular/AdminArticleForm'
import AdminOrganisationForm from '../../../At/Components/AdminPage/AdminFormular/AdminOrganisationForm'
import AdminProjectForm from '../../../At/Components/AdminPage/AdminFormular/AdminProjectForm'
import AdminPageForm from '../../../At/Components/AdminPage/AdminFormular/AdminPageForm'
import AdminSessionForm from '../../../At/Components/AdminPage/AdminFormular/AdminSessionForm'
import AdminOrgaMemberForm from '../../../At/Components/AdminPage/AdminFormular/AdminOrgaMemberForm'
import SiteBuilder from '../../../At/Components/SiteBuilder/SiteBuilder'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'AdminEntriesView',

    components: {ContentWrapper,AdminTableView,AdminEditView,AdminWebinarForm,AdminFormationForm,AdminArticleForm,AdminOrganisationForm,AdminProjectForm,AdminPageForm,AdminSessionForm,AdminOrgaMemberForm,SiteBuilder,},
	methods: {
	
		goToTableView: function() { 		
	this.getEntries()

	this.editMode = false

}
,
		editItem: function(item) { 		
	this.selectedItem = item

	this.editMode = true

	this.localization = 'fr' 

}
,
		createNewEntry: function() { 		
	this.selectedItem = null

	this.editMode = true

	this.localization = 'fr' 

}
,
		deleteItem: function(item) { 		
	for(let i=0; i < this.mediaFieldName.length; i++){

		let field = this.mediaFieldName[i]

		this.deleteFieldDatas(field, item)

	}

	this.deleteContent(item)

}
,
		deleteFieldDatas: function(field, item) { 		
	let fieldContent = item[field]

	if(fieldContent){

		if(Array.isArray(fieldContent)){

			for(let j=0; j < fieldContent.length; j++){

				let subContent = fieldContent[j]

				this.deleteFile(subContent)

			}

		} else {

			this.deleteFile(fieldContent)

		}

	}

}
,
		deleteFile: function(item) { 		
	if(item?.data){	

		Request.deleteFile(item.data.id)

	}

}
,
		deleteContent: function(item) { 			
	Request.deleteEntry(this.tableName, item.id)

	.then(res => {

		this.$awn.success(this.$t('Admin.entryUpdated'))

		this.getEntries()

	})

}
,
		getEntries: function() { 			
	let config = {	

		sort: ['updatedAt:desc', 'id:desc']

	} 

	

	if(this.filtersForGet){

		config.filters = this.filtersForGet

	}

	

	if(this.fieldsForGet){

		config.fields = this.fieldsForGet

	}

	

	if(this.populateForGet){

		config.populate = this.populateForGet

	}

	

	Request.getTableArgs(this.tableName, config)

	.then((res) => {

		this.entries = res.data.data

			.map(w => {

				w.attributes.id = w.id

				return w.attributes

			})

		this.loading = false

	})

}
,
		updateSelectedItem: function(id) { 			
	Request.getEntry(this.tableName, id)

	.then((res) => {

		this.selectedItem = res.data.data.attributes

		this.selectedItem.id = res.data.data.id

	})

}
,
		switchLocalization: function(lang = 'fr') { 			
	this.localization = lang

}
,
		createEntry: function() { 		
	this.$refs.adminForm.createEntry()

}
,
		updateEntry: function() { 		
	this.$refs.adminForm.updateEntry()

}
,
		setChanged: function(val) { 		
	this.changed = val

}
,
		goToDesignMode: function(data, link) { 		
	this.designMode = true

	this.$refs.siteBuilder.loadProjectData(data, link)

}
,
		backFromDesignMode: function() { 		
	this.designMode = false

}
,
		getDesignerData: function(data, htmlCode) { 		
	this.$refs.adminForm.getDesignerData(data, htmlCode)

}
,
	},
	mounted(){
			
	this.getEntries()

	},
	computed: {
	
		user: function() { 		
	return this.$store.state.user

}
,
		subSelectedItem: function() { 		
	if(this.localization == 'fr') return this.selectedItem

	

	let item = null

	

	if(this.localization == 'en') {

		if(this.selectedItem?.localizations == undefined) return null

		

		item = this.selectedItem?.localizations.data?.filter(l => l.attributes.locale == 'en')[0]

	}else if(this.localization == 'de') {

		if(this.selectedItem?.localizations == undefined) return null

		

		item = this.selectedItem?.localizations.data?.filter(l => l.attributes.locale == 'de')[0]

	}

		

	if(item){	

		item.attributes.id = item.id

		return item.attributes

	}

	return null

}
,
		selectedItemName: function() { 		
	return this.subSelectedItem?.name

}
,
		mediaFieldName: function() { 		
	return [

		"image",

		"logo",

		"firstImage",

		"secondImage",

		"bannerImage",

		"icon",

		"assets",

		"images",

		"imageDescription",

		"bgImage",

		"replaylist"

	]

}
,
	},
	watch: {
	
		parentID: function(val) { 		
	this.goToTableView()

}
,
	},
	data(){
	return {
		
		entries:  []		,
		loading:  true		,
		editMode:  false		,
		designMode:  false		,
		selectedItem:  null		,
		localization:  'fr'		,
		changed:  false		,
		}
	},
	props: {
	
		parentID: {					required: false,			default: () =>  null,		},
		tableName: {					required: false,			default: () =>  null,		},
		formName: {					required: false,			default: () =>  null,		},
		editViewHeader: {					required: false,			default: () =>  "..",		},
		headers: {					required: false,			default: () =>  [],		},
		fieldsForGet: {					required: false,			default: () =>  null,		},
		populateForGet: {					required: false,			default: () =>  null,		},
		filtersForGet: {					required: false,			default: () =>  null,		},
		hasLocalization: {			type: Boolean,			required: false,			default: () =>  true,		},
		mapItems: {			type: Boolean,			required: false,			default: () =>  false,		},
		mappingFunction: {			type: Function,			required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminEntriesView{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

.object.contentwrappercolumn05{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatecontentwrappercolumn051{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.col0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.admintableview{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.adminEditView{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateadminEditView1{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.object.adminForm{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

	
}


}

.object.siteBuilder{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.object.contentwrappercolumn05{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatecontentwrappercolumn051{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.col0111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.admintableview{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.adminEditView{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateadminEditView1{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

	
}


}

.object.siteBuilder{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.object.contentwrappercolumn05{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatecontentwrappercolumn051{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.col0111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.admintableview{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.adminEditView{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateadminEditView1{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

	
}


}

.object.siteBuilder{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.object.contentwrappercolumn05{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatecontentwrappercolumn051{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.col0111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.admintableview{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.adminEditView{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateadminEditView1{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

	
}


}

.object.siteBuilder{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.object.contentwrappercolumn05{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatecontentwrappercolumn051{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.col0111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.admintableview{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.adminEditView{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateadminEditView1{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

	
}


}

.object.siteBuilder{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.object.contentwrappercolumn05{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatecontentwrappercolumn051{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.col0111{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.admintableview{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.adminEditView{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplateadminEditView1{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.adminForm{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

	
}


}

.object.siteBuilder{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
