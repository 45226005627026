<template>
 <div>  
<div id="AdminSessionView"> 
         
 <AdminEntriesView :class="'object adminentriesview0 '"   id="adminentriesview0" ref="adminentriesview0"  v-bind:style="{}" :tableName = "'sessions'" 
 :formName = "'AdminSessionForm'" 
 :headers = "headers" 
 :editViewHeader = "$t('Admin.sessions')" 
 :filtersForGet = "filtersForGet" 
 :hasLocalization = "false" 
 :parentID = "parentID" 
>
</AdminEntriesView>
 
      </div>
 </div> 
</template>
<script>
import AdminEntriesView from '../../../At/Components/AdminPage/AdminEntriesView'


export default {
	name: 'AdminSessionView',

    components: {AdminEntriesView,},
	beforeCreate(){
			
	this.$options.components.AdminEntriesView = require('./AdminEntriesView.vue').default

	},
	computed: {
	
		filtersForGet: function() { 			
	let filter = {}

	

	if(this.parentID){

		filter.webinar = {

			id: {

				$eq: this.parentID

			}

		}

	}

	

	return filter

}
,
		headers: function() { 		
	return [	

	  {

	    text: 'ID',

	    align: 'start',

	    sortable: true,

	    value: 'id'

	  },

	  {

	    text: this.$t('Admin.date'),

	    align: 'start',

	    sortable: true,

	    value: 'date'

	  },

	  {

	    text: this.$t('Admin.maxPlaces'),

	    align: 'start',

	    sortable: true,

	    value: 'maxPlaces'

	  },

	  {

	    text: this.$t('Admin.updatedAt'),

	    align: 'start',

	    sortable: true,

	    value: 'updatedAt'

	  },

	  {

	    text: this.$t('Admin.actions'),

	    align: 'start',

	    sortable: false,

	    value: 'actions'

	  },

	]

}
,
	},
	props: {
	
		parentID: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminSessionView{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.adminentriesview0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


@media (max-width: 600px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 601px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 961px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1265px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1905px) {


.object.adminentriesview0{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}
}
</style>
