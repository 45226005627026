<template>
 <div>  
<div id="OrgaRightSection"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#fff'" 
 @click="showShare" :class="'button buttoncolumn01 '"   id="buttoncolumn01" ref="buttoncolumn01" v-if="!standalone" :_border = "'solid 1px #d7d7d7'" 
  v-bind:style="{border : 'solid 1px #d7d7d7',}"><v-icon  :style = "{}"  v-if = "'ion-ios-share-alt' !== undefined"  :left = "true" 
>{{ 'ion-ios-share-alt' }}
</v-icon><span>{{ $t('Organisations.shareOrga') }}</span>
</v-btn>
 <div  :class="'column columncolumn05 '"   id="columncolumn05" ref="columncolumn05"  v-bind:style="{}">
 <div  :class="'text textcolumncolumn051 '"   id="textcolumncolumn051" ref="textcolumncolumn051"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Organisations.otherOrga') }}</div>

   </div>
 <v-row  :class="'grid gridcolumncolumn055 '"   id="gridcolumncolumn055" ref="gridcolumncolumn055"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumn0551 '"   id="colgridcolumncolumn0551" ref="colgridcolumncolumn0551" v-for="(item,index) in organisations" :key="'colgridcolumncolumn0551' + index"  v-bind:style="{}" :cols = "12" 
 :md = "12" 
 :sm = "6" 
><div class="col-container">
 <OrgaItem :class="'object orgaitemcolgridcolumncolumn0531111 '"   id="orgaitemcolgridcolumncolumn0531111" ref="orgaitemcolgridcolumncolumn0531111"  v-bind:style="{}" :organisation = "item" 
>
</OrgaItem></div></v-col>  </v-row>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import OrgaItem from '../../../At/Components/OrganisationsPage/OrgaItem'

import * as Request from '../../../Js/requests'
export default {
	name: 'OrgaRightSection',

    components: {OrgaItem,},
	methods: {
	
		getOrganisations: function() {		
	let config = ({

		sort: ['id:desc']

	})

	

	if(this.orgaID){	

		config.filters = {		

	    id: {

				$ne: this.orgaID,

			}

	  }	

	}

	

	Request.getTableArgs('organisations', config)

	.then((res) => {

		if(res.data.meta != undefined){	

			this.organisations = res.data.data;

		} else {

			this.organisations = res.data;

		}

		

		if(this.maxItem){	

			this.organisations = this.organisations

				.slice(0, this.maxItem)

		}

		

		this.loading = false;

	}) 

}
,
	},
	mounted(){
			
	this.getOrganisations()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		organisations:  []		,
		}
	},
	props: {
	
		showShare: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		orgaID: {					required: false,			default: () =>  null,		},
		standalone: {			type: Boolean,			required: false,			default: () =>  false,		},
		maxItem: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#OrgaRightSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:18px ; background-color: transparent ; 

.button.buttoncolumn01{

height: auto;

position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:40px!important ; 
border:solid 1px #d7d7d7 !important; 
width: calc(100% - (0px + 0px)) !important;

border-radius:0px ; 
text-transform:unset ; 
min-width: auto;
padding-left: 16px;
padding-right: 16px;

i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

	.text.textcolumncolumn051{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	font-size:20px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.grid.gridcolumncolumn055{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	background-color: transparent ; 

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.object.orgaitemcolgridcolumncolumn0531111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:250px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.orgaitemcolgridcolumncolumn0531111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.orgaitemcolgridcolumncolumn0531111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.orgaitemcolgridcolumncolumn0531111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.orgaitemcolgridcolumncolumn0531111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.grid.gridcolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridcolumncolumn0551{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.object.orgaitemcolgridcolumncolumn0531111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

		
}

	
}


}


}
}
}
</style>
