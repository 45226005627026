<template>
 <div>  
<div id="HPreviewModal"> 
         
 <router-link  :class="'routerlink routerlink0 '"   id="routerlink0" ref="routerlink0"  v-bind:style="{}" :to = "link" 
>
 <div  :class="'column columnrouterlink03 '"   id="columnrouterlink03" ref="columnrouterlink03"  v-bind:style="{}">
 <div  :class="'row card011 ' + `elevation-${2}`"   id="card011" ref="card011" :elevation = "2" 
 :overflow = "'hidden'" 
  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageundefined32 '"   id="networkimageundefined32" ref="networkimageundefined32"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "image" 
>
 <div  :class="'row rownetworkvideo1231 '"   id="rownetworkvideo1231" ref="rownetworkvideo1231"  v-bind:style="{backgroundColor : '#3434347d',}">
 <div  :class="'text textrownetworkvideo11 '"   id="textrownetworkvideo11" ref="textrownetworkvideo11"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ duration }}</div>

   </div>  </div>  </v-img>
 <div  :class="'column columncard03 '"   id="columncard03" ref="columncard03" :_flex = "'1'" 
  v-bind:style="{flex : '1',justifyContent : 'space-between',}">
 <div  :class="'text textType '"   id="textType" ref="textType"  v-bind:style="{color : '#515151',}">
<div class="text-content" :style = "{}">{{ mainCat }}</div>

   </div>
 <div  :class="'text textName '"   id="textName" ref="textName"  v-bind:style="{color : '#515151',}">
<div class="text-content" :style = "{}">{{ name }}</div>

   </div>
 <div  :class="'row rowcolumncard035 '"   id="rowcolumncard035" ref="rowcolumncard035"  v-bind:style="{}">
 <div  :class="'text textrowcolumncard0351 '"   id="textrowcolumncard0351" ref="textrowcolumncard0351" :_flex = "'1'" 
 :_alignSelf = "'flex-end'" 
  v-bind:style="{flex : '1',alignSelf : 'flex-end',color : '#8d8d8d',}">
<div class="text-content" :style = "{}">{{ m_nbParticipants }}</div>

   </div>  </div>  </div>  </div>
 <div  :class="'rectangle rectanglecolumnrouterlink033 '"   id="rectanglecolumnrouterlink033" ref="rectanglecolumnrouterlink033" v-if="!last"  v-bind:style="{backgroundColor : '#00000026',}">  </div>  </div>  </router-link>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../Js/main'
export default {
	name: 'HPreviewModal',

    components: {},
	computed: {
	
		mainCat: function() { 		
	if(this.isWebinar){

		return this.$t('LearningDetails.webinar')

	}

	return this.$t('LearningDetails.course')

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		id: function(){		
	if(this.info){

		return this.info.id

	}

	return null

}
,
		attributes: function(){		
	if(this.info){

		return this.info.attributes

	}

	return null

}
,
		image: function() { 		
	if(this.attributes){

		if(this.attributes.image){

			let item = this.attributes.image.data?.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return null

	}

	return null

}
,
		name: function() { 		
	if(this.attributes){

		return this.attributes.name

	}

	return null

}
,
		duration: function() { 		
	if(this.attributes){

		if(this.attributes.duration){

			return Main.convertHMS(this.attributes.duration)

		}

		return null

	}

	return null

}
,
		baseLink: function() { 		
	return "/" + this.baseUrl + "/"

}
,
		link: function() { 		
	if(this.attributes){

		return this.baseLink + this.attributes.slug

	}

	return this.baseLink

}
,
		m_nbParticipants: function() { 		
	return this.nbParticipants + ' ' + this.$t('Learning.apprenants')

}
,
	},
	data(){
	return {
		
		nbParticipants:  200		,
		}
	},
	props: {
	
		info: {					required: false,			default: () =>  null,		},
		last: {					required: false,			default: () =>  false,		},
		isWebinar: {			type: Boolean,			required: false,			default: () =>  false,		},
		baseUrl: {					required: false,			default: () =>  "",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#HPreviewModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none !important;
	color: #e4580a;
}



.routerlink.routerlink0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
margin-bottom: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
height: calc(100% - (0px + 0px)) !important;


.column.columnrouterlink03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:8px ; background-color: transparent ; 

	.row.card011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	margin-bottom: 0px ; 
	overflow:hidden !important; 
	width: calc(100% - (0px + 0px)) !important;
	height: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;background-color:transparent ; 
	border-radius:3px ; 

		.networkimage.networkimageundefined32{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:128px!important ; 
		height:auto!important ; 
		max-height: 72px!important ; 
		
		display: inherit;

		flex: unset;
		border-radius:4px ; 
		border-style: solid ; 
		border-width:0.5px ; 
		border-color:#808080a8 ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			.row.rownetworkvideo1231{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			right: 0px ; 
			bottom: 0px ; 
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			background-color:#3434347d ; 
			padding-top:2px ; 
			padding-bottom:2px ; 
			padding-left:8px ; 
			padding-right:8px ; 

				.text.textrownetworkvideo11{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#fff ; 
				font-size:12px ; 
				text-transform:capitalize; 
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.column.columncard03{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		flex:1 !important; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:4px ; 
		justify-content:space-between ; background-color: transparent ; 
		padding-left:15px ; 

			.text.textType{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#515151 ; 
			font-size:13px ; 
			text-transform:uppercase; 
			.text-content{
			width: 100%;
			}

			
}

			.text.textName{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#515151 ; 
			line-height:12px ; 
			font-size:12px ; 
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

			.row.rowcolumncard035{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;background-color:transparent ; 

				.text.textrowcolumncard0351{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				width:auto!important ; 
				height:auto!important ; 
				flex:1 !important; 
				alignSelf:flex-end !important; 
				
				color:#8d8d8d ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.rectangle.rectanglecolumnrouterlink033{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:1px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	background-color:#00000026 ; 

	
}


}


}


@media (max-width: 600px) {


.routerlink.routerlink0{


position:relative !important; 






.column.columnrouterlink03{


position:relative !important; 









	.row.card011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimageundefined32{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.row.rownetworkvideo1231{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrownetworkvideo11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.column.columncard03{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textType{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard035{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowcolumncard0351{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.rectangle.rectanglecolumnrouterlink033{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 601px) {


.routerlink.routerlink0{


position:relative !important; 






.column.columnrouterlink03{


position:relative !important; 









	.row.card011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimageundefined32{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.row.rownetworkvideo1231{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrownetworkvideo11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.column.columncard03{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textType{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard035{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowcolumncard0351{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.rectangle.rectanglecolumnrouterlink033{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 961px) {


.routerlink.routerlink0{


position:relative !important; 






.column.columnrouterlink03{


position:relative !important; 









	.row.card011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimageundefined32{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.row.rownetworkvideo1231{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrownetworkvideo11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.column.columncard03{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textType{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard035{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowcolumncard0351{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.rectangle.rectanglecolumnrouterlink033{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 1265px) {


.routerlink.routerlink0{


position:relative !important; 






.column.columnrouterlink03{


position:relative !important; 









	.row.card011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimageundefined32{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.row.rownetworkvideo1231{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrownetworkvideo11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.column.columncard03{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textType{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard035{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowcolumncard0351{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.rectangle.rectanglecolumnrouterlink033{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 1905px) {


.routerlink.routerlink0{


position:relative !important; 






.column.columnrouterlink03{


position:relative !important; 









	.row.card011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimageundefined32{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.row.rownetworkvideo1231{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrownetworkvideo11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.column.columncard03{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textType{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumncard035{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowcolumncard0351{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.rectangle.rectanglecolumnrouterlink033{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}
}
</style>
