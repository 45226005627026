<template>
 <div>  
<div id="SiteBuilder"> 
         
 <div  :class="'row builderRow '"   id="builderRow" ref="builderRow"  v-bind:style="{backgroundColor : '#eee',}">
 <div  :class="'column leftPanel '"   id="leftPanel" ref="leftPanel"  v-bind:style="{backgroundColor : '#1d252b',}">
 <div  :class="'scrollarea scrollarealeftPanel3 '"   id="scrollarealeftPanel3" ref="scrollarealeftPanel3" :_flex = "'1'" 
  v-bind:style="{flex : '1',backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'column pageList '"   id="pageList" ref="pageList"  v-bind:style="{}">  </div>  </div>  </div>
 <div  :class="'column columnbuilderRow7 '"   id="columnbuilderRow7" ref="columnbuilderRow7" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'item gjs '"   id="gjs" ref="gjs" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">  </div>
 <div  :class="'rectangle bottomBar '"   id="bottomBar" ref="bottomBar" :_flex = "''" 
  v-bind:style="{flex : '',backgroundColor : '#1d252b',}">
 <div  :class="'column columnbottomBar3 '"   id="columnbottomBar3" ref="columnbottomBar3"  v-bind:style="{}">
 <a  :href = "pageLink" 
 :class="'link linkbottomBar11 '"   id="linkbottomBar11" ref="linkbottomBar11" :_flex = "'1'" 
 :target = "'_blank'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'row rowlinkbottomBar115 '"   id="rowlinkbottomBar115" ref="rowlinkbottomBar115"  v-bind:style="{}">
 <div  :class="'text textlinkbottomBar1111 '"   id="textlinkbottomBar1111" ref="textlinkbottomBar1111" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
<div class="text-content" :style = "{}">{{ pageLink }}</div>

   </div>
 <div  :class="'icon iconrowlinkbottomBar1153 '"   id="iconrowlinkbottomBar1153" ref="iconrowlinkbottomBar1153"  v-bind:style="{}"><i  :class="'ion-md-open'"></i></div>  </div>  </a>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'

import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";

import gjsPresetWebpage from "grapesjs-preset-webpage";
import gjsBlocksBasic from 'grapesjs-blocks-basic';
import gjsForms from 'grapesjs-plugin-forms';
import gjsCKEditor from 'grapesjs-plugin-ckeditor';
import gjsExport from 'grapesjs-plugin-export';
import gjsTabs from 'grapesjs-tabs';
import gjsCountdown from 'grapesjs-component-countdown';
import gjsCustomCode from 'grapesjs-custom-code';
import gjsNavBar from 'grapesjs-navbar';
import gjsToolTip from 'grapesjs-tooltip';
import gjsParserPostCss from 'grapesjs-parser-postcss';
import gjsTyped from 'grapesjs-typed';
import gjsFlexBlocs from 'grapesjs-blocks-flexbox';
import gjsTailwind from 'grapesjs-tailwind';


import gjsComponentCodeEditor from 'grapesjs-component-code-editor';
import 'grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css';

import gjsStyleBg from 'grapesjs-style-bg';
import "grapick/dist/grapick.min.css";
export default {
	name: 'SiteBuilder',

    components: {},
	methods: {
	
		loadProjectData: function(val, link) { 		
	this.projectData = val

	this.pageLink = link

}
,
		loadData: function() { 		
	if(this.editor){	

		if(this.projectData){

			this.editor.loadProjectData(this.projectData);

		} else {

			this.reset()

		}

	}

}
,
		saveData: function(editor) { 		
	let projectData = this.editor.getProjectData();

	let html = this.editor.getHtml()

	let css = this.editor.getCss()

	let htmlCode = Main.generateHTML(html, css)

	this.returnData(projectData, htmlCode)

}
,
		load: function() { 		
	const escapeName = (name) => `${name}`.trim().replace(/([^a-z0-9\w-:/]+)/gi, '-');

	

	this.editor = grapesjs.init({

     container: '#gjs',

     height: '100%',

     width: '100%',		

		  showOffsets: true,

		  fromElement: true,

		  noticeOnUnload: false,

			selectorManager: { escapeName },

			colorPicker: { 

				showAlpha: true,

        showPalette: true,

					showInput: true,

        preferredFormat: "hex",

				appendTo: 'parent', 

				offset: { top: 26, left: -166, }, 

			},

     plugins: [

				gjsPresetWebpage,

				gjsBlocksBasic,

				gjsForms,

				//gjsCKEditor,

				gjsExport,

				gjsTabs,

				gjsCountdown,

				gjsCustomCode,

				gjsNavBar,

				gjsToolTip,

				gjsParserPostCss,

				gjsStyleBg,

				gjsTyped,

				gjsFlexBlocs,

				gjsTailwind,

				gjsComponentCodeEditor,

				//gjsBootstrap4, 

			],

     storageManager: false,

			assetManager: {

				storageType : '',

				storeOnChange: true,

				storeAfterUpload: true,

				multiUpload: false,

				uploadFile: (e) =>{

					var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

					

					for(let i = 0; i < files.length; i++){

						let file = files[i];

						

						Request.uploadFile(file)

						.then(res => {

							let url = Main.getImgUrl(res.data[0], 'medium');

							this.editor.AssetManager.add({id: res.data[0].id, src: url});

						})

					}

				}

			},

			commands: {		

		    defaults: [

		      {

		        id: 'store-data',

		        run(editor) {

		          editor.store();

		        },

		      }

		    ]

		  },

     deviceManager: {

       devices:

       [

         {

           id: 'desktop',

           name: 'Desktop',

           width: '',

         },

         {

           id: 'tablet',

           name: 'Tablet',

           width: '768px',

           widthMedia: '992px',

         },

         {

           id: 'mobilePortrait',

           name: 'Mobile portrait',

           width: '320px',

           widthMedia: '575px',

         },

       ]

     },

     pluginsOpts: {},

			canvas: {

        styles: [

          'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css'

        ],

        scripts: [

          'https://code.jquery.com/jquery-3.3.1.slim.min.js',

          'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js',

          'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js'

        ],

      }

   })

	

	const commands = this.editor.Commands;

	commands.add('saveData', this.saveData);

	commands.add('goBack', this.goBack);

	

	const panelManager = this.editor.Panels;

	

	const panelViews = panelManager.addPanel({	

	  id: 'views'

	});

	panelViews.get('buttons').add([{

	  attributes: {

	     title: 'Open Code'

	  },

	  className: 'mdi mdi-code-braces',

	  command: 'open-code',

	  togglable: true, //close when button is clicked again

	  id: 'open-code'

	}]);

	

	var saveBtn = panelManager.addButton('options',{

  	id: 'saveBtn',

	  className: 'mdi mdi-content-save',

	  command: 'saveData',

	});

	

	var backBtn = panelManager.addButton('devices-c',{

  	id: 'backBtn',

	  className: 'mdi mdi-arrow-left',

	  command: 'goBack',

	});

	

	var themeBtn = panelManager.addButton("options", {	

	  id: "update-theme",

	  className: "mdi mdi-palette",

	  command: "open-update-theme",

	  attributes: {

	    title: "Update Theme",

	    "data-tooltip-pos": "bottom"

	  }

	});

	

	var assets = this.editor.AssetManager.getAll()

	assets.on('remove', (asset) => {

		if(typeof asset == "object"){

	  	Request.deleteFile(asset.get('id'));

		}

	})

}
,
		reset: function() { 		
	this.editor.DomComponents.clear(); // Clear components

	this.editor.CssComposer.clear();  // Clear styles

	this.editor.UndoManager.clear(); // Clear undo history

	this.editor.setComponents([]) // Add components

	this.editor.setStyle([]) // Add rules

}
,
	},
	mounted(){
			
	this.load()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
	},
	watch: {
	
		editor: function(val) { 		
	this.loadData()

}
,
		projectData: function(val) { 		
	this.loadData()

}
,
	},
	data(){
	return {
		
		projectData:  null		,
		editor:  null		,
		pageLink:  null		,
		}
	},
	props: {
	
		returnData: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		goBack: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SiteBuilder{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

/* We can remove the border we've set at the beginnig */
#gjs::v-deep {
  border: none;
	
	.gjs-pn-views, 
	.gjs-pn-views-container{
		width: 270px;
	}
	
	.gjs-pn-options{
		right: 270px;
	}
	
	.gjs-cv-canvas, 
	.gjs-pn-commands{
		width: calc(100% - 270px);
	}
	
	.gjs-pn-devices-c{
		.gjs-pn-buttons{
			flex-direction: row-reverse;
		}
	}
	
	.gjs-block svg {	
	  width: 100%;
	}

	.change-theme-button {
	  width: 40px;
	  height: 40px;
	  border-radius: 50%;
	  margin: 5px;
	}
	
	.change-theme-button:focus {
	  /* background-color: yellow; */
	  outline: none;
	  box-shadow: 0 0 0 2pt #c5c5c575;
	}
	
	/* Theming */
	
	/* Primary color for the background */
	.gjs-one-bg {
	  background-color: #1d252b;
	}
	
	/* Secondary color for the text color */
	.gjs-two-color {
	  color: #fff;
	}
	
	/* Tertiary color for the background */
	.gjs-three-bg {
	  background-color: #ec5896;
	  color: white;
	}
	
	/* Quaternary color for the text color */
	.gjs-four-color,
	.gjs-four-color-h:hover {
	  color: #e4580acc;
	}
	
	.gjs-field-colorp{
		z-index: 15;
	}

}



.row.builderRow{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
height:100vh!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
background-color:#eee ; 

.column.leftPanel{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
display:none !important; 
width:200px!important ; 
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; 
background-color:#1d252b ; 
padding-top:5px ; 
padding-bottom:5px ; 
padding-left:5px ; 
padding-right:5px ; 

	.scrollarea.scrollarealeftPanel3{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	flex:1 !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	overflow:hidden ; 
	overflow-x:hidden ; 
	overflow-y:auto ; 
	background-color:transparent ; 

		.column.pageList{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		right: 0px ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:4px ; background-color: transparent ; 

		
}

	
}


}

.column.columnbuilderRow7{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
flex:1 !important; 
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.item.gjs{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	flex:1 !important; 
	width: calc(100% - (0px + 0px)) !important;
	

	
}

	.rectangle.bottomBar{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:25px!important ; 
	flex: !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	background-color:#1d252b ; 

		.column.columnbottomBar3{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 
		padding-top:4px ; 
		padding-bottom:4px ; 
		padding-left:10px ; 
		padding-right:10px ; 

			.link.linkbottomBar11{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			margin-bottom: 0px ; 
			flex:1 !important; 
			target:_blank !important; 
			height: calc(100% - (0px + 0px)) !important;
			
			display: block;

				.row.rowlinkbottomBar115{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				top: 0px ; 
				bottom: 0px ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				gap:5px ; background-color:transparent ; 

					.text.textlinkbottomBar1111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					margin-bottom: 0px ; 
					flex:1 !important; 
					height: calc(100% - (0px + 0px)) !important;
					
					font-size:12px ; 
					text-overflow: ellipsis ; 
					.text-content{
					width: 100%;
					white-space: nowrap;
					overflow: hidden ;
					text-overflow: ellipsis ; 
					font-weight: bold;
					}

					
}

					.icon.iconrowlinkbottomBar1153{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
					
					
					top: calc(50% + 0px) ; 
					width:15px!important ; 
					height:15px!important ; 
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.row.builderRow{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.column.leftPanel{


position:relative !important; 









	.scrollarea.scrollarealeftPanel3{

	
	position:relative !important; 
	
	
	
	
	

		.column.pageList{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

		
}

	
}


}

.column.columnbuilderRow7{


position:relative !important; 









	.item.gjs{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.bottomBar{

	
	position:relative !important; 
	
	
	
	
	
	

		.column.columnbottomBar3{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.link.linkbottomBar11{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.row.rowlinkbottomBar115{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textlinkbottomBar1111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.icon.iconrowlinkbottomBar1153{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.row.builderRow{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.column.leftPanel{


position:relative !important; 









	.scrollarea.scrollarealeftPanel3{

	
	position:relative !important; 
	
	
	
	
	

		.column.pageList{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

		
}

	
}


}

.column.columnbuilderRow7{


position:relative !important; 









	.item.gjs{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.bottomBar{

	
	position:relative !important; 
	
	
	
	
	
	

		.column.columnbottomBar3{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.link.linkbottomBar11{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.row.rowlinkbottomBar115{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textlinkbottomBar1111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.icon.iconrowlinkbottomBar1153{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.row.builderRow{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.column.leftPanel{


position:relative !important; 









	.scrollarea.scrollarealeftPanel3{

	
	position:relative !important; 
	
	
	
	
	

		.column.pageList{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

		
}

	
}


}

.column.columnbuilderRow7{


position:relative !important; 









	.item.gjs{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.bottomBar{

	
	position:relative !important; 
	
	
	
	
	
	

		.column.columnbottomBar3{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.link.linkbottomBar11{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.row.rowlinkbottomBar115{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textlinkbottomBar1111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.icon.iconrowlinkbottomBar1153{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.row.builderRow{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.column.leftPanel{


position:relative !important; 









	.scrollarea.scrollarealeftPanel3{

	
	position:relative !important; 
	
	
	
	
	

		.column.pageList{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

		
}

	
}


}

.column.columnbuilderRow7{


position:relative !important; 









	.item.gjs{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.bottomBar{

	
	position:relative !important; 
	
	
	
	
	
	

		.column.columnbottomBar3{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.link.linkbottomBar11{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.row.rowlinkbottomBar115{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textlinkbottomBar1111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.icon.iconrowlinkbottomBar1153{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.row.builderRow{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.column.leftPanel{


position:relative !important; 









	.scrollarea.scrollarealeftPanel3{

	
	position:relative !important; 
	
	
	
	
	

		.column.pageList{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

		
}

	
}


}

.column.columnbuilderRow7{


position:relative !important; 









	.item.gjs{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.bottomBar{

	
	position:relative !important; 
	
	
	
	
	
	

		.column.columnbottomBar3{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.link.linkbottomBar11{

			
			position:relative !important; 
			
			
			
			
			
			display: block;

				.row.rowlinkbottomBar115{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textlinkbottomBar1111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.icon.iconrowlinkbottomBar1153{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
