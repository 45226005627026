<template>
 <div>  
<div id="OrgaMainSection"> 
         
 <div  :class="'column mainColumn '"   id="mainColumn" ref="mainColumn" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',backgroundColor : '#fff',}">
 <div  :class="'item topItem '"   id="topItem" ref="topItem"  v-bind:style="{}">
 <v-img  :class="'networkimage bgImage '"   id="bgImage" ref="bgImage"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "orgaBanner" 
>  </v-img>  </div>
 <div  :class="'column columncolumncolumncoltab013113 '"   id="columncolumncolumncoltab013113" ref="columncolumncolumncoltab013113"  v-bind:style="{}">
 <div  :class="'row rowcolumncolumncolumncoltab0131131 '"   id="rowcolumncolumncolumncoltab0131131" ref="rowcolumncolumncolumncoltab0131131"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'rectangle rectanglerowcolumncolumncolumncoltab01311311 '"   id="rectanglerowcolumncolumncolumncoltab01311311" ref="rectanglerowcolumncolumncolumncoltab01311311" :_alignSelf = "'flex-end'" 
 :_overflow = "'hidden'" 
  v-bind:style="{alignSelf : 'flex-end',overflow : 'hidden',}">
 <v-img  :class="'networkimage networkimagerectanglerowcolumncolumncolumncoltab013113111 '"   id="networkimagerectanglerowcolumncolumncolumncoltab013113111" ref="networkimagerectanglerowcolumncolumncolumncoltab013113111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "orgaLogo" 
>  </v-img>  </div>  </div>
 <div  :class="'column columncolgridcolumncolumncolumncoltab0131133113 '"   id="columncolgridcolumncolumncolumncoltab0131133113" ref="columncolgridcolumncolumncolumncoltab0131133113"  v-bind:style="{}">
 <div  :class="'text textcolumncolgridcolumncolumncolumncoltab0131133111 '"   id="textcolumncolgridcolumncolumncolumncoltab0131133111" ref="textcolumncolgridcolumncolumncolumncoltab0131133111"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ orgaName }}</div>

   </div>
 <div  :class="'text textcolumncolgridcolumncolumncolumncoltab01311331112 '"   id="textcolumncolgridcolumncolumncolumncoltab01311331112" ref="textcolumncolgridcolumncolumncolumncoltab01311331112" v-if="orgaTitle"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ orgaTitle }}</div>

   </div>
 <div  :class="'text textcolumncolgridcolumncolumncolumncoltab013113311123 '"   id="textcolumncolgridcolumncolumncolumncoltab013113311123" ref="textcolumncolgridcolumncolumncolumncoltab013113311123" v-if="orgaLocation"  v-bind:style="{color : '#858787',}">
<div class="text-content" :style = "{}">{{ orgaLocation }}</div>

   </div>
 <ActionLink :class="'object actionlinkcolumncolgridcolumncolumncolumncoltab0131133117 '"   id="actionlinkcolumncolgridcolumncolumncolumncoltab0131133117" ref="actionlinkcolumncolgridcolumncolumncolumncoltab0131133117"  v-bind:style="{}" :action = "showContactModal" 
 :rightIcon = "'ion-ios-expand'" 
 :title = "$t('Profile.contactDetails')" 
 :bold = "false" 
 :size = "'18'" 
>
</ActionLink>
 <ActionLink :class="'object actionlinkcolumncolgridcolumncolumncolumncoltab01311331175 '"   id="actionlinkcolumncolgridcolumncolumncolumncoltab01311331175" ref="actionlinkcolumncolgridcolumncolumncolumncoltab01311331175" v-if="websiteLink"  v-bind:style="{}" :action = "openPersonalPage" 
 :title = "$t('Profile.personalPage')" 
 :bold = "false" 
 :size = "'18'" 
 :rightIcon = "'ion-md-open'" 
 :underline = "true" 
>
</ActionLink>  </div>  </div><v-card class="overflow-hidden"
        
       :class="'toolbar bottomTabbar '"   id="bottomTabbar" ref="bottomTabbar" v-model="curTab"  v-bind:style="{}"
        ><v-toolbar
         :color = "'transparent'" 
 :flat = "true" 
scroll-target="#playground-bottomTabbar"
          style="z-index: 10;"><v-toolbar-title></v-toolbar-title>
  
        

<template v-slot:extension v-if='tabsName.length > 0'>
          <v-tabs
            background-color="transparent"
           v-model="curTab"
 :show-arrows = "true" 
>
        <v-tabs-slider   :color = "primaryColor" 
></v-tabs-slider>
  
        <v-tab
          v-for='(i,n) in tabsName'
          :key="n"
          :href="`#tab-${n}`"
        >
          {{ i }}
          <v-icon v-if='iconName = []' >{{ iconName[n] }}</v-icon></v-tab>
  
        </v-tabs>
        </template>
      </v-toolbar>
        <v-sheet  v-if="!true"
id="playground-bottomTabbar"
          class="overflow-y-auto"
        >
          <v-container style="height: -webkit-fill-available; max-width: unset; padding: unset; position: relative;">
  
          </v-container>
        </v-sheet>
      </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>
import ActionLink from '../../../At/Utils/ActionLink'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'OrgaMainSection',

    components: {ActionLink,},
	methods: {
	
		openPersonalPage: function() { 		
	window.open(this.websiteLink, '_blank').focus();

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		tabsName: function() { 		
	return [

		this.$t('Organisations.home'),

		this.$t('Organisations.about'),

		this.$t('Organisations.posts'),

		this.$t('Organisations.members')

	]

}
,
		orgaName: function() { 		
	if(this.orga){

		return this.orga.name

	}

	return null

}
,
		websiteLink: function() { 		
	if(this.orga){

		return this.orga.websiteLink

	}

	return null

}
,
		orgaTitle: function() { 		
	if(this.orga){

		return this.orga.title

	}

	return null

}
,
		orgaLocation: function() { 		
	if(this.orga){

		return this.orga.location

	}

	return null

}
,
		orgaBanner: function() { 		
	if(this.orga){

		if(this.orga.bannerImage){

			let item = this.orga.bannerImage?.data.attributes;

			return Main.getImgUrl(item, 'medium');

		}

		return Main.getRandomImage(1000)

	}

	return Main.getRandomImage(1000)

}
,
	},
	watch: {
	
		curTab: function(val) { 		
	this.setCurTab(val)

}
,
	},
	data(){
	return {
		
		profileBgEdited:  false		,
		m_profileBg:  null		,
		profileBgBlob:  null		,
		profileImgEdited:  false		,
		m_profileImg:  null		,
		profileImgBlob:  null		,
		curTab:  "tab-0"		,
		}
	},
	props: {
	
		orga: {					required: false,			default: () =>  null,		},
		orgaLogo: {					required: false,			default: () =>  null,		},
		setCurTab: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showContactModal: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#OrgaMainSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.bottomTabbar{
	header{	
    height: auto !important;
	
		::v-deep > .v-toolbar__content  {
			display: none !important;
		}
	}
}



.column.mainColumn{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
overflow:hidden !important; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
background-color:#fff ; 
border-radius:5px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#cecdcb ; 

.item.topItem{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



height:125px!important ; 


	.networkimage.bgImage{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:52px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:space-between ; background-color:transparent ; 
	padding-left:24px ; 
	padding-right:24px ; 

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-bottom: 0px ; 
		width:128px!important ; 
		height:128px!important ; 
		alignSelf:flex-end !important; 
		overflow:hidden !important; 
		
		background-color:#ffffff ; 

			.networkimage.networkimagerectanglerowcolumncolumncolumncoltab013113111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

		
}

	
}

	.column.columncolgridcolumncolumncolumncoltab0131133113{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:5px ; background-color: transparent ; 
	padding-top:24px ; 
	padding-bottom:24px ; 
	padding-left:24px ; 
	padding-right:24px ; 

		.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:25px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:15px ; 
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#858787 ; 
		font-size:15px ; 
		.text-content{
		width: 100%;
		}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:auto!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:auto!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.toolbar.bottomTabbar{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;


::v-deep header.v-sheet{
overflow: hidden;
}

	.overflow-y-auto{

		height: -webkit-fill-available;

	}

::v-deep .v-toolbar.v-toolbar--absolute{


position: relative;

}

}


}


@media (max-width: 600px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 






	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.networkimagerectanglerowcolumncolumncolumncoltab013113111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolgridcolumncolumncolumncoltab0131133113{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.toolbar.bottomTabbar{


position:relative !important; 






::v-deep header.v-sheet{
overflow: hidden;
}

	.overflow-y-auto{

		height: -webkit-fill-available;

	}

::v-deep .v-toolbar.v-toolbar--absolute{


position: relative;

}

}


}
}

@media (min-width: 601px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 




height:99px!important ; 


	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.networkimagerectanglerowcolumncolumncolumncoltab013113111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolgridcolumncolumncolumncoltab0131133113{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.toolbar.bottomTabbar{


position:relative !important; 






::v-deep header.v-sheet{
overflow: hidden;
}

	.overflow-y-auto{

		height: -webkit-fill-available;

	}

::v-deep .v-toolbar.v-toolbar--absolute{


position: relative;

}

}


}
}

@media (min-width: 961px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 




height:159px!important ; 


	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		width:160px!important ; 
		height:160px!important ; 
		
		

			.networkimage.networkimagerectanglerowcolumncolumncolumncoltab013113111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolgridcolumncolumncolumncoltab0131133113{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.toolbar.bottomTabbar{


position:relative !important; 






::v-deep header.v-sheet{
overflow: hidden;
}

	.overflow-y-auto{

		height: -webkit-fill-available;

	}

::v-deep .v-toolbar.v-toolbar--absolute{


position: relative;

}

}


}
}

@media (min-width: 1265px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 




height:195px!important ; 


	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.networkimagerectanglerowcolumncolumncolumncoltab013113111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolgridcolumncolumncolumncoltab0131133113{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.toolbar.bottomTabbar{


position:relative !important; 






::v-deep header.v-sheet{
overflow: hidden;
}

	.overflow-y-auto{

		height: -webkit-fill-available;

	}

::v-deep .v-toolbar.v-toolbar--absolute{


position: relative;

}

}


}
}

@media (min-width: 1905px) {


.column.mainColumn{


position:relative !important; 









.item.topItem{


position:relative !important; 






	.networkimage.bgImage{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.column.columncolumncolumncoltab013113{


position:relative !important; 









	.row.rowcolumncolumncolumncoltab0131131{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowcolumncolumncolumncoltab01311311{

		
		position:relative !important; 
		
		
		
		
		
		

			.networkimage.networkimagerectanglerowcolumncolumncolumncoltab013113111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

	
}

	.column.columncolgridcolumncolumncolumncoltab0131133113{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolgridcolumncolumncolumncoltab0131133111{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab01311331112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolgridcolumncolumncolumncoltab013113311123{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab0131133117{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.actionlinkcolumncolgridcolumncolumncolumncoltab01311331175{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.toolbar.bottomTabbar{


position:relative !important; 






::v-deep header.v-sheet{
overflow: hidden;
}

	.overflow-y-auto{

		height: -webkit-fill-available;

	}

::v-deep .v-toolbar.v-toolbar--absolute{


position: relative;

}

}


}
}
}
</style>
