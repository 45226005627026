<template>
 <div>  
<div id="Profile"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <MainLayout :class="'object defaultlayout01 '"   id="defaultlayout01" ref="defaultlayout01"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout0151 '"   id="contentwrappercolumnslottemplatedefaultlayout0151" ref="contentwrappercolumnslottemplatedefaultlayout0151"  v-bind:style="{}" :paddingLeft = "0" 
 :paddingRight = "0" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <v-row  :class="'grid tab011 '"   id="tab011" ref="tab011"  v-bind:style="{}">
 <v-col  :class="'col coltab03 '"   id="coltab03" ref="coltab03"  v-bind:style="{}" :cols = "12" 
 :md = "8" 
><div class="col-container">
 <div  :class="'column columncoltab0131 '"   id="columncoltab0131" ref="columncoltab0131"  v-bind:style="{}">
 <ProfileMainSection :class="'object profilesectioncolumncoltab01311 '"   id="profilesectioncolumncoltab01311" ref="profilesectioncolumncoltab01311"  v-bind:style="{}" :showEditModal = "showEditModal" 
 :showContactModal = "showContactModal" 
 :user = "user" 
 :watchMode = "watchMode" 
 :profileImage = "profileImage" 
>
</ProfileMainSection>
 <ProfileSection :class="'object profilesectioncolumncoltab01313 '"   id="profilesectioncolumncoltab01313" ref="profilesectioncolumncoltab01313"  v-bind:style="{}" :editAction = "showEditModal" 
 :title = "$t('Profile.description')" 
 :watchMode = "watchMode" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofilesectioncolumncoltab013131 '"   id="slottemplateprofilesectioncolumncoltab013131" ref="slottemplateprofilesectioncolumncoltab013131"  v-bind:style="{}">
 <ExtendableTextSection :class="'object extendabletextsectionslottemplateprofilesectioncolumncoltab0131311 '"   id="extendabletextsectionslottemplateprofilesectioncolumncoltab0131311" ref="extendabletextsectionslottemplateprofilesectioncolumncoltab0131311" v-if="profileDescription"  v-bind:style="{}" :hasTitle = "false" 
 :text = "profileDescription" 
 :maxCharacters = "(mqVal({default:100,md:200}, $mq))" 
>
</ExtendableTextSection></div></template>
</ProfileSection>
 <ProfileSection :class="'object profilesectioncolumncoltab013134 '"   id="profilesectioncolumncoltab013134" ref="profilesectioncolumncoltab013134"  v-bind:style="{}" :editAction = "showEditModal" 
 :title = "$t('Profile.memberships')" 
 :watchMode = "true" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofilesectioncolumncoltab013131 '"   id="slottemplateprofilesectioncolumncoltab013131" ref="slottemplateprofilesectioncolumncoltab013131"  v-bind:style="{}">
 <v-row  :class="'grid memberCardSection '"   id="memberCardSection" ref="memberCardSection"  v-bind:style="{}">
 <v-col  :class="'col colgridslottemplateprofilesectioncolumncoltab01313111 '"   id="colgridslottemplateprofilesectioncolumncoltab01313111" ref="colgridslottemplateprofilesectioncolumncoltab01313111" v-for="(item,index) in orgaMembership" :key="'colgridslottemplateprofilesectioncolumncoltab01313111' + index"  v-bind:style="{}" :cols = "12" 
 :sm = "6" 
><div class="col-container">
 <OrgaCardMember :class="'object orgaCardMember '"   id="orgaCardMember" ref="orgaCardMember"  v-bind:style="{}" :memberShip = "item" 
>
</OrgaCardMember></div></v-col>  </v-row></div></template>
</ProfileSection>
 <div  :class="'column columncolumncoltab013112173 '"   id="columncolumncoltab013112173" ref="columncolumncoltab013112173"  v-bind:style="{}">
 <PostItem :class="'object postitemcolumncoltab013112151 ' + 'noFit'"   id="postitemcolumncoltab013112151" ref="postitemcolumncoltab013112151" v-for="(item,index) in posts" :key="'postitemcolumncoltab013112151' + index"  v-bind:style="{}" :info = "item" 
>
</PostItem>  </div>  </div></div></v-col>
 <v-col  :class="'col coltab05 '"   id="coltab05" ref="coltab05"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
><div class="col-container">
 <div  :class="'column columncoltab0131 '"   id="columncoltab0131" ref="columncoltab0131"  v-bind:style="{}">
 <ProfileLeftSection :class="'object profileleftsectioncolumncoltab01311 '"   id="profileleftsectioncolumncoltab01311" ref="profileleftsectioncolumncoltab01311"  v-bind:style="{}" :showShare = "showShare" 
 :watchMode = "watchMode" 
>
</ProfileLeftSection>  </div></div></v-col>  </v-row></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 <ShareModal :class="'object sharemodalitem052222 '"   id="sharemodalitem052222" ref="sharemodalitem052222" v-if="shareModalVi"  v-bind:style="{}" :close = "hideShare" 
 :url = "shareOptions.url" 
 :title = "shareOptions.title" 
 :hashtags = "shareOptions.hashtags" 
 :subject = "shareOptions.subject" 
 :description = "shareOptions.description" 
>
</ShareModal>
 <EditProfileModal :class="'object editProfileModal '"   id="editProfileModal" ref="editProfileModal" v-if="editModalVisible"  v-bind:style="{}" :close = "closeEditModal" 
 :user = "user" 
>
</EditProfileModal>
 <ContactProfileModal :class="'object contactProfileModal '"   id="contactProfileModal" ref="contactProfileModal" v-if="contactModalVisible"  v-bind:style="{}" :close = "closeContactModal" 
 :user = "user" 
>
</ContactProfileModal>  </div>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import ProfileMainSection from '../../At/Components/ProfilePage/ProfileMainSection'
import ProfileSection from '../../At/Components/ProfilePage/ProfileSection'
import ExtendableTextSection from '../../At/Utils/ExtendableTextSection'
import OrgaCardMember from '../../At/Components/OrganisationsPage/OrgaCardMember'
import PostItem from '../../At/Components/FeedPage/PostItem'
import ProfileLeftSection from '../../At/Components/ProfilePage/ProfileLeftSection'
import ShareModal from '../../At/Sections/Modal/ShareModal'
import EditProfileModal from '../../At/Components/ProfilePage/EditProfileModal'
import ContactProfileModal from '../../At/Components/ProfilePage/ContactProfileModal'

import * as Main from '../../Js/main'
import * as Request from '../../Js/requests'

import introJs from 'intro.js/minified/intro.min.js';
import 'intro.js/minified/introjs.min.css';
export default {
	name: 'Profile',

    components: {MainLayout,ContentWrapper,ProfileMainSection,ProfileSection,ExtendableTextSection,OrgaCardMember,PostItem,ProfileLeftSection,ShareModal,EditProfileModal,ContactProfileModal,},
	methods: {
	
		showShare: function() { 		
	this.shareModalVi = true;

}
,
		hideShare: function() { 		
	this.shareModalVi = false;

}
,
		getPosts: function() { 		
	let config = {	

		filters:{

			author: {

				id: {

					$eq: this.user.id

				}

			}

		},

		populate: {	

			images: "*",

			video: "*",

	    author: {

	      populate: ['image'],

	    }

	  },

		sort: ['updatedAt:desc', 'id:desc']

	}

	

	Request.getTableArgs('posts', config)

	.then((res) => {

		this.posts = res.data.data

	})

}
,
		closeEditModal: function() { 		
	this.editModalVisible = false

}
,
		showEditModal: function() { 		
	this.editModalVisible = true

}
,
		closeContactModal: function() { 		
	this.contactModalVisible = false

}
,
		showContactModal: function() { 		
	this.contactModalVisible = true

}
,
		getOrgaMembership: function() {		
	let config = {

		populate: {

			organisation: {

				populate: '*'

			},

			user: {

				populate: '*'

			}

		},

	  filters: {

	    user: {

	      id: {

					$eq: this.user.id,

				}

	    },

	  }	

	}

	

	Request.getTableArgs('orga-members', config)

	.then((res) => {

		if(res.data.meta != undefined){	

			this.orgaMembership = res.data.data

				.map(m => {

					m.attributes.id = m.id

					return m.attributes

				});

		} else {

			this.orgaMembership = res.data;

		}

	}) 

}
,
		setupTour: function() { 		
	if(!this.watchMode && !this.$store.state.profilIntroWached){

		introJs().setOptions({	

		  steps: [	

			  {

			    element: document.querySelector('.bgImage'),

			    intro: this.$t('Introductions.bgImage')

			  },

			  {

			    element: document.querySelector('.changeBgImageBtn'),

			    intro: this.$t('Introductions.changeBgImageBtn')

			  },

			  {

			    element: document.querySelector('.avatarImage'),

			    intro: this.$t('Introductions.avatarImage')

			  },

			  {

			    element: document.querySelector('.profilePercentage'),

			    intro: this.$t('Introductions.profilePercentage')

			  },

			  {

			    element: document.querySelector('.editProfileBtn'),

			    intro: this.$t('Introductions.editProfileBtn')

			  },

			  {

			    element: document.querySelector('.adminBtn'),

			    intro: this.$t('Introductions.adminBtn')

			  },

			  {

			    element: document.querySelector('.memberCardSection'),

			    intro: this.$t('Introductions.memberCardSection'),

					position: 'bottom'

			  },

			]

		}).start();

		

		this.$store.commit('watchProfileIntro')

	}

}
,
	},
	mounted(){
			
	if(this.slug){	

		this.watchMode = true

		

		let config = {	

		  filters: {

		    slug: {

		      $eq: this.slug,

		    },

		  },	

			populate: {	

				image: "*",

				bgImage: "*"

		  }

		}

			

		Request.getTableArgs('users', config)

		.then((res) => {

			this.user = res.data[0]

			this.watchMode = (this.user.id != this.$store.state.user.id)

		})

	} else if(this.id) {		

		this.watchMode = true

		

		Request.getTable('users/' + this.id)

		.then((res) => {

			this.user = res.data

			this.watchMode = (this.user.id != this.$store.state.user.id)

		})

	} else {

		this.watchMode = false

		

		if(this.user = this.$store.state.user){

			this.user = this.user = this.$store.state.user

		} else {

			this.$router.push('/login')

		}

	}

	

	this.setupTour()

	},
	metaInfo(){
			
	if(this.shareOptions == null) return {}

	

	return {

		title: this.shareOptions.title,

		meta: [		

	   { name: 'description', content: this.shareOptions.description},

	

	   { property: 'og:title', content: this.shareOptions.title},

	   { property: 'og:site_name', content: this.$store.state.companyName},

	   { property: 'og:description', content: this.shareOptions.description},

	   { property: 'og:type', content: 'profile'},

	   { property: 'og:url', content: this.shareOptions.url},

	   { property: 'og:image', content: this.profileImage },

	

	   { name: 'twitter:title', content: this.shareOptions.title},

	   { name: 'twitter:site_name', content: this.$store.state.companyName},

	   { name: 'twitter:description', content: this.shareOptions.description},

	   { name: 'twitter:card', content: 'profile'},

	   { name: 'twitter:url', content: this.shareOptions.url},

	   { name: 'twitter:image', content: this.profileImage }    

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		profileDescription: function() { 		
	if(this.user){

		return this.user.description

	}

	return null

}
,
		profileImage: function() { 		
	if(this.user){

		if(this.user.image){

			let item = this.user.image;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.user.username)

	}

	return null

}
,
		slugWord: function() { 		
	if(this.slug){

		return Main.quebabToWords(this.slug)

	}

	return null

}
,
		shareOptions: function() { 		
	if(this.user){	

		let url = this.$store.state.webUrl

		let slug = this.user.slug

		

		if(slug){

			url += "/profile/" + slug

		} else {

			url += "/profile/id/" + this.user.id

		}

		

		return {

			url: url, 

			title: 'Afrotiative | ' + this.user.username, 

			hashtags: ['Afrotiative', 'profile', this.user.username], 

			description: this.profileDescription, 

			to: '',

			subject: this.user.username + ' ' + this.$t('LearningDetails.onAfro')

		}

	} else if(this.slugWord){	

		return {

			url: window.location.href, 

			title: this.slugWord + '| Afrotiative', 

			hashtags: ['Afrotiative', 'Profile', 'Person', 'Members'], 

			description: this.slugWord, 

			to: '',

			subject: this.slugWord

		}

	}

	

	return null

}
,
		id: function() { 		
	return this.$route.params.id

}
,
		slug: function() { 		
	return this.$route.params.slug

}
,
	},
	watch: {
	
		user: function(val) { 		
	this.getPosts()

	this.getOrgaMembership()

}
,
		id: function(val) { 		
	location.reload();

}
,
		slug: function(val) { 		
	location.reload();

}
,
	},
	data(){
	return {
		
		watchMode:  false		,
		posts:  []		,
		user:  null		,
		orgaMembership:  null		,
		shareModalVi:  false		,
		editModalVisible:  false		,
		contactModalVisible:  false		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Profile{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.object{
	::v-deep > div  {
		min-width: unset !important;
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.object.defaultlayout01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					background-color: transparent ; 

						.col.coltab03{

						
						position:relative !important; 
						margin: unset;
						transform-origin: top left;
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;
							gap:15px ; background-color: transparent ; 

								.object.profilesectioncolumncoltab01311{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.profilesectioncolumncoltab01313{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.object.extendabletextsectionslottemplateprofilesectioncolumncoltab0131311{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.object.profilesectioncolumncoltab013134{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.grid.memberCardSection{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										background-color: transparent ; 

											.col.colgridslottemplateprofilesectioncolumncoltab01313111{

											
											position:relative !important; 
											margin: unset;
											transform-origin: top left;
											
											
											
											height:200px!important ; 
											

											::v-deep .col-container{position: relative;

											height: 100%;

											width: 100%;

												.object.orgaCardMember{

												
												position:relative !important; 
												margin: unset;
												padding: unset;
												transform-origin: top left;
												
												
												margin-left: 0px ; 
												
												margin-top: 0px ; 
												margin-right: 0px ; 
												margin-bottom: 0px ; 
												width: calc(100% - (0px + 0px)) !important;
												height: calc(100% - (0px + 0px)) !important;
												
													::v-deep > * {
														min-width: unset;
														min-height: unset;
													}

												
}

											
}

											
}

										
}

									
}

								
}

								.column.columncolumncoltab013112173{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:5px ; background-color: transparent ; 

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						margin: unset;
						transform-origin: top left;
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;
							gap:15px ; background-color: transparent ; 

								.object.profileleftsectioncolumncoltab01311{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.editProfileModal{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactProfileModal{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profilesectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.profilesectioncolumncoltab01313{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.object.extendabletextsectionslottemplateprofilesectioncolumncoltab0131311{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.object.profilesectioncolumncoltab013134{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.grid.memberCardSection{

										
										position:relative !important; 
										
										
										
										
										

											.col.colgridslottemplateprofilesectioncolumncoltab01313111{

											
											position:relative !important; 
											
											
											
											

											::v-deep .col-container{position: relative;

											height: 100%;

											width: 100%;

												.object.orgaCardMember{

												
												position:relative !important; 
												
												
												
												
												
													::v-deep > * {
														min-width: unset;
														min-height: unset;
													}

												
}

											
}

											
}

										
}

									
}

								
}

								.column.columncolumncoltab013112173{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profileleftsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.editProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profilesectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.profilesectioncolumncoltab01313{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.object.extendabletextsectionslottemplateprofilesectioncolumncoltab0131311{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.object.profilesectioncolumncoltab013134{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.grid.memberCardSection{

										
										position:relative !important; 
										
										
										
										
										

											.col.colgridslottemplateprofilesectioncolumncoltab01313111{

											
											position:relative !important; 
											
											
											
											

											::v-deep .col-container{position: relative;

											height: 100%;

											width: 100%;

												.object.orgaCardMember{

												
												position:relative !important; 
												
												
												
												
												
													::v-deep > * {
														min-width: unset;
														min-height: unset;
													}

												
}

											
}

											
}

										
}

									
}

								
}

								.column.columncolumncoltab013112173{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profileleftsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.editProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profilesectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.profilesectioncolumncoltab01313{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.object.extendabletextsectionslottemplateprofilesectioncolumncoltab0131311{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.object.profilesectioncolumncoltab013134{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.grid.memberCardSection{

										
										position:relative !important; 
										
										
										
										
										

											.col.colgridslottemplateprofilesectioncolumncoltab01313111{

											
											position:relative !important; 
											
											
											
											

											::v-deep .col-container{position: relative;

											height: 100%;

											width: 100%;

												.object.orgaCardMember{

												
												position:relative !important; 
												
												
												
												
												
													::v-deep > * {
														min-width: unset;
														min-height: unset;
													}

												
}

											
}

											
}

										
}

									
}

								
}

								.column.columncolumncoltab013112173{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profileleftsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.editProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profilesectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.profilesectioncolumncoltab01313{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.object.extendabletextsectionslottemplateprofilesectioncolumncoltab0131311{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.object.profilesectioncolumncoltab013134{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.grid.memberCardSection{

										
										position:relative !important; 
										
										
										
										
										

											.col.colgridslottemplateprofilesectioncolumncoltab01313111{

											
											position:relative !important; 
											
											
											
											

											::v-deep .col-container{position: relative;

											height: 100%;

											width: 100%;

												.object.orgaCardMember{

												
												position:relative !important; 
												
												
												
												
												
													::v-deep > * {
														min-width: unset;
														min-height: unset;
													}

												
}

											
}

											
}

										
}

									
}

								
}

								.column.columncolumncoltab013112173{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profileleftsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.editProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profilesectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.object.profilesectioncolumncoltab01313{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.object.extendabletextsectionslottemplateprofilesectioncolumncoltab0131311{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.object.profilesectioncolumncoltab013134{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

									.slottemplate.slottemplateprofilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
									left: 0;
									top:0;
									right: 0;
									bottom: 0;
									width: 100% !important;
									height: 100% !important;

										.grid.memberCardSection{

										
										position:relative !important; 
										
										
										
										
										

											.col.colgridslottemplateprofilesectioncolumncoltab01313111{

											
											position:relative !important; 
											
											
											
											

											::v-deep .col-container{position: relative;

											height: 100%;

											width: 100%;

												.object.orgaCardMember{

												
												position:relative !important; 
												
												
												
												
												
													::v-deep > * {
														min-width: unset;
														min-height: unset;
													}

												
}

											
}

											
}

										
}

									
}

								
}

								.column.columncolumncoltab013112173{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.postitemcolumncoltab013112151{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.profileleftsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.editProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactProfileModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
