<template>
 <div>  
<div id="Webinar"> 
         
 <MainLayout :class="'object defaultlayout0 '"   id="defaultlayout0" ref="defaultlayout0"  v-bind:style="{}" :currentTab = "2" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{}">
 <LearningTopSection :class="'object learningtopsectioncolumnslottemplatedefaultlayout0155 '"   id="learningtopsectioncolumnslottemplatedefaultlayout0155" ref="learningtopsectioncolumnslottemplatedefaultlayout0155"  v-bind:style="{}">
</LearningTopSection>
 <div  :class="'column columnslottemplatedefaultlayout0152 '"   id="columnslottemplatedefaultlayout0152" ref="columnslottemplatedefaultlayout0152"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout01513310112 '"   id="contentwrappercolumnslottemplatedefaultlayout01513310112" ref="contentwrappercolumnslottemplatedefaultlayout01513310112" v-for="(cat, catIndex) in categories" :key="'contentwrappercolumnslottemplatedefaultlayout01513310112' + catIndex" v-if="returnCatVisible2(cat)"  v-bind:style="{}" :paddingTop = "40" 
 :paddingBottom = "40" 
 :bgColor = "getSectionBg(catIndex)" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <div  :class="'column columncoltab01311 '"   id="columncoltab01311" ref="columncoltab01311"  v-bind:style="{}">
 <SwiperRow :class="'object swiperrowcolumncoltab01311 '"   id="swiperrowcolumncoltab01311" ref="swiperrowcolumncoltab01311" v-for="(subcat, subcatIndex) in cat.attributes.subcategories.data" :key="'swiperrowcolumncoltab01311' + subcatIndex" v-if="subcat.attributes.webinars.data.length > 0"  v-bind:style="{}" :list = "subcat.attributes.webinars.data" 
 :title = "subcat.attributes.name" 
 :baseUrl = "'webinars'" 
>
</SwiperRow>  </div></div></template>
</ContentWrapper>  </div>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import LearningTopSection from '../../At/Components/FeedPage/LearningTopSection'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import SwiperRow from '../../At/Utils/SwiperRow'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
export default {
	name: 'Webinar',

    components: {MainLayout,LearningTopSection,ContentWrapper,SwiperRow,},
	methods: {
	
		getCategories: function() { 		
	let config = {	

		populate: {	

			webinars: {

				fields: ['id'],

				sort: 'updatedAt:desc',

			},

	    subcategories: {

	      populate: {

					webinars: {

						sort: 'updatedAt:desc',

						populate: "*"

					}

				}

	    }

	  }

	}

	

	Request.getLocalTableArgs('categories', config)

	.then((res) => {

		this.categories = res.data.data

	})

}
,
		getImage: function(item) { 		
	if(item.image){

			return Main.getImgUrl(item.image, 'small');

	}

	return "https://picsum.photos/200"

}
,
		returnCatVisible2: function(cat) { 		
	return cat.attributes.webinars.data.length > 0

}
,
		getSectionBg: function(index) { 		
	return ((index % 2) == 0 ? '#fff' : '#f1ece5')

}
,
	},
	mounted(){
			
	this.getCategories()

	},
	metaInfo(){
			
	return {

		title: this.companyName + " | Webinars",

		meta: [		

	   { name: 'description', content: this.$t('Webinar.description')},

	

	   { property: 'og:title', content: this.companyName + " | Webinars"},

	   { property: 'og:description', content: this.$t('Webinar.description')},

	   { property: 'og:type', content: 'website'},

	   { property: 'og:url', content: this.$store.state.webUrl + '/webinars'},

	

	   { name: 'twitter:title', content: this.companyName + " | Webinars"},

	   { name: 'twitter:description', content: this.$t('Webinar.description')},

	   { name: 'twitter:card', content: 'website'},

	   { name: 'twitter:url', content: this.$store.state.webUrl + '/webinars'}

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		selectedLang: function() { 		
	return this.$store.state.selectedLang

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
	},
	watch: {
	
		selectedLang: function() { 		
	this.getCategories()

}
,
	},
	data(){
	return {
		
		categories:  []		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Webinar{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.defaultlayout0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:50px ; background-color: transparent ; 

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.learningtopsectioncolumnslottemplatedefaultlayout0155{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.column.columnslottemplatedefaultlayout0152{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout01513310112{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.column.columncoltab01311{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.swiperrowcolumncoltab01311{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
