import router from "../../router";
import store from "../../store";
import i18n from "../../i18n";
import * as Request from './requests';

import {
  fbButton,
  tw,
  reddit,
  pinterest,
  tumblr,
  vk,
  ok,
  mail,
  email,
  linkedin,
  whatsapp,
  viber,
  telegram,
  line,
} from "vanilla-sharing";

export function checkLang() {
  // Get the current path
  let path = router.history.current.path;
  // Get the current langauge in query
  let lang = router.history.current.query.lang;
  // Get the actual sectected language
  let selectedLang = store.state.selectedLang;

  // If there is a language props in the query
  if (lang) {
    if (lang !== selectedLang) {
      store.commit("changeSelectedLang", lang);
    }
  } else {
    // Else check if one language was already choosed in cache and pass it as query
    /*if(selectedLang){
			router.push({ path: path, query: { ...router.history.current.query, lang:  selectedLang} })			
		} else {
			// Else pass the defaut language
			router.push({ path: path, query: { ...router.history.current.query, lang:  i18n.fallbackLocale} })		
		}
		location.reload();*/
  }
}

export function reloadLangChange() {
  // Get the current path
  let path = router.history.current.path;
  // Get the actual sectected language
  let selectedLang = store.state.selectedLang;
  router.push({
    path: path,
    query: { ...router.history.current.query, lang: selectedLang },
  });
  location.reload();
}

export function validateEmail(email) {
  if (email == null || email == undefined) {
    return false;
  }
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

export function getCurrentTime() {
  let today = new Date();
  return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
}

export function mergeObject(obj1, obj2) {
  return { ...obj1, ...obj2 };
}

export function share(
  target,
  { url, title, hashtags, description, to, tags, subject }
) {
  switch (target) {
    case "facebook":
      fbButton({ url: url });
			break;
    case "twitter":
      tw({ url: url, title: title, hashtags });
			break;
    case "reddit":
      reddit({ url: url, title: title });
			break;
    case "pinterest":
      pinterest({ url: url, description: description });
			break;
    case "tumblr":
      tumblr({ url: url, tags: tags });
			break;
    case "vk":
      fbButton({ url: url });
			break;
    case "vk":
      fbButton({ url: url });
			break;
    case "ok":
      ok({ url: url });
			break;
    case "mail":
      mail({ url: url, title: title, description: description });
			break;
    case "email":
      email({
        to: to,
        url: url,
        title: title,
        description: description,
        subject: subject,
      });
			break;
    case "linkedin":
      linkedin({ url: url, title: title, description: description });
			break;
    case "whatsapp":
      whatsapp({ url: url, title: title });
			break;
    case "viber":
      viber({ url: url, title: title });
			break;
    case "telegram":
      telegram({ url: url, title: title });
			break;
    case "line":
      line({ url: url, title: title });
			break;
  }
}

export function getImgUrl(asset, size = "medium") {
  if (asset) {
    let url = "";
    if (asset.formats) {
      url = switchFormat(asset, size);
    } else {
      url = asset.url;
    }
    return getUrl({url, provider: asset.provider});
  } else {
    return "https://picsum.photos/1000";
  }
}

export function getMoodleAssets(url){
	return url + '?token=' + store.state.moodleToken
}

function switchFormat(asset, size) {
  switch (size) {
    case "medium":
      return (
        asset.formats.medium?.url ?? switchFormat(asset, "small")
      );
    case "small":
      return (
        asset.formats.small?.url ?? switchFormat(asset, "large")
      );
    case "large":
      return (
        asset.formats.large?.url ?? switchFormat(asset, "thumbnail")
      );
    case "thumbnail":
      return asset.formats.thumbnail?.url ?? switchFormat(asset);
    default:
      return asset.url;
  }
}

export function getErrorMessage(error) {
	console.log(error)
  return error.response.data.message[0].messages[0].message;
}

export function getUrl(attributes){
	if(attributes.provider == "cloudinary"){
		return attributes.url
	}
	return store.state.apiServer + attributes.url
}

export function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (hours > 0) {hours   = hours + "h ";} else {hours = ""}
	
    if (minutes < 10) {minutes = "0"+minutes;}
    if (minutes > 0) {minutes   = minutes + "m ";} else {minutes = ""}
	
    if (seconds < 10) {seconds = "0"+seconds;}
    if (seconds > 0) {seconds   = seconds + "s";} else {seconds = ""}

    return hours + minutes + seconds; // Return is HH : MM : SS
}

export function openInNewTab(url){
	window.open(url, '_blank').focus();
}

export function getRandomAvatar(name){
	return "https://avatars.dicebear.com/api/big-smile/" + name + ".svg"
}

export function getRandomImage(size){
	size = size ?? 500
	return `https://source.unsplash.com/random/${size}?sig=${Math.random()}`
}

export function selectFile (contentType, multiple){	
	return new Promise(resolve => {
	    let input = document.createElement('input');
	    input.type = 'file';
	    input.multiple = multiple;
	    input.accept = contentType;
	
	    input.onchange = _ => {
	        let files = Array.from(input.files);
	        if (multiple)
	            resolve(files);
	        else
	            resolve(files[0]);
	    };
	
	    input.click();
	});
}

export function checkIfHeIsAlreadyEnrolled(orgaID, userID, callBack1, callback2){
	let config = {
	  filters: {
	    user: {
	      id: {
					$eq: userID,
				}
	    },
	    organisation: {
	      id: {
					$eq: orgaID,
				}
	    },
	  }	
	}
	
	Request.getTableArgs('orga-members', config)
	.then((res) => {
		console.log("----> is already enrolled?", res)
		let orgaMemberships = res.data.data
		
		if(orgaMemberships.length == 0){
			if(callBack1) callBack1()
		} else {
			if(callback2) callback2()
		}
	}) 

}

export function autoErollment(userID, callBack){
	let orgaID = store.state.orgaToEnroll
	
	checkIfHeIsAlreadyEnrolled(
		orgaID, 
		userID, 
		_ => {	
			Request.createMembership({	
				organisation: 1,
				user: userID,
				adhesionDate: new Date()
			})
			.then(res => {
				if(orgaID){		
					Request.createMembership({
						organisation: orgaID,
						user: userID,
						adhesionDate: new Date()
					})
					.then(res => {
						store.commit('setOrgaToEnroll', null)
						if(callBack) callBack()
					})
				} else {
					if(callBack) callBack()
				}
			})
		},
		callBack
	)
}

export function notify(title, config = {}) {	
	if (!("Notification" in window)) {
	 // Check if the browser supports notifications
	 alert("This browser does not support desktop notification");
	} else if (Notification.permission === "granted") {
	 // Check whether notification permissions have already been granted;
	 // if so, create a notification
	 const notification = new Notification(title);
	 // …
	} else if (Notification.permission !== "denied") {
	 // We need to ask the user for permission
	 Notification.requestPermission().then((permission) => {
	   // If the user accepts, let's create a notification
	   if (permission === "granted") {
	     const notification = new Notification(title);
	     // …
	   }
	 });
	}
	
	// At last, if the user has denied notifications, and you
	// want to be respectful there is no need to bother them anymore.
}

export function download(href, name, target="_self"){
	var a = document.createElement('a');
  a.href = href;
  a.download = name;
  a.target = target;
  a.click();
}

export function quebabToWords(text){
	return text.replace(/(^\w|-\w)/g, clearAndUpper);
}

function clearAndUpper(text) {
  return text.replace(/-/, " ").toUpperCase();
}

export function checkFilesSize(files, maxSize=2){
	for(let x in files){
		let file = files[x];
		let filesize = ((file.size/1024)/1024).toFixed(4); // MB
		
		if(filesize > maxSize){
			return [false, file.name]
		}
	}
	return [true, null]
}

export function generateHTML(html, css){
	return `
	<html>
		<head>
			<script type="text/javascript" src="https://code.jquery.com/jquery-3.3.1.slim.min.js"></script>
			<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js"></script>
			<script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js"></script>
			<script type="text/javascript" src="https://cdn.tailwindcss.com"></script>
			
			<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css">
			
			<style>
				${css}
			</style>
		</head>
		${html}
	</html>
	`
}

export function computeProlileCompletion(user){
	let total = 0;
	let completed = 0;
	
	let scoreOfKeys = {
		country: 5,
		slug: 2,
		description: 10,
		city: 2,
		firstName: 5,
		lastName: 5,
		image: 10,
		bgImage: 5,
		personalLink: 2,
		title: 3
	}
	
	for(let key in user){
		let score = scoreOfKeys[key] ?? 1
		
		if(user[key]){
			completed += score
		}
		total += score
	}
	
	return completed/total
}


