<template>
 <div>  
<div id="ResultGroup"> 
         
 <div  :class="'column column0 ' + ' ' + 'elevation-0'"   id="column0" ref="column0"  v-bind:style="{backgroundColor : actived ? 'transparent' : '#fff',borderWidth : getUnit(actived ? 0 : 1,'px'),borderColor : actived ? 'transparent' : '#00000026', borderStyle:'solid' ,  }">
 <div  :class="'column columncolumn01 '"   id="columncolumn01" ref="columncolumn01"  v-bind:style="{paddingLeft : (mqVal({default:16+'px',md:getUnit(padding,'px')}, $mq)),paddingRight : (mqVal({default:16+'px',md:getUnit(padding,'px')}, $mq)),}">
 <div  :class="'text textcolumncolumn011 '"   id="textcolumncolumn011" ref="textcolumncolumn011"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>  </div>
 <div  :class="'column columncolumn013 '"   id="columncolumn013" ref="columncolumn013"  v-bind:style="{paddingLeft : (mqVal({default:12+'px',md:getUnit(padding,'px')}, $mq)),paddingRight : (mqVal({default:12+'px',md:getUnit(padding,'px')}, $mq)),}">
 <div  :class="'slot slotcolumncolumn0131 '"   id="slotcolumncolumn0131" ref="slotcolumncolumn0131"  v-bind:style="{}"><slot  :name = "'content'" 
></slot></div>  </div>
 <div  :class="'rectangle divider '"   id="divider" ref="divider" v-if="!actived"  v-bind:style="{backgroundColor : '#00000026',}">  </div>
 <div  :class="'column rowcolumn07 '"   id="rowcolumn07" ref="rowcolumn07" v-if="!actived" v-on:click="action"  :_cursor = "'pointer'" 
 v-ripple = "true"  v-bind:style="{cursor : 'pointer',}">
 <div  :class="'text textrowcolumn071 '"   id="textrowcolumn071" ref="textrowcolumn071"  v-bind:style="{color : '#616264',}">
<div class="text-content" :style = "{}">{{ actionText }}</div>

   </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ResultGroup',

    components: {},
	props: {
	
		title: {					required: false,			default: () =>  "Autres produits Afrotiative",		},
		actionText: {					required: false,			default: () =>  "...",		},
		padding: {					required: false,			default: () =>  24,		},
		actived: {			type: Boolean,			required: false,			default: () =>  false,		},
		action: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ResultGroup{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
border-radius:8px ; 

.column.columncolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
padding-top:8px ; 
padding-bottom:0px ; 
padding-left:16px ; 
padding-right:16px ; 

	.text.textcolumncolumn011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	font-size:18px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}


}

.column.columncolumn013{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
padding-top:8px ; 
padding-bottom:8px ; 

	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	}


}

.rectangle.divider{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:1px!important ; 
width: calc(100% - (0px + 0px)) !important;

background-color:#00000026 ; 


}

.column.rowcolumn07{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
cursor:pointer !important; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 
padding-top:14px ; 
padding-bottom:14px ; 
padding-left:8px ; 
padding-right:8px ; 

	.text.textrowcolumn071{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	color:#616264 ; 
	text-align:center;
	display: flex;
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn013{


position:relative !important; 









	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.divider{


position:relative !important; 








}

.column.rowcolumn07{


position:relative !important; 









	.text.textrowcolumn071{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn013{


position:relative !important; 









	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.divider{


position:relative !important; 








}

.column.rowcolumn07{


position:relative !important; 









	.text.textrowcolumn071{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 








padding-top:16px ; 

	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn013{


position:relative !important; 








padding-top:16px ; 
padding-bottom:16px ; 

	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.divider{


position:relative !important; 








}

.column.rowcolumn07{


position:relative !important; 









	.text.textrowcolumn071{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn013{


position:relative !important; 









	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.divider{


position:relative !important; 








}

.column.rowcolumn07{


position:relative !important; 









	.text.textrowcolumn071{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.text.textcolumncolumn011{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.column.columncolumn013{


position:relative !important; 









	.slot.slotcolumncolumn0131{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.divider{


position:relative !important; 








}

.column.rowcolumn07{


position:relative !important; 









	.text.textrowcolumn071{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}


}
}
}
</style>
