<template>
  <v-app>
      <Router-view></Router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return { 
      title: "Afrotiative - Let's encourage African initiatives",
      meta: [
        { name: 'description', content:  'Afrotiative is an organisation that aims to encourage all African initiatives. It offers different services for young people, pupils, students, professionals and entrepreneurs. All with a focus on social capitalism.'},
        { name: 'keywords', content:  'Afrotiative, African, Initiative, Network, Organisation, Cameroon, social, capitalism, Artefact-labs, E. V.'},
        { name: 'copyright', content:  'Afrotiative'},
        { name: 'author', content:  'Afrotiative'},

        { property: 'og:title', content: "Afrotiative - Let's encourage African initiatives"},
        { property: 'og:site_name', content: "Afrotiative"},
        { property: 'og:description', content: 'Afrotiative is an organisation that aims to encourage all African initiatives. It offers different services for young people, pupils, students, professionals and entrepreneurs. All with a focus on social capitalism.'},
        { property: 'og:type', content: 'website'},
        { property: 'og:url', content: "https://afrotiative.com"},
        { property: 'og:image', content: "https://afrotiative.com/img/favicon-196.png"},
        { property: 'og:phone_number', content: "+49 17674355424"},
        { property: 'og:email', content: "contact@afrotiative.com"},
      
        { name: 'twitter:title', content: "Afrotiative - Let's encourage African initiatives"},
        { name: 'twitter:site_name', content: "Afrotiative"},
        { name: 'twitter:description', content: 'Afrotiative is an organisation that aims to encourage all African initiatives. It offers different services for young people, pupils, students, professionals and entrepreneurs. All with a focus on social capitalism.'},
        { name: 'twitter:card', content: 'website'},
        { name: 'twitter:url', content: "https://afrotiative.com"},
        { name: 'twitter:image', content: "https://afrotiative.com/img/favicon-196.png"},
        { name: 'twitter:og:phone_number', content: "+49 17674355424"},
        { name: 'twitter:og:og:email', content: "contact@afrotiative.com"},

        {name: 'robots', content: 'index,follow'} 
      ]
    }
  }
};
</script>

<style lang = "scss">
  html,body{    
    overflow: hidden !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
</style>
