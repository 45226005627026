<template>
 <div>  
<div id="ExtendableTextSection"> 
         
 <div  :class="'column columncolumn032 '"   id="columncolumn032" ref="columncolumn032"  v-bind:style="{}">
 <div  :class="'text textrowcolumncolumn01331 '"   id="textrowcolumncolumn01331" ref="textrowcolumncolumn01331" v-if="hasTitle"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <div  :class="'text textContent '"   id="textContent" ref="textContent" v-if="!hasHtmlCode"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ m_text }}</div>

   </div>
 <div  :class="'text textContentHTML '"   id="textContentHTML" ref="textContentHTML" v-if="hasHtmlCode" v-html="m_text"  v-bind:style="{}">
<div class="text-content" :style = "{}"></div>

   </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :text = "true" 
 @click="togleExtend" :class="'button buttonrowcolumncolumn013373 '"   id="buttonrowcolumncolumn013373" ref="buttonrowcolumncolumn013373" v-if="longText"  v-bind:style="{}"><span>{{ extended ? $t('Basic.showLess') : $t('Basic.showMore') }}</span>
<v-icon  :style = "{}"  v-if = "extended ? 'ion-ios-arrow-up' : 'ion-ios-arrow-down' !== undefined" >{{ extended ? 'ion-ios-arrow-up' : 'ion-ios-arrow-down' }}
</v-icon></v-btn>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ExtendableTextSection',

    components: {},
	methods: {
	
		togleExtend: function() { 		
	this.extended = !this.extended

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		longText: function() { 		
	return this.text.length > this.maxCharacters

}
,
		m_text: function() { 			
	if(this.text){

		if(this.longText){	

			if(this.extended){

				return this.text

			}

			return this.text.slice(0, this.maxCharacters) + '...'

		} else {

			return this.text

		}

	}

	return "..."

}
,
	},
	data(){
	return {
		
		extended:  false		,
		}
	},
	props: {
	
		title: {					required: false,			default: () =>  "Description du cours",		},
		text: {					required: false,			default: () =>  "...",		},
		maxCharacters: {					required: false,			default: () =>  500,		},
		hasTitle: {			type: Boolean,			required: false,			default: () =>  true,		},
		hasHtmlCode: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ExtendableTextSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.columncolumn032{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

.text.textrowcolumncolumn01331{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:24px!important ; 

font-size:22px ; 
align-items:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;
}


}

.text.textContent{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

.text-content{
width: 100%;
}


}

.text.textContentHTML{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

.text-content{
width: 100%;
}


}

.button.buttonrowcolumncolumn013373{

height: auto;
align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:auto!important ; 
height:24px!important ; 

font-size:15px ; 
text-transform:unset ; 
min-width: auto;
padding-left: 16px;
padding-right: 16px;

i.v-icon{
font-size:15px ; 
}
}


}


@media (max-width: 600px) {


.column.columncolumn032{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContent{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContentHTML{


position:relative !important; 





.text-content{
width: 100%;
}


}

.button.buttonrowcolumncolumn013373{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 601px) {


.column.columncolumn032{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContent{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContentHTML{


position:relative !important; 





.text-content{
width: 100%;
}


}

.button.buttonrowcolumncolumn013373{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 961px) {


.column.columncolumn032{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContent{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContentHTML{


position:relative !important; 





.text-content{
width: 100%;
}


}

.button.buttonrowcolumncolumn013373{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1265px) {


.column.columncolumn032{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContent{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContentHTML{


position:relative !important; 





.text-content{
width: 100%;
}


}

.button.buttonrowcolumncolumn013373{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1905px) {


.column.columncolumn032{


position:relative !important; 









.text.textrowcolumncolumn01331{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContent{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textContentHTML{


position:relative !important; 





.text-content{
width: 100%;
}


}

.button.buttonrowcolumncolumn013373{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}
}
</style>
