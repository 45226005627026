<template>
 <div>  
<div id="LDVideoModal"> 
         
 <DialogBoxModal :class="'object dialogboxmodal0 '"   id="dialogboxmodal0" ref="dialogboxmodal0"  v-bind:style="{}" :close = "close" 
 :title = "title" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedialogboxmodal01 '"   id="slottemplatedialogboxmodal01" ref="slottemplatedialogboxmodal01"  v-bind:style="{}">
 <div  :class="'flow flowslottemplatedialogboxmodal011 '"   id="flowslottemplatedialogboxmodal011" ref="flowslottemplatedialogboxmodal011"  v-bind:style="{}">
<div class="artefactVid"  :class="'networkvideo networkvideoflowslottemplatedialogboxmodal0111 '"   id="networkvideoflowslottemplatedialogboxmodal0111" ref="networkvideoflowslottemplatedialogboxmodal0111" :_flex = "(mqVal({default:'unset',md:'1'}, $mq))" 
  v-bind:style="{flex : (mqVal({default:'unset',md:'1'}, $mq)),}"> <video width="100%" height="100%"  :controls = "true" 
 :autoplay = "false" 
 :preload = "'auto'" 
 :poster = "image" 
>
<source  v-for='(itemVideoUrl,index) in preview' :key="index" :src="itemVideoUrl" >
</video> </div>
 <div  :class="'column columnflowslottemplatedialogboxmodal0113 '"   id="columnflowslottemplatedialogboxmodal0113" ref="columnflowslottemplatedialogboxmodal0113"  v-bind:style="{}">
 <div  :class="'column columncolumnflowslottemplatedialogboxmodal01133 '"   id="columncolumnflowslottemplatedialogboxmodal01133" ref="columncolumnflowslottemplatedialogboxmodal01133"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :outlined = "!item.important" 
 :dark = "true" 
 :class="'button actionBtn211 '"   id="actionBtn211" ref="actionBtn211" v-for="(item,index) in actions" :key="'actionBtn211' + index" :_padding = "'10px'" 
 :to = "item.to" 
 :href = "item.href" 
  v-bind:style="{padding : '10px',}"><span>{{ item.text }}</span>
</v-btn>  </div>
 <div  :class="'rectangle divider '"   id="divider" ref="divider"  v-bind:style="{backgroundColor : '#3f3f3f',}">  </div>
 <div  :class="'column columncolumn015 '"   id="columncolumn015" ref="columncolumn015"  v-bind:style="{}">
 <div  :class="'text textcolumncolumn011 '"   id="textcolumncolumn011" ref="textcolumncolumn011"  v-bind:style="{color : '#000',}">
<div class="text-content" :style = "{}">{{ $t('Learning.whatIncluded') }}</div>

   </div>
 <div  :class="'flow rowcolumncolumn01334 '"   id="rowcolumncolumn01334" ref="rowcolumncolumn01334" v-if="isFree"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerowcolumncolumn01335 '"   id="networkimagerowcolumncolumn01335" ref="networkimagerowcolumncolumn01335"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "'https://api.iconify.design/twemoji:money-with-wings.svg?color=%23e07f10'" 
>  </v-img>
 <div  :class="'text textrowcolumncolumn0133 '"   id="textrowcolumncolumn0133" ref="textrowcolumncolumn0133"  v-bind:style="{color : '#7d7d7d',}">
<div class="text-content" :style = "{}">{{ $t('Learning.free') }}</div>

   </div>  </div>
 <div  :class="'flow rowcolumncolumn0133 '"   id="rowcolumncolumn0133" ref="rowcolumncolumn0133" v-if="hasWorkshop"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerowcolumncolumn01335 '"   id="networkimagerowcolumncolumn01335" ref="networkimagerowcolumncolumn01335"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "'https://api.iconify.design/twemoji:puzzle-piece.svg?color=%23e07f10'" 
>  </v-img>
 <div  :class="'text textrowcolumncolumn0133 '"   id="textrowcolumncolumn0133" ref="textrowcolumncolumn0133"  v-bind:style="{color : '#7d7d7d',}">
<div class="text-content" :style = "{}">{{ $t('Learning.hasWorkshop') }}</div>

   </div>  </div>  </div>
 <div  :class="'rectangle divider4 '"   id="divider4" ref="divider4"  v-bind:style="{backgroundColor : '#3f3f3f',}">  </div>  </div>  </div></div></template>
</DialogBoxModal>
 
      </div>
 </div> 
</template>
<script>
import DialogBoxModal from '../../../At/Sections/Modal/DialogBoxModal'


export default {
	name: 'LDVideoModal',

    components: {DialogBoxModal,},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		preview: function() { 		
	if(this.videoLink){

		return [this.videoLink]

	}

	return []

}
,
		actions: function() { 		
	if(this.isWebinar){

		return [	

			{

				text: this.$t('LearningDetails.registerToWebinar'),

				href: this.url,

				important: true

			}

		]	

	}

	

	return [	

		{

			text: this.$t('LearningDetails.seeCours'),

			href: this.url,

			important: true

		}

	]	

}
,
		possibility: function() { 		
	return [

		this.$t('LearningDetails.offlineRead'),

		this.$t('LearningDetails.quizExercice'),

		this.$t('LearningDetails.certif')

	]

}
,
	},
	props: {
	
		title: {					required: false,			default: () =>  "...",		},
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		isWebinar: {			type: Boolean,			required: false,			default: () =>  false,		},
		videoLink: {					required: false,			default: () =>  null,		},
		image: {					required: false,			default: () =>  null,		},
		url: {					required: false,			default: () =>  null,		},
		isFree: {			type: Boolean,			required: false,			default: () =>  false,		},
		hasWorkshop: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LDVideoModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.dialogboxmodal0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.flowslottemplatedialogboxmodal011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: wrap;
	flex: initial;background-color:transparent ; 

		.networkvideo.networkvideoflowslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:100%!important ; 
		height:auto!important ; 
		flex:unset !important; 
		background-color: black;

		
}

		.column.columnflowslottemplatedialogboxmodal0113{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:100%!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:16px ; background-color: transparent ; 
		padding-left:0px ; 

			.column.columncolumnflowslottemplatedialogboxmodal01133{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:8px ; background-color: transparent ; 

				.button.actionBtn211{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:40px!important ; 
				padding:10px !important; 
				width: calc(100% - (0px + 0px)) !important;
				
				border-radius:0px ; 
				font-size:15px ; 
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				font-size:15px ; 
				}
				}

			
}

			.rectangle.divider{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			opacity: 0.3 ;  filter: alpha(opacity=30) ; 
			height:1px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			background-color:#3f3f3f ; 

			
}

			.column.columncolumn015{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:6px ; background-color: transparent ; 

				.text.textcolumncolumn011{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#000 ; 
				font-size:16px ; 
				text-transform:uppercase; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

				.flow.rowcolumncolumn01334{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: wrap;
				flex: initial;
				gap:4px ; background-color:transparent ; 

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:24px!important ; 
					height:24px!important ; 
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:auto!important ; 
					
					color:#7d7d7d ; 
					font-size:14px ; 
					align-items:center;
					display: flex;
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

				.flow.rowcolumncolumn0133{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: wrap;
				flex: initial;
				gap:4px ; background-color:transparent ; 

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:24px!important ; 
					height:24px!important ; 
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:auto!important ; 
					
					color:#7d7d7d ; 
					font-size:14px ; 
					align-items:center;
					display: flex;
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

			
}

			.rectangle.divider4{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			opacity: 0.3 ;  filter: alpha(opacity=30) ; 
			height:1px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			background-color:#3f3f3f ; 

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.flowslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkvideo.networkvideoflowslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		background-color: black;

		
}

		.column.columnflowslottemplatedialogboxmodal0113{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnflowslottemplatedialogboxmodal01133{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn211{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.rectangle.divider{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.column.columncolumn015{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumn011{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.rowcolumncolumn01334{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.flow.rowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.rectangle.divider4{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.flowslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkvideo.networkvideoflowslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		background-color: black;

		
}

		.column.columnflowslottemplatedialogboxmodal0113{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnflowslottemplatedialogboxmodal01133{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn211{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.rectangle.divider{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.column.columncolumn015{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumn011{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.rowcolumncolumn01334{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.flow.rowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.rectangle.divider4{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.flowslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkvideo.networkvideoflowslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		width:auto!important ; 
		flex:1 !important; 
		background-color: black;

		
}

		.column.columnflowslottemplatedialogboxmodal0113{

		
		position:relative !important; 
		
		
		
		
		width:350px!important ; 
		
		
		
		
		padding-left:24px ; 

			.column.columncolumnflowslottemplatedialogboxmodal01133{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn211{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				font-size:18px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:18px ; 
				}
				}

			
}

			.rectangle.divider{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.column.columncolumn015{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumn011{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.rowcolumncolumn01334{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.flow.rowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.rectangle.divider4{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.flowslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkvideo.networkvideoflowslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		background-color: black;

		
}

		.column.columnflowslottemplatedialogboxmodal0113{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnflowslottemplatedialogboxmodal01133{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn211{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				font-size:20px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:20px ; 
				}
				}

			
}

			.rectangle.divider{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.column.columncolumn015{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumn011{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.rowcolumncolumn01334{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.flow.rowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.rectangle.divider4{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.flow.flowslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.networkvideo.networkvideoflowslottemplatedialogboxmodal0111{

		
		position:relative !important; 
		
		
		
		
		background-color: black;

		
}

		.column.columnflowslottemplatedialogboxmodal0113{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.column.columncolumnflowslottemplatedialogboxmodal01133{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.actionBtn211{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.rectangle.divider{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.column.columncolumn015{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumn011{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.flow.rowcolumncolumn01334{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.flow.rowcolumncolumn0133{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.networkimage.networkimagerowcolumncolumn01335{

					
					position:relative !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.text.textrowcolumncolumn0133{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.rectangle.divider4{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

		
}

	
}


}


}
}
}
</style>
