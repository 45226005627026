import router from '../../router'
import store from '../../store'
import axios from 'axios'
import qs from 'qs'

function $axios(){
	return axios.create({
		headers: {
      Authorization: `Bearer ${store.state.token}`,
    }
	})
}

export function signinGoogle(){
	window.location.href = store.state.apiServer + '/api/connect/google'
}
export function signinFacebook(){
	window.location.href = store.state.apiServer + '/api/connect/facebook'
}
export function signinLinkedin(){
	window.location.href = store.state.apiServer + '/api/connect/linkedin'
}

// Request API.
export function signinProvider(){
	let provider = this.$route.params.provider
	let access_token = this.$route.query.access_token
	
	return axios.get(
      `${store.state.apiServer}/api/auth/${provider}/callback?access_token=${access_token}`
  )
}

// Request API.
export function sendMailConfirmation(mail){
	return axios
  .post(`${store.state.apiServer}/api/auth/send-email-confirmation`, {
    email: mail, // user's email
  })
}

// Request API.
export function signup(name, email, pass){
	return axios
	.post(`${store.state.apiServer}/api/auth/local/register`, {
    username: name,
    email: email,
    password: pass,
  })
} 

// Request API.
export function login(email, pass){
	return axios
	.post(`${store.state.apiServer}/api/auth/local`, {
    identifier: email,
    password: pass,
  })
} 

// Request API.
export function forgotPass(email){
	return axios
	.post(`${store.state.apiServer}/api/auth/forgot-password`, {
    email: email
  })
} 

// Request API.
export function resetPass(password, passwordConfirmation, code){
	return axios
	.post(`${store.state.apiServer}/api/auth/reset-password`, {
    password: password,
    passwordConfirmation: passwordConfirmation,
    code: code
  })
} 

// Request API.
export function logout(){
	store.dispatch('logout') 
	router.push('/login')
} 

// Request API.
export function getArchive(titel){
	let local = store.state.selectedLang
	
	return axios
  .get(`${store.state.apiServer}/api/archives?locale=${local}&filters[slug][$contains]=${titel}`)
}

// Request API.
export function getTable(tableTitel){	
	return axios
  .get(`${store.state.apiServer}/api/${tableTitel}?populate=*`)
}

// Request API.
export function getLocalTable(tableTitel){
	let local = store.state.selectedLang
	
	return axios
  .get(`${store.state.apiServer}/api/${tableTitel}?locale=${local}&populate=*`)
}

// Request API.
export function getTableArgs(tableTitel, args){
	if(typeof args == "object"){
		args = qs.stringify(args, {		
		  encodeValuesOnly: true, // prettify URL
		});
	}
	
	let checkPopulate = args.includes('populate')
	
	return axios
  .get(`${store.state.apiServer}/api/${tableTitel}?${args}${checkPopulate ? '' : '&populate=*'}`)
}

// Request API.
export function getLocalTableArgs(tableTitel, args){
	if(typeof args == "object"){
		args = qs.stringify(args, {		
		  encodeValuesOnly: true, // prettify URL
		});
	}
	
	let local = store.state.selectedLang
	
	let checkPopulate = args.includes('populate')
	
	return axios
  .get(`${store.state.apiServer}/api/${tableTitel}?locale=${local}${checkPopulate ? '' : '&populate=*'}&${args}`)
}


// Get the informations of a specific categorie
export function getCatInfos(id){
	let local = store.state.selectedLang
	 
	const query = qs.stringify({ _where: { _or: [{ localizations: id }, { id: id }] } });
	
	return axios.get(`${store.state.apiServer}/api/categories?locale=${local}&${query}&populate=*`);
}

export function uploadFile(url){
 	let formData = new FormData();
	formData.append('files', url);

	return $axios().post(store.state.apiServer + '/api/upload', formData) 	
}

export function deleteFile(id){
	let apiDeleteUrl = store.state.apiServer + `/api/upload/files/${id}`
	return $axios().delete(apiDeleteUrl)
}

export function updateUserData(data){
	let apiUrl = store.state.apiServer + '/api/users' + `/${store.state.user.id}?populate=*`

  let config = {
   	url: apiUrl,
		method: "put",
		data: data
  };
	
  return $axios()(config)
}

export function updateUserDataField(attribute, data){
	let apiUrl = store.state.apiServer + '/api/users' + `/${store.state.user.id}?populate=*`
	
  let dt = {};
	dt[attribute] = data;

  let config = {
   	url: apiUrl,
		method: "put",
		data: dt
  };
	
  return $axios()(config)
}

export function updateSession(id, data){
	let apiUrl = store.state.apiServer + `/api/sessions/${id}?populate=*`
	
  let dt = {
		data: data
	};

  let config = {
   	url: apiUrl,
		method: "put",
		data: dt
  };
	
  return $axios()(config)
}

export function createPost(config){
	let apiUrl = store.state.apiServer + `/api/posts`
	
  const data = {};
  const formData = new FormData();
	
	data['message'] = config.message
	data['author'] = config.author
	
	if(config.organisation){
		data['organisation'] = config.organisation
	}
	
	if(config.images){	
		config.images.forEach((image) => {	
			formData.append(`files.images`, image, image.name);
		});
	}
	
	if(config.video){
		formData.append(`files.video`, config.video, config.video.name);
	}
	
	formData.append('data', JSON.stringify(data));
	
  return $axios().post(apiUrl, formData)
}

export function createEntry(tableName, data){
	let apiUrl = store.state.apiServer + `/api/${tableName}`
  return $axios().post(apiUrl, data)
}

export function createLocalizedEntry(tableName, id, data){
	if(id) {	
		let apiUrl = store.state.apiServer + `/api/${tableName}/${id}/localizations`
	  return $axios().post(apiUrl, data)
	}
	return this.createEntry(tableName, data)
}

export function getEntry(tableName, id){
	let apiUrl = store.state.apiServer + `/api/${tableName}/${id}?populate=*`
  return axios.get(apiUrl)
}

export function deleteEntry(tableName, id){
	let apiUrl = store.state.apiServer + `/api/${tableName}/${id}`
	
  let config = {
   	url: apiUrl,
		method: "delete",
		data: {}
  };
	
  return $axios()(config)
}

export function updateEntry(tableName, postID, data){
	let apiUrl = store.state.apiServer + `/api/${tableName}/${postID}?populate=*`
	
  let dt = {
		data: data
	};
	
  let config = {
   	url: apiUrl,
		method: "put",
		data: dt
  };
	
  return $axios()(config)
}

export function createMembership(config){
	let apiUrl = store.state.apiServer + `/api/orga-members`
	
  return $axios().post(apiUrl, {data: config})
}

export function getMoodleCat(criteria = [] ){
	var qs = require('qs');
	var data = qs.stringify({
	  'wstoken': store.state.moodleToken,
	  'wsfunction': 'core_course_get_categories',
	  'moodlewsrestformat': 'json',
		'criteria': criteria
	});
	
	var config = {
	  method: 'post',
	  url: 'https://learning.afrotiative.com/webservice/rest/server.php',
	  headers: { 
	    'Content-Type': 'application/x-www-form-urlencoded'
	  },
	  data : data
	};
	
	return axios(config)
}

export function getMoodleCoursByField(field, value){
	var qs = require('qs');
	var data = {
	  'wstoken': store.state.moodleToken,
	  'wsfunction': 'core_course_get_courses_by_field',
	  'moodlewsrestformat': 'json'
	};
	
	if(field){	
		if(value){
			data.field = field
			data.value = value
		}
	}
	
	data = qs.stringify(data)
	
	var config = {
	  method: 'post',
	  url: 'https://learning.afrotiative.com/webservice/rest/server.php',
	  headers: { 
	    'Content-Type': 'application/x-www-form-urlencoded'
	  },
	  data : data
	};
	
	return axios(config)
}

export function getMoodleCoursContent(id){	
	var qs = require('qs');
	var data = qs.stringify({
	  'wstoken': store.state.moodleToken,
	  'wsfunction': 'core_course_get_contents',
	  'moodlewsrestformat': 'json',
	  'courseid': id 
	});
	var config = {
	  method: 'post',
	  url: 'https://learning.afrotiative.com/webservice/rest/server.php',
	  headers: { 
	    'Content-Type': 'application/x-www-form-urlencoded'
	  },
	  data : data
	};
	
	return axios(config)
}

export function getMoodleUsersByField(criteria = [] ){	
	var qs = require('qs');
	var data = qs.stringify({
	  'wstoken': store.state.moodleToken,
	  'wsfunction': 'core_user_get_users',
	  'moodlewsrestformat': 'json',
		'criteria': criteria
	});
	
	var config = {
	  method: 'post',
	  url: 'https://learning.afrotiative.com/webservice/rest/server.php',
	  headers: { 
	    'Content-Type': 'application/x-www-form-urlencoded'
	  },
	  data : data
	};
	
	return axios(config)
}




