<template>
 <div>  
<div id="OrganisationDetail"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <MainLayout :class="'object defaultlayout01 '"   id="defaultlayout01" ref="defaultlayout01"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{}">
 <ContentWrapper :class="'object contentwrappercolumnslottemplatedefaultlayout0151 '"   id="contentwrappercolumnslottemplatedefaultlayout0151" ref="contentwrappercolumnslottemplatedefaultlayout0151"  v-bind:style="{}" :paddingLeft = "0" 
 :paddingRight = "0" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentwrappercolumnslottemplatedefaultlayout01511 '"   id="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511" ref="slottemplatecontentwrappercolumnslottemplatedefaultlayout01511"  v-bind:style="{}">
 <v-row  :class="'grid tab011 '"   id="tab011" ref="tab011"  v-bind:style="{}">
 <v-col  :class="'col coltab03 '"   id="coltab03" ref="coltab03"  v-bind:style="{}" :cols = "12" 
 :md = "9" 
><div class="col-container">
 <div  :class="'column columncoltab0131 '"   id="columncoltab0131" ref="columncoltab0131"  v-bind:style="{}">
 <OrgaMainSection :class="'object orgaMainSection '"   id="orgaMainSection" ref="orgaMainSection"  v-bind:style="{}" :setCurTab = "setCurTab" 
 :showContactModal = "showContactModal" 
 :orga = "organisation" 
 :orgaLogo = "orgaLogo" 
>
</OrgaMainSection>
 <div  :class="'column tab-0 '"   id="tab-0" ref="tab-0" v-if="curTab == 'tab-0'"  v-bind:style="{}">
 <ProfileSection :class="'object profilesectioncolumncoltab013131 '"   id="profilesectioncolumncoltab013131" ref="profilesectioncolumncoltab013131" v-if="orgaDescription"  v-bind:style="{}" :title = "$t('Profile.description')" 
 :watchMode = "true" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofilesectioncolumncoltab013131 '"   id="slottemplateprofilesectioncolumncoltab013131" ref="slottemplateprofilesectioncolumncoltab013131"  v-bind:style="{}">
 <RichText :class="'object textContentRich1 '"   id="textContentRich1" ref="textContentRich1"  v-bind:style="{}" :value = "orgaDescription" 
>
</RichText></div></template>
</ProfileSection>  </div>
 <div  :class="'column tab-01 '"   id="tab-01" ref="tab-01" v-if="curTab == 'tab-1'"  v-bind:style="{}">
 <ProfileSection :class="'object profilesectioncolumncoltab013131 '"   id="profilesectioncolumncoltab013131" ref="profilesectioncolumncoltab013131" v-if="orgaGoal"  v-bind:style="{}" :title = "$t('Organisations.goal')" 
 :watchMode = "true" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofilesectioncolumncoltab013131 '"   id="slottemplateprofilesectioncolumncoltab013131" ref="slottemplateprofilesectioncolumncoltab013131"  v-bind:style="{}">
 <RichText :class="'object textContentRich2 '"   id="textContentRich2" ref="textContentRich2"  v-bind:style="{}" :value = "orgaGoal" 
>
</RichText></div></template>
</ProfileSection>
 <ProfileSection :class="'object profilesectioncolumncoltab0131312 '"   id="profilesectioncolumncoltab0131312" ref="profilesectioncolumncoltab0131312" v-if="orgaDescription"  v-bind:style="{}" :title = "$t('Profile.description')" 
 :watchMode = "true" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofilesectioncolumncoltab013131 '"   id="slottemplateprofilesectioncolumncoltab013131" ref="slottemplateprofilesectioncolumncoltab013131"  v-bind:style="{}">
 <RichText :class="'object textContentRich3 '"   id="textContentRich3" ref="textContentRich3"  v-bind:style="{}" :value = "orgaDescription" 
>
</RichText></div></template>
</ProfileSection>
 <ProfileSection :class="'object profilesectioncolumncoltab01313123 '"   id="profilesectioncolumncoltab01313123" ref="profilesectioncolumncoltab01313123" v-if="orgaDescription"  v-bind:style="{}" :title = "$t('Profile.description')" 
 :watchMode = "true" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofilesectioncolumncoltab013131 '"   id="slottemplateprofilesectioncolumncoltab013131" ref="slottemplateprofilesectioncolumncoltab013131"  v-bind:style="{}">
 <div  :class="'htmlobject gpsLocation '"   id="gpsLocation" ref="gpsLocation" v-html="orgaGpsLocation"  v-bind:style="{}"> </div></div></template>
</ProfileSection>  </div>
 <div  :class="'column tab-02 '"   id="tab-02" ref="tab-02" v-if="curTab == 'tab-2'"  v-bind:style="{}">
 <div  :class="'column columntab-021 '"   id="columntab-021" ref="columntab-021"  v-bind:style="{}">
 <ShareBoxFeed :class="'object shareBoxFeed '"   id="shareBoxFeed" ref="shareBoxFeed"  v-bind:style="{}" :showCreateDialog = "showCreateDialog" 
>
</ShareBoxFeed>  </div>
 <div  :class="'rectangle devider2 '"   id="devider2" ref="devider2"  v-bind:style="{backgroundColor : '#a1a1a166',}">  </div>
 <div  :class="'column columntab-025 '"   id="columntab-025" ref="columntab-025"  v-bind:style="{}">
 <PostItem :class="'object postItem ' + 'noFit'"   id="postItem" ref="postItem" v-for="(item,index) in posts" :key="'postItem' + index"  v-bind:style="{}" :info = "item" 
>
</PostItem>  </div>  </div>
 <div  :class="'column tab-03 '"   id="tab-03" ref="tab-03" v-if="curTab == 'tab-3'"  v-bind:style="{}">
 <ProfileSection :class="'object profilesectioncolumncoltab013131232 '"   id="profilesectioncolumncoltab013131232" ref="profilesectioncolumncoltab013131232" v-if="orgaDescription"  v-bind:style="{}" :watchMode = "true" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplateprofilesectioncolumncoltab013131 '"   id="slottemplateprofilesectioncolumncoltab013131" ref="slottemplateprofilesectioncolumncoltab013131"  v-bind:style="{}">
 <OrgaMembers :class="'object orgamemberstab-0311 '"   id="orgamemberstab-0311" ref="orgamemberstab-0311"  v-bind:style="{}" :orgaID = "organisation?.id" 
>
</OrgaMembers></div></template>
</ProfileSection>  </div>  </div></div></v-col>
 <v-col  :class="'col coltab05 '"   id="coltab05" ref="coltab05"  v-bind:style="{}" :cols = "12" 
 :md = "3" 
><div class="col-container">
 <div  :class="'column columncoltab0131 '"   id="columncoltab0131" ref="columncoltab0131"  v-bind:style="{}">
 <OrgaRightSection :class="'object orgarightsectioncolumncoltab01311 '"   id="orgarightsectioncolumncoltab01311" ref="orgarightsectioncolumncoltab01311"  v-bind:style="{}" :showShare = "showShare" 
 :orgaID = "organisation?.id" 
>
</OrgaRightSection>  </div></div></v-col>  </v-row></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 <ShareModal :class="'object sharemodalitem052222 '"   id="sharemodalitem052222" ref="sharemodalitem052222" v-if="shareModalVi"  v-bind:style="{}" :close = "hideShare" 
 :url = "shareOptions.url" 
 :title = "shareOptions.title" 
 :hashtags = "shareOptions.hashtags" 
 :subject = "shareOptions.subject" 
 :description = "shareOptions.description" 
>
</ShareModal>
 <ContactOrgaModal :class="'object contactOrgaModal '"   id="contactOrgaModal" ref="contactOrgaModal" v-if="contactModalVisible"  v-bind:style="{}" :close = "closeContactModal" 
 :orga = "organisation" 
>
</ContactOrgaModal>
 <CreatePostDialog :class="'object createpostdialogitem034 '"   id="createpostdialogitem034" ref="createpostdialogitem034" v-if="createPostDialogVisible"  v-bind:style="{}" :close = "closeCreateDialog" 
 :getPosts = "getPosts" 
 :orgaID = "orgaID" 
>
</CreatePostDialog>  </div>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'
import OrgaMainSection from '../../At/Components/OrganisationsPage/OrgaMainSection'
import ProfileSection from '../../At/Components/ProfilePage/ProfileSection'
import RichText from '../../At/Utils/RichText'
import ShareBoxFeed from '../../At/Components/FeedPage/ShareBoxFeed'
import PostItem from '../../At/Components/FeedPage/PostItem'
import OrgaMembers from '../../At/Components/OrganisationsPage/OrgaMembers'
import OrgaRightSection from '../../At/Components/OrganisationsPage/OrgaRightSection'
import ShareModal from '../../At/Sections/Modal/ShareModal'
import ContactOrgaModal from '../../At/Components/OrganisationsPage/ContactOrgaModal'
import CreatePostDialog from '../../At/Components/FeedPage/CreatePostDialog'

import * as Main from '../../Js/main'
import * as Request from '../../Js/requests'
export default {
	name: 'OrganisationDetail',

    components: {MainLayout,ContentWrapper,OrgaMainSection,ProfileSection,RichText,ShareBoxFeed,PostItem,OrgaMembers,OrgaRightSection,ShareModal,ContactOrgaModal,CreatePostDialog,},
	methods: {
	
		closeCreateDialog: function() { 		
	this.createPostDialogVisible = false

}
,
		showCreateDialog: function() { 		
	this.createPostDialogVisible = true

}
,
		showShare: function() { 		
	this.shareModalVi = true;

}
,
		hideShare: function() { 		
	this.shareModalVi = false;

}
,
		closeContactModal: function() { 		
	this.contactModalVisible = false

}
,
		showContactModal: function() { 		
	this.contactModalVisible = true

}
,
		setCurTab: function(i) { 		
	this.curTab = i

}
,
		getPosts: function() { 		
	let config = {	

		filters:{

			organisation: {

				id: {

					$eq: this.organisation.id

				}

			}

		},

		populate: {	

			images: "*",

			video: "*",

	    author: {

	      populate: ['image'],

	    },

	    organisation: {

	      populate: ['logo'],

	    }

	  },

		sort: ['updatedAt:desc', 'id:desc']

	}

	

	Request.getTableArgs('posts', config)

	.then((res) => {

		this.posts = res.data.data

	})

}
,
	},
	mounted(){
			
	let config = {	

	  filters: {

	    slug: {

	      $eq: this.slug,

	    },

	  }

	}

		

	Request.getTableArgs('organisations', config)

	.then((res) => {

		this.organisation = res.data.data[0].attributes

		this.organisation.id = res.data.data[0].id

		

		this.getPosts()

	})

	},
	metaInfo(){
			
	if(this.shareOptions == null) return {}

	

	return {

		title: this.shareOptions.title,

		meta: [		

	   { name: 'description', content: this.shareOptions.description},

	

	   { property: 'og:title', content: this.shareOptions.title},

	   { property: 'og:site_name', content: this.$store.state.companyName},

	   { property: 'og:description', content: this.shareOptions.description},

	   { property: 'og:type', content: 'Association'},

	   { property: 'og:url', content: this.shareOptions.url},

	   { property: 'og:image', content: this.orgaLogo },

	

	   { name: 'twitter:title', content: this.shareOptions.title},

	   { name: 'twitter:site_name', content: this.$store.state.companyName},

	   { name: 'twitter:description', content: this.shareOptions.description},

	   { name: 'twitter:card', content: 'Association'},

	   { name: 'twitter:url', content: this.shareOptions.url},

	   { name: 'twitter:image', content: this.orgaLogo }    

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		orgaDescription: function() { 		
	if(this.organisation){

		return this.organisation.description

	}

	return null

}
,
		orgaGoal: function() { 		
	if(this.organisation){

		return this.organisation.goal

	}

	return null

}
,
		orgaID: function() { 		
	return this.organisation.id

}
,
		orgaGpsLocation: function() { 		
	if(this.organisation){

		return this.organisation.gpsLocation

	}

	return null

}
,
		orgaLogo: function() { 		
	if(this.organisation){

		if(this.organisation.logo){

			let item = this.organisation.logo?.data.attributes;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.organisation.name)

	}

	return "https://picsum.photos/300"

}
,
		slugWord: function() { 		
	if(this.slug){

		return Main.quebabToWords(this.slug)

	}

	return null

}
,
		shareOptions: function() { 		
	if(this.organisation){	

		let url = this.$store.state.webUrl 

			+ "/organisations/" 

			+ this.organisation.slug

		

		return {

			url: url, 

			title: 'Afrotiative | ' + this.organisation.name, 

			hashtags: ['Afrotiative', 'Organisation', 'Association', this.organisation.name], 

			description: this.profileDescription, 

			to: '',

			subject: this.organisation.name + ' ' + this.$t('LearningDetails.onAfro')

		}

	} else if(this.slugWord){	

		return {	

			url: window.location.href, 

			title: this.slugWord + '| Afrotiative', 

			hashtags: ['Afrotiative', 'Organisation', 'Association'], 

			description: this.slugWord, 

			to: '',

			subject: this.slugWord

		}

	}

	

	return null

}
,
		slug: function() { 		
	return this.$route.params.slug

}
,
	},
	watch: {
	
		slug: function(val) { 		
	location.reload();

}
,
	},
	data(){
	return {
		
		posts:  []		,
		organisation:  null		,
		curTab:  'tab-0'		,
		shareModalVi:  false		,
		contactModalVisible:  false		,
		createPostDialogVisible:  false		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#OrganisationDetail{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.object{
	::v-deep > div  {
		min-width: unset !important;
	}
}

.gpsLocation{	
	::v-deep > iframe {
		width: 100% !important;
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.object.defaultlayout01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					background-color: transparent ; 

						.col.coltab03{

						
						position:relative !important; 
						margin: unset;
						transform-origin: top left;
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;
							gap:15px ; background-color: transparent ; 

								.object.orgaMainSection{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.column.tab-0{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:10px ; background-color: transparent ; 

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich1{

											
											position:relative !important; 
											margin: unset;
											padding: unset;
											transform-origin: top left;
											
											
											margin-left: 0px ; 
											
											margin-right: 0px ; 
											height:auto!important ; 
											width: calc(100% - (0px + 0px)) !important;
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

								.column.tab-01{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:10px ; background-color: transparent ; 

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich2{

											
											position:relative !important; 
											margin: unset;
											padding: unset;
											transform-origin: top left;
											
											
											margin-left: 0px ; 
											
											margin-right: 0px ; 
											height:auto!important ; 
											width: calc(100% - (0px + 0px)) !important;
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab0131312{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich3{

											
											position:relative !important; 
											margin: unset;
											padding: unset;
											transform-origin: top left;
											
											
											margin-left: 0px ; 
											
											margin-right: 0px ; 
											height:auto!important ; 
											width: calc(100% - (0px + 0px)) !important;
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab01313123{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.htmlobject.gpsLocation{

											
											position:relative !important; 
											margin: unset;
											padding: unset;
											transform-origin: top left;
											
											
											
											}

										
}

									
}

								
}

								.column.tab-02{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:10px ; background-color: transparent ; 

									.column.columntab-021{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
									display: flex;
									flex-wrap: nowrap;
									flex: initial;
									flex-direction: column;background-color: transparent ; 

										.object.shareBoxFeed{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

									.rectangle.devider2{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:1px!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
									background-color:#a1a1a166 ; 

									
}

									.column.columntab-025{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
									display: flex;
									flex-wrap: nowrap;
									flex: initial;
									flex-direction: column;
									gap:5px ; background-color: transparent ; 

										.object.postItem{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.column.tab-03{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
								display: flex;
								flex-wrap: nowrap;
								flex: initial;
								flex-direction: column;
								gap:10px ; background-color: transparent ; 

									.object.profilesectioncolumncoltab013131232{

									
									position:relative !important; 
									margin: unset;
									padding: unset;
									transform-origin: top left;
									
									
									margin-left: 0px ; 
									
									margin-right: 0px ; 
									height:auto!important ; 
									width: calc(100% - (0px + 0px)) !important;
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										margin: unset;
										padding: unset;
										transform-origin: top left;
										
										
										margin-left: 0px ; 
										
										margin-right: 0px ; 
										height:auto!important ; 
										width: calc(100% - (0px + 0px)) !important;
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.orgamemberstab-0311{

											
											position:relative !important; 
											margin: unset;
											padding: unset;
											transform-origin: top left;
											
											
											margin-left: 0px ; 
											
											margin-right: 0px ; 
											height:auto!important ; 
											width: calc(100% - (0px + 0px)) !important;
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						margin: unset;
						transform-origin: top left;
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;
							gap:15px ; background-color: transparent ; 

								.object.orgarightsectioncolumncoltab01311{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								height:auto!important ; 
								width: calc(100% - (0px + 0px)) !important;
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactOrgaModal{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.createpostdialogitem034{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgaMainSection{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.column.tab-0{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich1{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

								.column.tab-01{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich2{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab0131312{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich3{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab01313123{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.htmlobject.gpsLocation{

											
											position:relative !important; 
											
											
											
											
											}

										
}

									
}

								
}

								.column.tab-02{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.column.columntab-021{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.shareBoxFeed{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

									.rectangle.devider2{

									
									position:relative !important; 
									
									
									
									
									
									

									
}

									.column.columntab-025{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.postItem{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.column.tab-03{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131232{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.orgamemberstab-0311{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgarightsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactOrgaModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.createpostdialogitem034{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgaMainSection{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.column.tab-0{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich1{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

								.column.tab-01{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich2{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab0131312{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich3{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab01313123{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.htmlobject.gpsLocation{

											
											position:relative !important; 
											
											
											
											
											}

										
}

									
}

								
}

								.column.tab-02{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.column.columntab-021{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.shareBoxFeed{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

									.rectangle.devider2{

									
									position:relative !important; 
									
									
									
									
									
									

									
}

									.column.columntab-025{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.postItem{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.column.tab-03{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131232{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.orgamemberstab-0311{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgarightsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactOrgaModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.createpostdialogitem034{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgaMainSection{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.column.tab-0{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich1{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

								.column.tab-01{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich2{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab0131312{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich3{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab01313123{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.htmlobject.gpsLocation{

											
											position:relative !important; 
											
											
											
											
											}

										
}

									
}

								
}

								.column.tab-02{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.column.columntab-021{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.shareBoxFeed{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

									.rectangle.devider2{

									
									position:relative !important; 
									
									
									
									
									
									

									
}

									.column.columntab-025{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.postItem{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.column.tab-03{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131232{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.orgamemberstab-0311{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgarightsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactOrgaModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.createpostdialogitem034{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgaMainSection{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.column.tab-0{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich1{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

								.column.tab-01{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich2{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab0131312{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich3{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab01313123{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.htmlobject.gpsLocation{

											
											position:relative !important; 
											
											
											
											
											}

										
}

									
}

								
}

								.column.tab-02{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.column.columntab-021{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.shareBoxFeed{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

									.rectangle.devider2{

									
									position:relative !important; 
									
									
									
									
									
									

									
}

									.column.columntab-025{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.postItem{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.column.tab-03{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131232{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.orgamemberstab-0311{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgarightsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactOrgaModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.createpostdialogitem034{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.object.defaultlayout01{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

	.slottemplate.slottemplatedefaultlayout01{

	
	position:relative !important; 
	
	
	
	
	
	left: 0;
	top:0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;

		.column.columnslottemplatedefaultlayout015{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.contentwrappercolumnslottemplatedefaultlayout0151{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

				.slottemplate.slottemplatecontentwrappercolumnslottemplatedefaultlayout01511{

				
				position:relative !important; 
				
				
				
				
				
				left: 0;
				top:0;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;

					.grid.tab011{

					
					position:relative !important; 
					
					
					
					
					

						.col.coltab03{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgaMainSection{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

								.column.tab-0{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich1{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

								.column.tab-01{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich2{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab0131312{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.textContentRich3{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

									.object.profilesectioncolumncoltab01313123{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.htmlobject.gpsLocation{

											
											position:relative !important; 
											
											
											
											
											}

										
}

									
}

								
}

								.column.tab-02{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.column.columntab-021{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.shareBoxFeed{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

									.rectangle.devider2{

									
									position:relative !important; 
									
									
									
									
									
									

									
}

									.column.columntab-025{

									
									position:relative !important; 
									
									
									
									
									
									
									
									

										.object.postItem{

										
										position:relative !important; 
										
										
										
										
										
											::v-deep > * {
												min-width: unset;
												min-height: unset;
											}

										
}

									
}

								
}

								.column.tab-03{

								
								position:relative !important; 
								
								
								
								
								
								
								
								

									.object.profilesectioncolumncoltab013131232{

									
									position:relative !important; 
									
									
									
									
									
										::v-deep > * {
											min-width: unset;
											min-height: unset;
										}

										.slottemplate.slottemplateprofilesectioncolumncoltab013131{

										
										position:relative !important; 
										
										
										
										
										
										left: 0;
										top:0;
										right: 0;
										bottom: 0;
										width: 100% !important;
										height: 100% !important;

											.object.orgamemberstab-0311{

											
											position:relative !important; 
											
											
											
											
											
												::v-deep > * {
													min-width: unset;
													min-height: unset;
												}

											
}

										
}

									
}

								
}

							
}

						
}

						
}

						.col.coltab05{

						
						position:relative !important; 
						
						
						
						

						::v-deep .col-container{position: relative;

						height: 100%;

						width: 100%;

							.column.columncoltab0131{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.object.orgarightsectioncolumncoltab01311{

								
								position:relative !important; 
								
								
								
								
								
									::v-deep > * {
										min-width: unset;
										min-height: unset;
									}

								
}

							
}

						
}

						
}

					
}

				
}

			
}

		
}

	
}


}

.object.sharemodalitem052222{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.contactOrgaModal{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.createpostdialogitem034{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
