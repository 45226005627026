<template>
 <div>  
<div id="ArticleBox"> 
         
 <div  :class="'flow flow0 '"   id="flow0" ref="flow0"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'item itemflow05 '"   id="itemflow05" ref="itemflow05"  v-bind:style="{}">
 <v-img  :class="'networkimage imageflow011 '"   id="imageflow011" ref="imageflow011"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "image" 
>  </v-img>
 <router-link  :class="'routerlink routerlinkitemflow053 '"   id="routerlinkitemflow053" ref="routerlinkitemflow053"  v-bind:style="{}" :to = "link" 
>  </router-link>  </div>
 <div  :class="'column columnflow03 '"   id="columnflow03" ref="columnflow03" :_flex = "'1'" 
  v-bind:style="{flex : '1',justifyContent : 'space-between',}">
 <div  :class="'column columncolumnflow039 '"   id="columncolumnflow039" ref="columncolumnflow039"  v-bind:style="{}">
 <div  :class="'flow rowrowcolumnflow03554123 '"   id="rowrowcolumnflow03554123" ref="rowrowcolumnflow03554123"  v-bind:style="{}">
 <div  :class="'flow flowcolumnmainContent1111543 '"   id="flowcolumnmainContent1111543" ref="flowcolumnmainContent1111543"  v-bind:style="{}">
 <div  :class="'row tagRow61 '"   id="tagRow61" ref="tagRow61" v-for="(item,index) in tags" :key="'tagRow61' + index"  v-bind:style="{backgroundColor : primaryColor,}">
 <div  :class="'text tagText '"   id="tagText" ref="tagText"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ item.name }}</div>

   </div>  </div>  </div>
 <div  :class="'text textrowrowcolumnflow03553 '"   id="textrowrowcolumnflow03553" ref="textrowrowcolumnflow03553"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ '· ' }}</div>

   </div>
 <div  :class="'text textrowrowcolumnflow035533 '"   id="textrowrowcolumnflow035533" ref="textrowrowcolumnflow035533"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ date }}</div>

   </div>  </div>
 <div  :class="'text textcolumnflow0312 '"   id="textcolumnflow0312" ref="textcolumnflow0312"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ title }}</div>

 
 <router-link  :class="'routerlink routerlinkitemflow0531 '"   id="routerlinkitemflow0531" ref="routerlinkitemflow0531"  v-bind:style="{}" :to = "link" 
>  </router-link>  </div>
 <div  :class="'text textcolumnflow03123 '"   id="textcolumnflow03123" ref="textcolumnflow03123"  v-bind:style="{color : '#646567',}">
<div class="text-content" :style = "{}">{{ description }}</div>

   </div>  </div>
 <div  :class="'row rowcolumnflow035 '"   id="rowcolumnflow035" ref="rowcolumnflow035"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerowcolumnflow0351 '"   id="networkimagerowcolumnflow0351" ref="networkimagerowcolumnflow0351"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "redactorImage" 
>  </v-img>
 <div  :class="'column columnrowcolumnflow0353 '"   id="columnrowcolumnflow0353" ref="columnrowcolumnflow0353" :_flex = "'1'" 
  v-bind:style="{flex : '1',justifyContent : 'space-around',}">
 <div  :class="'text textcolumnrowcolumnflow03531 '"   id="textcolumnrowcolumnflow03531" ref="textcolumnrowcolumnflow03531"  v-bind:style="{color : '#444444',}">
<div class="text-content" :style = "{}">{{ redactorName }}</div>

   </div>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'ArticleBox',

    components: {},
	methods: {
	
		getInstructor: function() { 		
	if(this.attributes.redactor){	

		let config = {	

		  filters: {

		    id: {

		      $eq: this.attributes.redactor.data.id,

		    },

		  },

		}	

			

		Request.getTableArgs('users', config)

		.then((res) => {

			this.redactor = res.data[0]

		})

	}

}
,
	},
	mounted(){
			
	this.getInstructor()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		attributes: function() { 		
	return this.article.attributes

}
,
		title: function() { 		
	return this.attributes.title

}
,
		description: function() { 		
	if(this.attributes.description.length > 300){

		return this.attributes.description.slice(0, 300) + '...'

	}

	return this.attributes.description

}
,
		redactorName: function() { 		
	if(this.redactor){

		return this.redactor.username

	}

	return "..."

}
,
		redactorImage: function() { 		
	let item 

	if(this.redactor){	

		item = this.redactor.image;

	}

	return Main.getImgUrl(item, 'small');

}
,
		date: function() { 		
	let date = new Date(this.attributes.publishedAt)

	let lang = this.$store.state.selectedLang

	return new Intl.DateTimeFormat(lang, { dateStyle: 'full' }).format(date);

}
,
		image: function() { 		
	let item 

	if(this.attributes.image){	

		item = this.attributes.image.data.attributes;

	}

	return Main.getImgUrl(item, 'large');

}
,
		tags: function() { 		
	return (this.attributes.tags?

		this.attributes.tags.data.map(t => t.attributes)

		: []

	)

}
,
		slug: function() { 		
	return this.attributes.slug

}
,
		link: function() { 		
	return '/blog/' + this.slug

}
,
	},
	data(){
	return {
		
		redactor:  null		,
		}
	},
	props: {
	
		article: {					required: false,			default: () =>  ({}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ArticleBox{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.flow.flow0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
justify-content:space-between ; background-color:transparent ; 

.item.itemflow05{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:100%!important ; 
height:200px!important ; 


	.networkimage.imageflow011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	border-radius:8px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.routerlink.routerlinkitemflow053{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	

	
}


}

.column.columnflow03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



height:auto!important ; 
flex:1 !important; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:15px ; 
justify-content:space-between ; background-color: transparent ; 
padding-top:10px ; 
padding-left:0px ; 

	.column.columncolumnflow039{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:8px ; background-color: transparent ; 

		.flow.rowrowcolumnflow03554123{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;
		gap:5px ; background-color:transparent ; 

			.flow.flowcolumnmainContent1111543{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: wrap;
			flex: initial;
			gap:4px ; background-color:transparent ; 

				.row.tagRow61{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;background-color:transparent ; 
				border-radius:8px ; 
				padding-top:3px ; 
				padding-bottom:3px ; 
				padding-left:3px ; 
				padding-right:3px ; 

					.text.tagText{

					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:auto!important ; 
					
					color:#fff ; 
					font-size:8px ; 
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.text.textrowrowcolumnflow03553{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:18px!important ; 
			
			color:#444444 ; 
			font-size:20px ; 
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

			.text.textrowrowcolumnflow035533{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:18px!important ; 
			
			color:#444444 ; 
			font-size:12px ; 
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.textcolumnflow0312{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#444444 ; 
		line-height:26px ; 
		font-size:25px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

			.routerlink.routerlinkitemflow0531{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			

			
}

		
}

		.text.textcolumnflow03123{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		max-height: auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#646567 ; 
		line-height:18px ; 
		font-size:15px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnflow035{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.networkimage.networkimagerowcolumnflow0351{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:30px!important ; 
		height:30px!important ; 
		
		display: inherit;

		flex: unset;
		border-radius:29px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

		.column.columnrowcolumnflow0353{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		flex:1 !important; 
		height: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		justify-content:space-around ; background-color: transparent ; 

			.text.textcolumnrowcolumnflow03531{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:25px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#444444 ; 
			font-size:15px ; 
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.flow.flow0{


position:relative !important; 









.item.itemflow05{


position:relative !important; 






	.networkimage.imageflow011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.routerlink.routerlinkitemflow053{

	
	position:absolute !important; 
	
	
	
	
	

	
}


}

.column.columnflow03{


position:relative !important; 









	.column.columncolumnflow039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.rowrowcolumnflow03554123{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnmainContent1111543{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.tagRow61{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.tagText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.text.textrowrowcolumnflow03553{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textrowrowcolumnflow035533{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.textcolumnflow0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

			.routerlink.routerlinkitemflow0531{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.text.textcolumnflow03123{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnflow035{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimagerowcolumnflow0351{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnrowcolumnflow0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnrowcolumnflow03531{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.flow.flow0{


position:relative !important; 









.item.itemflow05{


position:relative !important; 






	.networkimage.imageflow011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.routerlink.routerlinkitemflow053{

	
	position:absolute !important; 
	
	
	
	
	

	
}


}

.column.columnflow03{


position:relative !important; 









	.column.columncolumnflow039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.rowrowcolumnflow03554123{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnmainContent1111543{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.tagRow61{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.tagText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.text.textrowrowcolumnflow03553{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textrowrowcolumnflow035533{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.textcolumnflow0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

			.routerlink.routerlinkitemflow0531{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.text.textcolumnflow03123{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnflow035{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimagerowcolumnflow0351{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnrowcolumnflow0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnrowcolumnflow03531{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.flow.flow0{


position:relative !important; 









.item.itemflow05{


position:relative !important; 




width:60%!important ; 
height:300px!important ; 


	.networkimage.imageflow011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.routerlink.routerlinkitemflow053{

	
	position:absolute !important; 
	
	
	
	
	

	
}


}

.column.columnflow03{


position:relative !important; 




height:300px!important ; 




padding-top:0px ; 
padding-left:20px ; 

	.column.columncolumnflow039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.rowrowcolumnflow03554123{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnmainContent1111543{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.tagRow61{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.tagText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.text.textrowrowcolumnflow03553{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textrowrowcolumnflow035533{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.textcolumnflow0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

			.routerlink.routerlinkitemflow0531{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.text.textcolumnflow03123{

		
		position:relative !important; 
		
		
		
		
		max-height: 100px!important ; 
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnflow035{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimagerowcolumnflow0351{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnrowcolumnflow0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnrowcolumnflow03531{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.flow.flow0{


position:relative !important; 









.item.itemflow05{


position:relative !important; 






	.networkimage.imageflow011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.routerlink.routerlinkitemflow053{

	
	position:absolute !important; 
	
	
	
	
	

	
}


}

.column.columnflow03{


position:relative !important; 









	.column.columncolumnflow039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.rowrowcolumnflow03554123{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnmainContent1111543{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.tagRow61{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.tagText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.text.textrowrowcolumnflow03553{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textrowrowcolumnflow035533{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.textcolumnflow0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

			.routerlink.routerlinkitemflow0531{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.text.textcolumnflow03123{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnflow035{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimagerowcolumnflow0351{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnrowcolumnflow0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnrowcolumnflow03531{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.flow.flow0{


position:relative !important; 









.item.itemflow05{


position:relative !important; 






	.networkimage.imageflow011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.routerlink.routerlinkitemflow053{

	
	position:absolute !important; 
	
	
	
	
	

	
}


}

.column.columnflow03{


position:relative !important; 









	.column.columncolumnflow039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.flow.rowrowcolumnflow03554123{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.flow.flowcolumnmainContent1111543{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.row.tagRow61{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.tagText{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.text.textrowrowcolumnflow03553{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textrowrowcolumnflow035533{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.textcolumnflow0312{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

			.routerlink.routerlinkitemflow0531{

			
			position:absolute !important; 
			
			
			
			
			

			
}

		
}

		.text.textcolumnflow03123{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnflow035{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.networkimage.networkimagerowcolumnflow0351{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnrowcolumnflow0353{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnrowcolumnflow03531{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}
}
</style>
