<template>
 <div>  
<div id="ResetPassword"> 
         
 <MainLayout :class="'object defaultlayout0 '"   id="defaultlayout0" ref="defaultlayout0"  v-bind:style="{}">
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedefaultlayout01 '"   id="slottemplatedefaultlayout01" ref="slottemplatedefaultlayout01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedefaultlayout015 '"   id="columnslottemplatedefaultlayout015" ref="columnslottemplatedefaultlayout015"  v-bind:style="{backgroundColor : (mqVal({md:'#f3f2ef'}, $mq)),}">
 <ContentWrapper :class="'object contentWrapper2221 '"   id="contentWrapper2221" ref="contentWrapper2221"  v-bind:style="{}" :paddingTop = "56" 
 :paddingBottom = "56" 
 :paddingLeft = "16" 
 :paddingRight = "16" 
 :bgColor = "'#f0ebe8'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <div  :class="'column columnslottemplatecontentWrapper2213 '"   id="columnslottemplatecontentWrapper2213" ref="columnslottemplatecontentWrapper2213"  v-bind:style="{}">
 <div  :class="'column formCol '"   id="formCol" ref="formCol" v-if="(!reseted)"  v-bind:style="{}">
 <div  :class="'text textcolumnslottemplatecontentWrapper22111 '"   id="textcolumnslottemplatecontentWrapper22111" ref="textcolumnslottemplatecontentWrapper22111"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('LogIn.enterNewPass') }}</div>

   </div>
 <div  :class="'column columncolumnslottemplatecontentWrapper22113 '"   id="columncolumnslottemplatecontentWrapper22113" ref="columncolumnslottemplatecontentWrapper22113"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'column columncolumncolumnslottemplatecontentWrapper2211315 '"   id="columncolumncolumnslottemplatecontentWrapper2211315" ref="columncolumncolumnslottemplatecontentWrapper2211315"  v-bind:style="{}">
 <v-text-field  :label = "$t('Signup.pass')" 
 :append-icon = "passIcon" 
 :dense = "true" 
 :type = "(showPass? 'text' : 'password')" 
 outlined :class="'textfield textfieldcolumncolumnslottemplatecontentWrapper22113123 '"   id="textfieldcolumncolumnslottemplatecontentWrapper22113123" ref="textfieldcolumncolumnslottemplatecontentWrapper22113123" v-model="pass"  v-bind:style="{color : primaryColor,}" @click:append="toggelPass"></v-text-field>
 <v-text-field  :label = "$t('Signup.pass')" 
 :append-icon = "passIcon" 
 :dense = "true" 
 :type = "(showPass? 'text' : 'password')" 
 outlined :class="'textfield textfieldcolumncolumnslottemplatecontentWrapper221131264 '"   id="textfieldcolumncolumnslottemplatecontentWrapper221131264" ref="textfieldcolumncolumnslottemplatecontentWrapper221131264" v-model="pass2"  v-bind:style="{color : primaryColor,}" @click:append="toggelPass"></v-text-field>  </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :rounded = "true" 
 :dark = "true" 
 @click="reset" :class="'button buttoncolumncolgridslottemplatecontentWrapper1111173 '"   id="buttoncolumncolgridslottemplatecontentWrapper1111173" ref="buttoncolumncolgridslottemplatecontentWrapper1111173"  v-bind:style="{}"><span>{{ $t('LogIn.reset') }}</span>
</v-btn>
 <div  :class="'text errorMessage6 '"   id="errorMessage6" ref="errorMessage6" v-if="errorVisible"  v-bind:style="{color : '#ff1a05',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>  </div>  </div>  </div></div></template>
</ContentWrapper>  </div></div></template>
</MainLayout>
 
      </div>
 </div> 
</template>
<script>
import MainLayout from '../../At/Layouts/MainLayout'
import ContentWrapper from '../../At/Utils/ContentWrapper'

import * as Request from '../../Js/requests'
import * as Main from '../../Js/main'
export default {
	name: 'ResetPassword',

    components: {MainLayout,ContentWrapper,},
	methods: {
	
		toggelPass: function() { 		
	this.showPass = !this.showPass

}
,
		reset: function() { 		
	// check pass

	if(this.pass == null){

		this.showError([this.$t('Signup.enterPass')])		

		return

	}	

	if(this.pass2 == null){

		this.showError([this.$t('Signup.enterPassConf')])		

		return

	}	

	if(this.pass != this.pass2){

		this.showError([this.$t('Signup.samePass')])		

		return

	}	

	

	Request.resetPass(this.pass, this.pass2, this.code)

	.then(res => {

		if(res.data.error){

			this.showError(res.data.error.message)

		} else {	

			this.$awn.success(this.$t('LogIn.passReset'))

			setTimeout(() => { this.$router.push('/login') }, 5000);

		}

	})

	.catch(error => {

	   const messages = error.response.data.message[0].messages;

	

	   const list = [];

	   messages.map((message,i) => {

	       let item = "";

	       if (i === 0) item += `<ul>`;

	

	       item += `<li>${message.id}</li>`;

	

	       if (i === messages.length - 1) item += `</ul>`

	       list.push(item);

	   });

	

	   this.showError(list);

	})

}
,
		showError: function(messages) { 		
	this.error = ""

	

	for(let mes in messages){

		this.error += mes

	}

	this.errorVisible = true	

	this.$awn.alert(this.error)

}
,
	},
	mounted(){
			
	if(this.$store.state.user){

		this.$router.push('/')

	}

	},
	metaInfo(){
			
	return {

		title: this.companyName + " | Rest password",

		meta: [		

	   { name: 'description', content: this.$t('Signup.description')},

	

	   { property: 'og:title', content: this.companyName + " | Rest password"},

	   { property: 'og:description', content: this.$t('Signup.description')},

	   { property: 'og:type', content: 'website'},

	   { property: 'og:url', content: this.$store.state.webUrl + '/reset-password'},

	

	   { name: 'twitter:title', content: this.companyName + " | Rest password"},

	   { name: 'twitter:description', content: this.$t('Signup.description')},

	   { name: 'twitter:card', content: 'website'},

	   { name: 'twitter:url', content: this.$store.state.webUrl + '/reset-password'}

	  ]

	}

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		passIcon: function(){		
	if(this.showPass){

		return 'ion-ios-eye'

	} else {

		return 'ion-ios-eye-off'

	}

}
,
		code: function() { 		
	return this.$router.history.current.query.code

}
,
		companyName: function() { 		
	return this.$store.state.companyName

}
,
	},
	data(){
	return {
		
		pass:  null		,
		pass2:  null		,
		errorVisible:  false		,
		error:  "..."		,
		showPass:  false		,
		reseted:  false		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#ResetPassword{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.defaultlayout0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2213{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:10px ; background-color: transparent ; 

					.column.formCol{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:15px ; background-color: transparent ; 

						.text.textcolumnslottemplatecontentWrapper22111{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						width: calc(100% - (0px + 0px)) !important;
						
						font-size:30px ; 
						text-align:center;
						display: flex;
						.text-content{
						width: 100%;
						font-weight: bold;
						}

						
}

						.column.columncolumnslottemplatecontentWrapper22113{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
						
						left: calc(50% + 0px) ; 
						
						width:100%!important ; 
						height:auto!important ; 
						
						display: flex;
						flex-wrap: nowrap;
						flex: initial;
						flex-direction: column;
						gap:10px ; 
						background-color:#fff ; 
						border-radius:10px ; 
						padding-top:24px ; 
						padding-bottom:24px ; 
						padding-left:24px ; 
						padding-right:24px ; 

							.column.columncolumncolumnslottemplatecontentWrapper2211315{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							display: flex;
							flex-wrap: nowrap;
							flex: initial;
							flex-direction: column;
							gap:0px ; background-color: transparent ; 

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								width: calc(100% - (0px + 0px)) !important;
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper221131264{

								
								position:relative !important; 
								margin: unset;
								padding: unset;
								transform-origin: top left;
								
								
								margin-left: 0px ; 
								
								margin-right: 0px ; 
								width: calc(100% - (0px + 0px)) !important;
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

							
}

							.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

							height: auto;
							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:48px!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							text-transform:unset ; 
							min-width: auto;
							padding-left: 16px;
							padding-right: 16px;

							i.v-icon{
							}
							}

							.text.errorMessage6{

							
							position:relative !important; 
							margin: unset;
							padding: unset;
							transform-origin: top left;
							
							
							margin-left: 0px ; 
							
							margin-right: 0px ; 
							height:auto!important ; 
							width: calc(100% - (0px + 0px)) !important;
							
							color:#ff1a05 ; 
							line-height:10px ; 
							font-size:10px ; 
							text-align:center;
							display: flex;
							.text-content{
							width: 100%;
							font-weight: bold;text-decoration: underline
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.formCol{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textcolumnslottemplatecontentWrapper22111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.column.columncolumnslottemplatecontentWrapper22113{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.column.columncolumncolumnslottemplatecontentWrapper2211315{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper221131264{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

							
}

							.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

							height: auto;
							
							position:relative !important; 
							
							
							
							
							
							text-transform:unset ; 
							min-width: auto;
							i.v-icon{
							}
							}

							.text.errorMessage6{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.formCol{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textcolumnslottemplatecontentWrapper22111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.column.columncolumnslottemplatecontentWrapper22113{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.column.columncolumncolumnslottemplatecontentWrapper2211315{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper221131264{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

							
}

							.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

							height: auto;
							
							position:relative !important; 
							
							
							
							
							
							text-transform:unset ; 
							min-width: auto;
							i.v-icon{
							}
							}

							.text.errorMessage6{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	
	background-color:#f3f2ef ; 

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.formCol{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textcolumnslottemplatecontentWrapper22111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.column.columncolumnslottemplatecontentWrapper22113{

						
						position:relative !important; 
						
						
						
						
						width:400px!important ; 
						
						
						
						

							.column.columncolumncolumnslottemplatecontentWrapper2211315{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper221131264{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

							
}

							.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

							height: auto;
							
							position:relative !important; 
							
							
							
							
							
							text-transform:unset ; 
							min-width: auto;
							i.v-icon{
							}
							}

							.text.errorMessage6{

							
							position:relative !important; 
							
							
							
							
							
							line-height:15px ; 
							font-size:15px ; 
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.formCol{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textcolumnslottemplatecontentWrapper22111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.column.columncolumnslottemplatecontentWrapper22113{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.column.columncolumncolumnslottemplatecontentWrapper2211315{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper221131264{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

							
}

							.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

							height: auto;
							
							position:relative !important; 
							
							
							
							
							
							text-transform:unset ; 
							min-width: auto;
							i.v-icon{
							}
							}

							.text.errorMessage6{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.defaultlayout0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedefaultlayout01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedefaultlayout015{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.contentWrapper2221{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.slottemplatecontentWrapper221{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.column.columnslottemplatecontentWrapper2213{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.column.formCol{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.text.textcolumnslottemplatecontentWrapper22111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.column.columncolumnslottemplatecontentWrapper22113{

						
						position:relative !important; 
						
						
						
						
						
						
						
						

							.column.columncolumncolumnslottemplatecontentWrapper2211315{

							
							position:relative !important; 
							
							
							
							
							
							
							
							

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper22113123{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

								.textfield.textfieldcolumncolumnslottemplatecontentWrapper221131264{

								
								position:relative !important; 
								
								
								
								
								
								::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
								
::v-deep &.v-input{
									flex-grow: 0;
input{

}
								}
								}

							
}

							.button.buttoncolumncolgridslottemplatecontentWrapper1111173{

							height: auto;
							
							position:relative !important; 
							
							
							
							
							
							text-transform:unset ; 
							min-width: auto;
							i.v-icon{
							}
							}

							.text.errorMessage6{

							
							position:relative !important; 
							
							
							
							
							
							.text-content{
							width: 100%;
							}

							
}

						
}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
