<template>
 <div>  
<div id="SwiperCard"> 
         
 <div  :class="'item slide2 '"   id="slide2" ref="slide2" v-on:mouseover="hoverIn"  v-on:mouseleave="hoverOut"   v-bind:style="{}">
 <PreviewModal :class="'object previewmodalcardslide1521 '"   id="previewmodalcardslide1521" ref="previewmodalcardslide1521"  v-bind:style="{zIndex : zIndex,width : (mqVal({default:'100%',sm:getUnit(width + ' !important','px')}, $mq)),height : (mqVal({default:'100%',sm:getUnit(height + ' !important','px')}, $mq)),}" :info = "m_info" 
 :moodleInfo = "m_moodleInfo" 
 :hovered = "hovered" 
 :fromMoodle = "fromMoodle" 
 :baseUrl = "baseUrl" 
>
</PreviewModal>  </div>
 
      </div>
 </div> 
</template>
<script>
import PreviewModal from '../../At/Components/PreviewModal'

import * as Main from '../../Js/main'
export default {
	name: 'SwiperCard',

    components: {PreviewModal,},
	methods: {
	
		showDetail: function() { 		
	this.$router.push(this.link)

}
,
		hoverIn: function() { 		
	this.hovered = true

}
,
		hoverOut: function() { 		
	this.hovered = false

}
,
	},
	computed: {
	
		m_info: function() { 		
	if(this.fromMoodle){

		return null

	}

	return this.info

}
,
		m_moodleInfo: function() { 		
	if(this.fromMoodle){

		return this.moodleInfo

	}

	return null

}
,
		link: function() { 		
	return '/learn/' + this.info.slug

}
,
		width: function() { 		
	return this.hovered? "130%" : "100%"

}
,
		height: function() { 		
	return this.hovered? "145%" : "100%"

}
,
		zIndex: function() { 		
	return this.hovered? 20 : 1

}
,
	},
	data(){
	return {
		
		hovered:  false		,
		}
	},
	props: {
	
		info: {					required: false,			default: () =>  ({}),		},
		moodleInfo: {					required: false,			default: () =>  ({}),		},
		fromMoodle: {			type: Boolean,			required: false,			default: () =>  false,		},
		baseUrl: {					required: false,			default: () =>  "",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SwiperCard{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.item.slide2{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.object.previewmodalcardslide1521{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:100%!important ; 
height:100%!important ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.slide2{


position:absolute !important; 






.object.previewmodalcardslide1521{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.slide2{


position:absolute !important; 






.object.previewmodalcardslide1521{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.slide2{


position:absolute !important; 






.object.previewmodalcardslide1521{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.slide2{


position:absolute !important; 






.object.previewmodalcardslide1521{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.slide2{


position:absolute !important; 






.object.previewmodalcardslide1521{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
