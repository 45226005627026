<template>
 <div>  
<div id="ProfileModal"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'column columncolumn01 ' + `elevation-${2}`"   id="columncolumn01" ref="columncolumn01" :elevation = "2" 
  v-bind:style="{backgroundColor : '#fff',}">
 <router-link  :class="'routerlink linkcolumncolumn012 '"   id="linkcolumncolumn012" ref="linkcolumncolumn012"  v-bind:style="{}" :to = "'profile'" 
>
 <div  :class="'flow flowcolumncolumn011 '"   id="flowcolumncolumn011" ref="flowcolumncolumn011"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageflowcolumncolumn0111 '"   id="networkimageflowcolumncolumn0111" ref="networkimageflowcolumncolumn0111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "profileImage" 
>  </v-img>
 <div  :class="'column columnflowcolumncolumn0113 '"   id="columnflowcolumncolumn0113" ref="columnflowcolumncolumn0113" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'text textcolumnflowcolumncolumn01131 '"   id="textcolumnflowcolumncolumn01131" ref="textcolumnflowcolumncolumn01131"  v-bind:style="{color : '#000',}">
<div class="text-content" :style = "{}">{{ userName }}</div>

   </div>
 <div  :class="'text textcolumnflowcolumncolumn011312 '"   id="textcolumnflowcolumncolumn011312" ref="textcolumnflowcolumncolumn011312" v-if="userProfileDescription"  v-bind:style="{color : '#000',}">
<div class="text-content" :style = "{}">{{ userProfileDescription }}</div>

   </div>  </div>  </div>  </router-link>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :outlined = "true" 
 :rounded = "true" 
 :class="'button buttoncolumncolumn013 '"   id="buttoncolumncolumn013" ref="buttoncolumncolumn013" :to = "'/profile'" 
  v-bind:style="{}"><span>{{ $t('Modal.seeProfile') }}</span>
</v-btn>  </div>
 <div  :class="'column columncolumn03 '"   id="columncolumn03" ref="columncolumn03"  v-bind:style="{}">
 <GroupItem :class="'object groupitemcolumncolumn031 '"   id="groupitemcolumncolumn031" ref="groupitemcolumncolumn031"  v-bind:style="{}" :title = "$t('Modal.gerer')" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplategroupitemcolumncolumn0311 '"   id="slottemplategroupitemcolumncolumn0311" ref="slottemplategroupitemcolumncolumn0311"  v-bind:style="{}">
 <div  :class="'column columnslottemplategroupitemcolumncolumn03111 '"   id="columnslottemplategroupitemcolumncolumn03111" ref="columnslottemplategroupitemcolumncolumn03111"  v-bind:style="{}">
 <AwareLink :class="'object awarelinkcolumnslottemplategroupitemcolumncolumn031111 '"   id="awarelinkcolumnslottemplategroupitemcolumncolumn031111" ref="awarelinkcolumnslottemplategroupitemcolumncolumn031111"  v-bind:style="{}" :title = "$t('Modal.panelAdmin')" 
 :subTitle = "$t('Modal.manageService')" 
 :link = "'/admin'" 
>
</AwareLink>  </div></div></template>
</GroupItem>  </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 @click="logout" :class="'button buttoncolumncolumn0133 '"   id="buttoncolumncolumn0133" ref="buttoncolumncolumn0133"  v-bind:style="{}"><span>{{ $t('Modal.disconnection') }}</span>
</v-btn>  </div>
 
      </div>
 </div> 
</template>
<script>
import GroupItem from '../../../At/Utils/GroupItem'
import AwareLink from '../../../At/Utils/AwareLink'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
export default {
	name: 'ProfileModal',

    components: {GroupItem,AwareLink,},
	methods: {
	
		logout: function() { 		
	Request.logout()

}
,
	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		user: function() { 		
	return this.$store.state.user

}
,
		userName: function() { 		
	return this.user.username

}
,
		userProfile: function() { 		
	return this.user.profile

}
,
		userProfileDescription: function() { 		
	if(this.userProfile){

		return this.userProfile.description

	}

	return null

}
,
		profileImage: function() { 		
	if(this.user){

		if(this.user.image){

			let item = this.user.image;

			return Main.getImgUrl(item, 'small');

		}

		return Main.getRandomAvatar(this.user.username)

	}

	return null

}
,
	},
} 
</script>

<style lang = "scss" scoped>


 div#ProfileModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:15px ; background-color: transparent ; 

.column.columncolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; 
background-color:#fff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#00000014 ; 
padding-top:10px ; 
padding-bottom:10px ; 
padding-left:10px ; 
padding-right:10px ; 

	.routerlink.linkcolumncolumn012{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	

		.flow.flowcolumncolumn011{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;
		gap:8px ; background-color:transparent ; 

			.networkimage.networkimageflowcolumncolumn0111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:56px!important ; 
			height:56px!important ; 
			
			display: inherit;

			flex: unset;
			border-radius:28px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

			.column.columnflowcolumncolumn0113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			flex:1 !important; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.text.textcolumnflowcolumncolumn01131{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#000 ; 
				font-size:20px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

				.text.textcolumnflowcolumncolumn011312{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#000 ; 
				font-size:18px ; 
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:24px!important ; 
	to:/profile !important; 
	width: calc(100% - (0px + 0px)) !important;
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}


}

.column.columncolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:4px ; background-color: transparent ; 

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.button.buttoncolumncolumn0133{

height: auto;

position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:40px!important ; 
width: calc(100% - (0px + 0px)) !important;

text-transform:unset ; 
min-width: auto;
padding-left: 16px;
padding-right: 16px;

i.v-icon{
}
}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.routerlink.linkcolumncolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.flow.flowcolumncolumn011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimageflowcolumncolumn0111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columnflowcolumncolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnflowcolumncolumn01131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnflowcolumncolumn011312{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.button.buttoncolumncolumn0133{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.routerlink.linkcolumncolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.flow.flowcolumncolumn011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimageflowcolumncolumn0111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columnflowcolumncolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnflowcolumncolumn01131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnflowcolumncolumn011312{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.button.buttoncolumncolumn0133{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.routerlink.linkcolumncolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.flow.flowcolumncolumn011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimageflowcolumncolumn0111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columnflowcolumncolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnflowcolumncolumn01131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnflowcolumncolumn011312{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.button.buttoncolumncolumn0133{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.routerlink.linkcolumncolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.flow.flowcolumncolumn011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimageflowcolumncolumn0111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columnflowcolumncolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnflowcolumncolumn01131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnflowcolumncolumn011312{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.button.buttoncolumncolumn0133{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.column.columncolumn01{


position:relative !important; 









	.routerlink.linkcolumncolumn012{

	
	position:relative !important; 
	
	
	
	
	

		.flow.flowcolumncolumn011{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimageflowcolumncolumn0111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columnflowcolumncolumn0113{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnflowcolumncolumn01131{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnflowcolumncolumn011312{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.button.buttoncolumncolumn013{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.column.columncolumn03{


position:relative !important; 









	.object.groupitemcolumncolumn031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

		.slottemplate.slottemplategroupitemcolumncolumn0311{

		
		position:relative !important; 
		
		
		
		
		
		left: 0;
		top:0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;

			.column.columnslottemplategroupitemcolumncolumn03111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.awarelinkcolumnslottemplategroupitemcolumncolumn031111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.button.buttoncolumncolumn0133{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}
}
</style>
