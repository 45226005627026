<template>
 <div>  
<div id="AdminMediaPicker"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'text textcolumn01 '"   id="textcolumn01" ref="textcolumn01"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <div  :class="'column columncolumn03 '"   id="columncolumn03" ref="columncolumn03" :_justifyContent = "'center'" 
  v-bind:style="{ opacity: disabled ? 0.5 : 1 ,  filter: 'alpha(opacity=disabled ? 0.5 : 1*100)' , justifyContent : 'center',backgroundColor : '#f6f6f9',}">
 <div  :class="'column columncolumncolumn0311 '"   id="columncolumncolumn0311" ref="columncolumncolumn0311" v-if="!filePicked"  v-bind:style="{}">
 <div  :class="'column columncolumncolumn0331 '"   id="columncolumncolumn0331" ref="columncolumncolumn0331"  v-bind:style="{}">
 <div  :class="'icon iconcolumncolumn0311 '"   id="iconcolumncolumn0311" ref="iconcolumncolumn0311"  v-bind:style="{color : primaryColor,fontSize : getUnit(30,'px'),}"><i  :class="icon"></i></div>  </div>
 <div  :class="'text textcolumncolumn0332 '"   id="textcolumncolumn0332" ref="textcolumncolumn0332"  v-bind:style="{color : '#80736e',}">
<div class="text-content" :style = "{}">{{ text }}</div>

   </div>  </div>
 <div  :class="'mousearea mouseareacolumncolumn035 '"   id="mouseareacolumncolumn035" ref="mouseareacolumncolumn035" v-if="!filePicked" v-ripple = "true"  v-bind:style="{}" @click="pickFile"></div>
 <div  :class="'column columncolumncolumn039 '"   id="columncolumncolumn039" ref="columncolumncolumn039"  v-bind:style="{}">
 <div  :class="'column columncolumncolumncolumn0395 '"   id="columncolumncolumncolumn0395" ref="columncolumncolumncolumn0395" v-if="m_file"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagecolumncolumn03711 '"   id="networkimagecolumncolumn03711" ref="networkimagecolumncolumn03711" v-if="loaded && (type == 'image')"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "m_file" 
>  </v-img>
<div class="artefactVid"  :class="'networkvideo networkvideocolumncolumncolumncolumn03955 ' + ' ' + 'elevation-1'"   id="networkvideocolumncolumncolumncolumn03955" ref="networkvideocolumncolumncolumncolumn03955" v-if="loaded && (type == 'video')"  v-bind:style="{}"> <video width="100%" height="100%" >
<source  v-for='(itemVideoUrl,index) in [m_file]' :key="index" :src="itemVideoUrl" >
</video> </div>
 <div  :class="'row rownetworkimagecolumncolumn0371132 '"   id="rownetworkimagecolumncolumn0371132" ref="rownetworkimagecolumncolumn0371132"  v-bind:style="{}">
 <v-btn tag="div" 
 :onlyIcon = "true" 
 @click="copyToClip" :class="'button buttonrownetworkimagecolumncolumn037111 ' + `elevation-${0}`"   id="buttonrownetworkimagecolumncolumn037111" ref="buttonrownetworkimagecolumncolumn037111" v-if="c_file" :elevation = "0" 
  v-bind:style="{}"><span v-if = "(!false) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-link' !== undefined" >{{ 'ion-ios-link' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :onlyIcon = "true" 
 @click="deleteItem" :class="'button buttonrownetworkimagecolumncolumn0371112 ' + `elevation-${0}`"   id="buttonrownetworkimagecolumncolumn0371112" ref="buttonrownetworkimagecolumncolumn0371112" :elevation = "0" 
  v-bind:style="{}"><span v-if = "(!false) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-trash' !== undefined" >{{ 'ion-md-trash' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :onlyIcon = "true" 
 @click="pickFile" :class="'button buttonrownetworkimagecolumncolumn0371113 ' + `elevation-${0}`"   id="buttonrownetworkimagecolumncolumn0371113" ref="buttonrownetworkimagecolumncolumn0371113" :elevation = "0" 
  v-bind:style="{}"><span v-if = "(!false) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-create' !== undefined" >{{ 'ion-md-create' }}
</v-icon></v-btn>  </div>  </div>
 <div  :class="'text nameText2 '"   id="nameText2" ref="nameText2" v-if="fileName"  v-bind:style="{color : '#80736e',}">
<div class="text-content" :style = "{}">{{ fileName }}</div>

   </div>  </div>
 <div  :class="'mousearea mouseareacolumncolumn037 '"   id="mouseareacolumncolumn037" ref="mouseareacolumncolumn037" v-if="disabled"  v-bind:style="{}"></div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'

import copy from 'copy-to-clipboard';
export default {
	name: 'AdminMediaPicker',

    components: {},
	methods: {
	
		copyToClip: function() { 		
	if(this.c_file){

		copy(this.c_file)

	}

}
,
		deleteItem: function() { 		
	if(this.uploadedID){

		this.deleteFile(this.uploadedID)

	} else {

		this.file = null

		this.fileBlog = null

	}

	this.loaded = false

}
,
		uploadFile: function(file) { 		
	Request.uploadFile(file)

	.then((res) => {

		this.updateItem(res.data[0].id)

		this.uploadedID = res.data[0].id

  })

  .catch((error) => {

 		this.$awn.alert(error.response.data.error.message)

	});

}
,
		deleteFile: function(fileID, callBack) { 		
	Request.deleteFile(fileID)

	.then((res) => {

		if(callBack) {

			callBack()

		} else {	

			this.file = null

			this.$awn.success(this.deleteSuccessMessage)

			this.updateItem(null)

		}

  })

  .catch((error) => {

 		this.$awn.alert(error.response.data.error.message)

	});

}
,
		pickFile: function(){		
	Main.selectFile(this.type + '/*', false)

	.then((file) => {			

		this.fileBlog = file;

		

			

		if(this.entryID){

			if(this.uploadedID){	

				this.deleteFile(this.uploadedID, _ => this.uploadFile(file))

			} else {

				this.uploadFile(file)

			}

		} else {

			if(this.uploadedID){	

				this.deleteFile(this.uploadedID, _ => this.updateItem(file))

			} else {

				this.updateItem(file)

			}

		}

		

  	const reader = new FileReader();

  	reader.addEventListener('load', (event) => {

    	this.file = event.target.result;

  	});

 		reader.readAsDataURL(file); 

	});

}
,
	},
	computed: {
	
		attributes: function() { 		
	return this.item?.attributes

}
,
		filePicked: function() { 		
	return this.file != null || this.item != null

}
,
		m_file: function() { 		
	if(this.file) return this.file

	return this.c_file

}
,
		image: function() { 		
	if(this.attributes){

		return Main.getImgUrl(this.attributes, 'small');

	}

	return null

}
,
		video: function() { 		
	return this.attributes?.url

}
,
		c_file: function() { 		
	if(this.type == "image") return this.image

	if(this.type == "video") return this.video

	return false

}
,
		fileName: function() { 		
	if(this.attributes) return this.attributes.name

	return null

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		icon: function() { 		
	if(this.type == "image") return "ion-ios-image"

	if(this.type == "video") return "ion-ios-videocam"

	return "ion-ios-document"

}
,
		text: function() { 		
	if(this.type == "image") return this.$t('Admin.addImage')

	if(this.type == "video") return this.$t('Admin.addVideo')

	return this.$t('Admin.addFile')

}
,
		deleteSuccessMessage: function() { 		
	if(this.type == 'image') return this.$t('Admin.imageDeleted')

	if(this.type == 'video') return this.$t('Admin.videoDeleted')

	return this.$t('Admin.fileDeleted')

}
,
	},
	watch: {
	
		item: function(val) { 		
	if(this.item){ this.uploadedID = this.item.id }

}
,
		file: function(val) { 		
	if(val) this.loaded = true

}
,
	},
	data(){
	return {
		
		file:  null		,
		fileBlog:  null		,
		uploadedID:  null		,
		loaded:  true		,
		}
	},
	props: {
	
		title: {					required: false,			default: () =>  "image",		},
		type: {					required: false,			default: () =>  "image",		},
		item: {					required: false,			default: () =>  null,		},
		entryID: {					required: false,			default: () =>  null,		},
		disabled: {			type: Boolean,			required: false,			default: () =>  false,		},
		updateItem: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AdminMediaPicker{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:8px ; background-color: transparent ; 

.text.textcolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;




font-size:15px ; 
.text-content{
width: 100%;
font-weight: bold;
}


}

.column.columncolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
min-height: 130px!important ; 
justifyContent:center !important; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
background-color:#f6f6f9 ; 
border-radius:8px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#dcdce4 ; 

	.column.columncolumncolumn0311{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:5px ; background-color: transparent ; 
	padding-top:8px ; 
	padding-bottom:8px ; 
	padding-left:8px ; 
	padding-right:8px ; 

		.column.columncolumncolumn0331{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.icon.iconcolumncolumn0311{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			width:30px!important ; 
			height:30px!important ; 
			
			font-size:30px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncolumn0332{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#80736e ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn035{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}

	.column.columncolumncolumn039{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:5px ; background-color: transparent ; 
	padding-top:8px ; 
	padding-bottom:8px ; 
	padding-left:8px ; 
	padding-right:8px ; 

		.column.columncolumncolumncolumn0395{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.networkimage.networkimagecolumncolumn03711{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			width:auto!important ; 
			height:100px!important ; 
			max-width: 200px!important ; 
			
			display: inherit;

			flex: unset;
			border-radius:8px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

			
}

			.networkvideo.networkvideocolumncolumncolumncolumn03955{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			width:auto!important ; 
			height:124px!important ; 
			max-width: 90%!important ; 
			max-height: 50%!important ; 
			background-color: black;

			
}

			.row.rownetworkimagecolumncolumn0371132{

			align-self: flex-start;
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			bottom: 5px ; 
			width:auto!important ; 
			height:35px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:5px ; background-color:transparent ; 

				.button.buttonrownetworkimagecolumncolumn037111{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:35px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371112{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:35px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371113{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:35px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.nameText2{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#80736e ; 
		font-size:10px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn037{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	margin-bottom: 0px ; 
	width: calc(100% - (0px + 0px)) !important;
	height: calc(100% - (0px + 0px)) !important;
	
	cursor: not-allowed;
	}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.column.columncolumn03{


position:relative !important; 









	.column.columncolumncolumn0311{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolumn0311{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncolumn0332{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn035{

	
	position:absolute !important; 
	
	
	
	
	
	}

	.column.columncolumncolumn039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumncolumn0395{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimagecolumncolumn03711{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.networkvideo.networkvideocolumncolumncolumncolumn03955{

			
			position:relative !important; 
			
			
			
			
			background-color: black;

			
}

			.row.rownetworkimagecolumncolumn0371132{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrownetworkimagecolumncolumn037111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371113{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.nameText2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn037{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.column.columncolumn03{


position:relative !important; 









	.column.columncolumncolumn0311{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolumn0311{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncolumn0332{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn035{

	
	position:absolute !important; 
	
	
	
	
	
	}

	.column.columncolumncolumn039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumncolumn0395{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimagecolumncolumn03711{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.networkvideo.networkvideocolumncolumncolumncolumn03955{

			
			position:relative !important; 
			
			
			
			
			background-color: black;

			
}

			.row.rownetworkimagecolumncolumn0371132{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrownetworkimagecolumncolumn037111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371113{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.nameText2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn037{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.column.columncolumn03{


position:relative !important; 









	.column.columncolumncolumn0311{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolumn0311{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncolumn0332{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn035{

	
	position:absolute !important; 
	
	
	
	
	
	}

	.column.columncolumncolumn039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumncolumn0395{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimagecolumncolumn03711{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.networkvideo.networkvideocolumncolumncolumncolumn03955{

			
			position:relative !important; 
			
			
			
			
			background-color: black;

			
}

			.row.rownetworkimagecolumncolumn0371132{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrownetworkimagecolumncolumn037111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371113{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.nameText2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn037{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.column.columncolumn03{


position:relative !important; 









	.column.columncolumncolumn0311{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolumn0311{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncolumn0332{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn035{

	
	position:absolute !important; 
	
	
	
	
	
	}

	.column.columncolumncolumn039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumncolumn0395{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimagecolumncolumn03711{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.networkvideo.networkvideocolumncolumncolumncolumn03955{

			
			position:relative !important; 
			
			
			
			
			background-color: black;

			
}

			.row.rownetworkimagecolumncolumn0371132{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrownetworkimagecolumncolumn037111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371113{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.nameText2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn037{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.column.columncolumn03{


position:relative !important; 









	.column.columncolumncolumn0311{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0331{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconcolumncolumn0311{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncolumn0332{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn035{

	
	position:absolute !important; 
	
	
	
	
	
	}

	.column.columncolumncolumn039{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumncolumn0395{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.networkimage.networkimagecolumncolumn03711{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.networkvideo.networkvideocolumncolumncolumncolumn03955{

			
			position:relative !important; 
			
			
			
			
			background-color: black;

			
}

			.row.rownetworkimagecolumncolumn0371132{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrownetworkimagecolumncolumn037111{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371112{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonrownetworkimagecolumncolumn0371113{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.nameText2{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.mousearea.mouseareacolumncolumn037{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}
}
</style>
