<template>
 <div>  
<div id="LDCourseOverviewModule"> 
         
 <div  :class="'row row0 '"   id="row0" ref="row0"  v-bind:style="{}">
 <div  :class="'row linkrow05 '"   id="linkrow05" ref="linkrow05" :_flex = "'1'" 
  v-bind:style="{flex : '1',}">
 <div  :class="'icon iconlinkrow053 '"   id="iconlinkrow053" ref="iconlinkrow053"  v-bind:style="{color : primaryColor,fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-radio-button-off'"></i></div>
 <div  :class="'column columnlinkrow051 '"   id="columnlinkrow051" ref="columnlinkrow051"  v-bind:style="{}">
 <div  :class="'text textcolumnlinkrow0511 '"   id="textcolumnlinkrow0511" ref="textcolumnlinkrow0511" v-if="!editMode"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ name }}</div>

   </div>
 <input  type = "text" :class="'textedit textcolumnlinkrow0511 '"   id="textcolumnlinkrow0511" ref="textcolumnlinkrow0511" v-if="editMode" v-model="m_name"  v-bind:style="{}" >
 <div  :class="'text textcolumnlinkrow05112 '"   id="textcolumnlinkrow05112" ref="textcolumnlinkrow05112" v-if="!editMode"  v-bind:style="{color : '#787878',}">
<div class="text-content" :style = "{}">{{ duration }}</div>

   </div>
 <div  :class="'row rowcolumnlinkrow0518 '"   id="rowcolumnlinkrow0518" ref="rowcolumnlinkrow0518" v-if="editMode"  v-bind:style="{}">
 <input  type = "text" :class="'textedit textcolumnlinkrow05112 '"   id="textcolumnlinkrow05112" ref="textcolumnlinkrow05112" v-model="m_duration"  v-bind:style="{color : '#787878',}" >
 <div  :class="'text textcolumnlinkrow051123 '"   id="textcolumnlinkrow051123" ref="textcolumnlinkrow051123"  v-bind:style="{color : '#787878',}">
<div class="text-content" :style = "{}">{{ $t('Admin.seconds') }}</div>

   </div>  </div>  </div>  </div>
 <div  :class="'row rowrowcolumn0253 '"   id="rowrowcolumn0253" ref="rowrowcolumn0253"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :fab = "true" 
 :dark = "true" 
 @click="m_editModule" :class="'button buttonrowcolumn0230 '"   id="buttonrowcolumn0230" ref="buttonrowcolumn0230" v-if="changed"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-save' !== undefined" >{{ 'ion-ios-save' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :color = "'#ed918a'" 
 :fab = "true" 
 :dark = "true" 
 @click="m_deleteModule" :class="'button buttonrowcolumn0231 '"   id="buttonrowcolumn0231" ref="buttonrowcolumn0231" v-if="editMode"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-trash' !== undefined" >{{ 'ion-ios-trash' }}
</v-icon></v-btn>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../../Js/main'
export default {
	name: 'LDCourseOverviewModule',

    components: {},
	methods: {
	
		m_editModule: function() { 		
	this.editModule(this.pIndex, this.index, this.m_name, this.m_duration)

	this.changed = false

}
,
		m_deleteModule: function() { 		
	this.deleteModule(this.pIndex, this.index)

}
,
	},
	mounted(){
			
	this.m_name = this.name

	this.m_duration = this.moduleInfo.duration ?? 0

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		name: function() { 		
	if(this.moduleInfo){

		return this.moduleInfo.name

	}

	return "..." 

}
,
		duration: function() { 		
	let duration = 0

	

	if(this.moduleInfo.duration){

		duration = this.moduleInfo.duration

	}

	return Main.convertHMS(duration)

}
,
	},
	watch: {
	
		m_name: function(val, old) { 		
	if(old){

		this.changed = true

	}

}
,
		m_duration: function(val, old) { 		
	if(old){

		this.changed = true

	}

}
,
	},
	data(){
	return {
		
		changed:  false		,
		m_name:  null		,
		m_duration:  null		,
		}
	},
	props: {
	
		pIndex: {					required: false,			default: () =>  0,		},
		index: {					required: false,			default: () =>  0,		},
		moduleInfo: {					required: false,			default: () =>  null,		},
		editMode: {					required: false,			default: () =>  false,		},
		editModule: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
		deleteModule: {			type: Function,			required: false,			default: () =>  (_ => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LDCourseOverviewModule{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
}



.row.row0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:5px ; background-color:transparent ; 

.row.linkrow05{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



flex:1 !important; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;background-color:transparent ; 

	.icon.iconlinkrow053{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:25px!important ; 
	height:25px!important ; 
	
	font-size:20px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.column.columnlinkrow051{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.text.textcolumnlinkrow0511{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:15px ; 
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textcolumnlinkrow0511{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:15px ; 
		}

		.text.textcolumnlinkrow05112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#787878 ; 
		font-size:12px ; 
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnlinkrow0518{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:4px ; background-color:transparent ; 

			.textedit.textcolumnlinkrow05112{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			color:#787878 ; 
			font-size:12px ; 
			}

			.text.textcolumnlinkrow051123{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#787878 ; 
			font-size:12px ; 
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.row.rowrowcolumn0253{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:auto!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:10px ; background-color:transparent ; 

	.button.buttonrowcolumn0230{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:25px!important ; 
	height:25px!important ; 
	
	font-size:20px ; 
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	font-size:20px ; 
	}
	}

	.button.buttonrowcolumn0231{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:25px!important ; 
	height:25px!important ; 
	
	font-size:20px ; 
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	font-size:20px ; 
	}
	}


}


}


@media (max-width: 600px) {


.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.linkrow05{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconlinkrow053{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.column.columnlinkrow051{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		}

		.text.textcolumnlinkrow05112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnlinkrow0518{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.textedit.textcolumnlinkrow05112{

			
			position:relative !important; 
			
			
			
			
			
			}

			.text.textcolumnlinkrow051123{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.row.rowrowcolumn0253{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowcolumn0230{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0231{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 601px) {


.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.linkrow05{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconlinkrow053{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.column.columnlinkrow051{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		}

		.text.textcolumnlinkrow05112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnlinkrow0518{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.textedit.textcolumnlinkrow05112{

			
			position:relative !important; 
			
			
			
			
			
			}

			.text.textcolumnlinkrow051123{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.row.rowrowcolumn0253{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowcolumn0230{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0231{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 961px) {


.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.linkrow05{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconlinkrow053{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.column.columnlinkrow051{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		}

		.text.textcolumnlinkrow05112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnlinkrow0518{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.textedit.textcolumnlinkrow05112{

			
			position:relative !important; 
			
			
			
			
			
			}

			.text.textcolumnlinkrow051123{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.row.rowrowcolumn0253{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowcolumn0230{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0231{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1265px) {


.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.linkrow05{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconlinkrow053{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.column.columnlinkrow051{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		}

		.text.textcolumnlinkrow05112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnlinkrow0518{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.textedit.textcolumnlinkrow05112{

			
			position:relative !important; 
			
			
			
			
			
			}

			.text.textcolumnlinkrow051123{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.row.rowrowcolumn0253{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowcolumn0230{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0231{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1905px) {


.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.linkrow05{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconlinkrow053{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.column.columnlinkrow051{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.textedit.textcolumnlinkrow0511{

		
		position:relative !important; 
		
		
		
		
		
		}

		.text.textcolumnlinkrow05112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnlinkrow0518{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.textedit.textcolumnlinkrow05112{

			
			position:relative !important; 
			
			
			
			
			
			}

			.text.textcolumnlinkrow051123{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.row.rowrowcolumn0253{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowcolumn0230{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowcolumn0231{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}
}
</style>
