<template>
 <div>  
<div id="ProfileLeftSection"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#fff'" 
 @click="showShare" :class="'button buttoncolumn01 '"   id="buttoncolumn01" ref="buttoncolumn01" :_border = "'solid 1px #d7d7d7'" 
  v-bind:style="{border : 'solid 1px #d7d7d7',}"><v-icon  :style = "{}"  v-if = "'ion-ios-share-alt' !== undefined"  :left = "true" 
>{{ 'ion-ios-share-alt' }}
</v-icon><span>{{ $t('Profile.shareProfile') }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 :class="'button adminBtn '"   id="adminBtn" ref="adminBtn" v-if="!watchMode" :to = "'/admin'" 
 :target = "'_blank'" 
  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-ios-cog' !== undefined"  :left = "true" 
>{{ 'ion-ios-cog' }}
</v-icon><span>{{ $t('Profile.adminPanel') }}</span>
</v-btn>
 <div  :class="'column columncolumn05 '"   id="columncolumn05" ref="columncolumn05"  v-bind:style="{}">
 <div  :class="'text textcolumncolumn051 '"   id="textcolumncolumn051" ref="textcolumncolumn051"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('LearningDetails.relatedWebinars') }}</div>

   </div>
 <div  :class="'column columncolumncolumn055 '"   id="columncolumncolumn055" ref="columncolumncolumn055"  v-bind:style="{}">
 <div  :class="'column columncolumncolumn0551 '"   id="columncolumncolumn0551" ref="columncolumncolumn0551" v-for="(item,index) in webinars" :key="'columncolumncolumn0551' + index"  v-bind:style="{}">
 <HPreviewModal :class="'object hpreviewmodalcolumncolumn0531 '"   id="hpreviewmodalcolumncolumn0531" ref="hpreviewmodalcolumncolumn0531"  v-bind:style="{}" :info = "item" 
 :last = "index == (webinars.length - 1)" 
 :isWebinar = "true" 
 :baseUrl = "'webinars'" 
>
</HPreviewModal>  </div>  </div>  </div>
 <div  :class="'column columncolumn055 '"   id="columncolumn055" ref="columncolumn055"  v-bind:style="{}">
 <div  :class="'text textcolumncolumn051 '"   id="textcolumncolumn051" ref="textcolumncolumn051"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('LearningDetails.relatedCourses') }}</div>

   </div>
 <div  :class="'column columncolumncolumn055 '"   id="columncolumncolumn055" ref="columncolumncolumn055"  v-bind:style="{}">
 <div  :class="'column columncolumncolumn0551 '"   id="columncolumncolumn0551" ref="columncolumncolumn0551" v-for="(item,index) in formations" :key="'columncolumncolumn0551' + index"  v-bind:style="{}">
 <HPreviewModal :class="'object hpreviewmodalcolumncolumn0531 '"   id="hpreviewmodalcolumncolumn0531" ref="hpreviewmodalcolumncolumn0531"  v-bind:style="{}" :info = "item" 
 :last = "index == (formations.length - 1)" 
 :baseUrl = "'learning'" 
>
</HPreviewModal>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import HPreviewModal from '../../../At/Components/HPreviewModal'

import * as Request from '../../../Js/requests'
export default {
	name: 'ProfileLeftSection',

    components: {HPreviewModal,},
	methods: {
	
		getFormations: function() { 		
	Request.getLocalTable('formations')

	.then((res) => {

		this.formations = res.data.data.slice(0, 6)

	})

}
,
		getWebinars: function() { 		
	Request.getLocalTable('webinars')

	.then((res) => {

		this.webinars = res.data.data.slice(0, 6)

	})

}
,
	},
	mounted(){
			
	this.getWebinars()

	this.getFormations()

	},
	computed: {
	
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		formations:  []		,
		webinars:  []		,
		}
	},
	props: {
	
		showShare: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		watchMode: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ProfileLeftSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:18px ; background-color: transparent ; 

.button.buttoncolumn01{

height: auto;

position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:40px!important ; 
border:solid 1px #d7d7d7 !important; 
width: calc(100% - (0px + 0px)) !important;

border-radius:0px ; 
text-transform:unset ; 
min-width: auto;
padding-left: 16px;
padding-right: 16px;

i.v-icon{
}
}

.button.adminBtn{

height: auto;

position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:40px!important ; 
to:/admin !important; 
target:_blank !important; 
width: calc(100% - (0px + 0px)) !important;

border-radius:0px ; 
text-transform:unset ; 
min-width: auto;
padding-left: 16px;
padding-right: 16px;

i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.text.textcolumncolumn051{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	font-size:20px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 
		padding-top:16px ; 
		padding-bottom:16px ; 

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:90px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.column.columncolumn055{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;background-color: transparent ; 

	.text.textcolumncolumn051{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	font-size:20px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 
		padding-top:16px ; 
		padding-bottom:16px ; 

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:90px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.button.adminBtn{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.column.columncolumn055{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.button.adminBtn{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.column.columncolumn055{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.button.adminBtn{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.column.columncolumn055{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.button.adminBtn{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.column.columncolumn055{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.button.buttoncolumn01{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.button.adminBtn{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.column.columncolumn05{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.column.columncolumn055{


position:relative !important; 









	.text.textcolumncolumn051{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columncolumncolumn055{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumncolumn0551{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.object.hpreviewmodalcolumncolumn0531{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}


}
}
}
</style>
