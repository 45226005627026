<template>
 <div>  
<div id="SelectCountryModal"> 
         
 <DialogBoxModal :class="'object dialogboxmodal0 '"   id="dialogboxmodal0" ref="dialogboxmodal0"  v-bind:style="{}" :action = "updateProfile" 
 :title = "$t('Profile.selectCountry')" 
 :hasAction = "true" 
 :fullHeight = "false" 
 :actionName = "$t('Profile.update')" 
 :maxWidth = "520" 
 :closable = "false" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatedialogboxmodal01 '"   id="slottemplatedialogboxmodal01" ref="slottemplatedialogboxmodal01"  v-bind:style="{}">
 <div  :class="'column columnslottemplatedialogboxmodal011 '"   id="columnslottemplatedialogboxmodal011" ref="columnslottemplatedialogboxmodal011"  v-bind:style="{}">
 <div  :class="'text textcolumnslottemplatedialogboxmodal01112 '"   id="textcolumnslottemplatedialogboxmodal01112" ref="textcolumnslottemplatedialogboxmodal01112"  v-bind:style="{color : '#6f6f6f',}">
<div class="text-content" :style = "{}">{{ $t('Profile.selectCountry') }}</div>

   </div>
 <v-select
           :label = "$t('Profile.country')" 
 :prepend-inner-icon = "'ion-md-globe'" 
:items = "countriesList" outlined :class="'select selectcolumncolumncolumnslottemplatecontentWrapper221131591 '"   id="selectcolumncolumncolumnslottemplatecontentWrapper221131591" ref="selectcolumncolumncolumnslottemplatecontentWrapper221131591" v-model="country" :dense = "true" 
 :item-text = "'label'" 
 :item-value = "'value'" 
  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>  </div></div></template>
</DialogBoxModal>
 
      </div>
 </div> 
</template>
<script>
import DialogBoxModal from '../../../At/Sections/Modal/DialogBoxModal'

import * as Main from '../../../Js/main'
import * as Request from '../../../Js/requests'
import * as Countries from 'i18n-iso-countries'
export default {
	name: 'SelectCountryModal',

    components: {DialogBoxModal,},
	methods: {
	
		chargeData: function() { 		
	this.country = this.user.country

}
,
		getUser: function(id) { 		
	Request.getTable('users/' + id)

	.then((res) => {

		this.$store.commit('changeUser', res.data)

	})

}
,
		updateProfile: function() { 		
	let config = {}

	

	if(this.country){ config.country = this.country }

	

	Request.updateUserData(config)

	.then((res) => {

		this.getUser(res.data.id)

		location.reload()

	})

}
,
	},
	mounted(){
			
	this.countries = Countries

	this.countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

	this.countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

	this.countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));



	this.chargeData()

	},
	computed: {
	
		user: function() { 		
	return this.$store.state.user

}
,
		primaryColor: function() { 		
	return this.$store.state.primaryColor

}
,
		selectedLang: function(){ 		
	return this.$store.state.selectedLang

}
,
		countriesList: function() { 			
	if(this.countries == null) return []

	

	const list = this.countries.getNames(this.selectedLang, {select: 'official'})

	return Object.keys(list).map((key) => ({

	    value: key, // FR

	    label: list[key] // ex: France

	}))

}
,
	},
	watch: {
	
		user: function(val) { 		
	this.chargeData()

}
,
	},
	data(){
	return {
		
		country:  null		,
		countries:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#SelectCountryModal{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.dialogboxmodal0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.text.textcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
		color:#6f6f6f ; 
		font-size:12px ; 
		.text-content{
		width: 100%;
		}

		
}

		.select.selectcolumncolumncolumnslottemplatecontentWrapper221131591{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		dense:true !important; 
		item-text:label !important; 
		item-value:value !important; 
		width: calc(100% - (0px + 0px)) !important;
		
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
		}

	
}


}


}


@media (max-width: 600px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectcolumncolumncolumnslottemplatecontentWrapper221131591{

		
		position:relative !important; 
		
		
		
		
		
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectcolumncolumncolumnslottemplatecontentWrapper221131591{

		
		position:relative !important; 
		
		
		
		
		
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectcolumncolumncolumnslottemplatecontentWrapper221131591{

		
		position:relative !important; 
		
		
		
		
		
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectcolumncolumncolumnslottemplatecontentWrapper221131591{

		
		position:relative !important; 
		
		
		
		
		
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.dialogboxmodal0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatedialogboxmodal01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnslottemplatedialogboxmodal011{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumnslottemplatedialogboxmodal01112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectcolumncolumncolumnslottemplatecontentWrapper221131591{

		
		position:relative !important; 
		
		
		
		
		
::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

    ::v-deep &.v-input{
        flex-grow: 0;
        input{

        }
    }
		}

	
}


}


}
}
}
</style>
