<template>
 <div>  
<div id="SwiperRow"> 
         
 <div  :class="'column item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'flow rowitem04 '"   id="rowitem04" ref="rowitem04" v-if="!mini"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textitem03 '"   id="textitem03" ref="textitem03"  v-bind:style="{color : '#161616',}">
<div class="text-content" :style = "{}">{{ title }}</div>

   </div>
 <div  :class="'row rowrowitem043 '"   id="rowrowitem043" ref="rowrowitem043"  v-bind:style="{}">
 <v-btn tag="div" 
 :outlined = "true" 
 :rounded = "true" 
 :class="'button buttonrowrowitem0431 '"   id="buttonrowrowitem0431" ref="buttonrowrowitem0431" v-if="hasMoreBtn" :to = "moreLink" 
  v-bind:style="{}"><span>{{ $t('Home.more') }}</span>
</v-btn>
 <v-btn tag="div" 
 :fab = "true" 
 :outlined = "true" 
 :rounded = "true" 
 @click="previous" :class="'button prevBtn '"   id="prevBtn" ref="prevBtn"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-arrow-back' !== undefined" >{{ 'ion-ios-arrow-back' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :fab = "true" 
 :outlined = "true" 
 :rounded = "true" 
 @click="next" :class="'button nextBtn '"   id="nextBtn" ref="nextBtn"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-arrow-forward' !== undefined" >{{ 'ion-ios-arrow-forward' }}
</v-icon></v-btn>  </div>  </div>
 <Swiper :class="'importedobject swiperContainer '"   id="swiperContainer" ref="swiperContainer" :options = "swiperOptions" 
 :_overflow = "'visible'" 
  v-bind:style="{overflow : 'visible',}">
 <SwiperSlide :class="'importedobject swiperSlide4 '"   id="swiperSlide4" ref="swiperSlide4" v-for="(item,index) in 5" :key="'swiperSlide4' + index" v-if="loading && !mini"  v-bind:style="{}">
 <Skeleton :class="'object swipercardShadow '"   id="swipercardShadow" ref="swipercardShadow"  v-bind:style="{}">
</Skeleton>
</SwiperSlide>
 <SwiperSlide :class="'importedobject swiperSlide45Mini '"   id="swiperSlide45Mini" ref="swiperSlide45Mini" v-for="(item,index) in 5" :key="'swiperSlide45Mini' + index" v-if="loading && mini"  v-bind:style="{}">
 <Skeleton :class="'object swipercardShadow '"   id="swipercardShadow" ref="swipercardShadow"  v-bind:style="{}">
</Skeleton>
</SwiperSlide>
 <SwiperSlide :class="'importedobject swiperSlide '"   id="swiperSlide" ref="swiperSlide" v-for="(item,index) in list" :key="'swiperSlide' + index" v-if="!isOrganisations && !isArticles && !mini"  v-bind:style="{}">
 <SwiperCard :class="'object swipercardswiperSlide1 '"   id="swipercardswiperSlide1" ref="swipercardswiperSlide1"  v-bind:style="{}" :info = "item" 
 :moodleInfo = "item" 
 :fromMoodle = "fromMoodle" 
 :baseUrl = "baseUrl" 
>
</SwiperCard>
</SwiperSlide>
 <SwiperSlide :class="'importedobject swiperSlide5Mini '"   id="swiperSlide5Mini" ref="swiperSlide5Mini" v-for="(item,index) in list" :key="'swiperSlide5Mini' + index" v-if="!isOrganisations && !isArticles && mini"  v-bind:style="{}">
 <MiniPreview :class="'object minipreviewswiperSlide5Mini1 '"   id="minipreviewswiperSlide5Mini1" ref="minipreviewswiperSlide5Mini1"  v-bind:style="{}" :info = "item" 
 :moodleInfo = "item" 
 :fromMoodle = "fromMoodle" 
 :baseUrl = "baseUrl" 
>
</MiniPreview>
</SwiperSlide>
 <SwiperSlide :class="'importedobject swiperSlide3 '"   id="swiperSlide3" ref="swiperSlide3" v-for="(item,index) in list" :key="'swiperSlide3' + index" v-if="isArticles && !mini"  v-bind:style="{}">
 <ArticleBoxMini :class="'object articleboxmainContent112 '"   id="articleboxmainContent112" ref="articleboxmainContent112"  v-bind:style="{}" :article = "item" 
>
</ArticleBoxMini>
</SwiperSlide>
 <SwiperSlide :class="'importedobject swiperSlide36 '"   id="swiperSlide36" ref="swiperSlide36" v-for="(item,index) in list" :key="'swiperSlide36' + index" v-if="isArticles && mini"  v-bind:style="{}">
 <ArticleBoxMini :class="'object articleboxmainContent112 '"   id="articleboxmainContent112" ref="articleboxmainContent112"  v-bind:style="{}" :article = "item" 
 :mini = "mini" 
>
</ArticleBoxMini>
</SwiperSlide>
 <SwiperSlide :class="'importedobject swiperSlide3 '"   id="swiperSlide3" ref="swiperSlide3" v-for="(item,index) in list" :key="'swiperSlide3' + index" v-if="isOrganisations"  v-bind:style="{}">
 <OrgaItem :class="'object orgaitemcolgridcolumncolumn05311 '"   id="orgaitemcolgridcolumncolumn05311" ref="orgaitemcolgridcolumncolumn05311"  v-bind:style="{}" :organisation = "item" 
>
</OrgaItem>
</SwiperSlide>
</Swiper>  </div>
 
      </div>
 </div> 
</template>
<script>
import Skeleton from '../../At/Utils/Skeleton'
import SwiperCard from '../../At/Utils/SwiperCard'
import MiniPreview from '../../At/Components/FeedPage/MiniPreview'
import ArticleBoxMini from '../../At/Components/BlogPage/ArticleBoxMini'
import OrgaItem from '../../At/Components/OrganisationsPage/OrgaItem'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
	name: 'SwiperRow',

    components: {Skeleton,SwiperCard,MiniPreview,ArticleBoxMini,OrgaItem,Swiper,SwiperSlide,},
	methods: {
	
		next: function() { 		
	this.$refs.swiperContainer.$swiper.slideNext()

}
,
		previous: function() { 		
	this.$refs.swiperContainer.$swiper.slidePrev()

}
,
	},
	computed: {
	
		hasMoreBtn: function() { 		
	return this.moreLink != null

}
,
		loading: function() { 		
	return this.list.length == 0

}
,
	},
	data(){
	return {
		
		swiperOptions:  {		
  spaceBetween: 15,

}
,
		}
	},
	props: {
	
		title: {					required: false,			default: () =>  "Most popular",		},
		list: {					required: false,			default: () =>  [],		},
		fromMoodle: {			type: Boolean,			required: false,			default: () =>  false,		},
		baseUrl: {					required: false,			default: () =>  "learning",		},
		isArticles: {			type: Boolean,			required: false,			default: () =>  false,		},
		isOrganisations: {			type: Boolean,			required: false,			default: () =>  false,		},
		moreLink: {					required: false,			default: () =>  null,		},
		mini: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SwiperRow{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.item0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:20px ; background-color: transparent ; 

.flow.rowitem04{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
justify-content:space-between ; background-color:transparent ; 
padding-left:10px ; 
padding-right:10px ; 

	.text.textitem03{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	height:32px!important ; 
	
	color:#161616 ; 
	font-size:28px ; 
	text-overflow: ellipsis ; 
	.text-content{
	width: 100%;
	white-space: nowrap;
	overflow: hidden ;
	text-overflow: ellipsis ; 
	font-weight: bold;
	}

	
}

	.row.rowrowitem043{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:32px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:5px ; background-color:transparent ; 

		.button.buttonrowrowitem0431{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:32px!important ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:32px!important ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.importedobject.swiperContainer{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
overflow:visible !important; 
width: calc(100% - (0px + 0px)) !important;


	.importedobject.swiperSlide4{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:70%!important ; 
	height:260px!important ; 
	

		.object.swipercardShadow{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide45Mini{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:80%!important ; 
	height:230px!important ; 
	

		.object.swipercardShadow{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:70%!important ; 
	height:260px!important ; 
	

		.object.swipercardswiperSlide1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide5Mini{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:80%!important ; 
	height:230px!important ; 
	

		.object.minipreviewswiperSlide5Mini1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:70%!important ; 
	height:auto!important ; 
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide36{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:80%!important ; 
	height:230px!important ; 
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:70%!important ; 
	height:250px!important ; 
	

		.object.orgaitemcolgridcolumncolumn05311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.item0{


position:relative !important; 









.flow.rowitem04{


position:relative !important; 









	.text.textitem03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowitem043{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowrowitem0431{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.importedobject.swiperContainer{


position:relative !important; 






	.importedobject.swiperSlide4{

	
	position:relative !important; 
	
	
	
	
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide45Mini{

	
	position:relative !important; 
	
	
	
	
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide{

	
	position:relative !important; 
	
	
	
	
	

		.object.swipercardswiperSlide1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide5Mini{

	
	position:relative !important; 
	
	
	
	
	

		.object.minipreviewswiperSlide5Mini1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide36{

	
	position:relative !important; 
	
	
	
	
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	

		.object.orgaitemcolgridcolumncolumn05311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.item0{


position:relative !important; 









.flow.rowitem04{


position:relative !important; 









	.text.textitem03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowitem043{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowrowitem0431{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.importedobject.swiperContainer{


position:relative !important; 






	.importedobject.swiperSlide4{

	
	position:relative !important; 
	
	
	
	
	width:40%!important ; 
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide45Mini{

	
	position:relative !important; 
	
	
	
	
	width:40%!important ; 
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide{

	
	position:relative !important; 
	
	
	
	
	width:30%!important ; 
	

		.object.swipercardswiperSlide1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide5Mini{

	
	position:relative !important; 
	
	
	
	
	width:40%!important ; 
	

		.object.minipreviewswiperSlide5Mini1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	width:30%!important ; 
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide36{

	
	position:relative !important; 
	
	
	
	
	width:40%!important ; 
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	width:30%!important ; 
	

		.object.orgaitemcolgridcolumncolumn05311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.item0{


position:relative !important; 









.flow.rowitem04{


position:relative !important; 









	.text.textitem03{

	
	position:relative !important; 
	
	
	
	
	
	font-size:25px ; 
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowitem043{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowrowitem0431{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.importedobject.swiperContainer{


position:relative !important; 






	.importedobject.swiperSlide4{

	
	position:relative !important; 
	
	
	
	
	width:20%!important ; 
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide45Mini{

	
	position:relative !important; 
	
	
	
	
	width:30%!important ; 
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide{

	
	position:relative !important; 
	
	
	
	
	width:20%!important ; 
	

		.object.swipercardswiperSlide1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide5Mini{

	
	position:relative !important; 
	
	
	
	
	width:30%!important ; 
	

		.object.minipreviewswiperSlide5Mini1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	width:20%!important ; 
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide36{

	
	position:relative !important; 
	
	
	
	
	width:30%!important ; 
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	width:20%!important ; 
	

		.object.orgaitemcolgridcolumncolumn05311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.item0{


position:relative !important; 









.flow.rowitem04{


position:relative !important; 









	.text.textitem03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowitem043{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowrowitem0431{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.importedobject.swiperContainer{


position:relative !important; 






	.importedobject.swiperSlide4{

	
	position:relative !important; 
	
	
	
	
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide45Mini{

	
	position:relative !important; 
	
	
	
	
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide{

	
	position:relative !important; 
	
	
	
	
	

		.object.swipercardswiperSlide1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide5Mini{

	
	position:relative !important; 
	
	
	
	
	

		.object.minipreviewswiperSlide5Mini1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide36{

	
	position:relative !important; 
	
	
	
	
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	

		.object.orgaitemcolgridcolumncolumn05311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.item0{


position:relative !important; 









.flow.rowitem04{


position:relative !important; 









	.text.textitem03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.row.rowrowitem043{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowrowitem0431{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.importedobject.swiperContainer{


position:relative !important; 






	.importedobject.swiperSlide4{

	
	position:relative !important; 
	
	
	
	
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide45Mini{

	
	position:relative !important; 
	
	
	
	
	

		.object.swipercardShadow{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide{

	
	position:relative !important; 
	
	
	
	
	

		.object.swipercardswiperSlide1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide5Mini{

	
	position:relative !important; 
	
	
	
	
	

		.object.minipreviewswiperSlide5Mini1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide36{

	
	position:relative !important; 
	
	
	
	
	

		.object.articleboxmainContent112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.importedobject.swiperSlide3{

	
	position:relative !important; 
	
	
	
	
	

		.object.orgaitemcolgridcolumncolumn05311{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
