<template>
 <div>  
<div id="WhoIsForSection"> 
         
 <ContentWrapper :class="'object contentWrapper222 '"   id="contentWrapper222" ref="contentWrapper222"  v-bind:style="{}" :paddingTop = "0" 
 :paddingBottom = "0" 
 :paddingLeft = "0" 
 :paddingRight = "0" 
 :fullWidth = "true" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate slottemplatecontentWrapper221 '"   id="slottemplatecontentWrapper221" ref="slottemplatecontentWrapper221"  v-bind:style="{}">
 <v-row  :class="'grid gridslottemplatecontentWrapper2211 '"   id="gridslottemplatecontentWrapper2211" ref="gridslottemplatecontentWrapper2211"  v-bind:style="{}" :no-gutters = "true" 
>
 <v-col  :class="'col colgridslottemplatecontentWrapper22111 '"   id="colgridslottemplatecontentWrapper22111" ref="colgridslottemplatecontentWrapper22111"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <div  :class="'column columncolgridslottemplatecontentWrapper221111 '"   id="columncolgridslottemplatecontentWrapper221111" ref="columncolgridslottemplatecontentWrapper221111" :_alignItems = "(mqVal({default:'center',md:'flex-end'}, $mq))" 
  v-bind:style="{alignItems : (mqVal({default:'center',md:'flex-end'}, $mq)),justifyContent : 'space-around',backgroundColor : '#f3f2f0',}">
 <div  :class="'column columncolgridslottemplatecontentWrapper2211111 '"   id="columncolgridslottemplatecontentWrapper2211111" ref="columncolgridslottemplatecontentWrapper2211111" :_maxWidth = "(mqVal({default:'unset',md:'564px'}, $mq))" 
  v-bind:style="{maxWidth : (mqVal({default:'unset',md:'564px'}, $mq)),}">
 <div  :class="'text textcolumncolgridslottemplatecontentWrapper2211111 '"   id="textcolumncolgridslottemplatecontentWrapper2211111" ref="textcolumncolgridslottemplatecontentWrapper2211111"  v-bind:style="{color : '#b24020',}">
<div class="text-content" :style = "{}">{{ $t('Home.whoIsAfroFor') }}</div>

   </div>
 <div  :class="'text textcolumncolgridslottemplatecontentWrapper2211113 '"   id="textcolumncolgridslottemplatecontentWrapper2211113" ref="textcolumncolgridslottemplatecontentWrapper2211113"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('Home.afroIsFor') }}</div>

   </div>
 <div  :class="'column columncolumncolgridslottemplatecontentWrapper22111115 '"   id="columncolumncolgridslottemplatecontentWrapper22111115" ref="columncolumncolgridslottemplatecontentWrapper22111115"  v-bind:style="{}">
 <EttaBtn :class="'object ettabtncolumncolumncolgridslottemplatecontentWrapper221111151 '"   id="ettabtncolumncolumncolgridslottemplatecontentWrapper221111151" ref="ettabtncolumncolumncolgridslottemplatecontentWrapper221111151" v-for="(item,index) in actions" :key="'ettabtncolumncolumncolgridslottemplatecontentWrapper221111151' + index"  v-bind:style="{}" :link = "item.link" 
 :text = "item.title" 
>
</EttaBtn>  </div>  </div>  </div></div></v-col>
 <v-col  :class="'col colgridslottemplatecontentWrapper22113 '"   id="colgridslottemplatecontentWrapper22113" ref="colgridslottemplatecontentWrapper22113"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <v-img  :class="'networkimage networkimagecolgridslottemplatecontentWrapper221131 '"   id="networkimagecolgridslottemplatecontentWrapper221131" ref="networkimagecolgridslottemplatecontentWrapper221131"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "image" 
>  </v-img></div></v-col>  </v-row></div></template>
</ContentWrapper>
 
      </div>
 </div> 
</template>
<script>
import ContentWrapper from '../../../At/Utils/ContentWrapper'
import EttaBtn from '../../../At/Components/HomePage/EttaBtn'


export default {
	name: 'WhoIsForSection',

    components: {ContentWrapper,EttaBtn,},
	computed: {
	
		actions: function() { 		
	return [

		{

			title: this.$t('Home.findcc'),

			link: "/comming-soon"

		},

		{

			title: this.$t('Home.findAsso'),

			link: "/comming-soon"

		},

		{

			title: this.$t('Home.findCT'),

			link: "/comming-soon"

		}

	]

}
,
	},
	props: {
	
		image: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#WhoIsForSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.contentWrapper222{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper2211{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	background-color: transparent ; 

		.col.colgridslottemplatecontentWrapper22111{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper221111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			margin-left: 0px ; 
			
			top: calc(50% + 0px) ; 
			margin-right: 0px ; 
			height:auto!important ; 
			alignItems:center !important; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			justify-content:space-around ; 
			background-color:#f3f2f0 ; 
			padding-top:56px ; 
			padding-bottom:56px ; 

				.column.columncolgridslottemplatecontentWrapper2211111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-right: 0px ; 
				height:auto!important ; 
				maxWidth:unset !important; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:12px ; background-color: transparent ; 
				padding-left:16px ; 
				padding-right:16px ; 

					.text.textcolumncolgridslottemplatecontentWrapper2211111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#b24020 ; 
					font-size:24px ; 
					text-align:left;
					display: flex;
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

					.text.textcolumncolgridslottemplatecontentWrapper2211113{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					width: calc(100% - (0px + 0px)) !important;
					
					font-size:10px ; 
					text-align:left;
					display: flex;
					.text-content{
					width: 100%;
					}

					
}

					.column.columncolumncolgridslottemplatecontentWrapper22111115{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					gap:16px ; background-color: transparent ; 

						.object.ettabtncolumncolumncolgridslottemplatecontentWrapper221111151{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:auto!important ; 
						min-height: 61px!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper22113{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.networkimage.networkimagecolgridslottemplatecontentWrapper221131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			margin-left: 96px ; 
			
			top: calc(50% + 0px) ; 
			display:none !important; 
			width:600px!important ; 
			height:600px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			
}

		
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper221111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columncolgridslottemplatecontentWrapper2211111{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumncolgridslottemplatecontentWrapper2211111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumncolgridslottemplatecontentWrapper2211113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.column.columncolumncolgridslottemplatecontentWrapper22111115{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.ettabtncolumncolumncolgridslottemplatecontentWrapper221111151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper22113{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.networkimage.networkimagecolgridslottemplatecontentWrapper221131{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper221111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columncolgridslottemplatecontentWrapper2211111{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumncolgridslottemplatecontentWrapper2211111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumncolgridslottemplatecontentWrapper2211113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.column.columncolumncolgridslottemplatecontentWrapper22111115{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.ettabtncolumncolumncolgridslottemplatecontentWrapper221111151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper22113{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.networkimage.networkimagecolgridslottemplatecontentWrapper221131{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper221111{

			
			position:relative !important; 
			
			
			
			
			alignItems:flex-end !important; 
			
			
			
			
			padding-top:135px ; 
			padding-bottom:135px ; 

				.column.columncolgridslottemplatecontentWrapper2211111{

				
				position:relative !important; 
				
				
				
				
				maxWidth:564px !important; 
				
				
				
				
				padding-left:50px ; 
				padding-right:50px ; 

					.text.textcolumncolgridslottemplatecontentWrapper2211111{

					
					position:relative !important; 
					
					
					
					
					
					font-size:40px ; 
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumncolgridslottemplatecontentWrapper2211113{

					
					position:relative !important; 
					
					
					
					
					
					font-size:25px ; 
					.text-content{
					width: 100%;
					}

					
}

					.column.columncolumncolgridslottemplatecontentWrapper22111115{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.ettabtncolumncolumncolgridslottemplatecontentWrapper221111151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper22113{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.networkimage.networkimagecolgridslottemplatecontentWrapper221131{

			
			position:relative !important; 
			
			
			
			
			 display:block !important; 
			
			display: inherit;

			flex: unset;
			border-radius:50% ; 
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper221111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columncolgridslottemplatecontentWrapper2211111{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumncolgridslottemplatecontentWrapper2211111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumncolgridslottemplatecontentWrapper2211113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.column.columncolumncolgridslottemplatecontentWrapper22111115{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.ettabtncolumncolumncolgridslottemplatecontentWrapper221111151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper22113{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.networkimage.networkimagecolgridslottemplatecontentWrapper221131{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.contentWrapper222{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatecontentWrapper221{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.grid.gridslottemplatecontentWrapper2211{

	
	position:relative !important; 
	
	
	
	
	

		.col.colgridslottemplatecontentWrapper22111{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columncolgridslottemplatecontentWrapper221111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columncolgridslottemplatecontentWrapper2211111{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumncolgridslottemplatecontentWrapper2211111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumncolgridslottemplatecontentWrapper2211113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.column.columncolumncolgridslottemplatecontentWrapper22111115{

					
					position:relative !important; 
					
					
					
					
					
					
					
					

						.object.ettabtncolumncolumncolgridslottemplatecontentWrapper221111151{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

				
}

			
}

		
}

		
}

		.col.colgridslottemplatecontentWrapper22113{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.networkimage.networkimagecolgridslottemplatecontentWrapper221131{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

		
}

		
}

	
}


}


}
}
}
</style>
