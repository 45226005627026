<template>
 <div>  
<div id="TabButton"> 
         
 <router-link  :class="'routerlink routerlink0 '"   id="routerlink0" ref="routerlink0"  v-bind:style="{}" :to = "link" 
>
 <div  :class="'column rectangle01 '"   id="rectangle01" ref="rectangle01"  v-bind:style="{justifyContent : 'space-around',}">
 <div  :class="'item itemrectangle013 '"   id="itemrectangle013" ref="itemrectangle013"  v-bind:style="{}">
 <div  :class="'icon iconitemrectangle0135 '"   id="iconitemrectangle0135" ref="iconitemrectangle0135"  v-bind:style="{color : (active? color: '#5a5a5a'),fontSize : getUnit(24,'px'),}"><i  :class="icon"></i></div>
 <div  :class="'item itemitemrectangle0135 '"   id="itemitemrectangle0135" ref="itemitemrectangle0135" v-if="showNotifs"  v-bind:style="{}">
 <div  :class="'row rectangleitemrectangle01311 '"   id="rectangleitemrectangle01311" ref="rectangleitemrectangle01311"  v-bind:style="{backgroundColor : '#cc1016',}">
 <div  :class="'text textrectangleitemrectangle01311 '"   id="textrectangleitemrectangle01311" ref="textrectangleitemrectangle01311"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ notifValue }}</div>

   </div>  </div>  </div>  </div>
 <div  :class="'row rowrectangle017 '"   id="rowrectangle017" ref="rowrectangle017"  v-bind:style="{}">
 <div  :class="'text textrectangle0151 '"   id="textrectangle0151" ref="textrectangle0151"  v-bind:style="{color : (active? color: '#5a5a5a'),}">
<div class="text-content" :style = "{}">{{ value }}</div>

   </div>  </div>
 <div  :class="'rectangle rectanglerectangle01 '"   id="rectanglerectangle01" ref="rectanglerectangle01" v-if="active"  v-bind:style="{backgroundColor : color,}">  </div>  </div>  </router-link>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'TabButton',

    components: {},
	computed: {
	
		notifValue: function() { 		
	if(this.numberNotifs > 100){

		return "+99"	

	}

	return this.numberNotifs

}
,
		showNotifs: function() { 		
	return this.numberNotifs > 0

}
,
	},
	props: {
	
		value: {					required: false,			default: () =>  "Offres d'emploi",		},
		color: {					required: false,			default: () =>  "#bdbdbd",		},
		link: {					required: false,			default: () =>  "/",		},
		active: {					required: false,			default: () =>  false,		},
		index: {					required: false,			default: () =>  0,		},
		numberNotifs: {					required: false,			default: () =>  0,		},
		icon: {					required: false,			default: () =>  "ion-ios-home",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#TabButton{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

a{
	text-decoration: none;
}



.routerlink.routerlink0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

margin-top: 0px ; 
margin-bottom: 0px ; 
height:auto!important ; 
height: calc(100% - (0px + 0px)) !important;


.column.rectangle01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; 
justify-content:space-around ; background-color: transparent ; 
padding-top:12px ; 
padding-bottom:3px ; 

	.item.itemrectangle013{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	width:24px!important ; 
	height:24px!important ; 
	

		.icon.iconitemrectangle0135{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		font-size:24px ; 
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.item.itemitemrectangle0135{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 0px ; 
		right: 0px ; 
		

			.row.rectangleitemrectangle01311{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:16px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			background-color:#cc1016 ; 
			border-radius:8px ; 
			padding-left:5px ; 
			padding-right:5px ; 

				.text.textrectangleitemrectangle01311{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				
				top: calc(50% + 0px) ; 
				width:auto!important ; 
				height:auto!important ; 
				
				color:#fff ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

			
}

		
}

	
}

	.row.rowrectangle017{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:18px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:5px ; background-color:transparent ; 
	padding-left:5px ; 
	padding-right:5px ; 

		.text.textrectangle0151{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		
		font-size:10px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglerectangle01{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	bottom: 0px ; 
	height:2px!important ; 
	
	background-color:#ffffff ; 

	
}


}


}


@media (max-width: 600px) {


.routerlink.routerlink0{


position:relative !important; 






.column.rectangle01{


position:relative !important; 









	.item.itemrectangle013{

	
	position:relative !important; 
	
	
	
	
	

		.icon.iconitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.item.itemitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		

			.row.rectangleitemrectangle01311{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrectangleitemrectangle01311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.row.rowrectangle017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectangle0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglerectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 601px) {


.routerlink.routerlink0{


position:relative !important; 






.column.rectangle01{


position:relative !important; 









	.item.itemrectangle013{

	
	position:relative !important; 
	
	
	
	
	

		.icon.iconitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.item.itemitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		

			.row.rectangleitemrectangle01311{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrectangleitemrectangle01311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.row.rowrectangle017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectangle0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglerectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 961px) {


.routerlink.routerlink0{


position:relative !important; 






.column.rectangle01{


position:relative !important; 









	.item.itemrectangle013{

	
	position:relative !important; 
	
	
	
	
	

		.icon.iconitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.item.itemitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		

			.row.rectangleitemrectangle01311{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrectangleitemrectangle01311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.row.rowrectangle017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectangle0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglerectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 1265px) {


.routerlink.routerlink0{


position:relative !important; 






.column.rectangle01{


position:relative !important; 









	.item.itemrectangle013{

	
	position:relative !important; 
	
	
	
	
	

		.icon.iconitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.item.itemitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		

			.row.rectangleitemrectangle01311{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrectangleitemrectangle01311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.row.rowrectangle017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectangle0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglerectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 1905px) {


.routerlink.routerlink0{


position:relative !important; 






.column.rectangle01{


position:relative !important; 









	.item.itemrectangle013{

	
	position:relative !important; 
	
	
	
	
	

		.icon.iconitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.item.itemitemrectangle0135{

		
		position:absolute !important; 
		
		
		
		
		

			.row.rectangleitemrectangle01311{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrectangleitemrectangle01311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}

	.row.rowrectangle017{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrectangle0151{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglerectangle01{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}


}
}
}
</style>
